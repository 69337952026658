<template>
  <div class="uploadImg">
    <!-- 上传图片 -->
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :destroy-on-close="true" class="uploadIMG" title="上传图片" :lock-scroll="true"
      :visible.sync="dialogVisible" :close-on-press-escape="false" :show-close="false"
      center width="500px">
      <!-- 上传图片 -->
      <el-upload v-if="dialogVisible" :action="baseupload" list-type="picture-card" :limit="1" :on-preview="preview"
        :on-success="onSuccess" :on-error="uploadError" :before-upload="uploads" :before-remove="handleRemove"
        :class="uploadShow ? 'disable' : ''">
        <i class="el-icon-plus"></i>
      </el-upload>

      <!-- 按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消 上 传</el-button>
        <el-button type="primary" @click="uploadInformation" :disabled="disabl">点 击 上 传</el-button>
      </span>
    </el-dialog>

    <!-- 图片放大 -->
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="dialogVisibles" class="xianshi" :destroy-on-close="true">
      <img draggable="false" style="width: 640px; height: 400px; margin: 20px 0px" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'uploadImg',
  data() {
    return {
      dialogVisible: false, //弹框默认隐藏
      dialogVisibles: false, // 放大的图片
      dialogImageUrl: '', // 上传的图片
      uploadShow: false, //是否显示上传图片
    }
  },
  created() { },
  methods: {
    /* 显示上传文件组件 */
    showUpload() {
      this.dialogVisible = true
    },
    /* 传递图片地址 */
    uploadInformation() {
      this.$emit('uploadInformation', this.dialogImageUrl)
      // 隐藏上传弹框
      this.dialogVisible = false
      this.uploadShow = false
      this.dialogImageUrl = ''
    },
    // 关闭弹框
    handleClose() {
      // this.$confirm('点击取消后您填写的信息将丢失，您确定取消？', "提示", {
      //   onConfirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "info ",
      // })
      //   .then(() => {
      //     this.handleRemove()
      //     this.$emit('handleClose')
      //     // 隐藏上传文件
      //     this.dialogVisible = false
      //     this.dialogImageUrl = ''
      //   })
      //   .catch(() => { })
      this.handleRemove()
      this.$emit('handleClose')
      // 隐藏上传文件
      this.dialogVisible = false
      this.dialogImageUrl = ''
    },

    // 删除图片
    handleRemove() {
      this.uploadShow = false
      this.dialogImageUrl = ''
      return true
    },

    // 预览
    preview() {
      this.dialogVisibles = true
    },

    // 上传成功
    onSuccess(response) {
      // 返回错误
      if (!response.data)
        return this.$message.error('上传图片失败，请删除后重新上传')

      this.dialogImageUrl = this.$store.state.ossUrl + response.data
    },

    // 上传前
    uploads(file) {
      if (!file.type.includes('image')) {
        this.$message.error('请上传图片！')
        return false
      }
      this.uploadShow = true
    },

    // 上传失败
    uploadError() {
      this.$message.error('请重新上传')
      this.uploadShow = false
    },
  },
  computed: {
    // baseurl
    baseupload() {
      return `${this.$store.state.uploadingUrl}`
    },

    // 提交按钮是否可以点击
    disabl() {
      if (!this.dialogImageUrl) return true
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../css/minx.scss';

.uploadImg {

  // 上传弹框内容部分
  /deep/.uploadIMG .el-dialog__body {
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
  }

  .disable {
    /deep/.el-upload {
      display: none !important;
    }
  }
}
</style>
