<template>
  <div class="suspensionstyle">
    <!-- 标题 -->
    <h2>{{ datas.text }}</h2>

    <!-- 表单 -->
    <el-form label-position="top" label-width="80px" :model="datas" size="small">
      <el-form-item label="跳转页面">
        <div class="d-flex">
          <el-upload :action="$store.state.uploadingUrl" list-type="picture-card" :class="{ disabled: uploadDisabled }"
            :limit="1" :on-progress="linkmanImgProgress" :on-success="linkmanImgSuccess" :on-remove="linkmanImgRemove"
            :file-list="imgFile">
            <i class="el-icon-plus"></i>
          </el-upload>
          <!-- 跳转页面 -->

          <div class="imgText">
            <associationJumpLink :linkData="datas.http" @changeFun="jumpLinkChange($event)" />
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'associationSuspensionstyle',
  props: {
    datas: Object,
  },
  data() {
    return {
      imgFile: []
    }
  },
  computed: {
    uploadDisabled() {
      return this.datas.img !== "";
    },
  },
  created() {
    this.imgFile = [{
      url: this.datas.img
    }]
  },
  methods: {
    /**@method 删除图片 */
    linkmanImgRemove(file, fileList) {
      this.datas.img = "";
      this.imgFile = [];
    },
    /**@method 图片上传 */
    linkmanImgProgress(event, file, fileList) {

    },
    /**@method 上传回调 */
    linkmanImgSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.$set(this.datas, "img", `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`);
    },
    /**@method 链接路径 */
    jumpLinkChange(data) {
      if (data.type) {
        let { urlType, type, id, title } = data;
        this.datas.http = {
          urlType,
          type,
          id,
          title
        };
      } else {
        this.datas.http = {};
      }
    },
  }
}
</script>
<style>
.suspensionstyle .el-upload {
  height: 65px;
  line-height: 75px;
  width: 65px;
}

.suspensionstyle .el-upload-list__item {
  height: 65px;
  line-height: 65px;
  width: 65px;
}

.suspensionstyle .el-icon-check {
  position: absolute;
  right: 15px;

}
</style>
<style lang="scss" scoped>
.suspensionstyle {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 10px 20px;
  box-sizing: border-box;

  /* 标题 */
  h2 {
    padding: 24px 16px 24px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #f2f4f6;
    font-size: 18px;
    font-weight: 600;
    color: #323233;
  }

  .imgText {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;

    .fir-sele.el-select {
      width: 40%;
    }
  }
}
</style>