var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"graphicnavigation",class:{ 'bShadow': _vm.datas.isShadow },style:({ backgroundImage: 'url(' + _vm.datas.bgImg + ')', background: _vm.datas.backgroundColor, 'border-radius': _vm.datas.borderRadius + 'px' })},[(_vm.datas.showTitle || _vm.datas.showMore)?_c('div',{staticClass:"navigationTitle flex-a-b-c",style:({ background: _vm.datas.backgroundColor })},[_c('div',{staticClass:"title"},[(_vm.datas.showTitle)?_c('span',[_vm._v(_vm._s(_vm.datas.title))]):_vm._e()]),(_vm.datas.showMore)?_c('div',{staticClass:"iconYQH iconyou"}):_vm._e()]):_vm._e(),(_vm.datas.addMethod === 'auto')?_c('div',[(!_vm.datas.autoData || !_vm.datas.autoData[0])?_c('section',{staticClass:"defaultNavigation",style:({
      display: _vm.datas.imgStyle === 0 ? 'flex' : '-webkit-box',
      'overflow-x': _vm.datas.imgStyle === 0 ? '' : 'scroll',
    })},_vm._l((_vm.datas.rowCount),function(index){return _c('div',{key:index,staticClass:"navigationList",style:({
        width:
          _vm.datas.imgStyle === 0 ? (100 / _vm.datas.rowCount) + '%' : 375 / _vm.datas.showSize - 1 + 'px',
      })},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.navigationType === 0),expression:"datas.navigationType === 0"}],style:({ 'border-radius': _vm.datas.iconRadius + '%' }),attrs:{"src":require("../../../images/imgs.png"),"alt":"默认图片","draggable":"false"}}),_c('p',{style:({ color: _vm.datas.textColor })},[_vm._v("导航")])])}),0):_c('section',{staticClass:"defaultNavigation",style:({
      background: _vm.datas.backgroundColor,
      display: _vm.datas.imgStyle === 0 ? 'flex' : '-webkit-box',
      'flex-wrap': _vm.datas.imgStyle === 0 ? 'wrap' : 'nowrap',
      'justify-content':
        _vm.datas.imgStyle === 0 ? 'left' : 'space-around',
      'overflow-x': _vm.datas.imgStyle === 0 ? '' : 'scroll',
    })},_vm._l((_vm.datas.autoData),function(item,index){return _c('div',{key:index,staticClass:"navigationList",style:({
        width: _vm.datas.imgStyle === 0 ? (100 / _vm.datas.rowCount) + '%' : 375 / _vm.datas.showSize - 1 + 'px',
      })},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.navigationType === 0),expression:"datas.navigationType === 0"}],style:({ 'border-radius': _vm.datas.iconRadius + '%', width: _vm.datas.imgSize + 'px', height: _vm.datas.imgSize + 'px' }),attrs:{"src":item.src,"alt":"默认图片","draggable":"false"}}),_c('p',{style:({
          color: _vm.datas.textColor,
          'font-size': _vm.datas.textSize + 'px',
          height: _vm.datas.textHeight + 'px',
          'line-height': _vm.datas.textHeight + 'px',
        })},[_vm._v(" "+_vm._s(item.text)+" ")])])}),0)]):_c('div',[(!_vm.datas.imageList[0])?_c('section',{staticClass:"defaultNavigation",style:({
      display: _vm.datas.imgStyle === 0 ? 'flex' : '-webkit-box',
      'overflow-x': _vm.datas.imgStyle === 0 ? '' : 'scroll',
    })},_vm._l((_vm.datas.rowCount),function(index){return _c('div',{key:index,staticClass:"navigationList",style:({
        width:
          _vm.datas.imgStyle === 0 ? (100 / _vm.datas.rowCount) + '%' : 375 / _vm.datas.showSize - 1 + 'px',
      })},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.navigationType === 0),expression:"datas.navigationType === 0"}],style:({ 'border-radius': _vm.datas.iconRadius + '%' }),attrs:{"src":require("../../../images/imgs.png"),"alt":"默认图片","draggable":"false"}}),_c('p',{style:({ color: _vm.datas.textColor })},[_vm._v("导航")])])}),0):_c('section',{staticClass:"defaultNavigation",style:({
      background: _vm.datas.backgroundColor,
      display: _vm.datas.imgStyle === 0 ? 'flex' : '-webkit-box',
      'flex-wrap': _vm.datas.imgStyle === 0 ? 'wrap' : 'nowrap',
      'justify-content':
        _vm.datas.imgStyle === 0 ? 'left' : 'space-around',
      'overflow-x': _vm.datas.imgStyle === 0 ? '' : 'scroll',
    })},_vm._l((_vm.datas.imageList),function(item,index){return _c('div',{key:index,staticClass:"navigationList",style:({
        width: _vm.datas.imgStyle === 0 ? (100 / _vm.datas.rowCount) + '%' : 375 / _vm.datas.showSize - 1 + 'px',
      })},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.navigationType === 0),expression:"datas.navigationType === 0"}],style:({ 'border-radius': _vm.datas.iconRadius + '%', width: _vm.datas.imgSize + 'px', height: _vm.datas.imgSize + 'px' }),attrs:{"src":item.src,"alt":"默认图片","draggable":"false"}}),_c('p',{style:({
          color: _vm.datas.textColor,
          'font-size': _vm.datas.textSize + 'px',
          height: _vm.datas.textHeight + 'px',
          'line-height': _vm.datas.textHeight + 'px',
        })},[_vm._v(" "+_vm._s(item.text)+" ")])])}),0)]),_vm._t("deles")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }