<template>
  <div class="videoss">
    <!-- 默认视频 -->
    <section class="default" v-if="!datas.src"
      :style="{ height: datas.videoHeight + 'px', 'border-radius': datas.borderRadius + 'px' }">
      <van-icon name="tv-o" size="150px" />
    </section>

    <!-- 选择视频后 -->
    <section v-else class="videoBorder"
      :style="{ 'border-radius': datas.borderRadius + 'px', height: datas.videoHeight + 'px' }">
      <video :src="src" controls :autoplay="datas.autoplay" :poster="datas.coverUrl" width="100%"
        :height="datas.videoHeight + 'px'"></video>
    </section>

    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
export default {
  name: 'videoss',
  props: {
    datas: Object,
  },
  data() {
    return {
      src: ""
    }
  },
  created() {
    this.src = this.datas.src;
  },
  watch: {
    'datas.src': function () {
      this.src = this.datas.src;
    },
    'datas.coverUrl': function () {
      this.src = "";
      let timeFun = setTimeout(() => {
        this.src = this.datas.src;
        timeFun = null;
      }, 300);
    }
  },
  methods: {
  },
}
</script>

<style scoped lang="scss">
.videoss {
  position: relative;

  .videoBorder {
    position: relative;
    overflow: hidden;
    background: #000;
  }

  /* 默认视频 */
  .default {
    width: 100%;
    height: 200px;
    overflow: hidden;
    background: #979797;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* 播放图标 */
  .bof {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 100px;
    opacity: 0.5;
    color: #999;
  }
}</style>
