<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent">
                <div class="textc pjTitle mt80 fs40">{{ checkedCompany.companyName }}</div>
                <div class="textc pjTitle mb20 fs40">产学研交流会议纪要</div>
                <table border="1" cellspacing="0" class="tableBorder institutionTable">
                    <tbody>
                        <tr class="h60">
                            <td class="textc itemContent w200">会议主题</td>
                            <td class="itemContent" colspan="3">
                                产学研合作交流会议纪要
                            </td>
                        </tr>
                        <tr class="h60">
                            <td class="textc itemContent w200">会议地点</td>
                            <td class="textc itemContent">会议室</td>
                            <td class="textc itemContent w200">会议时间</td>
                            <td class="textc itemContent">
                                <el-date-picker v-model="formData.meetingTime" class="dayInput w200"
                                    value-format="yyyy-MM-dd" format="yyyy.MM.dd" type="date" placeholder="选择日期">
                                </el-date-picker>
                            </td>
                        </tr>
                        <tr class="h60">
                            <td class="textc itemContent w200">主持人</td>
                            <td class="textc itemContent">
                                <el-input type="text" class="printName w200" placeholder="主持人"
                                    v-model="formData.hostName"></el-input>
                            </td>
                            <td class="textc itemContent w200">记录人</td>
                            <td class="textc itemContent">
                                {{ checkedCompany.printName }}
                            </td>
                        </tr>
                        <tr class="h60">
                            <td class="textc itemContent w200">参与人员</td>
                            <td class="itemContent" colspan="3">
                                <el-input type="text" class="printName" placeholder="参与人员"
                                    v-model="formData.joinName"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" class="lineheight60 pall10">
                                <div>会议内容：</div>
                                <div>
                                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<el-date-picker v-model="formData.meetingTime"
                                            class="dayInput w230" value-format="yyyy-MM-dd" format="yyyy年MM月dd日"
                                            type="date" placeholder="选择日期">
                                        </el-date-picker>， <el-input type="text" class="contentInput w400"
                                            placeholder="学校的老师" v-model="formData.schoolAndTeacherName"></el-input>
                                        到公司进行产学研合作交流，本次主要就我公司 <el-input type="text" class="contentInput w450"
                                            placeholder="项目问题" v-model="formData.problem"></el-input>
                                        等研发项目问题及公司发展问题进行深入的探讨和研究。</div>
                                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;一、我公司总经理 <el-input type="text"
                                            class="contentInput w200" placeholder="主持人"
                                            v-model="formData.hostName"></el-input>
                                        介绍了我公司的基本情况，展示了公司成立以来发展的业绩，介绍了公司的主要产品，目前正在研发的项目，遇到的问题与困难，并就我公司目前在人才需求方面，研发技术方面提出迫切的人才建设期望，同时介绍了公司未来三年发展规划。
                                    </div>
                                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;二、学校 <el-input type="text"
                                            class="contentInput w200" placeholder="老师"
                                            v-model="formData.teacherName"></el-input>
                                        介绍了该学校的发展历程与办学特色，并表示愿意积极发挥好高校人才聚集、智力聚集、平台聚集的优势，依托专业特长愿与公司共建<el-input type="text"
                                            class="contentInput w200" placeholder="项目名称"
                                            v-model="formData.projectName"></el-input>研发项目、攻克技术难关、全面推进深层次战略人才及研发技术合作，促进高校院校技术成果转化，实现共赢。
                                    </div>
                                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;三、公司与学校双方就关于产学研项目方面进行了深入交流与探讨，学校 <el-input
                                            type="text" class="contentInput w200" placeholder="老师"
                                            v-model="formData.teacherName"></el-input> 对公司目前产品情况，公司的技术研发情况、技术人才培养与引进等方面提出了一些建议与意见，并就与学校人才培养、培
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div></div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <table border="1" cellspacing="0" class="tableBorder institutionTable">
                    <tbody>
                        <tr>
                            <td colspan="4" class="lineheight60 pall10">
                                <div>
                                    育合作方面达到了共识，将为公司人才储备带来良好的，可持续性的良好循环，同时通过双方技术交流也给公司研发团队带来了很多启发与机遇，增强了公司对未来研发与产品市场的信心。
                                </div>
                                <div>
                                    会议决定：
                                </div>
                                <div class="textIndent40 ml25">
                                    双方将对于以下技术研发课题进行深层次的讨论或合作：
                                </div>
                                <div>
                                    <div>
                                        <el-input type="textarea" class="contentTextarea" show-word-limit
                                            maxlength="1000" :autosize="{ minRows: 3}" placeholder="探讨研究"
                                            v-model="formData.content"></el-input>
                                    </div>
                                </div>
                                <div class="mb80">
                                    <div class="text-align-right" style="margin-top: 300px;">{{
                checkedCompany.companyName }}</div>
                                    <div class="text-align-right">
                                        <el-date-picker v-model="formData.fileDay" class="dayInput w230"
                                            value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date"
                                            placeholder="选择日期">
                                        </el-date-picker>(自动转为中文)
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div></div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution9232",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                meetingTime: "",
                hostName: "",
                joinName: "",
                schoolAndTeacherName: "",
                teacherName: "",
                projectName:"",
                problem: "",
                content: "",
                fileDay: ""
            },
            checkedCompany: {}
        };
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData = {
                            meetingTime: data.fileDay,
                            hostName: data.hostName,
                            joinName: data.joinName,
                            fileDay: data.fileDay,
                            problem: data.problem,
                            schoolAndTeacherName: data.schoolAndTeacherName,
                            teacherName: data.teacherName,
                            projectName:data.projectName,
                            content: data.content
                        }
                    } else {
                        let checkedCompany = this.checkedCompany
                        this.formData = {
                            meetingTime: checkedCompany.fileDay,
                            hostName: checkedCompany.legalName,
                            joinName: "",
                            fileDay: checkedCompany.fileDay,
                            problem: "",
                            schoolAndTeacherName: "",
                            projectName:"",
                            teacherName: "",
                            content: ""
                        }
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    created() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.frame {
    padding: 0 10px;
    border: 1px solid #000;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 25px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 25px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}
.contentTextarea{
    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
        word-spacing: 10px;
        padding:0;
        text-indent: 10px;
    } 
}
.printName {
    /deep/input {
        font-size: 25px !important;
        line-height: 20px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 25px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>