<template>
  <div class="magiccubestyle">
    <!-- 标题 -->
    <h2>{{ datas.text }}</h2>
    <div class="moditystyle moditystyle2">
      <span class="addMethod" :class="datas.addMethod == 'auto' ? 'active' : ''" @click="datas.addMethod = 'auto'">
        自动获取商品
      </span>
      <span class="addMethod" :class="datas.addMethod == 'manual' ? 'active' : ''" @click="datas.addMethod = 'manual'">
        手动添加商品
      </span>
    </div>
    <div style="height: 20px" />
    <p style="color: #d40000; font-size: 14px">魔方布局</p>
    <p style="color: #969799; font-size: 12px; margin: 5px 0">
      选定布局区域，在下方选择套餐
    </p>

    <div v-if="datas.addMethod == 'manual'">
      <!-- 图片布局 -->
      <!-- 一行二个 -->
      <section class="buju buju0" v-if="datas.rubiksCubeType === 0">
        <div v-for="index in 2" :key="index" class="rubiksCubeType0 rubiksCubeType">
          <div style="font-size: 12px" v-if="!datas.imageList[index - 1]">
            宽度375像素
          </div>
          <img draggable="false" v-else :src="datas.imageList[index - 1].src" alt="" />
        </div>
      </section>

      <!-- 一行三个 -->
      <section class="buju buju0" v-if="datas.rubiksCubeType === 1">
        <div v-for="index in 3" :key="index" class="rubiksCubeType1 rubiksCubeType">
          <div style="font-size: 12px" v-if="!datas.imageList[index - 1]">
            宽度250像素
          </div>
          <img draggable="false" v-else :src="datas.imageList[index - 1].src" alt="" />
        </div>
      </section>

      <!-- 一行四个 -->
      <section class="buju buju0" v-if="datas.rubiksCubeType === 2">
        <div v-for="index in 4" :key="index" class="rubiksCubeType2 rubiksCubeType">
          <div style="font-size: 12px" v-if="!datas.imageList[index - 1]">
            宽度188像素
          </div>
          <img draggable="false" v-else :src="datas.imageList[index - 1].src" alt="" />
        </div>
      </section>

      <!-- 二左二右 -->
      <section class="buju buju0" v-if="datas.rubiksCubeType === 3">
        <div v-for="index in 4" :key="index" class="rubiksCubeType3 rubiksCubeType">
          <div style="font-size: 12px" v-if="!datas.imageList[index - 1]">
            375x375像素<br />或同等比例
          </div>
          <img draggable="false" v-else :src="datas.imageList[index - 1].src" alt="" />
        </div>
      </section>

      <!-- 一左二右 -->
      <section class="buju buju4" v-if="datas.rubiksCubeType === 4">
        <div class="rubiksCubeType4 rubiksCubeType" style="width: 163px; height: 326px">
          <div style="font-size: 12px" v-if="!datas.imageList[0]">
            375x750像素<br />或同等比例
          </div>
          <img draggable="false" v-else :src="datas.imageList[0].src" alt="" />
          <div></div>
        </div>
        <div style="display: inline-flex; flex-direction: column">
          <div class="rubiksCubeType4 rubiksCubeType" v-for="index in 2" :key="index">
            <div style="font-size: 12px" v-if="!datas.imageList[index]">
              375x375像素<br />或同等比例
            </div>
            <img draggable="false" v-else :src="datas.imageList[index].src" alt="" />
            <div></div>
          </div>
        </div>
      </section>

      <!-- 一上二下 -->
      <section class="buju buju5" v-if="datas.rubiksCubeType === 5">
        <div class="rubiksCubeType4 rubiksCubeType" style="width: 325px; height: 163px">
          <div style="font-size: 12px" v-if="!datas.imageList[0]">
            375x750像素<br />或同等比例
          </div>
          <img draggable="false" v-else :src="datas.imageList[0].src" alt="" />
          <div></div>
        </div>
        <div style="display: inline-flex">
          <div class="rubiksCubeType4 rubiksCubeType" v-for="index in 2" :key="index">
            <div style="font-size: 12px" v-if="!datas.imageList[index]">
              375x375像素<br />或同等比例
            </div>
            <img draggable="false" v-else :src="datas.imageList[index].src" alt="" style="width: 163px; height: 163px" />
            <div></div>
          </div>
        </div>
      </section>

      <!-- 一左三右 -->
      <section class="buju buju4" v-if="datas.rubiksCubeType === 6">
        <!-- 第一张图片 -->
        <div class="rubiksCubeType4 rubiksCubeType" style="width: 163px; height: 325px">
          <div style="font-size: 12px" v-if="!datas.imageList[0]">
            375x750像素<br />或同等比例
          </div>
          <img draggable="false" v-else :src="datas.imageList[0].src" alt="" />
        </div>
        <div style="display: inline-flex; flex-direction: column">
          <!-- 第二张图片 -->
          <div class="rubiksCubeType4 rubiksCubeType">
            <div style="font-size: 12px" v-if="!datas.imageList[1]">
              375x375像素<br />或同等比例
            </div>
            <img draggable="false" v-else :src="datas.imageList[1].src" alt="" />
            <div></div>
          </div>
          <div class="rubiksCubeType4 rubiksCubeType">
            <div style="
                      display: inline-flex;
                      width: 82px;
                      height: 163px;
                      justify-content: center;
                      align-items: center;
                      border: 1px solid #ebedf0;
                    " v-for="index in 2" :key="index">
              <div style="font-size: 12px" v-if="!datas.imageList[index + 1]">
                188x375像素<br />或同等比例
              </div>
              <img draggable="false" v-else :src="datas.imageList[index + 1].src" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>

    <div v-if="datas.addMethod == 'auto'">
      <!-- 图片布局 -->
      <!-- 一行二个 -->
      <section class="buju buju0" v-if="datas.rubiksCubeType === 0">
        <div v-for="index in 2" :key="index" class="rubiksCubeType0 rubiksCubeType">
          <div style="font-size: 12px" v-if="!datas.autoData[index - 1]">
            宽度375像素
          </div>
          <img draggable="false" v-else :src="datas.autoData[index - 1].src" alt="" />
        </div>
      </section>

      <!-- 一行三个 -->
      <section class="buju buju0" v-if="datas.rubiksCubeType === 1">
        <div v-for="index in 3" :key="index" class="rubiksCubeType1 rubiksCubeType">
          <div style="font-size: 12px" v-if="!datas.autoData[index - 1]">
            宽度250像素
          </div>
          <img draggable="false" v-else :src="datas.autoData[index - 1].src" alt="" />
        </div>
      </section>

      <!-- 一行四个 -->
      <section class="buju buju0" v-if="datas.rubiksCubeType === 2">
        <div v-for="index in 4" :key="index" class="rubiksCubeType2 rubiksCubeType">
          <div style="font-size: 12px" v-if="!datas.autoData[index - 1]">
            宽度188像素
          </div>
          <img draggable="false" v-else :src="datas.autoData[index - 1].src" alt="" />
        </div>
      </section>

      <!-- 二左二右 -->
      <section class="buju buju0" v-if="datas.rubiksCubeType === 3">
        <div v-for="index in 4" :key="index" class="rubiksCubeType3 rubiksCubeType">
          <div style="font-size: 12px" v-if="!datas.autoData[index - 1]">
            375x375像素<br />或同等比例
          </div>
          <img draggable="false" v-else :src="datas.autoData[index - 1].src" alt="" />
        </div>
      </section>

      <!-- 一左二右 -->
      <section class="buju buju4" v-if="datas.rubiksCubeType === 4">
        <div class="rubiksCubeType4 rubiksCubeType" style="width: 163px; height: 326px">
          <div style="font-size: 12px" v-if="!datas.autoData || !datas.autoData[0]">
            375x750像素<br />或同等比例
          </div>
          <img draggable="false" v-else :src="datas.autoData[0].src" alt="" />
          <div></div>
        </div>
        <div style="display: inline-flex; flex-direction: column">
          <div class="rubiksCubeType4 rubiksCubeType" v-for="index in 2" :key="index">
            <div style="font-size: 12px" v-if="!datas.autoData[index]">
              375x375像素<br />或同等比例
            </div>
            <img draggable="false" v-else :src="datas.autoData[index].src" alt="" />
            <div></div>
          </div>
        </div>
      </section>

      <!-- 一上二下 -->
      <section class="buju buju5" v-if="datas.rubiksCubeType === 5">
        <div class="rubiksCubeType4 rubiksCubeType" style="width: 325px; height: 163px">
          <div style="font-size: 12px" v-if="!datas.autoData || !datas.autoData[0]">
            375x750像素<br />或同等比例
          </div>
          <img draggable="false" v-else :src="datas.autoData[0].src" alt="" />
          <div></div>
        </div>
        <div style="display: inline-flex">
          <div class="rubiksCubeType4 rubiksCubeType" v-for="index in 2" :key="index">
            <div style="font-size: 12px" v-if="!datas.autoData[index]">
              375x375像素<br />或同等比例
            </div>
            <img draggable="false" v-else :src="datas.autoData[index].src" alt="" style="width: 163px; height: 163px" />
            <div></div>
          </div>
        </div>
      </section>

      <!-- 一左三右 -->
      <section class="buju buju4" v-if="datas.rubiksCubeType === 6">
        <!-- 第一张图片 -->
        <div class="rubiksCubeType4 rubiksCubeType" style="width: 163px; height: 325px">
          <div style="font-size: 12px" v-if="!datas.autoData || !datas.autoData[0]">
            375x750像素<br />或同等比例
          </div>
          <img draggable="false" v-else :src="datas.autoData[0].src" alt="" />
        </div>
        <div style="display: inline-flex; flex-direction: column">
          <!-- 第二张图片 -->
          <div class="rubiksCubeType4 rubiksCubeType">
            <div style="font-size: 12px" v-if="!datas.autoData[1]">
              375x375像素<br />或同等比例
            </div>
            <img draggable="false" v-else :src="datas.autoData[1].src" alt="" />
            <div></div>
          </div>
          <div class="rubiksCubeType4 rubiksCubeType">
            <div style="
                      display: inline-flex;
                      width: 82px;
                      height: 163px;
                      justify-content: center;
                      align-items: center;
                      border: 1px solid #ebedf0;
                    " v-for="index in 2" :key="index">
              <div style="font-size: 12px" v-if="!datas.autoData[index + 1]">
                188x375像素<br />或同等比例
              </div>
              <img draggable="false" v-else :src="datas.autoData[index + 1].src" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>

    <el-button @click="showProductModel()" v-if="datas.addMethod == 'manual'" class="uploadImg" type="primary" plain><i
        class="el-icon-plus" />点击添加套餐</el-button>

    <!-- 表单 -->
    <el-form label-width="80px" :model="datas" size="small">
      <div v-if="datas.imageList.length > 0 && datas.addMethod == 'manual'">
        <vuedraggable v-model="datas.imageList" v-bind="dragOptions">
          <transition-group>
            <section class="imgBanner" v-for="(item, index) in datas.imageList" :key="item + index">
              <i class="el-icon-circle-close" @click="deleteimg(index)" />
              <!-- 图片 -->
              <div class="imag">
                <!-- <img :src="item.img" alt="" draggable="false" @click="uploadImgFun(index)" /> -->
                <img :src="item.src" alt="" draggable="false" />
              </div>
              <!-- 标题和链接 -->
              <div class="imgText">
                <el-input v-model="item.title" placeholder="套餐名称" size="mini" disabled />
                <!-- 标题和链接 -->
                <div class="imgTextChild">
                  <el-input disabled="disabled" class="suffixInput" v-model.number="item.price" placeholder="商品价格"
                    size="mini">
                    <template slot="append">元</template>
                  </el-input>
                </div>
              </div>
            </section>
          </transition-group>
        </vuedraggable>
      </div>
      <div style="height: 20px" />
      <el-form-item label="套餐条数" class="lef borrediu" v-if="datas.addMethod == 'auto'">
        <el-slider v-model="datas.dataNumber" @change="changeDataNumber($event)" :max="imgActiveNumber" :min="1"
          input-size="mini" show-input>
        </el-slider>
      </el-form-item>

      <!-- 标题内容 -->
      <el-form-item label="选择模板" class="lef">
        <p style="color: #000">{{ styleText }}</p>
      </el-form-item>

      <!-- 商品样式选择 -->
      <div class="rubiksCubeType">
        <el-tooltip class="item" effect="dark" :content="item.content" placement="bottom"
          v-for="(item, index) in rubiksCubeTypes" :key="index">
          <span class="iconfont" style="font-size: 21px" :class="[
            item.type === datas.rubiksCubeType ? 'active' : '',
            item.icon,
          ]" @click="changeType(item)" />
        </el-tooltip>
      </div>

      <div style="height: 20px" />

      <!-- 图片高度 -->
      <el-form-item label="图片高度" v-if="datas.rubiksCubeType <= 3" class="lef">
        <el-slider v-model="datas.imgHeight" :max="200" input-size="mini" show-input>
        </el-slider>
      </el-form-item>
      <!-- 页面边距 -->
      <el-form-item label="页面边距" class="lef">
        <el-slider v-model="datas.pageMargin" :max="20" input-size="mini" show-input>
        </el-slider>
      </el-form-item>

      <!-- 图片间隙 -->
      <el-form-item label="图片间隙" class="lef">
        <el-slider v-model="datas.imgMargin" :max="20" input-size="mini" show-input>
        </el-slider>
      </el-form-item>
    </el-form>

    <!-- 上传图片 -->
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择套餐" :visible.sync="showProduct"
      :destroy-on-close="true" width="80%" center>
      <commonTable :tableData="productTableData" :loading="productLoading" @handleSizeChange="productHandleSizeChange"
        @handleCurrentChange="productHandleCurrentChange" :currentPage="productCurrentPage" :total="productTotal">
        <template v-slot:table>
          <el-table-column width="55">
            <template slot-scope="scope">
              <el-checkbox v-model="productIds[scope.row.levelId]" :disabled="disCheckbox(scope.row.levelId)"
                @change="selectProduct($event, scope.row)" :label="scope.row.levelId">{{ ''
                }}</el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="coverImg" align="center" label="商品图片">
            <template slot-scope="scope">
              <el-image v-if="scope.row.coverImg" style="width: 100px; height: 80px;margin-bottom:-10px"
                :src="scope.row.coverImg.split(',')[0]" :preview-src-list="scope.row.coverImg.split(',')">
              </el-image>
              <p v-else style="width: 100px; height: 80px;line-height: 80px;">
                暂无图片
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="goodsName" align="center" label="商品名称" show-overflow-tooltip />
          <el-table-column prop="price" align="center" label="价格" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.price | getPrice }}
            </template>
          </el-table-column>
          <el-table-column prop="levelName" align="center" label="会员等级" show-overflow-tooltip />

        </template>
      </commonTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showProduct = false">取 消 上 传</el-button>
        <el-button type="primary" @click="submitTable" :disabled="disabl">点 击 上 传</el-button>
      </span>
    </el-dialog>
    <!-- <uploadimg ref="upload" @uploadInformation="uploadInformation" /> -->
  </div>
</template>

<script>
// import uploadimg from '../../../uploadImg' //图片上传
import vuedraggable from 'vuedraggable' //拖拽组件
import { getGoodsMemberLinkList } from "@/api/appModel";
import commonTable from "@/components/common/commonTable";
export default {
  name: 'associationMagiccubestyle',
  props: {
    datas: Object,
  },
  components: {
    // uploadimg, 
    commonTable,
    vuedraggable
  },
  data() {
    return {
      rubiksCubeTypes: [
        {
          icon: 'icon-yihangerge',
          type: 0,
          content: '一行二个',
          num: 2
        },
        {
          icon: 'icon-yihangsange',
          type: 1,
          content: '一行三个',
          num: 3
        },
        {
          icon: 'icon-yihangsige',
          type: 2,
          content: '一行四个',
          num: 4
        },
        {
          icon: 'icon-erzuoeryou',
          type: 3,
          content: '二左二右',
          num: 4
        },
        {
          icon: 'icon-yizuoeryou',
          type: 4,
          content: '一左二右',
          num: 3
        },
        {
          icon: 'icon-yishangerxia',
          type: 5,
          content: '一上二下',
          num: 3
        },
        // {
        //   icon: 'icon-yizuosanyou',
        //   type: 6,
        //   content: '一左三右',
        //   num: 4
        // },
      ],
      imgActiveNumber: 2, //默认选中第一个图片
      //活动列表配置
      productIds: [],
      productTableData: [],
      showProduct: false,//模态框显示状态
      productCurrentPage: 1, //当前页
      productPageSize: 10, //显示条数
      productLoading: false, //表格加载
      productTotal: 0, //总条数
      checkedproduct: {},//选中的内容
      disabl: false,
      index: 0,
      product: {},//文章数据
      productType: [],//文章数据
      dragOptions: {
        animation: 200,
      },
      goodsLinkData: []
    }
  },
  computed: {
    disCheckbox() {
      return (levelId) => {
        let num = 0;
        for (let isOpen of this.productIds) {
          if (isOpen) {
            num++;
          }
        }
        if (levelId && this.productIds[levelId]) {
          return false;
        }
        num += this.datas.imageList.length;
        return num >= this.imgActiveNumber;
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    styleText() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (this.datas.rubiksCubeType === 0) return '一行二个'
      if (this.datas.rubiksCubeType === 1) return '一行三个'
      if (this.datas.rubiksCubeType === 2) return '一行四个'
      if (this.datas.rubiksCubeType === 3) return '二左二右'
      if (this.datas.rubiksCubeType === 4) return '一左二右'
      if (this.datas.rubiksCubeType === 5) return '一上二下'
      if (this.datas.rubiksCubeType === 6) return '一左三右'
    },
  },
  created() {
    this.imgActiveNumber = this.rubiksCubeTypes[this.datas.rubiksCubeType].num;
    this.goodsLinkData = this.$store.state.associationDrag.goodsLinkData;
    if (this.datas.addMethod === 'auto') {
      this.datas.autoData = this.goodsLinkData.slice(0, this.datas.dataNumber)
    }
  },
  methods: {
    changeType(row) {
      this.datas.rubiksCubeType = row.type;
      this.imgActiveNumber = row.num
      this.datas.dataNumber = row.num;
      this.datas.autoData = this.goodsLinkData.slice(0, this.datas.dataNumber)
    },
    submitTable() {
      this.disabl = true;
      let levelIds = []
      for (let row of this.datas.imageList) {
        levelIds.push(row.id);
      }
      for (let i in this.checkedproduct) {
        let row = this.checkedproduct[i];
        if (levelIds.indexOf(row.levelId) == -1) {
          this.datas.imageList.push({
            id: row.levelId,
            src: row.coverImg,
            price: (row.price / 100).toFixed(2),
            title: row.goodsName,
          })
        }
      }
      this.showProduct = false;
      this.disabl = false;
    },
    /**@method 链接路径 */
    jumpLinkChange(data, index) {
      if (data.type) {
        let { urlType, type, id, title } = data;
        this.datas.imageList[index].http = {
          urlType,
          type,
          id,
          title
        }
      } else {
        this.datas.imageList[index].http = {};
      }
      this.$set(this.datas.imageList, index, this.datas.imageList[index])
    },
    /* 替换 */
    // uploadInformation(res) {
    //   this.datas.imageList[this.imgActive].src = res
    // },
    /**套餐配置 */
    /**@method 选中套餐 */
    selectProduct(isOpen, data) {
      if (isOpen) {
        this.checkedproduct[data.levelId] = data;
      } else {
        delete this.checkedproduct[data.levelId];
      }
    },
    changeDataNumber(dataNumber) {
      this.datas.autoData = this.goodsLinkData.slice(0, dataNumber)
    },
    /**@method 显示园区套餐列表 */
    showProductModel() {
      this.showProduct = true;
      this.productIds = [];
      this.getProductList();
    },
    /**@method 显示套餐列表 */
    showProductFun(index) {
      this.index = index;
      this.showProduct = true;
    },
    /**@method 套餐获取列表 */
    async getProductList() {
      let params = {
        pageSize: this.productPageSize,
        pageNum: this.productCurrentPage,
        accountType: 9
      };
      try {
        this.productLoading = true;
        let res = await getGoodsMemberLinkList(params)
        this.productLoading = false;

        const { data } = res;
        this.productTableData = data.pageInfo.list;
        this.productTotal = data.pageInfo.total;
      } catch (error) {
        this.productLoading = false;
        this.productTableData = [];
      }
    },
    /**@method 套餐切换行 */
    productHandleSizeChange(val) {
      this.productPageSize = val;
      this.getProductList();
    },
    /**@method 套餐切换下一页 */
    productHandleCurrentChange(val) {
      this.productCurrentPage = val;
      this.getProductList();
    },
    /* 删除图片 */
    deleteimg(index) {
      this.datas.imageList.splice(index, 1)
    },
    /**套餐配置 */
  },
}
</script>

<style scoped lang="scss">
/* 商品样式 */
.moditystyle {
  font-size: 12px;
  width: 100%;
  display: flex;

  span {
    width: 86px;
    height: 32px;
    display: inline-block;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    border: 1px solid #ebedf0;

    &.active {
      border: 1px solid #51CDCB;
      background-color: #51cdcb26;
      color: #51CDCB;
    }
  }

  .addMethod {
    width: 110px;
    height: 40px;
    line-height: 40px;
  }
}

.moditystyle2 {
  justify-content: center;
}

/* 上传图片按钮 */
.uploadImg {
  width: 345px;
  height: 40px;
  margin-top: 20px;
}

.magiccubestyle {
  width: 100%;
  position: absolute;
  left: 0;
  overflow: hidden;
  top: 0;
  padding: 0 10px 20px;
  box-sizing: border-box;

  /* 标题 */
  h2 {
    padding: 24px 16px 24px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #f2f4f6;
    font-size: 18px;
    font-weight: 600;
    color: #323233;
  }

  /* 布局 */
  .buju {
    &.buju0 {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    &.buju4 {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .active {
      background: #51cdcb26;
      border: 1px solid #51CDCB;
      color: #51CDCB;
      z-index: 2;
    }

    .rubiksCubeType {
      background-color: #fff;
      border: 1px solid #ebedf0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #7d7e80;
      cursor: pointer;

      &.active {
        background: #51cdcb26;
        border: 1px solid #51CDCB;
        color: #51CDCB;
        z-index: 2;
      }

      &.rubiksCubeType0 {
        width: 50%;
        margin: 10px 0;

        height: 200px;

        img {
          width: 100%;
          height: 200px;
        }
      }

      &.rubiksCubeType1 {
        width: 33%;
        margin: 10px 0;
        height: 150px;

        img {
          width: 100%;
          height: 150px;
        }
      }

      &.rubiksCubeType2 {
        width: 25%;
        margin: 10px 0;
        height: 150px;

        img {
          width: 100%;
          height: 150px;
        }
      }

      &.rubiksCubeType3 {
        width: 50%;
        margin: 10px 0;
        height: 163px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &.rubiksCubeType4 {
        width: 163px;
        height: 163px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .lef {
    /deep/.el-form-item__label {
      text-align: left;
    }
  }

  /* 商品列表 */
  .imgBanner {
    padding: 6px 12px;
    margin: 16px 7px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    display: flex;
    position: relative;

    /* 删除图标 */
    .el-icon-circle-close {
      position: absolute;
      right: -10px;
      top: -10px;
      cursor: pointer;
      font-size: 19px;
    }

    /* 图片 */
    .imag {
      width: 60px;
      height: 60px;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        display: inline-block;
      }

      span {
        background: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        display: inline-block;
        width: 100%;
        text-align: center;
        color: #fff;
        height: 20px;
        line-height: 20px;
      }
    }

    /* 图片字 */
    .imgText {
      width: 80%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding-left: 20px;
      justify-content: space-between;

      /* 图片字 */
      .imgTextChild {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;

        .fir-sele.el-select {
          width: 40%;
        }
      }
    }
  }

  /* 列表样式 */
  .rubiksCubeType {
    display: flex;
    justify-content: space-around;
    align-items: center;

    span {
      display: inline-block;
      width: 58px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      background: #fff;
      border: 1px solid #ebedf0;
      color: #979797;
      margin: 0 1px;
      cursor: pointer;
      transition: all 0.5s;

      &:hover {
        border: 1px solid #51CDCB;
        color: #51CDCB;
      }

      &.active {
        border: 1px solid #51CDCB;
        background-color: #51cdcb26;
        color: #51CDCB;
      }
    }
  }
}
</style>
