var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"headBg",style:({ backgroundColor: _vm.pageSetup.titleBgColor, backgroundImage: 'url(' + _vm.pageSetup.titleBgImg + ')' })}),_c('div',{staticClass:"headerTop",style:({
    color: _vm.pageSetup.titleColor,
    height: _vm.pageSetup.titleHeight + 'px',
  })},[_c('div',{staticClass:"header-title",style:({
      height: _vm.pageSetup.titleHeight + 'px',
      'line-height': _vm.pageSetup.titleHeight + 'px',
    })},[_vm._v(" "+_vm._s(_vm.pageSetup.name)+" ")])]),_c('div',{staticClass:"d-flex justify-content-between userContent"},[_c('div',{staticClass:"d-flex"},[_vm._m(0),_c('div',{staticClass:"userDetails",style:({ color: _vm.pageSetup.titleColor })},[_c('div',{staticClass:"userName"},[_vm._v("匿 名")]),_c('div',{staticClass:"userPhone"},[_vm._v("135******71")])])]),_c('div',{staticClass:"smallFun",style:({ color: _vm.pageSetup.titleColor })},[_vm._m(1),_vm._m(2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"userPic"},[_c('img',{attrs:{"src":require("../../../images/userImg.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"iconYQH iconshuaxin"}),_c('div',{staticClass:"iconYQH iconxiaoxi2 mr10"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"member"},[_c('div',{staticClass:"memberName flex-c-c"},[_vm._v("董事")]),_c('div',{staticClass:"memberDate"},[_vm._v("永久")])])
}]

export { render, staticRenderFns }