<template>
	<div class="commoditysearch">
		<div class="newThreeBox flex-a-b-c">
			<div class="iocnSort flex-c-c pa5" v-if="datas.showSwitch"
				:style="{ backgroundColor: datas.borderColor, color: datas.textColor, 'border-radius': datas.borderRadius + 'px' }">
				<span class="el-icon-sort" style="font-size: 23px;"></span>
			</div>
			<div class="flex-a-b-c"
				:style="{ backgroundColor: datas.borderColor, 'border-radius': datas.borderRadius + 'px' }">
				<!-- 搜索框的长度 -->
				<div class="searchPlaceholder" v-if="showBtn == 3"
					:style="{ borderRight: '1px solid ' + datas.textColor, width: '180px', color: datas.textColor }">
					{{ datas.searchPlaceholder }}</div>
				<div class="searchPlaceholder" v-else-if="showBtn == 2"
					:style="{ borderRight: '1px solid ' + datas.textColor, width: '230px', color: datas.textColor }">
					{{ datas.searchPlaceholder }}</div>
				<div class="searchPlaceholder" v-else-if="showBtn == 1"
					:style="{ borderRight: '1px solid ' + datas.textColor, width: '280px', color: datas.textColor }">
					{{ datas.searchPlaceholder }}</div>
				<div class="searchPlaceholder" v-else-if="showBtn == 0"
					:style="{ borderRight: '1px solid ' + datas.textColor, width: '337px', color: datas.textColor }">
					{{ datas.searchPlaceholder }}</div>
				<div class="seeIcon flex-c-c pa5" :style="{ color: datas.textColor }">
					<span class="el-icon-search" style="font-size: 23px;"></span>
				</div>
			</div>
			<div class="flex-c-c pa5" v-if="datas.showSweep"
				:style="{ backgroundColor: datas.borderColor, color: datas.textColor, 'border-radius': datas.borderRadius + 'px' }">
				<van-icon name="scan" size="23" :color="datas.textColor" />
			</div>
			<div class="flex-c-c pa5" v-if="datas.showMessage"
				:style="{ backgroundColor: datas.borderColor, color: datas.textColor, 'border-radius': datas.borderRadius + 'px' }">
				<span class="el-icon-bell" style="font-size: 23px;"></span>
			</div>
		</div>

		<!-- 删除组件 -->
		<slot name="deles" />
	</div>
</template>

<script>
export default {
	name: 'homeCommoditysearch',
	props: {
		datas: Object,
	},
	computed: {
		showBtn() {
			let showState = 0;//0都没显示 1显示了一个 2显示了二个 3全部显示
			if (this.datas.showSweep) showState++;
			if (this.datas.showMessage) showState++;
			if (this.datas.showSwitch) showState++;
			return showState;
		}
	}
}
</script>

<style scoped lang="scss">
.commoditysearch {
	position: relative;
	padding:10px 0;
}

.newThreeBox {
	height: 33px;

	.searchPlaceholder {
		padding: 5px 10px;
		height: 31px;
		overflow: hidden;
		white-space: nowrap;
	}

	.iocnSort {
		transform: rotate(90deg)
	}
}
</style>
