<template>
  <div class="RealTimeView">
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="datas.show" width="414px" top="8vh" 
      :destroy-on-close="true">
      <img src="../../images/phoneTop.png" style="width: 375px" />
      <!-- <iframe
        v-if="datas.show"
        ref="iframe"
        class="screen"
        :scrolling="false"
        :src="'http://was666.gitee.io/as-editor-h5/#/?type=iframe'"
        @load="load"
      ></iframe> -->
      <!-- <van-loading v-if="loading" size="24px" vertical>加载中</van-loading> -->
      <association-home-header-top :pageSetup="pageSetup" @click.native="headTop" />
      <section class="phone-container RealTimeView " :style="{
        'background-color': pageSetup.bgColor,
        backgroundImage: 'url(' + pageSetup.bgImg + ')',
      }">
        <!-- <div :class="pointer.show ? 'pointer-events' : ''"> -->
        <div class="screen">
          <!-- 动态组件 -->
          <component :is="item.component" v-for="(item, index) in pageComponents" :key="index" :datas="item.setStyle"
            class="componentsClass" :data-type="item.type">
           
          </component>
        </div>
      </section>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'RealTimeView',
  props: {
    datas: {
      show: false,
    },
    val: Object
  },
  data() {
    return {
      loading: true,
      pageSetup: {
        // 页面设置属性
        name: '自动获取社团名称', //页面名称
        details: '', //页面描述
        isAddress: true, // 是否显示选择地区
        titleHeight: 45, // 高度
        bgColor: 'rgba(249, 249, 249, 10)', //背景颜色
        titleColor: "#fff",
        titleBgColor: "#3064ba",//标题背景图
        bgImg: '', // 背景图片
      },
      pageComponents: []
    }
  },
  watch: {
    val() {
      if (this.val.component) {
        this.pageComponents = JSON.parse(this.val.component);
      }
      if (this.val.templateJson) {
        this.pageSetup = JSON.parse(this.val.templateJson);
      }
    }
  },
  methods: {
    // load() {
    //   this.loading = false
    //  //-console.log("获取页面");
    //  //-console.log(this.val);
    //   // this.$refs["iframe"].contentWindow.postMessage(this.val, "http://localhost:8081");
    // },
  },
}
</script>

<style lang="scss" scoped>
.RealTimeView {
  .screen {
    overflow-y: scroll;
    width: 375px
      /*no*/
    ;
    height: 667px
      /*no*/
    ;
    border: 0;

    // 隐藏滚动条
    &::-webkit-scrollbar {
      display: none;
      /* Chrome Safari */
    }
  }
}
</style>
