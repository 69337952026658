<template>
  <div class="videostyle">
    <!-- 标题 -->
    <h2>{{ datas.text }}</h2>

    <el-form label-width="70px" :model="datas" size="small" class="lef">
      <el-form-item label="视频封面">
        <el-upload class="imgFile" :action="$store.state.uploadingUrl" list-type="picture-card"
          :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="linkmanImgProgress"
          :on-success="linkmanImgSuccess" :on-remove="linkmanImgRemove" :file-list="imgFile">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="视频内容">
        <el-upload class="avatar-uploader" :action="$store.state.uploadingUrl" list-type=".mp4" :show-file-list="false"
          :on-success="handleVideoSuccess">
          <video v-if="datas.src" :src="datas.src" width="100%" height="150px" controls></video>
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <div slot="file" slot-scope="{file}">
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span v-if="!disabled" class="el-upload-list__item-delete">
                <i class="el-icon-download"></i>
              </span>
              <span v-if="!disabled" class="el-upload-list__item-delete">
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="边框倒角" class="lef borrediu">
        <el-slider v-model="datas.borderRadius" :max="30" input-size="mini" show-input>
        </el-slider>
      </el-form-item>
      <el-form-item label="视频高度" class="lef borrediu">
        <el-slider v-model="datas.videoHeight" :max="400" input-size="mini" show-input>
        </el-slider>
      </el-form-item>
      <!-- 是否自动播放 -->
      <!-- <el-form-item class="lef" label="是否自动播放" label-width="100px">
        {{ datas.autoplay ? '是' : '否' }}
        <el-checkbox style="margin-left: 196px" v-model="datas.autoplay" />
      </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'videostyle',
  props: {
    datas: Object,
  },
  data() {
    return {
      imgFile: []
    }
  },
  computed: {
    uploadDisabled() {
      return this.datas.coverUrl !== "";
    },
  },
  created() {
    if (this.datas.coverUrl) {
      this.imgFile = [{
        url: this.datas.coverUrl
      }]
    }
  },
  methods: {
    handleVideoSuccess(res) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.$set(this.datas, "src", `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${res.data}`);
    },
    /**@method 删除图片 */
    linkmanImgRemove(file, fileList) {
      this.datas.coverUrl = "";
      this.imgFile = [];
    },
    /**@method 图片上传 */
    linkmanImgProgress(event, file, fileList) {

    },
    /**@method 上传回调 */
    linkmanImgSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.$set(this.datas, "coverUrl", `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`);
    },
  },
}
</script>
<style>
.imgFile .el-upload {
  height: 150px;
  line-height: 150px;
  width: 100%;
}

.imgFile .el-upload-list__item {
  height: 150px;
  line-height: 150px;
  width: 100%;
}

.imgFile .el-icon-check {
  position: absolute;
  right: 15px;

}
</style>
<style scoped lang="scss">
.avatar-uploader {
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  background-color: #fbfdff;
  height: 150px;
  overflow: hidden;
}


.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 250px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}

.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}

.videostyle {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 10px 20px;
  box-sizing: border-box;

  /* 标题 */
  h2 {
    padding: 24px 16px 24px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #f2f4f6;
    font-size: 18px;
    font-weight: 600;
    color: #323233;
  }

  .lef {
    /deep/.el-form-item__label {
      text-align: left;
    }
  }

  /* 刷新 */
  .link {
    display: inline-block;
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    cursor: pointer;
    color: #51CDCB;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
</style>
