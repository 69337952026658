<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发中心管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D005</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent" style="padding-top: 350px;">
                <div class="wordTtile">
                    <div class="flex-c-c textc">
                        <div class="zdhq fs24">研发中心管理办法</div>
                    </div>
                    <div class="textc pjTitle mt10 mb20 fs35">{{ wordData.isAgain }}</div>
                </div>
            </div>
            <div class="wordFoot">
                <div class="textc fs30">
                    {{ checkedCompany.companyName }}
                </div>
                <div class="textc fs25">
                    <el-date-picker v-model="formData.fileDay" class="dayInput w230" value-format="yyyy-MM-dd"
                        format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                    </el-date-picker>(自动转为中文)
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 1 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发中心管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D005</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordTtile">
                    <div class="textc pjTitle mt50 mb10 fs30">{{ checkedCompany.companyName }}</div>
                    <div class="flex-c-c textc lineBottom ">
                        <div class="zdhq fs40">研发中心管理办法</div>
                    </div>
                </div>
                <div class="lineheight45">
                    <div class="textIndent40">
                        为加强和提高公司技术创新、产品创新管理水平，整合公司资源，提升公司整体技术研发能力及科研成果转化能力，推进企业技术进步，规范公司的技术管理，增强企业的技术开发与创新能力，促进科研与生产的紧密结合，加速高新技术的产业化，适应市场经济发展的需要，本着为行业技术进步服务的目的，根据国家有关法律、法规和省市对企业研发中心的有关政策，结合公司的具体实际，特制定本办法。
                    </div>
                    <div class="textIndent40">{{formData.department}}研发中心具体组织架构及职责权限如下：</div>
                    <div class="fw600">
                        <!-- <el-input type="text" class="printName" placeholder="结构图标题" v-model="formData.title"></el-input> -->
                        一、研发中心组织结构图 <el-button type="primary" icon="el-icon-refresh" @click="switchImg">切换默认图</el-button>
                    </div>
                    <!-- <el-image class="w100p" :src="model9120Img"></el-image> -->
                    <el-upload class="upload" v-if="!formData.framework" accept=".JPG,.PNG" list-type="picture-card"
                        :action="uploadUrl" :show-file-list="false" :on-success="handleSuccess">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <div class="frameworkContent" v-else>
                        <el-image class="frameworkImg" :src="formData.framework" />
                        <div class="setFramework textc flex-c-c curPointer" @click="delFramework()">
                            <i class="el-icon-delete fs40 cred"></i>
                        </div>
                    </div>
                    <div class="textc">图1-1研发中心组织架构</div>
                    <div class="fw600">二、研发中心运作机制</div>
                    <div class="textIndent40">
                        1、研发中心实行公司总经理领导下的研发中心主任负责制，由公司主管技术的副总经理兼任，研发中心主任全面负责研发中心的技术管理、技术开发、创新的组织及部门之间的协调工作。</div>
                    <div class="textIndent40">
                        2、研发中心应由公司提供稳定的研发经费，原则上不低于当年销售收入的5%，研发中心的财务实行单独核算，所需经费应纳入企业年度研发预算并单独列帐，其固定资产纳入企业折旧范围。</div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>2</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发中心管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D005</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt80">
                    <div class="textIndent40">
                        3、执行本公司赋予的技术创新和服务的职能，组织并参加国内的技术交流与合作。参与制定和执行公司战略发展规划及经营计划，参与企业引进技术的论证、开展引进技术的消化、吸收和创新。
                    </div>
                    <div class="textIndent40">4、研发中心据公司科研项目需求，组建项目经理负责制的项目研发团队，并根据研发项目实际协调各方资源，保证研发项目顺利进行及目标达成。</div>
                    <div class="fw600">二、研发中心职责</div>
                    <div class="textIndent40">
                        1、负责制定公司技术管理制度；负责建立和完善产品设计、新产品的试制、标准化技术规程、技术情报管理制度，组织、协调有关部门建立和完善质量等管理标准及制度。
                    </div>
                    <div class="textIndent40">
                        2、组织和编制公司技术发展规划。编制近期技术提高及创新工作计划，编制长远技术发展和技术措施规划，并组织对计划、规划的拟定、修改、补充、实施等一系列技术组织和管理工作。</div>
                    <div class="textIndent40">3、负责公司年度科研项目的研发立项、评估、论证、及审核审批工作。负责根据公司研发项目制定研发费用预算制定及管理工作。</div>
                    <div class="textIndent40">4、负责制订和修改技术规程；编制产品的使用、技术安装等有关的技术规定。</div>
                    <div class="textIndent40">5、负责公司新技术引进和产品开发工作的计划、实施，确保产品不断创新发展；负责产品配方的开发、研制工作。</div>
                    <div class="textIndent40">6、合理编制技术文件，改进和规范工作流程。</div>
                    <div class="textIndent40">7、研究和摸索科学的流水作业规律，认真做好各类技术信息和资料收集、整理、分析、研究汇总归档保管工作。</div>
                    <div class="textIndent40">8、编制公司产品标准，按年度审核、充、修订定额内容。负责制定公司产品的企业标准，实现产品的规范化管理。</div>
                    <div class="textIndent40">9、认真做好技术图纸、技术资料的归档工作；负责制定严格的技术资料交接、保管工作制度。</div>
                    <div class="textIndent40">10、及时指导、处理、协调和解决产品出现的技术问题，确保生产、经营工作的正常进行。</div>
                    <div class="textIndent40">11、及时搜集整理国内外产品发展信息，及时把握产品发展趋势。</div>
                    <div class="textIndent40">12、负责编制公司技术研发计划，抓好技术管理人才培养，技术队伍的管理。有计划的推荐、引进、培养专业技术人员，搞好业务培训和管理工作。</div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>3</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发中心管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D005</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt50">
                    <div class="textIndent40">
                        13、组织技术成果及技术经济效益的评价工作。
                    </div>
                    <div class="textIndent40">
                        14、负责公司技术管理制度制订检查、监督、指导、考核专业管理工作。
                    </div>
                    <div class="textIndent40">
                        15、按时完成公司领导交办的其他工作任务。
                    </div>
                    <div class="fw600">三、研发中心主任职责</div>
                    <div class="textIndent40">
                        1、遵守国家法律法规、政策，遵守公司规章制度，在总经理领导下，主持研发中心的日常工作，制定研发中心的年度研发计划。负责组织编制公司远、中、近期技术发展规划。
                    </div>
                    <div class="textIndent40">
                        2、负责新产品、新工艺、新技术项目的立项，实施、监督、检查、改进及验收工作，并促进科技成果转化及产业化工作。
                    </div>
                    <div class="textIndent40">
                        3、负责聘用研发中心的管理人员和技术人员，根据对聘用或试用人员的考核情况，予以奖、惩、罚，对严重违反规定的，有权建议解除聘用合同，报总经理批准。对研发中心人员的使用，坚持德才兼备，以德为主，量才使用，分工明确，责任到人，定期检查、落实、公正考核；对青年技术人员进行经常性的技术指导、培养和提高，并从各方面对其进行教育、鼓励和关心，以使其感到本企业的温暖，看到自身价值和企业前途，以增强企业的凝聚力。
                    </div>
                    <div class="ml40">
                        <div>4、定期检查研发中心的工作进度，仪器设备的状态，开发技术的保密等事项。</div>
                        <div>5、制定及审核新产品企业标准，作好新产品的申报工作。</div>
                        <div>6、加强与各部门的协作关系，协助解决技术难题及技术改进工作。</div>
                        <div>7、及时完成总经理临时交办的工作。</div>
                    </div>
                    <div class="textIndent40">
                        本办法适用于公司研发中心，本办法由公司研发中心负责拟定，其解释权及修改权归研发中心所有。
                    </div>
                    <div class="text-align-right mt100">{{ checkedCompany.companyName }}</div>
                    <div class="text-align-right">
                        <el-date-picker v-model="formData.fileDay" class="miniDay w190" value-format="yyyy-MM-dd"
                            format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                        </el-date-picker>(自动转为中文)
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>4</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution9120",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                fileDay: "",
                framework: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/one-stand/institution/model9120-1.jpg",
                department:"",
            },
            imgData: [
                "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/one-stand/institution/model9120-1.jpg",
                "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/one-stand/institution/model9120-2.jpg"
            ],
            uploadUrl: this.$store.state.uploadingUrl,
            ossUrl: this.$store.state.ossUrl,
            checkedCompany: {},
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        },
        textTime(time) {
            if (time) {
                let dayArr = time.split("-");
                let fileDay = "";
                let dayObj = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
                let tObj = ["年", "月", "日"];
                for (let i in dayArr) {
                    let t = dayArr[i];
                    if (i == 0 || t < 10) {
                        for (let childKey of t) {
                            fileDay += dayObj[childKey];
                        }
                    } else {
                        if (t > 10) {
                            if (t >= 20) {
                                fileDay += dayObj[String(t).slice(0, 1)];
                            }
                            fileDay += "十";
                            if (String(t).slice(1) > 0) {
                                fileDay += dayObj[String(t).slice(1)];
                            }
                        } else {
                            fileDay += dayObj[t];
                        }
                    }
                    fileDay += tObj[i];
                }
                return fileDay;
            } else {
                return "";
            }
        },
        timeStr(time) {
            if (time) {
                let dayStr = time.replace("-", "年");
                dayStr = dayStr.replace("-", "月");
                return dayStr + "日";
            }
        },
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData.fileDay = data.fileDay;
                        this.formData.framework = data.framework;
                    } else {
                        this.formData.fileDay = this.checkedCompany.fileDay;
                    }
                    this.formData.department=data.department;
                }
            },
            deep: true,
            immediate: true
        },
    },
    created() { },
    methods: {
        /**@method 切换默认图 */
        switchImg(){
            let index=this.imgData.indexOf(this.formData.framework);
            if(index!=-1 && index+1<this.imgData.length){
                this.formData.framework=this.imgData[index+1];
            }else{
                this.formData.framework=this.imgData[0];
            }
        },
        /**@method 上传成功研发中心组织结构图*/
        handleSuccess(res) {
            this.formData.framework = this.ossUrl + res.data;
        },
        /**@method 删除研发中心组织结构图*/
        delFramework() {
            this.formData.framework = "";
        }
    },
};
</script>

<style lang="scss" scoped>
.frameworkContent {
    position: relative;

    .frameworkImg {
        width: 100%;
    }

    .setFramework {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // background: rgba(0,0,0,0.3);
        z-index: 2;

        .el-icon-delete {
            padding: 10px;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 50%;
            text-align: center;
            font-weight: bold;
        }
    }
}

.frameworkContent:hover {
    .setFramework {
        display: flex;
    }
}

.frame {
    padding: 0 10px;
    border: 1px solid #000;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemTitle {
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 20px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>