<template>
  <div class="pictureads" :class="datas.modelType">
    <!-- 无图片 -->
    <div class="upload" v-if="!imageList[0]" :style="{ height: datas.imgHeight + 'px' }">
      <i class="iconfont icon-lunbotu"></i>
    </div>
    <van-swipe v-if="imageList[0]" class="my-swipe" :autoplay="datas.intervalDate" indicator-color="#5785e7"
      :show-indicators="false" :height="datas.imgHeight + 'px'">
      <van-swipe-item class="vanSwipeItem" v-for="(item, index) in imageList" :key="index"
        :style="{ height: datas.imgHeight + 'px', 'border-radius': datas.borderRadius + 'px' }">
        <!-- <img :src="item.imageUrl" alt="" draggable="false"
          :style="{ 'border-radius': datas.borderRadius + 'px', height: datas.imgHeight + 'px' }" /> -->
        <el-image class="vanSwipeItemImage"
          :style="{ width: '100%', 'border-radius': datas.borderRadius + 'px', height: datas.imgHeight + 'px' }"
          :src="item.imageUrl" fit="cover"></el-image>
        <p class="title" v-show="item.text ? true : false">{{ item.text }}</p>
      </van-swipe-item>
    </van-swipe>

    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>

export default {
  name: 'pictureads',
  props: {
    datas: Object,
  },
  data() {
    return {
      mySwiper: null,
    }
  },
  computed: {
    /* 类型切换 */
    swiperType() {
      return this.datas.swiperType
    },
    /* 图片删除或者增加 */
    imageList() {
      //-console.log(this.datas.imageList.length, '-------轮播数量')
      return this.datas.imageList
    },
    /* 分页器类型切换 */
    pagingType() {
      return this.datas.pagingType
    },
    /* 一行个数 */
    rowindividual() {
      if (this.datas.swiperType === 1) {
        return 1
      } else {
        return this.datas.rowindividual
      }
    },
    /* 图片间距 */
    imageMargin() {
      if (this.datas.swiperType === 1) {
        return 0
      } else {
        return this.datas.imageMargin
      }
    },
  },
  watch: {
    pagingType() { },
    rowindividual() { },
    imageMargin() { },
  },
  methods: {
  },
}
</script>

<style scoped lang="scss">
.pictureads {
  position: relative;
  padding: 0 10px;

  /* 无图片 */
  .upload {
    background: #979797;
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 120px;
    }
  }

  .my-swipe {
    img {
      height: 100%;
      width: 100%;
    }
  }

  /* 类型0 */
  .type0 {
    box-sizing: border-box;

    /* 图片列表 */
    .imgLis {
      width: 100%;
      position: relative;
      overflow: hidden;

      &:last-child {
        margin: 0 !important;
      }

      /* 图片 */
      img {
        width: 100%;
        height: 100%;
        display: block;
      }

      .title {
        height: 36px;
        width: 100%;
        background-color: rgba(51, 51, 51, 0.8);
        text-align: center;
        line-height: 36px;
        color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  /* 类型1 */
  .type1 {
    width: 100%;
    position: relative;

    .swiper-slide {
      width: 100%;
      ;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
    }

    .title {
      height: 36px;
      width: 100%;
      background-color: rgba(51, 51, 51, 0.8);
      text-align: center;
      line-height: 36px;
      color: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: left;
      box-sizing: border-box;
      padding: 0 5px;
    }
  }

  .type3 {
    width: 100%;
    // height: 220px;
    align-items: center;

    .swiper-slide {
      // height: 210px !important;
      text-align: center;
      font-size: 18px;
      background: #fff;
      box-shadow: rgba(173, 173, 173, 0.8) 0px 7px 24px 0px;
      border-radius: 12px;
      overflow: hidden;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      transition: 300ms;
      transform: scale(0.8);

      img {
        width: 100%;
        height: 100%;
      }
    }

    .swiper-slide-active,
    .swiper-slide-duplicate-active {
      transform: scale(1);
    }

    .swiper-pagination {
      bottom: 0 !important;
    }

    .title {
      height: 36px;
      width: 100%;
      background-color: rgba(51, 51, 51, 0.8);
      text-align: center;
      line-height: 36px;
      color: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: left;
      box-sizing: border-box;
      padding: 0 5px;
    }
  }

  // .type3H {
  //   height: 220px;
  // }

  .swiper-container-horizontal>.swiper-pagination-progressbar {
    height: 2px;
  }
}

.leftRightFull {
  padding: 0 !important;
}

.allFull {
  padding: 0 !important;
  .upload{
    height:600px !important;
  }
  .vanSwipeItem {
    height: 600px !important;
  }
  .vanSwipeItemImage {
    height: 600px !important;
  }
}</style>
