<template>
    <el-autocomplete popper-class="my-autocomplete" :value="value" @input="watchInput" class="printName w300"
        :fetch-suggestions="getProjectPropertyList" :placeholder="placeholder" @select="selectProjectProperty">
        <template slot-scope="{ item }">
            <div class="flex-a-b-c">
                <div class="addr w180 text-overflow">{{ item.propertyName }}</div>
                <!-- <div class="name">{{ item.endDay }}</div> -->
            </div>
        </template>
    </el-autocomplete>
</template>

<script>
import { getProjectPropertyList } from "@/api/projectAdmin";
export default {
    name: "omSelectProjectProperty",
    props: {
        placeholder: {
            type: String,
            default: "请输入"
        },
        pcId:{
            default:''
        },
        params:{
            type: Object,
            default: function(){
                return {}
            }
        },
        value:String,
    },
    methods: {
        watchInput(val){
            this.$emit("input",val)
        },
        /**@method 获取项目列表 */
        getProjectPropertyList(str, cb) {
            let params = {
                pageSize: 100,
                pageNum: 1,
                sortCode: 1,
                pcId: this.pcId,
                ...this.params,
            };
            if (str) {
                params.propertyName = str;
            }
            let projectPropertyTable = [];
            getProjectPropertyList(params).then(res => {
                if (res.data) {
                    for (let row of res.data.pageInfo.list) {
                        projectPropertyTable.push(row);
                    }
                }
                cb(projectPropertyTable);
            }).catch(err => {
                cb(projectPropertyTable);
            })
        },
        /**@method 选中项目 */
        selectProjectProperty(data) {
            this.$emit("select",data);
        },
    }
}
</script>

<style></style>