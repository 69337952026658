<template>
    <div class="view" v-loading="contentLoading">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>员工创新提案与奖励管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GL-D012</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <table border="1" cellspacing="0" class="tableBorder institutionTable">
                    <tbody>
                        <tr class="h60">
                            <td class="itemContent fs35 textc" colspan="8"><el-image class="companyLogo"
                                    v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>创新提案申报表
                            </td>
                        </tr>
                        <tr class="h40">
                            <td class="itemContent textc" colspan="8">提案类型：<span class="fs14">■</span>技术创新类 <span
                                    class="fs14">□</span>工艺创新类 <span class="fs14">□</span>质量管理类 <span
                                    class="fs14">□</span>降本增效类 <span class="fs14">□</span>其他管理类
                                <el-date-picker v-model="formData.fileDay" class="tinyDay w164"
                                    value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                                </el-date-picker>
                            </td>
                        </tr>
                        <tr class="h40">
                            <td class="textc itemContent w140">提 案 人</td>
                            <td class="itemContent">
                                <om-select-user @select="selectUser" placeholder="提案人" :pcId="checkedCompany.pcId"
                                    v-model="formData.printName"></om-select-user>
                            </td>
                            <td class="textc itemContent w100">部 门</td>
                            <td class="textc itemContent w100">研发部</td>
                            <td class="textc itemContent w70">工号</td>
                            <td class="itemContent"> <el-input type="text" class="printName" placeholder="工号"
                                    v-model="formData.workNum"></el-input>
                            </td>
                            <td class="textc itemContent w130">职 位</td>
                            <td class="itemContent"> <el-input type="text" class="printName" placeholder="职位"
                                    v-model="formData.position"></el-input>
                            </td>
                        </tr>
                        <tr class="h40">
                            <td class="textc itemContent">提案名称</td>
                            <td class="itemContent" colspan="5">
                                <om-select-project v-model="formData.wordName" placeholder="提案名称"
                                    :pcId="checkedCompany.pcId" @select="selectProject"></om-select-project>
                            </td>
                            <td class="textc itemContent">责任部门</td>
                            <td class="textc itemContent">研发中心</td>
                        </tr>
                        <tr class="h40">
                            <td class="textc itemContent" colspan="8">提案内容（一项一案，若篇幅较长可另作附件）：</td>
                        </tr>
                        <tr>
                            <td class="itemContent">
                                <div class="textc mt50 mb50">
                                    <div>现状及改善</div>
                                    <div>原因</div>
                                </div>
                            </td>
                            <td class="itemContent" colspan="7">
                                <el-input type="textarea" class="printName" placeholder="现状及改善原因"
                                    v-model="formData.problem" rows="6" show-word-limit maxlength="1000"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="itemContent">
                                <div class="textc mt50 mb50">
                                    <div>创新或改善</div>
                                    <div>方法</div>
                                </div>
                            </td>
                            <td class="itemContent" colspan="7">
                                <el-input type="textarea" class="printName" placeholder="创新或改善"
                                    v-model="formData.resolvent" show-word-limit rows="6" maxlength="1000"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="itemContent">
                                <div class="textc mt50 mb50">
                                    <div>效 果</div>
                                    <div>预 测</div>
                                </div>
                            </td>
                            <td class="itemContent" colspan="7">
                                <el-input type="textarea" class="printName" placeholder="效果预测"
                                    v-model="formData.result" show-word-limit rows="6" maxlength="1000"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="itemContent textc" colspan="8">
                                提案评审委员会评分（对不采取的提案不作评分，评估人直接签名即可）
                            </td>
                        </tr>
                        <tr>
                            <td class="itemContent textc">
                                提案价值性（20分）
                            </td>
                            <td class="itemContent textc w150">
                                提案可操作性（20分）
                            </td>
                            <td class="itemContent textc w150">
                                提案创新程度（40分）
                            </td>
                            <td class="itemContent textc w150" colspan="2">
                                提案完整性（20分）
                            </td>
                            <td class="itemContent textc">
                                评 分
                            </td>
                            <td class="itemContent textc">
                                评估人
                            </td>
                            <td class="itemContent textc">
                                评估平均分
                            </td>
                        </tr>
                        <tr class="h40">
                            <td class="itemContent textc">

                            </td>
                            <td class="itemContent textc">

                            </td>
                            <td class="itemContent textc">

                            </td>
                            <td class="itemContent textc" colspan="2">

                            </td>
                            <td class="itemContent textc">

                            </td>
                            <td class="itemContent textc">

                            </td>
                            <td class="itemContent textc" rowspan="3">

                            </td>
                        </tr>
                        <tr class="h40">
                            <td class="itemContent textc">

                            </td>
                            <td class="itemContent textc">

                            </td>
                            <td class="itemContent textc">

                            </td>
                            <td class="itemContent textc" colspan="2">

                            </td>
                            <td class="itemContent textc">

                            </td>
                            <td class="itemContent textc">

                            </td>
                        </tr>
                        <tr class="h40">
                            <td class="itemContent textc">

                            </td>
                            <td class="itemContent textc">

                            </td>
                            <td class="itemContent textc">

                            </td>
                            <td class="itemContent textc" colspan="2">

                            </td>
                            <td class="itemContent textc">

                            </td>
                            <td class="itemContent textc">

                            </td>
                        </tr>
                        <tr>
                            <td class="itemContent" colspan="8">
                                提案处理意见：<span class="fs14">□</span>不采纳 <span class="fs14">□</span>采纳 原因说明：
                            </td>
                        </tr>
                        <tr>
                            <td class="itemContent" colspan="8">
                                推广意见：<span class="fs14">□</span>部分可在同领域推广；<span class="fs14">□</span>同一领域可全部推广运用；<span
                                    class="fs14">□</span>各领域均可推广
                            </td>
                        </tr>
                        <tr>
                            <td class="itemContent" colspan="8">
                                提案实施情况：<span class="fs14">□</span>不实施； <span class="fs14">□</span>待实施； <span
                                    class="fs14">□</span>已经实施； 实施人签名：
                            </td>
                        </tr>
                        <tr>
                            <td class="itemContent">
                                评定等级
                            </td>
                            <td class="itemContent textc" colspan="8">
                                <span class="fs14">□</span>一级提案：创新型提案
                                <span class="fs14 ml20">□</span>二级提案：改进型提案
                                <span class="fs14 ml20">□</span>三级提案：建议型提案
                            </td>
                        </tr>
                        <tr>
                            <td class="itemContent">
                                <div class="textc mt20 mb20">
                                    <div>提案评审委员</div>
                                    <div>会评估意见</div>
                                </div>
                            </td>
                            <td class="itemContent" colspan="8">
                                <div class="d-flex autograph">
                                    <div class="ml100 w150">建议奖励金额</div>
                                    <div class="ml50">元；</div>
                                    <div class="ml50 mr100">评估组长：</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="itemContent" rowspan="2">
                                <div class="textc mt50 mb50">
                                    <div>审 批</div>
                                </div>
                            </td>
                            <td class="itemContent textc" colspan="5">
                                技术中心/人力/财务负责人意见
                            </td>
                            <td class="itemContent textc" colspan="2">
                                总经理
                            </td>
                        </tr>
                        <tr class="h40">
                            <td class="itemContent textc" colspan="5">

                            </td>
                            <td class="itemContent textc" colspan="2">

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 1 页 共 1 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getNormalWordDataByPdId } from "@/api/projectAdmin";
export default {
    name: "institution9341",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                workNum: "",
                position: "",
                wordName: "",
                problem: "",
                resolvent: "",
                result: "",
                fileDay: "",
                printName: "",
            },
            checkedCompany: {},
            contentLoading:false,
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        },
        textTime(time) {
            if (time) {
                let dayArr = time.split("-");
                let fileDay = "";
                let dayObj = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
                let tObj = ["年", "月", "日"];
                for (let i in dayArr) {
                    let t = dayArr[i];
                    if (i == 0 || t < 10) {
                        for (let childKey of t) {
                            fileDay += dayObj[childKey];
                        }
                    } else {
                        if (t > 10) {
                            if(t>=20){
                                fileDay += dayObj[String(t).slice(0, 1)]; 
                            }
                            fileDay += "十";
                            if(String(t).slice(1)>0){
                                fileDay += dayObj[String(t).slice(1)];
                            }
                        } else {
                            fileDay += dayObj[t];
                        }
                    }
                    fileDay += tObj[i];
                }
                return fileDay;
            } else {
                return "";
            }
        },
        timeStr(time) {
            if (time) {
                let dayStr = time.replace("-", "年");
                dayStr = dayStr.replace("-", "月");
                return dayStr + "日";
            }
        },
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData = {
                            workNum: data.workNum,
                            position: data.position,
                            wordName: data.wordName,
                            problem: data.problem,
                            resolvent: data.resolvent,
                            result: data.result,
                            fileDay: data.fileDay,
                            printName: data.printName,
                        };
                    } else {
                        this.formData = {
                            workNum: "",
                            position: "",
                            wordName: "",
                            problem: `烧毁。常规的外壳结构没有从热量传导和扩散方面考虑，严重妨碍了热量从功率管传递扩散，并散热。为改善这个问题，该外壳在保证同样的体积下，从源头减小热阻，提高导热能力，提高散热面积。`,
                            resolvent: "，增加了散热面积，散热能力提高2倍以上。铝合金散热性非常好，有效提高散热能力。",
                            result: "该外壳采用后期钻孔方式，避免成型螺丝孔占据主散热面面积，改变侧壁形状，斜坡状更利于热量均衡扩散，，极大提高外壳的散热性能，十分适合大功率电动机控制器的使用。",
                            fileDay: this.checkedCompany.fileDay,
                            printName: this.checkedCompany.printName,
                        }
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        selectUser(data) {
            this.formData.printName = data.userName;
            this.formData.position = data.work;
        },
        /**@method 选中项目 */
        selectProject(data) {
           //console.log(data.startDay.slice(0, 10));
            this.formData.wordName = data.projectName;
            let time = data.startDay.slice(0, 7);
            this.formData.fileDay = time + "-15"
            this.contentLoading=true;
            return getNormalWordDataByPdId({
                pdId: data.pdId,
                modelType: 1
            }).then(res => {
                this.contentLoading=false;
                if(res.data){
                    if(res.data.wordData.objective){
                        this.formData.problem=res.data.wordData.objective+"烧毁。常规的外壳结构没有从热量传导和扩散方面考虑，严重妨碍了热量从功率管传递扩散，并散热。为改善这个问题，该外壳在保证同样的体积下，从源头减小热阻，提高导热能力，提高散热面积。"
                    }
                    if(res.data.wordData.innovate){
                        this.formData.resolvent=res.data.wordData.innovate+"，增加了散热面积，散热能力提高2倍以上。铝合金散热性非常好，有效提高散热能力。"
                    }
                    if(res.data.wordData.result){
                        this.formData.result=`该外壳采用后期钻孔方式，避免成型螺丝孔占据主散热面面积，改变侧壁形状，斜坡状更利于热量均衡扩散，${res.data.wordData.result}，极大提高外壳的散热性能，十分适合大功率电动机控制器的使用。`
                    }
                }
            }).catch(err=>{
                this.contentLoading=false;
            })
        },
    }
};
</script>

<style lang="scss" scoped>
.frame {
    padding: 0 10px;
    border: 1px solid #000;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 60px;
    position: absolute;
    top: 0px;
    left: 0px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemContent {
        position: relative;

        .autograph {
            position: absolute;
            right: 10px;
            bottom: 10px;
        }

        min-height: 30px;
        height: 100%;
        font-size: 17px;
        padding: 5px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 17px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 17px !important;
        line-height: 20px;
        margin-left: -5px;
    }
}

.fileDay {
    /deep/input {
        font-size: 17px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 17px !important;
                line-height: 20px;
                margin-left: -5px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>