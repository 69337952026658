import store from '../store'
import router from '../router';
export function clearLoginInfo() {
    /* wx.showToast({
      title: "登录授权已过期，请刷新当前页面重新授权登录",
      duration: 2000,
      icon: "none"
    }); */
    router.push("/login")
    localStorage.removeItem("info");
    localStorage.removeItem("token");
    localStorage.removeItem("loginDate")
    localStorage.removeItem("code");
    localStorage.removeItem("codePhoen");
    localStorage.removeItem("password");

    /**删除配置 */
    localStorage.removeItem("answerData"); //删除GPT记录
    localStorage.removeItem("GPTMessage"); //删除GPT记录
    localStorage.removeItem("wechatType"); //小程序授权配置 用于判断是立即授权/手动授权
    localStorage.removeItem("empowerType"); //小程序授权配置 用于判断授权返回的小程序类型
    sessionStorage.removeItem("menuType"); //菜单类型 园区版/crm版
    sessionStorage.removeItem("checkedPark"); //选中的园区数据
    sessionStorage.removeItem("checkedAssociation")
    store.commit("closeSendArticleData");
    localStorage.removeItem("wechatType");
    sessionStorage.removeItem("checkedProjectCompany") //研发项目公司数据
}
/**@method base64转二进制 */
export const converse = (base64, filename) => {
    let arr = base64.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    let suffix = mime.split('/')[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
    })
}
export const moveToErr = function(that) {
        that.$nextTick(() => {
            let isError = document.getElementsByClassName("is-error")
            if (isError.length) {
                isError[0].scrollIntoView({
                    block: "center",
                    behavior: "smooth"
                })
                setTimeout(() => {
                    let $htmlInput = isError[0].querySelector("input")
                    if ($htmlInput) {
                        $htmlInput.focus();
                    }
                }, 800)
            }
        })
    }
    /***
     *  @return {string} timeText 返回系统时间字符串
     */
export const getDataTimeSec = (val, type) => {
    if (!val) {
        //没有日期返回空
        return "";
    }
    //时间数字小于10，则在之前加个“0”补位。
    function check(i) {
        let num;
        i < 10 ? num = "0" + i : num = i;
        return num;
    }
    let time = val ? new Date(val) : new Date();
    let year = time.getFullYear();
    let month = time.getMonth() + 1;
    let day = time.getDate();
    //获取时分秒
    let h = time.getHours();
    let m = time.getMinutes();
    let s = time.getSeconds();
    //检查是否小于10
    month = check(month)
    day = check(day)
    h = check(h);
    m = check(m);
    s = check(s);
    let timeText = ''
    if (type == 'yy-mm-dd') {
        timeText = `${year}-${month}-${day}`;
    } else if (type == 'yy-mm') {
        timeText = `${year}-${month}`;
    } else if (type == 'hh:mm') {
        timeText = `${h}:${m}`;
    } else {
        timeText = `${year}-${month}-${day} ${h}:${m}:${s}`;
    }
    return timeText
}

/***
 *  @return {string} timeText 复制粘贴内容
 */
export const copyText = (val) => {
    if (window.clipboardData) {
        window.clipboardData.setData('text', val);
    } else {
        (function(val) {
            document.oncopy = function(e) {
                e.clipboardData.setData('text', val);
                e.preventDefault();
                document.oncopy = null;
            }
        })(val);
        document.execCommand('Copy');
    }
}


export default {
    clearLoginInfo,
    moveToErr,
    getDataTimeSec,
};