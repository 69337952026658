<template>
  <div class="graphicnavigation" :class="{ 'bShadow': datas.isShadow }"
    :style="{ backgroundImage: 'url(' + datas.bgImg + ')', background: datas.backgroundColor, 'border-radius': datas.borderRadius + 'px' }">
    <div class="navigationTitle flex-a-b-c" v-if="datas.showTitle || datas.showMore"
      :style="{ background: datas.backgroundColor }">
      <div class="title"><span v-if="datas.showTitle">{{ datas.title }}</span></div>
      <div v-if="datas.showMore" class="iconYQH iconyou"></div>
    </div>
    <div v-if="datas.addMethod === 'auto'">
      <!-- 默认导航 -->
      <section class="defaultNavigation" v-if="!datas.autoData || !datas.autoData[0]" :style="{
        display: datas.imgStyle === 0 ? 'flex' : '-webkit-box',
        'overflow-x': datas.imgStyle === 0 ? '' : 'scroll',
      }">
        <!-- 导航 -->
        <div class="navigationList" v-for="index in datas.rowCount" :key="index" :style="{
          width:
            datas.imgStyle === 0 ? (100 / datas.rowCount) + '%' : 375 / datas.showSize - 1 + 'px',
        }">
          <!-- 图片 -->
          <img src="../../../images/imgs.png" alt="默认图片" v-show="datas.navigationType === 0" draggable="false"
            :style="{ 'border-radius': datas.iconRadius + '%' }" />
          <!-- 文字 -->
          <p :style="{ color: datas.textColor }">导航</p>
        </div>
      </section>

      <!-- 导航列表 -->
      <section class="defaultNavigation" v-else :style="{
        background: datas.backgroundColor,
        display: datas.imgStyle === 0 ? 'flex' : '-webkit-box',
        'flex-wrap': datas.imgStyle === 0 ? 'wrap' : 'nowrap',
        'justify-content':
          datas.imgStyle === 0 ? 'left' : 'space-around',
        'overflow-x': datas.imgStyle === 0 ? '' : 'scroll',
      }">
        <!-- 导航 -->
        <div class="navigationList" v-for="(item, index) in datas.autoData" :key="index" :style="{
          width: datas.imgStyle === 0 ? (100 / datas.rowCount) + '%' : 375 / datas.showSize - 1 + 'px',
        }">
          <!-- 图片 -->
          <img :src="item.src" alt="默认图片" v-show="datas.navigationType === 0" draggable="false"
            :style="{ 'border-radius': datas.iconRadius + '%', width: datas.imgSize + 'px', height: datas.imgSize + 'px' }" />
          <!-- 文字 -->
          <p :style="{
            color: datas.textColor,
            'font-size': datas.textSize + 'px',
            height: datas.textHeight + 'px',
            'line-height': datas.textHeight + 'px',
          }">
            {{ item.text }}
          </p>
        </div>
      </section>
    </div>
    <div v-else>
      <!-- 默认导航 -->
      <section class="defaultNavigation" v-if="!datas.imageList[0]" :style="{
        display: datas.imgStyle === 0 ? 'flex' : '-webkit-box',
        'overflow-x': datas.imgStyle === 0 ? '' : 'scroll',
      }">
        <!-- 导航 -->
        <div class="navigationList" v-for="index in datas.rowCount" :key="index" :style="{
          width:
            datas.imgStyle === 0 ? (100 / datas.rowCount) + '%' : 375 / datas.showSize - 1 + 'px',
        }">
          <!-- 图片 -->
          <img src="../../../images/imgs.png" alt="默认图片" v-show="datas.navigationType === 0" draggable="false"
            :style="{ 'border-radius': datas.iconRadius + '%' }" />
          <!-- 文字 -->
          <p :style="{ color: datas.textColor }">导航</p>
        </div>
      </section>

      <!-- 导航列表 -->
      <section class="defaultNavigation" v-else :style="{
        background: datas.backgroundColor,
        display: datas.imgStyle === 0 ? 'flex' : '-webkit-box',
        'flex-wrap': datas.imgStyle === 0 ? 'wrap' : 'nowrap',
        'justify-content':
          datas.imgStyle === 0 ? 'left' : 'space-around',
        'overflow-x': datas.imgStyle === 0 ? '' : 'scroll',
      }">
        <!-- 导航 -->
        <div class="navigationList" v-for="(item, index) in datas.imageList" :key="index" :style="{
          width: datas.imgStyle === 0 ? (100 / datas.rowCount) + '%' : 375 / datas.showSize - 1 + 'px',
        }">
          <!-- 图片 -->
          <img :src="item.src" alt="默认图片" v-show="datas.navigationType === 0" draggable="false"
            :style="{ 'border-radius': datas.iconRadius + '%', width: datas.imgSize + 'px', height: datas.imgSize + 'px' }" />
          <!-- 文字 -->
          <p :style="{
            color: datas.textColor,
            'font-size': datas.textSize + 'px',
            height: datas.textHeight + 'px',
            'line-height': datas.textHeight + 'px',
          }">
            {{ item.text }}
          </p>
        </div>
      </section>
    </div>
    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
export default {
  name: 'productType',
  props: {
    datas: Object,
  },
  created() {
    //-console.log(this.datas, '--------graphicnavigation')
  }
}
</script>

<style scoped lang="scss">
.bShadow {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.graphicnavigation {
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .navigationTitle {
    padding: 10px;

    .title {
      font-weight: bold;
      height: 24px;
      font-size: 14px;
    }
  }

  /* 默认导航 */
  .defaultNavigation {
    // overflow-x: scroll;
    // justify-content: space-evenly;

    &::-webkit-scrollbar {
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #51CDCB;
    }

    /deep/.el-collapse-item__header,
    /deep/.el-collapse-item__wrap {
      border-bottom: 0 !important;
    }

    /* 导航 */
    .navigationList {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;

      img {
        width: 100%;
        max-width: 45px;
        height: 45px;
      }

      p {
        font-size: 12px;
        margin-top: 5px;
        width: 100%;
        // overflow: hidden;
        // text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }
}
</style>
