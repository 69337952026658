/**
 * 定义全局过滤器
 *
 * 时间戳转换日期
 *
 */

let globalFilter = {
    // 时间戳转换日期
    formatDate: (value) => {
        let date = new Date(value)
        let y = date.getFullYear()
        let MM = date.getMonth() + 1
        MM = MM < 10 ? '0' + MM : MM
        let d = date.getDate()
        d = d < 10 ? '0' + d : d
        let h = date.getHours()
        h = h < 10 ? '0' + h : h
        let m = date.getMinutes()
        m = m < 10 ? '0' + m : m
        let s = date.getSeconds()
        s = s < 10 ? '0' + s : s
        return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
    },
    getPrice(price) {
        return Number(price / 100).toFixed(2) + "元"
    },
    getPrice2(price) {
        return Number(price).toFixed(2) + "元"
    }
}

export default globalFilter