<template>
  <div class="commoditysearchstyle">
    <!-- 标题 -->
    <h2>{{ datas.text }}</h2>

    <!-- 表单 -->
    <el-form label-width="80px" :model="datas" size="small">


      <div style="height: 20px" />

      <el-form-item label="占位符" class="lef">
        <el-input v-model="datas.searchPlaceholder"></el-input>
      </el-form-item>

      <div style="height: 10px" />

      <!-- 框体样式 -->
      <!-- <el-form-item class="lef" label="框体样式">
        <div class="weiz">
          <span>{{ datas.borderRadius === 0 ? '方形' : '圆形' }}</span>
          <div>
            <el-tooltip effect="dark" :content="index - 1 === 0 ? '方形' : '圆形'" placement="bottom" v-for="index in 2"
              :key="index">
              <i class="iconfont" :class="[
                index - 1 === 0 ? 'icon-sousuokuang1' : 'icon-sousuokuang',
                datas.borderRadius === index - 1 ? 'active' : '',
              ]" @click="datas.borderRadius = index - 1" />
            </el-tooltip>
          </div>
        </div>
      </el-form-item> -->
      <!-- <el-form-item label="框体倒角" class="lef borrediu">
        <el-slider v-model="datas.borderRadius" :max="30" input-size="mini" show-input>
        </el-slider>
      </el-form-item> -->
      <!-- 社团切换 -->
      <!-- <el-form-item class="lef" label="社团切换">
        {{ datas.showSwitch ? '显示' : '隐藏' }}
        <el-checkbox style="margin-left: 196px" v-model="datas.showSwitch" />
      </el-form-item>
      <div style="height: 10px" /> -->
      <!-- 扫一扫 -->
      <!-- <el-form-item class="lef" label="扫一扫">
        {{ datas.sweeshowSweepp ? '显示' : '隐藏' }}
        <el-checkbox style="margin-left: 196px" v-model="datas.showSweep" />
      </el-form-item>
      <div style="height: 10px" /> -->

      <!-- 提醒消息 -->
      <!-- <el-form-item class="lef" label="提醒消息">
        {{ datas.showMessage ? '显示' : '隐藏' }}
        <el-checkbox style="margin-left: 196px" v-model="datas.showMessage" />
      </el-form-item>
      <div style="height: 10px" /> -->

      <!-- 框体颜色 -->
      <el-form-item class="lef" label="框体颜色">
        <!-- 颜色选择器 -->
        <el-color-picker v-model="datas.borderColor" show-alpha class="picke" :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>

      <div style="height: 10px" />

      <!-- 文本颜色 -->
      <el-form-item class="lef" label="文本颜色">
        <!-- 颜色选择器 -->
        <el-color-picker v-model="datas.textColor" show-alpha class="picke" :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
  name: 'homeCommoditysearchstyle',
  props: {
    datas: Object,
  },
  data() {
    return {
      predefineColors: [
        // 颜色选择器预设
        "#fff",
        "#000",
        "#3064ba",
        '#51CDCB',
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        '#409EFF',
        '#909399',
        '#C0C4CC',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577',
      ],
      dragOptions: {
        //拖拽配置
        animation: 200,
      },
    }
  },
  methods: {

  },
}
</script>

<style scoped lang="scss">
.commoditysearchstyle {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 10px 20px;
  box-sizing: border-box;

  /* 标题 */
  h2 {
    padding: 24px 16px 24px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #f2f4f6;
    font-size: 18px;
    font-weight: 600;
    color: #323233;
  }

  /* 上传图片按钮 */
  .uploadImg {
    width: 345px;
    height: 40px;
    margin-top: 10px;
  }

  /* 热词列表 */
  .imgList {
    padding: 6px 12px;
    margin: 16px 7px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    display: flex;
    position: relative;

    /* 删除图标 */
    .el-icon-circle-close {
      position: absolute;
      right: -10px;
      top: -10px;
      cursor: pointer;
      font-size: 19px;
    }

    /* 热词文字 */
    .imgText {
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: space-between;
    }
  }

  /* 图片样式 */
  .weiz {
    display: flex;
    justify-content: space-between;

    i {
      padding: 5px 14px;
      margin-left: 10px;
      border-radius: 0;
      border: 1px solid #ebedf0;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;

      &:last-child {
        font-size: 22px;
      }

      &.active {
        color: #51CDCB;
        border: 1px solid #51CDCB;
        background: #51cdcb26;
      }
    }
  }

  .lef {
    /deep/.el-form-item__label {
      text-align: left;
    }
  }

  /* 颜色选择器 */
  .picke {
    float: right;
  }
}
</style>
