<template>
  <div>
    <div class="headBg"
      :style="{ backgroundColor: pageSetup.titleBgColor, backgroundImage: 'url(' + pageSetup.titleBgImg + ')' }"></div>
    <div class="headerTop" :style="{
      color: pageSetup.titleColor,
      height: pageSetup.titleHeight + 'px',
    }">
      <!-- 左半部分 -->
      <!-- <div class="lef" v-show="pageSetup.isBack">
          <van-icon name="arrow-left" />
        </div> -->
      <!-- 标题 -->
      <div class="header-title" :style="{
        height: pageSetup.titleHeight + 'px',
        'line-height': pageSetup.titleHeight + 'px',
      }">
        {{ pageSetup.name }}
      </div>
      <!-- 右半部分 -->
      <!-- <div class="rig" v-show="pageSetup.isPerson">
          <span>个人中心</span>
        </div> -->
    </div>
    <div class="d-flex justify-content-between userContent">
      <div class="d-flex">
        <div class="userPic">
          <img src="../../../images/userImg.png" alt="">
        </div>
        <div class="userDetails" :style="{ color: pageSetup.titleColor }">
          <div class="userName">匿 名</div>
          <div class="userPhone">135******71</div>
        </div>
      </div>
      <div class="smallFun" :style="{ color: pageSetup.titleColor }">
        <div class="d-flex">
          <div class="iconYQH iconshuaxin"></div>
          <div class="iconYQH iconxiaoxi2 mr10"></div>
        </div>
        <div class="member">
          <div class="memberName flex-c-c">董事</div>
          <div class="memberDate">永久</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'crmMyHeaderTop',
  props: {
    pageSetup: Object,
  },
}
</script>

<style lang="scss" scoped>
.userContent {
  position: relative;
  z-index: 2;
  margin: 20px 0 30px 10px;

  .userPic {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .userDetails {
    padding-top: 8px;

    .userName,
    .userPhone {
      line-height: 30px;
      font-size: 18px;
    }
  }

  .smallFun {
    position: relative;
    height: 70px;

    .iconYQH {
      font-size: 22px;
      margin-left: 5px;
    }

    .member {
      position: absolute;
      top: 20px;
      right: 0;
      margin-top: 20px;
      font-size: 12px;
      text-align: right;

      .memberName {
        width: 60px;
        height: 30px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background: rgba(0, 0, 0, 0.2);
        text-align: center;
        padding-right: 10px;
      }
      .memberDate{
        padding-right: 10px;
      }
    }
  }
}

.headBg {
  height: 300px;
  width: 100%;
  background-size: 100% 100%;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  position: absolute;
  top: 20px;
  z-index: 1;
}

.headerTop {
  position: relative;
  height: 35px;
  width: 100%;
  display: flex;
  padding: 0 10px;
  // padding-top: 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  background-color: transparent;


  .header-title {
    width: 100%;
    text-align: left;
    font-size: 18px;
    line-height: 35px;
  }

  /* 右边 */
  .rig {
    // display: flex;
    // align-items: center;
    // height: 100%;
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);

    /* 搜索图标 */
    i {
      font-size: 18px;
      margin: 0 7px 5px;
    }

    /* 文字 */
    span {
      margin: 0 7px;
      font-size: 12px;
    }
  }
}
</style>
