<template>
  <div class="phoneBottom" :style="{backgroundColor:pageSetup.bgColor}"></div>
</template>

<script>
export default {
  name: 'phoneBottom',
  props: {
    pageSetup: Object,
  },
}
</script>

<style lang="scss" scoped>
.phoneBottom {
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  padding-top: 30px;
  box-sizing: border-box;
}
</style>
