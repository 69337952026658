<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="imgList">
                    <div class="imgItem w100p" v-for="(row, index) in formData.content" :key="index">
                        <div class="imgUrl imgContent">
                            <el-image class="imgUrl" :src="row.url"></el-image>
                            <div class="flex-c-c tool">
                                <i class="el-icon-delete-solid curPointer" @click="delData(index)"></i>
                                <i class="el-icon-caret-left curPointer" @click="topData(index)"></i>
                                <i class="el-icon-caret-right curPointer" @click="bottomData(index)"></i>
                            </div>
                        </div>
                    </div>
                    <div class="imgItem w100p">
                        <div>
                            <el-upload class="upload" multiple list-type="picture-card" :action="uploadUrl"
                                :show-file-list="false" :on-success="handleSuccess">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution9322",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            uploadUrl: this.$store.state.uploadingUrl,
            ossUrl: this.$store.state.ossUrl,
            formData: {
                content: []
            },
            imgTitle: "",
            checkedCompany: {}
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        }
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData.content = data.content ? data.content : [];
                    } else {
                        this.formData.content = [];
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    created() {

    },
    methods: {
        handleSuccess(res) {
            this.formData.content.push({
                url: this.ossUrl + res.data
            })
        },
        bottomData(i) {
            if (this.formData.content.length != (i + 1)) {
                let bottomData = JSON.parse(JSON.stringify(this.formData.content[i + 1]));
                let currentData = JSON.parse(JSON.stringify(this.formData.content[i]));
                // this.formData.content[i] = bottomData;
                // this.formData.content[i + 1] = currentData;
                this.$set(this.formData.content, i, bottomData);
                this.$set(this.formData.content, i + 1, currentData);
            }
        },
        topData(i) {
            if (i > 0) {
                let topData = JSON.parse(JSON.stringify(this.formData.content[i - 1]));
                let currentData = JSON.parse(JSON.stringify(this.formData.content[i]));
                // this.formData.content[i] = topData;
                // this.formData.content[i - 1] = currentData;
                this.$set(this.formData.content, i, topData);
                this.$set(this.formData.content, i - 1, currentData);
            }
        },
        delData(i) {
            if (this.formData.content.length >= 1) {
                this.formData.content.splice(i, 1)
            }
        }
    },
};
</script>
<style lang="scss">
.imgContent {
    position: relative;
}

.imgContent:hover .tool {
    opacity: 1;
}

.imgContent .tool {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    font-size: 30px;
    opacity: 0;
    transition: opacity .3s;

    i {
        color: #fff;
    }

    .el-icon-delete-solid {
        color: #F56C6C;
    }
}

.showModelContent .el-upload--picture-card,
.showModelContent .el-upload-list--picture-card .el-upload-list__item {
    height: 450px !important;
    width: 100% !important;
    line-height: 456px !important;
}

.showModelContent .el-upload-list--picture-card .el-upload-list__item-status-label .el-icon-check {
    position: absolute !important;
    bottom: 1px !important;
    left: 13px !important;
    margin: 0 !important;
}
</style>
<style lang="scss" scoped>
.imgList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .wordInput {
        /deep/input {
            font-size: 20px !important;
            line-height: 20px;
        }
    }

    .imgUrl {
        height: 450px;
        width: 100%;
    }

    .imgTitle {
        border-top: 1px solid #000;
        height: 40px;
    }
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.institutionTable {
    border-color: #000;

    .itemTitle {
        height: 100%;
        font-size: 25px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 20px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 20px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }
}
</style>