<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>员工创新提案与奖励管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GL-D012</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent" style="padding-top: 350px;">
                <div class="wordTtile">
                    <div class="flex-c-c textc">
                        <div class="zdhq fs24">员工创新提案与奖励管理办法</div>
                    </div>
                    <div class="textc pjTitle mt10 mb20 fs35">{{wordData.isAgain}}</div>
                </div>
            </div>
            <div class="wordFoot">
                <div class="textc fs30">
                    {{ checkedCompany.companyName }}
                </div>
                <div class="textc fs25">
                    <!-- <span class="fs30" v-if="!showSetTime">{{ formData.fileDay | textTime }}</span> -->
                    <el-date-picker v-model="formData.fileDay" class="dayInput w230" value-format="yyyy-MM-dd"
                        format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                    </el-date-picker>(自动转为中文)
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 1 页 共 3 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>员工创新提案与奖励管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GL-D012</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordTtile">
                    <div class="textc pjTitle mt50 mb10 fs30">{{ checkedCompany.companyName }}</div>
                    <div class="flex-c-c textc lineBottom ">
                        <div class="zdhq fs40">员工创新提案与奖励管理办法</div>
                    </div>
                </div>
                <div class="lineheight45">
                    <div class="fw600">
                        一、目的
                    </div>
                    <div class="textIndent40">
                        <div>
                            根据《国务院关于大力推进大众创业万众创新若干政策措施的意见》（国发〔2015〕32号）、《国务院关于加快构建大众创业万众创新支撑平台的指导意见》（国发〔2015〕53号）精神，为推动公司整体管理水平，经营绩效，技术层面的发展，鼓励公司各部门、激发发全体员工的创造力、想象力，倡导人人参与管理，集结员工的智慧与经验，提出创新提案并参与技术革新、技术开发活动，加强技术成果的管理，推广及应用，不断提高科技含量，结合公司的实际情况，特制定本办法。
                        </div>
                    </div>
                    <div class="fw600">
                        二、适用范围：
                    </div>
                    <div class="textIndent40">
                        <div>本方案适用于{{ checkedCompany.companyName }}全体员工。</div>
                        <div>创新提案指：技术创新、管理创新、创新项目及实施方案、持续改进方案、合理化建议等具有革新提案的总称。</div>
                    </div>
                    <div class="fw600">
                        三、提案评审委员会
                    </div>
                    <div class="textIndent40">
                        <div>1、公司成立提案评审委员会，并由总经办负责日常的运行。</div>
                        <div>2、委员由各部门负责人组成。</div>
                        <div>3、提案评审委员会的工作职能包括：</div>
                        <div class="ml20">A.有关创新、提案制度的研究改进事项；</div>
                        <div class="ml20">B.员工提案件的审议事项（审查核定提交的提案表及评分表）；</div>
                        <div class="ml20">C.员工提案件评审标准的研订事项；</div>
                        <div class="ml20">D.提案件奖金金额的研议事项；</div>
                        <div class="ml20">E.审查和监督重大提案的实施；</div>
                        <div class="ml20">F.提案件实施成果的检讨事项；</div>
                        <div class="ml20">G.总结、评估提案活动；</div>
                        <div class="ml20">H.其他与创新、提案相关的事项。</div>
                        <div>4、提案评审委员会属执行总经理直接领导。</div>
                    </div>
                    <div class="fw600 textc">
                        四、提案等级及范围
                    </div>
                    <div class="ml20">一级提案：创新型提案</div>
                    <div class="textIndent40">
                        <div>1、创新型提案必须先申报核准，并附有专门的创新项目实施方案及评估报告，其实施结果或效果能给公司创造较大的直接经济效益。</div>
                        <div>2、一级提案奖励最低不少于1000元，最高不过5000元，并附带精神奖励。</div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 2 页 共 3 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>员工创新提案与奖励管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GL-D012</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt50">
                    <div class="ml20">二级提案：改进型提案</div>
                    <div class="textIndent40">
                        <div>1、改进型提案必须经过申报、审订、实施、评审等过程，其实施结果应较大地提高工作效率或带来较大的经济利益。</div>
                        <div>2、二级提案奖励最低不少于300元，最高不超过1000元，或附带精神奖励。</div>
                    </div>
                    <div class="ml20">三级提案：建议型提案</div>
                    <div class="textIndent40">
                        <div>1、建议型提案应填写《创新提案申请表》，全面、准确、客观地提出有利于公司经营管理和发展稳定的合理化建议，经评审后可给予物质或精神奖励。</div>
                        <div>2、三级提案奖励最低不少于100元，最高不超过500元，或单项给予精神奖励。</div>
                    </div>
                    <div class="fw600">
                        五、提案评审周期
                    </div>
                    <div class="textIndent40">
                        <div>1、所有提案每半年评审一次。</div>
                        <div>2、所有参与评审的提案须于每季度第三个月的20日前提交到总经办。</div>
                        <div>3、逾期提交的提案一律计入下一次评审。</div>
                    </div>
                    <div class="fw600">
                        六、提案提交及执行
                    </div>
                    <div class="textIndent40">
                        <div>1、所有方案由总经办统一管理、审核，初审合格的方案，并填写“创新提案申报表”送至提案委员会审核；经提案委员会审核通过后，交至执行总经理批准。</div>
                        <div>2、批准后，由总经办将方案交给相关部门落实执行。</div>
                    </div>
                    <div class="fw600">
                        七、奖金发放
                    </div>
                    <div class="textIndent40">
                        <div>批准通过的提案，人力资源部门拟订奖励方案，报研发中心核实，总经理审批后统一发放。</div>
                    </div>
                    <div class="fw600">
                        八、附则
                    </div>
                    <div class="textIndent40">
                        <div>1、本管理办法解释权归总经办所有。</div>
                    </div>
                    <div class="text-align-right mt50">{{ checkedCompany.companyName }}</div>
                    <div class="text-align-right">
                        <!-- <span v-if="!showSetTime">{{ formData.fileDay | textTime }}</span> -->
                        <el-date-picker v-model="formData.fileDay" class="miniDay w190" value-format="yyyy-MM-dd"
                            format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                        </el-date-picker>(自动转为中文)
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 3 页 共 3 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution9340",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                fileDay: "",
            },
            checkedCompany: {}
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        },
        textTime(time) {
            if (time) {
                let dayArr = time.split("-");
                let fileDay = "";
                let dayObj = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
                let tObj = ["年", "月", "日"];
                for (let i in dayArr) {
                    let t = dayArr[i];
                    if (i == 0 || t < 10) {
                        for (let childKey of t) {
                            fileDay += dayObj[childKey];
                        }
                    } else {
                        if (t > 10) {
                            if(t>=20){
                                fileDay += dayObj[String(t).slice(0, 1)]; 
                            }
                            fileDay += "十";
                            if(String(t).slice(1)>0){
                                fileDay += dayObj[String(t).slice(1)];
                            }
                        } else {
                            fileDay += dayObj[t];
                        }
                    }
                    fileDay += tObj[i];
                }
                return fileDay;
            } else {
                return "";
            }
        },
        timeStr(time) {
            if (time) {
                let dayStr = time.replace("-", "年");
                dayStr = dayStr.replace("-", "月");
                return dayStr + "日";
            }
        },
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData.fileDay = data.fileDay;
                    } else {
                        this.formData.fileDay = this.checkedCompany.fileDay;
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
};
</script>

<style lang="scss" scoped>
.frame {
    padding: 0 10px;
    border: 1px solid #000;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemTitle {
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 20px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>