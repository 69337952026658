<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>内部孵化中心及实施管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GL-D004</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent" style="padding-top: 350px;">
                <div class="wordTtile">
                    <div class="flex-c-c textc">
                        <div class="zdhq fs24">内部孵化中心及实施管理办法</div>
                    </div>
                    <div class="textc pjTitle mt10 mb20 fs35">{{wordData.isAgain}}</div>
                </div>
            </div>
            <div class="wordFoot">
                <div class="textc fs30">
                    {{ checkedCompany.companyName }}
                </div>
                <div class="textc fs25">
                    <!-- <span class="fs30" v-if="!showSetTime">{{ formData.fileDay | textTime }}</span> -->
                    <el-date-picker v-model="formData.fileDay" class="dayInput w230" value-format="yyyy-MM-dd"
                        format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                    </el-date-picker>(自动转为中文)
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 1 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>内部孵化中心及实施管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GL-D004</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordTtile">
                    <div class="textc pjTitle mt50 mb10 fs30">{{ checkedCompany.companyName }}</div>
                    <div class="flex-c-c textc lineBottom ">
                        <div class="zdhq fs40">内部孵化中心及实施管理办法</div>
                    </div>
                </div>
                <div class="lineheight45">
                    <div class="textIndent40">
                        <div>
                            为贯彻落实《国务院关于大力推进大众创业万众创新若干政策措施的意见》（国发〔2015〕32号）、《国务院关于加快构建大众创业万众创新支撑平台的指导意见》（国发〔2015〕53号），省中小企业局《关于做好推动大众创业万众创新工作的通知》（粤中小企函[2015]54号）及《珠海市关于做好推动大众创业万众创新工作的实施方案》精神，进一步优化创业创新环境，激发公司员工创业创新活力，形成大众创业、万众创新的宏大局面，结合公司实际，制定本管理办法及实施细则。
                        </div>
                    </div>
                    <div class="fw600 textc">
                        第一章 总则
                    </div>
                    <div class="textIndent40">
                        <div>
                            第一条
                            为加快研发项目产业化工作的推进工作，促进公司人才创新创业，规范项目孵化及产业化及相关工作的操作流程，确保公司开展的各类孵化项目顺利完成，引导公司科技项目孵化的健康发展，提升其管理水平与项目孵化能力，以快速推动高新技术成果的产业化。
                        </div>
                        <div>第二条
                            本办法所指的项目孵化相关工作包括公司研发中心科技成果产业化、科技创新项目产业化等工作，是以促进科技成果转化、培育新项目、新技术、高新技术人才和项目管理队伍为宗旨的科技创新创业服务及相关运作（包括市场、载体、技术、资金、人员等要素）。
                        </div>
                    </div>
                    <div class="fw600 textc">
                        第二章 管理机构与职能
                    </div>
                    <div class="textIndent40">
                        <div>第三条 公司内部项目孵化中心相关工作的归口部门为公司总经办。</div>
                        <div>第四条 {{ checkedCompany.companyName }} （简称“公司”）作为公司对外开展有关项目投资、合资、持股等事宜。</div>
                        <div>第五条 总经办在项目孵化相关工作中的职责：</div>
                        <div>1、推动与落实有关项目孵化工作的相关政策和制度；</div>
                        <div>2、负责公司内部孵化中心的管理及资源协调工作；</div>
                        <div>3、发动公司研发人员，积极寻找、筛选、推荐拟孵化项目及合作单位，组织评审拟进入孵化中心的孵化项目《商业计划书》（项目SWOT、商业运作模式、治理结构及投入产出分析）；</div>
                        <div>4、对公司批准进入孵化中心孵化的项目进行必要的资源协调，并对项目的进展情况进行督导，定期向公司领导层汇报各孵化项目进展。</div>
                        <div>5、对出孵项目，落实相关企业注册、人员派驻、办公场地等工作；</div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 2 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>内部孵化中心及实施管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GL-D004</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt80">
                    <div class="textIndent40">
                        <div>
                            6、负责出孵项目企业、合资企业的对口联系工作，跟踪相关财务报表、年报、董事会决议等重要文件的落实情况；
                        </div>
                        <div>7、办理出孵项目相关工作的法律、财务、知识产权等事务工作；</div>
                        <div>
                            8、负责根据项目情况组建项目团队。
                        </div>
                        <div>第六条 项目团队职责：</div>
                        <div>1、拟定项目商业计划书、项目立项报告、项目可行性报告，并根据需要提供项目的相关背景材料；</div>
                        <div>2、根据公司批准的项目孵化方案，负责项目具体工作（含技术成果转移、新产品开发和推广、高新企业建立）的实施，组织管理；</div>
                        <div>3、按照公司相关企业管理制度，合法经营。</div>
                    </div>
                    <div class="fw600 textc">
                        第三章 孵化项目标准
                    </div>
                    <div class="textIndent40">
                        <div>第六条　入孵项目应当满足以下条件：</div>
                        <div>1、属于国家颁布的高新技术领域，符合我国高新技术产业导向；或符合广东省及珠海市产业结构调整及产业导向的技术开发项目能够促进我省经济发展的其他项目。
                        </div>
                        <div>2、项目技术来源清晰，取得合法的知识产权，已申请专利或者采取了适当的保密措施保护或者申请著作权登记等，无知识产权权属纠纷。</div>
                        <div>3、项目技术先进，创新力强，与现有技术相比有明显的创新与进步，有一定的技术进入壁垒。
                        </div>
                        <div>4、市场发展潜力大，预期的经济与社会效益显著。</div>
                        <div>5、项目技术负责人熟悉本技术领域的发展，具有实施转化本项目技术的能力。
                        </div>
                        <div>第七条　公司内部孵化器对入孵项目提供以下服务：</div>
                        <div>1、公司提供基础设施：包含办公场地、商务会务、物业服务等内容。</div>
                        <div>2、由内部孵化中心配套相关的公共资源，包括人才、培训、财务等方面给予全力支持，按项目独立核算原则进行内部核算。</div>
                        <div>第八条　出孵项目应当同时满足以下条件：</div>
                        <div>1、项目技术已成熟，已形成科技成果，进行小试后产品已达到预期标准或要求，投放市场后，已基本得到用户的认可，产品收入并已连续三个月实现10%的收入增长。</div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 3 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>内部孵化中心及实施管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GL-D004</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt50">
                    <div class="textIndent40">
                        <div>
                            2、团队核心人员稳定，科技成果转化已具备产业化条件。
                        </div>
                        <div>3、项目具备独立运作，与公司现有主营业务不产生冲突。出孵后的运营机制或建设方案已得到公司股东会的批准。</div>
                    </div>
                    <div class="fw600 textc">
                        第四章 孵化公司成立流程和步骤
                    </div>
                    <div class="textIndent40">
                        <div>第九条 经过孵化中心孵化成熟后的项目，经公司评审小组进行评审后，达到出孵标准的，按入孵协议，制定出孵后的合同或协议。</div>
                        <div>
                            第十条 项目孵化团队准备好商业计划书或项目可行性报告等材料，提交到总经办进行初步审核，总经办协助、指导项目孵化团队修改、完善商业技术书、项目可行性报告。
                        </div>
                        <div>第十一条 总经办组织研发部、产品部、财务部门等成员、或外部专家对拟孵化项目进行技术和市场评估。
                        </div>
                        <div>第十二条 总经办汇总相关专家意见，根据项目的投资大小提交公司总经理办公会及董事会领导审核、研究。</div>
                        <div>第十三条 通过总经理办公会及董事会批准的项目，由公司与孵化团队签订孵化合同，由总经办协助办理工商、税务、法律、财务等事务。</div>
                        <div>
                            第十四条 出孵化项目原则上由公司控股，但孵化团队持股比例不得低于35%，其中孵化项目负责人持股不得低于10%。
                        </div>
                        <div>第十五条 公司管理层可以对出孵化项目进行跟投，但跟投比例不得高于10%。</div>
                    </div>
                    <div class="fw600 textc">
                        第五章 附 则
                    </div>
                    <div class="textIndent40">
                        <div>第十六条 本管理办法由总经办负责解释及修订。</div>
                        <div>第十七条 本管理办法从发布之日起开始执行。</div>
                    </div>
                    <div class="text-align-right mt50">{{ checkedCompany.companyName }}</div>
                    <div class="text-align-right">
                        <!-- <span v-if="!showSetTime">{{ formData.fileDay | textTime }}</span> -->
                        <el-date-picker v-model="formData.fileDay" class="miniDay w190" value-format="yyyy-MM-dd"
                            format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                        </el-date-picker>(自动转为中文)
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 4 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution933",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                fileDay: "",
            },
            checkedCompany: {}
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        },
        textTime(time) {
            if (time) {
                let dayArr = time.split("-");
                let fileDay = "";
                let dayObj = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
                let tObj = ["年", "月", "日"];
                for (let i in dayArr) {
                    let t = dayArr[i];
                    if (i == 0 || t < 10) {
                        for (let childKey of t) {
                            fileDay += dayObj[childKey];
                        }
                    } else {
                        if (t > 10) {
                            if(t>=20){
                                fileDay += dayObj[String(t).slice(0, 1)]; 
                            }
                            fileDay += "十";
                            if(String(t).slice(1)>0){
                                fileDay += dayObj[String(t).slice(1)];
                            }
                        } else {
                            fileDay += dayObj[t];
                        }
                    }
                    fileDay += tObj[i];
                }
                return fileDay;
            } else {
                return "";
            }
        },
        timeStr(time) {
            if (time) {
                let dayStr = time.replace("-", "年");
                dayStr = dayStr.replace("-", "月");
                return dayStr + "日";
            }
        },
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData.fileDay = data.fileDay;
                    } else {
                        this.formData.fileDay = this.checkedCompany.fileDay;
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
};
</script>

<style lang="scss" scoped>
.frame {
    padding: 0 10px;
    border: 1px solid #000;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemTitle {
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 20px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>