<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发技术人员培训管理制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D003</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent" style="padding-top: 350px;">
                <div class="wordTtile">
                    <div class="flex-c-c textc">
                        <div class="zdhq fs24">研发技术人员培训管理制度</div>
                    </div>
                    <div class="textc pjTitle mt10 mb20 fs35">{{wordData.isAgain}}</div>
                </div>
            </div>
            <div class="wordFoot">
                <div class="textc fs30">
                    {{ checkedCompany.companyName }}
                </div>
                <div class="textc fs25">
                    <!-- <span class="fs30" v-if="!showSetTime">{{ formData.fileDay | textTime }}</span> -->
                    <el-date-picker v-model="formData.fileDay" class="dayInput w230" value-format="yyyy-MM-dd"
                        format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                    </el-date-picker>(自动转为中文)
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 1 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发技术人员培训管理制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D003</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordTtile">
                    <div class="textc pjTitle mt50 mb10 fs30">{{ checkedCompany.companyName }}</div>
                    <div class="flex-c-c textc lineBottom ">
                        <div class="zdhq fs40">研发技术人员培训管理制度</div>
                    </div>
                </div>
                <div class="lineheight45">
                    <div class="fw600">
                        一、总则
                    </div>
                    <div>1、目的</div>
                    <div class="textIndent40">
                        <div>
                            根据公司《培训管理制度》的有关规定，为提升公司专业技术研发人员的专业素质和职业素养，规范和促进研发中心研发工作流程和技术研发水平，提高绩效，提升公司核心竞争力，特制订研发技术人员的培训管理制度。
                        </div>
                    </div>
                    <div>2、适用范围</div>
                    <div class="textIndent40">
                        本制度适用于公司研发中心技术人员。
                    </div>
                    <div>3、管理职责</div>
                    <div>（1）公司研发中心负责统筹研发技术人员培训计划的制订、实施和控制，并负责研发中心内部培训工作。</div>
                    <div>（2）公司人力资源部门协助进行培训需求、培训计划的制订、实施和评估反馈。</div>
                    <div>4、培训内容</div>
                    <div>研发技术人员培训内容包括新员工入职培训、职业素养、专业技术培训、新技术讲座、新技术沙龙等多种形式。</div>
                    <div>5、培训形式</div>
                    <div>（1）根据工作需要，部门内部组织技能培训、上岗培训及专题讲座。</div>
                    <div>（2）人力资源部门根据培训需求，联络有资质的培训机构委外授课。</div>
                    <div class="fw600">
                        二、培训资源管理
                    </div>
                    <div>1、培训档案管理</div>
                    <div class="textIndent40">
                        培训教材包括外部培训教材和内部培训教材，教材的形式可以是书面文字、电子文档、录音、录像等形式，教材由人力资源部门统一管理。
                    </div>
                    <div>4、内部培训教材的获取</div>
                    <div>（1）工作过程中的经验分享与教训总结</div>
                    <div>（2）公司重大事件案例</div>
                    <div>（3）内部培训讲师组织开发的培训教材</div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 2 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发技术人员培训管理制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D003</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45">
                    <div>（4）人力资源部门开发的新员工培训教材</div>
                    <div>5、外部培训教材的引入</div>
                    <div>（1）公司聘请外部机构进行培训的，提供的教材由人力资源部门统一存档。</div>
                    <div>（2）公司员工参加外派公开课程的，应在培训结束后将教材原件或复印件交由人力资源部门归档。</div>
                    <div class="fw600">
                        三、培训经费控制
                    </div>
                    <div>1、公司每年划拨一定经费用于组织培训工作开展。</div>
                    <div>2、培训费用报销范围包括学费、报名费、资料教材费等。</div>
                    <div>3、为了便于管理，因培训所发生的交通、餐饮费用在各部门预算费用中列支，按公司标准报销。</div>
                    <div>4、内部讲师培训采取补贴方式进行，根据课程难易、重要性、内部讲师水平等综合评估，给予内部讲师一定的补贴。</div>
                    <div class="fw600">
                        四、培训计划的制定与实施
                    </div>
                    <div>1、培训需求调查</div>
                    <div>（1）根据公司经营发展目标和研究项目开发进程，在每年向人力资源部门提出培训需求，并填写《年度培训需求调查表》。</div>
                    <div>（2）根据人力资源部门提供的培训信息，征求部门人员个人意见，填写《个人培训需求表》，在规定时间内提交给人力资源部门。</div>
                    <div>2、制定年度培训计划</div>
                    <div class="textIndent40">
                        根据《培训需求表》反馈内容以及外部培训信息，制定《年度培训计划》，报公司总经理审批。
                    </div>
                    <div>3、年度培训计划实施</div>
                    <div>（1）根据《年度培训计划》实施培训工作，按月公布课程安排情况，部门根据培训情况调整工作，让员工有充足时间参加培训。</div>
                    <div>（2）人力资源部门根据培训安排，确认培训人员以及费用预算，做好培训组织工作并控制培训费用。</div>
                    <div>4、计划外培训审批与开展</div>
                    <div class="textIndent40">
                        当有需要临时增加培训项目时，应提前三周向人力资源部门提出培训申请，填写培训需求表，经人力资源部门审核后交总经理批准，批准通过后由人力资源部门实施。
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 3 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发技术人员培训管理制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D003</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt50">
                    <div>5、培训记录</div>
                    <div class="textIndent40">
                        人力资源部门负责对培训过程进行记录，保存培训资料，包括员工培训记录（签到）表、电子文档、录音、录像、PPT等，以此依据建立培训档案。
                    </div>
                    <div class="fw600">
                        五、培训效果评价
                    </div>
                    <div>1、培训效果评价内容</div>
                    <div class="textIndent40">针对本次培训过程评估，包括培训纪律、态度考核、课程安排、培训讲师授课质量等进行评估，由参加培训人员对培训讲师进行评价，填写《培训效果评价表》。
                    </div>
                    <div>2、培训结果</div>
                    <div>（1）公司内部培训，可根据需要对培训结果进行评估，包括试卷考试、实地操作等多种形式。</div>
                    <div>（2）学员须做培训小结，培训成绩与小结将放进员工的人事档案中。</div>
                    <div>3、培训成果运用</div>
                    <div class="textIndent40">培训结束后，学员应将培训中获得的知识技能应用于工作中，部门主管应对学员培训后的工作情况作评估，评估结果列入绩效考核中</div>
                    <div>六、附则</div>
                    <div>1、本制度自颁布之日起开始实施。</div>
                    <div>2、本制度由人力资源部门负责解释。</div>
                    <div class="text-align-right" style="margin-top: 200px;">{{ checkedCompany.companyName }}</div>
                    <div class="text-align-right">
                        <!-- <span v-if="!showSetTime">{{ formData.fileDay | textTime }}</span> -->
                        <el-date-picker v-model="formData.fileDay" class="miniDay w190" value-format="yyyy-MM-dd"
                            format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                        </el-date-picker>(自动转为中文)
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 4 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution9420",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                fileDay: "",
            },
            checkedCompany: {}
        };
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData.fileDay = data.fileDay;
                    } else {
                        this.formData.fileDay = this.checkedCompany.fileDay;
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        changeTime(time) {
            this.formData.fileDay = time;
        }
    },
};
</script>

<style lang="scss" scoped>
.frame {
    padding: 0 10px;
    border: 1px solid #000;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemTitle {
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 20px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>