<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="wordSetContent">
                <div class="modelHeader">
                    <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                        <div class="flex-a-c ">
                            <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.companyName }}</div>
                            <span class="ml20">管理文件>技术人员绩效考核记录表 V1.0</span>
                        </div>
                        <div class="flex-a-c">
                            <span>编号：</span>
                            <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.shortName }}</div>
                            <span>-GO-D001</span>
                        </div>
                    </div>
                    <div class="flex-a-b-c mb10">
                        <div class="squareLeft"></div>
                        <div class="squareRight"></div>
                    </div>
                </div>
                <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
                <div class="pl30 pr30 wordSetContent">
                    <div class="textc pjTitle mt20 mb20 fs40"><el-date-picker v-model="formData.year"
                            class="bigDay w130" value-format="yyyy" format="yyyy" type="year" placeholder="年份">
                        </el-date-picker>年研发人员绩效考核表</div>
                    <div class="text-align-right flex-a-b-c mt50 fs30">
                        <div>
                            姓名:<om-select-user @select="selectUser1" placeholder="姓名" contentType="uwt"
                                :pcId="checkedCompany.pcId" v-model="formData.userName1"></om-select-user>
                        </div>
                        <div>
                            岗位:<el-input type="text" class="printName w150" placeholder="岗位"
                                v-model="formData.position1"></el-input>
                        </div>
                        <div class="w200">
                            考核时段: 半年度
                        </div>
                        <div>
                            编号:<el-input type="text" class="printName w150" placeholder="编号"
                                v-model="formData.number1"></el-input>
                        </div>
                    </div>
                    <table border="1" cellspacing="0" class="tableBorder institutionTable">
                        <tbody>
                            <tr class="h50">
                                <td class="textc itemContent w40">序号</td>
                                <td class="textc itemContent w130">指标及权重</td>
                                <td class="textc itemContent w200">项目</td>
                                <td class="textc itemContent">考核内容</td>
                                <td class="textc itemContent w60">分值</td>
                                <td class="textc itemContent w150">
                                    <div>自评</div>
                                    <div class="bt000">分值/评定依据</div>
                                </td>
                                <td class="textc itemContent w150">
                                    <div>上级评定</div>
                                    <div class="bt000">分值/评定依据</div>
                                </td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">1</td>
                                <td class="textc itemContent" rowspan="3">
                                    <div>项目研发进度</div>
                                    <div>指标权重为</div>
                                    <div>25%</div>
                                </td>
                                <td class="textc itemContent">工作目标与计划制定</td>
                                <td class="itemContent pa5">根据工作任务和上级领导指示，制定工作计划，合理安排优先顺序和工作时间；制订切实可行的、可衡量的和有期限的工作标准
                                </td>
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u1mark1"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">2</td>
                                <td class="textc itemContent">项目过程管理能力</td>
                                <td class="itemContent pa5">能够合理安排负责项目的整体工作计划；掌握重点，综合平衡，保证个体项目符合整体项目的数量、质量要求</td>
                                <td class="textc itemContent">10</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u1mark2"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">3</td>
                                <td class="textc itemContent">项目进度控制</td>
                                <td class="itemContent pa5">能够按项目立项计划进度推进，合理安排项目难点攻关时间并请及时解决相关问题，按时完成开发。</td>
                                <td class="textc itemContent">10</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u1mark3"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">4</td>
                                <td class="textc itemContent" rowspan="2">
                                    <div>工作质量</div>
                                    <div>35%</div>
                                </td>
                                <td class="textc itemContent">项目开发质量</td>
                                <td class="itemContent pa5">能够按照项目技术开发要求及标准、开发规范进行开发，产品质量符合客户要求及公司要求。</td>
                                <td class="textc itemContent">30</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u1mark4"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent">研发项目文档</td>
                                <td class="itemContent pa5">开发有完整、规范的文档，并归档</td>
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u1mark5"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">6</td>
                                <td class="textc itemContent pt30 pb30">
                                    <div>技术及能力提升 15%</div>
                                </td>
                                <td class="textc itemContent">专业技术知识与素质</td>
                                <td class="itemContent pa5">熟练掌握本职工作所需的专业知识；具备技术开发人员所需的专业素质和基本技能，自觉积极学习新知识，新技能</td>
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u1mark6"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="wordPage flex-a-b-c">
                    <div class="squareLeft"></div>
                    <div></div>
                    <div class="squareRight"></div>
                </div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="wordSetContent">
                <div class="modelHeader">
                    <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                        <div class="flex-a-c ">
                            <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.companyName }}</div>
                            <span class="ml20">管理文件>技术人员绩效考核记录表 V1.0</span>
                        </div>
                        <div class="flex-a-c">
                            <span>编号：</span>
                            <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.shortName }}</div>
                            <span>-GO-D001</span>
                        </div>
                    </div>
                    <div class="flex-a-b-c mb10">
                        <div class="squareLeft"></div>
                        <div class="squareRight"></div>
                    </div>
                </div>
                <div class="pl30 pr30 wordSetContent">
                    <table border="1" cellspacing="0" class="tableBorder institutionTable">
                        <tbody>
                            <tr class="h50">
                                <td class="textc itemContent w40">7</td>
                                <td class="textc itemContent w130" rowspan="2"></td>
                                <td class="textc itemContent w200">
                                    <div>专业技术决策能力及</div>
                                    <div>问题解决能力</div>
                                </td>
                                <td class="itemContent pa5">根据技术市场的发展方向、用户的需求以及技术的可实现性进行决策；并能有效地解决技术问题，及时果断地解决突发事件</td>
                                <td class="textc itemContent w60">5</td>
                                <td class="textc itemContent w150"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u1mark7"></el-input> </td>
                                <td class="textc itemContent w150"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">8</td>
                                <td class="textc itemContent">发展潜能</td>
                                <td class="itemContent pa5">具备良好的专业素质，积极钻研、探讨研发领域的最新发展动向，不断提高本人的技术水平与开发</td>
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u1mark8"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">9</td>
                                <td class="textc itemContent">
                                    <div>创新能力权重</div>
                                    <div>为 10%</div>
                                </td>
                                <td class="textc itemContent">创新精神与变革意识</td>
                                <td class="itemContent pa5">是否能够对公司的产品研发、技术设计、专业工作流程、工作品质和企业文化建设等工作方面有改进、变革和创新并带来成效</td>
                                <td class="textc itemContent">10</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u1mark9"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">10</td>
                                <td class="textc itemContent" rowspan="3">
                                    <div>团队协作及工</div>
                                    <div>作态度</div>
                                    <div>15%</div>
                                </td>
                                <td class="textc itemContent">协作精神与沟通技巧</td>
                                <td class="itemContent pa5">能够在公司内部、部门和上下级之间，以及与客户之间进行清晰、有效、完整的专业沟通和技术辅导</td>
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u1mark10"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">11</td>
                                <td class="textc itemContent">服务意识及组织协调</td>
                                <td class="itemContent pa5">懂得技术与市场的存在关系，明确市场服务意识，能够协调处理好部门内部以及技术与市场的工作关系</td>
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u1mark11"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">12</td>
                                <td class="textc itemContent">
                                    <div>工作态度主动性、上</div>
                                    <div>进心、出勤</div>
                                </td>
                                <td class="itemContent pa5">能否严格遵守和执行公司的规章制度，工作积极主动，按时出勤，保障市场销售工作的需要，维护公司利益</td>
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u1mark12"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent pt30 pb30" colspan="2" rowspan="2">总体评价</td>
                                <td class="textc itemContent">自我分值合计及总体评价</td>
                                <td class="textc itemContent" colspan="4">{{ formData.u1mark0 }}</td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">上级分值合计及总体评价</td>
                                <td class="textc itemContent" colspan="4"></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-align-right flex-a-b-c mt50 fs30">
                        <div>
                            被考核人签名：
                        </div>
                        <div>
                            日期：<el-date-picker v-model="formData.printDay" class="miniDay w190"
                                value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </div>
                        <div class="w200">
                            考核人签名：
                        </div>
                        <div>
                            日期：<el-date-picker v-model="formData.printDay" class="miniDay w190"
                                value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <div class="wordPage flex-a-b-c">
                    <div class="squareLeft"></div>
                    <div></div>
                    <div class="squareRight"></div>
                </div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="wordSetContent">
                <div class="modelHeader">
                    <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                        <div class="flex-a-c ">
                            <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.companyName }}</div>
                            <span class="ml20">管理文件>技术人员绩效考核记录表 V1.0</span>
                        </div>
                        <div class="flex-a-c">
                            <span>编号：</span>
                            <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.shortName }}</div>
                            <span>-GO-D001</span>
                        </div>
                    </div>
                    <div class="flex-a-b-c mb10">
                        <div class="squareLeft"></div>
                        <div class="squareRight"></div>
                    </div>
                </div>
                <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
                <div class="pl30 pr30 wordSetContent">
                    <div class="textc pjTitle mt20 mb20 fs40"><el-date-picker v-model="formData.year"
                            class="bigDay w130" value-format="yyyy" format="yyyy" type="year" placeholder="年份">
                        </el-date-picker>年研发人员绩效考核表</div>
                    <div class="text-align-right flex-a-b-c mt50 fs30">
                        <div>
                            姓名:<om-select-user @select="selectUser2" placeholder="姓名" contentType="uwt"
                                :pcId="checkedCompany.pcId" v-model="formData.userName2"></om-select-user>
                        </div>
                        <div>
                            岗位:<el-input type="text" class="printName w150" placeholder="岗位"
                                v-model="formData.position2"></el-input>
                        </div>
                        <div class="w200">
                            考核时段: 半年度
                        </div>
                        <div>
                            编号:<el-input type="text" class="printName w150" placeholder="编号"
                                v-model="formData.number2"></el-input>
                        </div>
                    </div>
                    <table border="1" cellspacing="0" class="tableBorder institutionTable">
                        <tbody>
                            <tr class="h50">
                                <td class="textc itemContent w40">序号</td>
                                <td class="textc itemContent w130">指标及权重</td>
                                <td class="textc itemContent w200">项目</td>
                                <td class="textc itemContent">考核内容</td>
                                <td class="textc itemContent w60">分值</td>
                                <td class="textc itemContent w150">
                                    <div>自评</div>
                                    <div class="bt000">分值/评定依据</div>
                                </td>
                                <td class="textc itemContent w150">
                                    <div>上级评定</div>
                                    <div class="bt000">分值/评定依据</div>
                                </td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">1</td>
                                <td class="textc itemContent" rowspan="3">
                                    <div>项目研发进度</div>
                                    <div>指标权重为</div>
                                    <div>25%</div>
                                </td>
                                <td class="textc itemContent">工作目标与计划制定</td>
                                <td class="itemContent pa5">根据工作任务和上级领导指示，制定工作计划，合理安排优先顺序和工作时间；制订切实可行的、可衡量的和有期限的工作标准
                                </td>
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u2mark1"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">2</td>
                                <td class="textc itemContent">项目过程管理能力</td>
                                <td class="itemContent pa5">能够合理安排负责项目的整体工作计划；掌握重点，综合平衡，保证个体项目符合整体项目的数量、质量要求</td>
                                <td class="textc itemContent">10</td>
                                <td class="textc itemContent">
                                    <el-input type="number" class="printName w140" placeholder="分值"
                                        v-model="formData.u2mark2"></el-input>
                                </td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">3</td>
                                <td class="textc itemContent">项目进度控制</td>
                                <td class="itemContent pa5">能够按项目立项计划进度推进，合理安排项目难点攻关时间并请及时解决相关问题，按时完成开发。</td>
                                <td class="textc itemContent">10</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u2mark3"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">4</td>
                                <td class="textc itemContent" rowspan="2">
                                    <div>工作质量</div>
                                    <div>35%</div>
                                </td>
                                <td class="textc itemContent">项目开发质量</td>
                                <td class="itemContent pa5">能够按照项目技术开发要求及标准、开发规范进行开发，产品质量符合客户要求及公司要求。</td>
                                <td class="textc itemContent">30</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u2mark4"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent">研发项目文档</td>
                                <td class="itemContent pa5">开发有完整、规范的文档，并归档</td>
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u2mark5"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent pt30 pb30">
                                    <div>技术及能力提升 15%</div>
                                </td>
                                <td class="textc itemContent">专业技术知识与素质</td>
                                <td class="itemContent pa5">熟练掌握本职工作所需的专业知识；具备技术开发人员所需的专业素质和基本技能，自觉积极学习新知识，新技能</td>
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u2mark6"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="wordPage flex-a-b-c">
                    <div class="squareLeft"></div>
                    <div></div>
                    <div class="squareRight"></div>
                </div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="wordSetContent">
                <div class="modelHeader">
                    <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                        <div class="flex-a-c ">
                            <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.companyName }}</div>
                            <span class="ml20">管理文件>技术人员绩效考核记录表 V1.0</span>
                        </div>
                        <div class="flex-a-c">
                            <span>编号：</span>
                            <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.shortName }}</div>
                            <span>-GO-D001</span>
                        </div>
                    </div>
                    <div class="flex-a-b-c mb10">
                        <div class="squareLeft"></div>
                        <div class="squareRight"></div>
                    </div>
                </div>
                <div class="pl30 pr30 wordSetContent">
                    <table border="1" cellspacing="0" class="tableBorder institutionTable">
                        <tbody>
                            <tr class="h50">
                                <td class="textc itemContent w40">7</td>
                                <td class="textc itemContent w130" rowspan="2"></td>
                                <td class="textc itemContent w200">
                                    <div>专业技术决策能力及</div>
                                    <div>问题解决能力</div>
                                </td>
                                <td class="itemContent pa5">根据技术市场的发展方向、用户的需求以及技术的可实现性进行决策；并能有效地解决技术问题，及时果断地解决突发事件</td>
                                <td class="textc itemContent w60">5</td>
                                <td class="textc itemContent w150"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u2mark7"></el-input></td>
                                <td class="textc itemContent w150"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">8</td>
                                <td class="textc itemContent">发展潜能</td>
                                <td class="itemContent pa5">具备良好的专业素质，积极钻研、探讨研发领域的最新发展动向，不断提高本人的技术水平与开发</td>
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u2mark8"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">9</td>
                                <td class="textc itemContent">
                                    <div>创新能力权重</div>
                                    <div>为 10%</div>
                                </td>
                                <td class="textc itemContent">创新精神与变革意识</td>
                                <td class="itemContent pa5">是否能够对公司的产品研发、技术设计、专业工作流程、工作品质和企业文化建设等工作方面有改进、变革和创新并带来成效</td>
                                <td class="textc itemContent">10</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u2mark9"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">10</td>
                                <td class="textc itemContent" rowspan="3">
                                    <div>团队协作及工</div>
                                    <div>作态度</div>
                                    <div>15%</div>
                                </td>
                                <td class="textc itemContent">协作精神与沟通技巧</td>
                                <td class="itemContent pa5">能够在公司内部、部门和上下级之间，以及与客户之间进行清晰、有效、完整的专业沟通和技术辅导</td>
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u2mark10"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">11</td>
                                <td class="textc itemContent">服务意识及组织协调</td>
                                <td class="itemContent pa5">懂得技术与市场的存在关系，明确市场服务意识，能够协调处理好部门内部以及技术与市场的工作关系</td>
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u2mark11"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">12</td>
                                <td class="textc itemContent">
                                    <div>工作态度主动性、上</div>
                                    <div>进心、出勤</div>
                                </td>
                                <td class="itemContent pa5">能否严格遵守和执行公司的规章制度，工作积极主动，按时出勤，保障市场销售工作的需要，维护公司利益</td>
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent"><el-input type="number" class="printName w140"
                                        placeholder="分值" v-model="formData.u2mark12"></el-input></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent pt30 pb30" colspan="2" rowspan="2">总体评价</td>
                                <td class="textc itemContent">自我分值合计及总体评价</td>
                                <td class="textc itemContent" colspan="4">{{ formData.u2mark0 }}</td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">上级分值合计及总体评价</td>
                                <td class="textc itemContent" colspan="4"></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-align-right flex-a-b-c mt50 fs30">
                        <div>
                            被考核人签名：
                        </div>
                        <div>
                            日期：<el-date-picker v-model="formData.printDay2" class="miniDay w190"
                                value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </div>
                        <div class="w200">
                            考核人签名：
                        </div>
                        <div>
                            日期：<el-date-picker v-model="formData.printDay2" class="miniDay w190"
                                value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <div class="wordPage flex-a-b-c">
                    <div class="squareLeft"></div>
                    <div></div>
                    <div class="squareRight"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution94311",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            //列表配置
            formData: {
                userName1: "测试用户1",
                position1: "测试岗位1",
                number1: "测试编号1",
                u1mark1: 1,
                u1mark2: 2,
                u1mark3: 3,
                u1mark4: 4,
                u1mark5: 5,
                u1mark6: 6,
                u1mark7: 7,
                u1mark8: 8,
                u1mark9: 9,
                u1mark10: 10,
                u1mark11: 11,
                u1mark12: 12,
                u1mark0: 100,
                printDay: "",
                userName2: "测试用户2",
                position2: "测试岗位2",
                number2: "测试编号2",
                u2mark1: 1,
                u2mark2: 2,
                u2mark3: 3,
                u2mark4: 4,
                u2mark5: 5,
                u2mark6: 6,
                u2mark7: 7,
                u2mark8: 8,
                u2mark9: 9,
                u2mark10: 10,
                u2mark11: 11,
                u2mark12: 12,
                u2mark0: 100,
                printDay2: "",
            },
            checkedCompany: {}
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        }
    },
    watch: {
        formData() {
            let sum1 = 0;
            let sum2 = 0;
            for (let key in this.formData) {
                if (key.indexOf("u1mark") != -1 && key != 'u1mark0') {
                    sum1 += Number(this.formData[key]);
                }
                if (key.indexOf("u2mark") != -1 && key != 'u2mark0') {
                    sum2 += Number(this.formData[key]);
                }
            }
            this.formData['u1mark0'] = sum1;
            this.formData['u2mark0'] = sum2;
        },
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData = {
                            userName1: data.userName1,
                            position1: data.position1,
                            number1: data.number1,
                            u1mark1: data.u1mark1,
                            u1mark2: data.u1mark2,
                            u1mark3: data.u1mark3,
                            u1mark4: data.u1mark4,
                            u1mark5: data.u1mark5,
                            u1mark6: data.u1mark6,
                            u1mark7: data.u1mark7,
                            u1mark8: data.u1mark8,
                            u1mark9: data.u1mark9,
                            u1mark10: data.u1mark10,
                            u1mark11: data.u1mark11,
                            u1mark12: data.u1mark12,
                            u1mark0: data.u1mark0,
                            printDay: data.printDay,
                            userName2: data.userName2,
                            position2: data.position2,
                            number2: data.number2,
                            u2mark1: data.u2mark1,
                            u2mark2: data.u2mark2,
                            u2mark3: data.u2mark3,
                            u2mark4: data.u2mark4,
                            u2mark5: data.u2mark5,
                            u2mark6: data.u2mark6,
                            u2mark7: data.u2mark7,
                            u2mark8: data.u2mark8,
                            u2mark9: data.u2mark9,
                            u2mark10: data.u2mark10,
                            u2mark11: data.u2mark11,
                            u2mark12: data.u2mark12,
                            u2mark0: data.u2mark0,
                            printDay2: data.printDay2,
                            year: data.year?String(data.year):"",
                        }
                    } else {
                        this.formData = {
                            userName1: this.checkedCompany.legalName,
                            position1: "",
                            number1: "",
                            u1mark1: 5,
                            u1mark2: 9,
                            u1mark3: 9,
                            u1mark4: 28,
                            u1mark5: 5,
                            u1mark6: 5,
                            u1mark7: 5,
                            u1mark8: 5,
                            u1mark9: 9,
                            u1mark10: 5,
                            u1mark11: 5,
                            u1mark12: 5,
                            u1mark0: 0,
                            printDay: this.checkedCompany.fileDay,
                            userName2: this.checkedCompany.legalName,
                            position2: "",
                            number2: "",
                            u2mark1: 5,
                            u2mark2: 8,
                            u2mark3: 9,
                            u2mark4: 27,
                            u2mark5: 5,
                            u2mark6: 5,
                            u2mark7: 5,
                            u2mark8: 5,
                            u2mark9: 9,
                            u2mark10: 5,
                            u2mark11: 5,
                            u2mark12: 5,
                            u2mark0: 0,
                            printDay2: this.checkedCompany.fileDay,
                            year: this.yearValue
                        }
                    }
                }
            },
            deep: true,
            immediate: true,
        }
    },
    created() { },
    methods: {
        selectUser1(data) {
            this.formData.userName1 = data.userName;
            this.formData.position1 = data.work;
        },
        selectUser2(data) {
            this.formData.userName2 = data.userName;
            this.formData.position2 = data.work;
        },
    },
};
</script>

<style lang="scss" scoped>
.frame {
    padding: 0 10px;
    border: 1px solid #000;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemContent {
        position: relative;

        .autograph {
            position: absolute;
            right: 10px;
            bottom: 10px;
        }

        min-height: 30px;
        height: 100%;
        font-size: 17px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 17px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
    }
}

.fileDay {
    /deep/input {
        font-size: 17px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1250px;
    min-height: 1000px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1150px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>