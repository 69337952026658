<template>
  <div class="listswitching" :ref="listSwitchName" :style="{ backgroundImage: 'url(' + datas.bgImg + ')' }">
    <!-- 没有视频展示默认 -->
    <!-- 类型一 -->
    <div class="flex-a-b-c" v-if="datas.commoditylisttype === 0" :sytle="{
      'padding-left': datas.pageMargin + 'px',
      'padding-right': datas.pageMargin + 'px'
    }">
      <h4 class="pTitle">{{ datas.name }}</h4>
      <div class="more" v-show="datas.showMore" :style="{ 'margin-right': datas.pageMargin + 'px' }">
        更多<span class="iconYQH iconyou"></span>
      </div>
    </div>
    <div v-if="datas.addMethod === 'auto'">
      <section v-show="!datas.autoData || !datas.autoData[0] && datas.commoditylisttype === 0" :class="[
        datas.commodityType === 5 ? 'defaultcommodityList5' : '',
        datas.commodityType === 1 ? 'defaultcommodityListFlex' : '',
        datas.showType == 1 ? 'yqWrap' : '',
      ]" class="defaultcommodity" :style="{
  'padding-top': datas.commodityMargin + 'px',
  'padding-left': datas.pageMargin + 'px',
  'padding-right': datas.pageMargin + 'px',
}">
        <div v-for="index in datas.dataNumber" :key="index + 'b'" class="defaultcommodityList" :class="[
          datas.commodityType === 0 ? 'defaultcommodityList0' : '',
          datas.commodityType === 1 ? 'defaultcommodityList1' : '',
          datas.commodityType === 2 ? 'defaultcommodityList2' : '',
          datas.commodityType === 3 ? 'defaultcommodityList3' : '',
          datas.commodityType === 4 ? 'defaultcommodityList4' : '',
          datas.commodityType === 5 ? 'defaultcommodityList5' : '',
        ]" :style="{
  border: datas.moditystyle === 2 ? '1px solid rgba(50,50,51,0.1)' : '',
  'box-shadow':
    datas.moditystyle === 1 ? '0 2px 8px rgba(93,113,127,0.08)' : '',
  margin: datas.commodityMargin + 'px',
  width:
    datas.commodityType === 1
      ? 50 - datas.commodityMargin / 6 + '%'
      : datas.commodityType === 2
        ? 33 - datas.commodityMargin / 5 + '%'
        : datas.commodityType === 4
          ? 50 - datas.commodityMargin / 5 + '%'
          : '',
  'border-radius': datas.borderRadius + 'px',
}">
          <!-- 视频图片 -->
          <div class="bg-pic">
            <!-- <img draggable="false" :src="imgs" alt="" /> -->
            <el-image :src="imgs" style="width:100%;height:100%;" fit="cover"></el-image>
            <!-- 标签 -->
            <van-tag mark :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 0">新品</van-tag>
            <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 1">热卖</van-tag>
            <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 2">NEW</van-tag>
            <van-tag plain :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 3">HOT</van-tag>
          </div>

          <!-- 文字内容 -->
          <div class="text" :style="{
            background: datas.moditystyle !== 3 ? '#fff' : 'none',
            // 'border-bottom-left-radius': datas.borderRadius + 'px',
            // 'border-bottom-right-radius': datas.borderRadius + 'px',
          }">
            <!-- 视频名称 -->
            <h5 :style="{
              'font-weight': datas.textWeight,
              'text-align': datas.positions === 'center' ? 'center' : '',
            }">
              这里显示商品名称，最多显示1行
            </h5>
            <!-- 视频描述 -->
            <p :style="{
              'text-align': datas.positions === 'center' ? 'center' : '',
            }">
              <!-- 这里显示商品描述，最多显示1行 -->
            </p>
            <div class="mony">
              <span>
                <span v-show="datas.priceofcommodity">
                  <i class="m">￥</i>99.00
                </span>
              </span>
              <div v-show="datas.purchasebutton">
                <van-icon name="cart-circle-o" class="yqCartCircleO" size="23" v-show="datas.purchasebuttonType === 0" />
                <van-icon name="add-o" size="23" v-show="datas.purchasebuttonType === 1" />
                <van-icon name="add" size="23" v-show="datas.purchasebuttonType === 2" />
                <van-icon name="cart-o" class="yqCartO" size="23" v-show="datas.purchasebuttonType === 3" />
                <em v-show="datas.purchasebuttonType === 4">{{
                  datas.purchase
                }}</em>
                <em v-show="datas.purchasebuttonType === 5">{{
                  datas.purchase
                }}</em>
                <em v-show="datas.purchasebuttonType === 6">{{
                  datas.purchase
                }}</em>
                <em v-show="datas.purchasebuttonType === 7">{{
                  datas.purchase
                }}</em>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-c-c dropPage" v-if="datas.commodityType != 5 && datas.commoditylisttype != 1 && datas.isPage">
          下拉加载更多
        </div>
      </section>

      <section v-show="datas.autoData && datas.autoData[0] && datas.commoditylisttype === 0" :class="[
        datas.commodityType === 5 ? 'defaultcommodityList5' : '',
        datas.commodityType === 1 ? 'defaultcommodityListFlex' : '',
        datas.showType == 1 ? 'yqWrap' : '',
      ]" class="defaultcommodity" :style="{
  'padding-top': datas.commodityMargin + 'px',
  'padding-left': datas.pageMargin + 'px',
  'padding-right': datas.pageMargin + 'px',
}">
        <div v-for="(item, index) in datas.autoData" :key="index" class="defaultcommodityList" :class="[
          datas.commodityType === 0 ? 'defaultcommodityList0' : '',
          datas.commodityType === 1 ? 'defaultcommodityList1' : '',
          datas.commodityType === 2 ? 'defaultcommodityList2' : '',
          datas.commodityType === 3 ? 'defaultcommodityList3' : '',
          datas.commodityType === 4 ? 'defaultcommodityList4' : '',
          datas.commodityType === 5 ? 'defaultcommodityList5' : '',
        ]" :style="{
  border: datas.moditystyle === 2 ? '1px solid rgba(50,50,51,0.1)' : '',
  'box-shadow':
    datas.moditystyle === 1 ? '0 2px 8px rgba(93,113,127,0.08)' : '',
  margin: datas.commodityMargin + 'px',
  width:
    datas.commodityType === 1
      ? 50 - datas.commodityMargin / 6 + '%'
      : datas.commodityType === 2
        ? 33 - datas.commodityMargin / 5 + '%'
        : datas.commodityType === 4
          ? 50 - datas.commodityMargin / 5 + '%'
          : '',
  'border-radius': datas.borderRadius + 'px',
}">
          <!-- 视频图片 -->
          <div class="bg-pic">
            <!-- <img draggable="false" :src="item.coverUrl" alt="" /> -->
            <el-image :src="item.coverUrl" style="width:100%;height:100%;" fit="cover"></el-image>
            <!-- 标签 -->
            <van-tag mark :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 0">新品</van-tag>
            <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 1">热卖</van-tag>
            <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 2">NEW</van-tag>
            <van-tag plain :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 3">HOT</van-tag>
          </div>

          <!-- 文字内容 -->
          <div class="text" :style="{
            background: datas.moditystyle !== 3 ? '#fff' : 'none',
            // 'border-bottom-left-radius': datas.borderRadius + 'px',
            // 'border-bottom-right-radius': datas.borderRadius + 'px',
          }">
            <!-- 视频名称 -->
            <h5 :style="{
              'font-weight': datas.textWeight,
              'text-align': datas.positions === 'center' ? 'center' : '',
            }">
              {{ item.name }}
            </h5>
            <!-- 视频描述 -->
            <p :style="{
              'text-align': datas.positions === 'center' ? 'center' : '',
            }">
              <!-- {{ item.introduce }} -->
            </p>
            <div class="mony" v-if="datas.priceofcommodity || datas.purchasebutton">
              <span>
                <span v-show="datas.priceofcommodity">
                  <i class="m">￥</i>
                  {{ item.price.toFixed(2) }}
                </span>
              </span>
              <div v-show="datas.purchasebutton">
                <van-icon name="cart-circle-o" class="yqCartCircleO" size="23" v-show="datas.purchasebuttonType === 0" />
                <van-icon name="add-o" size="23" v-show="datas.purchasebuttonType === 1" />
                <van-icon name="add" size="23" v-show="datas.purchasebuttonType === 2" />
                <van-icon name="cart-o" class="yqCartO" size="23" v-show="datas.purchasebuttonType === 3" />
                <em v-show="datas.purchasebuttonType === 4">{{
                  datas.purchase
                }}</em>
                <em v-show="datas.purchasebuttonType === 5">{{
                  datas.purchase
                }}</em>
                <em v-show="datas.purchasebuttonType === 6">{{
                  datas.purchase
                }}</em>
                <em v-show="datas.purchasebuttonType === 7">{{
                  datas.purchase
                }}</em>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-c-c dropPage" v-if="datas.commodityType != 5 && datas.commoditylisttype != 1 && datas.isPage">
          下拉加载更多
        </div>
      </section>

      <!-- 类型二 -->
      <div class="tab-type2">
        <van-tabs v-model="active1" v-show="datas.commoditylisttype === 1" animated swipeable :color="datas.tabColor">
          <van-tab :name="index" :title="item.text" v-for="(item, index) in datas.commoditylisttypetab" :key="index"
            :style="{
              'font-size': item.wordSize + 'px',
              'font-weight': item.wordWeight,
            }">
            <section v-show="!item.autoData[0] && datas.commoditylisttype === 1"
              :class="[datas.commodityType === 5 ? 'defaultcommodityList5' : '']" class="defaultcommodity" :style="{
                'padding-top': datas.commodityMargin + 'px',
                'padding-left': datas.pageMargin + 'px',
                'padding-right': datas.pageMargin + 'px',
              }">
              <div v-for="index in 4" :key="index" class="defaultcommodityList" :class="[
                datas.commodityType === 0 ? 'defaultcommodityList0' : '',
                datas.commodityType === 1 ? 'defaultcommodityList1' : '',
                datas.commodityType === 2 ? 'defaultcommodityList2' : '',
                datas.commodityType === 3 ? 'defaultcommodityList3' : '',
                datas.commodityType === 4 ? 'defaultcommodityList4' : '',
                datas.commodityType === 5 ? 'defaultcommodityList5' : '',
              ]" :style="{
  border:
    datas.moditystyle === 2 ? '1px solid rgba(50,50,51,0.1)' : '',
  'box-shadow':
    datas.moditystyle === 1
      ? '0 2px 8px rgba(93,113,127,0.08)'
      : '',
  margin: datas.commodityMargin + 'px',
  width:
    datas.commodityType === 1
      ? 50 - datas.commodityMargin / 6 + '%'
      : datas.commodityType === 2
        ? 33 - datas.commodityMargin / 5 + '%'
        : datas.commodityType === 4
          ? 50 - datas.commodityMargin / 5 + '%'
          : '',
  'border-radius': datas.borderRadius + 'px',
}">
                <!-- 视频图片 -->
                <div class="bg-pic">
                  <!-- <img draggable="false" :src="imgs" alt="" /> -->
                  <el-image :src="imgs" style="width:100%;height:100%;" fit="cover"></el-image>
                  <!-- 标签 -->
                  <van-tag mark :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 0
                    ">新品</van-tag>
                  <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 1
                    ">热卖</van-tag>
                  <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 2
                    ">NEW</van-tag>
                  <van-tag plain :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 3
                    ">HOT</van-tag>
                </div>

                <!-- 文字内容 -->
                <div class="text" :style="{
                  background: datas.moditystyle !== 3 ? '#fff' : 'none',
                  // 'border-bottom-left-radius': datas.borderRadius + 'px',
                  // 'border-bottom-right-radius': datas.borderRadius + 'px',
                }">
                  <!-- 视频名称 -->
                  <h5 :style="{
                    'font-weight': datas.textWeight,
                    'text-align': datas.positions === 'center' ? 'center' : '',
                  }">
                    这里显示商品名称，最多显示1行
                  </h5>
                  <!-- 视频描述 -->
                  <p :style="{
                    'text-align': datas.positions === 'center' ? 'center' : '',
                  }">
                    <!-- 这里显示商品描述，最多显示1行 -->
                  </p>
                  <div class="mony">
                    <span>
                      <span v-show="datas.priceofcommodity">
                        <i class="m">￥</i>99.00
                      </span>
                    </span>
                    <div v-show="datas.purchasebutton">
                      <van-icon name="cart-circle-o" class="yqCartCircleO" size="23"
                        v-show="datas.purchasebuttonType === 0" />
                      <van-icon name="add-o" size="23" v-show="datas.purchasebuttonType === 1" />
                      <van-icon name="add" size="23" v-show="datas.purchasebuttonType === 2" />
                      <van-icon name="cart-o" class="yqCartO" size="23" v-show="datas.purchasebuttonType === 3" />
                      <em v-show="datas.purchasebuttonType === 4">{{
                        datas.purchase
                      }}</em>
                      <em v-show="datas.purchasebuttonType === 5">{{
                        datas.purchase
                      }}</em>
                      <em v-show="datas.purchasebuttonType === 6">{{
                        datas.purchase
                      }}</em>
                      <em v-show="datas.purchasebuttonType === 7">{{
                        datas.purchase
                      }}</em>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section v-show="item.autoData[0] && datas.commoditylisttype === 1"
              :class="[datas.commodityType === 5 ? 'defaultcommodityList5' : '']" class="defaultcommodity" :style="{
                'padding-top': datas.commodityMargin + 'px',
                'padding-left': datas.pageMargin + 'px',
                'padding-right': datas.pageMargin + 'px',
              }">
              <div v-for="(item, index) in item.autoData" :key="index" class="defaultcommodityList" :class="[
                datas.commodityType === 0 ? 'defaultcommodityList0' : '',
                datas.commodityType === 1 ? 'defaultcommodityList1' : '',
                datas.commodityType === 2 ? 'defaultcommodityList2' : '',
                datas.commodityType === 3 ? 'defaultcommodityList3' : '',
                datas.commodityType === 4 ? 'defaultcommodityList4' : '',
                datas.commodityType === 5 ? 'defaultcommodityList5' : '',
              ]" :style="{
  border:
    datas.moditystyle === 2 ? '1px solid rgba(50,50,51,0.1)' : '',
  'box-shadow':
    datas.moditystyle === 1
      ? '0 2px 8px rgba(93,113,127,0.08)'
      : '',
  margin: datas.commodityMargin + 'px',
  width:
    datas.commodityType === 1
      ? 50 - datas.commodityMargin / 6 + '%'
      : datas.commodityType === 2
        ? 33 - datas.commodityMargin / 5 + '%'
        : datas.commodityType === 4
          ? 50 - datas.commodityMargin / 5 + '%'
          : '',
  'border-radius': datas.borderRadius + 'px',
}">
                <!-- 视频图片 -->
                <div class="bg-pic">
                  <!-- <img draggable="false" :src="item.coverUrl" alt="" /> -->
                  <el-image :src="item.coverUrl" style="width:100%;height:100%;" fit="cover"></el-image>
                  <!-- 标签 -->
                  <van-tag mark :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 0
                    ">新品</van-tag>
                  <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 1
                    ">热卖</van-tag>
                  <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 2
                    ">NEW</van-tag>
                  <van-tag plain :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 3
                    ">HOT</van-tag>
                </div>

                <!-- 文字内容 -->
                <div class="text" :style="{
                  background: datas.moditystyle !== 3 ? '#fff' : 'none',
                  // 'border-bottom-left-radius': datas.borderRadius + 'px',
                  // 'border-bottom-right-radius': datas.borderRadius + 'px',
                }">
                  <!-- 视频名称 -->
                  <h5 :style="{
                    'font-weight': datas.textWeight,
                    'text-align': datas.positions === 'center' ? 'center' : '',
                  }">
                    {{ item.name }}
                  </h5>
                  <!-- 视频描述 -->
                  <p :style="{
                    'text-align': datas.positions === 'center' ? 'center' : '',
                  }">
                    <!-- {{ item.introduce }} -->
                  </p>
                  <div class="mony" v-if="datas.priceofcommodity || datas.purchasebutton">
                    <span>
                      <span v-show="datas.priceofcommodity">
                        <i class="m">￥</i>
                        {{ item.price.toFixed(2) }}
                      </span>
                    </span>
                    <div v-show="datas.purchasebutton">
                      <van-icon name="cart-circle-o" class="yqCartCircleO" size="23"
                        v-show="datas.purchasebuttonType === 0" />
                      <van-icon name="add-o" size="23" v-show="datas.purchasebuttonType === 1" />
                      <van-icon name="add" size="23" v-show="datas.purchasebuttonType === 2" />
                      <van-icon name="cart-o" class="yqCartO" size="23" v-show="datas.purchasebuttonType === 3" />
                      <em v-show="datas.purchasebuttonType === 4">{{
                        datas.purchase
                      }}</em>
                      <em v-show="datas.purchasebuttonType === 5">{{
                        datas.purchase
                      }}</em>
                      <em v-show="datas.purchasebuttonType === 6">{{
                        datas.purchase
                      }}</em>
                      <em v-show="datas.purchasebuttonType === 7">{{
                        datas.purchase
                      }}</em>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div v-if="datas.addMethod === 'manual'">
      <section v-show="!datas.manualData[0] && datas.commoditylisttype === 0" :class="[
        datas.commodityType === 5 ? 'defaultcommodityList5' : '',
        datas.commodityType === 1 ? 'defaultcommodityListFlex' : '',
        datas.showType == 1 ? 'yqWrap' : '',
      ]" class="defaultcommodity" :style="{
  'padding-top': datas.commodityMargin + 'px',
  'padding-left': datas.pageMargin + 'px',
  'padding-right': datas.pageMargin + 'px',
}">
        <div v-for="index in 4" :key="index" class="defaultcommodityList" :class="[
          datas.commodityType === 0 ? 'defaultcommodityList0' : '',
          datas.commodityType === 1 ? 'defaultcommodityList1' : '',
          datas.commodityType === 2 ? 'defaultcommodityList2' : '',
          datas.commodityType === 3 ? 'defaultcommodityList3' : '',
          datas.commodityType === 4 ? 'defaultcommodityList4' : '',
          datas.commodityType === 5 ? 'defaultcommodityList5' : '',
        ]" :style="{
  border: datas.moditystyle === 2 ? '1px solid rgba(50,50,51,0.1)' : '',
  'box-shadow':
    datas.moditystyle === 1 ? '0 2px 8px rgba(93,113,127,0.08)' : '',
  margin: datas.commodityMargin + 'px',
  width:
    datas.commodityType === 1
      ? 50 - datas.commodityMargin / 6 + '%'
      : datas.commodityType === 2
        ? 33 - datas.commodityMargin / 5 + '%'
        : datas.commodityType === 4
          ? 50 - datas.commodityMargin / 5 + '%'
          : '',
  'border-radius': datas.borderRadius + 'px',
}">
          <!-- 视频图片 -->
          <div class="bg-pic">
            <!-- <img draggable="false" :src="imgs" alt="" /> -->
            <el-image :src="imgs" style="width:100%;height:100%;" fit="cover"></el-image>
            <!-- 标签 -->
            <van-tag mark :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 0">新品</van-tag>
            <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 1">热卖</van-tag>
            <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 2">NEW</van-tag>
            <van-tag plain :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 3">HOT</van-tag>
          </div>

          <!-- 文字内容 -->
          <div class="text" :style="{
            background: datas.moditystyle !== 3 ? '#fff' : 'none',
            // 'border-bottom-left-radius': datas.borderRadius + 'px',
            // 'border-bottom-right-radius': datas.borderRadius + 'px',
          }">
            <!-- 视频名称 -->
            <h5 :style="{
              'font-weight': datas.textWeight,
              'text-align': datas.positions === 'center' ? 'center' : '',
            }">
              这里显示商品名称，最多显示1行
            </h5>
            <!-- 视频描述 -->
            <p :style="{
              'text-align': datas.positions === 'center' ? 'center' : '',
            }">
              <!-- 这里显示商品描述，最多显示1行 -->
            </p>
            <div class="mony">
              <span>
                <span v-show="datas.priceofcommodity">
                  <i class="m">￥</i>99.00
                </span>
              </span>
              <div v-show="datas.purchasebutton">
                <van-icon name="cart-circle-o" class="yqCartCircleO" size="23" v-show="datas.purchasebuttonType === 0" />
                <van-icon name="add-o" size="23" v-show="datas.purchasebuttonType === 1" />
                <van-icon name="add" size="23" v-show="datas.purchasebuttonType === 2" />
                <van-icon name="cart-o" class="yqCartO" size="23" v-show="datas.purchasebuttonType === 3" />
                <em v-show="datas.purchasebuttonType === 4">{{
                  datas.purchase
                }}</em>
                <em v-show="datas.purchasebuttonType === 5">{{
                  datas.purchase
                }}</em>
                <em v-show="datas.purchasebuttonType === 6">{{
                  datas.purchase
                }}</em>
                <em v-show="datas.purchasebuttonType === 7">{{
                  datas.purchase
                }}</em>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-show="datas.manualData[0] && datas.commoditylisttype === 0" :class="[
        datas.commodityType === 5 ? 'defaultcommodityList5' : '',
        datas.commodityType === 1 ? 'defaultcommodityListFlex' : '',
        datas.showType == 1 ? 'yqWrap' : '',
      ]" class="defaultcommodity" :style="{
  'padding-top': datas.commodityMargin + 'px',
  'padding-left': datas.pageMargin + 'px',
  'padding-right': datas.pageMargin + 'px',
}">
        <div v-for="(item, index) in datas.manualData" :key="index" class="defaultcommodityList" :class="[
          datas.commodityType === 0 ? 'defaultcommodityList0' : '',
          datas.commodityType === 1 ? 'defaultcommodityList1' : '',
          datas.commodityType === 2 ? 'defaultcommodityList2' : '',
          datas.commodityType === 3 ? 'defaultcommodityList3' : '',
          datas.commodityType === 4 ? 'defaultcommodityList4' : '',
          datas.commodityType === 5 ? 'defaultcommodityList5' : '',
        ]" :style="{
  border: datas.moditystyle === 2 ? '1px solid rgba(50,50,51,0.1)' : '',
  'box-shadow':
    datas.moditystyle === 1 ? '0 2px 8px rgba(93,113,127,0.08)' : '',
  margin: datas.commodityMargin + 'px',
  width:
    datas.commodityType === 1
      ? 50 - datas.commodityMargin / 6 + '%'
      : datas.commodityType === 2
        ? 33 - datas.commodityMargin / 5 + '%'
        : datas.commodityType === 4
          ? 50 - datas.commodityMargin / 5 + '%'
          : '',
  'border-radius': datas.borderRadius + 'px',
}">
          <!-- 视频图片 -->
          <div class="bg-pic">
            <!-- <img draggable="false" :src="item.coverUrl" alt="" /> -->
            <el-image :src="item.coverUrl" style="width:100%;height:100%;" fit="cover"></el-image>
            <!-- 标签 -->
            <van-tag mark :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 0">新品</van-tag>
            <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 1">热卖</van-tag>
            <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 2">NEW</van-tag>
            <van-tag plain :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks"
              v-show="datas.commoditycorner && datas.commoditycornertype === 3">HOT</van-tag>
          </div>

          <!-- 文字内容 -->
          <div class="text" :style="{
            background: datas.moditystyle !== 3 ? '#fff' : 'none',
            // 'border-bottom-left-radius': datas.borderRadius + 'px',
            // 'border-bottom-right-radius': datas.borderRadius + 'px',
          }">
            <!-- 视频名称 -->
            <h5 :style="{
              'font-weight': datas.textWeight,
              'text-align': datas.positions === 'center' ? 'center' : '',
            }">
              {{ item.name }}
            </h5>
            <!-- 视频描述 -->
            <p :style="{
              'text-align': datas.positions === 'center' ? 'center' : '',
            }">
              <!-- {{ item.introduce }} -->
            </p>
            <div class="mony" v-if="datas.priceofcommodity || datas.purchasebutton">
              <span>
                <span v-show="datas.priceofcommodity">
                  <i class="m">￥</i>
                  {{ (item.price).toFixed(2) }}
                </span>
              </span>
              <div v-show="datas.purchasebutton">
                <van-icon name="cart-circle-o" class="yqCartCircleO" size="23" v-show="datas.purchasebuttonType === 0" />
                <van-icon name="add-o" size="23" v-show="datas.purchasebuttonType === 1" />
                <van-icon name="add" size="23" v-show="datas.purchasebuttonType === 2" />
                <van-icon name="cart-o" class="yqCartO" size="23" v-show="datas.purchasebuttonType === 3" />
                <em v-show="datas.purchasebuttonType === 4">{{
                  datas.purchase
                }}</em>
                <em v-show="datas.purchasebuttonType === 5">{{
                  datas.purchase
                }}</em>
                <em v-show="datas.purchasebuttonType === 6">{{
                  datas.purchase
                }}</em>
                <em v-show="datas.purchasebuttonType === 7">{{
                  datas.purchase
                }}</em>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- 类型二 -->

      <!-- 类型三 -->

    </div>
    <!-- 类型三 -->

    <div>
      <van-tree-select height="auto" :style="{ '--bgColor': datas.tabColor }" :items="datas.commoditylisttypetab"
        :main-active-index.sync="active" v-show="datas.commoditylisttype === 2" class="type3" @click-nav="treeSelect">
        <template #content>
          <div>
            <van-swipe v-if="datas.bannerData && datas.bannerData.length > 0" class="my-swipe mt10" :autoplay="3000"
              indicator-color="#5785e7" :show-indicators="false" :height="datas.imgHeight + 'px'">
              <van-swipe-item v-for="(row, index) in datas.bannerData" :key="index">
                <img :src="row.imageUrl" alt="" draggable="false" />
              </van-swipe-item>
            </van-swipe>
          </div>
          <div v-for="(item, index) in datas.commoditylisttypetab" :key="index">
            <section v-if="active === index && !item.autoData[0]" class="defaultcommodity ml10 mr10" :style="{
              'padding-top': datas.commodityMargin + 'px',
              'padding-left': datas.pageMargin + 'px',
              'padding-right': datas.pageMargin + 'px',
            }">
              <div v-for="index in 4" :key="index" class="defaultcommodityList defaultcommodityList3" :style="{
                border:
                  datas.moditystyle === 2
                    ? '1px solid rgba(50,50,51,0.1)'
                    : '',
                'box-shadow':
                  datas.moditystyle === 1
                    ? '0 2px 8px rgba(93,113,127,0.08)'
                    : '',
                margin: datas.commodityMargin + 'px'
              }">
                <div class="bg-pic">
                  <!-- <img draggable="false" :src="imgs" alt="" /> -->
                  <el-image :src="imgs" style="width:100%;height:100%;" fit="cover"></el-image>
                  <van-tag mark :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 0
                    ">新品</van-tag>
                  <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 1
                    ">热卖</van-tag>
                  <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 2
                    ">NEW</van-tag>
                  <van-tag plain :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 3
                    ">HOT</van-tag>
                </div>

                <div class="text" :style="{
                  background: datas.moditystyle !== 3 ? '#fff' : 'none',
                  // 'border-bottom-left-radius': datas.borderRadius + 'px',
                  // 'border-bottom-right-radius': datas.borderRadius + 'px',
                }">
                  <h5 :style="{
                    'font-weight': datas.textWeight,
                    'text-align':
                      datas.positions === 'center' ? 'center' : '',
                  }">
                    这里显示商品名称，最多显示1行
                  </h5>
                  <p :style="{
                    'text-align':
                      datas.positions === 'center' ? 'center' : '',
                  }">
                  </p>
                  <div class="mony">
                    <span>
                      <span v-show="datas.priceofcommodity">
                        <i class="m">￥</i>99.00
                      </span>
                    </span>
                    <div v-show="datas.purchasebutton">
                      <van-icon name="cart-circle-o" class="yqCartCircleO" size="23"
                        v-show="datas.purchasebuttonType === 0" />
                      <van-icon name="add-o" size="23" v-show="datas.purchasebuttonType === 1" />
                      <van-icon name="add" size="23" v-show="datas.purchasebuttonType === 2" />
                      <van-icon name="cart-o" class="yqCartO" size="23" v-show="datas.purchasebuttonType === 3" />
                      <em v-show="datas.purchasebuttonType === 4">{{
                        datas.purchase
                      }}</em>
                      <em v-show="datas.purchasebuttonType === 5">{{
                        datas.purchase
                      }}</em>
                      <em v-show="datas.purchasebuttonType === 6">{{
                        datas.purchase
                      }}</em>
                      <em v-show="datas.purchasebuttonType === 7">{{
                        datas.purchase
                      }}</em>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section v-if="active === index && item.autoData[0]" class="defaultcommodity ml10 mr10" :style="{
              'padding-top': datas.commodityMargin + 'px',
              'padding-left': datas.pageMargin + 'px',
              'padding-right': datas.pageMargin + 'px',
            }">
              <div v-for="(item, index) in item.autoData" :key="index" class="defaultcommodityList defaultcommodityList3"
                :style="{
                  border:
                    datas.moditystyle === 2
                      ? '1px solid rgba(50,50,51,0.1)'
                      : '',
                  'box-shadow':
                    datas.moditystyle === 1
                      ? '0 2px 8px rgba(93,113,127,0.08)'
                      : '',
                  margin: datas.commodityMargin + 'px',
                  'border-radius': datas.borderRadius + 'px',
                }">
                <!-- 视频图片 -->
                <div class="bg-pic">
                  <!-- <img draggable="false" :src="item.coverUrl" alt="" /> -->
                  <el-image :src="item.coverUrl" style="width:100%;height:100%;" fit="cover"></el-image>
                  <!-- 标签 -->
                  <van-tag mark :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 0
                    ">新品</van-tag>
                  <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 1
                    ">热卖</van-tag>
                  <van-tag :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 2
                    ">NEW</van-tag>
                  <van-tag plain :color="datas.commodityTagColor || tagColor" :style="styleString" class="marks" v-show="datas.commoditycorner && datas.commoditycornertype === 3
                    ">HOT</van-tag>
                </div>

                <!-- 文字内容 -->
                <div class="text" :style="{
                  background: datas.moditystyle !== 3 ? '#fff' : 'none',
                  // 'border-bottom-left-radius': datas.borderRadius + 'px',
                  // 'border-bottom-right-radius': datas.borderRadius + 'px',
                }">
                  <!-- 视频名称 -->
                  <h5 :style="{
                    'font-weight': datas.textWeight,
                    'text-align':
                      datas.positions === 'center' ? 'center' : '',
                  }">
                    {{ item.name }}
                  </h5>
                  <!-- 视频描述 -->
                  <p :style="{
                    'text-align':
                      datas.positions === 'center' ? 'center' : '',
                  }">
                    <!-- {{ item.introduce }} -->
                  </p>
                  <div class="mony" v-if="datas.priceofcommodity || datas.purchasebutton">
                    <span>
                      <span v-show="datas.priceofcommodity">
                        <i class="m">￥</i>
                        {{ item.price.toFixed(2) }}
                      </span>
                    </span>
                    <div v-show="datas.purchasebutton">
                      <van-icon name="cart-circle-o" class="yqCartCircleO" size="23"
                        v-show="datas.purchasebuttonType === 0" />
                      <van-icon name="add-o" size="23" v-show="datas.purchasebuttonType === 1" />
                      <van-icon name="add" size="23" v-show="datas.purchasebuttonType === 2" />
                      <van-icon name="cart-o" class="yqCartO" size="23" v-show="datas.purchasebuttonType === 3" />
                      <em v-show="datas.purchasebuttonType === 4">{{
                        datas.purchase
                      }}</em>
                      <em v-show="datas.purchasebuttonType === 5">{{
                        datas.purchase
                      }}</em>
                      <em v-show="datas.purchasebuttonType === 6">{{
                        datas.purchase
                      }}</em>
                      <em v-show="datas.purchasebuttonType === 7">{{
                        datas.purchase
                      }}</em>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </template>
      </van-tree-select>
    </div>

    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
export default {
  name: 'listswitching',
  props: {
    datas: Object,
  },
  data() {
    return {
      imgs: require('@/pages/miniProgram/addAppModel/images/imgs.png'),
      active1: 0,
      active: 0,
      listSwitchName: 'listswitchingWeb',
      tagColor: '#07c160',
      styleString: {},
      productGroupData: this.$store.state.productGroupData,
    }
  },
  mounted() {
    this.$nextTick(() => {
      // this.listSwitchName =
      //   'listswitching' + document.querySelectorAll('.listswitching').length
      if (this.$refs[this.listSwitchName] && this.$refs[this.listSwitchName].querySelector(
          '.van-sidebar-item--select'
        )) {
        this.$refs[this.listSwitchName].querySelector(
          '.van-sidebar-item--select'
        ).style.color = this.datas.tabColor;
      }
      this.tagPositionStyle()
    })
  },
  methods: {
    // 这里有个bug  当出现多个商品组件且同为类型3时  点击某一个商品组件会无法匹配 .van-sidebar-item 个数
    treeSelect(index) {
      setTimeout(() => {
        this.$refs[this.listSwitchName]
          .querySelectorAll('.van-sidebar-item')
          .forEach((item, i) => {
            if (i !== index) {
              item.style.color = '#323233'
            }
          })
        this.$refs[this.listSwitchName].querySelector(
          '.van-sidebar-item--select'
        ).style.color = this.datas.tabColor
      })
    },

    // 处理标记位置
    tagPositionStyle() {
      this.styleString = {
        position: 'absolute',
      }
      // if(!this.datas.tagPosition) {
      //   this.styleString.top = '5px'
      //   this.styleString.left = '0px'
      //   this.datas.commoditycornertype === 0 ?  this.styleString.borderRadius = '10px 0px 0px 10px !important' : ''
      //   return
      // }
      /* '左上',
        '左下',
        '又上',
        '又下' */
      switch (this.datas.tagPosition) {
        case 0: // 左上
          this.styleString.top = '5px'
          this.styleString.left = '0px'
          break
        case 1: // 左下
          this.styleString.bottom = '5px'
          this.styleString.left = '0px'
          break
        case 2: // 右上
          this.styleString.top = '5px'
          this.styleString.right = '0px'
          this.datas.commoditycornertype === 0
            ? (this.styleString.borderRadius = '10px 0px 0px 10px !important')
            : ''
          break
        case 3: // 右下
          this.styleString.bottom = '5px'
          this.styleString.right = '0px'
          this.datas.commoditycornertype === 0
            ? (this.styleString.borderRadius = '10px 0px 0px 10px !important')
            : ''
          break
      }
    },
  },
  watch: {
    'datas.tabColor': function () {
      this.$refs[this.listSwitchName].querySelector(
        '.van-sidebar-item--select'
      ).style.color = this.datas.tabColor
    },
    'datas.commoditylisttype': function () {
      if (this.datas.commoditylisttype === 2) {
        this.$refs[this.listSwitchName].querySelector(
          '.van-sidebar-item--select'
        ).style.color = this.datas.tabColor
      }
    },
    'datas.tagPosition': function () {
      this.tagPositionStyle()
    },
    'datas.commoditycornertype': function () {
      this.tagPositionStyle()
    },
  },
}
</script>

<style scoped lang="scss">
.van-sidebar-item--select::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 4px;
  height: 16px;
  background-color: var(--bgColor);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}

.dropPage {
  height: 30px;
  position: relative;
  width: 100%;
}

.my-swipe {
  height: 120px;
  margin: 0 10px;
  border-radius: 5px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
  }
}

.listswitching {
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 10px;

  .pTitle {
    font-size: 16px;
    color: #323233;
    font-weight: 400;
    width: 270px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .more {
    height: 25px;
    line-height: 25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: #999;
    cursor: pointer;
  }
  .defaultcommodityList5.yqWrap {
    overflow: auto !important;
    display: flex;
    flex-wrap: wrap !important;
  }
  .yqWrap .defaultcommodityList5{
    margin:10px !important;
    margin-right:0px !important;
  }
  .yqWrap .defaultcommodityList5:nth-child(3n+1) {
    margin-left: 0 !important;
  }
  /* 默认商品 */
  .defaultcommodity {
    box-sizing: border-box;

    &.defaultcommodityList5 {
      overflow-x: scroll;
      display: flex;
      flex-wrap: nowrap;

      /* 滚动条 */
      &::-webkit-scrollbar {
        height: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #5785e7;
      }

      /deep/.el-collapse-item__header,
      /deep/.el-collapse-item__wrap {
        border-bottom: 0 !important;
      }
    }

    .bg-pic {
      width: 100%;
      height: 240px;
      position: relative;
    }

    .defaultcommodityList {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      overflow: hidden;

      // border: 1px solid #fff;
      &.defaultcommodityList0 {
        width: 100%;
        // height: 340px;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;

        &:nth-of-type(1) {
          margin-top: 0 !important;
        }

        .bg-pic {
          width: 100%;
          height: 240px;
        }
      }

      &.defaultcommodityList1 {
        // border: none !important;
        margin-top: 0 !important;

        &:nth-of-type(even) {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        &:nth-of-type(odd) {
          margin-left: 0 !important;
        }

        .bg-pic {
          width: 100%;
          height: 150px;
          min-height: 150px;
        }
      }

      &.defaultcommodityList2 {
        max-width: 33%;
        margin-top: 0 !important;
        margin-left: 0 !important;

        &:nth-of-type(3n) {
          margin-right: 0 !important;
        }

        .text {
          h5 {
            font-size: 12px !important;
          }

          p {
            font-size: 10px !important;
          }

          .mony {
            span {
              font-size: 12px !important;

              i {
                font-size: 12px !important;
              }
            }

            i {
              font-size: 14px !important;
            }

            em {
              font-size: 5px !important;
              padding: 1px 5px !important;
            }
          }
        }

        .bg-pic {
          width: 100%;
          height: 90px;
        }
      }

      &.defaultcommodityList3 {
        width: 100%;
        flex-direction: row !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        height: 105px;
        display: flex;

        .bg-pic {
          width: 40%;
          height: 100%;
          // float: left;
        }

        .text {
          width: 60%;
          // float: right;
        }

        .mony {
          margin-top: 10px;
        }
      }

      &.defaultcommodityList4 {
        &:nth-of-type(3n-2) {
          width: 100% !important;
          margin-left: 0px !important;
          margin-right: 0px !important;

          .bg-pic {
            width: 100%;
            height: 240px;
          }
        }

        &:nth-of-type(3n-1) {
          margin-left: 0px !important;

          .bg-pic {
            width: 100%;
            height: 150px;
          }
        }

        &:nth-of-type(3n) {
          margin-left: 0px !important;
          margin-right: 0px !important;

          .bg-pic {
            width: 100%;
            height: 150px;
          }
        }
      }

      &.defaultcommodityList5 {
        width: calc(33% - 6px);
        min-width: calc(33% - 6px);
        // height: 160px;
        margin-top: 0 !important;
        margin-right: 0px !important;

        &:first-of-type {
          margin-left: 0px !important;
        }

        .text {
          h5 {
            font-size: 12px !important;
          }

          p {
            font-size: 10px !important;
          }

          .mony {
            span {
              font-size: 12px !important;

              i {
                font-size: 12px !important;
              }
            }

            i {
              font-size: 14px !important;
            }

            em {
              font-size: 5px !important;
              padding: 1px 5px !important;
            }
          }
        }

        .bg-pic {
          width: 100%;
          height: 110px;
          min-height: 110px;
        }
      }

      &.defaultcommodityList3:last-child {
        margin-bottom: 0 !important;
      }

      /* 标签 */
      // .marks {
      //   &:nth-of-type(1) {
      //     position: absolute;
      //     top: 5px;
      //     right: 0;
      //   }
      //   &:nth-of-type(2) {
      //     position: absolute;
      //     top: 10px;
      //     display: flex;
      //     right: 5px;
      //   }
      //   &:nth-of-type(3) {
      //     position: absolute;
      //     top: 5px;
      //     right: 5px;
      //   }
      //   &:nth-of-type(4) {
      //     position: absolute;
      //     top: 5px;
      //     right: 5px;
      //   }
      // }
      // /deep/.van-tag--mark{
      //   border-radius: 10px 0px 0px 10px !important;
      //   padding-right: 3px;
      //   padding-left: 8px;
      // }
      img {
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
      }

      /* 文字 */
      .text {
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        height: 100%;

        /* 商品名称 */
        h5 {
          font-size: 14px;
          line-height: 20px;
          margin: 0 0 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        /* 商品描述 */
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 16px;
          color: #999;
          font-size: 12px;
          line-height: 16px;
        }

        /* 视频价格 */
        .mony {
          // height: 44px;
          height: 24px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 16px;
            color: #ff2626;

            i {
              font-size: 12px;
            }
          }

          i {
            font-size: 16px;
            line-height: 24px;
            color: #5785e7;
          }

          .yqCartO,
          .yqCartCircleO {
            color: #fe7546;
          }

          .m {
            color: #ff2626;
          }

          em {
            font-style: normal;
            padding: 2px 7px;
            font-size: 12px;
            border: 1px solid #5785e7;

            &:nth-of-type(1) {
              border-radius: 10px;
              color: #5785e7;
            }

            &:nth-of-type(2) {
              background-color: #5785e7;
              border-radius: 10px;
              color: #fff;
            }

            &:nth-of-type(3) {
              color: #5785e7;
            }

            &:nth-of-type(4) {
              background-color: #5785e7;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .defaultcommodityListFlex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .tab-type2 {
    /deep/.van-tabs__wrap {
      min-height: 44px;
    }

    /deep/.van-tabs__line {
      height: 2px;
    }
  }

  .type3 {
    /deep/.van-tree-select__nav {
      overflow-x: hidden;
      white-space: nowrap;
    }

    /deep/.van-tree-select__content {
      flex: 3.4 !important;

      .defaultcommodityList3 {
        height: 84px !important;

        .bg-pic {
          height: 84px !important;
          overflow: hidden;
        }
      }

      .text {
        width: 150px;

        h5 {
          font-size: 12px !important;
        }

        p {
          font-size: 10px !important;
        }

        .mony {

          // margin-top:20px;
          span {
            font-size: 12px !important;

            i {
              font-size: 12px !important;
            }
          }

          i {
            font-size: 14px !important;
          }

          em {
            font-size: 5px !important;
            padding: 1px 5px !important;
          }
        }
      }
    }

    /deep/.van-sidebar-item {
      font-size: 16px;
    }
  }
}
</style>
