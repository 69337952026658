<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
    <div class="sendArticle flex-c-c" @click="showModelFun()" v-if="articleNumber">
      <i class="el-icon-s-promotion"></i>
      <div class="articleNumber flex-c-c">{{ articleNumber }}</div>
    </div>
    <el-dialog title="公众号文章推送" class="sendArticleModel" @close="closeModelFun" :destroy-on-close="true"
      :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="showModel" width="90%">
      <div class="d-flex" v-loading="loading">
        <div class="showArticleList pa10">
          <h4 class="mb20">拖拽调整文章位置</h4>
          <vuedraggable v-model="sendArticleData" v-bind="dragOptions">
            <transition-group>
              <div class="articleList" draggable="true" v-for="(child, key) in sendArticleData" :key="key+'a'">
                <div class="articleMaxPic" v-if="key == 0" :style="{ 'background-image': 'url(' + child.img + ')' }">
                  <div class="articleMaxTitle">{{ child.title }}</div>
                </div>
                <div class="articleItem flex-a-b-c" v-else>
                  <div class="articleTitle text-overflow2">{{ child.title }}</div>
                  <el-image class="articlePic" :src="child.img"></el-image>
                </div>
              </div>
            </transition-group>
          </vuedraggable>
        </div>
        <div class="articleTable">
          <div style="margin-left: auto;margin-bottom:24px ;">
            <el-button type="danger" @click="closeSendArticleData"
              :disabled="sendArticleData.length == 0">清空数据</el-button>
            <el-button type="success" @click="addArticleList" :disabled="sendArticleData.length == 0">保存素材</el-button>
            <el-button type="primary" @click="sendPublish"
              :disabled="!mediaIds[checkedAppid] || sendArticleData.length == 0">发布文章</el-button>
            <el-button type="primary" @click="sendPreview"
              :disabled="!mediaIds[checkedAppid] || sendArticleData.length == 0">推送预览</el-button>
            <el-button type="primary" @click="sendAll"
              :disabled="!mediaIds[checkedAppid] || sendArticleData.length == 0">群发文章</el-button>
          </div>
          <el-collapse class="mCollapse" v-model="activeGZH">
            <el-collapse-item name="1">
              <template slot="title">
                <i class="cred mr5 ml10">*</i> 请选择公众号
              </template>
              <h4 class="flex-c-c" v-if="accountList.length == 0">请配置公众号</h4>
              <div class="flex-a-c pall10 flex-wrap-y" v-else>
                <div v-for="(row, index) in accountList" :key="index+'b'" class="flex-a-a-c accountList"
                  :class="{ 'on': checkedAppid == row.appId }" @click="checkedAccount(row)">
                  <el-image class="companyLogo" :src="userInfo.companyLogo"></el-image>
                  <div class="appName text-overflow">
                    {{ row.appName }}
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
          <commonTable :tableData="sendArticleData" :loading="false" :paginationVisible="false">
            <template v-slot:table>
              <el-table-column prop="title" align="center" label="标题" show-overflow-tooltip />
              <el-table-column prop="type" align="center" width="120" label="文章来源" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ optionTypeData[scope.row.optionType] }}
                </template>
              </el-table-column>
              <el-table-column align="center" width="80" label="操作">
                <template slot-scope="scope">
                  <customPopconfirm confirm-button-text="确定" cancel-button-text="取消" @confirm="delArticle(scope.row)"
                    icon="el-icon-info" icon-color="red" title="确定要继续删除这条文章？">
                    <el-button type="text" slot="reference" style="color: #F56C6C">
                      <div class="el-icon-delete-solid"></div>
                    </el-button>
                  </customPopconfirm>
                </template>
              </el-table-column>
            </template>
          </commonTable>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import { uploadDynamicMessage, publishNew, sendAll } from "@/api/authorization";
import { getOfficialAccountList } from "@/api/wechat";
import vuedraggable from 'vuedraggable' //拖拽组件
export default {
  name: "App",
  components: {
    commonTable,
    customPopconfirm,
    vuedraggable
  },
  provide() {
    return {
      reload: this.reload,
    }
  },
  data() {
    return {
      optionTypeData: {//文章类型
        1: "公司文章",
        2: "社团文章"
      },
      dragOptions: {
        animation: 200,
        // class是 delDragitem 禁止拖拽
      },
      isRouterAlive: true,
      articleNumber: 0,//文章数量
      sendArticleData: [],//公众号文章
      showModel: false,
      userInfo: this.$store.state.loginRoot.userInfo,
      accountList: [],//公众号
      checkedAppid: 0,//选中的公众号
      activeGZH: "1",
      loading: false,
      mediaIds: {}
    }
  },
  computed: {
    watchArticleData() {
      return this.$store.state.sendArticleData
    }
  },
  watch: {
    /**@method  */
    sendArticleData() {
      this.$store.commit("setSendArticleData", this.sendArticleData)
    },
    watchArticleData(data) {//监听文章
      this.articleNumber = data ? data.length : 0;
      this.sendArticleData = data;
    }
  },
  created() {
    this.sendArticleData = JSON.parse(sessionStorage.getItem("sendArticleData")) || [];
    this.articleNumber = this.sendArticleData.length;
    document.title = '园企汇智能crm';
  },
  methods: {
    closeModelFun() {
      let vModel = document.querySelector(".sendArticleModel+.v-modal")
      if (vModel) {
        vModel.remove()
      }
    },
    showModelFun() {
      this.showModel = true;
      this.getOfficialAccountList();
    },
    /**@method 选中公众号 */
    checkedAccount(row) {
      this.checkedAppid = row.appId;
    },
    /**@method 获取公众号 */
    getOfficialAccountList() {
      getOfficialAccountList({ isPage: false }).then(res => {
        if (res.code === 200) {
          if (res.data) {
            this.accountList = res.data;
            this.checkedAppid = res.data[0].appId;
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch(err => {
        if (err.code === 201) {
          this.$message.error(err.message);
        }
      })
    },
    /**@method 保存素材 */
    addArticleList() {
      if (this.checkedAppid) {
        let dynamicMessageList = [];
        for (let row of this.sendArticleData) {
          dynamicMessageList.push({
            ids: row.id,
            optionType: row.optionType
          });
        }
        let params = {
          dynamicMessageList: dynamicMessageList,
          appIds: this.checkedAppid,
          publish: false
        };
        this.loading = true;
        uploadDynamicMessage(params).then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.mediaIds[this.checkedAppid] = res.data.mediaId
            this.$message({
              type: "success",
              message: "保存成功"
            })
          } else {
            this.$message.error(res.message);
          }
        }).catch(err => {
          this.loading = false;
          if (err.code === 201) {
            this.$message.error(res.message);
          }
        })
      } else {
        this.$message({
          type: "warning",
          message: "请选择保存素材的公众号!"
        })
      }
    },
    /**@method 群发 */
    sendAll() {
      this.$confirm('每天只能群发一次, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: "info",
          message: "群发中..."
        })
        let params = {
          mediaId: this.mediaIds[this.checkedAppid],
          authorizerAppid: this.checkedAppid
        }
        sendAll(params).then(res => {//删除草稿箱文章
          if (res.code === 0) {
            this.$message({
              type: 'success',
              message: "群发成功,等公众号管理员通过"
            })
          } else {
            this.$message.error(this.gzhErrcode[res.code]);
          }
        }).catch(err => {
          this.$message.error(this.gzhErrcode[err.code]);
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    /**@method 发送预览 */
    sendPreview() {
      this.$prompt('请输入微信号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: "可使用 “,” 分隔，推送多个预览"
      }).then(({ value }) => {
        this.$message({
          type: "info",
          message: "推送中..."
        })
        let params = {
          publish: false,
          mediaId: this.mediaIds[this.checkedAppid],
          towxname: value,
          authorizerAppid: this.checkedAppid
        }
        publishNew(params).then(res => {//删除草稿箱文章
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: res.data
            })
          } else {
            this.$message.error(this.gzhErrcode[res.code]);
          }
        }).catch(err => {
          this.$message.error(this.gzhErrcode[err.code]);
        })
      }).catch((err) => {
       //console.log(err);
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    /**@method 发布 */
    sendPublish() {
      this.$message({
        type: "info",
        message: "发布中..."
      })
      let params = {
        mediaId: this.mediaIds[this.checkedAppid],
        authorizerAppid: this.checkedAppid
      }
      publishNew(params).then(res => {//删除草稿箱文章
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: "发布成功"
          })
        } else {
          this.$message.error(this.gzhErrcode[res.code]);
        }
      }).catch(err => {
        this.$message.error(this.gzhErrcode[err.code]);
      })
    },
    /**@method 清空文章 */
    closeSendArticleData() {
      this.$store.commit("closeSendArticleData");
      this.mediaIds = {};
    },
    /**@method 删除文章 */
    delArticle(data) {
      this.$store.commit("delSendArticleData", data)
      this.mediaIds = {};
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
};
</script>
<style>
.ql-editor {
    height: 500px;
}
</style>
<style lang="scss" scoped>
.accountList {
  width: 150px;
  height: 70px;
  padding: 10px;
  border: 2px solid #bebebe;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;

  .companyLogo {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  .appName {
    width: calc(100% - 70px);
    text-align: center;
  }
}

.accountList.on {
  border: 2px solid #51CBCD;
}

.showArticleList {
  width: calc(30% - 10px);
  margin-right: 20px;
  background: #f5f7f9;
}

.articleTable {
  width: calc(70% - 10px);
}

.mCollapse {
  border-right: 1px solid #EBEEF5;
  border-left: 1px solid #EBEEF5;
}

.sendArticle:hover {
  background-color: #F2F6FC;
}

.sendArticle {
  position: fixed;
  right: 40px;
  bottom: 90px;
  border-radius: 50%;
  box-shadow: 0 0 6px rgba(0, 0, 0, .12);
  z-index: 999;
  height: 40px;
  width: 40px;
  background: #fff;
  cursor: pointer;

  .el-icon-s-promotion {
    font-size: 23px;
    color: #51CDCB;
  }

  .articleNumber {
    font-size: 12px;
    position: absolute;
    padding: 0 5px;
    height: 16px;
    border-radius: 40px;
    color: #fff;
    background: #F56C6C;
    left: 28px;
    top: -4px;
  }
}

.articleList {
  background: #fff;
  cursor: all-scroll;

  .articleMaxPic {
    position: relative;
    width: 100%;
    height: 180px;
    background-size: 100% 100%;

    .articleMaxTitle {
      position: absolute;
      width: 100%;
      bottom: 0;
      color: #fff;
      padding: 10px;
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
    }
  }

  .articleItem {
    padding: 10px;
    color: #000;
    border-bottom: 1px solid #eee;

    .articlePic {
      height: 60px;
      width: 60px;
    }

    .articleTitle {
      width: calc(100% - 80px);
    }
  }
}
</style>
