<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>高技术人才引进管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-D001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent" style="padding-top: 350px;">
                <div class="wordTtile">
                    <div class="flex-c-c textc">
                        <div class="zdhq fs24">高技术人才引进管理办法</div>
                    </div>
                    <div class="textc pjTitle mt10 mb20 fs35">{{wordData.isAgain}}</div>
                </div>
            </div>
            <div class="wordFoot">
                <div class="textc fs30">
                    {{ checkedCompany.companyName }}
                </div>
                <div class="textc fs25">
                    <!-- <span class="fs30" v-if="!showSetTime">{{ formData.fileDay | textTime }}</span> -->
                    <el-date-picker v-model="formData.fileDay" class="dayInput w230" value-format="yyyy-MM-dd"
                        format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                    </el-date-picker>(自动转为中文)
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 1 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>高技术人才引进管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-D001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordTtile">
                    <div class="textc pjTitle mt50 mb10 fs30">{{ checkedCompany.companyName }}</div>
                    <div class="flex-c-c textc lineBottom ">
                        <div class="zdhq fs40">高技术人才引进管理办法</div>
                    </div>
                </div>
                <div class="lineheight45">
                    <div class="textIndent40">
                        <div class="fw600">
                            第一章 总则
                        </div>
                        <div>
                            1.1 为落实{{ checkedCompany.companyName }} 人才发展战略，做好人才梯队建设，进一步优化人力资源结构，实现企业的长远发展目标，制定本办法。
                        </div>
                        <div>
                            1.2 人才引进工作要适应{{ checkedCompany.companyName }}
                            的发展战略要求，坚持培养与引进并举，不断增加人才总量，优化人才结构，提高人才素质，引进高技术、高端管理人才，为企业持续、健康发展提供人才保障。
                        </div>
                        <div>
                            1.3 本办法适用于{{ checkedCompany.companyName }} 。
                        </div>
                        <br>
                        <div class="fw600">
                            第二章 人才引进的原则
                        </div>
                        <div>2.1 坚持公开、公平原则；</div>
                        <div>2.2 坚持全面考核、德才兼备、保证质量。</div>
                        <br>
                        <div class="fw600">
                            第三章 引进人才的对象
                        </div>
                        <div>3.1 具有硕士及以上学位或取得高级以上专业技术职称的高级人才；</div>
                        <div>3.2 以“211”院校为主体的专业对口的大学毕业生；</div>
                        <div>3.3 具有专业特长、满足{{ checkedCompany.companyName }} 发展需要的各类骨干人才，特别是软件系统开发、数据技术、互联网+、大数据方面高端人才。
                        </div>
                        <br>
                        <div class="fw600">
                            第四章 引进人才的形式
                        </div>
                        <div>4.1 所需人才可采取录用、调动、兼职、咨询、科研和技术合作、技术入股等方式加以引进和聘用。</div>
                        <div>4.2
                            高级人才可按照“不求所有、但求所用”、“不求所在、但求所为”的柔性引进机制，以智力引进、智力借入、业余兼职、临时聘请、技术合作项目或承担研究课题等方式，灵活多样的超常规、创造性地开展人才引进工作。
                        </div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 2 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>高技术人才引进管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-D001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45">
                    <div class="textIndent40">
                        <div class="fw600">
                            第五章 人才引进的基本途径
                        </div>
                        <div>5.1 市场猎取；</div>
                        <div>5.2 面向社会公开招聘；</div>
                        <div>5.3 校园招聘；</div>
                        <div>5.4 业务合作：以不触动人事关系为前提，邀请行业专家在企业急需人才的时候，使企业获得人才突破并实现知识传递；</div>
                        <div>5.5 特聘：凡符合公司引进人才条件，暂时不能办理调动手续，而本人愿意来公司工作的人员，可特聘来公司工作；</div>
                        <div>5.6
                            提前聘用：对符合公司引进高级技术人才条件的部分专业在读博士研究生，毕业后愿意到公司工作的，公司可提前与其签订有关协议，鼓励其在读期间积极参与公司的建设与科研活动，并可提前3个月享受有关的工资待遇和科研优惠待遇；
                        </div>
                        <div>5.7 兼职聘用(互聘)：坚持“关系不变、来去自由、流动服务、按绩付酬”的原则，聘用兼职高级技术人才，如聘用重点院校的骨干导师到公司兼职从事科研工作。</div>
                        <br>
                        <div class="fw600">
                            第六章 人才引进的程序
                        </div>
                        <div>6.1  高级人才：</div>
                        <div>6.1.1  被引进者如实向公司人力资源部门提供本人有关材料，包括简历、工作业绩、获奖证书、最高学历学位和职称证书原件、复印件及家庭情况、工作要求和设想、本人联系电话和通讯地址等；</div>
                        <div>6.1.2  公司人力资源部门及相关部门对拟引进的人才进行面试和外调、考察；</div>
                        <div>6.1.3  公司人力资源部门组织相关部门领导对拟引进目标的学历学位、专业背景和发展潜力等条件进行综合评议；</div>
                        <div>6.1.4  部门提出引进人才使用、待遇、管理等方面具体的意见，经人力资源部门审核、主管领导审查后，提交总经理办公会议讨论决定；</div>
                        <div>6.1.5  在双方协商的基础上，签订《协议书》(含聘约期协议)，由人力资源部门统一办理相关手续。</div>
                        <div>6.2  市场猎取和面向社会公开招聘的专业人才，按6.1之规定执行。</div>
                        <div>6.3  校园招聘人才：</div>
                        <div>6.3.1 人力资源部门根据公司发展规划和企业实际制定年度招聘计划，明确毕业生需求数量、专业、院校、性别及工作岗位意向；</div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 3 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>高技术人才引进管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-D001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt50">
                    <div class="textIndent40">
                        <div>
                            6.3.2 公司组织各部门参与各相关院校举办的校园招聘会，对学生进行面试和考察，查阅本人有关材料，包括简历、学习成绩、毕业证书、学位证书、获奖证书等原件、复印件及家庭情况、工作要求和设想、本人联系电话和通讯地址等；
                        </div>
                        <div>
                            6.3.3 人力资源部门提出录用意见，经主管领导审查后，报总经理审定；
                        </div>
                        <div>
                            6.3.4 双方在协商的基础上，签订《就业协议书》，由人力资源部门统一办理相关手续。
                        </div>
                        <br>
                        <div class="fw600">
                            第七章 人才管理与待遇
                        </div>
                        <div>7.1  引进的高级技术人员采用岗位聘用制，按照责、权、利一致的原则签订工作协议，约定岗位职责、待遇和聘任期限等相关事宜，实行合约管理；</div>
                        <div>7.2  引进的高级人才人力资源部门统一管理、统一调配，其薪酬及福利待遇由双方谈判确定。</div>
                        <div>7.3  专业人才实行项目制管理或岗位制管理，其薪酬比照公司内同类人员确定，根据人才能力及水平，采取一才一策制，保证留住人才、发挥人才的主观能动性，让公司平台成为人才的创新创业舞台。</div>
                        <div>7.4  引进人才如签约期未到而提出或要求终止协议(含调离、辞职、自动离职和解聘等)，应按《协议书》规定承担违约责任。</div>
                        <br>
                        <div class="fw600">
                            第八章 附 则
                        </div>
                        <div>8.1  本办法自颁布之日起生效。</div>
                        <div>8.2  本办法由公司人力资源部负责解释。</div>
                    </div>
                    <div class="text-align-right" style="margin-top: 100px;">{{ checkedCompany.companyName }}</div>
                    <div class="text-align-right">
                        <!-- <span v-if="!showSetTime">{{ formData.fileDay | textTime }}</span> -->
                        <el-date-picker v-model="formData.fileDay" class="miniDay w190" value-format="yyyy-MM-dd"
                            format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                        </el-date-picker>(自动转为中文)
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 4 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution941",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                fileDay: "",
            },
            checkedCompany:{},
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        },
        textTime(time) {
            if (time) {
                let dayArr = time.split("-");
                let fileDay = "";
                let dayObj = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
                let tObj = ["年", "月", "日"];
                for (let i in dayArr) {
                    let t = dayArr[i];
                    if (i == 0 || t < 10) {
                        for (let childKey of t) {
                            fileDay += dayObj[childKey];
                        }
                    } else {
                        if (t > 10) {
                            if(t>=20){
                                fileDay += dayObj[String(t).slice(0, 1)]; 
                            }
                            fileDay += "十";
                            if(String(t).slice(1)>0){
                                fileDay += dayObj[String(t).slice(1)];
                            }
                        } else {
                            fileDay += dayObj[t];
                        }
                    }
                    fileDay += tObj[i];
                }
                return fileDay;
            } else {
                return "";
            }
        },
        timeStr(time) {
            if (time) {
                let dayStr = time.replace("-", "年");
                dayStr = dayStr.replace("-", "月");
                return dayStr + "日";
            }
        },
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany=data.companyData;
                    if(data.rulesId){
                        this.formData.fileDay = data.fileDay;
                    }else{
                        this.formData.fileDay = this.checkedCompany.fileDay;
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
};
</script>

<style lang="scss" scoped>
.frame {
    padding: 0 10px;
    border: 1px solid #000;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemTitle {
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 20px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>