<template>
  <div class="graphicnavigationstyle">
    <!-- 标题 -->
    <h2>{{ datas.text }}</h2>

    <!-- 表单 -->
    <el-form label-width="90px" :model="datas" size="small">
      <!-- 导航标题 -->
      <el-form-item class="lef" label="导航标题">
        <div class="weiz flex-a-b-c">
          <div style="width: 196px">
            <el-input v-if="datas.showTitle" v-model="datas.title" placeholder="请输入标题"></el-input>
          </div>
          <el-checkbox v-model="datas.showTitle" />
        </div>
      </el-form-item>
      <div style="height: 10px" />
      <!-- 分享码 -->
      <el-form-item class="lef" label="分享码">
        <div class="weiz flex-a-b-c">
          <div style="width: 196px" class="text-align-left">
            {{ datas.showEwm ? "显示" : "隐藏" }}
          </div>
          <el-checkbox v-model="datas.showEwm" />
        </div>
      </el-form-item>
      <div style="height: 10px" />
      <!-- 是否显示更多 -->
      <el-form-item class="lef" label="更多">
        <div class="weiz flex-a-b-c ">
          <div style="width: 196px" class="text-align-left">
            {{ datas.showMore ? '显示' : '隐藏' }}
          </div>
          <el-checkbox v-model="datas.showMore" />
        </div>
      </el-form-item>
      <div style="height: 10px" />
      <!-- 边框倒角 -->
      <el-form-item label="边框倒角" class="lef borrediu">
        <el-slider v-model="datas.borderRadius" :max="30" input-size="mini" show-input>
        </el-slider>
      </el-form-item>

      <div style="height: 10px" />

      <!-- 文字高度 -->
      <!-- <el-form-item label="文字高度" class="lef">
        <el-slider v-model="datas.textHeight" :max="50" :min="12" input-size="mini" show-input>
        </el-slider>
      </el-form-item> -->

      <div style="height: 10px" />

      <!-- 文字大小 -->
      <el-form-item label="文字大小" prop="textSize" :hide-required-asterisk="true" class="lef">
        <el-input type="number" v-model.number="datas.textSize" placeholder="请输入文字大小" :maxlength="2" />
      </el-form-item>

      <div style="height: 10px" />

      <!-- <el-form-item class="lef" label="背景图片">
        <div class="shop-head-pic" style="text-align: center">
          <img class="home-bg" :src="datas.bgImg" alt="" v-if="datas.bgImg" />
          <div class="shop-head-pic-btn" style="text-align: center">
            <el-button @click="showUpload('1')" class="uploadImg" type="primary" plain><i class="el-icon-plus" />更换图片
            </el-button>
            <el-button type="primary" class="uploadImg" @click="clear()">清空图片</el-button>
          </div>
        </div>
      </el-form-item>
      <div style="height: 10px" /> -->
      <div style="height: 10px" />
      <el-form-item label="边框阴影" class="lef">
        <div class="flex-a-b-c">
          {{ datas.isShadow ? '显示' : '隐藏' }}
          <el-checkbox v-model="datas.isShadow" />
        </div>
      </el-form-item>
      <!-- 背景颜色 -->
      <el-form-item class="lef" label="背景颜色">
        <!-- 颜色选择器 -->
        <el-color-picker v-model="datas.backgroundColor" show-alpha class="picke" :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>

      <div style="height: 10px" />

      <!-- 文字颜色 -->
      <el-form-item class="lef" label="文字颜色">
        <!-- 颜色选择器 -->
        <el-color-picker v-model="datas.textColor" show-alpha class="picke" :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>

      <div style="height: 10px" />

      <!-- 分享码颜色 -->
      <!-- <el-form-item class="lef" label="分享码颜色">
        <el-color-picker v-model="datas.btnColor" show-alpha class="picke" :predefine="predefineColors">
        </el-color-picker>
      </el-form-item> -->


    </el-form>

  </div>
</template>

<script>

export default {
  name: 'crmDistributorRecordstyle',
  props: {
    datas: Object,
  },
  data() {
    return {
      setImgIndex: 0,
      dragOptions: {
        //拖拽配置
        animation: 200,
      },
      predefineColors: [
        // 颜色选择器预设
        "#fff",
        "#000",
        "#3064ba",
        '#51CDCB',
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        '#409EFF',
        '#909399',
        '#C0C4CC',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577',
      ],
      emptyText: '',
      uploadImgDataType: null,
    }
  },
  created() {
  },
  methods: {
    /**@method 链接路径 */
    moreJumpLinkChange(data) {
      if (data.type) {
        let { urlType, type, id, title } = data;
        this.datas.moreHttp = {
          urlType,
          type,
          id,
          title
        }
      } else {
        this.datas.moreHttp = {};
      }
    },
    showUpload(type, index) {
      this.uploadImgDataType = type
      if (type === '1') {
        this.$refs.upload.showUpload()
      } else if (type === '2') {
        this.$refs.imgUpload.showUpload()
        this.setImgIndex = index;
      }

    },
    // 提交
    uploadInformation(res) {
      this.datas.bgImg = res
    },

    // 清空背景图片
    clear() {
      this.datas.bgImg = ''
    },
  }
}
</script>

<style scoped lang="scss">
.graphicnavigationstyle {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 10px 20px;
  box-sizing: border-box;

  /* 标题 */
  h2 {
    padding: 24px 16px 24px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #f2f4f6;
    font-size: 18px;
    font-weight: 600;
    color: #323233;
  }

  .lef {
    /deep/.el-form-item__label {
      text-align: left;
    }
  }

  /* 上传图片按钮 */
  .uploadImg {
    width: 345px;
    height: 40px;
    margin-top: 10px;
  }

  /* 商品列表 */
  .imgList {
    padding: 6px 12px;
    margin: 16px 7px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    display: flex;
    position: relative;

    /* 删除图标 */
    .el-icon-circle-close {
      position: absolute;
      right: -10px;
      top: -10px;
      cursor: pointer;
      font-size: 19px;
    }

    /* 图片 */
    .imag {
      width: 60px;
      height: 60px;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        display: inline-block;
      }

      span {
        background: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        display: inline-block;
        width: 100%;
        text-align: center;
        color: #fff;
        height: 20px;
        line-height: 20px;
      }
    }

    /* 图片字 */
    .imgText {
      width: 80%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding-left: 20px;
      justify-content: space-between;

      .select-type {
        display: flex;

        /deep/.el-select {
          .el-input {
            input {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  /* 图片样式 */
  .weiz {
    text-align: right;

    i {
      padding: 5px 14px;
      margin-left: 10px;
      border-radius: 0;
      border: 1px solid #ebedf0;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;

      &:last-child {
        font-size: 22px;
      }

      &.active {
        color: #51CDCB;
        border: 1px solid #51CDCB;
        background: #51cdcb26;
      }
    }
  }

  .shop-head-pic {
    color: #ababab;
    display: flex;
    flex-direction: column;

    .home-bg {
      width: 100px;
      height: 100px;
      margin: 10px auto;
    }

    .shop-head-pic-btn {
      display: flex;
      flex-direction: row;

      .el-button {
        flex: 1;
      }
    }
  }

  /* 颜色选择器 */
  .picke {
    float: right;
  }
}
</style>
