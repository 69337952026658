<template>
  <div class="uploadCommodity">
    <!-- 选择商品 -->
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择商品" :visible.sync="dialogVisible" :destroy-on-close="true"
      width="80%" center>
      <commonTable :tableData="productTableData" :loading="productLoading" @handleSizeChange="productHandleSizeChange"
        @handleCurrentChange="productHandleCurrentChange" :currentPage="productCurrentPage" :total="productTotal">
        <template v-slot:table>
          <el-table-column width="55">
            <template slot-scope="scope">
              <!-- <el-radio class="ml10" v-model="productCheckedData.id" :label="scope.row.goodsId"
                @change="selectProduct(scope.row)">
                {{ ' ' }}
              </el-radio> -->
              <el-checkbox v-model="goodsIds[scope.$index]" @change="selectProduct($event, scope.row)"
                :label="scope.row.goodsId">{{ '' }}</el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="goodPhoto" align="center" label="商品图片">
            <template slot-scope="scope">
              <el-image v-if="scope.row.goodPhoto" style="width: 100px; height: 80px;margin-bottom:-10px"
                :src="scope.row.goodPhoto.split(',')[0]" :preview-src-list="scope.row.goodPhoto.split(',')">
              </el-image>
              <p v-else style="width: 100px; height: 80px;line-height: 80px;">
                暂无图片
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="goodsName" align="center" label="商品名称" show-overflow-tooltip />
          <el-table-column prop="price" align="center" label="价格" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.price | getPrice }}
            </template>
          </el-table-column>
          <el-table-column prop="stockNum" align="center" label="库存" show-overflow-tooltip />
        </template>
      </commonTable>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="checkedProduct">确 定</el-button> -->
        <el-button @click="handleClose">取 消 上 传</el-button>
        <el-button type="primary" @click="uploadInformation" :disabled="disabl">点 击 上 传</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getGoodsInfoPC } from "@/api/appModel";
export default {
  components: { commonTable },
  name: 'uploadCrmCommodity',
  data() {
    return {
      dialogVisible: false, //弹框默认隐藏

      //显示商品配置
      goodsIds: [],
      checkedProduct: {},
      productTableData: [],
      showProduct: false,//模态框显示状态
      productCurrentPage: 1, //当前页
      productPageSize: 10, //显示条数
      productLoading: false, //表格加载
      productTotal: 0, //总条数
    }
  },
  created() {
    this.getProductList();
  },
  filters: {
    getPrice(price) {
      return Number(price / 100).toFixed(2) + "元"
    }
  },
  methods: {
    /**商品配置 */
    /**@method 选中商品 */
    selectProduct(isOpen, data) {
      if (isOpen) {
        this.checkedProduct[data.goodsId] = data;
      } else {
        delete this.checkedProduct[data.goodsId];
      }
    },
    /**@method 显示社团商品列表 */
    showProductModel() {
      this.showProduct = true;
      this.getProductList();
    },
    /**@method 显示商品列表 */
    showProductFun(index) {
      this.index = index;
      this.showProduct = true;
    },
    /**@method 商品获取列表 */
    async getProductList() {
      let params = {
        pageSize: this.productPageSize,
        pageNum: this.productCurrentPage,
        isOrdinary: 1
      };
      try {
        this.productLoading = true;
        let res = await getGoodsInfoPC(params)
        this.productLoading = false;

        const { pageInfo } = res.data;
        const { total, list } = JSON.parse(pageInfo);
        this.productTableData = list
        this.productTotal = total;
      } catch (error) {
        this.productLoading = false;
        this.productTableData = [];
      }
    },
    /**@method 商品切换行 */
    productHandleSizeChange(val) {
      this.productPageSize = val;
      this.getProductList();
    },
    /**@method 商品切换下一页 */
    productHandleCurrentChange(val) {
      this.productCurrentPage = val;
      this.getProductList();
    },

    /* 传递图片地址 */
    uploadInformation() {
      let params = [];
      for (let i in this.checkedProduct) {
        let val = this.checkedProduct[i]
        params.push({
          coverUrl: val.coverImg,
          type: val.goodstypeId,
          id: val.goodsId,
          name: val.goodsName,
          price: val.price/100
        });
      }

      this.$emit('uploadListInformation', params)

      // 隐藏上传弹框
      this.dialogVisible = false
      this.checkedProduct = {}
      this.goodsIds=[];
    },
    // 关闭弹框
    handleClose() {
      this.dialogVisible = false
      // this.$confirm('点击取消后您填写的信息将丢失，您确定取消？', "提示", {
      //   onConfirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "info ",
      // })
      //   .then(() => {
      //     // 隐藏上传文件
      //     this.dialogVisible = false
      //   })
      //   .catch(() => { })
    },
    /* 显示上传文件组件 */
    showUpload() {
      this.dialogVisible = true
    },
  },
  computed: {
    // 提交按钮是否可以点击
    disabl() {
      if (!this.checkedProduct) return true
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../css/minx.scss';

.uploadCommodity {

  // 上传弹框内容部分
  /deep/.uploadIMG .el-dialog__body {
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
  }

  .disable {
    /deep/.el-upload {
      display: none !important;
    }
  }
}
</style>
