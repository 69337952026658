<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发辅助台账及核算管理制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D003</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent" style="padding-top: 350px;">
                <div class="wordTtile">
                    <div class="flex-c-c textc">
                        <div class="zdhq fs24">研发辅助台账及核算管理制度</div>
                    </div>
                    <div class="textc pjTitle mt10 mb20 fs35">{{wordData.isAgain}}</div>
                </div>
            </div>
            <div class="wordFoot">
                <div class="textc fs30">
                    {{ checkedCompany.companyName }}
                </div>
                <div class="textc fs25">
                    <!-- <span class="fs30" v-if="!showSetTime">{{ formData.fileDay | textTime }}</span> -->
                    <el-date-picker v-model="formData.fileDay" class="dayInput w230" value-format="yyyy-MM-dd"
                        format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                    </el-date-picker>(自动转为中文)
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 1 页 共 6 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发辅助台账及核算管理制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D003</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordTtile">
                    <div class="textc pjTitle mt50 mb10 fs30">{{ checkedCompany.companyName }}</div>
                    <div class="flex-c-c textc lineBottom ">
                        <div class="zdhq fs40">研发辅助台账及核算管理制度</div>
                    </div>
                </div>
                <div class="lineheight45">
                    <div class="fw600">
                        一、总 则
                    </div>
                    <div class="textIndent40">
                        <div>
                            1.1 为进一步规范和加强对研发经费的管理，提高资金使用效益，根据国家有关规定并结合本公司的实际情况，制定本办法。
                        </div>
                        <div>1.2 明确研发经费的开支范围和标准，严格审批程序，并按照研发项目和承担研发任务设立研发辅助台账归集、核算研发费用，加强研发投入的专项审核，严禁以任何方式变相谋取私利。</div>
                        <div>1.3 研发中心应当严格按照本办法的规定，制定内部管理办法，加强对研发经费的监督和管理。</div>
                        <div>1.4 科学安排，合理配置。严格按照项目的目标和任务，科学合理地编制和安排预算，杜绝随意性。重点在于项目组可控的人、财、物投入，使财务部门能获得项目财务管理所需的项目核算数据。
                        </div>
                        <div>1.5 单独核算，专款专用。项目经费纳入财务部门统一管理，单独核算，确保专款专用，并建立专项经费管理和使用的追踪问效机制。</div>
                        <div>1.6
                            为正确评价科技投入水平、促进加大科技投入力度创造条件，将研发投入评价结果同考核承担部门绩效内容挂钩，增强自主创新的内在动力，真正建立起追求技术进步的体制和机制，从长远出发，克服短期行为，有效地持续增加研发投入，以保证研发出效益。
                        </div>
                        <div>1.7 研发项目分为国家、省、市计划项目、横向委托与合作项目、自主研发项目和其他项目与课题等四大类。</div>
                    </div>
                    <div>二、研发经费开支范围</div>
                    <div class="textIndent40">
                        <div>开支范围指公司在产品、技术、材料、工艺、标准等方面的研究、开发过程中发生的各项费用，包括：</div>
                        <div>2.1 直接消耗的材料、燃料和动力费用。</div>
                        <div>2.2 研发人员的工资、奖金、津贴、补贴、社会保险费、住房公积金等人工费用以及外聘研发人员的劳务费用。</div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 2 页 共 6 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发辅助台账及核算管理制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D003</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt80">
                    <div class="textIndent40">
                        <div>2.3 研发活动的仪器、设备、房屋等固定资产的折旧费或租赁费以及相关固定资产的运行维护、维修等费用。
                        </div>
                        <div>2.4 研发活动的软件、专利权、非专利技术等无形资产的摊销费用。</div>
                        <div>2.5 中间试验和产品试制的模具、工艺装备开发及制造费，设备调整及检验费，样品、样机及一般测试手段购置费，试制产品的检验费等。</div>
                        <div>2.6 研发结果的论证、评审、验收、评估以及知识产权的申请费、注册费、代理费等费用。</div>
                        <div>2.7 外包、合作研发等方式，委托其他单位、个人或者与之合作进行研发而支付的费用。</div>
                        <div>2.8 研发活动直接相关的其他费用，包括技术图书资料费、资料翻译费、会议费、差旅费、办公费、外事费、研发人员培训费、培养费、专家咨询费、高新科技研发保险费用等。</div>
                    </div>
                    <div>三、预算编制与审批</div>
                    <div class="textIndent40">
                        <div>
                            3.1研发中心：项目负责人负责编制研发项目计划、经费的预算和决算。研发中心负责汇总形成《XX年研发计划项目及预算表》经研发中心主任审核后，报公司预算管理委员会及总经理办公会讨论、审议审批；项目负责人严格按照项目任务书或合同书规定的开支范围和标准使用项目经费，自觉控制经费的各项支出，对研发经费使用的真实性、有效性承担责任。
                        </div>
                        <div>
                            3.2财务部门：负责研发准备金的财务管理和会计核算，指导项目部门编制项目经费预算，审核项目经费决算，监督和指导项目部门按照项目经费管理规定使用研发经费，核算研发准备金，并形成研发准备金台账。
                        </div>
                        <div>3.3研发中心主任：负责研发项目经费预算的审核、划拨和有关支出的审批，负责研发经费使用的监督和检查工作。</div>
                        <div>3.4总经理办公会：负责审议年度研发项目计划及研发项目经费预算。</div>
                    </div>
                    <div>四、研发经费分配和使用管理</div>
                    <div class="textIndent40">
                        <div>4.1 国家、省、市政府各类科技计划项目，项目申报成功后，上级下拨的科研经费由研发中心协同财务部门统一管理，按照项目过程的阶段和需要分配经费。</div>
                        <div>4.2横向委托与合作项目，外来经费由研究开发中心代管，由所在单位负责分配，公司配套经费由研发中心按照项目过程的阶段分配和使用进行管理。</div>
                        <div>4.3 企业级科研开发项目，经批准后，经费同时确定，全部经费由公司承担。科研经费下达方式根据项目的具体情况确定。</div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 3 页 共 6 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发辅助台账及核算管理制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D003</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt80">
                    <div class="textIndent40">
                        <div>4.4
                            科研开发经费实行专款专用，财务上为每个项目专门台账《科研经费使用台账》，由项目负责人掌握明细经费使用内容和金额。在项目的各个阶段点由财务人员进行决策评审，避免项目经费偏离预算计划较大。
                        </div>
                        <div>4.5 年末，研发中心对研究开发项目进行总结、考评；组织承担部门按照规定编制经费使用年度报告，报财务部门审核。</div>
                        <div>4.6 研发项目的年度结存经费，结转下一年度按规定继续使用。项目因故终止，财务部门应当及时清理帐目与资产，编制财务报告及资产清单，组织清查处理。</div>
                        <div>4.7 对于将经费挪作他用或报销金额与实际不符等不按规定管理和使用项目经费的；对于不按规定进行会计核算的项目承担部门和个人，财务部门予以停拨经费或通报批评。并根据具体情况进行处理。
                        </div>
                        <div>4.8
                            为规范研发项目管理，项目立项报告中应该明确项目预算、研发目标任务、提交成果形式、主要技术和经济指标、进度和项目的人、财、物投入。一方面作为项目组按时按质实现项目的产出的承诺，另一方面也作为公司管理层及各部门对项目人、财、物按时足额投入的承诺。在项目完工验收时，按项目的立项报告内容对项目组进行考核，考核的结果进入年度绩效考核，并和项目组的奖惩挂钩。
                        </div>
                    </div>
                    <div>五、研发开发经费的核算及归集方式</div>
                    <div class="textIndent40">
                        <div>
                            对包括直接研究开发活动和可以计入的间接活动所发生的费用进行核算。研究开发费用核算及归集要求如下：
                        </div>
                        <div>
                            5.1 研究开发费用的核算
                        </div>
                        <div>财务部门应设置研发费用核算账目，对费用结构进行明细，接受审计部门的年度审计。</div>
                        <div>5.2 研究开发费用的归集范围</div>
                        <div>（1）人员人工费用</div>
                        <div>包括公司科技人员的工资薪金、基本养老保险费、基本医疗保险费、失业保险费、工伤保险费、生育保险费和住房公积金，以及外聘科技人员的劳务费用。</div>
                        <div>（2）直接投入费用</div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 4 页 共 6 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发辅助台账及核算管理制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D003</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt50">
                    <div class="textIndent40">
                        <div>直接投入费用是指公司为实施研究开发活动而实际发生的相关支出。包括：</div>
                        <div>
                            ——直接消耗的材料、燃料和动力费用；
                        </div>
                        <div>——用于中间试验和产品试制的模具、工艺装备开发及制造费，不构成固定资产的样品、样机及一般测试手段购置费，试制产品的检验费；
                        </div>
                        <div>——用于研究开发活动的仪器、设备的运行维护、调整、检验、检测、维修等费用，以及通过经营租赁方式租入的用于研发活动的固定资产租赁费。</div>
                        <div>（3）折旧费用与长期待摊费用</div>
                        <div>
                            折旧费用是指用于研究开发活动的仪器、设备和在用建筑物的折旧费。
                        </div>
                        <div>长期待摊费用是指研发设施的改建、改装、装修和修理过程中发生的长期待摊费用。</div>
                        <div>（4）无形资产摊销费用</div>
                        <div>无形资产摊销费用是指用于研究开发活动的软件、知识产权、非专利技术（专有技术、许可证、设计和计算方法等）的摊销费用。</div>
                        <div>（5）设计费用</div>
                        <div>设计费用是指为新产品和新工艺进行构思、开发和制造，进行工序、技术规范、规程制定、操作特性方面的设计等发生的费用。包括为获得创新性、创意性、突破性产品进行的创意设计活动发生的相关费用。
                        </div>
                        <div>（6）装备调试费用与试验费用</div>
                        <div>装备调试费用是指工装准备过程中研究开发活动所发生的费用，包括研制特殊、专用的生产机器，改变生产和质量控制程序，或制定新方法及标准等活动所发生的费用。</div>
                        <div>为大规模批量化和商业化生产所进行的常规性工装准备和工业工程发生的费用不能计入归集范围。</div>
                        <div>试验费用包括新药研制的临床试验费、勘探开发技术的现场试验费、田间试验费等。</div>
                        <div>（7）委托外部研究开发费用</div>
                        <div>
                            委托外部研究开发费用是指公司委托境内外其他机构或个人进行研究开发活动所发生的费用（研究开发活动成果为公司拥有）。委托外部研究开发费用的实际发生额应按照独立交易原则确定，按照实际发生额的80%计入公司研发费用总额。
                        </div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 5 页 共 6 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发辅助台账及核算管理制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D003</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt50">
                    <div class="textIndent40">
                        <div>（8）其他费用</div>
                        <div>
                            其他费用是指上述费用之外与研究开发活动直接相关的其他费用，包括技术图书资料费、资料翻译费、专家咨询费、高新科技研发保险费，研发成果的检索、论证、评审、鉴定、验收费用，知识产权的申请费、注册费、代理费，会议费、差旅费、通讯费等。此项费用一般不得超过研究开发总费用的20%，另有规定的除外。
                        </div>
                        <div>5.3研究开发费用归集办法
                        </div>
                        <div>
                            研究开发费用是以单个研发活动为基本单位分别进行测度并加总计算的。包括直接研究开发活动和可以计入的间接研究开发活动所发生的费用进行归集，财务部门负责根据要求编制“公司年度研究开发费用结构明细表”。
                        </div>
                        <div>同时，公司财务部门应按照“公司年度研究开发费用结构明细表”设立高新技术企业认定专用《研究开发费用辅助台账》，并提供相关凭证及明细表。</div>
                    </div>
                    <div>六、项目的结题与经费决算</div>
                    <div class="textIndent40">
                        <div>6.1研发项目严格按计划组织实施，按时结题。各类项目结题时按照有关计划要求及立项报告内容及目标，实事求是地进行工作总结和技术总结，完成工作报告和研究开发报告。项目完成后，首先由研发中心按照《科研开发项目立项申请书》组织进行验收，并签署验收意见。最后提交给财务部门进行核算。</div>
                        <div>6.2 项目完成后，项目负责人提出经费验收申请，财务部门负责组织对经费进行核算、验收及财务决算。</div>
                    </div>
                    <div>七、附则</div>
                    <div class="textIndent40">
                        <div>7.1本办法由财务部门、研发中心负责解释。</div>
                        <div>7.2本办法自发布之日起执行。</div>
                    </div>
                    <div class="text-align-right mt50">{{ checkedCompany.companyName }}</div>
                    <div class="text-align-right">
                        <!-- <span v-if="!showSetTime">{{ formData.fileDay | textTime }}</span> -->
                        <el-date-picker v-model="formData.fileDay" class="miniDay w190" value-format="yyyy-MM-dd"
                            format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                        </el-date-picker>(自动转为中文)
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 6 页 共 6 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution914",
    props:{
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                fileDay: "",
            },
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        },
        textTime(time) {
            if (time) {
                let dayArr = time.split("-");
                let fileDay = "";
                let dayObj = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
                let tObj = ["年", "月", "日"];
                for (let i in dayArr) {
                    let t = dayArr[i];
                    if (i == 0 || t < 10) {
                        for (let childKey of t) {
                            fileDay += dayObj[childKey];
                        }
                    } else {
                        if (t > 10) {
                            if(t>=20){
                                fileDay += dayObj[String(t).slice(0, 1)]; 
                            }
                            fileDay += "十";
                            if(String(t).slice(1)>0){
                                fileDay += dayObj[String(t).slice(1)];
                            }
                        } else {
                            fileDay += dayObj[t];
                        }
                    }
                    fileDay += tObj[i];
                }
                return fileDay;
            } else {
                return "";
            }
        },
        timeStr(time) {
            if (time) {
                let dayStr = time.replace("-", "年");
                dayStr = dayStr.replace("-", "月");
                return dayStr + "日";
            }
        },
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany=data.companyData;
                    if(data.rulesId){
                        this.formData.fileDay = data.fileDay;
                    }else{
                        this.formData.fileDay = this.checkedCompany.fileDay;
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
};
</script>

<style lang="scss" scoped>
.frame {
    padding: 0 10px;
    border: 1px solid #000;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemTitle {
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 20px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>