<template>
  <div class="richtextstyle">
    <!-- 标题 -->
    <h2>{{ datas.text }}</h2>

    <el-form label-width="80px" :model="datas" size="small">
      <!-- 背景颜色 -->
      <el-form-item label="背景颜色">
        <!-- 背景颜色 -->
        <el-color-picker v-model="datas.backColor" show-alpha style="float: right" class="picke"
          :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>
    </el-form>

    <div class="edit">
      <quill-editor style="height: 550px" v-model="datas.myValue" ref="myQuillEditor" :options="editorOption" />
    </div>
  </div>
</template>

<script>
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
var Align = Quill.import('attributors/style/align');
Align.whitelist = ['right', 'center', 'justify'];
Quill.register(Align, true);

export default {
  name: 'richtextstyle',
  props: {
    datas: Object,
  },
  components: {
    quillEditor,
  },
  data() {
    return {
      editorOption: quillConfig,
      predefineColors: [
        // 颜色选择器预设
        "#fff",
        "#000",
        "#3064ba",
        '#51CDCB',
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        '#409EFF',
        '#909399',
        '#C0C4CC',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577',
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.richtextstyle {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 10px 20px;
  box-sizing: border-box;

  /* 标题 */
  h2 {
    padding: 24px 16px 24px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #f2f4f6;
    font-size: 18px;
    font-weight: 600;
    color: #323233;
  }
}

/* 修改富文本样式 */
/deep/.tox {
  border: 1px solid #ebedf0 !important;
}

/deep/.tox .tox-tbtn {
  height: 25px !important;
  margin: 0 2px !important;
  width: auto;
  font-size: 13px;
}

/deep/.tox .tox-tbtn--bespoke .tox-tbtn__select-label {
  width: auto !important;
}

/deep/.tox .tox-toolbar,
.tox .tox-toolbar__primary,
.tox .tox-toolbar__overflow {
  background: none !important;
}

/deep/.tox .tox-toolbar__group {
  background: #f7f8fa;
}

/deep/.tox .tox-tbtn--enabled,
.tox .tox-tbtn--enabled:hover {
  background: none !important;

  /deep/svg {
    fill: #51CDCB !important;
  }
}
</style>
