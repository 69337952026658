var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"commoditysearch",style:({
    background: _vm.datas.backgroundColor,
    border: `1px solid ${_vm.datas.backgroundColor}`,
  })},[_c('section',{staticClass:"searchs",style:({
      height: _vm.datas.heights + 'px',
      'justify-content': _vm.datas.textPosition === 0 ? 'left' : 'center',
      background: _vm.datas.borderColor,
      'border-radius': _vm.datas.borderRadius === 0 ? '0px' : '15px',
    })},[_c('div',{staticClass:"search-left"},[_c('van-icon',{style:({ color: _vm.datas.textColor }),attrs:{"name":"search","size":"16"}}),_c('span',{style:({ color: _vm.datas.textColor })},[_vm._v("搜索商品")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.sweep),expression:"datas.sweep"}],staticClass:"sweep",style:({ color: _vm.datas.textColor })},[_c('span',[_vm._v("扫一扫")])])]),_vm._t("deles")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }