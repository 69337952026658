<template>
    <div id="follow">
        <div class="more1">
            <h4>{{ datas.title }}</h4>
            <p v-show="datas.showDetalis">详情 <van-icon name="arrow" /></p>
        </div>
        <div class="follow-box"
            :style="{ 'border-radius': datas.borderRadius + 'px', backgroundColor: datas.backgroundColor }">
            <div class="follow-box-item" :style="{ backgroundColor: datas.backgroundColor }">
                <div class="follow-pic">
                    <!-- <img :src="datas.parkImg" alt="" /> -->
                    <el-image class="w100p h100p" :src="parkImg" fit="cover"></el-image>
                </div>
                <div class="follow-info">
                    <span>{{ parkName }}</span>
                </div>
            </div>
        </div>

        <!-- 删除组件 -->
        <slot name="deles" />
    </div>
</template>

<script>
export default {
    name: 'parkInfo',
    props: {
        datas: Object,
    },
    data() {
        return {
            parkImg: "",
            parkName: ""
        }
    },
    watch: {
        watchPark(row) {
            this.parkImg = row.parkImg
            this.parkName = row.parkName
        }
    },
    computed: {
        watchPark() {
            return this.$store.state.checkedPark
        }
    },
    created() {
        let parkData = JSON.parse(sessionStorage.getItem("checkedPark"))
        this.parkImg = parkData.parkImg;
        this.parkName = parkData.parkName;
    }
}
</script>

<style lang="scss" scoped>
.more1 {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
        font-size: 15px;
        color: #323233;
        font-weight: 400;
        width: 270px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    p {
        color: #969799;
        font-size: 12px;
        display: flex;
        align-items: center;
    }
}

#follow {
    position: relative;
    box-sizing: border-box;

    .follow-box {
        box-sizing: border-box;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

        .follow-box-item {
            padding: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
        }

        .follow-pic {
            width: 50px;
            height: 50px;
            overflow: hidden;
        }

        .follow-info {
            flex: 1;
            margin-left: 20px;
            font-size: 14px;
            color: #333;
        }
    }
}
</style>