var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity"},[_c('div',{staticClass:"more1"},[_c('h4',[_vm._v(_vm._s(_vm.datas.name))]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.viewMore1),expression:"datas.viewMore1"}]},[_vm._v("查看更多 "),_c('van-icon',{attrs:{"name":"arrow"}})],1)]),(_vm.datas.addMethod === 'auto')?_c('div',[_c('section',{directives:[{name:"show",rawName:"v-show",value:(!_vm.datas.autoData || !_vm.datas.autoData[0]),expression:"!datas.autoData || !datas.autoData[0]"}],staticClass:"defaultcommodity",class:[_vm.datas.commodityType === 2 ? 'defaultcommodityList2' : '']},[_vm._l((_vm.datas.dataNumber),function(index){return _c('div',{key:index,staticClass:"defaultcommodityList",class:[
          _vm.datas.commodityType === 0 ? 'defaultcommodityList0' : '',
          _vm.datas.commodityType === 1 ? 'defaultcommodityList1' : '',
          _vm.datas.commodityType === 2 ? 'defaultcommodityList2' : '',
          _vm.datas.commodityType === 3 ? 'defaultcommodityList3' : '',
          _vm.datas.commodityType === 4 ? 'defaultcommodityList4' : '',
          _vm.datas.commodityType === 5 ? 'defaultcommodityList5' : '',
        ],style:({
  'border-radius': _vm.datas.borderRadius + 'px',
  border: _vm.datas.moditystyle === 2 ? '1px solid rgba(50,50,51,0.1)' : '',
  'box-shadow':
    _vm.datas.moditystyle === 1 ? '0 2px 8px rgba(93,113,127,0.08)' : '',
  width:
    _vm.datas.commodityType === 1
      ? 50 - _vm.datas.commodityMargin / 6 + '%'
      : _vm.datas.commodityType === 2
        ? 33 - _vm.datas.commodityMargin / 5 + '%'
        : _vm.datas.commodityType === 4
          ? 50 - _vm.datas.commodityMargin / 5 + '%'
          : '',
})},[_c('div',{staticClass:"imgss",class:[_vm.datas.positions === 'top' ? 'containoptions' : ''],staticStyle:{"position":"relative","width":"100%"}},[_c('img',{attrs:{"draggable":"false","src":require("../../../images/imgs.png"),"alt":""}})]),_c('div',{staticClass:"text",class:[_vm.datas.positions === 'top' ? 'positionsTop' : ''],style:({ background: _vm.datas.moditystyle !== 3 ? '#fff' : 'none' })},[_c('h5',[_vm._v("这里显示活动名称，最多显示2行")]),_c('div',{staticClass:"dianz"},[(_vm.datas.address)?_c('span',{staticClass:"fir text-overflow"},[_vm._v("详情地址")]):_c('span'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.releaseTime),expression:"datas.releaseTime"}]},[_vm._v("YYYY-MM-DD")])])])])}),(_vm.datas.commodityType != 2 && _vm.datas.isPage)?_c('div',{staticClass:"flex-c-c dropPage mb10"},[_vm._v("下拉加载更多")]):_vm._e()],2),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.autoData && _vm.datas.autoData[0]),expression:"datas.autoData && datas.autoData[0]"}],staticClass:"defaultcommodity",class:[_vm.datas.commodityType === 2 ? 'defaultcommodityList2' : '']},[_vm._l((_vm.datas.autoData),function(item,index){return _c('div',{key:index,staticClass:"defaultcommodityList",class:[
          _vm.datas.commodityType === 0 ? 'defaultcommodityList0' : '',
          _vm.datas.commodityType === 1 ? 'defaultcommodityList1' : '',
          _vm.datas.commodityType === 2 ? 'defaultcommodityList2' : '',
          _vm.datas.commodityType === 3 ? 'defaultcommodityList3' : '',
          _vm.datas.commodityType === 4 ? 'defaultcommodityList4' : '',
          _vm.datas.commodityType === 5 ? 'defaultcommodityList5' : '',
        ],style:({
  'border-radius': _vm.datas.borderRadius + 'px',
  border: _vm.datas.moditystyle === 2 ? '1px solid rgba(50,50,51,0.1)' : '',
  'box-shadow':
    _vm.datas.moditystyle === 1 ? '0 2px 8px rgba(93,113,127,0.08)' : '',
  width:
    _vm.datas.commodityType === 1
      ? 50 - _vm.datas.commodityMargin / 6 + '%'
      : _vm.datas.commodityType === 2
        ? 33 - _vm.datas.commodityMargin / 5 + '%'
        : _vm.datas.commodityType === 4
          ? 50 - _vm.datas.commodityMargin / 5 + '%'
          : '',
})},[_c('div',{staticClass:"imgss",class:[_vm.datas.positions === 'top' ? 'containoptions' : ''],staticStyle:{"position":"relative","width":"100%"}},[_c('img',{attrs:{"draggable":"false","src":item.img,"alt":""}})]),_c('div',{staticClass:"text",class:[_vm.datas.positions === 'top' ? 'positionsTop' : ''],style:({ background: _vm.datas.moditystyle !== 3 ? '#fff' : 'none' })},[_c('h5',[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"dianz"},[(_vm.datas.address)?_c('span',{staticClass:"fir text-overflow"},[_vm._v(_vm._s(item.address))]):_c('span'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.releaseTime),expression:"datas.releaseTime"}]},[_vm._v(" "+_vm._s(item.releaseTime))])])])])}),(_vm.datas.commodityType != 2 && _vm.datas.isPage)?_c('div',{staticClass:"flex-c-c dropPage mb10"},[_vm._v("下拉加载更多")]):_vm._e()],2)]):_vm._e(),(_vm.datas.addMethod === 'manual')?_c('div',[_c('section',{directives:[{name:"show",rawName:"v-show",value:(!_vm.datas.manualData[0]),expression:"!datas.manualData[0]"}],staticClass:"defaultcommodity",class:[_vm.datas.commodityType === 2 ? 'defaultcommodityList2' : '']},_vm._l((3),function(index){return _c('div',{key:index,staticClass:"defaultcommodityList",class:[
          _vm.datas.commodityType === 0 ? 'defaultcommodityList0' : '',
          _vm.datas.commodityType === 1 ? 'defaultcommodityList1' : '',
          _vm.datas.commodityType === 2 ? 'defaultcommodityList2' : '',
          _vm.datas.commodityType === 3 ? 'defaultcommodityList3' : '',
          _vm.datas.commodityType === 4 ? 'defaultcommodityList4' : '',
          _vm.datas.commodityType === 5 ? 'defaultcommodityList5' : '',
        ],style:({
  'border-radius': _vm.datas.borderRadius + 'px',
  border: _vm.datas.moditystyle === 2 ? '1px solid rgba(50,50,51,0.1)' : '',
  'box-shadow':
    _vm.datas.moditystyle === 1 ? '0 2px 8px rgba(93,113,127,0.08)' : '',
  width:
    _vm.datas.commodityType === 1
      ? 50 - _vm.datas.commodityMargin / 6 + '%'
      : _vm.datas.commodityType === 2
        ? 33 - _vm.datas.commodityMargin / 5 + '%'
        : _vm.datas.commodityType === 4
          ? 50 - _vm.datas.commodityMargin / 5 + '%'
          : '',
})},[_c('div',{staticClass:"imgss",class:[_vm.datas.positions === 'top' ? 'containoptions' : ''],staticStyle:{"position":"relative","width":"100%"}},[_c('img',{attrs:{"draggable":"false","src":require("../../../images/imgs.png"),"alt":""}})]),_c('div',{staticClass:"text",class:[_vm.datas.positions === 'top' ? 'positionsTop' : ''],style:({ background: _vm.datas.moditystyle !== 3 ? '#fff' : 'none' })},[_c('h5',[_vm._v("这里显示活动名称，最多显示2行")]),_c('div',{staticClass:"dianz"},[(_vm.datas.address)?_c('span',{staticClass:"fir text-overflow"},[_vm._v("详情地址")]):_c('span'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.releaseTime),expression:"datas.releaseTime"}]},[_vm._v("YYYY-MM-DD")])])])])}),0),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.manualData[0]),expression:"datas.manualData[0]"}],staticClass:"defaultcommodity",class:[_vm.datas.commodityType === 2 ? 'defaultcommodityList2' : '']},_vm._l((_vm.datas.manualData),function(item,index){return _c('div',{key:index,staticClass:"defaultcommodityList",class:[
          _vm.datas.commodityType === 0 ? 'defaultcommodityList0' : '',
          _vm.datas.commodityType === 1 ? 'defaultcommodityList1' : '',
          _vm.datas.commodityType === 2 ? 'defaultcommodityList2' : '',
          _vm.datas.commodityType === 3 ? 'defaultcommodityList3' : '',
          _vm.datas.commodityType === 4 ? 'defaultcommodityList4' : '',
          _vm.datas.commodityType === 5 ? 'defaultcommodityList5' : '',
        ],style:({
  'border-radius': _vm.datas.borderRadius + 'px',
  border: _vm.datas.moditystyle === 2 ? '1px solid rgba(50,50,51,0.1)' : '',
  'box-shadow':
    _vm.datas.moditystyle === 1 ? '0 2px 8px rgba(93,113,127,0.08)' : '',
  width:
    _vm.datas.commodityType === 1
      ? 50 - _vm.datas.commodityMargin / 6 + '%'
      : _vm.datas.commodityType === 2
        ? 33 - _vm.datas.commodityMargin / 5 + '%'
        : _vm.datas.commodityType === 4
          ? 50 - _vm.datas.commodityMargin / 5 + '%'
          : '',
})},[_c('div',{staticClass:"imgss",class:[_vm.datas.positions === 'top' ? 'containoptions' : ''],staticStyle:{"position":"relative","width":"100%"}},[_c('img',{attrs:{"draggable":"false","src":item.img,"alt":""}})]),_c('div',{staticClass:"text",class:[_vm.datas.positions === 'top' ? 'positionsTop' : ''],style:({ background: _vm.datas.moditystyle !== 3 ? '#fff' : 'none' })},[_c('h5',[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"dianz"},[(_vm.datas.address)?_c('span',{staticClass:"fir text-overflow"},[_vm._v(_vm._s(item.address))]):_c('span'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.releaseTime),expression:"datas.releaseTime"}]},[_vm._v(" "+_vm._s(item.releaseTime))])])])])}),0)]):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.viewMore2),expression:"datas.viewMore2"}],staticClass:"more2"},[_vm._v(" 查看更多 "),_c('van-icon',{attrs:{"name":"arrow"}})],1),_vm._t("deles")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }