<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>技术创新及科技成果转化奖励制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D006</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent">
                <div class="textc pjTitle mt20 mb20 fs40">技术创新及科技成果转化奖励申请表</div>
                <div class="text-align-right"><el-date-picker v-model="formData.fileDay" class="dayInput w230"
                        value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                    </el-date-picker></div>
                <table border="1" cellspacing="0" class="tableBorder institutionTable">
                    <tbody>
                        <tr class="h60">
                            <td class="textc itemContent w220">申请奖励事项</td>
                            <td class="itemContent" colspan="3">
                                “
                                <el-autocomplete popper-class="my-autocomplete" v-model="formData.content"
                                    class="printName w300" :fetch-suggestions="getProjectData" placeholder="奖励事项"
                                    @select="selectProject">
                                    <template slot-scope="{ item }">
                                        <div class="flex-a-b-c">
                                            <div class="addr w180 text-overflow">{{ item.projectName }}</div>
                                            <div class="name">{{ item.endDay }}</div>
                                        </div>
                                    </template>
                                </el-autocomplete>
                                ”取得科技成果转化
                            </td>
                        </tr>
                        <tr>
                            <td class="textc itemContent w220">奖励事项主要内容</td>
                            <td class="itemContent" colspan="3">
                                经过团队努力，成功研发出“
                                <el-autocomplete popper-class="my-autocomplete" v-model="formData.content"
                                    class="printName w300" :fetch-suggestions="getProjectData" placeholder="奖励事项"
                                    @select="selectProject">
                                    <template slot-scope="{ item }">
                                        <div class="flex-a-b-c">
                                            <div class="addr w180 text-overflow">{{ item.projectName }}</div>
                                            <div class="name">{{ item.endDay }}</div>
                                        </div>
                                    </template>
                                </el-autocomplete>
                                ”，并实现了科技成果转化，为公司带来了良好的收益，根据公司《技术创新及科技成果转化奖励制度》，特申请科技成果转化奖励，并在下月绩效工资中发放。
                            </td>
                        </tr>
                        <tr class="h60">
                            <td class="textc itemContent w220">主要参与人员</td>
                            <td class="itemContent" colspan="3">
                                <el-input type="textarea" class="printName" rows="3" placeholder="主要参与人员"
                                    v-model="formData.joinName"></el-input>
                            </td>
                        </tr>
                        <tr class="h60">
                            <td class="textc itemContent w220">申请部门</td>
                            <td class="itemContent">研发中心</td>
                            <td class="textc itemContent w200">申请奖励金额</td>
                            <td class="textc itemContent w200">
                                <el-input type="number" class="printName w150" placeholder="奖励金额"
                                    v-model="formData.money"></el-input>元
                            </td>
                        </tr>
                        <tr class="h60">
                            <td class="textc itemContent w220">经办人</td>
                            <td class="textc itemContent">
                                <el-input type="text" class="printName" placeholder="经办人"
                                    v-model="formData.hostName"></el-input>
                            </td>
                            <td class="textc itemContent w200">联系电话</td>
                            <td class="textc itemContent"></td>
                        </tr>
                        <tr>
                            <td class="itemContent w220 h100">
                                <div class="textc mt50 mb50">
                                    <div>研发中心领导</div>
                                    <div>意 见</div>
                                </div>
                            </td>
                            <td class="textc itemContent" colspan="3">
                                <div class="d-flex autograph">
                                    <div class="ml100 w100">签名：</div>
                                    <div class="ml50">年</div>
                                    <div class="ml50">月</div>
                                    <div class="ml50">日</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="itemContent w220 h100">
                                <div class="textc mt50 mb50">
                                    <div>人力资源部门</div>
                                    <div>意 见</div>
                                </div>
                            </td>
                            <td class="textc itemContent" colspan="3">
                                <div class="d-flex autograph">
                                    <div class="ml100 w100">签名：</div>
                                    <div class="ml50">年</div>
                                    <div class="ml50">月</div>
                                    <div class="ml50">日</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="itemContent w220 h100">
                                <div class="textc mt50 mb50">
                                    <div>财务部</div>
                                    <div>意 见</div>
                                </div>
                            </td>
                            <td class="textc itemContent" colspan="3">
                                <div class="d-flex autograph">
                                    <div class="ml100 w100">签名：</div>
                                    <div class="ml50">年</div>
                                    <div class="ml50">月</div>
                                    <div class="ml50">日</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="itemContent w220 h100">
                                <div class="textc mt50 mb50">
                                    <div>总经理</div>
                                    <div>意 见</div>
                                </div>
                            </td>
                            <td class="textc itemContent" colspan="3">
                                <div class="d-flex autograph">
                                    <div class="ml100 w100">签名：</div>
                                    <div class="ml50">年</div>
                                    <div class="ml50">月</div>
                                    <div class="ml50">日</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="fs8">注：此表须复印一式三份，一份管理部门，一份人力资源部门，一份财务部门，原件留于申请奖励部门存档</div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 1 页 共 1 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getProjectDataList } from "@/api/projectAdmin";
export default {
    name: "institution93111",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                hostName: "",
                joinName: "",
                money: 0,
                content: "",
                fileDay: ""
            },
            checkedCompany: {}
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        },
        textTime(time) {
            if (time) {
                let dayArr = time.split("-");
                let fileDay = "";
                let dayObj = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
                let tObj = ["年", "月", "日"];
                for (let i in dayArr) {
                    let t = dayArr[i];
                    if (i == 0 || t < 10) {
                        for (let childKey of t) {
                            fileDay += dayObj[childKey];
                        }
                    } else {
                        if (t > 10) {
                            if(t>=20){
                                fileDay += dayObj[String(t).slice(0, 1)]; 
                            }
                            fileDay += "十";
                            if(String(t).slice(1)>0){
                                fileDay += dayObj[String(t).slice(1)];
                            }
                        } else {
                            fileDay += dayObj[t];
                        }
                    }
                    fileDay += tObj[i];
                }
                return fileDay;
            } else {
                return "";
            }
        },
        timeStr(time) {
            if (time) {
                let dayStr = time.replace("-", "年");
                dayStr = dayStr.replace("-", "月");
                return dayStr + "日";
            }
        },
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData = {
                            hostName: data.hostName,
                            joinName: data.joinName,
                            money: data.money,
                            content: data.content,
                            fileDay: data.fileDay,
                        };
                    } else {
                        this.formData = {
                            hostName: this.checkedCompany.legalName,
                            joinName: "",
                            money: 2500,
                            content: "",
                            fileDay: this.checkedCompany.fileDay,
                        };
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        /**@method 获取项目列表 */
        getProjectData(str, cb) {
            let params = {
                pageSize: 100,
                pageNum: 1,
                sortCode: 1,
                pcId: this.checkedCompany.pcId,
            };
            if (str) {
                params.projectName = str;
            }
            let projectTable = [];
            getProjectDataList(params).then(res => {
                if (res.data) {
                    for (let row of res.data.pageInfo.list) {
                        row.projectName = row.projectName.replace("的研究开发", "")
                        projectTable.push(row);
                    }
                }
                cb(projectTable);
            }).catch(err => {
                cb(projectTable);
            })
        },
        /**@method 选中项目 */
        selectProject(data) {
            let userList = [];
            if (data.projectUserList) {
                for (let row of data.projectUserList) {
                    userList.push(row.userName);
                }
            }
            this.formData.hostName = data.headName;
            this.formData.joinName = userList.join("、");
            this.formData.content = data.projectName;
            let oneDay = 1000 * 60 * 60 * 24;
            let fileDay = data.endDay ? new Date(data.endDay).getTime() + (oneDay * 15) : "";
            let startYear = new Date(data.startDay).getFullYear();
            let endYear = new Date(fileDay).getFullYear();
            let endMonth = new Date(fileDay).getMonth() + 1;
            if (startYear < endYear && endMonth == 1) {
                this.formData.fileDay = startYear + "-12-28"
            } else {
                this.formData.fileDay = new Date(fileDay).toLocaleDateString().replace(/\//ig,"-");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.frame {
    padding: 0 10px;
    border: 1px solid #000;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemContent {
        position: relative;

        .autograph {
            position: absolute;
            right: 10px;
            bottom: 10px;
        }

        min-height: 30px;
        height: 100%;
        font-size: 20px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 20px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>