<template>
  <div class="phoneBottom" :style="{ backgroundColor: pageSetup.bgColor }">
    <van-tabbar v-model="active" :fixed="false" :placeholder="true"
      :active-color="pageSetup.navigationData.activeTitleColor" :inactive-color="pageSetup.navigationData.titleColor">
      <van-tabbar-item v-for="(item, index) in pageSetup.navigationData.data" :key="index" :class="{hideTab:!item.isShow}" :name="item.title">
        <span>{{ item.title }}</span>
        <template #icon="props">
          <img :src="props.active ? item.activeIcon : item.icon" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'associationHomePhoneBottom',
  props: {
    pageSetup: Object,
  },
  data() {
    return {
      active: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.hideTab{
  display: none;
}
.phoneBottom {
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  min-height: 50px;
  padding-bottom:50px;
  box-sizing: border-box;
}
</style>
