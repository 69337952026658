<template>
  <div>
    <div class="headerTop" :style="{
      color: pageSetup.titleColor,
      height: pageSetup.titleHeight + 'px',
      background: pageSetup.titleBgColor,
      backgroundImage: 'url(' + pageSetup.titleBgImg + ')'
    }">
      <!-- 左半部分 -->
      <!-- <div class="lef" v-show="pageSetup.isBack">
        <van-icon name="arrow-left" />
      </div> -->
      <!-- 标题 -->
      <div class="header-title" :style="{
          height: pageSetup.titleHeight + 'px',
          'line-height': pageSetup.titleHeight + 'px',
        }">
        {{ pageSetup.name }}
      </div>
      <!-- 右半部分 -->
      <!-- <div class="rig" v-show="pageSetup.isPerson">
        <span>个人中心</span>
      </div> -->
    </div>
    <div class="newThreeBox flex-a-b-c pall10" :style="{ background: pageSetup.titleBgColor, }">
      <div class="typeBtn flex-c-c"  v-if="pageSetup.showButton" :style="{ backgroundColor: 'rgb(238, 238, 238)', color: '#999' }">
        <div class="flex-c-c" :style="{ background: '#51CDCB', color: '#fff' }">商城
        </div>
        <div class="flex-c-c">服务
        </div>
      </div>
      <div class="flex-a-b-c search" :style="{ backgroundColor: 'rgb(238, 238, 238)', 'border-radius': '20px' }">
        <!-- 搜索框的长度 -->
        <div class="seeIcon flex-c-c pl10" :style="{ color: '#999' }">
          <span class="el-icon-search" style="font-size: 23px;"></span>
        </div>
        <div class="searchPlaceholder" v-if="pageSetup.showButton" :style="{ width: '180px', color: '#999' }">
          请输入产品名称搜索</div>
          <div class="searchPlaceholder" v-else :style="{ width: '300px', color: '#999' }">
          请输入产品名称搜索</div>
      </div>
      <div class="flex-c-c pa5 coupon">
        <img src="https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/one-stand/products/icon_discount.png" alt="">
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'parkMallHeaderTop',
  props: {
    pageSetup: Object,
  }
}
</script>
  
<style lang="scss" scoped>
.headerTop {
  position: relative;
  height: 35px;
  width: 100%;
  display: flex;
  padding: 0 5px;
  // padding-top: 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  background-color: transparent;

  /* 左边 */
  .lef {
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-content: center;
    height: 20px;

    /* 图标 */
    i {
      font-size: 20px;
      margin-right: 5px
    }

    span {
      font-weight: 400;
      font-size: 14px;
    }
  }

  .header-title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 35px;
  }

  /* 右边 */
  .rig {
    // display: flex;
    // align-items: center;
    // height: 100%;
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);

    /* 搜索图标 */
    i {
      font-size: 18px;
      margin: 0 7px 5px;
    }

    /* 文字 */
    span {
      margin: 0 7px;
      font-size: 12px;
    }
  }
}

.newThreeBox {
  height: 60px;

  .searchPlaceholder {
    padding: 5px 20px 5px 5px;
    height: 31px;
    overflow: hidden;
    white-space: nowrap;
  }

  .typeBtn {
    height: 30px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #eee;

    >div {
      padding: 0 10px;
      height: 100%;
    }
  }

  .coupon {
    img {
      height: 34px;
    }
  }

  .iocnSort {
    transform: rotate(90deg)
  }
}
</style>
  