<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="wordSetContent">
                <div class="modelHeader">
                    <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                        <div class="flex-a-c ">
                            <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.companyName }}</div>
                            <span class="ml20">管理文件>培训记录 V1.0</span>
                        </div>
                        <div class="flex-a-c">
                            <span>编号：</span>
                            <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.shortName }}</div>
                            <span>-HR-R004</span>
                        </div>
                    </div>
                    <div class="flex-a-b-c mb10">
                        <div class="squareLeft"></div>
                        <div class="squareRight"></div>
                    </div>
                </div>
                <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
                <div class="pl30 pr30 wordSetContent">
                    <div class="textc pjTitle mt20 mb20 fs35">员工培训记录（签到）表</div>
                    <div class="text-align-right mr100">No:</div>
                    <table border="1" cellspacing="0" class="tableBorder institutionTable">
                        <tbody>
                            <tr class="h50">
                                <td class="textc itemContent fwbold" colspan="2">培训项目名称</td>
                                <td class="itemContent" colspan="4">
                                    <el-input type="text" class="projectName textc" placeholder="培训项目名称"
                                        v-model="formData.projectName"></el-input>
                                </td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent fwbold" colspan="2">培训时间</td>
                                <td class="itemContent textc" colspan="4">
                                    <el-date-picker v-model="formData.trainTime" class="miniDay w190"
                                        value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                                    </el-date-picker>下午14：00
                                </td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent fwbold" colspan="2">培训地点</td>
                                <td class="itemContent textc" colspan="4">
                                    公司会议室
                                </td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent fwbold" colspan="2">参加人数</td>
                                <td class="textc itemContent" colspan="4">
                                    <el-input type="number" class="projectName w130" placeholder="参加人数"
                                        v-model="formData.joinNum"></el-input>人
                                </td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent fwbold" colspan="2">培训主办或主讲</td>
                                <td class="textc itemContent" colspan="4">
                                    <el-input type="text" class="projectName textc" placeholder="培训主办或主讲"
                                        v-model="formData.hostName"></el-input>
                                </td>
                            </tr>
                            <tr class="h200">
                                <td class="textc itemContent fwbold" colspan="2">培训主要内容</td>
                                <td class="textc itemContent" colspan="4">
                                    <el-input type="textarea" class="wrodInput projectName" :autosize="{ minRows: 6 }"
                                        placeholder="培训主要内容" v-model="formData.content"></el-input>
                                </td>
                            </tr>
                            <tr class="h50 fwbold">
                                <td class="textc itemContent w80">序号</td>
                                <td class="textc itemContent w200">部 门</td>
                                <td class="textc itemContent w200">姓 名</td>
                                <td class="textc itemContent w80">序号</td>
                                <td class="textc itemContent w200">部 门</td>
                                <td class="textc itemContent w200">姓 名</td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">1</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent">11</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">2</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent">12</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">3</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent">13</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">4</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent">14</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">5</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent">15</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">6</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent">16</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">7</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent">17</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">8</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent">18</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">9</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent">19</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent">10</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent">20</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent"></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="fs8 mt10">注：本表用于各种技术、研发、讨论、技能、知识培训等记录。</div>
                </div>
                <div class="wordPage flex-a-b-c">
                    <div class="squareLeft"></div>
                    <div></div>
                    <div class="squareRight"></div>
                </div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="wordSetContent">
                <div class="modelHeader">
                    <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                        <div class="flex-a-c ">
                            <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.companyName }}</div>
                            <span class="ml20">管理文件>培训记录 V1.0</span>
                        </div>
                        <div class="flex-a-c">
                            <span>编号：</span>
                            <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.shortName }}</div>
                            <span>-HR-R004</span>
                        </div>
                    </div>
                    <div class="flex-a-b-c mb10">
                        <div class="squareLeft"></div>
                        <div class="squareRight"></div>
                    </div>
                </div>
                <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
                <div class="pl30 pr30 wordSetContent">
                    <div class="textc pjTitle mt20 mb20 fs35">培训效果评价表</div>
                    <div class="text-align-right mr100">No:</div>
                    <table border="1" cellspacing="0" class="tableBorder institutionTable">
                        <tbody>
                            <tr class="h50">
                                <td class="textc itemContent w150 fwbold">培训项目</td>
                                <td class="itemContent" colspan="3">
                                    <el-input type="text" class="projectName 250" placeholder="培训项目"
                                        v-model="formData.projectName"></el-input>
                                </td>
                                <td class="textc itemContent w150 fwbold">培训对象</td>
                                <td class="textc itemContent">技术员</td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent w150 fwbold">培训日期</td>
                                <td class="itemContent" colspan="3">
                                    <el-date-picker v-model="formData.trainTime" class="miniDay w230"
                                        value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                                    </el-date-picker>
                                </td>
                                <td class="textc itemContent w150 fwbold">授 课 人</td>
                                <td class="itemContent">
                                    <el-input type="text" class="projectName" placeholder="授课人"
                                        v-model="formData.hostName"></el-input>
                                </td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent w150 fwbold">培训教材</td>
                                <td class="textc itemContent" colspan="5">PPT</td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent" colspan="6">培训效果评价</td>
                            </tr>
                            <tr class="h50 fwbold">
                                <td class="textc itemContent w250" colspan="2">评价项目</td>
                                <td class="textc itemContent w80">标准分值</td>
                                <td class="textc itemContent w80">评价分数</td>
                                <td class="textc itemContent" colspan="2">备注（评分说明或不满意之处）</td>
                            </tr>
                            <tr class="h50">
                                <td class="itemContent fwbold" colspan="2">培训内容和形式</td>
                                <td class="textc itemContent">10</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent" colspan="2"></td>
                            </tr>
                            <tr class="h50">
                                <td class="itemContent fwbold" colspan="2">培训设施</td>
                                <td class="textc itemContent">10</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent" colspan="2"></td>
                            </tr>
                            <tr class="h50">
                                <td class="itemContent fwbold" colspan="2">培训教材</td>
                                <td class="textc itemContent">10</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent" colspan="2"></td>
                            </tr>
                            <tr class="h50">
                                <td class="itemContent fwbold" colspan="2">授课人的素质和教学能力</td>
                                <td class="textc itemContent">15</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent" colspan="2"></td>
                            </tr>
                            <tr class="h50">
                                <td class="itemContent fwbold" colspan="2">培训后学员学识增进情况</td>
                                <td class="textc itemContent">25</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent" colspan="2"></td>
                            </tr>
                            <tr class="h50">
                                <td class="itemContent fwbold" colspan="2">培训后学员操作技能或工作绩效的提高程度</td>
                                <td class="textc itemContent">30</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent" colspan="2"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent fwbold" colspan="2">合计</td>
                                <td class="textc itemContent">100</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent" colspan="2"></td>
                            </tr>
                            <tr>
                                <td class="itemContent" colspan="6">
                                    <div class="fwbold" style="height:300px">其他补充事宜：</div>
                                    <div class="d-flex autograph">
                                        <div class="mr100 w150"> 评价人签名：</div>
                                        <div class="mr100 w100">日期：</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="itemContent" colspan="6">
                                    <div style="height:300px">
                                        针对评价发现的问题采取相应措施（注：评价总分≤70分时,由人力资源部负责采取相应改进措施,并将措施填在本栏中，高于70分时，无需填写）：</div>
                                    <div class="d-flex autograph">
                                        <div class="mr100 w150">人力资源部：</div>
                                        <div class="mr100 w80">日期：</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="fs8">注：（1）本表与培训记录表一同使用，每一种类/项目的培训进行一次效果评价。（2）每课评价人员应不少于2人。</div>
                </div>
                <div class="wordPage flex-a-b-c">
                    <div class="squareLeft"></div>
                    <div></div>
                    <div class="squareRight"></div>
                </div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="wordSetContent">
                <div class="modelHeader">
                    <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                        <div class="flex-a-c ">
                            <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.companyName }}</div>
                            <span class="ml20">管理文件>培训记录 V1.0</span>
                        </div>
                        <div class="flex-a-c">
                            <span>编号：</span>
                            <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.shortName }}</div>
                            <span>-HR-R004</span>
                        </div>
                    </div>
                    <div class="flex-a-b-c mb10">
                        <div class="squareLeft"></div>
                        <div class="squareRight"></div>
                    </div>
                </div>
                <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
                <div class="pl30 pr30 wordSetContent">
                    <div class="textc pjTitle mt20 mb20 fs35">培训效果评价表</div>
                    <div class="text-align-right mr100">No:</div>
                    <table border="1" cellspacing="0" class="tableBorder institutionTable">
                        <tbody>
                            <tr class="h50">
                                <td class="textc itemContent w150 fwbold">培训项目</td>
                                <td class="itemContent" colspan="3">
                                    <el-input type="text" class="projectName 250" placeholder="培训项目"
                                        v-model="formData.projectName"></el-input>
                                </td>
                                <td class="textc itemContent w150 fwbold">培训对象</td>
                                <td class="textc itemContent">技术员</td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent w150 fwbold">培训日期</td>
                                <td class="itemContent" colspan="3">
                                    <el-date-picker v-model="formData.trainTime" class="miniDay w230"
                                        value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                                    </el-date-picker>
                                </td>
                                <td class="textc itemContent w150 fwbold">授 课 人</td>
                                <td class="itemContent">
                                    <el-input type="text" class="projectName" placeholder="授课人"
                                        v-model="formData.hostName"></el-input>
                                </td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent w150 fwbold">培训教材</td>
                                <td class="textc itemContent" colspan="5">PPT</td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent" colspan="6">培训效果评价</td>
                            </tr>
                            <tr class="h50 fwbold">
                                <td class="textc itemContent w250" colspan="2">评价项目</td>
                                <td class="textc itemContent w80">标准分值</td>
                                <td class="textc itemContent w80">评价分数</td>
                                <td class="textc itemContent" colspan="2">备注（评分说明或不满意之处）</td>
                            </tr>
                            <tr class="h50">
                                <td class="itemContent fwbold" colspan="2">培训内容和形式</td>
                                <td class="textc itemContent">10</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent" colspan="2"></td>
                            </tr>
                            <tr class="h50">
                                <td class="itemContent fwbold" colspan="2">培训设施</td>
                                <td class="textc itemContent">10</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent" colspan="2"></td>
                            </tr>
                            <tr class="h50">
                                <td class="itemContent fwbold" colspan="2">培训教材</td>
                                <td class="textc itemContent">10</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent" colspan="2"></td>
                            </tr>
                            <tr class="h50">
                                <td class="itemContent fwbold" colspan="2">授课人的素质和教学能力</td>
                                <td class="textc itemContent">15</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent" colspan="2"></td>
                            </tr>
                            <tr class="h50">
                                <td class="itemContent fwbold" colspan="2">培训后学员学识增进情况</td>
                                <td class="textc itemContent">25</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent" colspan="2"></td>
                            </tr>
                            <tr class="h50">
                                <td class="itemContent fwbold" colspan="2">培训后学员操作技能或工作绩效的提高程度</td>
                                <td class="textc itemContent">30</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent" colspan="2"></td>
                            </tr>
                            <tr class="h50">
                                <td class="textc itemContent fwbold" colspan="2">合计</td>
                                <td class="textc itemContent">100</td>
                                <td class="textc itemContent"></td>
                                <td class="textc itemContent" colspan="2"></td>
                            </tr>
                            <tr>
                                <td class="itemContent" colspan="6">
                                    <div class="fwbold" style="height:300px">其他补充事宜：</div>
                                    <div class="d-flex autograph">
                                        <div class="mr100 w150"> 评价人签名：</div>
                                        <div class="mr100 w100">日期：</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="itemContent" colspan="6">
                                    <div style="height:300px">
                                        针对评价发现的问题采取相应措施（注：评价总分≤70分时,由人力资源部负责采取相应改进措施,并将措施填在本栏中，高于70分时，无需填写）：</div>
                                    <div class="d-flex autograph">
                                        <div class="mr100 w150">人力资源部：</div>
                                        <div class="mr100 w80">日期：</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="fs8">注：（1）本表与培训记录表一同使用，每一种类/项目的培训进行一次效果评价。（2）每课评价人员应不少于2人。</div>
                </div>
                <div class="wordPage flex-a-b-c">
                    <div class="squareLeft"></div>
                    <div></div>
                    <div class="squareRight"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution942220",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                projectName: "",
                trainTime: "",
                joinNum: 4,
                hostName: "",
                content: "",
                result: ""
            },
            checkedCompany: {},
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        },
        textTime(time) {
            if (time) {
                let dayArr = time.split("-");
                let fileDay = "";
                let dayObj = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
                let tObj = ["年", "月", "日"];
                for (let i in dayArr) {
                    let t = dayArr[i];
                    if (i == 0 || t < 10) {
                        for (let childKey of t) {
                            fileDay += dayObj[childKey];
                        }
                    } else {
                        if (t > 10) {
                            if (t >= 20) {
                                fileDay += dayObj[String(t).slice(0, 1)];
                            }
                            fileDay += "十";
                            if (String(t).slice(1) > 0) {
                                fileDay += dayObj[String(t).slice(1)];
                            }
                        } else {
                            fileDay += dayObj[t];
                        }
                    }
                    fileDay += tObj[i];
                }
                return fileDay;
            } else {
                return "";
            }
        },
        timeStr(time) {
            if (time) {
                let dayStr = time.replace("-", "年");
                dayStr = dayStr.replace("-", "月");
                return dayStr + "日";
            }
        },
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData = {
                            projectName: data.projectName,
                            trainTime: data.trainTime,
                            joinNum: data.joinNum,
                            hostName: data.hostName,
                            content: data.content,
                            result: data.result
                        }
                    } else {
                        this.formData = {
                            projectName: "",
                            trainTime: this.checkedCompany.fileDay,
                            joinNum: 4,
                            hostName: this.checkedCompany.projectName,
                            content: "",
                            result: ""
                        }
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
};
</script>

<style lang="scss" scoped>
.frame {
    padding: 0 10px;
    border: 1px solid #000;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemContent {
        position: relative;

        .autograph {
            position: absolute;
            right: 10px;
            bottom: 10px;
        }

        min-height: 30px;
        height: 100%;
        font-size: 20px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 20px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.projectName {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>