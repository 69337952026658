import axios from '../service/axios'
import qs from 'querystring'
import { clearLoginInfo } from './index'
import { Message } from 'element-ui'
let gzhErrcode = {
    0: '请求成功',
    1003: 'POST参数非法',
    20002: '商品id不存在',
    40001: '获取 access_token 时 AppSecret 错误，或者 access_token 无效。请开发者认真比对 AppSecret  的正确性，或查看是否正在为恰当的公众号调用接口',
    40002: '不合法的凭证类型',
    40003: '不合法的 OpenID ，请开发者确认 OpenID （该用户）是否已关注公众号，或是否是其他公众号的 OpenID',
    40004: '不合法的媒体文件类型',
    40005: '上传素材文件格式不对',
    40006: '上传素材文件大小超出限制',
    40007: '不合法的媒体文件 id',
    40008: '不合法的消息类型',
    40009: '图片尺寸太大',
    40010: '不合法的语音文件大小',
    40011: '不合法的视频文件大小',
    40012: '不合法的缩略图文件大小',
    40013: '不合法的appid',
    40014: '不合法的 access_token ，请开发者认真比对 access_token 的有效性（如是否过期），或查看是否正在为恰当的公众号调用接口',
    40015: '不合法的菜单类型',
    40016: '不合法的按钮个数',
    40017: '不合法的按钮类型',
    40018: '不合法的按钮名字长度',
    40019: '不合法的按钮 KEY 长度',
    40020: '不合法的按钮 URL 长度',
    40021: '不合法的菜单版本号',
    40022: '不合法的子菜单级数',
    40023: '不合法的子菜单按钮个数',
    40024: '不合法的子菜单按钮类型',
    40025: '不合法的子菜单按钮名字长度',
    40026: '不合法的子菜单按钮 KEY 长度',
    40027: '不合法的子菜单按钮 URL 长度',
    40028: '不合法的自定义菜单使用用户',
    40029: '无效的 oauth_code',
    40030: '不合法的 refresh_token',
    40031: '不合法的 openid 列表',
    40032: '不合法的 openid 列表长度',
    40033: '不合法的请求字符，不能包含 \\u×××× 格式的字符',
    40034: '未知错误',
    40035: '不合法的参数',
    40036: '不合法的 template_id 长度',
    40037: '不合法的 template_id',
    40038: '不合法的请求格式',
    40039: '不合法的 URL 长度',
    40040: '未知错误',
    40041: '未知错误',
    40042: '未知错误',
    40043: '未知错误',
    40044: '未知错误',
    40045: '未知错误',
    40046: '未知错误',
    40047: '未知错误',
    40048: '无效的url',
    40049: '未知错误',
    40050: '不合法的分组 id',
    40051: '分组名字不合法',
    40052: '未知错误',
    40053: '未知错误',
    40054: '不合法的子菜单按钮 url 域名',
    40055: '不合法的菜单按钮 url 域名',
    40056: '未知错误',
    40057: '未知错误',
    40058: '未知错误',
    40059: '未知错误',
    40060: '删除单篇图文时，指定的 article_idx 不合法',
    40062: '未知错误',
    40063: '未知错误',
    40064: '未知错误',
    40065: '未知错误',
    40066: '不合法的 url ，递交的页面被sitemap标记为拦截',
    40067: '未知错误',
    40068: '未知错误',
    40069: '未知错误',
    40070: '未知错误',
    40071: '未知错误',
    40072: '未知错误',
    40073: '未知错误',
    40074: '未知错误',
    40075: '未知错误',
    40076: '未知错误',
    40077: '未知错误',
    40078: '未知错误',
    40079: '未知错误',
    40080: '未知错误',
    40081: '未知错误',
    40082: '未知错误',
    40083: '未知错误',
    40084: '未知错误',
    40085: '未知错误',
    40086: '未知错误',
    40087: '未知错误',
    40088: '未知错误',
    40089: '未知错误',
    40090: '未知错误',
    40091: '未知错误',
    40092: '未知错误',
    40093: '未知错误',
    40094: '未知错误',
    40095: '未知错误',
    40096: '未知错误',
    40097: '参数错误',
    40098: '未知错误',
    40099: '未知错误',
    40100: '未知错误',
    40101: '未知错误',
    40102: '未知错误',
    40103: '未知错误',
    40104: '未知错误',
    40105: '未知错误',
    40106: '未知错误',
    40107: '未知错误',
    40108: '未知错误',
    40109: '未知错误',
    40110: '未知错误',
    40111: '未知错误',
    40112: '未知错误',
    40113: '未知错误',
    40114: '未知错误',
    40115: '未知错误',
    40116: '未知错误',
    40117: '分组名字不合法',
    40118: 'media_id 大小不合法',
    40119: 'button 类型错误',
    40120: '子 button 类型错误',
    40121: '不合法的 media_id 类型',
    40122: '未知错误',
    40123: '未知错误',
    40124: '未知错误',
    40125: '不合法的  AppID ，请开发者检查 AppID 的正确性，避免异常字符，注意大小写',
    40126: '未知错误',
    40127: '未知错误',
    40128: '未知错误',
    40129: '未知错误',
    40130: '未知错误',
    40131: '未知错误',
    40132: '微信号不合法',
    40133: '未知错误',
    40135: '未知错误',
    40136: '未知错误',
    40137: '不支持的图片格式',
    40138: '未知错误',
    40139: '未知错误',
    40140: '未知错误',
    40141: '未知错误',
    40142: '未知错误',
    40143: '未知错误',
    40144: '未知错误',
    40145: '未知错误',
    40146: '未知错误',
    40147: '未知错误',
    40148: '未知错误',
    40149: '未知错误',
    40150: '未知错误',
    40151: '未知错误',
    40152: '未知错误',
    40153: '未知错误',
    40154: '未知错误',
    40155: '请勿添加其他公众号的主页链接',
    40156: '未知错误',
    40157: '未知错误',
    40158: '未知错误',
    40159: '未知错误',
    40160: '未知错误',
    40161: '未知错误',
    40162: '未知错误',
    40163: 'oauth_code已使用',
    40164: '未知错误',
    40165: '未知错误',
    40166: '未知错误',
    40167: '未知错误',
    40168: '未知错误',
    40169: '未知错误',
    40170: '未知错误',
    40171: '未知错误',
    40172: '未知错误',
    40173: '未知错误',
    40174: '未知错误',
    40175: '未知错误',
    40176: '未知错误',
    40177: '未知错误',
    40178: '未知错误',
    40179: '未知错误',
    40180: '未知错误',
    40181: '未知错误',
    40182: '未知错误',
    40183: '未知错误',
    40184: '未知错误',
    40185: '未知错误',
    40186: '未知错误',
    40187: '未知错误',
    40188: '未知错误',
    40189: '未知错误',
    40190: '未知错误',
    40191: '未知错误',
    40192: '未知错误',
    40193: '未知错误',
    40194: '未知错误',
    40195: '未知错误',
    40196: '未知错误',
    40197: '未知错误',
    40198: '未知错误',
    40199: '运单 ID 不存在，未查到运单',
    40200: '未知错误',
    40201: '未知错误',
    40202: '未知错误',
    40203: '未知错误',
    40204: '未知错误',
    40205: '未知错误',
    40206: '未知错误',
    40207: '未知错误',
    40208: '未知错误',
    40209: '未知错误',
    40210: 'pages 中的path参数不存在或为空',
    40211: '未知错误',
    40212: 'paegs 当中存在不合法的query，query格式遵循URL标准，即k1=v1&amp;k2=v2',
    40213: '未知错误',
    40214: '未知错误',
    40215: '未知错误',
    40216: '未知错误',
    40217: '未知错误',
    40218: '未知错误',
    40219: 'pages不存在或者参数为空',
    40220: '未知错误',
    40221: '未知错误',
    40222: '未知错误',
    41001: '缺少 access_token 参数',
    41002: '缺少 appid 参数',
    41003: '缺少 refresh_token 参数',
    41004: '缺少 secret 参数',
    41005: '缺少多媒体文件数据，传输素材无视频或图片内容',
    41006: '缺少 media_id 参数',
    41007: '缺少子菜单数据',
    41008: '缺少 oauth code',
    41009: '缺少 openid',
    41010: '缺失 url 参数',
    41011: '未知错误',
    41012: '未知错误',
    41013: '未知错误',
    41014: '未知错误',
    41015: '未知错误',
    41016: '未知错误',
    41017: '未知错误',
    41018: '未知错误',
    41019: '未知错误',
    41020: '未知错误',
    41021: '未知错误',
    41024: '未知错误',
    41025: '未知错误',
    41026: '未知错误',
    41027: '未知错误',
    41028: '未知错误',
    41029: '未知错误',
    41030: 'page路径不正确，需要保证在现网版本小程序中存在，与app.json保持一致',
    41031: '未知错误',
    41032: '未知错误',
    41033: '只允许通过api创建的小程序使用',
    41034: '未知错误',
    41035: '未知错误',
    41036: '未知错误',
    41037: '未知错误',
    41038: '未知错误',
    42001: 'access_token 超时，请检查 access_token 的有效期，请参考基础支持 - 获取 access_token 中，对  access_token 的详细机制说明',
    42002: 'refresh_token 超时',
    42003: 'oauth_code 超时',
    42004: '未知错误',
    42005: '未知错误',
    42006: '未知错误',
    42007: '用户修改微信密码， accesstoken 和 refreshtoken 失效，需要重新授权',
    42008: '未知错误',
    42009: '未知错误',
    43001: '需要 GET 请求',
    43002: '需要 POST 请求',
    43003: '需要 HTTPS 请求',
    43004: '需要接收者关注',
    43005: '需要好友关系',
    43006: '未知错误',
    43007: '未知错误',
    43008: '未知错误',
    43009: '未知错误',
    43010: '未知错误',
    43011: '未知错误',
    43012: '未知错误',
    43013: '未知错误',
    43014: '未知错误',
    43015: '未知错误',
    43016: '小程序未认证',
    43017: '未知错误',
    43018: '未知错误',
    43019: '需要将接收者从黑名单中移除',
    43100: '未知错误',
    43101: '用户拒绝接受消息，如果用户之前曾经订阅过，则表示用户取消了订阅关系',
    43102: '未知错误',
    43103: '未知错误',
    43104: '未知错误',
    43105: '未知错误',
    43106: '未知错误',
    44001: '多媒体文件为空',
    44002: 'POST 的数据包为空',
    44003: '图文消息内容为空',
    44004: '文本消息内容为空',
    44005: '空白的列表',
    44006: '未知错误',
    44007: '未知错误',
    44997: '未知错误',
    44998: '未知错误',
    44999: '未知错误',
    45000: '未知错误',
    45001: '多媒体文件大小超过限制',
    45002: '消息内容超过限制',
    45003: '标题字段超过限制',
    45004: '描述字段超过限制',
    45005: '链接字段超过限制',
    45006: '图片链接字段超过限制',
    45007: '语音播放时间超过限制',
    45008: '图文消息超过限制',
    45009: '接口调用超过限制',
    45010: '创建菜单个数超过限制',
    45011: 'API 调用太频繁，请稍候再试',
    45012: '模板大小超过限制',
    45013: '未知错误',
    45014: '未知错误',
    45015: '回复时间超过限制',
    45016: '系统分组，不允许修改',
    45017: '分组名字过长',
    45018: '分组数量超过上限',
    45019: '未知错误',
    45020: '未知错误',
    45021: '未知错误',
    45022: '未知错误',
    45023: '未知错误',
    45024: '未知错误',
    45025: '未知错误',
    45026: '未知错误',
    45027: '未知错误',
    45028: '未知错误',
    45029: '未知错误',
    45030: '未知错误',
    45031: '未知错误',
    45032: '未知错误',
    45033: '未知错误',
    45034: '未知错误',
    45035: '未知错误',
    45036: '未知错误',
    45037: '未知错误',
    45038: '未知错误',
    45039: '未知错误',
    45040: '未知错误',
    45041: '未知错误',
    45042: '未知错误',
    45043: '未知错误',
    45044: '未知错误',
    45045: '未知错误',
    45046: '未知错误',
    45047: '客服接口下行条数超过上限',
    45048: '未知错误',
    45049: '未知错误',
    45050: '未知错误',
    45051: '未知错误',
    45052: '未知错误',
    45053: '未知错误',
    45054: '未知错误',
    45055: '未知错误',
    45056: '未知错误',
    45057: '未知错误',
    45058: '未知错误',
    45059: '未知错误',
    45060: '未知错误',
    45061: '未知错误',
    45062: '未知错误',
    45063: '未知错误',
    45064: '创建菜单包含未关联的小程序',
    45065: '相同 clientmsgid 已存在群发记录，返回数据中带有已存在的群发任务的 msgid',
    45066: '相同 clientmsgid 重试速度过快，请间隔1分钟重试',
    45067: 'clientmsgid 长度超过限制',
    45068: '未知错误',
    45069: '未知错误',
    45070: '未知错误',
    45071: '未知错误',
    45072: 'command字段取值不对',
    45073: '未知错误',
    45074: '未知错误',
    45075: '未知错误',
    45076: '未知错误',
    45077: '未知错误',
    45078: '未知错误',
    45079: '未知错误',
    45080: '下发输入状态，需要之前30秒内跟用户有过消息交互',
    45081: '已经在输入状态，不可重复下发',
    45082: '未知错误',
    45083: '未知错误',
    45084: '未知错误',
    45085: '未知错误',
    45086: '未知错误',
    45087: '未知错误',
    45088: '未知错误',
    45089: '未知错误',
    45090: '未知错误',
    45091: '未知错误',
    45092: '未知错误',
    45093: '未知错误',
    45094: '未知错误',
    45095: '未知错误',
    45096: '未知错误',
    45097: '未知错误',
    45098: '未知错误',
    45099: '未知错误',
    45100: '未知错误',
    45101: '未知错误',
    45102: '未知错误',
    45103: '未知错误',
    45104: '未知错误',
    45154: '未知错误',
    45155: '未知错误',
    45156: '未知错误',
    45157: '未知错误',
    45158: '未知错误',
    45159: '未知错误',
    45160: '未知错误',
    45161: '未知错误',
    45162: '未知错误',
    45163: '未知错误',
    45164: '未知错误',
    45165: '未知错误',
    45166: '未知错误',
    45167: '未知错误',
    45168: '未知错误',
    45501: '未知错误',
    46001: '不存在媒体数据，media_id 不存在',
    46002: '不存在的菜单版本',
    46003: '不存在的菜单数据',
    46004: '不存在的用户',
    46005: '未知错误',
    46006: '未知错误',
    46007: '未知错误',
    46008: '未知错误',
    46009: '未知错误',
    46101: '未知错误',
    47001: '解析 JSON/XML 内容错误',
    47002: '未知错误',
    47003: '模板参数不准确，可能为空或者不满足规则，errmsg会提示具体是哪个字段出错',
    47004: '每次提交的页面数超过1000（备注：每次提交页面数应小于或等于1000）',
    47005: '未知错误',
    47006: '当天提交页面数达到了配额上限，请明天再试',
    47101: '搜索结果总数超过了1000条',
    47102: 'next_page_info参数错误',
    47501: '参数 activity_id 错误',
    47502: '参数 target_state 错误',
    47503: '参数 version_type 错误',
    47504: 'activity_id',
    48001: 'api 功能未授权，请确认公众号已获得该接口，可以在公众平台官网 - 开发者中心页中查看接口权限',
    48002: '粉丝拒收消息（粉丝在公众号选项中，关闭了 “ 接收消息 ” ）',
    48003: '未知错误',
    48004: 'api 接口被封禁，请登录 mp.weixin.qq.com 查看详情',
    48005: 'api 禁止删除被自动回复和自定义菜单引用的素材',
    48006: 'api 禁止清零调用次数，因为清零次数达到上限',
    48007: '未知错误',
    48008: '没有该类型消息的发送权限',
    48009: '未知错误',
    48010: '未知错误',
    48011: '未知错误',
    48012: '未知错误',
    48013: '该视频非新接口上传，不能用于视频消息群发',
    48014: '该视频审核状态异常，请检查后重试',
    48015: '该账号无留言功能权限',
    48016: '该账号不满足智能配置"观看更多"视频条件',
    49001: '未知错误',
    49002: '未知错误',
    49003: '未知错误',
    49004: '未知错误',
    49005: '未知错误',
    49006: '未知错误',
    49007: '未知错误',
    49008: '未知错误',
    49009: '未知错误',
    49010: '未知错误',
    49300: '未知错误',
    49301: '未知错误',
    49302: '未知错误',
    50001: '用户未授权该 api',
    50002: '用户受限，可能是用户帐号被冻结或注销',
    50003: '未知错误',
    50004: '未知错误',
    50005: '用户未关注公众号',
    50006: '未知错误',
    51000: '未知错误',
    51001: '未知错误',
    51002: '未知错误',
    51003: '未知错误',
    51004: '未知错误',
    51005: '未知错误',
    51006: '未知错误',
    51007: '未知错误',
    51008: '未知错误',
    51009: '未知错误',
    51010: '未知错误',
    51011: '未知错误',
    51012: '未知错误',
    51013: '未知错误',
    51014: '未知错误',
    51015: '未知错误',
    51020: '未知错误',
    51021: '未知错误',
    51022: '未知错误',
    51023: '未知错误',
    51024: '未知错误',
    51025: '未知错误',
    51026: '未知错误',
    51027: '未知错误',
    51028: '未知错误',
    51029: '未知错误',
    51030: '未知错误',
    51031: '未知错误',
    51032: '未知错误',
    51033: '未知错误',
    51034: '未知错误',
    51035: '未知错误',
    52000: '未知错误',
    52001: '未知错误',
    52002: '未知错误',
    52003: '未知错误',
    52004: '未知错误',
    52005: '未知错误',
    52006: '未知错误',
    52007: '未知错误',
    52008: '未知错误',
    52009: '未知错误',
    52010: '未知错误',
    52011: '未知错误',
    52012: '未知错误',
    52013: '未知错误',
    52014: '未知错误',
    52015: '未知错误',
    52016: '未知错误',
    52017: '未知错误',
    52018: '未知错误',
    52019: '未知错误',
    53000: '未知错误',
    53001: '未知错误',
    53002: '未知错误',
    53003: '未知错误',
    53010: '名称格式不合法',
    53011: '名称检测命中频率限制',
    53012: '禁止使用该名称',
    53013: '公众号：名称与已有公众号名称重复;小程序：该名称与已有小程序名称重复',
    53014: '公众号：公众号已有{名称  A+}时，需与该帐号相同主体才可申请{名称 A};小程序：小程序已有{名称 A+}时，需与该帐号相同主体才可申请{名称 A}',
    53015: '公众号：该名称与已有小程序名称重复，需与该小程序帐号相同主体才可申请;小程序：该名称与已有公众号名称重复，需与该公众号帐号相同主体才可申请',
    53016: '公众号：该名称与已有多个小程序名称重复，暂不支持申请;小程序：该名称与已有多个公众号名称重复，暂不支持申请',
    53017: '公众号：小程序已有{名称  A+}时，需与该帐号相同主体才可申请{名称 A};小程序：公众号已有{名称 A+}时，需与该帐号相同主体才可申请{名称 A}',
    53018: '名称命中微信号',
    53019: '名称在保护期内',
    53100: '未知错误',
    53101: '未知错误',
    53102: '未知错误',
    53103: '未知错误',
    53200: '本月功能介绍修改次数已用完',
    53201: '功能介绍内容命中黑名单关键字',
    53202: '本月头像修改次数已用完',
    53203: '未知错误',
    53204: '未知错误',
    53300: '超出每月次数限制',
    53301: '超出可配置类目总数限制',
    53302: '当前账号主体类型不允许设置此种类目',
    53303: '提交的参数不合法',
    53304: '与已有类目重复',
    53305: '包含未通过IPC校验的类目',
    53306: '修改类目只允许修改类目资质，不允许修改类目ID',
    53307: '只有审核失败的类目允许修改',
    53308: '审核中的类目不允许删除',
    53309: '社交红包不允许删除',
    53310: '类目超过上限，但是可以添加apply_reason参数申请更多类目',
    53311: '需要提交资料信息',
    60005: '未知错误',
    60006: '未知错误',
    61000: '未知错误',
    61001: '未知错误',
    61002: '未知错误',
    61003: '未知错误',
    61004: 'api 功能未授权，请确认公众号/小程序已获得该接口，可以在公众平台官网 - 开发者中心页中查看接口权限',
    61005: '未知错误',
    61006: '未知错误',
    61007: '未知错误',
    61008: '未知错误',
    61009: '未知错误',
    61010: '未知错误',
    61011: '未知错误',
    61012: '未知错误',
    61013: '未知错误',
    61014: '未知错误',
    61015: '未知错误',
    61016: '未知错误',
    61017: '未知错误',
    61018: '未知错误',
    61019: '未知错误',
    61020: '未知错误',
    61021: '未知错误',
    61022: '未知错误',
    61023: '未知错误',
    61024: '未知错误',
    61025: '未知错误',
    61026: '未知错误',
    61027: '未知错误',
    61028: '未知错误',
    61029: '未知错误',
    61030: '未知错误',
    61051: '未知错误',
    61052: '未知错误',
    61053: '未知错误',
    61054: '未知错误',
    61055: '未知错误',
    61056: '未知错误',
    61058: '未知错误',
    61061: '未知错误',
    61063: '未知错误',
    61064: '未知错误',
    61065: '未知错误',
    61066: '未知错误',
    61067: '未知错误',
    61068: '未知错误',
    61069: '未知错误',
    61070: '未知错误',
    61100: '未知错误',
    61101: '未知错误',
    61102: '未知错误',
    61200: '未知错误',
    61300: '未知错误',
    61301: '未知错误',
    61302: '未知错误',
    61303: '未知错误',
    61304: '未知错误',
    61305: '未知错误',
    61306: '未知错误',
    61307: '未知错误',
    61308: '未知错误',
    61309: '未知错误',
    61310: '未知错误',
    61311: '未知错误',
    61312: '未知错误',
    61313: '未知错误',
    61314: '未知错误',
    61315: '未知错误',
    61316: '未知错误',
    61317: '未知错误',
    61318: '未知错误',
    61319: '未知错误',
    61320: '未知错误',
    61321: '未知错误',
    61322: '未知错误',
    61323: '未知错误',
    61324: '未知错误',
    61325: '未知错误',
    61326: '未知错误',
    61327: '未知错误',
    61328: '未知错误',
    61329: '未知错误',
    61330: '未知错误',
    61331: '未知错误',
    61332: '未知错误',
    61333: '未知错误',
    61334: '未知错误',
    61335: '未知错误',
    61336: '未知错误',
    61337: '未知错误',
    61338: '未知错误',
    61339: '未知错误',
    61340: '未知错误',
    61341: '未知错误',
    61342: '未知错误',
    61343: '未知错误',
    61344: '未知错误',
    61345: '未知错误',
    61346: '未知错误',
    61347: '未知错误',
    61348: '未知错误',
    61350: '未知错误',
    61351: '未知错误',
    61400: '未知错误',
    61401: '未知错误',
    61450: '系统错误 (system error)',
    61451: '参数错误 (invalid parameter)',
    61452: '无效客服账号 (invalid kf_account)',
    61453: '客服帐号已存在 (kf_account exsited)',
    61454: '客服帐号名长度超过限制 ( 仅允许 10 个英文字符，不包括 @ 及 @ 后的公众号的微信号 )(invalid  kf_acount  length)',
    61455: '客服帐号名包含非法字符 ( 仅允许英文 + 数字 )(illegal character in   kf_account)',
    61456: '客服帐号个数超过限制 (10 个客服账号 )(kf_account count exceeded)',
    61457: '无效头像文件类型 (invalid  file type)',
    61500: '日期格式错误',
    61501: '未知错误',
    61502: '未知错误',
    61503: '未知错误',
    62001: '未知错误',
    62002: '未知错误',
    62003: '未知错误',
    62004: '未知错误',
    62005: '未知错误',
    63001: '部分参数为空',
    63002: '无效的签名',
    63003: '未知错误',
    63004: '未知错误',
    63149: '未知错误',
    63152: '未知错误',
    63153: '未知错误',
    63154: '未知错误',
    63155: '未知错误',
    63156: '未知错误',
    63157: '未知错误',
    63158: '未知错误',
    63159: '未知错误',
    63160: '未知错误',
    63161: '未知错误',
    63162: '未知错误',
    63163: '未知错误',
    63164: '未知错误',
    63165: '未知错误',
    63166: '未知错误',
    63167: '未知错误',
    63168: '未知错误',
    63169: '未知错误',
    63170: '未知错误',
    63171: '未知错误',
    63172: '未知错误',
    63173: '未知错误',
    63174: '未知错误',
    63175: '未知错误',
    63176: '未知错误',
    63177: '未知错误',
    63178: '未知错误',
    63179: '未知错误',
    63180: '未知错误',
    63181: '未知错误',
    63182: '未知错误',
    65001: '未知错误',
    65002: '未知错误',
    65003: '未知错误',
    65004: '未知错误',
    65104: '未知错误',
    65105: '未知错误',
    65106: '未知错误',
    65107: '未知错误',
    65109: '未知错误',
    65110: '未知错误',
    65111: '未知错误',
    65112: '未知错误',
    65113: '未知错误',
    65114: '未知错误',
    65115: '未知错误',
    65116: '未知错误',
    65117: '未知错误',
    65118: '未知错误',
    65119: '未知错误',
    65120: '未知错误',
    65201: '未知错误',
    65202: '未知错误',
    65203: '未知错误',
    65204: '未知错误',
    65205: '未知错误',
    65301: '不存在此 menuid 对应的个性化菜单',
    65302: '没有相应的用户',
    65303: '没有默认菜单，不能创建个性化菜单',
    65304: 'MatchRule 信息为空',
    65305: '个性化菜单数量受限',
    65306: '不支持个性化菜单的帐号',
    65307: '个性化菜单信息为空',
    65308: '包含没有响应类型的 button',
    65309: '个性化菜单开关处于关闭状态',
    65310: '填写了省份或城市信息，国家信息不能为空',
    65311: '填写了城市信息，省份信息不能为空',
    65312: '不合法的国家信息',
    65313: '不合法的省份信息',
    65314: '不合法的城市信息',
    65315: '未知错误',
    65316: '该公众号的菜单设置了过多的域名外跳（最多跳转到 3 个域名的链接）',
    65317: '不合法的 URL',
    65318: '未知错误',
    65319: '未知错误',
    65400: '未知错误',
    65401: '未知错误',
    65402: '未知错误',
    65403: '未知错误',
    65404: '未知错误',
    65405: '未知错误',
    65406: '未知错误',
    65407: '未知错误',
    65408: '未知错误',
    65409: '未知错误',
    65410: '未知错误',
    65411: '未知错误',
    65412: '未知错误',
    65413: '未知错误',
    65414: '未知错误',
    65415: '未知错误',
    65416: '未知错误',
    65417: '未知错误',
    65450: '未知错误',
    68002: '未知错误',
    68003: '未知错误',
    68004: '未知错误',
    68005: '未知错误',
    68006: '未知错误',
    68007: '未知错误',
    68008: '未知错误',
    68009: '未知错误',
    68010: '未知错误',
    68011: '未知错误',
    68012: '未知错误',
    68013: '未知错误',
    68014: '未知错误',
    68015: '未知错误',
    68016: '未知错误',
    68017: '未知错误',
    68018: '未知错误',
    68019: '未知错误',
    68020: '未知错误',
    68021: '未知错误',
    68022: '未知错误',
    68023: '未知错误',
    68024: '未知错误',
    68025: '未知错误',
    68026: '未知错误',
    68027: '未知错误',
    68028: '未知错误',
    68029: '未知错误',
    68030: '未知错误',
    68031: '未知错误',
    68032: '未知错误',
    68033: '未知错误',
    68034: '未知错误',
    68035: '未知错误',
    68036: '未知错误',
    68037: '未知错误',
    68038: '未知错误',
    68039: '未知错误',
    68040: '未知错误',
    68041: '未知错误',
    68042: '未知错误',
    68043: '未知错误',
    68044: '未知错误',
    68045: '未知错误',
    68046: '未知错误',
    68500: '未知错误',
    68501: '未知错误',
    71001: '未知错误',
    71002: '未知错误',
    71003: '未知错误',
    71004: '未知错误',
    71005: '未知错误',
    71006: '未知错误',
    71007: '未知错误',
    71008: '未知错误',
    72001: '未知错误',
    72002: '未知错误',
    72003: '未知错误',
    72004: '未知错误',
    72005: '未知错误',
    72006: '未知错误',
    72007: '未知错误',
    72008: '未知错误',
    72009: '未知错误',
    72010: '未知错误',
    72011: '未知错误',
    72012: '未知错误',
    72013: '未知错误',
    72014: '未知错误',
    72015: '未知错误',
    72016: '未知错误',
    72017: '未知错误',
    72018: '未知错误',
    72019: '未知错误',
    72020: '未知错误',
    72021: '未知错误',
    72022: '未知错误',
    72023: '未知错误',
    72024: '未知错误',
    72025: '未知错误',
    72026: '未知错误',
    72027: '未知错误',
    72028: '未知错误',
    72029: '未知错误',
    72030: '未知错误',
    72031: '未知错误',
    72032: '未知错误',
    72033: '未知错误',
    72034: '未知错误',
    72035: '未知错误',
    72036: '未知错误',
    72037: '未知错误',
    72038: '未知错误',
    72039: '未知错误',
    72040: '未知错误',
    72041: '未知错误',
    72042: '未知错误',
    72043: '未知错误',
    72044: '未知错误',
    72045: '未知错误',
    72046: '未知错误',
    72047: '未知错误',
    72048: '未知错误',
    72049: '未知错误',
    72050: '未知错误',
    72051: '未知错误',
    72052: '未知错误',
    72053: '未知错误',
    72054: '未知错误',
    72055: '未知错误',
    72056: '未知错误',
    72057: '未知错误',
    72058: '未知错误',
    72059: '未知错误',
    72060: '未知错误',
    72061: '未知错误',
    72062: '未知错误',
    72063: '未知错误',
    72064: '未知错误',
    72065: '未知错误',
    72066: '未知错误',
    72067: '未知错误',
    72068: '未知错误',
    72069: '未知错误',
    72070: '未知错误',
    72071: '未知错误',
    72072: '未知错误',
    72073: '未知错误',
    72074: '未知错误',
    72075: '未知错误',
    72076: '未知错误',
    72077: '未知错误',
    72078: '未知错误',
    72079: '未知错误',
    72080: '未知错误',
    72081: '未知错误',
    72082: '未知错误',
    72083: '未知错误',
    72084: '未知错误',
    72085: '未知错误',
    72086: '未知错误',
    72087: '未知错误',
    72088: '未知错误',
    72089: '未知错误',
    72090: '未知错误',
    72091: '未知错误',
    72092: '未知错误',
    72093: '未知错误',
    73000: '未知错误',
    73001: '未知错误',
    73002: '未知错误',
    73003: '未知错误',
    73004: '未知错误',
    73005: '未知错误',
    73006: '未知错误',
    73007: '未知错误',
    73008: '未知错误',
    73009: '未知错误',
    73010: '未知错误',
    73011: '未知错误',
    73012: '未知错误',
    73013: '未知错误',
    73014: '未知错误',
    73015: '未知错误',
    73016: '未知错误',
    73100: '未知错误',
    73101: '未知错误',
    73103: '未知错误',
    73104: '未知错误',
    73105: '未知错误',
    73106: '未知错误',
    73107: '未知错误',
    73108: '未知错误',
    73109: '未知错误',
    73200: '未知错误',
    73201: '未知错误',
    73202: '未知错误',
    73203: '未知错误',
    73204: '未知错误',
    73205: '朋友的券玩法升级中，当前暂停创建，请创建其他类型卡券',
    73206: '朋友的券玩法升级中，当前暂停券点充值，请创建其他类型卡券',
    73207: '朋友的券玩法升级中，当前暂停开通券点账户',
    73208: '朋友的券玩法升级中，当前不支持修改库存',
    73209: '朋友的券玩法升级中，当前不支持修改有效期',
    73210: '当前批次不支持修改卡券批次库存',
    73211: '不再支持配置网页链接跳转，请选择小程序替代',
    73212: '未知错误',
    73213: '未知错误',
    73214: '未知错误',
    73215: '支付打通券能力已下线，请直接使用微信支付代金券API：https://pay.weixin.qq.com/wiki/doc/apiv3/wxpay/marketing/convention/chapter1_1.shtml',
    73216: '不合法的按钮名字，请从中选择一个:使用礼品卡/立即使用/去点外卖',
    73217: '礼品卡本身没有设置appname和path，不允许在修改接口设置',
    73218: '未授权使用礼品卡落地页跳转小程序功能',
    74000: '未知错误',
    74001: '未知错误',
    74002: '未知错误',
    74003: '未知错误',
    74004: '未知错误',
    74005: '未知错误',
    74007: '未知错误',
    74008: '未知错误',
    74009: '未知错误',
    74010: '未知错误',
    74011: '未知错误',
    74021: '未知错误',
    74022: '未知错误',
    74023: '未知错误',
    74040: '未知错误',
    74041: '未知错误',
    75001: '未知错误',
    75002: '未知错误',
    75003: '未知错误',
    75004: '未知错误',
    75005: '未知错误',
    75006: '未知错误',
    75007: '未知错误',
    75008: '未知错误',
    75009: '未知错误',
    75010: '未知错误',
    75011: '未知错误',
    75012: '未知错误',
    75013: '未知错误',
    75014: '未知错误',
    75015: '未知错误',
    75016: '未知错误',
    75017: '未知错误',
    75018: '未知错误',
    75019: '未知错误',
    80000: '系统错误，请稍后再试',
    80001: '参数格式校验错误',
    80002: '签名失败',
    80003: '该日期订单未生成',
    80004: '用户未绑卡',
    80005: '姓名不符',
    80006: '身份证不符',
    80007: '获取城市信息失败',
    80008: '未找到指定少儿信息',
    80009: '少儿身份证不符',
    80010: '少儿未绑定',
    80011: '签约号不符',
    80012: '该地区局方配置不存在',
    80013: '调用方appid与局方配置不匹配',
    80014: '获取消息账号失败',
    80066: '非法的插件版本',
    80067: '找不到使用的插件',
    80082: '没有权限使用该插件',
    80101: '商家未接入',
    80111: '实名校验code不存在',
    80112: 'code并发冲突',
    80113: '无效code',
    80201: 'report_type无效',
    80202: 'service_type无效',
    80300: '申请单不存在',
    80301: '申请单不属于该账号',
    80302: '激活号段有重叠',
    80303: '码格式错误',
    80304: '该码未激活',
    80305: '激活失败',
    80306: '码索引超出申请范围',
    80307: '申请已存在',
    80308: '子任务未完成',
    80309: '子任务文件过期',
    80310: '子任务不存在',
    80311: '获取文件失败',
    80312: '加密数据失败',
    80313: '加密数据密钥不存在，请联系接口人申请',
    81000: '未知错误',
    81001: '未知错误',
    81002: '未知错误',
    81004: '未知错误',
    81005: '未知错误',
    81006: '未知错误',
    81007: '未知错误',
    81008: '未知错误',
    81009: '未知错误',
    81010: '未知错误',
    81011: '未知错误',
    81012: '未知错误',
    81013: '未知错误',
    81014: '未知错误',
    81015: '未知错误',
    81016: '未知错误',
    81017: '未知错误',
    81018: '未知错误',
    81019: '未知错误',
    81020: '未知错误',
    81021: '未知错误',
    81022: '未知错误',
    81023: '未知错误',
    81024: '未知错误',
    81025: '未知错误',
    81026: '未知错误',
    81027: '未知错误',
    81028: '未知错误',
    81029: '未知错误',
    81030: '未知错误',
    81031: '未知错误',
    82000: '未知错误',
    82001: '未知错误',
    82002: '未知错误',
    82003: '未知错误',
    82010: '未知错误',
    82011: '未知错误',
    82020: '未知错误',
    82021: '未知错误',
    82022: '未知错误',
    82023: '未知错误',
    82024: '未知错误',
    82025: '未知错误',
    82026: '未知错误',
    82027: '未知错误',
    82100: '未知错误',
    82101: '未知错误',
    82102: '未知错误',
    82103: '未知错误',
    82200: '公众平台账号与服务id不匹配',
    82201: '该停车场已存在，请勿重复添加',
    82202: '该停车场信息不存在，请先导入',
    82203: '停车场价格格式不正确',
    82204: 'appid与code不匹配',
    82205: 'wx_park_code字段为空',
    82206: 'wx_park_code无效或已过期',
    82207: '电话字段为空',
    82208: '关闭时间格式不正确',
    82300: '该appid不支持开通城市服务插件',
    82301: '添加插件失败',
    82302: '未添加城市服务插件',
    82303: 'fileid无效',
    82304: '临时文件过期',
    83000: '未知错误',
    83001: '未知错误',
    83002: '未知错误',
    83003: '未知错误',
    83004: '未知错误',
    83005: '未知错误',
    83006: '未知错误',
    83007: '未知错误',
    83008: '未知错误',
    83009: '未知错误',
    83200: '未知错误',
    83201: '未知错误',
    83202: '未知错误',
    83203: '未知错误',
    83204: '未知错误',
    83205: '未知错误',
    83206: '未知错误',
    83207: '未知错误',
    83208: '未知错误',
    83209: '未知错误',
    84001: '未知错误',
    84002: '未知错误',
    84003: '未知错误',
    84004: '未知错误',
    84005: '未知错误',
    84006: '未知错误',
    85001: '微信号不存在或微信号设置为不可搜索',
    85002: '小程序绑定的体验者数量达到上限',
    85003: '微信号绑定的小程序体验者达到上限',
    85004: '微信号已经绑定',
    85005: '未知错误',
    85006: '标签格式错误',
    85007: '页面路径错误',
    85008: '当前小程序没有已经审核通过的类目，请添加类目成功后重试',
    85009: '已经有正在审核的版本',
    85010: 'item_list 有项目为空',
    85011: '标题填写错误',
    85012: '无效的审核 id',
    85013: '无效的自定义配置',
    85014: '无效的模板编号',
    85015: '该账号不是小程序账号',
    85015: '版本输入错误',
    85016: '域名数量超过限制 ，总数不能超过1000',
    85017: '没有新增域名，请确认小程序已经添加了域名或该域名是否没有在第三方平台添加',
    85018: '域名没有在第三方平台设置',
    85019: '没有审核版本',
    85020: '审核状态未满足发布',
    85021: '未知错误',
    85022: '未知错误',
    85023: '审核列表填写的项目数不在 1-5 以内',
    85024: '未知错误',
    85025: '未知错误',
    85026: '未知错误',
    85027: '未知错误',
    85028: '未知错误',
    85029: '未知错误',
    85030: '未知错误',
    85031: '未知错误',
    85032: '未知错误',
    85033: '未知错误',
    85034: '未知错误',
    85035: '未知错误',
    85036: '未知错误',
    85038: '未知错误',
    85039: '未知错误',
    85040: '未知错误',
    85041: '未知错误',
    85042: '未知错误',
    85043: '模板错误',
    85044: '代码包超过大小限制',
    85045: 'ext_json 有不存在的路径',
    85046: 'tabBar 中缺少 path',
    85047: 'pages 字段为空',
    85048: 'ext_json 解析失败',
    85049: '未知错误',
    85050: '未知错误',
    85051: 'version_desc或者preview_info超限',
    85052: '未知错误',
    85053: '未知错误',
    85054: '未知错误',
    85055: '未知错误',
    85056: '未知错误',
    85057: '未知错误',
    85058: '未知错误',
    85059: '未知错误',
    85060: '未知错误',
    85061: '未知错误',
    85062: '未知错误',
    85063: '未知错误',
    85064: '找不到模板',
    85065: '模板库已满',
    85066: '链接错误',
    85067: '未知错误',
    85068: '测试链接不是子链接',
    85069: '校验文件失败',
    85070: '个人类型小程序无法设置二维码规则',
    85071: '已添加该链接，请勿重复添加',
    85072: '该链接已被占用',
    85073: '二维码规则已满',
    85074: '小程序未发布, 小程序必须先发布代码才可以发布二维码跳转规则',
    85075: '个人类型小程序无法设置二维码规则',
    85077: '小程序类目信息失效（类目中含有官方下架的类目，请重新选择类目）',
    85078: '未知错误',
    85079: '小程序没有线上版本，不能进行灰度',
    85080: '小程序提交的审核未审核通过',
    85081: '无效的发布比例',
    85082: '当前的发布比例需要比之前设置的高',
    85083: '搜索标记位被封禁，无法修改',
    85084: '非法的 status 值，只能填 0 或者 1',
    85085: '小程序提审数量已达本月上限，请点击查看<a href="https://developers.weixin.qq.com/community/minihome/doc/00022ce7b209f09f363b9c62958401?blockType=99" target="_blank" rel="noopener noreferrer">《自助临时申请额度》<span></span></a>',
    85086: '提交代码审核之前需提前上传代码',
    85087: '小程序已使用 api navigateToMiniProgram，请声明跳转 appid 列表后再次提交',
    85088: '未知错误',
    85089: '未知错误',
    85090: '未知错误',
    85091: '小程序的搜索开关被关闭。请访问设置页面打开开关再重试',
    85092: 'preview_info格式错误',
    85093: 'preview_info 视频或者图片个数超限',
    85094: '需提供审核机制说明信息',
    85101: '小程序不能发送该运动类型或运动类型不存在',
    85102: '数值异常',
    86000: '不是由第三方代小程序进行调用',
    86001: '不存在第三方的已经提交的代码',
    86002: '小程序还未设置昵称、头像、简介。请先设置完后再重新提交',
    86003: '未知错误',
    86004: '未知错误',
    86005: '未知错误',
    86006: '未知错误',
    86007: '小程序禁止提交',
    86008: '服务商被处罚，限制全部代码提审能力',
    86009: '服务商新增小程序代码提审能力被限制',
    86010: '服务商迭代小程序代码提审能力被限制',
    87006: '小游戏不能提交',
    87007: '未知错误',
    87008: '未知错误',
    87009: '无效的签名',
    87010: '未知错误',
    87011: '现网已经在灰度发布，不能进行版本回退',
    87012: '该版本不能回退，可能的原因：1:无上一个线上版用于回退 2:此版本为已回退版本，不能回退 3:此版本为回退功能上线之前的版本，不能回退',
    87013: '撤回次数达到上限（每天5次，每个月 10 次）',
    87014: '未知错误',
    87015: '未知错误',
    87016: '未知错误',
    87017: '未知错误',
    87018: '未知错误',
    87019: '未知错误',
    87080: '未知错误',
    87081: '未知错误',
    87082: '未知错误',
    87083: '未知错误',
    87084: '未知错误',
    87085: '未知错误',
    88000: '未知错误',
    88001: '未知错误',
    88002: '未知错误',
    88003: '未知错误',
    88004: '未知错误',
    88005: '未知错误',
    88007: '未知错误',
    88008: '未知错误',
    88009: '未知错误',
    88010: '未知错误',
    88011: '未知错误',
    89000: 'account has bound open，该公众号/小程序已经绑定了开放平台帐号',
    89001: 'not same contractor，Authorizer 与开放平台帐号主体不相同',
    89002: 'open not exists，该公众号/小程序未绑定微信开放平台帐号。',
    89003: '该开放平台帐号并非通过 api 创建，不允许操作',
    89004: '该开放平台帐号所绑定的公众号/小程序已达上限（100  个）',
    89005: '未知错误',
    89006: '未知错误',
    89007: '未知错误',
    89008: '未知错误',
    89009: '未知错误',
    89010: '未知错误',
    89011: '未知错误',
    89012: '未知错误',
    89013: '未知错误',
    89014: '未知错误',
    89015: '未知错误',
    89016: '未知错误',
    89017: '未知错误',
    89018: '未知错误',
    89019: '业务域名无更改，无需重复设置',
    89020: '尚未设置小程序业务域名，请先在第三方平台中设置小程序业务域名后在调用本接口',
    89021: '请求保存的域名不是第三方平台中已设置的小程序业务域名或子域名',
    89022: '未知错误',
    89029: '业务域名数量超过限制，最多可以添加100个业务域名',
    89030: '未知错误',
    89031: '未知错误',
    89032: '未知错误',
    89033: '未知错误',
    89034: '未知错误',
    89035: '未知错误',
    89231: '个人小程序不支持调用 setwebviewdomain 接口',
    89235: '未知错误',
    89236: '该插件不能申请',
    89237: '已经添加该插件',
    89238: '申请或使用的插件已经达到上限',
    89239: '该插件不存在',
    89240: '未知错误',
    89241: '未知错误',
    89242: '未知错误',
    89243: '该申请为“待确认”状态，不可删除',
    89244: '不存在该插件 appid',
    89245: '未知错误',
    89246: '未知错误',
    89247: '系统内部错误',
    89248: '未知错误',
    89249: '未知错误',
    89250: '内部错误',
    89251: '模板消息已下发，待法人人脸核身校验',
    89253: '法人&amp;企业信息一致性校验中',
    89254: '未知错误',
    89255: 'code参数无效，请检查code长度以及内容是否正确；注意code_type的值不同需要传的code长度不一样',
    89256: 'token 信息有误',
    89257: '该插件版本不支持快速更新',
    89258: '当前小程序帐号存在灰度发布中的版本，不可操作快速更新',
    89259: '未知错误',
    89300: '订单无效',
    89401: '系统不稳定，请稍后再试，如多次失败请通过社区反馈',
    89402: '该小程序不在待审核队列，请检查是否已提交审核或已审完',
    89403: '本单属于平台不支持加急种类，请等待正常审核流程',
    89404: '本单已加速成功，请勿重复提交',
    89405: '本月加急额度已用完，请提高提审质量以获取更多额度',
    89501: '公众号有未处理的确认请求，请稍候重试',
    89502: '请耐心等待管理员确认',
    89503: '此次调用需要管理员确认，请耐心等候',
    89504: '正在等管理员确认，请耐心等待',
    89505: '正在等管理员确认，请稍候重试',
    89506: '该IP调用求请求已被公众号管理员拒绝，请24小时后再试，建议调用前与管理员沟通确认',
    89507: '该IP调用求请求已被公众号管理员拒绝，请1小时后再试，建议调用前与管理员沟通确认',
    90001: '未知错误',
    90002: '未知错误',
    90003: '未知错误',
    90004: '未知错误',
    90005: '未知错误',
    90006: '未知错误',
    90007: '未知错误',
    90009: '未知错误',
    90010: '未知错误',
    90011: '未知错误',
    90012: '未知错误',
    90013: '未知错误',
    90014: '未知错误',
    90015: '未知错误',
    90016: '未知错误',
    90017: '未知错误',
    90018: '未知错误',
    91001: '不是公众号快速创建的小程序',
    91002: '小程序发布后不可改名',
    91003: '改名状态不合法',
    91004: '昵称不合法',
    91005: '昵称 15 天主体保护',
    91006: '昵称命中微信号',
    91007: '昵称已被占用',
    91008: '昵称命中 7 天侵权保护期',
    91009: '需要提交材料',
    91010: '其他错误',
    91011: '查不到昵称修改审核单信息',
    91012: '其它错误',
    91013: '占用名字过多',
    91014: '+号规则 同一类型关联名主体不一致',
    91015: '原始名不同类型主体不一致',
    91016: '名称占用者 ≥2',
    91017: '+号规则 不同类型关联名主体不一致',
    91018: '组织类型小程序发布后，侵权被清空昵称，需走认证改名',
    91019: '小程序正在审核中',
    92000: '该经营资质已添加，请勿重复添加',
    92002: '附近地点添加数量达到上线，无法继续添加',
    92003: '地点已被其它小程序占用',
    92004: '附近功能被封禁',
    92005: '地点正在审核中',
    92006: '地点正在展示小程序',
    92007: '地点审核失败',
    92008: '小程序未展示在该地点',
    93009: '小程序未上架或不可见',
    93010: '地点不存在',
    93011: '个人类型小程序不可用',
    93012: '非普通类型小程序（门店小程序、小店小程序等）不可用',
    93013: '从腾讯地图获取地址详细信息失败',
    93014: '同一资质证件号重复添加',
    93015: '附近类目审核中',
    93016: '服务标签个数超限制（官方最多5个，自定义最多4个）',
    93017: '服务标签或者客服的名字不符合要求',
    93018: '服务能力中填写的小程序appid不是同主体小程序',
    93019: '申请类目之后才能添加附近地点',
    93020: 'qualification_list无效',
    93021: 'company_name字段为空',
    93022: 'credential字段为空',
    93023: 'address字段为空',
    93024: 'qualification_list字段为空',
    93025: '服务appid对应的path不存在',
    94001: '未知错误',
    94002: '未知错误',
    94003: '未知错误',
    94004: '未知错误',
    94005: '未知错误',
    94006: '未知错误',
    94007: '未知错误',
    94008: '未知错误',
    94009: '未知错误',
    94010: '未知错误',
    94011: '未知错误',
    94012: 'appid和商户号的绑定关系不存在',
    95001: '未知错误',
    95002: '未知错误',
    95101: '未知错误',
    95102: '未知错误',
    95103: '未知错误',
    95104: '未知错误',
    95105: '未知错误',
    95106: '未知错误',
    101000: '未知错误',
    101001: '未知错误',
    101002: '未知错误',
    200002: '入参错误',
    200011: '此账号已被封禁，无法操作',
    200012: '个人模板数已达上限，上限25个',
    200013: '此模板已被封禁，无法选用',
    200014: '模板  tid 参数错误',
    200016: 'start  参数错误',
    200017: 'limit  参数错误',
    200018: '类目  ids 缺失',
    200019: '类目  ids 不合法',
    200020: '关键词列表  kidList 参数错误',
    200021: '场景描述  sceneDesc 参数错误',
    300001: '禁止创建/更新商品（如商品创建功能被封禁） 或 禁止编辑&amp;更新房间',
    300002: '名称长度不符合规则',
    300003: '价格输入不合规（如现价比原价大、传入价格非数字等）',
    300004: '商品名称存在违规违法内容',
    300005: '商品图片存在违规违法内容',
    300006: '图片上传失败（如mediaID过期）',
    300007: '线上小程序版本不存在该链接',
    300008: '添加商品失败',
    300009: '商品审核撤回失败',
    300010: '商品审核状态不对（如商品审核中）',
    300011: '操作非法（API不允许操作非API创建的商品）',
    300012: '没有提审额度（每天500次提审额度）',
    300013: '提审失败',
    300014: '审核中，无法删除（非零代表失败）',
    300017: '商品未提审',
    300021: '商品添加成功，审核失败',
    300022: '此房间号不存在',
    300023: '房间状态 拦截（当前房间状态不允许此操作）',
    300024: '商品不存在',
    300025: '商品审核未通过',
    300026: '房间商品数量已经满额',
    300027: '导入商品失败',
    300028: '房间名称违规',
    300029: '主播昵称违规',
    300030: '主播微信号不合法',
    300031: '直播间封面图不合规',
    300032: '直播间分享图违规',
    300033: '添加商品超过直播间上限',
    300034: '主播微信昵称长度不符合要求',
    300035: '主播微信号不存在',
    300036: '主播微信号未实名认证',
    600001: '未知错误',
    600002: '未知错误',
    600003: '未知错误',
    928000: '票据已存在',
    928001: '票据不存在',
    930555: '未知错误',
    930556: '未知错误',
    930557: '未知错误',
    930558: '未知错误',
    930559: '沙盒环境openid无效',
    930560: '未知错误',
    930561: '参数错误',
    930562: '未知错误',
    930563: '订单不存在',
    930564: '沙盒环境调用无配额',
    930565: '未知错误',
    930566: '未知错误',
    930567: '未知错误',
    930568: '不支持个人类型小程序',
    930569: '已经开通不需要再开通',
    930570: '未知错误',
    930571: '该商户没有内测权限，请先申请权限: https://wj.qq.com/s2/7243532/fcfb/',
    931010: '未知错误',
    6000100: '未知错误',
    6000101: '未知错误',
    6000102: '未知错误',
    9001001: 'POST 数据参数不合法',
    9001002: '远端服务不可用',
    9001003: 'Ticket 不合法',
    9001004: '获取摇周边用户信息失败',
    9001005: '获取商户信息失败',
    9001006: '获取 OpenID 失败',
    9001007: '上传文件缺失',
    9001008: '上传素材的文件类型不合法',
    9001009: '上传素材的文件尺寸不合法',
    9001010: '上传失败',
    9001020: '帐号不合法',
    9001021: '已有设备激活率低于 50% ，不能新增设备',
    9001022: '设备申请数不合法，必须为大于 0 的数字',
    9001023: '已存在审核中的设备 ID 申请',
    9001024: '一次查询设备 ID 数量不能超过 50',
    9001025: '设备 ID 不合法',
    9001026: '页面 ID 不合法',
    9001027: '页面参数不合法',
    9001028: '一次删除页面 ID 数量不能超过 10',
    9001029: '页面已应用在设备中，请先解除应用关系再删除',
    9001030: '一次查询页面 ID 数量不能超过 50',
    9001031: '时间区间不合法',
    9001032: '保存设备与页面的绑定关系参数错误',
    9001033: '门店 ID 不合法',
    9001034: '设备备注信息过长',
    9001035: '设备申请参数不合法',
    9001036: '查询起始值 begin 不合法',
    9002008: '未知错误',
    9002009: '未知错误',
    9002010: '未知错误',
    9002011: '未知错误',
    9002012: '未知错误',
    9002013: '未知错误',
    9002016: '未知错误',
    9002017: '未知错误',
    9002026: '未知错误',
    9002041: '未知错误',
    9002044: '未知错误',
    9002052: '未知错误',
    9007003: '未知错误',
    9007004: '未知错误',
    9007005: '未知错误',
    9008001: '未知错误',
    9008002: '未知错误',
    9008003: '未知错误',
    9008004: '未知错误',
    9200001: '未知错误',
    9200002: '未知错误',
    9200003: '未知错误',
    9200211: '未知错误',
    9200231: '未知错误',
    9200232: '未知错误',
    9200233: '未知错误',
    9200235: '未知错误',
    9200236: '未知错误',
    9200295: '未知错误',
    9200297: '未知错误',
    9200298: '未知错误',
    9200299: '未知错误',
    9200300: '未知错误',
    9201000: '未知错误',
    9201001: '未知错误',
    9201002: '未知错误',
    9201003: '未知错误',
    9201004: '未知错误',
    9201005: '未知错误',
    9201006: '未知错误',
    9201007: '未知错误',
    9201008: '未知错误',
    9201009: '未知错误',
    9201010: '未知错误',
    9201011: '未知错误',
    9201012: '未知错误',
    9201013: '未知错误',
    9201014: '未知错误',
    9201015: '未知错误',
    9201016: '未知错误',
    9201017: '未知错误',
    9201018: '未知错误',
    9201019: '未知错误',
    9201020: '未知错误',
    9201021: '未知错误',
    9201022: '未知错误',
    9201023: '未知错误',
    9201024: '未知错误',
    9201026: '未知错误',
    9201027: '未知错误',
    9201029: '未知错误',
    9201031: '未知错误',
    9201032: '未知错误',
    9201033: '未知错误',
    9201034: '未知错误',
    9201035: '未知错误',
    9202000: '未知错误',
    9202001: '未知错误',
    9202002: '未知错误',
    9202003: '未知错误',
    9202004: '未知错误',
    9202005: '未知错误',
    9202006: '未知错误',
    9202010: '未知错误',
    9202011: '未知错误',
    9202012: '未知错误',
    9202013: '未知错误',
    9202016: '未知错误',
    9202017: '未知错误',
    9202018: '未知错误',
    9202020: '未知错误',
    9202021: '未知错误',
    9203000: '未知错误',
    9203001: '未知错误',
    9203002: '未知错误',
    9203003: '未知错误',
    9203004: '未知错误',
    9203999: '未知错误',
    9204000: '未知错误',
    9204001: '未知错误',
    9204002: '未知错误',
    9205000: '未知错误',
    9205001: '未知错误',
    9205002: '未知错误',
    9205010: '未知错误',
    9205101: '未知错误',
    9205201: '未知错误',
    9206000: '未知错误',
    9206001: '未知错误',
    9206002: '未知错误',
    9206003: '未知错误',
    9206200: '未知错误',
    9206201: '未知错误',
    9206900: '未知错误',
    9206901: '未知错误',
    9206910: '未知错误',
    9206911: '未知错误',
    9207000: '未知错误',
    9207001: '未知错误',
    9207002: '未知错误',
    9207003: '未知错误',
    9207004: '未知错误',
    9207005: '未知错误',
    9207006: '未知错误',
    9207007: '未知错误',
    9207008: '未知错误',
    9207009: '未知错误',
    9207010: '未知错误',
    9207011: '未知错误',
    9207012: '未知错误',
    9207013: '未知错误',
    9207028: '未知错误',
    9207029: '未知错误',
    9207030: '未知错误',
    9207031: '未知错误',
    9207032: '未知错误',
    9207033: '未知错误',
    9207034: '未知错误',
    9207050: '未知错误',
    9210000: '未知错误',
    9291000: '未知错误',
    9291001: '未知错误',
    9291002: '未知错误',
    9291003: '未知错误',
    9291004: '未知错误',
    9291005: '未知错误',
    9291006: '未知错误',
    9299999: '未知错误',
    9300001: '未知错误',
    9300002: '未知错误',
    9300003: '未知错误',
    9300004: '未知错误',
    9300005: '未知错误',
    9300006: '未知错误',
    9300007: '未知错误',
    9300008: '未知错误',
    9300009: '未知错误',
    9300010: '未知错误',
    9300011: '未知错误',
    9300012: '未知错误',
    9300013: '未知错误',
    9300014: '未知错误',
    9300015: '未知错误',
    9300016: '未知错误',
    9300017: '未知错误',
    9300018: '未知错误',
    9300019: '未知错误',
    9300020: '未知错误',
    9300021: '未知错误',
    9300022: '未知错误',
    9300023: '未知错误',
    9300024: '未知错误',
    9300501: '快递侧逻辑错误，详细原因需要看 delivery_resultcode, 请先确认一下编码方式，python建议 json.dumps(b, ensure_ascii=False)，php建议 json_encode($arr, JSON_UNESCAPED_UNICODE)',
    9300502: '快递公司系统错误',
    9300503: 'delivery_id 不存在',
    9300504: 'service_type 不存在',
    9300505: '未知错误',
    9300506: '运单 ID 已经存在轨迹，不可取消',
    9300507: 'Token 不正确',
    9300508: '未知错误',
    9300509: '未知错误',
    9300510: '未知错误',
    9300511: '未知错误',
    9300512: '模板格式错误，渲染失败',
    9300513: '未知错误',
    9300514: '未知错误',
    9300515: '未知错误',
    9300516: '未知错误',
    9300517: 'update_type 不正确,请使用"bind" 或者“unbind”',
    9300520: '未知错误',
    9300521: '未知错误',
    9300522: '未知错误',
    9300523: '未知错误',
    9300524: '订单已取消（一般为重复取消订单）',
    9300525: 'bizid未绑定',
    9300526: '参数字段长度不正确',
    9300527: '未知错误',
    9300528: '未知错误',
    9300529: '账号已绑定过',
    9300530: '解绑的biz_id不存在',
    9300531: 'bizid无效 或者密码错误',
    9300532: '绑定已提交，审核中',
    9300533: '未知错误',
    9300534: 'add_source=2时，wx_appid和当前小程序不同主体',
    9300535: 'shop字段商品缩略图 url、商品名称为空或者非法，或者商品数量为0',
    9300536: 'add_source=2时，wx_appid无效',
    9300537: '未知错误',
    9300538: '未知错误',
    9300539: '未知错误',
    9300540: '未知错误',
    9300541: '未知错误',
    9300601: '未知错误',
    9300602: '未知错误',
    9300603: '未知错误',
    9300604: '未知错误',
    9300605: '未知错误',
    9300606: '未知错误',
    9300607: '未知错误',
    9300608: '未知错误',
    9300609: '未知错误',
    9300610: '未知错误',
    9300611: '未知错误',
    9300701: '未知错误',
    9300702: '未知错误',
    9300703: '未知错误',
    9300704: '未知错误',
    9300705: '未知错误',
    9300706: '未知错误',
    9300707: '未知错误',
    9300708: '未知错误',
    9300801: '无效的微信号',
    9300802: '服务号未开通导购功能',
    9300803: '微信号已经绑定为导购',
    9300804: '该微信号不是导购',
    9300805: '微信号已经被其他账号绑定为导购',
    9300806: '粉丝和导购不存在绑定关系',
    9300807: '标签值无效，不是可选标签值',
    9300808: '标签值不存在',
    9300809: '展示标签值不存在',
    9300810: '导购昵称太长，最多16个字符',
    9300811: '只支持mmbiz.qpic.cn域名的图片',
    9300812: '达到导购绑定个数限制',
    9300813: '达到导购粉丝绑定个数限制',
    9300814: '敏感词个数超过上限',
    9300815: '快捷回复个数超过上限',
    9300816: '文字素材个数超过上限',
    9300817: '小程序卡片素材个数超过上限',
    9300818: '图片素材个数超过上限',
    9300819: 'mediaid 有误',
    9300820: '可查询标签类别超过上限',
    9300821: '小程序卡片内appid不符合要求',
    9300822: '标签类别的名字无效',
    9300823: '查询聊天记录时间参数有误',
    9300824: '自动回复字数太长',
    9300825: '导购群组id错误',
    9300826: '维护中',
    9301001: '未知错误',
    9301002: '未知错误',
    9301003: '未知错误',
    9301004: '未知错误',
    9301006: '未知错误',
    9301007: '未知错误',
    9301008: '未知错误',
    9301009: '未知错误',
    9301010: '未知错误',
    9301011: '未知错误',
    9301012: '未知错误',
    9400001: '该开发小程序已开通小程序直播权限，不支持发布版本。如需发版，请解绑开发小程序后再操作。',
    9401001: '商品已存在',
    9401002: '商品不存在',
    9401003: '类目已存在',
    9401004: '类目不存在',
    9401005: 'SKU已存在',
    9401006: 'SKU不存在',
    9401007: '属性已存在',
    9401008: '属性不存在',
    9401020: '非法参数',
    9401021: '没有商品权限',
    9401022: '未知错误',
    9401023: '未知错误',
    9401024: '未知错误',
    9401025: '未知错误',
    9402001: '未知错误',
    9402002: '未知错误',
    9402003: '未知错误',
    9402004: '未知错误',
    9402005: '未知错误',
    9402006: '未知错误',
    9402007: '未知错误',
    9402008: '未知错误',
    9402009: '未知错误',
    9402010: '未知错误',
    9402011: '未知错误',
    9402012: '未知错误',
    9402013: '未知错误',
    9402014: '未知错误',
    9402015: '未知错误',
    9402016: '未知错误',
    9402101: '未知错误',
    9402102: '未知错误',
    9402202: '请勿频繁提交，待上一次操作完成后再提交',
    9402301: '未知错误',
    9403000: '消息类型错误!',
    9403001: '消息字段的内容过长!',
    9403002: '消息字段的内容违规!',
    9403003: '发送的微信号太多!',
    9403004: '存在错误的微信号!',
    9410000: '直播间列表为空',
    9410001: '获取房间失败',
    9410002: '获取商品失败',
    9410003: '获取回放失败',
}
let base64 = require('js-base64').Base64
    /**
     * http请求
     */

export default class httpUtil {

    /**
     * get请求
     * @param url
     * @param params
     * @returns {Promise<R>}
     */



    static get(url, params = {}) {
        return new Promise(async(resolve, reject) => {
            try {
                let query = await qs.stringify(params);
                let res = null;
                if (!params) {
                    res = await axios.get(url);
                } else {
                    res = await axios.get(url + '?' + query);
                }
                resolve(res);
            } catch (error) {
                let errorMsg = `请求报错路径： ${url} \n 请求错误信息: ${error}`;
                //console.log(errorMsg)
                reject(error);
            }
        });
    }

    /**
     * POST请求
     * @param url
     * @param params
     * @returns {Promise<R>}
     */
    static post(url, params = {}, type) {
        return new Promise(async(resolve, reject) => {
            try {
                ////console.log('params:',params)
                let query = base64.encode(JSON.stringify(params));
                const data = {
                    data: query,
                }
                let res = ''
                if (params && url && url.indexOf("pcLoginCallBack") == -1) {
                    res = await axios.post(url, qs.stringify(data), { headers: type });
                    //可以调整为白名单数组
                } else if (url && (url.indexOf("pcLoginCallBack") != -1 || url.indexOf("componentCallBack") != -1)) {
                    //登录回调
                    res = await axios.post(url, qs.stringify(params), { headers: type });
                } else {
                    res = await axios.post(url);
                }
                /*
        *   SUCCESS(200,"成功"),
            ERROR(500,"系统异常"),
            FAULT_TOLERANT(501,"容错"),
            FAIL(201,"操作失败"),
            NO_LOGIN(401,"未登陆"),
            NO_ROLE(403,"无权限"),
            NO_PARAM(202,"参数缺失"),
            PARAM_CHECK(203,"参数校验失败"),
            NO_DATA(204,"无数据"),
            TEST(999999,"TEST")
        * */
                if ("code" in res) {
                    if (res.code < 999) { //区分微信和自己的api提示
                        if (
                            res.code == 0 ||
                            res.code == 200 ||
                            res.code == 209 ||
                            res.code == 204

                        ) {
                            resolve(res);
                        } else if (res.code == 401) {
                            clearLoginInfo();
                            reject(res.data);
                        } else if (res.code == 201) {
                            reject(res);
                        } else {
                            reject(res.data);
                            let { message } = res;
                            message = message ?
                                message === "GENERAL" ?
                                "系统繁忙" :
                                message :
                                "系统繁忙";
                            Message({
                                    message: message,
                                    type: 'error'
                                })
                                // try{
                                // 	//检测
                                // 	// const blob = new Blob([res], {type: 'application/vnd.ms-excel'});
                                // 	return
                                // 	resolve(res);
                                // }catch (e) {

                            // }
                        }
                    } else {
                        Message({
                            message: gzhErrcode[res.code],
                            type: 'error'
                        })
                    }
                }
                //console.log('params:',res)
                resolve(res);
            } catch (error) {
                let errorMsg = `请求报错路径：${url} \n 请求错误信息: ${error}`;
                reject(errorMsg);
            }
        });
    }
    static noBase64Post(url, params = {}, type) {
        return new Promise(async(resolve, reject) => {
            try {
                const data = {
                    ...params
                }
                let res = ''
                if (params && url && url.indexOf("pcLoginCallBack") == -1) {
                    res = await axios.post(url, qs.stringify(data), { headers: type });
                    //可以调整为白名单数组
                } else if (url && (url.indexOf("pcLoginCallBack") != -1 || url.indexOf("componentCallBack") != -1)) {
                    //登录回调
                    res = await axios.post(url, qs.stringify(params), { headers: type });
                } else {
                    res = await axios.post(url);
                }
                /*
        *   SUCCESS(200,"成功"),
            ERROR(500,"系统异常"),
            FAULT_TOLERANT(501,"容错"),
            FAIL(201,"操作失败"),
            NO_LOGIN(401,"未登陆"),
            NO_ROLE(403,"无权限"),
            NO_PARAM(202,"参数缺失"),
            PARAM_CHECK(203,"参数校验失败"),
            NO_DATA(204,"无数据"),
            TEST(999999,"TEST")
        * */
                if ("code" in res) {
                    if (res.code < 999) { //区分微信和自己的api提示
                        if (
                            res.code == 0 ||
                            res.code == 200 ||
                            res.code == 209 ||
                            res.code == 204

                        ) {
                            resolve(res);
                        } else if (res.code == 401) {
                            clearLoginInfo();
                            reject(res.data);
                        } else if (res.code == 201) {
                            reject(res);
                        } else {
                            reject(res.data);
                            let { message } = res;
                            message = message ?
                                message === "GENERAL" ?
                                "系统繁忙" :
                                message :
                                "系统繁忙";
                            Message({
                                    message: message,
                                    type: 'error'
                                })
                                // try{
                                // 	//检测
                                // 	// const blob = new Blob([res], {type: 'application/vnd.ms-excel'});
                                // 	return
                                // 	resolve(res);
                                // }catch (e) {

                            // }
                        }
                    } else {
                        Message({
                            message: gzhErrcode[res.code],
                            type: 'error'
                        })
                    }
                }
                //console.log('params:',res)
                resolve(res);
            } catch (error) {
                let errorMsg = `请求报错路径：${url} \n 请求错误信息: ${error}`;
                reject(errorMsg);
            }
        });
    }
    static filePost(url, params = {}, fileList) {
        return new Promise(async(resolve, reject) => {
            try {
                const data = new FormData()
                for (let key in params) {
                    data.append(key, params[key]);
                }
                if (fileList) {
                    for (let key in fileList) {
                        for (let file of fileList[key]) {
                            data.append(key, file);
                        }
                    }
                }
                const res = await axios({
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    method: 'post',
                    url: url,
                    data: data
                })
                if ("code" in res) {
                    if (res.code < 999) { //区分微信和自己的api提示
                        if (
                            res.code == 0 ||
                            res.code == 200 ||
                            res.code == 209 ||
                            res.code == 204

                        ) {
                            resolve(res);
                        } else if (res.code == 401) {
                            clearLoginInfo();
                            reject(res.data);
                        } else if (res.code == 201) {
                            reject(res);
                        } else {
                            reject(res.data);
                            let { message } = res;
                            message = message ?
                                message === "GENERAL" ?
                                "系统繁忙" :
                                message :
                                "系统繁忙";
                            Message({
                                    message: message,
                                    type: 'error'
                                })
                                // try{
                                // 	//检测
                                // 	// const blob = new Blob([res], {type: 'application/vnd.ms-excel'});
                                // 	return
                                // 	resolve(res);
                                // }catch (e) {

                            // }
                        }
                    } else {
                        Message({
                            message: gzhErrcode[res.code],
                            type: 'error'
                        })
                    }
                }
                //console.log('params:',res)
                resolve(res);
            } catch (error) {
                let errorMsg = `请求报错路径：${url} \n 请求错误信息: ${error}`;
                reject(errorMsg);
            }
        });
    }

    static zcget(url, params = {}) {
        return axios({
            method: "get",
            params: params,
            url: url
        }).then(res => {
            return res;
        })
    }

    static async zcpost(url, data = {}) {
        let query = await qs.stringify(data);
        return axios({
            method: "post",
            data: qs.stringify({
                data: base64.encode(JSON.stringify({ url: url + "?" + query }))
            }),
            url: "/api/crmPc/zcApi/getDataByNet"
        }).then(res => {
            return res;
        })
    }
    static async fileStream(url, params, fileName) {
            console.log("文件流");
            return axios({
                method: "get",
                url: `${url}`,
                data: params,
                headers: {
                    "Accept": "application/octet-stream"
                },
                responseType: 'blob',
            }).then(res => {
                var file = new File([res.data], fileName, { type: "application/msword;charset=utf-8" })
                var a = document.createElement('a')
                a.download = fileName
                a.href = window.URL.createObjectURL(file)
                a.click()
                a.remove();
            })
        }
        /**@method 上传图片 */
    static uploadFile(url, file, fun) {
        var xhr = new XMLHttpRequest();
        xhr.open("post", url, false);
        // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        let fd = new FormData()
        fd.append('file', file)
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    fun(JSON.parse(xhr.responseText));
                } else {
                    console.error(xhr.statusText)
                }
            };
        }
        xhr.send(fd);
    }
}