<template>
    <div class="view">
        <fileInstitution ref="fileInstitution" code="05" :yearValue="yearValue" :title="title" :content="content"
            :keywordData="keywordData" @change="changeFormData" :wordData="wordData" />
    </div>
</template>

<script>
export default {
    name: "institution9142",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        },
        isAgain: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            title: "关于发布实施《研发辅助台账及核算管理制度》的通知",
            content: "为进一步规范和加强对研发经费的管理，提高资金使用效益，根据国家有关规定并结合本公司的实际情况，明确研发经费的开支范围和标准，严格审批程序，并按照研发项目和承担研发任务设立研发辅助台账归集、核算研发费用，加强研发投入的专项审核，特重新修订《研发辅助台账及核算管理制度》，请各部门按此办法严格执行。",
            keywordData: [
                { title: '研发辅助账' },
                { title: '核算' },
                { title: '制度' }
            ],
            formData: {
                fileDay: "",
                hostName: "",
                printName: "",
            },
            checkedCompany:{}
        };
    },
    created(){
        if(!this.isAgain){
            this.content=this.content.replace("重新修订","制定")
        }
    },
    methods: {
        changeFormData(formData){
            this.formData=formData;
        }
    },
};
</script>

<style lang="scss" scoped>
.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 2px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemTitle {
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 25px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C; // #000;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C; // #000;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 25px !important;
        line-height: 25px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 25px !important;
        line-height: 25px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            div {
                color: red;
            }

            border-bottom: 4px solid red;
            padding-bottom: 20px;
            margin-bottom: 50px;
        }
    }
}
</style>