<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发准备金管理制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D002</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent" style="padding-top: 350px;">
                <div class="wordTtile">
                    <div class="flex-c-c textc">
                        <div class="zdhq fs24">研发准备金管理制度</div>
                    </div>
                    <div class="textc pjTitle mt10 mb20 fs35">{{wordData.isAgain}}</div>
                </div>
            </div>
            <div class="wordFoot">
                <div class="textc fs30">
                    {{ checkedCompany.companyName }}
                </div>
                <div class="textc fs25">
                    <el-date-picker v-model="formData.fileDay" class="dayInput w230" value-format="yyyy-MM-dd"
                        format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                    </el-date-picker>(自动转为中文)
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 1 页 共 5 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发准备金管理制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D002</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordTtile">
                    <div class="textc pjTitle mt50 mb10 fs30">{{ checkedCompany.companyName }}</div>
                    <div class="flex-c-c textc lineBottom ">
                        <div class="zdhq fs40">研发准备金管理制度</div>
                    </div>
                </div>
                <div class="lineheight45">
                    <div class="fw600 textc">
                        第一章 总则
                    </div>
                    <div class="textIndent40">
                        <div>
                            研发准备金的概念、制订的意义、依据和适用范围。
                        </div>
                        <div>1、 研发准备金是指为保证{{ checkedCompany.companyName }}
                            （以下简称“公司”）内部研究开发项目的资金需求,根据相关法律法规及公司总经理办公会的决议,在研发项目实际展开前或研究开发过程中提前计提安排的专门用于内部研发项目支出的资金。</div>
                        <div>
                            2、为进一步规范和加强对研发经费的管理，提高资金使用效益，根据国家有关政策规定并结合本公司的实际情况，特制定本制度。</div>
                        <div>
                            3、研发准备金单独核算，专款专用。项目组或者相关部门应严格按照本制度的规定，制订内部管理办法，加强对研发准备金的监督和管理。</div>
                        <div>
                            4、公司在产品、技术、材料、工艺、标准的研究、开发过程中发生的各项费用的管理按本制度执行。</div>
                    </div>
                    <div class="textIndent40">
                        <div class="fs9">备注：相关政策依据：</div>
                        <div class="fs9">
                            1.2005年12月31日，《国家中长期科学技术发展规划纲要（2006-2020）》（国发［2005］44号）规定：八、若干重要政策和措施1、实施激励企业技术创新的财税政策：“结合企业所得税和企业财务制度改革，鼓励企业建立技术研究开发专项资金制度”。
                        </div>
                        <div class="fs9">
                            2.2006年12月4日，《企业财务通则》（财政部令［2007］第41号）：第38条：“企业技术研发和科技成果转化项目所需经费，可以通过建立研发准备金筹措，据实列入相关资产成本或当期费用。
                        </div>
                        <div class="fs9">
                            3.2007年9月4日，《财政部关于企业加强研发费用财务管理的若干意见》（财企［2007］194号）规定：“四、企业可以建立研发准备金制度，根据研发计划及资金需求，提前安排资金，确保研发资金的需要，研发费用按实际发生额列入成本（费用）。
                        </div>
                    </div>
                    <div class="fw600 textc">
                        第二章 提取与分配
                    </div>
                    <div class="textIndent40">
                        <div>
                            研发项目的研发阶段分为研究阶段和开发阶段，基于谨慎性原则，现实中开发阶段会形成无形资产以及现行的会计准则规定，公司仅计提研究阶段的准备金。（下文所指的研发准备金无特别说明时仅指研究阶段的准备金）
                        </div>
                        <div>期初计提研发准备金是一种事前确认，以后会计期间所发生的研发费用可用研发准备金冲抵，具体方法有：</div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 2 页 共 5 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发准备金管理制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D002</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt80">
                    <div class="textIndent40">
                        <div>
                            a、销售额基准法。该法是以上年度销售额（或当年很可能实现的预期销售额）为基础来确定研发准备金。计算公式为：预提的研发准备金=上年度销售额*研发准备金提取比例，提取比例实际工作中主要参照高新技术企业认定标准中关于研发费用占销售额的比例。
                        </div>
                        <div>b、公司研发中心根据以往的经验和研究开发项目未来的总体资金需求核定一个数额。</div>
                        <div>
                            c、利润基准法。利润基准法是以上年度税后利润为基础，按照一定比例提取的研发准备金的一种方法。这种方法降低了企业由于研发支出过多而造成的财务风险，但不适用企业在初创期或者亏损有明显转机的时候。
                        </div>
                        <div>我公司将根据不同的发展阶段选择不同的方法结合公司实际情况计提研发准备金</div>
                        <div>公司财务在一季度结束之前根据公司总经理办公会批准生效的“研发准备金预算”，据此提前准备研发经费并计提好研发准备金，确保按研发计划提供资金保障。</div>
                        <div>研发准备金公司将根据研发进度、各项目的研发预算在不同的研发项目之间进行分配。</div>
                    </div>
                    <div class="fw600 textc">
                        第三章 使用范围
                    </div>
                    <div class="textIndent40">
                        <div>研发准备金的开支范围主要包括以下几方面：</div>
                        <div>1、研发人员人工：</div>
                        <div>从事研究开发活动人员（也称研发人员）全年工资薪金，包括基本工资、奖金、津贴、补贴、社保、年终加薪、加班工资以及与其任职或者受雇有关的其他支出。</div>
                        <div>2、直接投入费用：</div>
                        <div>（1）研发活动直接消耗的材料、燃料和动力费用。</div>
                        <div>（2）用于中间试验和产品试制的模具、工艺装备开发及制造费，不构成固定资产的样品、样机及一般测试手段购置费，试制产品的检验费。</div>
                        <div>（3）用于研发活动的仪器、设备的运行维护、调整、检验、维修等费用，以及通过经营租赁方式租入的用于研发活动的仪器、设备租赁费。</div>
                        <div>3、折旧：折旧费用包括为执行研究开发活动而购置的仪器和设备以及研究开发项目在用建筑物的折旧费用。</div>
                        <div>4、长期费用摊销：包括研发设施改建、改装、装修和修理过程中发生的长期待摊费用摊销。</div>
                        <div>5、设计费：为新产品和新工艺的构思、开发和制造，进行工序、技术规范、操作特性方面的设计等发生的费用。</div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 3 页 共 5 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发准备金管理制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D002</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt80">
                    <div class="textIndent40">
                        <div>
                            6、设备调试费：主要包括工装准备中研究开发活动所发生的费用（如研制生产机器、模具和工具，改变生产和质量控制程序，或制定新方法及标准等）。为大规模批量化和商业化生产所进行的常规性工装准备和工业工程发生的费用不能计入。
                        </div>
                        <div>7、无形资产摊销：因研究开发活动需要购入的专有技术（包括专利、非专利发明、许可证、专有技术、设计和计算方法等）所发生的费用摊销。</div>
                        <div>
                            8、委外投入：是指公司委托境内其他企业、大学、研究机构、转制院所、技术专业服务机构和境外机构进行研究开发活动所发生的费用（项目成果为公司拥有，且与公司的主要经营业务紧密相关）。委托外部研究开发费用的发生金额应按照独立交易原则确定。
                        </div>
                        <div>
                            9、其他费用：与研发活动直接相关的其他费用，如技术图书资料费、资料翻译费、专家咨询费、高新科技研发保险费，研发成果的检索、分析、评议、论证、鉴定、评审、评估、验收费用，知识产权的申请费、注册费、代理费，差旅费、会议费等。
                        </div>
                    </div>
                    <div class="fw600 textc">
                        第四章 管理
                    </div>
                    <div class="textIndent40">
                        <div>1、研发项目的立项与审批</div>
                        <div>项目申请人或负责人负责编制研发项目立项报告、项目经费的预算。研发中心负责汇总形成《XX年研发计划项目及预算》表经研发中心主任审核后，报公司预算管理委员会及总经理办公会讨论、审议审批。
                        </div>
                        <div>2、 研发准备金的预算编制</div>
                        <div>“研发费用资金预算”由每个研发项目组分别编制，
                            对于研发过程中所需的新材料不明价格的，在列预算时可以根据市场同类产品的价格进行预估，允许15%的预算误差率。同一研发人员参与不同项目的研发时，应将人工费合理分配，无法分配的需注明研发人员的研发工时数（估计）。
                        </div>
                        <div>3、研发准备金的支出管理</div>
                        <div>
                            （1）研发材料、设备、仪器、模具费用等支出，外包与外单位合作研发的费用支出必须先进行申请，申请或者使用时需注明所属项目，财务部接到付款申请后首先应该审核该项支出是否在预算范围内，所申请的支出财务部应先行在对应的预算中进行扣除，费用实际发生时再予冲抵。如超出预算的财务部不予通过，并提醒研发部需要走追加预算的流程；
                        </div>
                        <div>（2）研发人员的职工薪酬、研发设备的折旧、场地租赁等固定费用由财务部 按结算周期直接从预算中扣除；</div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 4 页 共 5 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发准备金管理制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D002</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt50">
                    <div class="textIndent40">
                        <div>
                            （3）其它类别的研发费用发生之后，研发部参照公司“财务报销制度”申请报销，财务部据“研发费用资金预算”进行审核，支出在预算之内的，由部门主管审核，财务部审核及总经理签字后可以报销，预算之外的费用必须经过公司总经理签核后才能报销，并应进行“追加预算”。
                        </div>
                    </div>
                    <div class="fw600 textc">
                        第五章 核算与披露
                    </div>
                    <div class="textIndent40">
                        <div>1、研发准备金的核算</div>
                        <div>
                            公司按照研发预算，提取当年预算使用的研发准备金作为研发项目的专项资金。计提研发准备时，全部计入当期研发费用，预提不足的年末补计提。当年多计提的研发准备金不得转回；当期发生研究支出时，当期直接冲减研发准备金，本年年底未用完的研发准备金所得税汇算清缴时纳税调增，次年用完后再纳税调减。
                        </div>
                        <div>根据《 财政部 国家税务总局科技部关于完善研究开发费用税前加计扣除政策的通知 》（
                            财税〔2015〕119号文件的规定，研发支出需设置项目辅助账核算，具体核算时，根据研发项目名称登记编号，专属费用采用直接对应和算法，公用费用采用总量计量再分摊的办法，对不同的费用采用不同的分摊依据。
                        </div>
                        <div>开发阶段的支出，在支付时直接计入研发支出，达到资本化条件时研发支出转为无形资产。</div>
                        <div>2、研发准备金的披露。</div>
                        <div>
                            在与研发准备金配套的会计准则尚未出台前，年末未使用完的研发准备金，我公司在会计报表的开发支出中列示。研发准备金在附注中将披露计提的依据（方法）、本年计提金额及本年实际支出金额、准备金结余等项目。
                        </div>
                    </div>
                    <div class="fw600 textc">
                        第六章 附则
                    </div>
                    <div class="textIndent40">
                        <div>1、本办法由财务部门负责解释及修订。</div>
                        <div>2、本办法自发布之日起执行。</div>
                    </div>
                    <div class="text-align-right mt50">{{ checkedCompany.companyName }}</div>
                    <div class="text-align-right">
                        <el-date-picker v-model="formData.fileDay" class="miniDay w190" value-format="yyyy-MM-dd"
                            format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                        </el-date-picker>(自动转为中文)
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 5 页 共 5 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution913",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                fileDay: "",
            },
            checkedCompany:{},
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        },
        textTime(time) {
            if (time) {
                let dayArr = time.split("-");
                let fileDay = "";
                let dayObj = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
                let tObj = ["年", "月", "日"];
                for (let i in dayArr) {
                    let t = dayArr[i];
                    if (i == 0 || t < 10) {
                        for (let childKey of t) {
                            fileDay += dayObj[childKey];
                        }
                    } else {
                        if (t > 10) {
                            if(t>=20){
                                fileDay += dayObj[String(t).slice(0, 1)]; 
                            }
                            fileDay += "十";
                            if(String(t).slice(1)>0){
                                fileDay += dayObj[String(t).slice(1)];
                            }
                        } else {
                            fileDay += dayObj[t];
                        }
                    }
                    fileDay += tObj[i];
                }
                return fileDay;
            } else {
                return "";
            }
        },
        timeStr(time) {
            if (time) {
                let dayStr = time.replace("-", "年");
                dayStr = dayStr.replace("-", "月");
                return dayStr + "日";
            }
        },
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData.fileDay = data.fileDay;
                    } else {
                        this.formData.fileDay = this.checkedCompany.fileDay;
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    created() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.frame {
    padding: 0 10px;
    border: 1px solid #000;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemTitle {
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 20px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>