<template>
	<div class="notice">
		<!-- 公告 -->
		<!-- <van-notice-bar :title="datas.title" :text="datas.noticeText" left-icon="volume-o" :background="datas.backColor"
      :color="datas.textColor" /> -->
		<div class="headBox flexLeft" :style="{ backgroundColor: datas.backColor }">
			<div class="noticeTitle" :style="{ color: datas.titleColor, fontStyle: datas.isItalic ? 'italic' : 'inherit',backgroundColor: datas.backColor }">
				{{ datas.title }}</div>
			<div class="noticeFrame">
				<!-- <div :class="datas.showType" :style="{ color: datas.textColor }">
					{{ datas.noticeText }}
				</div> -->
				<div>
					<van-notice-bar class="vnotice" :scrollable="false" :background="datas.backColor"
						:color="datas.textColor">
						<van-swipe vertical class="notice-swipe" :autoplay="3000" :touchable="false"
							:show-indicators="false">
							<div v-if="datas.addMethod === 'manual'">
								<div v-if="datas.activity.length > 0">
									<van-swipe-item v-for="(row, index) in datas.activity" :key="index">{{ row.title
									}}</van-swipe-item>
								</div>
								<div v-else>
									<van-swipe-item>请选择公告内容</van-swipe-item>
								</div>
							</div>
							<div v-else>
								<div v-if="datas.autoData.length > 0">
									<van-swipe-item v-for="(row, index) in datas.autoData" :key="index">{{ row.title
									}}</van-swipe-item>
								</div>
								<div v-else>
									<van-swipe-item>请前往新闻动态，上传内容</van-swipe-item>
								</div>
							</div>

						</van-swipe>
					</van-notice-bar>
				</div>
				<div v-if="datas.addMethod === 'auto'">
					<van-notice-bar class="vnotice" :scrollable="false" :background="datas.backColor"
						:color="datas.textColor">
						<van-swipe vertical class="notice-swipe" :autoplay="3000" :touchable="false"
							:show-indicators="false">

						</van-swipe>
					</van-notice-bar>
				</div>
			</div>

			<div style="width: 1px;height: 20px;background: #bebebe;position: absolute;top: 12px;left: 15%;"></div>
		</div>
		<!-- 删除组件 -->
		<slot name="deles" />
	</div>
</template>

<script>
export default {
	name: 'notice',
	props: {
		datas: Object,
	},
}
</script>

<style scoped lang="scss">
.vnotice {
	height: 45px;
}

.notice-swipe {
	height: 45px;
	line-height: 45px;
}

.notice {
	position: relative;
}

.flexLeft {
	display: flex;
	align-items: center;
	justify-content: left;
}

.headBox {
	width: 100%;
	background: white;
	font-size: 15px;
	box-sizing: border-box;
	position: relative;
	box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);

	.noticeTitle {
		font-size: 17px;
		font-weight: bold;
		width: 56px;
		padding: 10px 0 10px 10px;
	}

	.noticeFrame {
		color: #000000;
		width: 315px;
		overflow: hidden;
		position: relative;
		height: 45px;

		.leftRightRoll {
			margin-top: 13px;
			animation: leftRightMove 5s infinite linear;
		}

		.topBottomRoll {
			animation: topBottomMove 3s infinite linear;
		}
	}
}
</style>
