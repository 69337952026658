import { render, staticRenderFns } from "./my.vue?vue&type=template&id=63442fe0&scoped=true&"
import script from "./my.vue?vue&type=script&lang=js&"
export * from "./my.vue?vue&type=script&lang=js&"
import style0 from "./my.vue?vue&type=style&index=0&id=63442fe0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63442fe0",
  null
  
)

export default component.exports