<template>
    <div class="articlestyle">
        <!-- 标题 -->
        <h2>{{ datas.text }}</h2>

        <!-- 表单 -->
        <el-form label-width="80px" :model="datas" size="small" :rules="rules">
            <br>
            <el-form-item label="文章标题" class="lef">
                <el-input v-model="datas.name"></el-input>
            </el-form-item>

            <div class="bor" />

            <!-- 标题内容 -->
            <el-form-item class="lef" label="选择模板">
                <p style="color: #000">{{ styleText }}</p>
            </el-form-item>

            <!-- 卡片样式选择 -->
            <div class="commodityType">
                <el-tooltip class="item" effect="dark" :content="item.content" placement="bottom"
                    v-for="(item, index) in commodityTypes" :key="index">
                    <span class="iconfont" style="font-size: 21px" :class="[
            item.type === datas.commodityType ? 'active' : '',
            item.icon,
        ]" @click="setCommodityTypeFun(item.type)" />
                </el-tooltip>
            </div>

            <div class="bor" />
            <div>
                <h5 style="color: #000; font-size: 14px">基础设置</h5>
                <div style="height: 10px" />
                <!-- <el-form-item class="lef" label="列表类型">
                    <el-radio v-model="datas.isPage" :label="true" @change="changeIsPage">拖动分页</el-radio>
                    <el-radio v-model="datas.isPage" :label="false" @change="changeIsPage">固定条数</el-radio>
                </el-form-item> -->
                <el-form-item label="文章条数" v-if="!datas.isPage" class="lef borrediu">
                    <el-slider v-model="datas.dataNumber" @change="changeDataNumber($event)" :max="10" :min="1"
                        input-size="mini" show-input>
                    </el-slider>
                </el-form-item>
            </div>

            <!-- 卡片样式 -->
            <!-- <el-form-item label="文章样式" class="lef" /> -->
            <!-- 卡片样式选择 -->
            <!-- <div class="moditystyle">
                <span v-for="(item, index) in moditystyles" :key="index"
                    :class="item.type == datas.moditystyle ? 'active' : ''" @click="datas.moditystyle = index">
                    {{ item.text }}
                </span>
            </div> -->


            <!-- 文本粗细 -->
            <el-form-item class="lef" label="文本粗细" prop="textWeight" :hide-required-asterisk="true">
                <el-input type="number" v-model.number="datas.textWeight" placeholder="请输入文本粗细" />
            </el-form-item>

            <div style="height: 10px" v-if="datas.commodityType !== 3" />

            <!-- 边框倒角 -->
            <el-form-item label="边框倒角" class="lef borrediu" v-if="datas.commodityType !== 3">
                <el-slider v-model="datas.borderRadius" :max="30" input-size="mini" show-input>
                </el-slider>
            </el-form-item>

            <div style="height: 10px" v-if="datas.commodityType === 0" />

            <!-- 显示位置 -->
            <el-form-item label="标题位置" class="lef" v-if="datas.commodityType === 0">
                <div class="weiz">
                    <el-tooltip class="item" effect="dark" content="上方" placement="bottom">
                        <i :class="datas.positions === 'top' ? 'active' : ''" class="iconfont icon-shang"
                            @click="datas.positions = 'top'" />
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="下方" placement="bottom">
                        <i :class="datas.positions === 'bottom' ? 'active' : ''" class="iconfont icon-jiantou"
                            @click="datas.positions = 'bottom'" />
                    </el-tooltip>
                </div>
            </el-form-item>

            <div class="bor" />

            <!--活动时间 -->
            <el-form-item class="lef" label="发布时间">
                {{ datas.releaseTime ? '显示' : '隐藏' }}
                <el-checkbox style="margin-left: 196px" v-model="datas.releaseTime" />
            </el-form-item>

            <!--查看更多 -->
            <el-form-item class="lef" label="查看更多">
                {{ datas.viewMore1 ? '显示' : '隐藏' }}
                <el-checkbox style="margin-left: 196px" v-model="datas.viewMore1" />
                <!-- <el-checkbox v-model="datas.viewMore1">头部{{ datas.viewMore1 ? '显示' : '隐藏' }}</el-checkbox>
                <el-checkbox v-model="datas.viewMore2">尾部{{ datas.viewMore2 ? '显示' : '隐藏' }}</el-checkbox>
                <div class="imgText1" v-show="datas.viewMore1 || datas.viewMore2">
                    <parkJumpLink :linkData="datas.http" @changeFun="jumpLinkChange($event)" />
                </div> -->
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { getDataTimeSec } from "@/utils";
export default {
    name: 'companyarticlestyle',
    props: {
        datas: Object,
    },
    data() {
        let kon = (rule, value, callback) => {
            if (value.length === 0) callback(new Error('请输入有效数字'))
        }
        return {
            moditystyles: [
                /* 卡片样式 */
                {
                    text: '无边白底',
                    type: 0,
                },
                {
                    text: '卡片投影',
                    type: 1,
                },
                {
                    text: '描边白底',
                    type: 2,
                },
                {
                    text: '无边透明底',
                    type: 3,
                },
            ],
            commodityTypes: [
                {
                    icon: 'icon-datumoshi',
                    type: 0,
                    content: '大图模式',
                },
                {
                    icon: 'icon-commodity-yihangliangge',
                    type: 1,
                    content: '一行两个',
                },
                {
                    icon: 'icon-xuanzemokuai-daohanghengxianghuadong',
                    type: 2,
                    content: '横向滑动',
                },
                {
                    icon: 'icon-sanlan',
                    type: 3,
                    content: '一大两小',
                },
                {
                    icon: 'icon-commodity-xiangxiliebiao',
                    type: 4,
                    content: '详细列表',
                },
            ],
            rules: {
                textWeight: [{ required: true, validator: kon, trigger: 'blur' }],
            },
            marker: ['新品', '热卖', 'NEW', 'HOT'],
            dragOptions: {
                animation: 200,
            },
            emptyText: '',
            //文章列表配置
            disabl: false,
            index: 0,
            parkId: 0,
            article: [],//文章数据
        }
    },
    watch: {
        watchPark(row) {
            this.parkId = row.parkId;
        },
    },
    created() {
        this.parkId = JSON.parse(sessionStorage.getItem('checkedPark')).parkId;
        //获取文章数据
        this.article = this.$store.state.parkDrag.companyarticleData;
        this.getArticleData();
    },
    methods: {
        setCommodityTypeFun(type) {
            this.datas.commodityType = type;
            if (this.datas.commodityType === 3) {
                this.datas.borderRadius = 0;
            }
        },
        /**@method 监听文章分类获取文章数据*/
        changeType() {
            this.getArticleData();
            //-console.log(this.$store.state.parkDrag.articleData);
        },
        /**@method 链接路径 */
        jumpLinkChange(data) {
            if (data.type) {
                let { urlType, type, id, title } = data;
                this.datas.http = {
                    urlType,
                    type,
                    id,
                    title
                };
            } else {
                this.datas.http = {};
            }
        },
        /**@method 获取商品数据 */
        getArticleData() {
            this.datas.autoData = this.article.slice(0, this.datas.dataNumber);

        },
        changeIsPage(val) {
            if (val) {
                this.datas.dataNumber = 3;
                this.datas.autoData = this.article.slice(0, this.datas.dataNumber)
            }
        },
        changeDataNumber(dataNumber) {
            this.datas.autoData = this.article.slice(0, this.datas.dataNumber);

        },
    },
    computed: {
        setDataTimeSec() {
            return (data) => getDataTimeSec(data);
        },
        // eslint-disable-next-line vue/return-in-computed-property
        styleText() {
            if (this.datas.commodityType === 0) return '大图模式'
            if (this.datas.commodityType === 1) return '一行两个'
            if (this.datas.commodityType === 2) return '横向滑动'
            if (this.datas.commodityType === 3) return '详细列表'
            if (this.datas.commodityType === 4) return '一大两小'
            if (this.datas.commodityType === 5) return '横向滑动'
        },
        watchPark() {
            return this.$store.state.checkedPark
        }
    },
}
</script>

<style scoped lang="scss">
.articlestyle {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 10px 20px;
    box-sizing: border-box;

    /* 标题 */
    h2 {
        padding: 24px 16px 24px 0;
        margin-bottom: 15px;
        border-bottom: 1px solid #f2f4f6;
        font-size: 18px;
        font-weight: 600;
        color: #323233;
    }

    .lef {
        /deep/.el-form-item__label {
            text-align: left;
        }
    }

    /* 图片字 */
    .imgText1 {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;

        .fir-sele.el-select {
            width: 40%;
        }
    }

    /* 列表样式 */
    .commodityType {
        display: flex;
        justify-content: space-around;
        align-items: center;

        span {
            display: inline-block;
            width: 58px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            background: #fff;
            border: 1px solid #ebedf0;
            color: #979797;
            margin: 0 1px;
            cursor: pointer;
            transition: all 0.5s;

            &:hover {
                border: 1px solid #51CDCB;
                color: #51CDCB;
            }

            &.active {
                border: 1px solid #51CDCB;
                background-color: #51cdcb26;
                color: #51CDCB;
            }
        }
    }

    /* 卡片样式 */
    .moditystyle {
        font-size: 12px;
        width: 100%;
        display: flex;

        span {
            width: 86px;
            height: 32px;
            display: inline-block;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            border: 1px solid #ebedf0;

            &.active {
                border: 1px solid #51CDCB;
                background-color: #51cdcb26;
                color: #51CDCB;
            }
        }

        .addMethod {
            width: 110px;
            height: 40px;
            line-height: 40px;
        }
    }

    .moditystyle2 {
        justify-content: center;
    }

    /* 位置 */
    .weiz {
        text-align: right;

        i {
            padding: 5px 14px;
            margin-left: 10px;
            border-radius: 0;
            border: 1px solid #ebedf0;
            font-size: 20px;
            font-weight: 500;
            cursor: pointer;

            &:last-child {
                font-size: 22px;
            }

            &.active {
                color: #51CDCB;
                border: 1px solid #51CDCB;
                background: #51cdcb26;
            }
        }
    }

    /* 单选框 */
    /deep/.radi1 {
        border-top: 1px solid #f7f8fa;
        border-bottom: 1px solid #f7f8fa;
        padding: 12px 0;

        .el-radio {
            margin: 10px 25px 7px 0;
        }
    }

    /* 卡片列表 */
    .imgBanner {
        padding: 6px 12px;
        margin: 16px 7px;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
        display: flex;
        position: relative;

        /* 删除图标 */
        .el-icon-circle-close {
            position: absolute;
            right: -10px;
            top: -10px;
            cursor: pointer;
            font-size: 19px;
        }

        /* 图片 */
        .imag {
            width: 60px;
            height: 60px;
            border-radius: 5px;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                display: inline-block;
            }

            span {
                background: rgba(0, 0, 0, 0.5);
                font-size: 12px;
                position: absolute;
                left: 0px;
                bottom: 0px;
                display: inline-block;
                width: 100%;
                text-align: center;
                color: #fff;
                height: 20px;
                line-height: 20px;
            }
        }

        /* 图片字 */
        .imgText {
            width: 80%;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            padding-left: 20px;
            justify-content: space-between;

            /* 图片字 */
            .imgTextChild {
                width: 100%;
                display: flex;
                box-sizing: border-box;
                justify-content: space-between;

                .fir-sele.el-select {
                    width: 40%;
                }
            }
        }
    }

    /* 上传图片按钮 */
    .uploadImg {
        width: 345px;
        height: 40px;
        margin-top: 20px;
    }

    // 上传弹框内容部分
    /deep/.uploadIMG .el-dialog__body {
        height: 280px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        justify-content: center;
    }

    .disable {
        /deep/.el-upload {
            display: none !important;
        }
    }

    .tit {
        /deep/.el-input__inner {
            text-align: center;
        }
    }
}
</style>