import Vue from 'vue'
import Vuex from 'vuex'
import loginRoot from './loginRoot'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        pickerOptions: {
            // disabledDate(time) {
            //     return time.getTime() > Date.now();
            // },
            shortcuts: [{
                text: '今天',
                onClick(picker) {
                    picker.$emit('pick', new Date());
                }
            }, {
                text: '昨天',
                onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    picker.$emit('pick', date);
                }
            }, {
                text: '一周前',
                onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', date);
                }
            }]
        },
        phoneReg: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/, //手机号正则
        styleStore: "", // 移动端楼层装修中选择风格存储
        isShowLoading: false, // 全局 loading
        subjectColor: '#FFFFFF', //主题颜色
        tagColor: '#51CBCD',
        goodsInfo: {},
        uploadingUrl: 'https://api.yuanqi366.com/file/uploadObjectOSS', //上传域名
        ossUrl: 'https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/', //静态资源域名
        routerArr: [],
        empId: '', //部门id
        menuType: '', // 菜单类型 yuanqi/card/shetuan
        checkedPark: null, //选中的园区
        myParkData: null, //我的园区数据
        checkedAssociation: null, //选中的社团
        myAssociationData: null, //我的社团数据
        //微信公众号返回码
        gzhErrcode: {
            0: '请求成功',
            1003: 'POST参数非法',
            20002: '商品id不存在',
            40001: '获取 access_token 时 AppSecret 错误，或者 access_token 无效。请开发者认真比对 AppSecret  的正确性，或查看是否正在为恰当的公众号调用接口',
            40002: '不合法的凭证类型',
            40003: '不合法的 OpenID ，请开发者确认 OpenID （该用户）是否已关注公众号，或是否是其他公众号的 OpenID',
            40004: '不合法的媒体文件类型',
            40005: '上传素材文件格式不对',
            40006: '上传素材文件大小超出限制',
            40007: '不合法的媒体文件 id',
            40008: '不合法的消息类型',
            40009: '图片尺寸太大',
            40010: '不合法的语音文件大小',
            40011: '不合法的视频文件大小',
            40012: '不合法的缩略图文件大小',
            40013: '不合法的appid',
            40014: '不合法的 access_token ，请开发者认真比对 access_token 的有效性（如是否过期），或查看是否正在为恰当的公众号调用接口',
            40015: '不合法的菜单类型',
            40016: '不合法的按钮个数',
            40017: '不合法的按钮类型',
            40018: '不合法的按钮名字长度',
            40019: '不合法的按钮 KEY 长度',
            40020: '不合法的按钮 URL 长度',
            40021: '不合法的菜单版本号',
            40022: '不合法的子菜单级数',
            40023: '不合法的子菜单按钮个数',
            40024: '不合法的子菜单按钮类型',
            40025: '不合法的子菜单按钮名字长度',
            40026: '不合法的子菜单按钮 KEY 长度',
            40027: '不合法的子菜单按钮 URL 长度',
            40028: '不合法的自定义菜单使用用户',
            40029: '无效的 oauth_code',
            40030: '不合法的 refresh_token',
            40031: '不合法的 openid 列表',
            40032: '不合法的 openid 列表长度',
            40033: '不合法的请求字符，不能包含 \\u×××× 格式的字符',
            40034: '微信官方未告知具体错误，请联系管理员',
            40035: '不合法的参数',
            40036: '不合法的 template_id 长度',
            40037: '不合法的 template_id',
            40038: '不合法的请求格式',
            40039: '不合法的 URL 长度',
            40040: '微信官方未告知具体错误，请联系管理员',
            40041: '微信官方未告知具体错误，请联系管理员',
            40042: '微信官方未告知具体错误，请联系管理员',
            40043: '微信官方未告知具体错误，请联系管理员',
            40044: '微信官方未告知具体错误，请联系管理员',
            40045: '微信官方未告知具体错误，请联系管理员',
            40046: '微信官方未告知具体错误，请联系管理员',
            40047: '微信官方未告知具体错误，请联系管理员',
            40048: '无效的url',
            40049: '微信官方未告知具体错误，请联系管理员',
            40050: '不合法的分组 id',
            40051: '分组名字不合法',
            40052: '微信官方未告知具体错误，请联系管理员',
            40053: '微信官方未告知具体错误，请联系管理员',
            40054: '不合法的子菜单按钮 url 域名',
            40055: '不合法的菜单按钮 url 域名',
            40056: '微信官方未告知具体错误，请联系管理员',
            40057: '微信官方未告知具体错误，请联系管理员',
            40058: '微信官方未告知具体错误，请联系管理员',
            40059: '微信官方未告知具体错误，请联系管理员',
            40060: '删除单篇图文时，指定的 article_idx 不合法',
            40062: '微信官方未告知具体错误，请联系管理员',
            40063: '微信官方未告知具体错误，请联系管理员',
            40064: '微信官方未告知具体错误，请联系管理员',
            40065: '微信官方未告知具体错误，请联系管理员',
            40066: '不合法的 url ，递交的页面被sitemap标记为拦截',
            40067: '微信官方未告知具体错误，请联系管理员',
            40068: '微信官方未告知具体错误，请联系管理员',
            40069: '微信官方未告知具体错误，请联系管理员',
            40070: '微信官方未告知具体错误，请联系管理员',
            40071: '微信官方未告知具体错误，请联系管理员',
            40072: '微信官方未告知具体错误，请联系管理员',
            40073: '微信官方未告知具体错误，请联系管理员',
            40074: '微信官方未告知具体错误，请联系管理员',
            40075: '微信官方未告知具体错误，请联系管理员',
            40076: '微信官方未告知具体错误，请联系管理员',
            40077: '微信官方未告知具体错误，请联系管理员',
            40078: '微信官方未告知具体错误，请联系管理员',
            40079: '微信官方未告知具体错误，请联系管理员',
            40080: '微信官方未告知具体错误，请联系管理员',
            40081: '微信官方未告知具体错误，请联系管理员',
            40082: '微信官方未告知具体错误，请联系管理员',
            40083: '微信官方未告知具体错误，请联系管理员',
            40084: '微信官方未告知具体错误，请联系管理员',
            40085: '微信官方未告知具体错误，请联系管理员',
            40086: '微信官方未告知具体错误，请联系管理员',
            40087: '微信官方未告知具体错误，请联系管理员',
            40088: '微信官方未告知具体错误，请联系管理员',
            40089: '微信官方未告知具体错误，请联系管理员',
            40090: '微信官方未告知具体错误，请联系管理员',
            40091: '微信官方未告知具体错误，请联系管理员',
            40092: '微信官方未告知具体错误，请联系管理员',
            40093: '微信官方未告知具体错误，请联系管理员',
            40094: '微信官方未告知具体错误，请联系管理员',
            40095: '微信官方未告知具体错误，请联系管理员',
            40096: '微信官方未告知具体错误，请联系管理员',
            40097: '参数错误',
            40098: '微信官方未告知具体错误，请联系管理员',
            40099: '微信官方未告知具体错误，请联系管理员',
            40100: '微信官方未告知具体错误，请联系管理员',
            40101: '微信官方未告知具体错误，请联系管理员',
            40102: '微信官方未告知具体错误，请联系管理员',
            40103: '微信官方未告知具体错误，请联系管理员',
            40104: '微信官方未告知具体错误，请联系管理员',
            40105: '微信官方未告知具体错误，请联系管理员',
            40106: '微信官方未告知具体错误，请联系管理员',
            40107: '微信官方未告知具体错误，请联系管理员',
            40108: '微信官方未告知具体错误，请联系管理员',
            40109: '微信官方未告知具体错误，请联系管理员',
            40110: '微信官方未告知具体错误，请联系管理员',
            40111: '微信官方未告知具体错误，请联系管理员',
            40112: '微信官方未告知具体错误，请联系管理员',
            40113: '微信官方未告知具体错误，请联系管理员',
            40114: '微信官方未告知具体错误，请联系管理员',
            40115: '微信官方未告知具体错误，请联系管理员',
            40116: '微信官方未告知具体错误，请联系管理员',
            40117: '分组名字不合法',
            40118: 'media_id 大小不合法',
            40119: 'button 类型错误',
            40120: '子 button 类型错误',
            40121: '不合法的 media_id 类型',
            40122: '微信官方未告知具体错误，请联系管理员',
            40123: '微信官方未告知具体错误，请联系管理员',
            40124: '微信官方未告知具体错误，请联系管理员',
            40125: '不合法的  AppID ，请开发者检查 AppID 的正确性，避免异常字符，注意大小写',
            40126: '微信官方未告知具体错误，请联系管理员',
            40127: '微信官方未告知具体错误，请联系管理员',
            40128: '微信官方未告知具体错误，请联系管理员',
            40129: '微信官方未告知具体错误，请联系管理员',
            40130: '微信官方未告知具体错误，请联系管理员',
            40131: '微信官方未告知具体错误，请联系管理员',
            40132: '微信号不合法',
            40133: '微信官方未告知具体错误，请联系管理员',
            40135: '微信官方未告知具体错误，请联系管理员',
            40136: '微信官方未告知具体错误，请联系管理员',
            40137: '不支持的图片格式',
            40138: '微信官方未告知具体错误，请联系管理员',
            40139: '微信官方未告知具体错误，请联系管理员',
            40140: '微信官方未告知具体错误，请联系管理员',
            40141: '微信官方未告知具体错误，请联系管理员',
            40142: '微信官方未告知具体错误，请联系管理员',
            40143: '微信官方未告知具体错误，请联系管理员',
            40144: '微信官方未告知具体错误，请联系管理员',
            40145: '微信官方未告知具体错误，请联系管理员',
            40146: '微信官方未告知具体错误，请联系管理员',
            40147: '微信官方未告知具体错误，请联系管理员',
            40148: '微信官方未告知具体错误，请联系管理员',
            40149: '微信官方未告知具体错误，请联系管理员',
            40150: '微信官方未告知具体错误，请联系管理员',
            40151: '微信官方未告知具体错误，请联系管理员',
            40152: '微信官方未告知具体错误，请联系管理员',
            40153: '微信官方未告知具体错误，请联系管理员',
            40154: '微信官方未告知具体错误，请联系管理员',
            40155: '请勿添加其他公众号的主页链接',
            40156: '微信官方未告知具体错误，请联系管理员',
            40157: '微信官方未告知具体错误，请联系管理员',
            40158: '微信官方未告知具体错误，请联系管理员',
            40159: '微信官方未告知具体错误，请联系管理员',
            40160: '微信官方未告知具体错误，请联系管理员',
            40161: '微信官方未告知具体错误，请联系管理员',
            40162: '微信官方未告知具体错误，请联系管理员',
            40163: 'oauth_code已使用',
            40164: '微信官方未告知具体错误，请联系管理员',
            40165: '微信官方未告知具体错误，请联系管理员',
            40166: '微信官方未告知具体错误，请联系管理员',
            40167: '微信官方未告知具体错误，请联系管理员',
            40168: '微信官方未告知具体错误，请联系管理员',
            40169: '微信官方未告知具体错误，请联系管理员',
            40170: '微信官方未告知具体错误，请联系管理员',
            40171: '微信官方未告知具体错误，请联系管理员',
            40172: '微信官方未告知具体错误，请联系管理员',
            40173: '微信官方未告知具体错误，请联系管理员',
            40174: '微信官方未告知具体错误，请联系管理员',
            40175: '微信官方未告知具体错误，请联系管理员',
            40176: '微信官方未告知具体错误，请联系管理员',
            40177: '微信官方未告知具体错误，请联系管理员',
            40178: '微信官方未告知具体错误，请联系管理员',
            40179: '微信官方未告知具体错误，请联系管理员',
            40180: '微信官方未告知具体错误，请联系管理员',
            40181: '微信官方未告知具体错误，请联系管理员',
            40182: '微信官方未告知具体错误，请联系管理员',
            40183: '微信官方未告知具体错误，请联系管理员',
            40184: '微信官方未告知具体错误，请联系管理员',
            40185: '微信官方未告知具体错误，请联系管理员',
            40186: '微信官方未告知具体错误，请联系管理员',
            40187: '微信官方未告知具体错误，请联系管理员',
            40188: '微信官方未告知具体错误，请联系管理员',
            40189: '微信官方未告知具体错误，请联系管理员',
            40190: '微信官方未告知具体错误，请联系管理员',
            40191: '微信官方未告知具体错误，请联系管理员',
            40192: '微信官方未告知具体错误，请联系管理员',
            40193: '微信官方未告知具体错误，请联系管理员',
            40194: '微信官方未告知具体错误，请联系管理员',
            40195: '微信官方未告知具体错误，请联系管理员',
            40196: '微信官方未告知具体错误，请联系管理员',
            40197: '微信官方未告知具体错误，请联系管理员',
            40198: '微信官方未告知具体错误，请联系管理员',
            40199: '运单 ID 不存在，未查到运单',
            40200: '微信官方未告知具体错误，请联系管理员',
            40201: '微信官方未告知具体错误，请联系管理员',
            40202: '微信官方未告知具体错误，请联系管理员',
            40203: '微信官方未告知具体错误，请联系管理员',
            40204: '微信官方未告知具体错误，请联系管理员',
            40205: '微信官方未告知具体错误，请联系管理员',
            40206: '微信官方未告知具体错误，请联系管理员',
            40207: '微信官方未告知具体错误，请联系管理员',
            40208: '微信官方未告知具体错误，请联系管理员',
            40209: '微信官方未告知具体错误，请联系管理员',
            40210: 'pages 中的path参数不存在或为空',
            40211: '微信官方未告知具体错误，请联系管理员',
            40212: 'paegs 当中存在不合法的query，query格式遵循URL标准，即k1=v1&amp;k2=v2',
            40213: '微信官方未告知具体错误，请联系管理员',
            40214: '微信官方未告知具体错误，请联系管理员',
            40215: '微信官方未告知具体错误，请联系管理员',
            40216: '微信官方未告知具体错误，请联系管理员',
            40217: '微信官方未告知具体错误，请联系管理员',
            40218: '微信官方未告知具体错误，请联系管理员',
            40219: 'pages不存在或者参数为空',
            40220: '微信官方未告知具体错误，请联系管理员',
            40221: '微信官方未告知具体错误，请联系管理员',
            40222: '微信官方未告知具体错误，请联系管理员',
            41001: '缺少 access_token 参数',
            41002: '缺少 appid 参数',
            41003: '缺少 refresh_token 参数',
            41004: '缺少 secret 参数',
            41005: '缺少多媒体文件数据，传输素材无视频或图片内容',
            41006: '缺少 media_id 参数',
            41007: '缺少子菜单数据',
            41008: '缺少 oauth code',
            41009: '缺少 openid',
            41010: '缺失 url 参数',
            41011: '微信官方未告知具体错误，请联系管理员',
            41012: '微信官方未告知具体错误，请联系管理员',
            41013: '微信官方未告知具体错误，请联系管理员',
            41014: '微信官方未告知具体错误，请联系管理员',
            41015: '微信官方未告知具体错误，请联系管理员',
            41016: '微信官方未告知具体错误，请联系管理员',
            41017: '微信官方未告知具体错误，请联系管理员',
            41018: '微信官方未告知具体错误，请联系管理员',
            41019: '微信官方未告知具体错误，请联系管理员',
            41020: '微信官方未告知具体错误，请联系管理员',
            41021: '微信官方未告知具体错误，请联系管理员',
            41024: '微信官方未告知具体错误，请联系管理员',
            41025: '微信官方未告知具体错误，请联系管理员',
            41026: '微信官方未告知具体错误，请联系管理员',
            41027: '微信官方未告知具体错误，请联系管理员',
            41028: '微信官方未告知具体错误，请联系管理员',
            41029: '微信官方未告知具体错误，请联系管理员',
            41030: 'page路径不正确，需要保证在现网版本小程序中存在，与app.json保持一致',
            41031: '微信官方未告知具体错误，请联系管理员',
            41032: '微信官方未告知具体错误，请联系管理员',
            41033: '只允许通过api创建的小程序使用',
            41034: '微信官方未告知具体错误，请联系管理员',
            41035: '微信官方未告知具体错误，请联系管理员',
            41036: '微信官方未告知具体错误，请联系管理员',
            41037: '微信官方未告知具体错误，请联系管理员',
            41038: '微信官方未告知具体错误，请联系管理员',
            42001: 'access_token 超时，请检查 access_token 的有效期，请参考基础支持 - 获取 access_token 中，对  access_token 的详细机制说明',
            42002: 'refresh_token 超时',
            42003: 'oauth_code 超时',
            42004: '微信官方未告知具体错误，请联系管理员',
            42005: '微信官方未告知具体错误，请联系管理员',
            42006: '微信官方未告知具体错误，请联系管理员',
            42007: '用户修改微信密码， accesstoken 和 refreshtoken 失效，需要重新授权',
            42008: '微信官方未告知具体错误，请联系管理员',
            42009: '微信官方未告知具体错误，请联系管理员',
            43001: '需要 GET 请求',
            43002: '需要 POST 请求',
            43003: '需要 HTTPS 请求',
            43004: '需要接收者关注',
            43005: '需要好友关系',
            43006: '微信官方未告知具体错误，请联系管理员',
            43007: '微信官方未告知具体错误，请联系管理员',
            43008: '微信官方未告知具体错误，请联系管理员',
            43009: '微信官方未告知具体错误，请联系管理员',
            43010: '微信官方未告知具体错误，请联系管理员',
            43011: '微信官方未告知具体错误，请联系管理员',
            43012: '微信官方未告知具体错误，请联系管理员',
            43013: '微信官方未告知具体错误，请联系管理员',
            43014: '微信官方未告知具体错误，请联系管理员',
            43015: '微信官方未告知具体错误，请联系管理员',
            43016: '小程序未认证',
            43017: '微信官方未告知具体错误，请联系管理员',
            43018: '微信官方未告知具体错误，请联系管理员',
            43019: '需要将接收者从黑名单中移除',
            43100: '微信官方未告知具体错误，请联系管理员',
            43101: '用户拒绝接受消息，如果用户之前曾经订阅过，则表示用户取消了订阅关系',
            43102: '微信官方未告知具体错误，请联系管理员',
            43103: '微信官方未告知具体错误，请联系管理员',
            43104: '微信官方未告知具体错误，请联系管理员',
            43105: '微信官方未告知具体错误，请联系管理员',
            43106: '微信官方未告知具体错误，请联系管理员',
            44001: '多媒体文件为空',
            44002: 'POST 的数据包为空',
            44003: '图文消息内容为空',
            44004: '文本消息内容为空',
            44005: '空白的列表',
            44006: '微信官方未告知具体错误，请联系管理员',
            44007: '微信官方未告知具体错误，请联系管理员',
            44997: '微信官方未告知具体错误，请联系管理员',
            44998: '微信官方未告知具体错误，请联系管理员',
            44999: '微信官方未告知具体错误，请联系管理员',
            45000: '微信官方未告知具体错误，请联系管理员',
            45001: '多媒体文件大小超过限制',
            45002: '消息内容超过限制',
            45003: '标题字段超过限制',
            45004: '描述字段超过限制',
            45005: '链接字段超过限制',
            45006: '图片链接字段超过限制',
            45007: '语音播放时间超过限制',
            45008: '图文消息超过限制',
            45009: '接口调用超过限制',
            45010: '创建菜单个数超过限制',
            45011: 'API 调用太频繁，请稍候再试',
            45012: '模板大小超过限制',
            45013: '微信官方未告知具体错误，请联系管理员',
            45014: '微信官方未告知具体错误，请联系管理员',
            45015: '回复时间超过限制',
            45016: '系统分组，不允许修改',
            45017: '分组名字过长',
            45018: '分组数量超过上限',
            45019: '微信官方未告知具体错误，请联系管理员',
            45020: '微信官方未告知具体错误，请联系管理员',
            45021: '微信官方未告知具体错误，请联系管理员',
            45022: '微信官方未告知具体错误，请联系管理员',
            45023: '微信官方未告知具体错误，请联系管理员',
            45024: '微信官方未告知具体错误，请联系管理员',
            45025: '微信官方未告知具体错误，请联系管理员',
            45026: '微信官方未告知具体错误，请联系管理员',
            45027: '微信官方未告知具体错误，请联系管理员',
            45028: '微信官方未告知具体错误，请联系管理员',
            45029: '微信官方未告知具体错误，请联系管理员',
            45030: '微信官方未告知具体错误，请联系管理员',
            45031: '微信官方未告知具体错误，请联系管理员',
            45032: '微信官方未告知具体错误，请联系管理员',
            45033: '微信官方未告知具体错误，请联系管理员',
            45034: '微信官方未告知具体错误，请联系管理员',
            45035: '微信官方未告知具体错误，请联系管理员',
            45036: '微信官方未告知具体错误，请联系管理员',
            45037: '微信官方未告知具体错误，请联系管理员',
            45038: '微信官方未告知具体错误，请联系管理员',
            45039: '微信官方未告知具体错误，请联系管理员',
            45040: '微信官方未告知具体错误，请联系管理员',
            45041: '微信官方未告知具体错误，请联系管理员',
            45042: '微信官方未告知具体错误，请联系管理员',
            45043: '微信官方未告知具体错误，请联系管理员',
            45044: '微信官方未告知具体错误，请联系管理员',
            45045: '微信官方未告知具体错误，请联系管理员',
            45046: '微信官方未告知具体错误，请联系管理员',
            45047: '客服接口下行条数超过上限',
            45048: '微信官方未告知具体错误，请联系管理员',
            45049: '微信官方未告知具体错误，请联系管理员',
            45050: '微信官方未告知具体错误，请联系管理员',
            45051: '微信官方未告知具体错误，请联系管理员',
            45052: '微信官方未告知具体错误，请联系管理员',
            45053: '微信官方未告知具体错误，请联系管理员',
            45054: '微信官方未告知具体错误，请联系管理员',
            45055: '微信官方未告知具体错误，请联系管理员',
            45056: '微信官方未告知具体错误，请联系管理员',
            45057: '微信官方未告知具体错误，请联系管理员',
            45058: '微信官方未告知具体错误，请联系管理员',
            45059: '微信官方未告知具体错误，请联系管理员',
            45060: '微信官方未告知具体错误，请联系管理员',
            45061: '微信官方未告知具体错误，请联系管理员',
            45062: '微信官方未告知具体错误，请联系管理员',
            45063: '微信官方未告知具体错误，请联系管理员',
            45064: '创建菜单包含未关联的小程序',
            45065: '相同 clientmsgid 已存在群发记录，返回数据中带有已存在的群发任务的 msgid',
            45066: '相同 clientmsgid 重试速度过快，请间隔1分钟重试',
            45067: 'clientmsgid 长度超过限制',
            45068: '微信官方未告知具体错误，请联系管理员',
            45069: '微信官方未告知具体错误，请联系管理员',
            45070: '微信官方未告知具体错误，请联系管理员',
            45071: '微信官方未告知具体错误，请联系管理员',
            45072: 'command字段取值不对',
            45073: '微信官方未告知具体错误，请联系管理员',
            45074: '微信官方未告知具体错误，请联系管理员',
            45075: '微信官方未告知具体错误，请联系管理员',
            45076: '微信官方未告知具体错误，请联系管理员',
            45077: '微信官方未告知具体错误，请联系管理员',
            45078: '微信官方未告知具体错误，请联系管理员',
            45079: '微信官方未告知具体错误，请联系管理员',
            45080: '下发输入状态，需要之前30秒内跟用户有过消息交互',
            45081: '已经在输入状态，不可重复下发',
            45082: '微信官方未告知具体错误，请联系管理员',
            45083: '微信官方未告知具体错误，请联系管理员',
            45084: '微信官方未告知具体错误，请联系管理员',
            45085: '微信官方未告知具体错误，请联系管理员',
            45086: '微信官方未告知具体错误，请联系管理员',
            45087: '微信官方未告知具体错误，请联系管理员',
            45088: '微信官方未告知具体错误，请联系管理员',
            45089: '微信官方未告知具体错误，请联系管理员',
            45090: '微信官方未告知具体错误，请联系管理员',
            45091: '微信官方未告知具体错误，请联系管理员',
            45092: '微信官方未告知具体错误，请联系管理员',
            45093: '微信官方未告知具体错误，请联系管理员',
            45094: '微信官方未告知具体错误，请联系管理员',
            45095: '微信官方未告知具体错误，请联系管理员',
            45096: '微信官方未告知具体错误，请联系管理员',
            45097: '微信官方未告知具体错误，请联系管理员',
            45098: '微信官方未告知具体错误，请联系管理员',
            45099: '微信官方未告知具体错误，请联系管理员',
            45100: '微信官方未告知具体错误，请联系管理员',
            45101: '微信官方未告知具体错误，请联系管理员',
            45102: '微信官方未告知具体错误，请联系管理员',
            45103: '微信官方未告知具体错误，请联系管理员',
            45104: '微信官方未告知具体错误，请联系管理员',
            45154: '微信官方未告知具体错误，请联系管理员',
            45155: '微信官方未告知具体错误，请联系管理员',
            45156: '微信官方未告知具体错误，请联系管理员',
            45157: '微信官方未告知具体错误，请联系管理员',
            45158: '微信官方未告知具体错误，请联系管理员',
            45159: '微信官方未告知具体错误，请联系管理员',
            45160: '微信官方未告知具体错误，请联系管理员',
            45161: '微信官方未告知具体错误，请联系管理员',
            45162: '微信官方未告知具体错误，请联系管理员',
            45163: '微信官方未告知具体错误，请联系管理员',
            45164: '微信官方未告知具体错误，请联系管理员',
            45165: '微信官方未告知具体错误，请联系管理员',
            45166: '微信官方未告知具体错误，请联系管理员',
            45167: '微信官方未告知具体错误，请联系管理员',
            45168: '微信官方未告知具体错误，请联系管理员',
            45501: '微信官方未告知具体错误，请联系管理员',
            46001: '不存在媒体数据，media_id 不存在',
            46002: '不存在的菜单版本',
            46003: '不存在的菜单数据',
            46004: '不存在的用户',
            46005: '微信官方未告知具体错误，请联系管理员',
            46006: '微信官方未告知具体错误，请联系管理员',
            46007: '微信官方未告知具体错误，请联系管理员',
            46008: '微信官方未告知具体错误，请联系管理员',
            46009: '微信官方未告知具体错误，请联系管理员',
            46101: '微信官方未告知具体错误，请联系管理员',
            47001: '解析 JSON/XML 内容错误',
            47002: '微信官方未告知具体错误，请联系管理员',
            47003: '模板参数不准确，可能为空或者不满足规则，errmsg会提示具体是哪个字段出错',
            47004: '每次提交的页面数超过1000（备注：每次提交页面数应小于或等于1000）',
            47005: '微信官方未告知具体错误，请联系管理员',
            47006: '当天提交页面数达到了配额上限，请明天再试',
            47101: '搜索结果总数超过了1000条',
            47102: 'next_page_info参数错误',
            47501: '参数 activity_id 错误',
            47502: '参数 target_state 错误',
            47503: '参数 version_type 错误',
            47504: 'activity_id',
            48001: 'api 功能未授权，请确认公众号已获得该接口，可以在公众平台官网 - 开发者中心页中查看接口权限',
            48002: '粉丝拒收消息（粉丝在公众号选项中，关闭了 “ 接收消息 ” ）',
            48003: '微信官方未告知具体错误，请联系管理员',
            48004: 'api 接口被封禁，请登录 mp.weixin.qq.com 查看详情',
            48005: 'api 禁止删除被自动回复和自定义菜单引用的素材',
            48006: 'api 禁止清零调用次数，因为清零次数达到上限',
            48007: '微信官方未告知具体错误，请联系管理员',
            48008: '没有该类型消息的发送权限',
            48009: '微信官方未告知具体错误，请联系管理员',
            48010: '微信官方未告知具体错误，请联系管理员',
            48011: '微信官方未告知具体错误，请联系管理员',
            48012: '微信官方未告知具体错误，请联系管理员',
            48013: '该视频非新接口上传，不能用于视频消息群发',
            48014: '该视频审核状态异常，请检查后重试',
            48015: '该账号无留言功能权限',
            48016: '该账号不满足智能配置"观看更多"视频条件',
            49001: '微信官方未告知具体错误，请联系管理员',
            49002: '微信官方未告知具体错误，请联系管理员',
            49003: '微信官方未告知具体错误，请联系管理员',
            49004: '微信官方未告知具体错误，请联系管理员',
            49005: '微信官方未告知具体错误，请联系管理员',
            49006: '微信官方未告知具体错误，请联系管理员',
            49007: '微信官方未告知具体错误，请联系管理员',
            49008: '微信官方未告知具体错误，请联系管理员',
            49009: '微信官方未告知具体错误，请联系管理员',
            49010: '微信官方未告知具体错误，请联系管理员',
            49300: '微信官方未告知具体错误，请联系管理员',
            49301: '微信官方未告知具体错误，请联系管理员',
            49302: '微信官方未告知具体错误，请联系管理员',
            50001: '用户未授权该 api',
            50002: '用户受限，可能是用户帐号被冻结或注销',
            50003: '微信官方未告知具体错误，请联系管理员',
            50004: '微信官方未告知具体错误，请联系管理员',
            50005: '用户未关注公众号',
            50006: '微信官方未告知具体错误，请联系管理员',
            51000: '微信官方未告知具体错误，请联系管理员',
            51001: '微信官方未告知具体错误，请联系管理员',
            51002: '微信官方未告知具体错误，请联系管理员',
            51003: '微信官方未告知具体错误，请联系管理员',
            51004: '微信官方未告知具体错误，请联系管理员',
            51005: '微信官方未告知具体错误，请联系管理员',
            51006: '微信官方未告知具体错误，请联系管理员',
            51007: '微信官方未告知具体错误，请联系管理员',
            51008: '微信官方未告知具体错误，请联系管理员',
            51009: '微信官方未告知具体错误，请联系管理员',
            51010: '微信官方未告知具体错误，请联系管理员',
            51011: '微信官方未告知具体错误，请联系管理员',
            51012: '微信官方未告知具体错误，请联系管理员',
            51013: '微信官方未告知具体错误，请联系管理员',
            51014: '微信官方未告知具体错误，请联系管理员',
            51015: '微信官方未告知具体错误，请联系管理员',
            51020: '微信官方未告知具体错误，请联系管理员',
            51021: '微信官方未告知具体错误，请联系管理员',
            51022: '微信官方未告知具体错误，请联系管理员',
            51023: '微信官方未告知具体错误，请联系管理员',
            51024: '微信官方未告知具体错误，请联系管理员',
            51025: '微信官方未告知具体错误，请联系管理员',
            51026: '微信官方未告知具体错误，请联系管理员',
            51027: '微信官方未告知具体错误，请联系管理员',
            51028: '微信官方未告知具体错误，请联系管理员',
            51029: '微信官方未告知具体错误，请联系管理员',
            51030: '微信官方未告知具体错误，请联系管理员',
            51031: '微信官方未告知具体错误，请联系管理员',
            51032: '微信官方未告知具体错误，请联系管理员',
            51033: '微信官方未告知具体错误，请联系管理员',
            51034: '微信官方未告知具体错误，请联系管理员',
            51035: '微信官方未告知具体错误，请联系管理员',
            52000: '微信官方未告知具体错误，请联系管理员',
            52001: '微信官方未告知具体错误，请联系管理员',
            52002: '微信官方未告知具体错误，请联系管理员',
            52003: '微信官方未告知具体错误，请联系管理员',
            52004: '微信官方未告知具体错误，请联系管理员',
            52005: '微信官方未告知具体错误，请联系管理员',
            52006: '微信官方未告知具体错误，请联系管理员',
            52007: '微信官方未告知具体错误，请联系管理员',
            52008: '微信官方未告知具体错误，请联系管理员',
            52009: '微信官方未告知具体错误，请联系管理员',
            52010: '微信官方未告知具体错误，请联系管理员',
            52011: '微信官方未告知具体错误，请联系管理员',
            52012: '微信官方未告知具体错误，请联系管理员',
            52013: '微信官方未告知具体错误，请联系管理员',
            52014: '微信官方未告知具体错误，请联系管理员',
            52015: '微信官方未告知具体错误，请联系管理员',
            52016: '微信官方未告知具体错误，请联系管理员',
            52017: '微信官方未告知具体错误，请联系管理员',
            52018: '微信官方未告知具体错误，请联系管理员',
            52019: '微信官方未告知具体错误，请联系管理员',
            53000: '微信官方未告知具体错误，请联系管理员',
            53001: '微信官方未告知具体错误，请联系管理员',
            53002: '微信官方未告知具体错误，请联系管理员',
            53003: '微信官方未告知具体错误，请联系管理员',
            53010: '名称格式不合法',
            53011: '名称检测命中频率限制',
            53012: '禁止使用该名称',
            53013: '公众号：名称与已有公众号名称重复;小程序：该名称与已有小程序名称重复',
            53014: '公众号：公众号已有{名称  A+}时，需与该帐号相同主体才可申请{名称 A};小程序：小程序已有{名称 A+}时，需与该帐号相同主体才可申请{名称 A}',
            53015: '公众号：该名称与已有小程序名称重复，需与该小程序帐号相同主体才可申请;小程序：该名称与已有公众号名称重复，需与该公众号帐号相同主体才可申请',
            53016: '公众号：该名称与已有多个小程序名称重复，暂不支持申请;小程序：该名称与已有多个公众号名称重复，暂不支持申请',
            53017: '公众号：小程序已有{名称  A+}时，需与该帐号相同主体才可申请{名称 A};小程序：公众号已有{名称 A+}时，需与该帐号相同主体才可申请{名称 A}',
            53018: '名称命中微信号',
            53019: '名称在保护期内',
            53100: '微信官方未告知具体错误，请联系管理员',
            53101: '微信官方未告知具体错误，请联系管理员',
            53102: '微信官方未告知具体错误，请联系管理员',
            53103: '微信官方未告知具体错误，请联系管理员',
            53200: '本月功能介绍修改次数已用完',
            53201: '功能介绍内容命中黑名单关键字',
            53202: '本月头像修改次数已用完',
            53203: '微信官方未告知具体错误，请联系管理员',
            53204: '微信官方未告知具体错误，请联系管理员',
            53300: '超出每月次数限制',
            53301: '超出可配置类目总数限制',
            53302: '当前账号主体类型不允许设置此种类目',
            53303: '提交的参数不合法',
            53304: '与已有类目重复',
            53305: '包含未通过IPC校验的类目',
            53306: '修改类目只允许修改类目资质，不允许修改类目ID',
            53307: '只有审核失败的类目允许修改',
            53308: '审核中的类目不允许删除',
            53309: '社交红包不允许删除',
            53310: '类目超过上限，但是可以添加apply_reason参数申请更多类目',
            53311: '需要提交资料信息',
            60005: '微信官方未告知具体错误，请联系管理员',
            60006: '微信官方未告知具体错误，请联系管理员',
            61000: '微信官方未告知具体错误，请联系管理员',
            61001: '微信官方未告知具体错误，请联系管理员',
            61002: '微信官方未告知具体错误，请联系管理员',
            61003: '微信官方未告知具体错误，请联系管理员',
            61004: 'api 功能未授权，请确认公众号/小程序已获得该接口，可以在公众平台官网 - 开发者中心页中查看接口权限',
            61005: '微信官方未告知具体错误，请联系管理员',
            61006: '微信官方未告知具体错误，请联系管理员',
            61007: '微信官方未告知具体错误，请联系管理员',
            61008: '微信官方未告知具体错误，请联系管理员',
            61009: '微信官方未告知具体错误，请联系管理员',
            61010: '微信官方未告知具体错误，请联系管理员',
            61011: '微信官方未告知具体错误，请联系管理员',
            61012: '微信官方未告知具体错误，请联系管理员',
            61013: '微信官方未告知具体错误，请联系管理员',
            61014: '微信官方未告知具体错误，请联系管理员',
            61015: '微信官方未告知具体错误，请联系管理员',
            61016: '微信官方未告知具体错误，请联系管理员',
            61017: '微信官方未告知具体错误，请联系管理员',
            61018: '微信官方未告知具体错误，请联系管理员',
            61019: '微信官方未告知具体错误，请联系管理员',
            61020: '微信官方未告知具体错误，请联系管理员',
            61021: '微信官方未告知具体错误，请联系管理员',
            61022: '微信官方未告知具体错误，请联系管理员',
            61023: '微信官方未告知具体错误，请联系管理员',
            61024: '微信官方未告知具体错误，请联系管理员',
            61025: '微信官方未告知具体错误，请联系管理员',
            61026: '微信官方未告知具体错误，请联系管理员',
            61027: '微信官方未告知具体错误，请联系管理员',
            61028: '微信官方未告知具体错误，请联系管理员',
            61029: '微信官方未告知具体错误，请联系管理员',
            61030: '微信官方未告知具体错误，请联系管理员',
            61051: '微信官方未告知具体错误，请联系管理员',
            61052: '微信官方未告知具体错误，请联系管理员',
            61053: '微信官方未告知具体错误，请联系管理员',
            61054: '微信官方未告知具体错误，请联系管理员',
            61055: '微信官方未告知具体错误，请联系管理员',
            61056: '微信官方未告知具体错误，请联系管理员',
            61058: '微信官方未告知具体错误，请联系管理员',
            61061: '微信官方未告知具体错误，请联系管理员',
            61063: '微信官方未告知具体错误，请联系管理员',
            61064: '微信官方未告知具体错误，请联系管理员',
            61065: '微信官方未告知具体错误，请联系管理员',
            61066: '微信官方未告知具体错误，请联系管理员',
            61067: '微信官方未告知具体错误，请联系管理员',
            61068: '微信官方未告知具体错误，请联系管理员',
            61069: '微信官方未告知具体错误，请联系管理员',
            61070: '微信官方未告知具体错误，请联系管理员',
            61100: '微信官方未告知具体错误，请联系管理员',
            61101: '微信官方未告知具体错误，请联系管理员',
            61102: '微信官方未告知具体错误，请联系管理员',
            61200: '微信官方未告知具体错误，请联系管理员',
            61300: '微信官方未告知具体错误，请联系管理员',
            61301: '微信官方未告知具体错误，请联系管理员',
            61302: '微信官方未告知具体错误，请联系管理员',
            61303: '微信官方未告知具体错误，请联系管理员',
            61304: '微信官方未告知具体错误，请联系管理员',
            61305: '微信官方未告知具体错误，请联系管理员',
            61306: '微信官方未告知具体错误，请联系管理员',
            61307: '微信官方未告知具体错误，请联系管理员',
            61308: '微信官方未告知具体错误，请联系管理员',
            61309: '微信官方未告知具体错误，请联系管理员',
            61310: '微信官方未告知具体错误，请联系管理员',
            61311: '微信官方未告知具体错误，请联系管理员',
            61312: '微信官方未告知具体错误，请联系管理员',
            61313: '微信官方未告知具体错误，请联系管理员',
            61314: '微信官方未告知具体错误，请联系管理员',
            61315: '微信官方未告知具体错误，请联系管理员',
            61316: '微信官方未告知具体错误，请联系管理员',
            61317: '微信官方未告知具体错误，请联系管理员',
            61318: '微信官方未告知具体错误，请联系管理员',
            61319: '微信官方未告知具体错误，请联系管理员',
            61320: '微信官方未告知具体错误，请联系管理员',
            61321: '微信官方未告知具体错误，请联系管理员',
            61322: '微信官方未告知具体错误，请联系管理员',
            61323: '微信官方未告知具体错误，请联系管理员',
            61324: '微信官方未告知具体错误，请联系管理员',
            61325: '微信官方未告知具体错误，请联系管理员',
            61326: '微信官方未告知具体错误，请联系管理员',
            61327: '微信官方未告知具体错误，请联系管理员',
            61328: '微信官方未告知具体错误，请联系管理员',
            61329: '微信官方未告知具体错误，请联系管理员',
            61330: '微信官方未告知具体错误，请联系管理员',
            61331: '微信官方未告知具体错误，请联系管理员',
            61332: '微信官方未告知具体错误，请联系管理员',
            61333: '微信官方未告知具体错误，请联系管理员',
            61334: '微信官方未告知具体错误，请联系管理员',
            61335: '微信官方未告知具体错误，请联系管理员',
            61336: '微信官方未告知具体错误，请联系管理员',
            61337: '微信官方未告知具体错误，请联系管理员',
            61338: '微信官方未告知具体错误，请联系管理员',
            61339: '微信官方未告知具体错误，请联系管理员',
            61340: '微信官方未告知具体错误，请联系管理员',
            61341: '微信官方未告知具体错误，请联系管理员',
            61342: '微信官方未告知具体错误，请联系管理员',
            61343: '微信官方未告知具体错误，请联系管理员',
            61344: '微信官方未告知具体错误，请联系管理员',
            61345: '微信官方未告知具体错误，请联系管理员',
            61346: '微信官方未告知具体错误，请联系管理员',
            61347: '微信官方未告知具体错误，请联系管理员',
            61348: '微信官方未告知具体错误，请联系管理员',
            61350: '微信官方未告知具体错误，请联系管理员',
            61351: '微信官方未告知具体错误，请联系管理员',
            61400: '微信官方未告知具体错误，请联系管理员',
            61401: '微信官方未告知具体错误，请联系管理员',
            61450: '系统错误 (system error)',
            61451: '参数错误 (invalid parameter)',
            61452: '无效客服账号 (invalid kf_account)',
            61453: '客服帐号已存在 (kf_account exsited)',
            61454: '客服帐号名长度超过限制 ( 仅允许 10 个英文字符，不包括 @ 及 @ 后的公众号的微信号 )(invalid  kf_acount  length)',
            61455: '客服帐号名包含非法字符 ( 仅允许英文 + 数字 )(illegal character in   kf_account)',
            61456: '客服帐号个数超过限制 (10 个客服账号 )(kf_account count exceeded)',
            61457: '无效头像文件类型 (invalid  file type)',
            61500: '日期格式错误',
            61501: '微信官方未告知具体错误，请联系管理员',
            61502: '微信官方未告知具体错误，请联系管理员',
            61503: '微信官方未告知具体错误，请联系管理员',
            62001: '微信官方未告知具体错误，请联系管理员',
            62002: '微信官方未告知具体错误，请联系管理员',
            62003: '微信官方未告知具体错误，请联系管理员',
            62004: '微信官方未告知具体错误，请联系管理员',
            62005: '微信官方未告知具体错误，请联系管理员',
            63001: '部分参数为空',
            63002: '无效的签名',
            63003: '微信官方未告知具体错误，请联系管理员',
            63004: '微信官方未告知具体错误，请联系管理员',
            63149: '微信官方未告知具体错误，请联系管理员',
            63152: '微信官方未告知具体错误，请联系管理员',
            63153: '微信官方未告知具体错误，请联系管理员',
            63154: '微信官方未告知具体错误，请联系管理员',
            63155: '微信官方未告知具体错误，请联系管理员',
            63156: '微信官方未告知具体错误，请联系管理员',
            63157: '微信官方未告知具体错误，请联系管理员',
            63158: '微信官方未告知具体错误，请联系管理员',
            63159: '微信官方未告知具体错误，请联系管理员',
            63160: '微信官方未告知具体错误，请联系管理员',
            63161: '微信官方未告知具体错误，请联系管理员',
            63162: '微信官方未告知具体错误，请联系管理员',
            63163: '微信官方未告知具体错误，请联系管理员',
            63164: '微信官方未告知具体错误，请联系管理员',
            63165: '微信官方未告知具体错误，请联系管理员',
            63166: '微信官方未告知具体错误，请联系管理员',
            63167: '微信官方未告知具体错误，请联系管理员',
            63168: '微信官方未告知具体错误，请联系管理员',
            63169: '微信官方未告知具体错误，请联系管理员',
            63170: '微信官方未告知具体错误，请联系管理员',
            63171: '微信官方未告知具体错误，请联系管理员',
            63172: '微信官方未告知具体错误，请联系管理员',
            63173: '微信官方未告知具体错误，请联系管理员',
            63174: '微信官方未告知具体错误，请联系管理员',
            63175: '微信官方未告知具体错误，请联系管理员',
            63176: '微信官方未告知具体错误，请联系管理员',
            63177: '微信官方未告知具体错误，请联系管理员',
            63178: '微信官方未告知具体错误，请联系管理员',
            63179: '微信官方未告知具体错误，请联系管理员',
            63180: '微信官方未告知具体错误，请联系管理员',
            63181: '微信官方未告知具体错误，请联系管理员',
            63182: '微信官方未告知具体错误，请联系管理员',
            65001: '微信官方未告知具体错误，请联系管理员',
            65002: '微信官方未告知具体错误，请联系管理员',
            65003: '微信官方未告知具体错误，请联系管理员',
            65004: '微信官方未告知具体错误，请联系管理员',
            65104: '微信官方未告知具体错误，请联系管理员',
            65105: '微信官方未告知具体错误，请联系管理员',
            65106: '微信官方未告知具体错误，请联系管理员',
            65107: '微信官方未告知具体错误，请联系管理员',
            65109: '微信官方未告知具体错误，请联系管理员',
            65110: '微信官方未告知具体错误，请联系管理员',
            65111: '微信官方未告知具体错误，请联系管理员',
            65112: '微信官方未告知具体错误，请联系管理员',
            65113: '微信官方未告知具体错误，请联系管理员',
            65114: '微信官方未告知具体错误，请联系管理员',
            65115: '微信官方未告知具体错误，请联系管理员',
            65116: '微信官方未告知具体错误，请联系管理员',
            65117: '微信官方未告知具体错误，请联系管理员',
            65118: '微信官方未告知具体错误，请联系管理员',
            65119: '微信官方未告知具体错误，请联系管理员',
            65120: '微信官方未告知具体错误，请联系管理员',
            65201: '微信官方未告知具体错误，请联系管理员',
            65202: '微信官方未告知具体错误，请联系管理员',
            65203: '微信官方未告知具体错误，请联系管理员',
            65204: '微信官方未告知具体错误，请联系管理员',
            65205: '微信官方未告知具体错误，请联系管理员',
            65301: '不存在此 menuid 对应的个性化菜单',
            65302: '没有相应的用户',
            65303: '没有默认菜单，不能创建个性化菜单',
            65304: 'MatchRule 信息为空',
            65305: '个性化菜单数量受限',
            65306: '不支持个性化菜单的帐号',
            65307: '个性化菜单信息为空',
            65308: '包含没有响应类型的 button',
            65309: '个性化菜单开关处于关闭状态',
            65310: '填写了省份或城市信息，国家信息不能为空',
            65311: '填写了城市信息，省份信息不能为空',
            65312: '不合法的国家信息',
            65313: '不合法的省份信息',
            65314: '不合法的城市信息',
            65315: '微信官方未告知具体错误，请联系管理员',
            65316: '该公众号的菜单设置了过多的域名外跳（最多跳转到 3 个域名的链接）',
            65317: '不合法的 URL',
            65318: '微信官方未告知具体错误，请联系管理员',
            65319: '微信官方未告知具体错误，请联系管理员',
            65400: '微信官方未告知具体错误，请联系管理员',
            65401: '微信官方未告知具体错误，请联系管理员',
            65402: '微信官方未告知具体错误，请联系管理员',
            65403: '微信官方未告知具体错误，请联系管理员',
            65404: '微信官方未告知具体错误，请联系管理员',
            65405: '微信官方未告知具体错误，请联系管理员',
            65406: '微信官方未告知具体错误，请联系管理员',
            65407: '微信官方未告知具体错误，请联系管理员',
            65408: '微信官方未告知具体错误，请联系管理员',
            65409: '微信官方未告知具体错误，请联系管理员',
            65410: '微信官方未告知具体错误，请联系管理员',
            65411: '微信官方未告知具体错误，请联系管理员',
            65412: '微信官方未告知具体错误，请联系管理员',
            65413: '微信官方未告知具体错误，请联系管理员',
            65414: '微信官方未告知具体错误，请联系管理员',
            65415: '微信官方未告知具体错误，请联系管理员',
            65416: '微信官方未告知具体错误，请联系管理员',
            65417: '微信官方未告知具体错误，请联系管理员',
            65450: '微信官方未告知具体错误，请联系管理员',
            68002: '微信官方未告知具体错误，请联系管理员',
            68003: '微信官方未告知具体错误，请联系管理员',
            68004: '微信官方未告知具体错误，请联系管理员',
            68005: '微信官方未告知具体错误，请联系管理员',
            68006: '微信官方未告知具体错误，请联系管理员',
            68007: '微信官方未告知具体错误，请联系管理员',
            68008: '微信官方未告知具体错误，请联系管理员',
            68009: '微信官方未告知具体错误，请联系管理员',
            68010: '微信官方未告知具体错误，请联系管理员',
            68011: '微信官方未告知具体错误，请联系管理员',
            68012: '微信官方未告知具体错误，请联系管理员',
            68013: '微信官方未告知具体错误，请联系管理员',
            68014: '微信官方未告知具体错误，请联系管理员',
            68015: '微信官方未告知具体错误，请联系管理员',
            68016: '微信官方未告知具体错误，请联系管理员',
            68017: '微信官方未告知具体错误，请联系管理员',
            68018: '微信官方未告知具体错误，请联系管理员',
            68019: '微信官方未告知具体错误，请联系管理员',
            68020: '微信官方未告知具体错误，请联系管理员',
            68021: '微信官方未告知具体错误，请联系管理员',
            68022: '微信官方未告知具体错误，请联系管理员',
            68023: '微信官方未告知具体错误，请联系管理员',
            68024: '微信官方未告知具体错误，请联系管理员',
            68025: '微信官方未告知具体错误，请联系管理员',
            68026: '微信官方未告知具体错误，请联系管理员',
            68027: '微信官方未告知具体错误，请联系管理员',
            68028: '微信官方未告知具体错误，请联系管理员',
            68029: '微信官方未告知具体错误，请联系管理员',
            68030: '微信官方未告知具体错误，请联系管理员',
            68031: '微信官方未告知具体错误，请联系管理员',
            68032: '微信官方未告知具体错误，请联系管理员',
            68033: '微信官方未告知具体错误，请联系管理员',
            68034: '微信官方未告知具体错误，请联系管理员',
            68035: '微信官方未告知具体错误，请联系管理员',
            68036: '微信官方未告知具体错误，请联系管理员',
            68037: '微信官方未告知具体错误，请联系管理员',
            68038: '微信官方未告知具体错误，请联系管理员',
            68039: '微信官方未告知具体错误，请联系管理员',
            68040: '微信官方未告知具体错误，请联系管理员',
            68041: '微信官方未告知具体错误，请联系管理员',
            68042: '微信官方未告知具体错误，请联系管理员',
            68043: '微信官方未告知具体错误，请联系管理员',
            68044: '微信官方未告知具体错误，请联系管理员',
            68045: '微信官方未告知具体错误，请联系管理员',
            68046: '微信官方未告知具体错误，请联系管理员',
            68500: '微信官方未告知具体错误，请联系管理员',
            68501: '微信官方未告知具体错误，请联系管理员',
            71001: '微信官方未告知具体错误，请联系管理员',
            71002: '微信官方未告知具体错误，请联系管理员',
            71003: '微信官方未告知具体错误，请联系管理员',
            71004: '微信官方未告知具体错误，请联系管理员',
            71005: '微信官方未告知具体错误，请联系管理员',
            71006: '微信官方未告知具体错误，请联系管理员',
            71007: '微信官方未告知具体错误，请联系管理员',
            71008: '微信官方未告知具体错误，请联系管理员',
            72001: '微信官方未告知具体错误，请联系管理员',
            72002: '微信官方未告知具体错误，请联系管理员',
            72003: '微信官方未告知具体错误，请联系管理员',
            72004: '微信官方未告知具体错误，请联系管理员',
            72005: '微信官方未告知具体错误，请联系管理员',
            72006: '微信官方未告知具体错误，请联系管理员',
            72007: '微信官方未告知具体错误，请联系管理员',
            72008: '微信官方未告知具体错误，请联系管理员',
            72009: '微信官方未告知具体错误，请联系管理员',
            72010: '微信官方未告知具体错误，请联系管理员',
            72011: '微信官方未告知具体错误，请联系管理员',
            72012: '微信官方未告知具体错误，请联系管理员',
            72013: '微信官方未告知具体错误，请联系管理员',
            72014: '微信官方未告知具体错误，请联系管理员',
            72015: '微信官方未告知具体错误，请联系管理员',
            72016: '微信官方未告知具体错误，请联系管理员',
            72017: '微信官方未告知具体错误，请联系管理员',
            72018: '微信官方未告知具体错误，请联系管理员',
            72019: '微信官方未告知具体错误，请联系管理员',
            72020: '微信官方未告知具体错误，请联系管理员',
            72021: '微信官方未告知具体错误，请联系管理员',
            72022: '微信官方未告知具体错误，请联系管理员',
            72023: '微信官方未告知具体错误，请联系管理员',
            72024: '微信官方未告知具体错误，请联系管理员',
            72025: '微信官方未告知具体错误，请联系管理员',
            72026: '微信官方未告知具体错误，请联系管理员',
            72027: '微信官方未告知具体错误，请联系管理员',
            72028: '微信官方未告知具体错误，请联系管理员',
            72029: '微信官方未告知具体错误，请联系管理员',
            72030: '微信官方未告知具体错误，请联系管理员',
            72031: '微信官方未告知具体错误，请联系管理员',
            72032: '微信官方未告知具体错误，请联系管理员',
            72033: '微信官方未告知具体错误，请联系管理员',
            72034: '微信官方未告知具体错误，请联系管理员',
            72035: '微信官方未告知具体错误，请联系管理员',
            72036: '微信官方未告知具体错误，请联系管理员',
            72037: '微信官方未告知具体错误，请联系管理员',
            72038: '微信官方未告知具体错误，请联系管理员',
            72039: '微信官方未告知具体错误，请联系管理员',
            72040: '微信官方未告知具体错误，请联系管理员',
            72041: '微信官方未告知具体错误，请联系管理员',
            72042: '微信官方未告知具体错误，请联系管理员',
            72043: '微信官方未告知具体错误，请联系管理员',
            72044: '微信官方未告知具体错误，请联系管理员',
            72045: '微信官方未告知具体错误，请联系管理员',
            72046: '微信官方未告知具体错误，请联系管理员',
            72047: '微信官方未告知具体错误，请联系管理员',
            72048: '微信官方未告知具体错误，请联系管理员',
            72049: '微信官方未告知具体错误，请联系管理员',
            72050: '微信官方未告知具体错误，请联系管理员',
            72051: '微信官方未告知具体错误，请联系管理员',
            72052: '微信官方未告知具体错误，请联系管理员',
            72053: '微信官方未告知具体错误，请联系管理员',
            72054: '微信官方未告知具体错误，请联系管理员',
            72055: '微信官方未告知具体错误，请联系管理员',
            72056: '微信官方未告知具体错误，请联系管理员',
            72057: '微信官方未告知具体错误，请联系管理员',
            72058: '微信官方未告知具体错误，请联系管理员',
            72059: '微信官方未告知具体错误，请联系管理员',
            72060: '微信官方未告知具体错误，请联系管理员',
            72061: '微信官方未告知具体错误，请联系管理员',
            72062: '微信官方未告知具体错误，请联系管理员',
            72063: '微信官方未告知具体错误，请联系管理员',
            72064: '微信官方未告知具体错误，请联系管理员',
            72065: '微信官方未告知具体错误，请联系管理员',
            72066: '微信官方未告知具体错误，请联系管理员',
            72067: '微信官方未告知具体错误，请联系管理员',
            72068: '微信官方未告知具体错误，请联系管理员',
            72069: '微信官方未告知具体错误，请联系管理员',
            72070: '微信官方未告知具体错误，请联系管理员',
            72071: '微信官方未告知具体错误，请联系管理员',
            72072: '微信官方未告知具体错误，请联系管理员',
            72073: '微信官方未告知具体错误，请联系管理员',
            72074: '微信官方未告知具体错误，请联系管理员',
            72075: '微信官方未告知具体错误，请联系管理员',
            72076: '微信官方未告知具体错误，请联系管理员',
            72077: '微信官方未告知具体错误，请联系管理员',
            72078: '微信官方未告知具体错误，请联系管理员',
            72079: '微信官方未告知具体错误，请联系管理员',
            72080: '微信官方未告知具体错误，请联系管理员',
            72081: '微信官方未告知具体错误，请联系管理员',
            72082: '微信官方未告知具体错误，请联系管理员',
            72083: '微信官方未告知具体错误，请联系管理员',
            72084: '微信官方未告知具体错误，请联系管理员',
            72085: '微信官方未告知具体错误，请联系管理员',
            72086: '微信官方未告知具体错误，请联系管理员',
            72087: '微信官方未告知具体错误，请联系管理员',
            72088: '微信官方未告知具体错误，请联系管理员',
            72089: '微信官方未告知具体错误，请联系管理员',
            72090: '微信官方未告知具体错误，请联系管理员',
            72091: '微信官方未告知具体错误，请联系管理员',
            72092: '微信官方未告知具体错误，请联系管理员',
            72093: '微信官方未告知具体错误，请联系管理员',
            73000: '微信官方未告知具体错误，请联系管理员',
            73001: '微信官方未告知具体错误，请联系管理员',
            73002: '微信官方未告知具体错误，请联系管理员',
            73003: '微信官方未告知具体错误，请联系管理员',
            73004: '微信官方未告知具体错误，请联系管理员',
            73005: '微信官方未告知具体错误，请联系管理员',
            73006: '微信官方未告知具体错误，请联系管理员',
            73007: '微信官方未告知具体错误，请联系管理员',
            73008: '微信官方未告知具体错误，请联系管理员',
            73009: '微信官方未告知具体错误，请联系管理员',
            73010: '微信官方未告知具体错误，请联系管理员',
            73011: '微信官方未告知具体错误，请联系管理员',
            73012: '微信官方未告知具体错误，请联系管理员',
            73013: '微信官方未告知具体错误，请联系管理员',
            73014: '微信官方未告知具体错误，请联系管理员',
            73015: '微信官方未告知具体错误，请联系管理员',
            73016: '微信官方未告知具体错误，请联系管理员',
            73100: '微信官方未告知具体错误，请联系管理员',
            73101: '微信官方未告知具体错误，请联系管理员',
            73103: '微信官方未告知具体错误，请联系管理员',
            73104: '微信官方未告知具体错误，请联系管理员',
            73105: '微信官方未告知具体错误，请联系管理员',
            73106: '微信官方未告知具体错误，请联系管理员',
            73107: '微信官方未告知具体错误，请联系管理员',
            73108: '微信官方未告知具体错误，请联系管理员',
            73109: '微信官方未告知具体错误，请联系管理员',
            73200: '微信官方未告知具体错误，请联系管理员',
            73201: '微信官方未告知具体错误，请联系管理员',
            73202: '微信官方未告知具体错误，请联系管理员',
            73203: '微信官方未告知具体错误，请联系管理员',
            73204: '微信官方未告知具体错误，请联系管理员',
            73205: '朋友的券玩法升级中，当前暂停创建，请创建其他类型卡券',
            73206: '朋友的券玩法升级中，当前暂停券点充值，请创建其他类型卡券',
            73207: '朋友的券玩法升级中，当前暂停开通券点账户',
            73208: '朋友的券玩法升级中，当前不支持修改库存',
            73209: '朋友的券玩法升级中，当前不支持修改有效期',
            73210: '当前批次不支持修改卡券批次库存',
            73211: '不再支持配置网页链接跳转，请选择小程序替代',
            73212: '微信官方未告知具体错误，请联系管理员',
            73213: '微信官方未告知具体错误，请联系管理员',
            73214: '微信官方未告知具体错误，请联系管理员',
            73215: '支付打通券能力已下线，请直接使用微信支付代金券API：https://pay.weixin.qq.com/wiki/doc/apiv3/wxpay/marketing/convention/chapter1_1.shtml',
            73216: '不合法的按钮名字，请从中选择一个:使用礼品卡/立即使用/去点外卖',
            73217: '礼品卡本身没有设置appname和path，不允许在修改接口设置',
            73218: '未授权使用礼品卡落地页跳转小程序功能',
            74000: '微信官方未告知具体错误，请联系管理员',
            74001: '微信官方未告知具体错误，请联系管理员',
            74002: '微信官方未告知具体错误，请联系管理员',
            74003: '微信官方未告知具体错误，请联系管理员',
            74004: '微信官方未告知具体错误，请联系管理员',
            74005: '微信官方未告知具体错误，请联系管理员',
            74007: '微信官方未告知具体错误，请联系管理员',
            74008: '微信官方未告知具体错误，请联系管理员',
            74009: '微信官方未告知具体错误，请联系管理员',
            74010: '微信官方未告知具体错误，请联系管理员',
            74011: '微信官方未告知具体错误，请联系管理员',
            74021: '微信官方未告知具体错误，请联系管理员',
            74022: '微信官方未告知具体错误，请联系管理员',
            74023: '微信官方未告知具体错误，请联系管理员',
            74040: '微信官方未告知具体错误，请联系管理员',
            74041: '微信官方未告知具体错误，请联系管理员',
            75001: '微信官方未告知具体错误，请联系管理员',
            75002: '微信官方未告知具体错误，请联系管理员',
            75003: '微信官方未告知具体错误，请联系管理员',
            75004: '微信官方未告知具体错误，请联系管理员',
            75005: '微信官方未告知具体错误，请联系管理员',
            75006: '微信官方未告知具体错误，请联系管理员',
            75007: '微信官方未告知具体错误，请联系管理员',
            75008: '微信官方未告知具体错误，请联系管理员',
            75009: '微信官方未告知具体错误，请联系管理员',
            75010: '微信官方未告知具体错误，请联系管理员',
            75011: '微信官方未告知具体错误，请联系管理员',
            75012: '微信官方未告知具体错误，请联系管理员',
            75013: '微信官方未告知具体错误，请联系管理员',
            75014: '微信官方未告知具体错误，请联系管理员',
            75015: '微信官方未告知具体错误，请联系管理员',
            75016: '微信官方未告知具体错误，请联系管理员',
            75017: '微信官方未告知具体错误，请联系管理员',
            75018: '微信官方未告知具体错误，请联系管理员',
            75019: '微信官方未告知具体错误，请联系管理员',
            80000: '系统错误，请稍后再试',
            80001: '参数格式校验错误',
            80002: '签名失败',
            80003: '该日期订单未生成',
            80004: '用户未绑卡',
            80005: '姓名不符',
            80006: '身份证不符',
            80007: '获取城市信息失败',
            80008: '未找到指定少儿信息',
            80009: '少儿身份证不符',
            80010: '少儿未绑定',
            80011: '签约号不符',
            80012: '该地区局方配置不存在',
            80013: '调用方appid与局方配置不匹配',
            80014: '获取消息账号失败',
            80066: '非法的插件版本',
            80067: '找不到使用的插件',
            80082: '没有权限使用该插件',
            80101: '商家未接入',
            80111: '实名校验code不存在',
            80112: 'code并发冲突',
            80113: '无效code',
            80201: 'report_type无效',
            80202: 'service_type无效',
            80300: '申请单不存在',
            80301: '申请单不属于该账号',
            80302: '激活号段有重叠',
            80303: '码格式错误',
            80304: '该码未激活',
            80305: '激活失败',
            80306: '码索引超出申请范围',
            80307: '申请已存在',
            80308: '子任务未完成',
            80309: '子任务文件过期',
            80310: '子任务不存在',
            80311: '获取文件失败',
            80312: '加密数据失败',
            80313: '加密数据密钥不存在，请联系接口人申请',
            81000: '微信官方未告知具体错误，请联系管理员',
            81001: '微信官方未告知具体错误，请联系管理员',
            81002: '微信官方未告知具体错误，请联系管理员',
            81004: '微信官方未告知具体错误，请联系管理员',
            81005: '微信官方未告知具体错误，请联系管理员',
            81006: '微信官方未告知具体错误，请联系管理员',
            81007: '微信官方未告知具体错误，请联系管理员',
            81008: '微信官方未告知具体错误，请联系管理员',
            81009: '微信官方未告知具体错误，请联系管理员',
            81010: '微信官方未告知具体错误，请联系管理员',
            81011: '微信官方未告知具体错误，请联系管理员',
            81012: '微信官方未告知具体错误，请联系管理员',
            81013: '微信官方未告知具体错误，请联系管理员',
            81014: '微信官方未告知具体错误，请联系管理员',
            81015: '微信官方未告知具体错误，请联系管理员',
            81016: '微信官方未告知具体错误，请联系管理员',
            81017: '微信官方未告知具体错误，请联系管理员',
            81018: '微信官方未告知具体错误，请联系管理员',
            81019: '微信官方未告知具体错误，请联系管理员',
            81020: '微信官方未告知具体错误，请联系管理员',
            81021: '微信官方未告知具体错误，请联系管理员',
            81022: '微信官方未告知具体错误，请联系管理员',
            81023: '微信官方未告知具体错误，请联系管理员',
            81024: '微信官方未告知具体错误，请联系管理员',
            81025: '微信官方未告知具体错误，请联系管理员',
            81026: '微信官方未告知具体错误，请联系管理员',
            81027: '微信官方未告知具体错误，请联系管理员',
            81028: '微信官方未告知具体错误，请联系管理员',
            81029: '微信官方未告知具体错误，请联系管理员',
            81030: '微信官方未告知具体错误，请联系管理员',
            81031: '微信官方未告知具体错误，请联系管理员',
            82000: '微信官方未告知具体错误，请联系管理员',
            82001: '微信官方未告知具体错误，请联系管理员',
            82002: '微信官方未告知具体错误，请联系管理员',
            82003: '微信官方未告知具体错误，请联系管理员',
            82010: '微信官方未告知具体错误，请联系管理员',
            82011: '微信官方未告知具体错误，请联系管理员',
            82020: '微信官方未告知具体错误，请联系管理员',
            82021: '微信官方未告知具体错误，请联系管理员',
            82022: '微信官方未告知具体错误，请联系管理员',
            82023: '微信官方未告知具体错误，请联系管理员',
            82024: '微信官方未告知具体错误，请联系管理员',
            82025: '微信官方未告知具体错误，请联系管理员',
            82026: '微信官方未告知具体错误，请联系管理员',
            82027: '微信官方未告知具体错误，请联系管理员',
            82100: '微信官方未告知具体错误，请联系管理员',
            82101: '微信官方未告知具体错误，请联系管理员',
            82102: '微信官方未告知具体错误，请联系管理员',
            82103: '微信官方未告知具体错误，请联系管理员',
            82200: '公众平台账号与服务id不匹配',
            82201: '该停车场已存在，请勿重复添加',
            82202: '该停车场信息不存在，请先导入',
            82203: '停车场价格格式不正确',
            82204: 'appid与code不匹配',
            82205: 'wx_park_code字段为空',
            82206: 'wx_park_code无效或已过期',
            82207: '电话字段为空',
            82208: '关闭时间格式不正确',
            82300: '该appid不支持开通城市服务插件',
            82301: '添加插件失败',
            82302: '未添加城市服务插件',
            82303: 'fileid无效',
            82304: '临时文件过期',
            83000: '微信官方未告知具体错误，请联系管理员',
            83001: '微信官方未告知具体错误，请联系管理员',
            83002: '微信官方未告知具体错误，请联系管理员',
            83003: '微信官方未告知具体错误，请联系管理员',
            83004: '微信官方未告知具体错误，请联系管理员',
            83005: '微信官方未告知具体错误，请联系管理员',
            83006: '微信官方未告知具体错误，请联系管理员',
            83007: '微信官方未告知具体错误，请联系管理员',
            83008: '微信官方未告知具体错误，请联系管理员',
            83009: '微信官方未告知具体错误，请联系管理员',
            83200: '微信官方未告知具体错误，请联系管理员',
            83201: '微信官方未告知具体错误，请联系管理员',
            83202: '微信官方未告知具体错误，请联系管理员',
            83203: '微信官方未告知具体错误，请联系管理员',
            83204: '微信官方未告知具体错误，请联系管理员',
            83205: '微信官方未告知具体错误，请联系管理员',
            83206: '微信官方未告知具体错误，请联系管理员',
            83207: '微信官方未告知具体错误，请联系管理员',
            83208: '微信官方未告知具体错误，请联系管理员',
            83209: '微信官方未告知具体错误，请联系管理员',
            84001: '微信官方未告知具体错误，请联系管理员',
            84002: '微信官方未告知具体错误，请联系管理员',
            84003: '微信官方未告知具体错误，请联系管理员',
            84004: '微信官方未告知具体错误，请联系管理员',
            84005: '微信官方未告知具体错误，请联系管理员',
            84006: '微信官方未告知具体错误，请联系管理员',
            85001: '微信号不存在或微信号设置为不可搜索',
            85002: '小程序绑定的体验者数量达到上限',
            85003: '微信号绑定的小程序体验者达到上限',
            85004: '微信号已经绑定',
            85005: '微信官方未告知具体错误，请联系管理员',
            85006: '标签格式错误',
            85007: '页面路径错误',
            85008: '当前小程序没有已经审核通过的类目，请添加类目成功后重试',
            85009: '已经有正在审核的版本',
            85010: 'item_list 有项目为空',
            85011: '标题填写错误',
            85012: '无效的审核 id',
            85013: '无效的自定义配置',
            85014: '无效的模板编号',
            85015: '该账号不是小程序账号',
            85015: '版本输入错误',
            85016: '域名数量超过限制 ，总数不能超过1000',
            85017: '没有新增域名，请确认小程序已经添加了域名或该域名是否没有在第三方平台添加',
            85018: '域名没有在第三方平台设置',
            85019: '没有审核版本',
            85020: '审核状态未满足发布',
            85021: '微信官方未告知具体错误，请联系管理员',
            85022: '微信官方未告知具体错误，请联系管理员',
            85023: '审核列表填写的项目数不在 1-5 以内',
            85024: '微信官方未告知具体错误，请联系管理员',
            85025: '微信官方未告知具体错误，请联系管理员',
            85026: '微信官方未告知具体错误，请联系管理员',
            85027: '微信官方未告知具体错误，请联系管理员',
            85028: '微信官方未告知具体错误，请联系管理员',
            85029: '微信官方未告知具体错误，请联系管理员',
            85030: '微信官方未告知具体错误，请联系管理员',
            85031: '微信官方未告知具体错误，请联系管理员',
            85032: '微信官方未告知具体错误，请联系管理员',
            85033: '微信官方未告知具体错误，请联系管理员',
            85034: '微信官方未告知具体错误，请联系管理员',
            85035: '微信官方未告知具体错误，请联系管理员',
            85036: '微信官方未告知具体错误，请联系管理员',
            85038: '微信官方未告知具体错误，请联系管理员',
            85039: '微信官方未告知具体错误，请联系管理员',
            85040: '微信官方未告知具体错误，请联系管理员',
            85041: '微信官方未告知具体错误，请联系管理员',
            85042: '微信官方未告知具体错误，请联系管理员',
            85043: '模板错误',
            85044: '代码包超过大小限制',
            85045: 'ext_json 有不存在的路径',
            85046: 'tabBar 中缺少 path',
            85047: 'pages 字段为空',
            85048: 'ext_json 解析失败',
            85049: '微信官方未告知具体错误，请联系管理员',
            85050: '微信官方未告知具体错误，请联系管理员',
            85051: 'version_desc或者preview_info超限',
            85052: '微信官方未告知具体错误，请联系管理员',
            85053: '微信官方未告知具体错误，请联系管理员',
            85054: '微信官方未告知具体错误，请联系管理员',
            85055: '微信官方未告知具体错误，请联系管理员',
            85056: '微信官方未告知具体错误，请联系管理员',
            85057: '微信官方未告知具体错误，请联系管理员',
            85058: '微信官方未告知具体错误，请联系管理员',
            85059: '微信官方未告知具体错误，请联系管理员',
            85060: '微信官方未告知具体错误，请联系管理员',
            85061: '微信官方未告知具体错误，请联系管理员',
            85062: '微信官方未告知具体错误，请联系管理员',
            85063: '微信官方未告知具体错误，请联系管理员',
            85064: '找不到模板',
            85065: '模板库已满',
            85066: '链接错误',
            85067: '微信官方未告知具体错误，请联系管理员',
            85068: '测试链接不是子链接',
            85069: '校验文件失败',
            85070: '个人类型小程序无法设置二维码规则',
            85071: '已添加该链接，请勿重复添加',
            85072: '该链接已被占用',
            85073: '二维码规则已满',
            85074: '小程序未发布, 小程序必须先发布代码才可以发布二维码跳转规则',
            85075: '个人类型小程序无法设置二维码规则',
            85077: '小程序类目信息失效（类目中含有官方下架的类目，请重新选择类目）',
            85078: '微信官方未告知具体错误，请联系管理员',
            85079: '小程序没有线上版本，不能进行灰度',
            85080: '小程序提交的审核未审核通过',
            85081: '无效的发布比例',
            85082: '当前的发布比例需要比之前设置的高',
            85083: '搜索标记位被封禁，无法修改',
            85084: '非法的 status 值，只能填 0 或者 1',
            85085: '小程序提审数量已达本月上限，请点击查看<a href="https://developers.weixin.qq.com/community/minihome/doc/00022ce7b209f09f363b9c62958401?blockType=99" target="_blank" rel="noopener noreferrer">《自助临时申请额度》<span></span></a>',
            85086: '提交代码审核之前需提前上传代码',
            85087: '小程序已使用 api navigateToMiniProgram，请声明跳转 appid 列表后再次提交',
            85088: '微信官方未告知具体错误，请联系管理员',
            85089: '微信官方未告知具体错误，请联系管理员',
            85090: '微信官方未告知具体错误，请联系管理员',
            85091: '小程序的搜索开关被关闭。请访问设置页面打开开关再重试',
            85092: 'preview_info格式错误',
            85093: 'preview_info 视频或者图片个数超限',
            85094: '需提供审核机制说明信息',
            85101: '小程序不能发送该运动类型或运动类型不存在',
            85102: '数值异常',
            86000: '不是由第三方代小程序进行调用',
            86001: '不存在第三方的已经提交的代码',
            86002: '小程序还未设置昵称、头像、简介。请先设置完后再重新提交',
            86003: '微信官方未告知具体错误，请联系管理员',
            86004: '微信官方未告知具体错误，请联系管理员',
            86005: '微信官方未告知具体错误，请联系管理员',
            86006: '微信官方未告知具体错误，请联系管理员',
            86007: '小程序禁止提交',
            86008: '服务商被处罚，限制全部代码提审能力',
            86009: '服务商新增小程序代码提审能力被限制',
            86010: '服务商迭代小程序代码提审能力被限制',
            87006: '小游戏不能提交',
            87007: '微信官方未告知具体错误，请联系管理员',
            87008: '微信官方未告知具体错误，请联系管理员',
            87009: '无效的签名',
            87010: '微信官方未告知具体错误，请联系管理员',
            87011: '现网已经在灰度发布，不能进行版本回退',
            87012: '该版本不能回退，可能的原因：1:无上一个线上版用于回退 2:此版本为已回退版本，不能回退 3:此版本为回退功能上线之前的版本，不能回退',
            87013: '撤回次数达到上限（每天5次，每个月 10 次）',
            87014: '微信官方未告知具体错误，请联系管理员',
            87015: '微信官方未告知具体错误，请联系管理员',
            87016: '微信官方未告知具体错误，请联系管理员',
            87017: '微信官方未告知具体错误，请联系管理员',
            87018: '微信官方未告知具体错误，请联系管理员',
            87019: '微信官方未告知具体错误，请联系管理员',
            87080: '微信官方未告知具体错误，请联系管理员',
            87081: '微信官方未告知具体错误，请联系管理员',
            87082: '微信官方未告知具体错误，请联系管理员',
            87083: '微信官方未告知具体错误，请联系管理员',
            87084: '微信官方未告知具体错误，请联系管理员',
            87085: '微信官方未告知具体错误，请联系管理员',
            88000: '微信官方未告知具体错误，请联系管理员',
            88001: '微信官方未告知具体错误，请联系管理员',
            88002: '微信官方未告知具体错误，请联系管理员',
            88003: '微信官方未告知具体错误，请联系管理员',
            88004: '微信官方未告知具体错误，请联系管理员',
            88005: '微信官方未告知具体错误，请联系管理员',
            88007: '微信官方未告知具体错误，请联系管理员',
            88008: '微信官方未告知具体错误，请联系管理员',
            88009: '微信官方未告知具体错误，请联系管理员',
            88010: '微信官方未告知具体错误，请联系管理员',
            88011: '微信官方未告知具体错误，请联系管理员',
            89000: 'account has bound open，该公众号/小程序已经绑定了开放平台帐号',
            89001: 'not same contractor，Authorizer 与开放平台帐号主体不相同',
            89002: 'open not exists，该公众号/小程序未绑定微信开放平台帐号。',
            89003: '该开放平台帐号并非通过 api 创建，不允许操作',
            89004: '该开放平台帐号所绑定的公众号/小程序已达上限（100  个）',
            89005: '微信官方未告知具体错误，请联系管理员',
            89006: '微信官方未告知具体错误，请联系管理员',
            89007: '微信官方未告知具体错误，请联系管理员',
            89008: '微信官方未告知具体错误，请联系管理员',
            89009: '微信官方未告知具体错误，请联系管理员',
            89010: '微信官方未告知具体错误，请联系管理员',
            89011: '微信官方未告知具体错误，请联系管理员',
            89012: '微信官方未告知具体错误，请联系管理员',
            89013: '微信官方未告知具体错误，请联系管理员',
            89014: '微信官方未告知具体错误，请联系管理员',
            89015: '微信官方未告知具体错误，请联系管理员',
            89016: '微信官方未告知具体错误，请联系管理员',
            89017: '微信官方未告知具体错误，请联系管理员',
            89018: '微信官方未告知具体错误，请联系管理员',
            89019: '业务域名无更改，无需重复设置',
            89020: '尚未设置小程序业务域名，请先在第三方平台中设置小程序业务域名后在调用本接口',
            89021: '请求保存的域名不是第三方平台中已设置的小程序业务域名或子域名',
            89022: '微信官方未告知具体错误，请联系管理员',
            89029: '业务域名数量超过限制，最多可以添加100个业务域名',
            89030: '微信官方未告知具体错误，请联系管理员',
            89031: '微信官方未告知具体错误，请联系管理员',
            89032: '微信官方未告知具体错误，请联系管理员',
            89033: '微信官方未告知具体错误，请联系管理员',
            89034: '微信官方未告知具体错误，请联系管理员',
            89035: '微信官方未告知具体错误，请联系管理员',
            89231: '个人小程序不支持调用 setwebviewdomain 接口',
            89235: '微信官方未告知具体错误，请联系管理员',
            89236: '该插件不能申请',
            89237: '已经添加该插件',
            89238: '申请或使用的插件已经达到上限',
            89239: '该插件不存在',
            89240: '微信官方未告知具体错误，请联系管理员',
            89241: '微信官方未告知具体错误，请联系管理员',
            89242: '微信官方未告知具体错误，请联系管理员',
            89243: '该申请为“待确认”状态，不可删除',
            89244: '不存在该插件 appid',
            89245: '微信官方未告知具体错误，请联系管理员',
            89246: '微信官方未告知具体错误，请联系管理员',
            89247: '系统内部错误',
            89248: '微信官方未告知具体错误，请联系管理员',
            89249: '微信官方未告知具体错误，请联系管理员',
            89250: '内部错误',
            89251: '模板消息已下发，待法人人脸核身校验',
            89253: '法人与企业信息一致性校验中',
            89254: '微信官方未告知具体错误，请联系管理员',
            89255: 'code参数无效，请检查code长度以及内容是否正确；注意code_type的值不同需要传的code长度不一样',
            89256: 'token 信息有误',
            89257: '该插件版本不支持快速更新',
            89258: '当前小程序帐号存在灰度发布中的版本，不可操作快速更新',
            89259: '微信官方未告知具体错误，请联系管理员',
            89300: '订单无效',
            89401: '系统不稳定，请稍后再试，如多次失败请通过社区反馈',
            89402: '该小程序不在待审核队列，请检查是否已提交审核或已审完',
            89403: '本单属于平台不支持加急种类，请等待正常审核流程',
            89404: '本单已加速成功，请勿重复提交',
            89405: '本月加急额度已用完，请提高提审质量以获取更多额度',
            89501: '公众号有未处理的确认请求，请稍候重试',
            89502: '请耐心等待管理员确认',
            89503: '此次调用需要管理员确认，请耐心等候',
            89504: '正在等管理员确认，请耐心等待',
            89505: '正在等管理员确认，请稍候重试',
            89506: '该IP调用求请求已被公众号管理员拒绝，请24小时后再试，建议调用前与管理员沟通确认',
            89507: '该IP调用求请求已被公众号管理员拒绝，请1小时后再试，建议调用前与管理员沟通确认',
            90001: '微信官方未告知具体错误，请联系管理员',
            90002: '微信官方未告知具体错误，请联系管理员',
            90003: '微信官方未告知具体错误，请联系管理员',
            90004: '微信官方未告知具体错误，请联系管理员',
            90005: '微信官方未告知具体错误，请联系管理员',
            90006: '微信官方未告知具体错误，请联系管理员',
            90007: '微信官方未告知具体错误，请联系管理员',
            90009: '微信官方未告知具体错误，请联系管理员',
            90010: '微信官方未告知具体错误，请联系管理员',
            90011: '微信官方未告知具体错误，请联系管理员',
            90012: '微信官方未告知具体错误，请联系管理员',
            90013: '微信官方未告知具体错误，请联系管理员',
            90014: '微信官方未告知具体错误，请联系管理员',
            90015: '微信官方未告知具体错误，请联系管理员',
            90016: '微信官方未告知具体错误，请联系管理员',
            90017: '微信官方未告知具体错误，请联系管理员',
            90018: '微信官方未告知具体错误，请联系管理员',
            91001: '不是公众号快速创建的小程序',
            91002: '小程序发布后不可改名',
            91003: '改名状态不合法',
            91004: '昵称不合法',
            91005: '昵称 15 天主体保护',
            91006: '昵称命中微信号',
            91007: '昵称已被占用',
            91008: '昵称命中 7 天侵权保护期',
            91009: '需要提交材料',
            91010: '其他错误',
            91011: '查不到昵称修改审核单信息',
            91012: '其它错误',
            91013: '占用名字过多',
            91014: '+号规则 同一类型关联名主体不一致',
            91015: '原始名不同类型主体不一致',
            91016: '名称占用者 ≥2',
            91017: '+号规则 不同类型关联名主体不一致',
            91018: '组织类型小程序发布后，侵权被清空昵称，需走认证改名',
            91019: '小程序正在审核中',
            92000: '该经营资质已添加，请勿重复添加',
            92002: '附近地点添加数量达到上线，无法继续添加',
            92003: '地点已被其它小程序占用',
            92004: '附近功能被封禁',
            92005: '地点正在审核中',
            92006: '地点正在展示小程序',
            92007: '地点审核失败',
            92008: '小程序未展示在该地点',
            93009: '小程序未上架或不可见',
            93010: '地点不存在',
            93011: '个人类型小程序不可用',
            93012: '非普通类型小程序（门店小程序、小店小程序等）不可用',
            93013: '从腾讯地图获取地址详细信息失败',
            93014: '同一资质证件号重复添加',
            93015: '附近类目审核中',
            93016: '服务标签个数超限制（官方最多5个，自定义最多4个）',
            93017: '服务标签或者客服的名字不符合要求',
            93018: '服务能力中填写的小程序appid不是同主体小程序',
            93019: '申请类目之后才能添加附近地点',
            93020: 'qualification_list无效',
            93021: 'company_name字段为空',
            93022: 'credential字段为空',
            93023: 'address字段为空',
            93024: 'qualification_list字段为空',
            93025: '服务appid对应的path不存在',
            94001: '微信官方未告知具体错误，请联系管理员',
            94002: '微信官方未告知具体错误，请联系管理员',
            94003: '微信官方未告知具体错误，请联系管理员',
            94004: '微信官方未告知具体错误，请联系管理员',
            94005: '微信官方未告知具体错误，请联系管理员',
            94006: '微信官方未告知具体错误，请联系管理员',
            94007: '微信官方未告知具体错误，请联系管理员',
            94008: '微信官方未告知具体错误，请联系管理员',
            94009: '微信官方未告知具体错误，请联系管理员',
            94010: '微信官方未告知具体错误，请联系管理员',
            94011: '微信官方未告知具体错误，请联系管理员',
            94012: 'appid和商户号的绑定关系不存在',
            95001: '微信官方未告知具体错误，请联系管理员',
            95002: '微信官方未告知具体错误，请联系管理员',
            95101: '微信官方未告知具体错误，请联系管理员',
            95102: '微信官方未告知具体错误，请联系管理员',
            95103: '微信官方未告知具体错误，请联系管理员',
            95104: '微信官方未告知具体错误，请联系管理员',
            95105: '微信官方未告知具体错误，请联系管理员',
            95106: '微信官方未告知具体错误，请联系管理员',
            101000: '微信官方未告知具体错误，请联系管理员',
            101001: '微信官方未告知具体错误，请联系管理员',
            101002: '微信官方未告知具体错误，请联系管理员',
            200002: '入参错误',
            200011: '此账号已被封禁，无法操作',
            200012: '个人模板数已达上限，上限25个',
            200013: '此模板已被封禁，无法选用',
            200014: '模板  tid 参数错误',
            200016: 'start  参数错误',
            200017: 'limit  参数错误',
            200018: '类目  ids 缺失',
            200019: '类目  ids 不合法',
            200020: '关键词列表  kidList 参数错误',
            200021: '场景描述  sceneDesc 参数错误',
            300001: '禁止创建/更新商品（如商品创建功能被封禁） 或 禁止编辑&amp;更新房间',
            300002: '名称长度不符合规则',
            300003: '价格输入不合规（如现价比原价大、传入价格非数字等）',
            300004: '商品名称存在违规违法内容',
            300005: '商品图片存在违规违法内容',
            300006: '图片上传失败（如mediaID过期）',
            300007: '线上小程序版本不存在该链接',
            300008: '添加商品失败',
            300009: '商品审核撤回失败',
            300010: '商品审核状态不对（如商品审核中）',
            300011: '操作非法（API不允许操作非API创建的商品）',
            300012: '没有提审额度（每天500次提审额度）',
            300013: '提审失败',
            300014: '审核中，无法删除（非零代表失败）',
            300017: '商品未提审',
            300021: '商品添加成功，审核失败',
            300022: '此房间号不存在',
            300023: '房间状态 拦截（当前房间状态不允许此操作）',
            300024: '商品不存在',
            300025: '商品审核未通过',
            300026: '房间商品数量已经满额',
            300027: '导入商品失败',
            300028: '房间名称违规',
            300029: '主播昵称违规',
            300030: '主播微信号不合法',
            300031: '直播间封面图不合规',
            300032: '直播间分享图违规',
            300033: '添加商品超过直播间上限',
            300034: '主播微信昵称长度不符合要求',
            300035: '主播微信号不存在',
            300036: '主播微信号未实名认证',
            600001: '微信官方未告知具体错误，请联系管理员',
            600002: '微信官方未告知具体错误，请联系管理员',
            600003: '微信官方未告知具体错误，请联系管理员',
            928000: '票据已存在',
            928001: '票据不存在',
            930555: '微信官方未告知具体错误，请联系管理员',
            930556: '微信官方未告知具体错误，请联系管理员',
            930557: '微信官方未告知具体错误，请联系管理员',
            930558: '微信官方未告知具体错误，请联系管理员',
            930559: '沙盒环境openid无效',
            930560: '微信官方未告知具体错误，请联系管理员',
            930561: '参数错误',
            930562: '微信官方未告知具体错误，请联系管理员',
            930563: '订单不存在',
            930564: '沙盒环境调用无配额',
            930565: '微信官方未告知具体错误，请联系管理员',
            930566: '微信官方未告知具体错误，请联系管理员',
            930567: '微信官方未告知具体错误，请联系管理员',
            930568: '不支持个人类型小程序',
            930569: '已经开通不需要再开通',
            930570: '微信官方未告知具体错误，请联系管理员',
            930571: '该商户没有内测权限，请先申请权限: https://wj.qq.com/s2/7243532/fcfb/',
            931010: '微信官方未告知具体错误，请联系管理员',
            6000100: '微信官方未告知具体错误，请联系管理员',
            6000101: '微信官方未告知具体错误，请联系管理员',
            6000102: '微信官方未告知具体错误，请联系管理员',
            9001001: 'POST 数据参数不合法',
            9001002: '远端服务不可用',
            9001003: 'Ticket 不合法',
            9001004: '获取摇周边用户信息失败',
            9001005: '获取商户信息失败',
            9001006: '获取 OpenID 失败',
            9001007: '上传文件缺失',
            9001008: '上传素材的文件类型不合法',
            9001009: '上传素材的文件尺寸不合法',
            9001010: '上传失败',
            9001020: '帐号不合法',
            9001021: '已有设备激活率低于 50% ，不能新增设备',
            9001022: '设备申请数不合法，必须为大于 0 的数字',
            9001023: '已存在审核中的设备 ID 申请',
            9001024: '一次查询设备 ID 数量不能超过 50',
            9001025: '设备 ID 不合法',
            9001026: '页面 ID 不合法',
            9001027: '页面参数不合法',
            9001028: '一次删除页面 ID 数量不能超过 10',
            9001029: '页面已应用在设备中，请先解除应用关系再删除',
            9001030: '一次查询页面 ID 数量不能超过 50',
            9001031: '时间区间不合法',
            9001032: '保存设备与页面的绑定关系参数错误',
            9001033: '门店 ID 不合法',
            9001034: '设备备注信息过长',
            9001035: '设备申请参数不合法',
            9001036: '查询起始值 begin 不合法',
            9002008: '微信官方未告知具体错误，请联系管理员',
            9002009: '微信官方未告知具体错误，请联系管理员',
            9002010: '微信官方未告知具体错误，请联系管理员',
            9002011: '微信官方未告知具体错误，请联系管理员',
            9002012: '微信官方未告知具体错误，请联系管理员',
            9002013: '微信官方未告知具体错误，请联系管理员',
            9002016: '微信官方未告知具体错误，请联系管理员',
            9002017: '微信官方未告知具体错误，请联系管理员',
            9002026: '微信官方未告知具体错误，请联系管理员',
            9002041: '微信官方未告知具体错误，请联系管理员',
            9002044: '微信官方未告知具体错误，请联系管理员',
            9002052: '微信官方未告知具体错误，请联系管理员',
            9007003: '微信官方未告知具体错误，请联系管理员',
            9007004: '微信官方未告知具体错误，请联系管理员',
            9007005: '微信官方未告知具体错误，请联系管理员',
            9008001: '微信官方未告知具体错误，请联系管理员',
            9008002: '微信官方未告知具体错误，请联系管理员',
            9008003: '微信官方未告知具体错误，请联系管理员',
            9008004: '微信官方未告知具体错误，请联系管理员',
            9200001: '微信官方未告知具体错误，请联系管理员',
            9200002: '微信官方未告知具体错误，请联系管理员',
            9200003: '微信官方未告知具体错误，请联系管理员',
            9200211: '微信官方未告知具体错误，请联系管理员',
            9200231: '微信官方未告知具体错误，请联系管理员',
            9200232: '微信官方未告知具体错误，请联系管理员',
            9200233: '微信官方未告知具体错误，请联系管理员',
            9200235: '微信官方未告知具体错误，请联系管理员',
            9200236: '微信官方未告知具体错误，请联系管理员',
            9200295: '微信官方未告知具体错误，请联系管理员',
            9200297: '微信官方未告知具体错误，请联系管理员',
            9200298: '微信官方未告知具体错误，请联系管理员',
            9200299: '微信官方未告知具体错误，请联系管理员',
            9200300: '微信官方未告知具体错误，请联系管理员',
            9201000: '微信官方未告知具体错误，请联系管理员',
            9201001: '微信官方未告知具体错误，请联系管理员',
            9201002: '微信官方未告知具体错误，请联系管理员',
            9201003: '微信官方未告知具体错误，请联系管理员',
            9201004: '微信官方未告知具体错误，请联系管理员',
            9201005: '微信官方未告知具体错误，请联系管理员',
            9201006: '微信官方未告知具体错误，请联系管理员',
            9201007: '微信官方未告知具体错误，请联系管理员',
            9201008: '微信官方未告知具体错误，请联系管理员',
            9201009: '微信官方未告知具体错误，请联系管理员',
            9201010: '微信官方未告知具体错误，请联系管理员',
            9201011: '微信官方未告知具体错误，请联系管理员',
            9201012: '微信官方未告知具体错误，请联系管理员',
            9201013: '微信官方未告知具体错误，请联系管理员',
            9201014: '微信官方未告知具体错误，请联系管理员',
            9201015: '微信官方未告知具体错误，请联系管理员',
            9201016: '微信官方未告知具体错误，请联系管理员',
            9201017: '微信官方未告知具体错误，请联系管理员',
            9201018: '微信官方未告知具体错误，请联系管理员',
            9201019: '微信官方未告知具体错误，请联系管理员',
            9201020: '微信官方未告知具体错误，请联系管理员',
            9201021: '微信官方未告知具体错误，请联系管理员',
            9201022: '微信官方未告知具体错误，请联系管理员',
            9201023: '微信官方未告知具体错误，请联系管理员',
            9201024: '微信官方未告知具体错误，请联系管理员',
            9201026: '微信官方未告知具体错误，请联系管理员',
            9201027: '微信官方未告知具体错误，请联系管理员',
            9201029: '微信官方未告知具体错误，请联系管理员',
            9201031: '微信官方未告知具体错误，请联系管理员',
            9201032: '微信官方未告知具体错误，请联系管理员',
            9201033: '微信官方未告知具体错误，请联系管理员',
            9201034: '微信官方未告知具体错误，请联系管理员',
            9201035: '微信官方未告知具体错误，请联系管理员',
            9202000: '微信官方未告知具体错误，请联系管理员',
            9202001: '微信官方未告知具体错误，请联系管理员',
            9202002: '微信官方未告知具体错误，请联系管理员',
            9202003: '微信官方未告知具体错误，请联系管理员',
            9202004: '微信官方未告知具体错误，请联系管理员',
            9202005: '微信官方未告知具体错误，请联系管理员',
            9202006: '微信官方未告知具体错误，请联系管理员',
            9202010: '微信官方未告知具体错误，请联系管理员',
            9202011: '微信官方未告知具体错误，请联系管理员',
            9202012: '微信官方未告知具体错误，请联系管理员',
            9202013: '微信官方未告知具体错误，请联系管理员',
            9202016: '微信官方未告知具体错误，请联系管理员',
            9202017: '微信官方未告知具体错误，请联系管理员',
            9202018: '微信官方未告知具体错误，请联系管理员',
            9202020: '微信官方未告知具体错误，请联系管理员',
            9202021: '微信官方未告知具体错误，请联系管理员',
            9203000: '微信官方未告知具体错误，请联系管理员',
            9203001: '微信官方未告知具体错误，请联系管理员',
            9203002: '微信官方未告知具体错误，请联系管理员',
            9203003: '微信官方未告知具体错误，请联系管理员',
            9203004: '微信官方未告知具体错误，请联系管理员',
            9203999: '微信官方未告知具体错误，请联系管理员',
            9204000: '微信官方未告知具体错误，请联系管理员',
            9204001: '微信官方未告知具体错误，请联系管理员',
            9204002: '微信官方未告知具体错误，请联系管理员',
            9205000: '微信官方未告知具体错误，请联系管理员',
            9205001: '微信官方未告知具体错误，请联系管理员',
            9205002: '微信官方未告知具体错误，请联系管理员',
            9205010: '微信官方未告知具体错误，请联系管理员',
            9205101: '微信官方未告知具体错误，请联系管理员',
            9205201: '微信官方未告知具体错误，请联系管理员',
            9206000: '微信官方未告知具体错误，请联系管理员',
            9206001: '微信官方未告知具体错误，请联系管理员',
            9206002: '微信官方未告知具体错误，请联系管理员',
            9206003: '微信官方未告知具体错误，请联系管理员',
            9206200: '微信官方未告知具体错误，请联系管理员',
            9206201: '微信官方未告知具体错误，请联系管理员',
            9206900: '微信官方未告知具体错误，请联系管理员',
            9206901: '微信官方未告知具体错误，请联系管理员',
            9206910: '微信官方未告知具体错误，请联系管理员',
            9206911: '微信官方未告知具体错误，请联系管理员',
            9207000: '微信官方未告知具体错误，请联系管理员',
            9207001: '微信官方未告知具体错误，请联系管理员',
            9207002: '微信官方未告知具体错误，请联系管理员',
            9207003: '微信官方未告知具体错误，请联系管理员',
            9207004: '微信官方未告知具体错误，请联系管理员',
            9207005: '微信官方未告知具体错误，请联系管理员',
            9207006: '微信官方未告知具体错误，请联系管理员',
            9207007: '微信官方未告知具体错误，请联系管理员',
            9207008: '微信官方未告知具体错误，请联系管理员',
            9207009: '微信官方未告知具体错误，请联系管理员',
            9207010: '微信官方未告知具体错误，请联系管理员',
            9207011: '微信官方未告知具体错误，请联系管理员',
            9207012: '微信官方未告知具体错误，请联系管理员',
            9207013: '微信官方未告知具体错误，请联系管理员',
            9207028: '微信官方未告知具体错误，请联系管理员',
            9207029: '微信官方未告知具体错误，请联系管理员',
            9207030: '微信官方未告知具体错误，请联系管理员',
            9207031: '微信官方未告知具体错误，请联系管理员',
            9207032: '微信官方未告知具体错误，请联系管理员',
            9207033: '微信官方未告知具体错误，请联系管理员',
            9207034: '微信官方未告知具体错误，请联系管理员',
            9207050: '微信官方未告知具体错误，请联系管理员',
            9210000: '微信官方未告知具体错误，请联系管理员',
            9291000: '微信官方未告知具体错误，请联系管理员',
            9291001: '微信官方未告知具体错误，请联系管理员',
            9291002: '微信官方未告知具体错误，请联系管理员',
            9291003: '微信官方未告知具体错误，请联系管理员',
            9291004: '微信官方未告知具体错误，请联系管理员',
            9291005: '微信官方未告知具体错误，请联系管理员',
            9291006: '微信官方未告知具体错误，请联系管理员',
            9299999: '微信官方未告知具体错误，请联系管理员',
            9300001: '微信官方未告知具体错误，请联系管理员',
            9300002: '微信官方未告知具体错误，请联系管理员',
            9300003: '微信官方未告知具体错误，请联系管理员',
            9300004: '微信官方未告知具体错误，请联系管理员',
            9300005: '微信官方未告知具体错误，请联系管理员',
            9300006: '微信官方未告知具体错误，请联系管理员',
            9300007: '微信官方未告知具体错误，请联系管理员',
            9300008: '微信官方未告知具体错误，请联系管理员',
            9300009: '微信官方未告知具体错误，请联系管理员',
            9300010: '微信官方未告知具体错误，请联系管理员',
            9300011: '微信官方未告知具体错误，请联系管理员',
            9300012: '微信官方未告知具体错误，请联系管理员',
            9300013: '微信官方未告知具体错误，请联系管理员',
            9300014: '微信官方未告知具体错误，请联系管理员',
            9300015: '微信官方未告知具体错误，请联系管理员',
            9300016: '微信官方未告知具体错误，请联系管理员',
            9300017: '微信官方未告知具体错误，请联系管理员',
            9300018: '微信官方未告知具体错误，请联系管理员',
            9300019: '微信官方未告知具体错误，请联系管理员',
            9300020: '微信官方未告知具体错误，请联系管理员',
            9300021: '微信官方未告知具体错误，请联系管理员',
            9300022: '微信官方未告知具体错误，请联系管理员',
            9300023: '微信官方未告知具体错误，请联系管理员',
            9300024: '微信官方未告知具体错误，请联系管理员',
            9300501: '快递侧逻辑错误，详细原因需要看 delivery_resultcode, 请先确认一下编码方式，python建议 json.dumps(b, ensure_ascii=False)，php建议 json_encode($arr, JSON_UNESCAPED_UNICODE)',
            9300502: '快递公司系统错误',
            9300503: 'delivery_id 不存在',
            9300504: 'service_type 不存在',
            9300505: '微信官方未告知具体错误，请联系管理员',
            9300506: '运单 ID 已经存在轨迹，不可取消',
            9300507: 'Token 不正确',
            9300508: '微信官方未告知具体错误，请联系管理员',
            9300509: '微信官方未告知具体错误，请联系管理员',
            9300510: '微信官方未告知具体错误，请联系管理员',
            9300511: '微信官方未告知具体错误，请联系管理员',
            9300512: '模板格式错误，渲染失败',
            9300513: '微信官方未告知具体错误，请联系管理员',
            9300514: '微信官方未告知具体错误，请联系管理员',
            9300515: '微信官方未告知具体错误，请联系管理员',
            9300516: '微信官方未告知具体错误，请联系管理员',
            9300517: 'update_type 不正确,请使用"bind" 或者“unbind”',
            9300520: '微信官方未告知具体错误，请联系管理员',
            9300521: '微信官方未告知具体错误，请联系管理员',
            9300522: '微信官方未告知具体错误，请联系管理员',
            9300523: '微信官方未告知具体错误，请联系管理员',
            9300524: '订单已取消（一般为重复取消订单）',
            9300525: 'bizid未绑定',
            9300526: '参数字段长度不正确',
            9300527: '微信官方未告知具体错误，请联系管理员',
            9300528: '微信官方未告知具体错误，请联系管理员',
            9300529: '账号已绑定过',
            9300530: '解绑的biz_id不存在',
            9300531: 'bizid无效 或者密码错误',
            9300532: '绑定已提交，审核中',
            9300533: '微信官方未告知具体错误，请联系管理员',
            9300534: 'add_source=2时，wx_appid和当前小程序不同主体',
            9300535: 'shop字段商品缩略图 url、商品名称为空或者非法，或者商品数量为0',
            9300536: 'add_source=2时，wx_appid无效',
            9300537: '微信官方未告知具体错误，请联系管理员',
            9300538: '微信官方未告知具体错误，请联系管理员',
            9300539: '微信官方未告知具体错误，请联系管理员',
            9300540: '微信官方未告知具体错误，请联系管理员',
            9300541: '微信官方未告知具体错误，请联系管理员',
            9300601: '微信官方未告知具体错误，请联系管理员',
            9300602: '微信官方未告知具体错误，请联系管理员',
            9300603: '微信官方未告知具体错误，请联系管理员',
            9300604: '微信官方未告知具体错误，请联系管理员',
            9300605: '微信官方未告知具体错误，请联系管理员',
            9300606: '微信官方未告知具体错误，请联系管理员',
            9300607: '微信官方未告知具体错误，请联系管理员',
            9300608: '微信官方未告知具体错误，请联系管理员',
            9300609: '微信官方未告知具体错误，请联系管理员',
            9300610: '微信官方未告知具体错误，请联系管理员',
            9300611: '微信官方未告知具体错误，请联系管理员',
            9300701: '微信官方未告知具体错误，请联系管理员',
            9300702: '微信官方未告知具体错误，请联系管理员',
            9300703: '微信官方未告知具体错误，请联系管理员',
            9300704: '微信官方未告知具体错误，请联系管理员',
            9300705: '微信官方未告知具体错误，请联系管理员',
            9300706: '微信官方未告知具体错误，请联系管理员',
            9300707: '微信官方未告知具体错误，请联系管理员',
            9300708: '微信官方未告知具体错误，请联系管理员',
            9300801: '无效的微信号',
            9300802: '服务号未开通导购功能',
            9300803: '微信号已经绑定为导购',
            9300804: '该微信号不是导购',
            9300805: '微信号已经被其他账号绑定为导购',
            9300806: '粉丝和导购不存在绑定关系',
            9300807: '标签值无效，不是可选标签值',
            9300808: '标签值不存在',
            9300809: '展示标签值不存在',
            9300810: '导购昵称太长，最多16个字符',
            9300811: '只支持mmbiz.qpic.cn域名的图片',
            9300812: '达到导购绑定个数限制',
            9300813: '达到导购粉丝绑定个数限制',
            9300814: '敏感词个数超过上限',
            9300815: '快捷回复个数超过上限',
            9300816: '文字素材个数超过上限',
            9300817: '小程序卡片素材个数超过上限',
            9300818: '图片素材个数超过上限',
            9300819: 'mediaid 有误',
            9300820: '可查询标签类别超过上限',
            9300821: '小程序卡片内appid不符合要求',
            9300822: '标签类别的名字无效',
            9300823: '查询聊天记录时间参数有误',
            9300824: '自动回复字数太长',
            9300825: '导购群组id错误',
            9300826: '维护中',
            9301001: '微信官方未告知具体错误，请联系管理员',
            9301002: '微信官方未告知具体错误，请联系管理员',
            9301003: '微信官方未告知具体错误，请联系管理员',
            9301004: '微信官方未告知具体错误，请联系管理员',
            9301006: '微信官方未告知具体错误，请联系管理员',
            9301007: '微信官方未告知具体错误，请联系管理员',
            9301008: '微信官方未告知具体错误，请联系管理员',
            9301009: '微信官方未告知具体错误，请联系管理员',
            9301010: '微信官方未告知具体错误，请联系管理员',
            9301011: '微信官方未告知具体错误，请联系管理员',
            9301012: '微信官方未告知具体错误，请联系管理员',
            9400001: '该开发小程序已开通小程序直播权限，不支持发布版本。如需发版，请解绑开发小程序后再操作。',
            9401001: '商品已存在',
            9401002: '商品不存在',
            9401003: '类目已存在',
            9401004: '类目不存在',
            9401005: 'SKU已存在',
            9401006: 'SKU不存在',
            9401007: '属性已存在',
            9401008: '属性不存在',
            9401020: '非法参数',
            9401021: '没有商品权限',
            9401022: '微信官方未告知具体错误，请联系管理员',
            9401023: '微信官方未告知具体错误，请联系管理员',
            9401024: '微信官方未告知具体错误，请联系管理员',
            9401025: '微信官方未告知具体错误，请联系管理员',
            9402001: '微信官方未告知具体错误，请联系管理员',
            9402002: '微信官方未告知具体错误，请联系管理员',
            9402003: '微信官方未告知具体错误，请联系管理员',
            9402004: '微信官方未告知具体错误，请联系管理员',
            9402005: '微信官方未告知具体错误，请联系管理员',
            9402006: '微信官方未告知具体错误，请联系管理员',
            9402007: '微信官方未告知具体错误，请联系管理员',
            9402008: '微信官方未告知具体错误，请联系管理员',
            9402009: '微信官方未告知具体错误，请联系管理员',
            9402010: '微信官方未告知具体错误，请联系管理员',
            9402011: '微信官方未告知具体错误，请联系管理员',
            9402012: '微信官方未告知具体错误，请联系管理员',
            9402013: '微信官方未告知具体错误，请联系管理员',
            9402014: '微信官方未告知具体错误，请联系管理员',
            9402015: '微信官方未告知具体错误，请联系管理员',
            9402016: '微信官方未告知具体错误，请联系管理员',
            9402101: '微信官方未告知具体错误，请联系管理员',
            9402102: '微信官方未告知具体错误，请联系管理员',
            9402202: '请勿频繁提交，待上一次操作完成后再提交',
            9402301: '微信官方未告知具体错误，请联系管理员',
            9403000: '消息类型错误!',
            9403001: '消息字段的内容过长!',
            9403002: '消息字段的内容违规!',
            9403003: '发送的微信号太多!',
            9403004: '存在错误的微信号!',
            9410000: '直播间列表为空',
            9410001: '获取房间失败',
            9410002: '获取商品失败',
            9410003: '获取回放失败',
        },
        educationData: [ //项目管理 学历数据
            {
                id: "博士研究生",
                value: "博士研究生"
            },
            {
                id: "硕士研究生",
                value: "硕士研究生"
            },
            {
                id: "本科",
                value: "本科"
            },
            {
                id: "大专",
                value: "大专"
            },
            {
                id: "大学",
                value: "大学"
            },
            {
                id: "高中",
                value: "高中"
            },
            {
                id: "中专",
                value: "中专"
            },
            {
                id: "初中",
                value: "初中"
            },

            {
                id: "小学",
                value: "小学"
            },

        ],
        workData: [ //项目管理 岗位数据
            {
                id: "项目经理",
                value: "项目经理"
            },
            {
                id: "总工程师",
                value: "总工程师"
            },
            {
                id: "技术总监",
                value: "技术总监"
            },
            {
                id: "技术经理",
                value: "技术经理"
            },
            {
                id: "技术主管",
                value: "技术主管"
            },
            {
                id: "研发工程师",
                value: "研发工程师"
            },
            {
                id: "质量检测工程师",
                value: "质量检测工程师"
            },
            {
                id: "测试工程师",
                value: "测试工程师"
            }, {
                id: "研发中心主任",
                value: "研发中心主任"
            },
        ],
        propertyTypeObj: { //知识产权 类别
            1: "实用新型专",
            2: "外观专",
            3: "软件著作",
            4: "发明专",
            5: "集成电路布图设",
            6: "植物新品种",
            7: "国家新",
            8: "国家一级中药保护品",
            9: "国家级农作物品种"
        },
        wayTypeObj: { //知识产权 获得方式
            1: "自主研发",
            2: "受让",
            3: "并购",
            4: "受赠",
            5: "其他",
        },
        userTableJobDefault: { //项目岗位默认值
            1: [
                { key: "项目经理", content: "项目总负责人，主要负责项目统筹，项目架构、需求分析、资源协调等。" },
                { key: "技术总监", content: "主要负责项目技术攻关，产品规划和设计，对产品的技术要求进行评估，确保产品质量和竞争力。" },
                { key: "产品经理", content: "主要负责项目前期调研、需求分析、需求研究、项目设计、优化等。" },
                { key: "研发工程师", content: "主要负责项目设计、产品开发、优化等。" },
                { key: "检测工程师", content: "主要负责对设备质量和性能进行检测、控制及测试。" },
                { key: '技术支持工程师', content: "主要负责解决客户在使用产品过程中遇到的问题，提供技术支持和解决方案。" },
                { key: '质量管理人员', content: "主要负责产品的质量控制和质量保证工作，包括质量策划、质量控制、质量改进等。" },
                { key: '知识产权工程师', content: "负责知识产权的申请、维护和管理工作，包括专利申请、专利审查和专利维护等。" },
                { key: '技术转移专员', content: "负责技术转移和成果转化工作，包括与产业界的合作、技术推广和商业化等。" },
                { key: '硬件工程师', content: "主要负责硬件设计、开发、测试和调试等工作，包括电路设计、芯片选型、原理图和PCB设计等。" },
                { key: '硬件设计工程师', content: "主要负责硬件方案设计、器件选型、原理图和PCB设计等工作。" },
                { key: '软件工程师', content: "主要负责软件设计、开发、测试和调试等工作，包括操作系统、驱动程序、应用程序等。" },
                { key: '嵌入式软件工程师', content: "主要负责嵌入式系统的软件开发、调试和优化等工作，包括驱动程序、应用程序等。" },
                { key: 'FPGA工程师', content: "主要负责FPGA设计、编程和调试等工作，包括逻辑设计、硬件描述语言编程等。" },
                { key: '维修工程师', content: "主要负责硬件故障的维修和排查，包括电路板维修、器件替换等。" },
                { key: '可靠性工程师', content: "主要负责硬件可靠性的评估和测试，包括环境适应性测试、寿命测试等。" },
                { key: '成本工程师', content: "主要负责硬件成本的分析和控制，包括材料成本、制造成本等。" },
                { key: '机械设计师', content: "主要负责机械设计、开发和优化等工作，包括机械结构、传动系统、液压和气压系统等设计。" },
                { key: '机械工程师', content: "主要负责机械工程方面的设计、开发和优化等工作，包括机构设计、性能分析、材料选择等。" },
                { key: '制造工艺工程师', content: "主要负责制造工艺的设计和优化，包括加工工艺、热处理工艺、表面处理工艺等。" },
                { key: '装配工程师', content: "主要负责机械设备的装配、调试和检测等工作，包括装配图纸设计、装配过程控制等。" },
                { key: '维护工程师', content: "主要负责机械设备的维护和保养工作，包括定期维护计划制定、维护实施等。" },
                { key: '质量工程师', content: "主要负责机械设备的质量控制和质量保证工作，包括质量策划、质量控制、质量改进等。" },
                { key: '采购工程师', content: "主要负责机械设备的采购工作，包括供应商开发、采购合同签订、到货验收等。" },
                { key: '文档编写人员', content: "主要负责编写和维护机械设备的相关的文档，包括使用手册、维护手册、安装手册等。" },
                { key: '材料研发工程师', content: "负责新型材料的研发、试制和优化工作，包括无机材料、有机材料、复合材料等。" },
                { key: '材料测试工程师', content: "负责材料的测试和表征工作，包括物理性能测试、化学成分分析、表面形貌分析等。" },
                { key: '材料生产工程师', content: "负责材料的生产工艺开发和优化工作，包括熔炼、铸造、热处理、表面处理等。" },
                { key: '材料应用工程师', content: "负责材料的应用研究和应用方案设计，包括材料在各个领域的应用探索和开发。" },
                { key: '材料研究专家', content: "负责材料研究领域的学术研究和探索，包括新材料发现、性能研究、应用探索等。" },
                { key: '模具设计工程师', content: "负责根据产品需要选择合适的模具材料，进行三维建模（结构设计），编制模具的制造工艺，确定主要工艺装置和参数等。" },
                { key: '模具制造工程师', content: "负责将模具设计工程师设计出来的模具制造出来。" },
                { key: '传感器研发工程师', content: "负责设计和开发新型传感器，包括传感器的机械设计、电子电路设计、软件算法开发等。" },
                { key: '传感器应用工程师', content: "负责将传感器应用于具体的场景中，包括工业自动化、环境监测、医疗诊断等。" },
                { key: '继电器设计工程师', content: "负责设计和开发新型继电器，包括继电器的机械结构、电磁设计、电子控制等。" },
                { key: '继电器应用工程师', content: "负责将继电器应用于具体的场景中，包括电力控制系统、自动化设备、交通信号控制等。" },
                { key: '仪器仪表测试工程师', content: "负责仪器仪表的测试和验证工作，包括性能测试、可靠性测试、电磁兼容性测试等。" },
                { key: '仪器仪表制造工程师', content: "负责将设计和开发完成的传感器和继电器制造出来，包括机械加工、电子组装、测试调试等。" },
                { key: '电力设备研发工程师', content: "负责电力设备的研发和设计工作，包括电气设计、机械设计、控制系统设计等。" },
                { key: '电力设备测试工程师', content: "负责电力设备的测试和验证工作，包括电气性能测试、机械性能测试、控制系统测试等。" },
                { key: '电力设备生产工艺工程师', content: "负责电力设备的生产工艺开发和优化工作，包括加工工艺、装配工艺、调试工艺等。" },
                { key: '电力设备安装工程师', content: "负责电力设备的安装和调试工作，包括基础施工、设备安装、线路调试等。" },
                { key: '电力设备检修工程师', content: "负责电力设备的定期检修和大型维修工作，包括设备解体检查、零件更换、性能测试等。" },
                { key: '通信设备研发工程师', content: "负责通信设备的研发和设计工作，包括光学设计、机械设计、电子设计等。" },
                { key: '通信设备测试工程师', content: "负责通信设备的测试和验证工作，包括光学性能测试、机械性能测试、电气性能测试等。" },
                { key: '通信设备生产工艺工程师', content: "负责通信设备的生产工艺开发和优化工作，包括材料选择、加工工艺、装配工艺等。" },
                { key: '通信设备安装工程师', content: "负责通信设备的安装和调试工作，包括基础施工、设备安装、线路调试等。" },
                { key: '通信设备检修工程师', content: "负责通信设备的定期检修和大型维修工作，包括设备解体检查、零件更换、性能测试等。" },
                { key: '通信协议开发工程师', content: "负责通信协议的设计和开发工作，包括数据传输协议、错误纠正协议、网络管理协议等。" },
                { key: '通信网络安全工程师', content: "负责通信网络的安全设计和实施工作，包括防火墙设计、入侵检测系统设计、数据加密等。" },
                { key: '化学分析师', content: "负责化学产品的分析、检测、研究等工作，包括化学成分分析、性能测试、质量控制等。" },
                { key: '化学工程师', content: "负责化学产品的设计和研发工作，包括合成路线设计、反应条件优化、生产工艺研发等。" },
                { key: '化学实验员', content: "负责化学实验的准备工作和实验操作，包括实验设备的准备、化学试剂的配制、实验数据的记录等。" },
                { key: '化学产品研究员', content: "负责化学产品的研究和开发工作，包括新产品的研发、应用拓展、专利申请等。" },
                { key: '化学产品应用工程师', content: "负责化学产品在相关领域的应用和推广工作，包括产品应用指导、技术支持等。" },
                { key: '化学产品生产工艺工程师', content: "负责化学产品的生产工艺开发和优化工作，包括材料选择、加工工艺、装配工艺等。" },
                { key: '化验工程师', content: "负责化学产品的检验和测试工作，包括外观检验、性能测试、安全评估等。" },
                { key: '农产品研发工程师', content: "负责农产品的研发和设计工作，包括种植技术、养殖技术、加工技术等。" },
                { key: '农产品测试工程师', content: "负责农产品的测试和验证工作，包括营养成分测试、质量安全测试、保鲜技术等。" },
                { key: '农产品技术支持工程师', content: "负责解决客户在农产品生产、加工、销售过程中遇到的问题，提供技术支持和解决方案。" },
                { key: '农产品生产工艺工程师', content: "负责农产品的生产工艺开发和优化工作，包括种植技术优化、养殖技术改进、加工工艺流程设计等。" },
                { key: '农产品种植研究员', content: "负责农作物的种植研究和开发工作，包括新品种培育、种植技术改进、病虫害防治等。" },
                { key: '农产品养殖研究员', content: "负责动物的养殖研究和开发工作，包括新品种培育、养殖技术改进、疾病防治等。" },
                { key: '农产品加工研究员', content: "负责农产品的加工研究和开发工作，包括新工艺研发、产品质量控制、生产效率提升等。" },
                { key: '农产品营养师', content: "负责农产品的营养研究和开发工作，包括营养成分分析、膳食搭配建议、营养咨询服务等。" },
                { key: '农产品质量安全检测员', content: "负责农产品的质量安全检测工作，包括样品采集、化验检测、质量评估等。" },
                { key: '农产品质量安全检测员', content: "负责农产品的质量安全检测工作，包括样品采集、化验检测、质量评估等。" },
                { key: '技术顾问', content: "主要负责给予项目技术原理的理论支持、指导技术攻关解决方法和思路。" },
                { key: '助理工程师', content: "主要负责质量的检查、检验、分析、鉴定；根据质量统计记录分析产品整体质量，并制定纠正预防措施。" },
            ],
            2: [
                { key: "项目经理", content: "项目总负责人，主要负责项目统筹，项目架构、需求分析、资源协调等。" },
                { key: "技术总监", content: "主要负责项目技术攻关，产品规划和设计，对产品的技术要求进行评估，确保产品质量和竞争力。" },
                { key: "产品经理", content: "主要负责项目产品规划设计、项目架构、流程设计、市场调研。" },
                { key: "前端工程师", content: "主要负责项目前端技术，页面设计，功能维护等。" },
                { key: "研发工程师", content: "主要负责研发产品的基础构架设计、开发和升级维护、程序编码。" },
                { key: "测试工程师", content: "主要负责测试硬件和软件的功能、性能和可靠性，确保产品达到预期的性能和质量标准。" },
                { key: "前端开发工程师", content: "主要负责前端界面的设计和开发，包括用户界面、交互流程等。" },
                { key: "后端开发工程师", content: "主要负责后端服务的设计和开发，包括服务器端程序的开发、数据库的访问等。" },
                { key: "需求分析师", content: "主要负责理解和管理非功能性系统需求，如软件的可维护性、性能、复用性、可靠性、有效性和可测试性等，同时负责完成《用户需求说明书》、《需求变更说明书》。" },
                { key: "系统架构师", content: "主要负责整个软件架构的设计，关键构件、接口的设计，并协助系统分析师完成《系统概要设计说明书》。" },
                { key: "系统分析师", content: "主要负责系统的分析、设计和优化，编写《系统概要设计说明书》，并指导程序员进行编码。" },
                { key: "数据库管理员", content: "主要负责数据库的设计、实施和管理，包括数据库结构的设计、数据存储和备份、数据安全等。" },
                { key: "技术支持工程师", content: "主要负责解决客户在使用产品过程中遇到的问题，提供技术支持和解决方案。" },
                { key: "文档编写人员", content: "主要负责编写和维护产品的相关文档，包括用户手册、技术手册、安装手册等。" },
                { key: "数据分析师", content: "主要负责数据采集、数据处理、数据分析和数据可视化等工作，为项目提供数据支持和洞察。" },
                { key: "算法工程师", content: "主要负责算法的设计、实现和优化等工作，包括机器学习、深度学习等领域。" },
                { key: "自然语言处理工程师", content: "主要负责自然语言处理相关任务，如文本分类、情感分析、机器翻译等。" },
                { key: "图形图像处理工程师", content: "主要负责图形图像的处理和分析，如图像识别、图像增强、三维重建等。" },
                { key: "软件安全工程师", content: "主要负责软件系统的安全性评估和保障工作，包括漏洞扫描、安全审计、安全培训等。" },
                { key: "系统集成工程师", content: "主要负责系统集成任务，如硬件设备调试、软件接口对接、系统性能优化等。" },
                { key: "软件运维工程师", content: "主要负责软件系统的运维工作，包括系统监控、故障排除、性能优化等。" },
                { key: "嵌入式软件开发工程师", content: "主要负责在嵌入式系统中进行软件开发，包括驱动程序、操作系统内核、应用程序等。" },
                { key: "嵌入式系统架构师", content: "主要负责嵌入式系统的架构设计，包括系统分层、模块划分、技术选型等。" },
                { key: "用户体验设计师", content: "负责微信小程序的用户界面设计和用户体验优化，提高用户满意度和忠诚度。" },
                { key: "UI设计师", content: "负责APP的用户界面设计，包括图标、按钮、布局等，提升用户体验和品牌形象。" },
                { key: "Android开发工程师", content: "负责APP的安卓平台开发，包括界面设计、功能实现、性能优化等。" },
                { key: "iOS开发工程师", content: "负责APP的iOS平台开发，包括界面设计、功能实现、性能优化等。" },
                { key: "运营专员", content: "负责APP的推广和运营，包括市场调研、用户反馈处理、活动策划等。" },
                { key: '技术顾问', content: "主要负责给予项目技术原理的理论支持、指导技术攻关解决方法和思路。" },
                { key: '助理工程师', content: "主要负责质量的检查、检验、分析、鉴定；根据质量统计记录分析产品整体质量，并制定纠正预防措施。" },
            ],
            3: [
                { key: "项目经理", content: "项目总负责人，主要负责项目统筹，项目架构、需求分析、资源协调等。" },
                { key: "技术总监", content: "主要负责项目技术攻关，产品规划和设计，对产品的技术要求进行评估，确保产品质量和竞争力。" },
                { key: "产品经理", content: "主要负责项目产品规划设计、项目架构、流程设计、市场调研。" },
                { key: "研发工程师", content: "主要负责项目设计、工艺流程设计优化等。" },
                { key: "研发工程师", content: "主要负责项目应用规划与流程改进。" },
                { key: "检测工程师", content: "主要负责技术验证，产品质量检测。" },
                { key: "工艺技术研发工程师", content: "负责工艺技术的研发和设计工作，包括工艺流程设计、工艺试验设计、工艺技术优化等。" },
                { key: "工艺技术测试工程师", content: "负责工艺技术的测试和验证工作，包括工艺流程测试、工艺试验测试、工艺技术性能评估等。" },
                { key: "工艺技术支持工程师", content: "负责解决客户在使用工艺技术过程中遇到的问题，提供技术支持和解决方案。" },
                { key: "工艺技术文档编写人员", content: "负责编写和维护工艺技术的相关文档，包括工艺技术手册、工艺流程图册、工艺技术专利等。" },
                { key: "工艺技术生产工艺工程师", content: "负责工艺技术的生产工艺开发和优化工作，包括生产设备选型、生产流程设计、生产效率提升等。" },
                { key: "工艺技术分析研究员", content: "负责工艺技术的分析和研究工作，包括工艺流程分析、工艺效率分析、工艺改进建议等。" },
                { key: "工艺技术优化工程师", content: "负责工艺技术的优化和改进工作，包括工艺流程优化、工艺控制优化、工艺效率提升等。" },
                { key: "工艺技术开发工程师", content: "负责新工艺技术的研发和设计工作，包括新工艺流程设计、新工艺试验设计、新工艺技术评估等。" },
                { key: "工艺技术培训师", content: "负责为生产人员提供工艺技术培训和指导，包括新员工培训、技能提升培训、安全生产培训等。" },
                { key: "造纸工程师", content: "负责造纸工艺的设计、研发和优化，包括纸张配方设计、生产流程设计、生产工艺优化等。" },
                { key: "造纸技术研究员", content: "负责造纸技术的研发和实验工作，包括新纸张品种的开发、新型造纸技术的探索、造纸设备的研究与改进等。" },
                { key: "造纸品质控制员", content: "负责纸张品质的检测和控制，包括纸张外观检查、物理性能测试、化学成分分析等。" },
                { key: "造纸生产工艺员", content: "负责造纸生产工艺的实施和管理，包括生产流程安排、工艺条件控制、生产效率提升等。" },
                { key: "造纸设备维护员", content: "负责造纸设备的日常维护和保养，包括设备巡检、故障排除、维修保养等。" },
                { key: "造纸环保工程师", content: "负责造纸工艺的环保设计和实施，包括废水处理、废气治理、固废处置等。" },
                { key: "环保治理工程师", content: "负责环保治理工艺的设计、研发和优化，包括废水处理、废气治理、固废处置等工艺流程的设计和实施。" },
                { key: "环保治理研究员", content: "负责环保治理技术的研发和实验工作，包括新治理技术的探索、污染物减排措施的研究与改进等。" },
                { key: "环保治理品质控制员", content: "负责环保治理品质的检测和控制，包括治理后污染物排放的检测、治理设施运行效能评估等。" },
                { key: "环保治理生产工艺员", content: "负责环保治理生产工艺的实施和管理，包括生产流程安排、工艺条件控制、生产效率提升等。" },
                { key: "环保治理设备维护员", content: "负责环保治理设备的日常维护和保养，包括设备巡检、故障排除、维修保养等。" },
                { key: "环保治理技术文档编写员", content: "负责编写和维护环保治理技术的相关文档，包括环保治理工艺手册、设备操作手册、生产技术规范等。" },
                { key: "环保治理技术培训师", content: "负责为生产人员提供环保治理技术培训和指导，包括新员工培训、技能提升培训、安全生产培训等。" },
                { key: "环保治理咨询顾问", content: "负责为客户提供环保治理方面的技术支持和咨询服务，包括项目方案设计、技术难题解答等。" },
                { key: "环保治理研发助理", content: "协助环保治理工程师或研究员开展研发工作，包括实验准备、数据整理、文献查阅等。" },
                { key: "电梯工程师", content: "负责电梯工程的设计、研发和优化，包括电梯结构设计、控制系统设计、安全系统设计等。" },
                { key: "电梯技术研究员", content: "负责电梯技术的研发和实验工作，包括新电梯品种的开发、新型控制技术的探索、电梯安全性能的研究与改进等。" },
                { key: "电梯品质控制员", content: "负责电梯品质的检测和控制，包括电梯外观检查、性能测试、安全检验等。" },
                { key: "电梯设备维护员", content: "负责电梯设备的日常维护和保养，包括设备巡检、故障排除、维修保养等。" },
                { key: '技术顾问', content: "主要负责给予项目技术原理的理论支持、指导技术攻关解决方法和思路。" },
                { key: '助理工程师', content: "主要负责质量的检查、检验、分析、鉴定；根据质量统计记录分析产品整体质量，并制定纠正预防措施。" },
            ],
            4: [
                { key: "项目经理", content: "项目总负责人，主要负责项目统筹，项目架构、需求分析、资源协调等。" },
                { key: "技术总监", content: "主要负责项目技术攻关，产品规划和设计，对产品的技术要求进行评估，确保产品质量和竞争力。" },
                { key: "产品经理", content: "主要负责项目前期调研、需求分析、需求研究、项目设计、优化等。" },
                { key: "研发工程师", content: "主要负责项目设计、产品开发、优化等。" },
                { key: "检测工程师", content: "主要负责对设备质量和性能进行检测、控制及测试。" },
                { key: '技术支持工程师', content: "主要负责解决客户在使用产品过程中遇到的问题，提供技术支持和解决方案。" },
                { key: '质量管理人员', content: "主要负责产品的质量控制和质量保证工作，包括质量策划、质量控制、质量改进等。" },
                { key: '知识产权工程师', content: "负责知识产权的申请、维护和管理工作，包括专利申请、专利审查和专利维护等。" },
                { key: '技术转移专员', content: "负责技术转移和成果转化工作，包括与产业界的合作、技术推广和商业化等。" },
                { key: '硬件工程师', content: "主要负责硬件设计、开发、测试和调试等工作，包括电路设计、芯片选型、原理图和PCB设计等。" },
                { key: '硬件设计工程师', content: "主要负责硬件方案设计、器件选型、原理图和PCB设计等工作。" },
                { key: '软件工程师', content: "主要负责软件设计、开发、测试和调试等工作，包括操作系统、驱动程序、应用程序等。" },
                { key: '嵌入式软件工程师', content: "主要负责嵌入式系统的软件开发、调试和优化等工作，包括驱动程序、应用程序等。" },
                { key: 'FPGA工程师', content: "主要负责FPGA设计、编程和调试等工作，包括逻辑设计、硬件描述语言编程等。" },
                { key: '维修工程师', content: "主要负责硬件故障的维修和排查，包括电路板维修、器件替换等。" },
                { key: '可靠性工程师', content: "主要负责硬件可靠性的评估和测试，包括环境适应性测试、寿命测试等。" },
                { key: '成本工程师', content: "主要负责硬件成本的分析和控制，包括材料成本、制造成本等。" },
                { key: '机械设计师', content: "主要负责机械设计、开发和优化等工作，包括机械结构、传动系统、液压和气压系统等设计。" },
                { key: '机械工程师', content: "主要负责机械工程方面的设计、开发和优化等工作，包括机构设计、性能分析、材料选择等。" },
                { key: '制造工艺工程师', content: "主要负责制造工艺的设计和优化，包括加工工艺、热处理工艺、表面处理工艺等。" },
                { key: '装配工程师', content: "主要负责机械设备的装配、调试和检测等工作，包括装配图纸设计、装配过程控制等。" },
                { key: '维护工程师', content: "主要负责机械设备的维护和保养工作，包括定期维护计划制定、维护实施等。" },
                { key: '质量工程师', content: "主要负责机械设备的质量控制和质量保证工作，包括质量策划、质量控制、质量改进等。" },
                { key: '采购工程师', content: "主要负责机械设备的采购工作，包括供应商开发、采购合同签订、到货验收等。" },
                { key: '文档编写人员', content: "主要负责编写和维护机械设备的相关的文档，包括使用手册、维护手册、安装手册等。" },
                { key: '材料研发工程师', content: "负责新型材料的研发、试制和优化工作，包括无机材料、有机材料、复合材料等。" },
                { key: '材料测试工程师', content: "负责材料的测试和表征工作，包括物理性能测试、化学成分分析、表面形貌分析等。" },
                { key: '材料生产工程师', content: "负责材料的生产工艺开发和优化工作，包括熔炼、铸造、热处理、表面处理等。" },
                { key: '材料应用工程师', content: "负责材料的应用研究和应用方案设计，包括材料在各个领域的应用探索和开发。" },
                { key: '材料研究专家', content: "负责材料研究领域的学术研究和探索，包括新材料发现、性能研究、应用探索等。" },
                { key: '模具设计工程师', content: "负责根据产品需要选择合适的模具材料，进行三维建模（结构设计），编制模具的制造工艺，确定主要工艺装置和参数等。" },
                { key: '模具制造工程师', content: "负责将模具设计工程师设计出来的模具制造出来。" },
                { key: '传感器研发工程师', content: "负责设计和开发新型传感器，包括传感器的机械设计、电子电路设计、软件算法开发等。" },
                { key: '传感器应用工程师', content: "负责将传感器应用于具体的场景中，包括工业自动化、环境监测、医疗诊断等。" },
                { key: '继电器设计工程师', content: "负责设计和开发新型继电器，包括继电器的机械结构、电磁设计、电子控制等。" },
                { key: '继电器应用工程师', content: "负责将继电器应用于具体的场景中，包括电力控制系统、自动化设备、交通信号控制等。" },
                { key: '仪器仪表测试工程师', content: "负责仪器仪表的测试和验证工作，包括性能测试、可靠性测试、电磁兼容性测试等。" },
                { key: '仪器仪表制造工程师', content: "负责将设计和开发完成的传感器和继电器制造出来，包括机械加工、电子组装、测试调试等。" },
                { key: '电力设备研发工程师', content: "负责电力设备的研发和设计工作，包括电气设计、机械设计、控制系统设计等。" },
                { key: '电力设备测试工程师', content: "负责电力设备的测试和验证工作，包括电气性能测试、机械性能测试、控制系统测试等。" },
                { key: '电力设备生产工艺工程师', content: "负责电力设备的生产工艺开发和优化工作，包括加工工艺、装配工艺、调试工艺等。" },
                { key: '电力设备安装工程师', content: "负责电力设备的安装和调试工作，包括基础施工、设备安装、线路调试等。" },
                { key: '电力设备检修工程师', content: "负责电力设备的定期检修和大型维修工作，包括设备解体检查、零件更换、性能测试等。" },
                { key: '通信设备研发工程师', content: "负责通信设备的研发和设计工作，包括光学设计、机械设计、电子设计等。" },
                { key: '通信设备测试工程师', content: "负责通信设备的测试和验证工作，包括光学性能测试、机械性能测试、电气性能测试等。" },
                { key: '通信设备生产工艺工程师', content: "负责通信设备的生产工艺开发和优化工作，包括材料选择、加工工艺、装配工艺等。" },
                { key: '通信设备安装工程师', content: "负责通信设备的安装和调试工作，包括基础施工、设备安装、线路调试等。" },
                { key: '通信设备检修工程师', content: "负责通信设备的定期检修和大型维修工作，包括设备解体检查、零件更换、性能测试等。" },
                { key: '通信协议开发工程师', content: "负责通信协议的设计和开发工作，包括数据传输协议、错误纠正协议、网络管理协议等。" },
                { key: '通信网络安全工程师', content: "负责通信网络的安全设计和实施工作，包括防火墙设计、入侵检测系统设计、数据加密等。" },
                { key: '化学分析师', content: "负责化学产品的分析、检测、研究等工作，包括化学成分分析、性能测试、质量控制等。" },
                { key: '化学工程师', content: "负责化学产品的设计和研发工作，包括合成路线设计、反应条件优化、生产工艺研发等。" },
                { key: '化学实验员', content: "负责化学实验的准备工作和实验操作，包括实验设备的准备、化学试剂的配制、实验数据的记录等。" },
                { key: '化学产品研究员', content: "负责化学产品的研究和开发工作，包括新产品的研发、应用拓展、专利申请等。" },
                { key: '化学产品应用工程师', content: "负责化学产品在相关领域的应用和推广工作，包括产品应用指导、技术支持等。" },
                { key: '化学产品生产工艺工程师', content: "负责化学产品的生产工艺开发和优化工作，包括材料选择、加工工艺、装配工艺等。" },
                { key: '化验工程师', content: "负责化学产品的检验和测试工作，包括外观检验、性能测试、安全评估等。" },
                { key: '农产品研发工程师', content: "负责农产品的研发和设计工作，包括种植技术、养殖技术、加工技术等。" },
                { key: '农产品测试工程师', content: "负责农产品的测试和验证工作，包括营养成分测试、质量安全测试、保鲜技术等。" },
                { key: '农产品技术支持工程师', content: "负责解决客户在农产品生产、加工、销售过程中遇到的问题，提供技术支持和解决方案。" },
                { key: '农产品生产工艺工程师', content: "负责农产品的生产工艺开发和优化工作，包括种植技术优化、养殖技术改进、加工工艺流程设计等。" },
                { key: '农产品种植研究员', content: "负责农作物的种植研究和开发工作，包括新品种培育、种植技术改进、病虫害防治等。" },
                { key: '农产品养殖研究员', content: "负责动物的养殖研究和开发工作，包括新品种培育、养殖技术改进、疾病防治等。" },
                { key: '农产品加工研究员', content: "负责农产品的加工研究和开发工作，包括新工艺研发、产品质量控制、生产效率提升等。" },
                { key: '农产品营养师', content: "负责农产品的营养研究和开发工作，包括营养成分分析、膳食搭配建议、营养咨询服务等。" },
                { key: '农产品质量安全检测员', content: "负责农产品的质量安全检测工作，包括样品采集、化验检测、质量评估等。" },
                { key: '技术顾问', content: "主要负责给予项目技术原理的理论支持、指导技术攻关解决方法和思路。" },
                { key: '助理工程师', content: "主要负责质量的检查、检验、分析、鉴定；根据质量统计记录分析产品整体质量，并制定纠正预防措施。" },
            ],
        },
        associationDrag: { //拖拽列表数据
            dynamicType: [], //动态分类
            photoUrl: [], //链接类型
            webUrl: [], //页面链接
            myWebUrl: [], //我的页面链接

            articleAllData: [], //文章数据 显示全部
            articleData: [], //有图片的文章数据 属性名为动态分类id 值为动态数据
            activityData: [], //活动数据
            memberStyleData: [], //会员风采
            goodsLinkData: [], //套餐数据
            productData: [], //商品数据
            productTypeData: [], //商品分类 有图标
            productGroupData: [], //商品组
            typeProductData: {}, //用分类区分商品
        },
        parkDrag: { //拖拽列表数据
            dynamicType: [], //动态分类
            photoUrl: [], //链接类型
            webUrl: [], //页面链接
            myWebUrl: [], //我的页面链接

            articleType: [], //文章分类
            articleAllData: [], //文章数据 显示全部
            articleData: [], //有图片的文章数据 属性名为动态分类id 值为动态数据
            activityData: [], //活动数据
            // memberStyleData: [], //会员风采
            // goodsLinkData: [], //套餐数据
            productData: [], //商品数据
            productTypeData: [], //商品分类 有图标
            productGroupData: [], //商品组
            typeProductData: {}, //用分类区分商品
            zhengceData: {}, //政策头条文章数据
            companyarticleData: [], //企业动态
        },
        crmDrag: { //crm拖拽列表数据
            dynamicType: [], //动态分类
            photoUrl: [], //链接类型
            webUrl: [], //页面链接
            myWebUrl: [], //我的页面链接

            articleAllData: [], //文章数据 显示全部
            articleData: {}, //有图片的文章数据 属性名为动态分类id 值为动态数据
            activityData: [], //活动数据
            // memberStyleData: [], //会员风采
            // goodsLinkData: [], //套餐数据

            productData: [], //商品数据
            productTypeData: [], //商品分类 有图标
            productGroupData: [], //商品组
            typeProductData: {}, //用分类区分商品
        },
        //推送数据
        sendArticleData: JSON.parse(sessionStorage.getItem("sendArticleData")) || [],
        wsUrl: "wss://api.yuanqi366.com/ws", //正式库
        apiUrl: "https://api.yuanqi366.com", //正式库
        // wsUrl: "ws://192.168.5.232:7443/ws", //测试库
        // apiUrl: "http://192.168.5.232:8764", //测试库
    },
    mutations: {
        //拖拽数据设置
        /**@method 保存社团拖拽列表数据 */
        setAssociationDrag(state, data) {
            state.associationDrag[data.key] = data.list;
        },
        /**@method 保存社团动态数据 */
        setAssociationDragObjData(state, data) {
            state.associationDrag[data.parentKey][data.key] = data.list;
        },
        /**@method 保存crm拖拽列表数据 */
        setCrmDrag(state, data) {
            state.crmDrag[data.key] = data.list;
        },
        /**@method 保存crm动态数据 */
        setCrmDragObjData(state, data) {
            state.crmDrag[data.parentKey][data.key] = data.list;
        },
        /**@method 保存园区拖拽列表数据 */
        setParkDrag(state, data) {
            state.parkDrag[data.key] = data.list;
        },
        /**@method 保存园区动态数据 */
        setParkDragObjData(state, data) {
            state.parkDrag[data.parentKey][data.key] = data.list;
        },


        /**@method 修改整个文章列表 */
        setSendArticleData(state, data) {
            state.sendArticleData = data;
            sessionStorage.setItem("sendArticleData", JSON.stringify(state.sendArticleData))
        },
        /**@method 保存推送文章数据 */
        addSendArticleData(state, data) {
            if (data) {
                let ids = [];
                for (let item of state.sendArticleData) {
                    ids.push(item.optionType + '-' + item.id);
                }
                for (let row of data) {
                    if (ids.indexOf(row.optionType + '-' + row.id) == -1) {
                        state.sendArticleData.push(row);
                    }
                }
            }
            sessionStorage.setItem("sendArticleData", JSON.stringify(state.sendArticleData))
        },
        /**@method 删除推送文章数据 */
        delSendArticleData(state, data) {
            let ids = [];
            for (let item of state.sendArticleData) {
                ids.push(item.optionType + '-' + item.id);
            }
            let delIndex = ids.indexOf(data.optionType + '-' + data.id);
            state.sendArticleData.splice(delIndex, 1);
            sessionStorage.setItem("sendArticleData", JSON.stringify(state.sendArticleData))
        },
        /**@method 清空推送文章数据 */
        closeSendArticleData(state, data) {
            state.sendArticleData = []
            sessionStorage.removeItem("sendArticleData")
        },
        SET_GOODSINFO(state, data) {
            state.goodsInfo = data;
        },
        setMenuType(state, type) {
            state.menuType = type;
        },
        /**@method 设置选中的园区 */
        setCheckedPark(state, data) {
            state.checkedPark = data;
        },
        /**@method 设置园区数据 */
        setMyParkData(state, data) {
            state.myParkData = data;
        },
        /**@method 设置选中的社团 */
        setCheckedAssociation(state, data) {
            state.checkedAssociation = data;
        },
        /**@method 设置社团数据 */
        setMyAssociationData(state, data) {
            state.myAssociationData = data;
        },
        setRouterArr(state, data) {
            state.routerArr = data
        },
        setEmpId(state, data) {
            state.empId = data
        }
    },
    modules: {
        loginRoot
    }
})

export default store