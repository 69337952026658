<template>
	<div class="commoditysearch">
		<div class="newThreeBox flex-a-b-c">
			<div class="typeBtn flex-c-c" v-if="datas.showTypeBtn"
				:style="{ backgroundColor: datas.borderColor, color: datas.textColor }">
				<div class="flex-c-c" v-for="(title, index) in datas.btnArr" :key="index"
					:style="{ background: index === 0 ? datas.checkedColor : '', color: index === 0 ? '#fff' : '' }">{{ title }}</div>
			</div>
			<div class="flex-a-b-c search"
				:style="{ backgroundColor: datas.borderColor, 'border-radius': datas.borderRadius + 'px' }">
				<!-- 搜索框的长度 -->
				<div class="seeIcon flex-c-c pl10" :style="{ color: datas.textColor }">
					<span class="el-icon-search" style="font-size: 23px;"></span>
				</div>
				<div class="searchPlaceholder" v-if="showBtn == 2" :style="{ width: '180px', color: datas.textColor }">
					{{ datas.searchPlaceholder }}</div>
				<div class="searchPlaceholder" v-else-if="showBtn == 1" :style="{ width: '280px', color: datas.textColor }">
					{{ datas.searchPlaceholder }}</div>
				<div class="searchPlaceholder" v-else-if="showBtn == 0" :style="{ width: '337px', color: datas.textColor }">
					{{ datas.searchPlaceholder }}</div>
			</div>
			<div class="flex-c-c pa5 coupon" v-if="datas.showCoupon">
				<img src="https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/one-stand/products/icon_discount.png"
					alt="">
			</div>
		</div>

		<!-- 删除组件 -->
		<slot name="deles" />
	</div>
</template>

<script>
export default {
	name: 'associationProductsearch',
	props: {
		datas: Object,
	},
	computed: {
		showBtn() {
			let showState = 0;//0都没显示 1显示了一个 2显示了二个 3全部显示
			if (this.datas.showCoupon) showState++;
			if (this.datas.showTypeBtn) showState++;
			return showState;
		}
	}
}
</script>

<style scoped lang="scss">
.commoditysearch {
	position: relative;
}

.newThreeBox {
	height: 33px;

	.searchPlaceholder {
		padding: 5px 20px 5px 5px;
		height: 31px;
		overflow: hidden;
		white-space: nowrap;
	}

	.typeBtn {
		height: 30px;
		border-radius: 5px;
		overflow: hidden;
		border: 1px solid #eee;

		>div {
			padding: 0 10px;
			height: 100%;
		}
	}

	.coupon {
		img {
			height: 34px;
		}
	}

	.iocnSort {
		transform: rotate(90deg)
	}
}</style>
