import httpUtil from "@/utils/httpUtil";

/**设置项目人员 */
export function setProjectUser(params) {
    return httpUtil.post("/api/crmPc/project/setProjectUser", params)
}
/**获得项目人员列表 */
export function getProjectUserList(params) {
    return httpUtil.post("/api/crmPc/project/getProjectUserList", params)
}
/**设置项目数据 */
export function setProjectData(params) {
    return httpUtil.post("/api/crmPc/project/setProjectData", params)
}
/**获得项目数据列表 */
export function getProjectDataList(params) {
    return httpUtil.post("/api/crmPc/project/getProjectDataList", params)
}
/**获得模板数据列表 */
export function getWordModelList(params) {
    return httpUtil.post("/api/crmPc/project/getWordModelList", params)
}
/**解析文档内容格式/上传模板 */
export function analysisWord(params) {
    return httpUtil.post("/api/crmPc/project/analysisWord", params)
}
/**删除模板 */
export function delWordModel(params) {
    return httpUtil.post("/api/crmPc/project/delWordModel", params)
}
/**修改模板 */
export function setWordModel(params) {
    return httpUtil.post("/api/crmPc/project/setWordModel", params)
}
/**文档列表 */
export function getWordDataList(params) {
    return httpUtil.post("/api/crmPc/project/getWordDataList", params)
}
/**填写模板导出文档 */
export function writeAndDownload(params, fileList) {
    return httpUtil.filePost("/api/crmPc/project/writeAndDownload", params, fileList)
}
export function writeAndDownloadFile(params, fileName) {
    return httpUtil.fileStream("/api/crmPc/project/writeAndDownloadFile", params, fileName)
}
/**删除文档 */
export function delWordData(params) {
    return httpUtil.post("/api/crmPc/project/delWordData", params)
}
/**设置项目公司 */
export function setProjectCompany(params) {
    return httpUtil.post("/api/crmPc/project/setProjectCompany", params)
}
/**获得项目公司列表 */
export function getProjectCompanyList(params) {
    return httpUtil.post("/api/crmPc/project/getProjectCompanyList", params)
}

/**项目PDF列表 */
export function getProjectPdfList(params) {
    return httpUtil.post("/api/crmPc/project/getProjectPdfList", params)
}
/**删除项目PDF */
export function delProjectPdf(params) {
    return httpUtil.post("/api/crmPc/project/delProjectPdf", params)
}
/**合并PDF */
export function mergePdfFiles(params) {
    return httpUtil.post("/api/crmPc/project/mergePdfFiles", params)
}
/**压缩pdf */
export function compressPdf(params) {
    return httpUtil.post("/api/crmPc/project/compressPdf", params)
}
/**word数据转PDF */
export function word2Pdf(params) {
    return httpUtil.post("/api/crmPc/project/word2Pdf", params)
}
/**上传word转PDF */
export function uploadPdfFiles(params, fileList) {
    return httpUtil.filePost("/api/crmPc/project/uploadPdfFiles", params, fileList)
}
/**字典 */
export const getByDictType = async params => httpUtil.post("/api/crmPc/sysDict/getByDictType", params);
/**获得项目部门 */
export const getProjectDepartment = async params => httpUtil.post("/api/crmPc/project/getProjectDepartment", params);
/**设置项目部门 */
export const setProjectDepartment = async params => httpUtil.post("/api/crmPc/project/setProjectDepartment", params);
/**下载word文件流 */
export const downloadWordFile = async params => httpUtil.fileStream("/api/crmPc/project/downloadWordFile", params);
/**设置知识产权信息 */
export const outPutProjectNormalWord = async params => httpUtil.post("/api/crmPc/project/outPutProjectNormalWord", params);
/**填写项目默认模板数据 */
export const setProjectNormalWord = async params => httpUtil.post("/api/crmPc/project/setProjectNormalWord", params);
/**word数据列表 */
export const getNormalWordDataList = async params => httpUtil.post("/api/crmPc/project/getNormalWordDataList", params);
/**word数据详情 */
export const getOneNormalWordData = async params => httpUtil.post("/api/crmPc/project/getOneNormalWordData", params);
/**删除word数据 */
export const delNormalWordData = async params => httpUtil.post("/api/crmPc/project/delNormalWordData", params);
/**获得项目数据 */
export const getNormalWordDataByPdId = async params => httpUtil.post("/api/crmPc/project/getNormalWordDataByPdId", params);
/**人员申请日期范围 */
export const getProjectCYSQMonth = async params => httpUtil.post("/api/crmPc/project/getProjectCYSQMonth", params);

/**获取组织管理制度数据 */
export const getProjectRulesData = async params => httpUtil.post("/api/crmPc/project/getProjectRulesData", params);
/**编辑组织管理制度数据 */
export const setProjectRulesData = async params => httpUtil.post("/api/crmPc/project/setProjectRulesData", params);

/**获得知识产权类型数量 */
export const getProjectPropertyTypeNum = async params => httpUtil.post("/api/crmPc/project/getProjectPropertyTypeNum", params);
/**获得知识产权信息列表 */
export const getProjectPropertyList = async params => httpUtil.post("/api/crmPc/project/getProjectPropertyList", params);
/**设置知识产权信息 */
export const setProjectProperty = async params => httpUtil.post("/api/crmPc/project/setProjectProperty", params);

/**获得项目和人员数据列表 */
export const getProjectDataAndUserDataList = async params => httpUtil.post("/api/crmPc/project/getProjectDataAndUserDataList", params);
/**设置项目和人员数据列表 */
export const setProjectDataAndUserData = async params => httpUtil.post("/api/crmPc/project/setProjectDataAndUserData", params);

/**更新组织管理制度数据 */
export const updateProjectRulesData = async params => httpUtil.post("/api/crmPc/project/updateProjectRulesData", params);
/**导出项目数据表 */
export const outPutProjectData = async params => httpUtil.post("/api/crmPc/project/outPutProjectData", params);
/**更新项目费用计算 */
export const updateProjectMoneyCount = async params => httpUtil.post("/api/crmPc/project/updateProjectMoneyCount", params);
/**删除申报项目数据 */
export const delProjectNormalWord = async params => httpUtil.post("/api/crmPc/project/delProjectNormalWord", params);