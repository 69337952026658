<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent">
                <div class="flex-c-c textc mt20">
                    <!-- <el-input v-model="formData.name" class="fs30 pjName w100p" placeholder="项目名称"></el-input> -->
                    <div class="zdhq fs35">{{ checkedCompany.companyName }}</div>
                </div>
                <div class="textc pjTitle mt10 mb20 fs30">科技人员的培养进修职工技能培训优秀人才引进以及人才绩效评价奖励制度等材料审查目录</div>
                <table border="1" cellspacing="0" class="tableBorder institutionTable">
                    <thead>
                        <tr class="h80 bgffebdd">
                            <td class="textc itemTitle mw80">序号</td>
                            <td class="textc itemTitle">制度名称或佐证材料名称</td>
                            <td class="textc itemTitle mw100">文件类型</td>
                            <td class="textc itemTitle mw100">是/否有</td>
                        </tr>
                    </thead>
                    <tbody v-if="tableData.length > 0">
                        <tr class="h80" :class="{ 'fwbold': row.fwbold }" v-for="(row, index) in tableData"
                            :key="index">
                            <td class="textc itemContent">{{ row.projectNo }}</td>
                            <td class="itemContent">{{ row.projectName }}</td>
                            <td class="textc itemContent">{{ row.type }}</td>
                            <td class="textc itemContent" :class="{ 'fwbold': row.fwbold }">√</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr class="h200 textc">
                            <td colspan="7">暂无数据</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution940",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            //列表配置
            tableData: [
                {
                    projectNo: "9.4.1.0",
                    projectName: "高技术人才引进管理办法",
                    type: "制度文件",
                    fwbold: true,
                },
                {
                    projectNo: "9.4.2.0",
                    projectName: "研发技术人员培训管理制度",
                    type: "制度文件",
                    fwbold: true,
                },
                {
                    projectNo: "9.4.2.1",
                    projectName: "科技人员年度培训计划",
                    type: "佐证材料",
                    fwbold: false,
                },
                {
                    projectNo: "9.4.2.2",
                    projectName: "培训效果评价表",
                    type: "佐证材料",
                    fwbold: false,
                },
                {
                    projectNo: "9.4.2.3",
                    projectName: "员工培训记录（签到）表",
                    type: "佐证材料",
                    fwbold: false,
                },
                {
                    projectNo: "9.4.2.4",
                    projectName: "内部培训讲师培训费签收单",
                    type: "佐证材料",
                    fwbold: false,
                },
                {
                    projectNo: "9.4.3.0",
                    projectName: "技术人员绩效考核管理办法",
                    type: "制度文件",
                    fwbold: true,
                },
                {
                    projectNo: "9.4.3.1",
                    projectName: "项目经理半年度、年度绩效考评表",
                    type: "佐证材料",
                    fwbold: false,
                },
                {
                    projectNo: "9.4.3.2",
                    projectName: "研发人员半年度、年度绩效考评表",
                    type: "佐证材料",
                    fwbold: false,
                },
                {
                    projectNo: "9.4.4.0",
                    projectName: "技术创新及科技成果转化奖励制度",
                    type: "制度文件",
                    fwbold: true,
                },
                {
                    projectNo: "9.4.4.1",
                    projectName: "技术创新及科技成果转化奖励申请表",
                    type: "佐证材料",
                    fwbold: false,
                },
            ],
            checkedCompany:{},
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        }
    },
    watch: {
        yearValue: {
            handler(data) {
            },
            deep: true,
            immediate: true,
        },
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany=data.companyData;
                }
            },
            deep: true,
            immediate: true
        },
    },
    created() {

    },
    methods: {

    },
};
</script>

<style lang="scss" scoped>
.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.institutionTable {
    border-color: #000;

    .itemTitle {
        height: 100%;
        font-size: 25px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 20px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    letter-spacing: 3px;
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 20px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }
}
</style>