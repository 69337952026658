<template>
    <div class="graphicnavigationstyle">
        <!-- 标题 -->
        <h2>{{ datas.text }}</h2>
        <div class="moditystyle moditystyle2">
            <span class="addMethod" :class="datas.addMethod == 'auto' ? 'active' : ''" @click="datas.addMethod = 'auto'">
                自动获取活动
            </span>
            <span class="addMethod" :class="datas.addMethod == 'manual' ? 'active' : ''"
                @click="datas.addMethod = 'manual'">
                手动添加活动
            </span>
        </div>
        <br>
        <div v-if="datas.addMethod == 'manual'">
            <!-- 提示 -->
            <p style="color: #969799; font-size: 12px; margin-top: 10px">
                拖动选中的导航可对其排序
            </p>

            <!-- 图片广告 -->
            <div v-if="datas.imageList[0]">
                <vuedraggable v-model="datas.imageList" v-bind="dragOptions">
                    <transition-group>
                        <section class="imgList" v-for="(item, index) in datas.imageList" :key="item + index">
                            <i class="el-icon-circle-close" @click="deleteimg(index)" />
                            <!-- 图片 -->
                            <div class="imag">
                                <img draggable="false" :src="item.src" alt="" />
                            </div>
                            <!-- 标题和链接 -->
                            <div class="imgText">
                                <el-input v-model="item.dictLabel" readonly placeholder="大分类" size="mini"></el-input>
                                <el-input v-model="item.text" readonly placeholder="请输入标题，也可不填" size="mini"></el-input>
                            </div>
                        </section>
                    </transition-group>
                </vuedraggable>
            </div>

            <!-- 上传图片 -->
            <el-button class="uploadImg" @click="showProrductTypeModel" type="primary" plain><i
                    class="el-icon-plus" />点击添加商品分类
            </el-button>
        </div>
        <div class="bor"></div>

        <!-- 表单 -->
        <el-form label-width="80px" :model="datas" size="small">
            <!-- 是否显示更多 -->
            <el-form-item class="lef" label="导航标题">
                <div class="weiz flex-a-b-c">
                    <div style="width: 196px">
                        <el-input v-if="datas.showTitle" v-model="datas.title" placeholder="请输入标题"></el-input>
                    </div>
                    <el-checkbox v-model="datas.showTitle" />
                </div>
            </el-form-item>

            <div style="height: 10px" />

            <!-- 是否显示更多 -->
            <el-form-item class="lef" label="更多">
                <div class="weiz flex-a-b-c">
                    <div style="width: 196px">
                        <parkJumpLink :linkData="datas.moreHttp" @changeFun="moreJumpLinkChange($event)" />
                    </div>
                    <el-checkbox v-model="datas.showMore" />
                </div>
            </el-form-item>

            <el-form-item class="lef" label="验证">
                验证登录 <el-checkbox v-model="datas.isLogin" />
                验证名片 <el-checkbox v-model="datas.isCard" @change="changeCheckCard($event)" />
            </el-form-item>
            <div style="height: 10px" />

            <!-- 导航类型选择 -->
            <!-- <el-form-item class="lef" label="导航类型">
          <el-radio-group v-model="datas.navigationType">
            <el-radio style="margin-left: 35px" :label="index - 1" v-for="index in 2" :key="index">{{ index === 1 ? '图片导航'
              : '文字导航'
            }}</el-radio>
          </el-radio-group>
        </el-form-item> -->

            <div style="height: 10px" />
            <!-- 边框倒角 -->
            <el-form-item label="边框倒角" class="lef borrediu">
                <el-slider v-model="datas.borderRadius" :max="30" input-size="mini" show-input>
                </el-slider>
            </el-form-item>

            <div style="height: 10px" />
            <!-- 一行显示 -->
            <el-form-item label="一行显示" class="lef">
                <el-slider v-model="datas.rowCount" :max="5" :min="1" input-size="mini" show-input>
                </el-slider>
            </el-form-item>

            <div style="height: 10px" />

            <!-- 导航样式 -->
            <!-- <el-form-item class="lef" label="导航样式">
          <div class="weiz">
            <el-tooltip effect="dark" :content="index - 1 === 0 ? '固定' : '滑动'" placement="bottom" v-for="index in 2"
              :key="index">
              <i class="iconfont" :class="[
                index - 1 === 0 ? 'icon-guding' : 'icon-hengxianghuadong',
                datas.imgStyle === index - 1 ? 'active' : '',
              ]" @click="datas.imgStyle = index - 1" />
            </el-tooltip>
          </div>
        </el-form-item> -->

            <!-- <div style="height: 10px" /> -->

            <!-- 一屏显示 -->
            <el-form-item class="lef" label="一屏显示" v-show="datas.imgStyle === 1">
                <el-select v-model="datas.showSize" placeholder="请选择活动区域" style="margin-left: 90px">
                    <el-option :label="index + 4 + '个导航'" :value="index + 4" v-for="index in 7" :key="index"></el-option>
                </el-select>
            </el-form-item>

            <div style="height: 10px" v-show="datas.imgStyle === 1" />

            <!-- 文字高度 -->
            <!-- <el-form-item label="文字高度" class="lef">
          <el-slider v-model="datas.textHeight" :max="50" :min="12" input-size="mini" show-input>
          </el-slider>
        </el-form-item>
  
        <div style="height: 10px" /> -->

            <!-- 文字大小 -->
            <!-- <el-form-item label="文字大小" prop="textSize" :hide-required-asterisk="true" class="lef">
          <el-input type="number" v-model.number="datas.textSize" placeholder="请输入文字大小" :maxlength="2" />
        </el-form-item>
  
        <div style="height: 10px" /> -->

            <!-- 图片大小 -->
            <el-form-item label="图片大小" class="lef borrediu">
                <el-slider v-model="datas.imgSize" :max="45" :min="10" input-size="mini" show-input>
                </el-slider>
            </el-form-item>

            <!-- <div style="height: 10px" /> -->
            <!-- 图片倒角 -->
            <!-- <el-form-item label="图片倒角" class="lef borrediu">
          <el-slider v-model="datas.iconRadius" :max="50" input-size="mini" show-input>
          </el-slider>
        </el-form-item>
  
        <div style="height: 10px" /> -->

            <!-- <el-form-item class="lef" label="背景图片">
          <div class="shop-head-pic" style="text-align: center">
            <img class="home-bg" :src="datas.bgImg" alt="" v-if="datas.bgImg" />
            <div class="shop-head-pic-btn" style="text-align: center">
              <el-button @click="showUpload('1')" class="uploadImg" type="primary" plain><i class="el-icon-plus" />更换图片
              </el-button>
              <el-button type="primary" class="uploadImg" @click="clear()">清空图片</el-button>
            </div>
          </div>
        </el-form-item>
        <div style="height: 10px" /> -->
            <div style="height: 10px" />
            <el-form-item label="边框阴影" class="lef">
                <div class="flex-a-b-c">
                    {{ datas.isShadow ? '显示' : '隐藏' }}
                    <el-checkbox v-model="datas.isShadow" />
                </div>
            </el-form-item>
            <!-- 背景颜色 -->
            <el-form-item class="lef" label="背景颜色">
                <el-color-picker v-model="datas.backgroundColor" show-alpha class="picke" :predefine="predefineColors">
                </el-color-picker>
            </el-form-item>

            <div style="height: 10px" />

            <!-- 文字颜色 -->
            <el-form-item class="lef" label="文字颜色">
                <el-color-picker v-model="datas.textColor" show-alpha class="picke" :predefine="predefineColors">
                </el-color-picker>
            </el-form-item>
        </el-form>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择活动" :visible.sync="showProrductType"
            :destroy-on-close="true" width="80%" center>
            <commonTable :tableData="prorductTypeTableData" :loading="prorductTypeLoading"
                @handleSizeChange="prorductTypeHandleSizeChange" @handleCurrentChange="prorductTypeHandleCurrentChange"
                :currentPage="prorductTypeCurrentPage" :total="prorductTypeTotal">
                <template v-slot:table>
                    <el-table-column width="55">
                        <template slot-scope="scope">
                            <!-- <el-radio class="ml10" v-model="prorductTypeCheckedData.id" :label="scope.row.aeId"
                                @change="selectProrductType(scope.row)">
                                {{ ' ' }}
                            </el-radio> -->
                            <el-checkbox v-model="goodstypeIds[scope.row.goodstypeId]"
                                @change="selectProrductType($event, scope.row)" :label="scope.row.goodstypeId">{{ ''
                                }}</el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goodPhoto" align="center" label="图标" width="120px">
                        <template slot-scope="scope">
                            <el-image v-if="scope.row.typeIcon" style="width: 100px; height: 100px;margin-bottom:-10px"
                                :src="scope.row.typeIcon">
                            </el-image>
                            <p v-else style="width: 100px; height: 100px;line-height: 100px">
                                暂无图片
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="dictLabel" align="center" label="大分类" />
                    <el-table-column prop="name" align="center" label="分类名称" />
                    <el-table-column prop="goodsNum" align="center" label="商品数量" />
                    <el-table-column prop="dealNum" align="center" label="总销量" />
                </template>
            </commonTable>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消 上 传</el-button>
                <el-button type="primary" @click="submitTable" :disabled="disabl">点 击 上 传</el-button>
            </span>
        </el-dialog>
        <!-- 上传图片 -->
        <uploadImg ref="upload" @uploadInformation="uploadInformation" />
    </div>
</template>
  
<script>
import uploadImg from '../../../uploadImg' //图片上传
import vuedraggable from 'vuedraggable' //拖拽组件
import { selectGoodsTypeInfoPC } from "@/api/appModel.js";
import commonTable from "@/components/common/commonTable";

export default {
    name: 'parkProductTypestyle',
    props: {
        datas: Object,
    },
    data() {
        return {
            setImgIndex: 0,
            dragOptions: {
                //拖拽配置
                animation: 200,
            },
            predefineColors: [
                // 颜色选择器预设
                "#fff",
                "#000",
                "#3064ba",
                '#51CDCB',
                '#ff4500',
                '#ff8c00',
                '#ffd700',
                '#90ee90',
                '#00ced1',
                '#1e90ff',
                '#c71585',
                '#409EFF',
                '#909399',
                '#C0C4CC',
                'rgba(255, 69, 0, 0.68)',
                'rgb(255, 120, 0)',
                'hsv(51, 100, 98)',
                'hsva(120, 40, 94, 0.5)',
                'hsl(181, 100%, 37%)',
                'hsla(209, 100%, 56%, 0.73)',
                '#c7158577',
            ],
            emptyText: '',
            uploadImgDataType: null,
            productType: [],
            //商品分类选择
            goodstypeIds: [],
            prorductTypeTableData: [],
            showProrductType: false,//模态框显示状态
            prorductTypeCurrentPage: 1, //当前页
            prorductTypePageSize: 10, //显示条数
            prorductTypeLoading: false, //表格加载
            prorductTypeTotal: 0, //总条数
            checkedProrductType: {},//选中的内容
            disabl: false,
        }
    },
    created() {
        this.productType = this.$store.state.parkDrag.productTypeData;
        if (this.datas.addMethod === 'auto') {
            this.changeDataNumber();
        }
    },
    methods: {
        /**@method 关闭商品分类列表 */
        handleClose() {
            this.showProrductType = false
        },
        /**@method 确认选中商品分类 */
        submitTable() {
            this.disabl = true;
            for (let i in this.checkedProrductType) {
                let row = this.checkedProrductType[i];
                this.datas.imageList.push({
                    id: row.goodstypeId,
                    src: row.typeIcon,
                    text: row.name,
                    dictLabel:row.dictLabel
                })
            }
            this.showProrductType = false;
            this.disabl = false;
        },
        /**@method 选中商品分类 */
        selectProrductType(isOpen, data) {
            if (isOpen) {
                this.checkedProrductType[data.goodstypeId] = data;
            } else {
                delete this.checkedProrductType[data.goodstypeId];
            }
        },
        /**@method 显示商品分类列表 */
        showProrductTypeModel() {
            this.showProrductType = true;
            this.selectGoodsTypeInfoPC();
        },
        /**@method 获取商品分类列表 */
        async selectGoodsTypeInfoPC() {
            let params = {
                pageSize: this.prorductTypePageSize,
                pageNum: this.prorductTypeCurrentPage
            };
            try {
                this.prorductTypeLoading = true;
                let res = await selectGoodsTypeInfoPC(params)
                this.prorductTypeLoading = false;

                const { data } = res;
                this.prorductTypeTableData = data.pageInfo.list;
                this.prorductTypeTotal = data.pageInfo.total;
            } catch (error) {
                this.prorductTypeLoading = false;
                this.prorductTypeTableData = [];
            }
        },
        /**@method 商品分类切换行 */
        prorductTypeHandleSizeChange(val) {
            this.prorductTypePageSize = val;
            this.selectGoodsTypeInfoPC();
        },
        /**@method 商品分类切换下一页 */
        prorductTypeHandleCurrentChange(val) {
            this.prorductTypeCurrentPage = val;
            this.selectGoodsTypeInfoPC();
        },
        /**@method 修改显示条数 */
        changeDataNumber() {
            this.datas.autoData = this.productType
        },
        changeListCheckCard(isCard, index) {
            if (isCard) {
                this.datas.imageList[index].isLogin = true;
            }
        },
        changeCheckCard(isCard) {
            if (isCard) {
                this.datas.isLogin = true;
            }
        },
        /**@method 链接路径 */
        moreJumpLinkChange(data) {
            if (data.type) {
                let { urlType, type, id, title } = data;
                this.datas.moreHttp = {
                    urlType,
                    type,
                    id,
                    title
                }
            } else {
                this.datas.moreHttp = {};
            }
        },
        showUpload(type, index) {
            this.uploadImgDataType = type
            this.$refs.upload.showUpload()


        },
        uploadMultipleInformation(data) {
            for (let src of data) {
                this.datas.imageList.push({
                    src: src,
                    text: '',
                    isLogin: false,
                    http: {},
                })
            }
        },
        // 提交
        uploadInformation(res) {
            this.datas.bgImg = res
        },

        // 清空背景图片
        clear() {
            this.datas.bgImg = ''
        },
        /* 删除图片列表的图片 */
        deleteimg(index) {
            this.datas.imageList.splice(index, 1)
        },
    },
    components: { vuedraggable, uploadImg, commonTable },
}
</script>
  
<style scoped lang="scss">
.graphicnavigationstyle {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 10px 20px;
    box-sizing: border-box;

    .moditystyle {
        font-size: 12px;
        width: 100%;
        display: flex;

        span {
            width: 86px;
            height: 32px;
            display: inline-block;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            border: 1px solid #ebedf0;

            &.active {
                border: 1px solid #51CDCB;
                background-color: #51cdcb26;
                color: #51CDCB;
            }
        }

        .addMethod {
            width: 110px;
            height: 40px;
            line-height: 40px;
        }
    }

    .moditystyle2 {
        justify-content: center;
    }

    /* 卡片样式 */

    .moditystyle {
        font-size: 12px;
        width: 100%;
        display: flex;

        span {
            width: 86px;
            height: 32px;
            display: inline-block;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            border: 1px solid #ebedf0;

            &.active {
                border: 1px solid #51CDCB;
                background-color: #51cdcb26;
                color: #51CDCB;
            }
        }
    }

    /* 标题 */
    h2 {
        padding: 24px 16px 24px 0;
        margin-bottom: 15px;
        border-bottom: 1px solid #f2f4f6;
        font-size: 18px;
        font-weight: 600;
        color: #323233;
    }

    .lef {
        /deep/.el-form-item__label {
            text-align: left;
        }
    }

    /* 上传图片按钮 */
    .uploadImg {
        width: 345px;
        height: 40px;
        margin-top: 10px;
    }

    /* 商品列表 */
    .imgList {
        padding: 6px 12px;
        margin: 16px 7px;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
        display: flex;
        position: relative;

        /* 删除图标 */
        .el-icon-circle-close {
            position: absolute;
            right: -10px;
            top: -10px;
            cursor: pointer;
            font-size: 19px;
        }

        /* 图片 */
        .imag {
            width: 60px;
            height: 60px;
            border-radius: 5px;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                display: inline-block;
            }

            span {
                background: rgba(0, 0, 0, 0.5);
                font-size: 12px;
                position: absolute;
                left: 0px;
                bottom: 0px;
                display: inline-block;
                width: 100%;
                text-align: center;
                color: #fff;
                height: 20px;
                line-height: 20px;
            }
        }

        /* 图片字 */
        .imgText {
            width: 80%;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            padding-left: 20px;
            justify-content: space-between;

            .select-type {
                display: flex;

                /deep/.el-select {
                    .el-input {
                        input {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }

    /* 图片样式 */
    .weiz {
        text-align: right;

        i {
            padding: 5px 14px;
            margin-left: 10px;
            border-radius: 0;
            border: 1px solid #ebedf0;
            font-size: 20px;
            font-weight: 500;
            cursor: pointer;

            &:last-child {
                font-size: 22px;
            }

            &.active {
                color: #51CDCB;
                border: 1px solid #51CDCB;
                background: #51cdcb26;
            }
        }
    }

    .shop-head-pic {
        color: #ababab;
        display: flex;
        flex-direction: column;

        .home-bg {
            width: 100px;
            height: 100px;
            margin: 10px auto;
        }

        .shop-head-pic-btn {
            display: flex;
            flex-direction: row;

            .el-button {
                flex: 1;
            }
        }
    }

    /* 颜色选择器 */
    .picke {
        float: right;
    }
}
</style>
  