<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent mt10">
                <div class="wordTtile">
                    <div class="flex-c-c textc">
                        <div class="zdhq fs24">{{ checkedCompany.companyName }}</div>
                    </div>
                    <div class="textc pjTitle mt10 mb20 fs24">文&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;件</div>
                </div>
                <div class="flex-a-b-c">
                    <div class="flex-a-c">
                        <div class="fs30">
                            {{ checkedCompany.companyWord }}字
                        </div>
                        <span class="fs30">[{{ yearValue }}]</span>
                        <div class="fs30">{{ code }}号</div>
                    </div>
                    <div class="flex-a-c fs30">
                        <div>签发人：</div>
                        <div>
                            <el-input type="text" class="printName w150" placeholder="签发人"
                                v-model="formData.signName"></el-input>
                            <!-- {{ checkedCompany.legalName }} -->
                        </div>
                    </div>
                </div>
                <div class="textc fs30 mt50">
                    {{ title }}
                </div>
                <div class="textc fs14 mb50 mt30">{{ wordData.isAgain }}</div>
                <div class="wrodContent">
                    <div>
                        各部门：
                    </div>
                    <div class="textIndent40">
                        {{ content }}
                    </div>
                </div>
                <div class="text-align-right mt100 mb100 h40">{{ formData.fileDay | textTime }}</div>
                <div class="d-flex pb20 lineBottom pt100">
                    <div>主题词：</div>
                    <div class="ml20" v-for="(row, index) in keywordData" :key="index">{{ row.title }}</div>
                </div>
                <div class="d-flex pb20 pt20 lineBottom">
                    <div>抄送：</div>
                    <div class="ml20">各部门</div>
                </div>
                <div class="flex-a-b-c pt20">
                    <div class="d-flex">
                        <div>打印：</div>
                        <div class="ml20">
                            <el-input type="text" class="printName w150" placeholder="打印人"
                                v-model="formData.printName"></el-input>
                            <!-- {{ checkedCompany.printName }} -->
                        </div>
                    </div>
                    <div>{{ checkedCompany.companyName }}</div>
                    <div>
                        <el-date-picker v-model="formData.fileDay" class="dayInput w230" value-format="yyyy-MM-dd"
                            format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                        </el-date-picker>印发
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 1 页 共 1 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "fileInstitution",
    props: {
        title: {
            type: String,
            default: ""
        },
        code: {
            type: String,
            default: ""
        },
        content: {
            type: String,
            default: ""
        },
        contentTitle: {
            type: String,
            default: ""
        },
        keywordData: {
            type: Array,
            default: function () {
                return [];
            }
        },
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                fileDay: "",
                signName: "",
                printName: "",
            },
            checkedCompany: {},
        };
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData = {
                            fileDay: data.fileDay,
                            signName: data.signName,
                            printName: data.printName,
                        }
                    } else {
                        this.formData = {
                            fileDay: this.checkedCompany.fileDay,
                            signName: this.checkedCompany.legalName,
                            printName: this.checkedCompany.printName,
                        }
                    }
                }
            },
            deep: true,
            immediate: true
        },
        formData: {
            handler() {
                this.$emit("change", this.formData)
            },
            deep: true,
            immediate: true,
        }
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        },
        textTime(time) {
            if (time) {
                let dayArr = time.split("-");
                let fileDay = "";
                let dayObj = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
                let tObj = ["年", "月", "日"];
                for (let i in dayArr) {
                    let t = dayArr[i];
                    if (i == 0 || t < 10) {
                        for (let childKey of t) {
                            fileDay += dayObj[childKey];
                        }
                    } else {
                        if (t > 10) {
                            if(t>=20){
                                fileDay += dayObj[String(t).slice(0, 1)]; 
                            }
                            fileDay += "十";
                            if(String(t).slice(1)>0){
                                fileDay += dayObj[String(t).slice(1)];
                            }
                        } else {
                            fileDay += dayObj[t];
                        }
                    }
                    fileDay += tObj[i];
                }
                return fileDay;
            } else {
                return "";
            }
        },
        timeStr(time) {
            if (time) {
                let dayStr = time.replace("-", "年");
                dayStr = dayStr.replace("-", "月");
                return dayStr + "日";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 2px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemTitle {
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 25px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C; // #000;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C; // #000;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 26px !important;
        line-height: 25px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 25px !important;
        line-height: 25px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            div {
                color: red;
            }

            border-bottom: 4px solid red;
            padding-bottom: 20px;
            margin-bottom: 50px;
        }
    }
}
</style>