<template>
  <div>
    <div>
      <div class="headBg"
        :style="{ backgroundColor: pageSetup.titleBgColor, backgroundImage: 'url(' + pageSetup.titleBgImg + ')' }"></div>
      <div class="headerTop" :style="{
        color: pageSetup.titleColor,
        height: pageSetup.titleHeight + 'px',
      }">
        <!-- 左半部分 -->
        <!-- <div class="lef" v-show="pageSetup.isBack">
      <van-icon name="arrow-left" />
    </div> -->
        <div class="lef" v-show="pageSetup.isAddress">
          <i class="el-icon-location-outline"></i> <span>中国</span>
        </div>
        <!-- 标题 -->
        <div class="header-title" :style="{
          height: pageSetup.titleHeight + 'px',
          'line-height': pageSetup.titleHeight + 'px',
        }">
          {{ associationName }}
        </div>
        <!-- 右半部分 -->
        <!-- <div class="rig" v-show="pageSetup.isPerson">
      <span>个人中心</span>
    </div> -->
      </div>
    </div>
    <!-- <div class="commoditysearch">
      <div class="newThreeBox flex-a-b-c">
        <div class="iocnSort flex-c-c pa5"
          :style="{ backgroundColor: 'rgba(55, 84, 140, 0.9)', color: pageSetup.titleColor, 'border-radius': '3px' }">
          <span class="el-icon-sort" style="font-size: 23px;"></span>
        </div>
        <div class="flex-a-b-c" :style="{ backgroundColor: 'rgba(55, 84, 140, 0.9)', 'border-radius': '3px' }">
          <div class="searchPlaceholder" :style="{ borderRight: '1px solid '+pageSetup.titleColor, width: '180px', color: pageSetup.titleColor}">
            {{ ' 搜索动态/活动/企业/供求' }}</div>
          <div class="seeIcon flex-c-c pa5" :style="{ color: pageSetup.titleColor }">
            <span class="el-icon-search" style="font-size: 23px;"></span>
          </div>
        </div>
        <div class="flex-c-c pa5"
          :style="{ backgroundColor: 'rgba(55, 84, 140, 0.9)', color: '#fff', 'border-radius': '3px' }">
          <van-icon name="scan" size="23" :color="pageSetup.titleColor" />
        </div>
        <div class="flex-c-c pa5"
          :style="{ backgroundColor: 'rgba(55, 84, 140, 0.9)', color: pageSetup.titleColor, 'border-radius': '3px' }">
          <span class="el-icon-bell" style="font-size: 23px;"></span>
        </div>
      </div>
      <slot name="deles" />
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'associationHomeHeaderTop',
  props: {
    pageSetup: Object,
  },
  data() {
    return {
      associationName: ""
    }
  },
  computed: {
    watchAssociation() {
      return this.$store.state.checkedAssociation
    }
  },
  watch: {
    watchAssociation(row) {
      this.associationName = row.associationName;
    },
  },
  created() {
    let associationData = JSON.parse(sessionStorage.getItem('checkedAssociation'))
    this.associationName = associationData.associationName;
  }
}
</script>

<style lang="scss" scoped>
.headBg {
  height: 250px;
  width: 100%;
  background-size: 100% 100%;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  position: absolute;
  top: 20px;
  z-index: 1;
}

.headerTop {
  position: relative;
  margin-top: 20px;
  height: 35px;
  width: 100%;
  display: flex;
  padding: 0 5px;
  // padding-top: 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  background-color: transparent;

  /* 左边 */
  .lef {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-content: center;
    height: 20px;

    /* 图标 */
    i {
      font-size: 20px;
      margin-right: 5px
    }

    span {
      font-weight: 400;
      font-size: 14px;
    }
  }

  .header-title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 35px;
  }

  /* 右边 */
  .rig {
    // display: flex;
    // align-items: center;
    // height: 100%;
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);

    /* 搜索图标 */
    i {
      font-size: 18px;
      margin: 0 7px 5px;
    }

    /* 文字 */
    span {
      margin: 0 7px;
      font-size: 12px;
    }
  }
}

.commoditysearch {
  position: relative;
  z-index:1;
  padding:10px;
}

.newThreeBox {
  height: 33px;

  .searchPlaceholder {
    padding: 5px 10px;
    height: 31px;
    overflow: hidden;
    white-space: nowrap;
  }

  .iocnSort {
    transform: rotate(90deg)
  }
}
</style>
