<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>产学研合作管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D007</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent" style="padding-top: 350px;">
                <div class="wordTtile">
                    <div class="flex-c-c textc">
                        <div class="zdhq fs24">产学研合作管理办法</div>
                    </div>
                    <div class="textc pjTitle mt10 mb20 fs35">{{wordData.isAgain}}</div>
                </div>
            </div>
            <div class="wordFoot">
                <div class="textc fs30">
                    {{ checkedCompany.companyName }}
                </div>
                <div class="textc fs25">
                    <!-- <span class="fs30" v-if="!showSetTime">{{ formData.fileDay | textTime }}</span> -->
                    <el-date-picker v-model="formData.fileDay" class="dayInput w230" value-format="yyyy-MM-dd"
                        format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                    </el-date-picker>(自动转为中文)
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 1 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>产学研合作管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D007</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordTtile">
                    <div class="textc pjTitle mt50 mb10 fs30">{{ checkedCompany.companyName }}</div>
                    <div class="flex-c-c textc lineBottom ">
                        <div class="zdhq fs40">产学研合作管理办法</div>
                    </div>
                </div>
                <div class="lineheight45">
                    <div class="fw600 textc mb50">
                        第一章 总则
                    </div>
                    <div class="textIndent40">
                        <div>
                            <span class="fw600">第一条</span> 为加强公司与科研院校的产学研合作力度，提高公司自主创新能力，提升公司核心竞争力，结合公司实际，制定本管理办法。
                        </div>
                        <div>
                            <span class="fw600">第二条</span>
                            产学研合作，是指公司与科研机构、高等院校通过共同合作开发或直接采用科研机构、高等院校具有实用价值的科技成果，改进企业的技术、工艺和装备水平，并形成新产品、新工艺、新技术、新装备或新材料的技术创新活动
                        </div>
                        <div>
                            <span class="fw600">第三条</span> 产学研合作应当遵循自愿公平、诚实信用的原则，并依法或依照合同约定享受利益、承担风险。
                        </div>
                        <div>
                            <span class="fw600">第四条</span> 公司企业研发中心为产学研合作职能部门，负责全公司产学研合作的技术交流、组织管理、考核评价及协调工作。
                        </div>
                    </div>
                    <div class="fw600 textc mb50 mt50">
                        第二章 组织管理
                    </div>
                    <div class="textIndent40">
                        <div>
                            <span class="fw600">第五条</span>
                            公司企业研发中心要建立科技信息网络，搭建信息交流平台，紧密跟踪科研院所、高等院校的技术研究工作动态，及时与技术人员交流，建立技术联络通道。
                        </div>
                        <div>
                            <span class="fw600">第六条</span>
                            公司企业研发中心每年邀请相关科研院所、高等院校到公司进行现场调研，发现生产中存在的问题，确定技术课题，定期召开产学研合作单位技术交流会议。
                        </div>
                        <div>
                            <span class="fw600">第七条</span>
                            产学研合作的选题范围主要围绕大数据和移动应用相关方面的技术研发过程中的技术难题、企业研发中心的研究开发课题、行业共性技术难题、公司重大技术攻关课题等。
                        </div>
                        <div>
                            <span class="fw600">第八条</span> 产学研合作成立研发经济实体的，必须按《公司法》的相关规定运行。
                        </div>
                        <div>
                            <span class="fw600">第九条</span>
                            与科研院所、高等院校共建的研究课题组，每季度必须向公司研发中心汇报课题研发进展情况、经费使用情况及研究人员的工作情况等信息。
                        </div>
                        <div>
                            <span class="fw600">第十条</span> 产学研合作课题组成员可以采用在社会招聘、公司内部抽调等方式。
                        </div>
                        <div>
                            <span class="fw600">第十一条</span> 产学研合作机构人员管理，参照公司有关规定执行，课题组组长负责对课题组成员的管理与考核。
                        </div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 2 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>产学研合作管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D007</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt80">
                    <div class="fw600 textc mb50 mt50">
                        第三章 合作模式及工作程序
                    </div>
                    <div class="textIndent40">
                        <div>
                            <span class="fw600">第十二条</span> 根据公司技术创新体系及技术需求现状，产学研合作模式有以下几种方式：
                        </div>
                        <div>1、技术转让：科研院所、高等院校将所持有的技术成果按照合同约定转让给公司使用；</div>
                        <div>2、委托开发：公司根据研发项目或产业发展要求，委托高校或研究院所对新技术、新产品、新工艺和新材料进行专题研究，并将其研究成果按合同的约定转化应用;</div>
                        <div>
                            3、共建研发实体：（公司目前主要采用这种形式）利用科研院所、高等院校的基础理论扎实、研发能力强的技术优势，结合公司研发流程，具有产业化和资金优势，由双方技术人员共建研发实体，对某一课题进行联合研究开发；
                        </div>
                        <div>4、共建人才培养基地：由公司和高校科研单位共建人才培训基地，为企业可持续发展提供高素质人才，为高校提供实地培训的基地。</div>
                        <div>
                            <span class="fw600">第十三条</span> 产学研合作工作程序：
                        </div>
                        <div>1、公司研发中心提出产学研合作课题（项目）及意向合作单位；</div>
                        <div>2、公司研发中心组织有关部门对产学研合作课题、合作单位进行考察，报公司领导审定；</div>
                        <div>3、与产学研合作单位签定合作协议。协议内容包括：合作形式、合作双方权利、义务、资金预算、成果管理、薪酬等事项；</div>
                        <div>4、任用产学研合作课题组负责人、课题组成员，确定课题组工作地点等事宜；</div>
                        <div>5、资金注入，课题组开始工作。</div>
                    </div>
                    <div class="fw600 textc mb50 mt50">
                        第四章 成果管理
                    </div>
                    <div class="textIndent40">
                        <div>
                            <span class="fw600">第十四条</span> 公司产学研合作技术成果原则上归公司所有，课题组成员可以根据其贡献大小获得公司奖励。
                        </div>
                        <div>
                            <span class="fw600">第十五条</span> 公司产学研合作项目成果申报，由产学研课题组准备相关资料报公司研发中心统一组织申报工作。
                        </div>
                        <div>
                            <span class="fw600">第十六条</span> 产学研合作成果应用推广
                        </div>
                        <div>1、产学研合作取得的科技成果由研发中心组织在公司推广及应用。对具有重要推广价值的优秀科技成果，将优先列入公司科技成果推广计划予以支持，重大项目给予重点支持。</div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 3 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>产学研合作管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D007</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt50">
                    <div class="textIndent40">
                        <div>
                            2、科技成果的对外转让工作，必须以公司名义对外签订技术转让合同，严禁以课题组或个人名义对外签订科技成果转让合同或协议。
                        </div>
                        <div>
                            <span class="fw600">第十七条</span> 对产学研合作中作出突出贡献的个人除协议中应该给予的待遇外，可按照公司《绩效考核管理办法》对个人进行奖励。
                        </div>
                        <div>
                            <span class="fw600">第十八条</span> 对于双方合作开发项目所获得的经济效益，根据双方合同约定对利益分成。
                        </div>
                    </div>
                    <div class="fw600 textc">
                        第五章  资金管理
                    </div>
                    <div class="textIndent40">
                        <div>
                            <span class="fw600">第十九条</span> 产学研合作经费列入公司年度项目开发费计划。
                        </div>
                        <div>
                            <span class="fw600">第二十条</span> 研发中心根据国家、省、市政策要求，积极申请产学研合作项目资金支持，加大产学研资金的投入。
                        </div>
                        <div>
                            <span class="fw600">第二十一条</span> 组建产学研经济实体，以技术成果入股的，必须符合国家相关规定，技术入股比例不能超过总股本的35%。
                        </div>
                        <div>
                            <span class="fw600">第二十二条</span> 产学研合作机构资金的注入必须在签定合同后，由产学研合作课题组提出资金申请，经研发中心审核，公司总经理审批后，按合同要求拨付。
                        </div>
                        <div>
                            <span class="fw600">第二十三条</span> 研究室建立在科研院校的，其购置的固定资产，必须将资产明细报公司研发中心，课题结束时移交研发中心。
                        </div>
                        <div>
                            <span class="fw600">第二十四条</span> 产学研合作经费必须单独建帐，专款专用，年终及项目终止由公司财务部部门审核。
                        </div>
                    </div>
                    <div class="fw600 textc">
                        第六章 附 则
                    </div>
                    <div class="textIndent40">
                        <div>
                            <span class="fw600">第二十五条</span> 本办法由公司总经办负责解释。
                        </div>
                        <div>
                            <span class="fw600">第二十六条</span> 本办法修订之日起实行。
                        </div>
                    </div>
                    <div class="text-align-right mt50">{{ checkedCompany.companyName }}</div>
                    <div class="text-align-right">
                        <!-- <span v-if="!showSetTime">{{ formData.fileDay | textTime }}</span> -->
                        <el-date-picker v-model="formData.fileDay" class="miniDay w190" value-format="yyyy-MM-dd"
                            format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                        </el-date-picker>(自动转为中文)
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 4 页 共 4 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution9230",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                fileDay: "",
            },
            checkedCompany:{}
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        },
        textTime(time) {
            if (time) {
                let dayArr = time.split("-");
                let fileDay = "";
                let dayObj = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
                let tObj = ["年", "月", "日"];
                for (let i in dayArr) {
                    let t = dayArr[i];
                    if (i == 0 || t < 10) {
                        for (let childKey of t) {
                            fileDay += dayObj[childKey];
                        }
                    } else {
                        if (t > 10) {
                            if(t>=20){
                                fileDay += dayObj[String(t).slice(0, 1)]; 
                            }
                            fileDay += "十";
                            if(String(t).slice(1)>0){
                                fileDay += dayObj[String(t).slice(1)];
                            }
                        } else {
                            fileDay += dayObj[t];
                        }
                    }
                    fileDay += tObj[i];
                }
                return fileDay;
            } else {
                return "";
            }
        },
        timeStr(time) {
            if (time) {
                let dayStr = time.replace("-", "年");
                dayStr = dayStr.replace("-", "月");
                return dayStr + "日";
            }
        },
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany=data.companyData;
                    if(data.rulesId){
                        this.formData.fileDay = data.fileDay;
                    }else{
                        this.formData.fileDay = this.checkedCompany.fileDay;
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
};
</script>

<style lang="scss" scoped>
.frame {
    padding: 0 10px;
    border: 1px solid #000;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemTitle {
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 20px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>