<template>
  <div class="graphicnavigationstyle">
    <!-- 标题 -->
    <h2>{{ datas.text }}</h2>

    <!-- 提示 -->
    <p style="color: #969799; font-size: 12px; margin-top: 10px">
      拖动选中的导航可对其排序
    </p>

    <!-- 图片广告 -->
    <div v-if="datas.imageList[0]">
      <vuedraggable v-model="datas.imageList" v-bind="dragOptions">
        <transition-group>
          <section class="imgList" v-for="(item, index) in datas.imageList" :key="item + index">
            <i class="el-icon-circle-close" @click="deleteimg(index)" />
            <!-- 图片 -->
            <div class="imag">
              <img draggable="false" :src="item.src" alt="" @click="showUpload('2', index)" />
            </div>
            <!-- 标题和链接 -->
            <div class="imgText">
              <div class="mb5">
                验证登录 <el-checkbox v-model="item.isLogin" />
                验证名片 <el-checkbox v-model="item.isCard" @change="changeListCheckCard($event, index)" />
              </div>
              <el-input v-model="item.text" placeholder="请输入标题，也可不填" size="mini"></el-input>
              <crmJumpLink :linkData="item.http" @changeFun="jumpLinkChange($event, index)" />
            </div>
          </section>
        </transition-group>
      </vuedraggable>
    </div>

    <!-- 上传图片 -->
    <el-button @click="showUpload('0')" class="uploadImg" type="primary" plain><i class="el-icon-plus" />点击添加导航
    </el-button>

    <div class="bor"></div>

    <!-- 表单 -->
    <el-form label-width="80px" :model="datas" size="small">
      <!-- 是否显示更多 -->
      <el-form-item class="lef" label="导航标题">
        <div class="weiz flex-a-b-c">
          <div style="width: 196px">
            <el-input v-if="datas.showTitle" v-model="datas.title" placeholder="请输入标题"></el-input>
          </div>
          <el-checkbox v-model="datas.showTitle" />
        </div>
      </el-form-item>

      <div style="height: 10px" />

      <!-- 是否显示更多 -->
      <el-form-item class="lef" label="更多">
        <div class="weiz flex-a-b-c">
          <div style="width: 196px">
            <crmJumpLink :linkData="datas.moreHttp" @changeFun="moreJumpLinkChange($event)" />
          </div>
          <el-checkbox v-model="datas.showMore" />
        </div>
      </el-form-item>

      <el-form-item class="lef" label="更多验证">
        验证登录 <el-checkbox v-model="datas.isLogin" />
        验证名片 <el-checkbox v-model="datas.isCard" @change="changeCheckCard($event)" />
      </el-form-item>
      <div style="height: 10px" />

      <!-- 导航类型选择 -->
      <!-- <el-form-item class="lef" label="导航类型">
        <el-radio-group v-model="datas.navigationType">
          <el-radio style="margin-left: 35px" :label="index - 1" v-for="index in 2" :key="index">{{ index === 1 ? '图片导航'
            : '文字导航'
          }}</el-radio>
        </el-radio-group>
      </el-form-item> -->

      <div style="height: 10px" />
      <!-- 边框倒角 -->
      <el-form-item label="边框倒角" class="lef borrediu">
        <el-slider v-model="datas.borderRadius" :max="30" input-size="mini" show-input>
        </el-slider>
      </el-form-item>

      <div style="height: 10px" />
      <!-- 一行显示 -->
      <el-form-item label="一行显示" class="lef">
        <el-slider v-model="datas.rowCount" :max="5" :min="1" input-size="mini" show-input>
        </el-slider>
      </el-form-item>

      <div style="height: 10px" />

      <!-- 导航样式 -->
      <!-- <el-form-item class="lef" label="导航样式">
        <div class="weiz">
          <el-tooltip effect="dark" :content="index - 1 === 0 ? '固定' : '滑动'" placement="bottom" v-for="index in 2"
            :key="index">
            <i class="iconfont" :class="[
              index - 1 === 0 ? 'icon-guding' : 'icon-hengxianghuadong',
              datas.imgStyle === index - 1 ? 'active' : '',
            ]" @click="datas.imgStyle = index - 1" />
          </el-tooltip>
        </div>
      </el-form-item> -->

      <!-- <div style="height: 10px" /> -->

      <!-- 一屏显示 -->
      <el-form-item class="lef" label="一屏显示" v-show="datas.imgStyle === 1">
        <el-select v-model="datas.showSize" placeholder="请选择活动区域" style="margin-left: 90px">
          <el-option :label="index + 4 + '个导航'" :value="index + 4" v-for="index in 7" :key="index"></el-option>
        </el-select>
      </el-form-item>

      <div style="height: 10px" v-show="datas.imgStyle === 1" />

      <!-- 文字高度 -->
      <!-- <el-form-item label="文字高度" class="lef">
        <el-slider v-model="datas.textHeight" :max="50" :min="12" input-size="mini" show-input>
        </el-slider>
      </el-form-item>

      <div style="height: 10px" /> -->

      <!-- 文字大小 -->
      <!-- <el-form-item label="文字大小" prop="textSize" :hide-required-asterisk="true" class="lef">
        <el-input type="number" v-model.number="datas.textSize" placeholder="请输入文字大小" :maxlength="2" />
      </el-form-item>

      <div style="height: 10px" /> -->

      <!-- 图片大小 -->
      <el-form-item label="图片大小" class="lef borrediu">
        <el-slider v-model="datas.imgSize" :max="45" :min="10" input-size="mini" show-input>
        </el-slider>
      </el-form-item>

      <!-- <div style="height: 10px" /> -->
      <!-- 图片倒角 -->
      <!-- <el-form-item label="图片倒角" class="lef borrediu">
        <el-slider v-model="datas.iconRadius" :max="50" input-size="mini" show-input>
        </el-slider>
      </el-form-item>

      <div style="height: 10px" /> -->

      <!-- <el-form-item class="lef" label="背景图片">
        <div class="shop-head-pic" style="text-align: center">
          <img class="home-bg" :src="datas.bgImg" alt="" v-if="datas.bgImg" />
          <div class="shop-head-pic-btn" style="text-align: center">
            <el-button @click="showUpload('1')" class="uploadImg" type="primary" plain><i class="el-icon-plus" />更换图片
            </el-button>
            <el-button type="primary" class="uploadImg" @click="clear()">清空图片</el-button>
          </div>
        </div>
      </el-form-item>
      <div style="height: 10px" /> -->
      <div style="height: 10px" />
      <el-form-item label="边框阴影" class="lef">
        <div class="flex-a-b-c">
          {{ datas.isShadow ? '显示' : '隐藏' }}
          <el-checkbox v-model="datas.isShadow" />
        </div>
      </el-form-item>
      <!-- 背景颜色 -->
      <el-form-item class="lef" label="背景颜色">
        <el-color-picker v-model="datas.backgroundColor" show-alpha class="picke" :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>

      <div style="height: 10px" />

      <!-- 文字颜色 -->
      <el-form-item class="lef" label="文字颜色">
        <el-color-picker v-model="datas.textColor" show-alpha class="picke" :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>
    </el-form>

    <!-- 上传图片 -->
    <uploadMultipleImg ref="uploadMultipleImg" @uploadInformation="uploadMultipleInformation" />
    <uploadImg ref="upload" @uploadInformation="uploadInformation" />
    <uploadImg ref="imgUpload" @uploadInformation="setImgUrlFun" />
  </div>
</template>

<script>
import uploadImg from '../../../uploadImg' //图片上传
import uploadMultipleImg from '../../../uploadMultipleImg' //图片上传
import vuedraggable from 'vuedraggable' //拖拽组件

export default {
  name: 'crmGraphicnavigationstyle',
  props: {
    datas: Object,
  },
  data() {
    return {
      setImgIndex: 0,
      dragOptions: {
        //拖拽配置
        animation: 200,
      },
      predefineColors: [
        // 颜色选择器预设
        "#fff",
        "#000",
        "#3064ba",
        '#51CDCB',
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        '#409EFF',
        '#909399',
        '#C0C4CC',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577',
      ],
      emptyText: '',
      uploadImgDataType: null,
    }
  },
  created() {
  },
  methods: {
    changeListCheckCard(isCard, index) {
      if (isCard) {
        this.datas.imageList[index].isLogin = true;
      }
    },
    changeCheckCard(isCard) {
      if (isCard) {
        this.datas.isLogin = true;
      }
    },
    /**@method 链接路径 */
    moreJumpLinkChange(data) {
      if (data.type) {
        let { urlType, type, id, title } = data;
        this.datas.moreHttp = {
          urlType,
          type,
          id,
          title
        }
      } else {
        this.datas.moreHttp = {};
      }
    },
    /**@method 链接路径 */
    jumpLinkChange(data, index) {
      if (data.type) {
        let { urlType, type, id, title } = data;
        this.datas.imageList[index].http = {
          urlType,
          type,
          id,
          title
        }
      } else {
        this.datas.imageList[index].http = {};
      }
      this.$set(this.datas.imageList, index, this.datas.imageList[index])
    },
    showUpload(type, index) {
      this.uploadImgDataType = type
      if (type === '0') {
        this.$refs.uploadMultipleImg.showUpload()
      } else if (type === '1') {
        this.$refs.upload.showUpload()
      } else if (type === '2') {
        this.$refs.imgUpload.showUpload()
        this.setImgIndex = index;
      }

    },
    uploadMultipleInformation(data) {
      for (let src of data) {
        this.datas.imageList.push({
          src: src,
          text: '',
          isLogin: false,
          http: {},
        })
      }
    },
    setImgUrlFun(url) {
      this.datas.imageList[this.setImgIndex].src = url;
    },
    // 提交
    uploadInformation(res) {
      this.datas.bgImg = res
    },

    // 清空背景图片
    clear() {
      this.datas.bgImg = ''
    },
    /* 删除图片列表的图片 */
    deleteimg(index) {
      this.datas.imageList.splice(index, 1)
    },
  },
  components: { uploadMultipleImg, vuedraggable, uploadImg },
}
</script>

<style scoped lang="scss">
.graphicnavigationstyle {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 10px 20px;
  box-sizing: border-box;

  /* 标题 */
  h2 {
    padding: 24px 16px 24px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #f2f4f6;
    font-size: 18px;
    font-weight: 600;
    color: #323233;
  }

  .lef {
    /deep/.el-form-item__label {
      text-align: left;
    }
  }

  /* 上传图片按钮 */
  .uploadImg {
    width: 345px;
    height: 40px;
    margin-top: 10px;
  }

  /* 商品列表 */
  .imgList {
    padding: 6px 12px;
    margin: 16px 7px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    display: flex;
    position: relative;

    /* 删除图标 */
    .el-icon-circle-close {
      position: absolute;
      right: -10px;
      top: -10px;
      cursor: pointer;
      font-size: 19px;
    }

    /* 图片 */
    .imag {
      width: 60px;
      height: 60px;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        display: inline-block;
      }

      span {
        background: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        display: inline-block;
        width: 100%;
        text-align: center;
        color: #fff;
        height: 20px;
        line-height: 20px;
      }
    }

    /* 图片字 */
    .imgText {
      width: 80%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding-left: 20px;
      justify-content: space-between;

      .select-type {
        display: flex;

        /deep/.el-select {
          .el-input {
            input {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  /* 图片样式 */
  .weiz {
    text-align: right;

    i {
      padding: 5px 14px;
      margin-left: 10px;
      border-radius: 0;
      border: 1px solid #ebedf0;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;

      &:last-child {
        font-size: 22px;
      }

      &.active {
        color: #51CDCB;
        border: 1px solid #51CDCB;
        background: #51cdcb26;
      }
    }
  }

  .shop-head-pic {
    color: #ababab;
    display: flex;
    flex-direction: column;

    .home-bg {
      width: 100px;
      height: 100px;
      margin: 10px auto;
    }

    .shop-head-pic-btn {
      display: flex;
      flex-direction: row;

      .el-button {
        flex: 1;
      }
    }
  }

  /* 颜色选择器 */
  .picke {
    float: right;
  }
}
</style>
