<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="wordSetContent">
                <div class="modelHeader">
                    <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                        <div class="flex-a-c ">
                            <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.companyName }}</div>
                            <span class="ml20">管理文件>培训记录 V1.0</span>
                        </div>
                        <div class="flex-a-c">
                            <span>编号：</span>
                            <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.shortName }}</div>
                            <span>-HR-R004</span>
                        </div>
                    </div>
                    <div class="flex-a-b-c mb10">
                        <div class="squareLeft"></div>
                        <div class="squareRight"></div>
                    </div>
                </div>
                <el-image class="companyLogo" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
                <div class="pl30 pr30 wordSetContent">
                    <div class="textc pjTitle mt10 mb20 fs30"><el-date-picker v-model="formData.year"
                            class="dayInput w100" value-format="yyyy" format="yyyy" type="year" placeholder="年份">
                        </el-date-picker>年度个人培训需求调查表</div>
                    <table border="1" cellspacing="0" class="tableBorder institutionTable">
                        <thead>
                            <tr class="h60 fwbold">
                                <td class="textc itemTitle w80 fs9">序号</td>
                                <td class="textc itemTitle w300 fs9">培训课程名称</td>
                                <td class="textc itemTitle fs9">培训对象</td>
                                <td class="textc itemTitle w150 fs9">培训方式</td>
                                <td class="textc itemTitle fs9 w150">培训时间</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="h60" v-for="(row, index) in formData.content" :key="index">
                                <td class="textc itemContent">{{ row.num }}</td>
                                <td class="itemContent">
                                    <el-input v-model="row.content" class="wordInput" placeholder="培训内容或目的"></el-input>
                                </td>
                                <td class="textc itemContent">
                                    <el-input v-model="row.user" class="wordInput" placeholder="培训对象"></el-input>
                                </td>
                                <td class="itemContent">
                                    <el-input v-model="row.way" class="wordInput" placeholder="培训方式"></el-input>
                                </td>
                                <td class="textc itemContent">
                                    <el-date-picker v-model="row.time" class="miniDay w170" value-format="yyyy-MM"
                                        format="yyyy年MM月" type="month" placeholder="选择日期">
                                    </el-date-picker>
                                    <div class="tool d-flex">
                                        <div>
                                            <i class="eicon el-icon-caret-top" @click="topData(index)" title="上移"></i>
                                            <i class="eicon el-icon-caret-bottom" @click="bottomData(index)"
                                                title="下移"></i>
                                        </div>
                                        <div>
                                            <i class="eicon el-icon-plus" @click="addData()" title="添加"></i>
                                            <i class="eicon el-icon-delete" @click="delData(index)" title="删除"></i>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="mt30 fs9">注：</div>
                    <div class="fs9">1、培训内容或目的或不能具体，可以笼统填写</div>
                    <div class="fs9">2、培训对象：填写需求本人姓名</div>
                    <div class="fs9">3、培训方式：外部培训或内部培训</div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div></div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution94222",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                year:"",
                content: [{
                    num: 1,
                    content: "",
                    user: "",
                    way: "内部培训",
                    time: ""
                }]
            },
            showAddData: false,
            visible: true,
            checkedCompany:{},
        };
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData.year = data.year ? String(data.year) : "";
                        this.formData.content = data.content;
                    } else {
                        this.formData = {
                            year: this.yearValue,
                            content: [{
                                num: 1,
                                content: "",
                                user: "",
                                way: "内部培训",
                                time: this.checkedCompany.fileDay ? this.checkedCompany.fileDay.slice(0, 7) : ""
                            }]
                        }
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    created() {

    },
    methods: {
        /**@method 添加 */
        addData() {
            this.formData.content.push({
                content: "",
                user: "",
                way: "内部培训",
                time: this.checkedCompany.fileDay ? this.checkedCompany.fileDay.slice(0, 7) : ""
            });
            this.getTableNum();

        },
        getTableNum() {
            for (let i in this.formData.content) {
                this.$set(this.formData.content[i], "num", Number(i) + 1);
            }
        },
        delData(i) {
            if (this.formData.content.length > 1) {
                this.formData.content.splice(i, 1)
                this.getTableNum();
            }
        },
        bottomData(i) {
            if (this.formData.content.length != (i + 1)) {
                let bottomData = JSON.parse(JSON.stringify(this.formData.content[i + 1]));
                let currentData = JSON.parse(JSON.stringify(this.formData.content[i]));
                // this.formData.content[i] = bottomData;
                // this.formData.content[i + 1] = currentData;
                this.$set(this.formData.content, i, bottomData);
                this.$set(this.formData.content, i + 1, currentData);
                this.getTableNum();
            }
        },
        topData(i) {
            if (i > 0) {
                let topData = JSON.parse(JSON.stringify(this.formData.content[i - 1]));
                let currentData = JSON.parse(JSON.stringify(this.formData.content[i]));
                // this.formData.content[i] = topData;
                // this.formData.content[i - 1] = currentData;
                this.$set(this.formData.content, i, topData);
                this.$set(this.formData.content, i - 1, currentData);
                this.getTableNum();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.institutionTable {
    border-color: #000;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }

    .tool {
        display: block;
        position: absolute;
        top: 50%;
        right: -51px;
        width: 50px;
        height: 50px;
        margin-top: -25px;
        background: #f1f2f4;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

        .eicon {
            font-size: 18px;
            color: #666;
            cursor: pointer;
            height: 25px;
            width: 25px;
            text-align: center;
            line-height: 25px;
        }

        .el-icon-delete {
            color: #F56C6C;
        }
    }

    .itemTitle {
        height: 100%;
        font-size: 15px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 15px;
        position: relative;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 20px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 20px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 20px !important;
                line-height: 20px;
            }
        }
    }
}
</style>