<template>
  <div>
    <el-select style="width:100%" v-model="checkedData.type" placeholder="跳转类型" clearable @change="changeType()">
      <el-option label="详情链接" value="detalisLink">
      </el-option>
      <el-option label="首页链接" value="webLink">
      </el-option>
      <el-option label="动态分类链接" value="typeLink">
      </el-option>
      <el-option label="个人中心链接" value="myLink">
      </el-option>
      <el-option label="进驻分类链接" value="businessType">
      </el-option>
      <el-option label="自定义链接" value="custom">
      </el-option>
    </el-select>
    <input v-model="checkedData.title" :disabled="disabled" class="yqh-input" style="width:100%" placeholder="请先选择跳转类型"
      type="text" readonly v-if="!checkedData.type">
    <div class="flex-a-c" v-else-if="checkedData.type === 'detalisLink'">
      <el-select :disabled="disabled" style="width:40%" v-model="checkedData.urlType" placeholder="文章类型" clearable
        @change="changeUrlType()">
        <el-option v-for="(row, index) in photoUrl" :key="index" :label="row.dictLabel" :value="row.dictValue">
        </el-option>
      </el-select>
      <input v-model="checkedData.title" :disabled="disabled" class="yqh-input" style="width:60%" placeholder="请先选择文章类型"
        type="text" readonly v-if="!checkedData.urlType">
      <input v-model="checkedData.title" :disabled="disabled" class="yqh-input" style="width:60%" placeholder="点击选择商品"
        type="text" readonly v-if="checkedData.urlType == 2" @click="showProductFun()">
      <input v-model="checkedData.title" :disabled="disabled" class="yqh-input" style="width:60%" placeholder="点击选择活动"
        type="text" readonly v-if="checkedData.urlType == 1" @click="showActivityFun()">
      <input v-model="checkedData.title" :disabled="disabled" class="yqh-input" style="width:60%" placeholder="点击选择文章"
        type="text" readonly v-if="checkedData.urlType == 3" @click="showArticleFun()">
    </div>
    <div class="flex-a-c" v-else-if="checkedData.type === 'custom'">
      <input v-model="checkedData.id" class="yqh-input" style="width:100%" placeholder="自定义路径" type="text"
        @change="changeVal()">
    </div>
    <input v-model="checkedData.title" :disabled="disabled" class="yqh-input" style="width:100%" placeholder="点击选择进驻分类"
      type="text" readonly v-if="checkedData.type == 'businessType'" @click="showShopTypeFun()">
    <el-select :disabled="disabled" v-else-if="checkedData.type === 'webLink'" style="width:100%" filterable
      v-model="checkedData.id" placeholder="点击选择跳转路径" clearable @change="changeVal()">
      <el-option v-for="(row, index) in webUrl" :key="index" :label="row.dictLabel" :value="row.dictValue">
        <span style="float: left">{{ row.dictLabel }}</span>
        <span style="float: right; color: #8492a6; font-size: 12px">{{ row.dictValue }}</span>
      </el-option>
    </el-select>
    <el-select :disabled="disabled" v-else-if="checkedData.type === 'myLink'" style="width:100%" filterable
      v-model="checkedData.id" placeholder="点击选择跳转路径" clearable @change="changeVal()">
      <el-option v-for="(row, index) in myWebUrl" :key="index" :label="row.dictLabel" :value="row.dictValue">
        <span style="float: left">{{ row.dictLabel }}</span>
        <span style="float: right; color: #8492a6; font-size: 12px">{{ row.dictValue }}</span>
      </el-option>
    </el-select>
    <el-select :disabled="disabled" v-else-if="checkedData.type === 'typeLink'" style="width:100%" filterable
      v-model="checkedData.id" placeholder="点击选择跳转路径" clearable @change="changeVal()">
      <el-option v-for="(row, index) in typeData" :key="index" :label="row.title" :value="row.url">
      </el-option>
    </el-select>
    <!-- <el-input v-else-if="checkedData.type === 'appletLink'" v-model="checkedData.id" placeholder="输入appId跳转小程序"></el-input> -->
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择商品" :visible.sync="showProduct"
      :destroy-on-close="true" width="80%" center>
      <commonTable :tableData="productTableData" class="tableRadio" :loading="productLoading"
        @handleSizeChange="productHandleSizeChange" @handleCurrentChange="productHandleCurrentChange"
        @handleClickRow="selectProduct" :currentPage="productCurrentPage" :total="productTotal">
        <template v-slot:table>
          <el-table-column prop="goodPhoto" align="center" label="商品图片">
            <template slot-scope="scope">
              <el-image v-if="scope.row.goodPhoto" style="width: 100px; height: 80px;margin-bottom:-10px"
                :src="scope.row.goodPhoto.split(',')[0]" :preview-src-list="scope.row.goodPhoto.split(',')">
              </el-image>
              <p v-else style="width: 100px; height: 80px;line-height: 80px;">
                暂无图片
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="goodsName" align="center" label="商品名称" show-overflow-tooltip />
          <el-table-column prop="price" align="center" label="价格" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.price | getPrice }}
            </template>
          </el-table-column>
          <el-table-column prop="stockNum" align="center" label="库存" show-overflow-tooltip />
        </template>
      </commonTable>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择活动" :visible.sync="showActivity"
      :destroy-on-close="true" width="80%" center>
      <commonTable :tableData="activityTableData" class="tableRadio" :loading="activityLoading"
        @handleSizeChange="activityHandleSizeChange" @handleCurrentChange="activityHandleCurrentChange"
        @handleClickRow="selectActivity" :currentPage="activityCurrentPage" :total="activityTotal">
        <template v-slot:table>
          <el-table-column prop="photos" align="center" label="活动图片">
            <template slot-scope="scope">
              <el-image v-if="scope.row.photos" style="width: 100px; height: 80px;margin-bottom:-10px"
                :src="scope.row.photos"></el-image>
              <p v-else style="width: 100px; height: 80px;line-height: 80px;">
                暂无图片
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="name" align="center" width="200" label="活动名称" show-overflow-tooltip />
          <el-table-column prop="description" width="250" show-overflow-tooltip align="center" label="活动概述" />
          <el-table-column prop="startTime" width="250" show-overflow-tooltip align="center" label="活动时间周期">
            <template slot-scope="scope">
              {{ scope.row.startTime + ' ~ ' + scope.row.endTime }}
            </template>
          </el-table-column>
          <el-table-column prop="phone" align="center" label="活动状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status == 1" type="info">未开始</el-tag>
              <el-tag v-else-if="scope.row.status == 2">进行中</el-tag>
              <el-tag v-else type="danger">已结束</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="userNum" align="center" label="报名人数限制" width="100">
            <template slot-scope="scope">
              {{ scope.row.userNum == 0 ? '不限制' : scope.row.userNum }}
            </template>
          </el-table-column>
        </template>
      </commonTable>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择文章资讯" :visible.sync="showArticle"
      :destroy-on-close="true" width="80%" center>
      <commonTable :tableData="articleTableData" class="tableRadio" :loading="articleLoading"
        @handleSizeChange="articleHandleSizeChange" @handleCurrentChange="articleHandleCurrentChange"
        @handleClickRow="selectArticle" :currentPage="articleCurrentPage" :total="articleTotal">
        <template v-slot:table>
          <el-table-column prop="photos" align="center" label="封面图" width="120px" required>
            <template slot-scope="scope">
              <el-image v-if="scope.row.photos" style="width: 100px; height: 100px;margin-bottom:-10px"
                :src="scope.row.photos">
              </el-image>
              <p v-else
                style="width: 100px; height: 100px;line-height: 100px;font-size:12px;margin:0 auto;background: #eee;">
                暂无图片
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="title" align="center" label="标题" show-overflow-tooltip />
          <el-table-column prop="typeName" align="center" label="动态类型" show-overflow-tooltip />
        </template>
      </commonTable>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择进驻类型" :visible.sync="showShopType"
      :destroy-on-close="true" width="80%" center>
      <el-tabs v-model="typeUse" @tab-click="getShopTypeList">
        <el-tab-pane label="企服商家" name="0"></el-tab-pane>
        <el-tab-pane label="合作商家" name="1"></el-tab-pane>
      </el-tabs>
      <commonTable :tableData="shopTypeLinkList" class="tableRadio" @handleClickRow="selectShopType"
        :loading="shopTypeLoading" :paginationVisible="false">
        <template v-slot:table>
          <!-- <el-table-column width="55">
            <template slot-scope="scope">
              <el-radio class="ml10" v-model="shopTypeCheckedData.id" :label="scope.row.linkId"
                @change="selectShopType(scope.row)">
                {{ ' ' }}
              </el-radio>
            </template>
    </el-table-column> -->
          <el-table-column prop="iconUrl" label="分类图标" align="center" width="150">
            <template slot-scope="scope">
              <img :src="scope.row.iconUrl" width="50px" height="50px" alt="">
            </template>
          </el-table-column>
          <el-table-column prop="typeName" align="center" label="分类名称" show-overflow-tooltip />
        </template>
      </commonTable>
    </el-dialog>
  </div>
</template>

<script>
import {
  getGoodsListByLink,
  getParkMessageList,
  activityEventList,
  getParkShopTypeLinkList
} from "@/api/appModel";
import commonTable from "@/components/common/commonTable";
export default {
  name: "parkJumpLink",
  components: { commonTable },
  props: ['linkData', 'disabled'],
  data() {
    return {
      photoUrl: [],
      webUrl: [],
      myWebUrl: [],
      typeData: [],
      checkedData: {
        type: "", //detalisLink 详情跳转 webLink 首页跳转 typeLink 动态分类链接 myLink 个人中心链接 businessType 进驻类型
        urlType: "", //1 活动 2 商品 3 文章
        title: "",
        id: ""
      },
      //显示商品配置
      productCheckedData: {
        title: "",
        id: 0
      },
      productTableData: [],
      showProduct: false,//模态框显示状态
      productCurrentPage: 1, //当前页
      productPageSize: 10, //显示条数
      productLoading: false, //表格加载
      productTotal: 0, //总条数
      //活动列表配置
      activityCheckedData: {
        title: "",
        id: 0
      },
      activityTableData: [],
      showActivity: false,//模态框显示状态
      activityCurrentPage: 1, //当前页
      activityPageSize: 10, //显示条数
      activityLoading: false, //表格加载
      activityTotal: 0, //总条数
      //文章列表配置
      articleCheckedData: {
        title: "",
        id: 0
      },
      articleTableData: [],
      showArticle: false,//模态框显示状态
      articleCurrentPage: 1, //当前页
      articlePageSize: 10, //显示条数
      articleLoading: false, //表格加载
      articleTotal: 0, //总条数
      parkId: 0,
      //进驻分类
      shopTypeLinkList: [],
      shopTypeLoading: false,
      showShopType: false,
      shopTypeCheckedData: {
        title: "",
        id: 0
      },
      typeUse: "0"
    }
  },
  computed: {
    watchPark() {
      return this.$store.state.checkedPark
    },
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  watch: {
    linkData: {
      handler(data) {
        if (data) {
          let {
            urlType,
            title,
            id,
            type
          } = data
          this.checkedData = {
            urlType: urlType ? urlType : "",
            title: title ? title : "",
            id: id ? id : "",
            type: type ? type : ""
          }
        }
      },
      deep: true,
      immediate: true
    },
    watchPark(row) {
      this.parkId = row.parkId;
      this.productCurrentPage = 1;
      this.activityCurrentPage = 1;
      this.articleCurrentPage = 1;
    }
  },
  created() {
    this.parkId = JSON.parse(sessionStorage.getItem('checkedPark')).parkId;
    this.getByDictType();//获取页面链接
    this.getContentTypeList();//获取园区动态分类
  },
  methods: {
    /**@method 获取园区动态分类*/
    getContentTypeList() {
      this.typeData = this.$store.state.parkDrag.dynamicType;
    },
    /**@method 获取字典数据 */
    getByDictType() {
     //console.log("获取字典数据");
      this.photoUrl = this.$store.state.parkDrag.photoUrl;
      this.webUrl = this.$store.state.parkDrag.webUrl;
      this.myWebUrl = this.$store.state.parkDrag.myWebUrl;
    },
    changeVal() {
      this.$emit("changeFun", this.checkedData)
    },
    changeType() {
      this.$set(this.checkedData, "urlType", "")
      this.changeUrlType();
    },
    /**@method 选择类型时清空选中内容 */
    changeUrlType() {
      this.productCheckedData = {
        title: "",
        id: 0
      };
      this.activityCheckedData = {
        title: "",
        id: 0
      };
      this.articleCheckedData = {
        title: "",
        id: 0
      };
      this.shopTypeCheckedData = {
        title: "",
        id: 0
      };
      this.$set(this.checkedData, "id", "")
      this.$set(this.checkedData, "title", "")
      this.$emit("changeFun", this.checkedData)
    },
    /**文章配置 */
    /**@method 选中文章 */
    selectArticle(data) {
      // this.articleCheckedData.title = data.title;
      // this.articleCheckedData.id = data.messageId;
      this.$set(this.checkedData, "id", data.messageId)
      this.$set(this.checkedData, "title", data.title)
      this.showArticle = false;
      this.$emit("changeFun", this.checkedData)
    },
    /**@method 显示文章列表 */
    showArticleFun() {
      this.showArticle = true;
      this.getArticleList();
    },
    /**@method 文章获取列表 */
    async getArticleList() {
      let params = {
        pageSize: this.articlePageSize,
        pageNum: this.articleCurrentPage,
        parkId: this.parkId,
        isPage: 1
      };
      try {
        this.articleLoading = true;
        let res = await getParkMessageList(params)
        this.articleLoading = false;

        const { data } = res;
        this.articleTableData = data.list;
        this.articleTotal = data.total;
      } catch (error) {
        this.articleLoading = false;
        this.articleTableData = [];
      }
    },
    /**@method 文章切换行 */
    articleHandleSizeChange(val) {
      this.articlePageSize = val;
      this.getArticleList();
    },
    /**@method 文章切换下一页 */
    articleHandleCurrentChange(val) {
      this.articleCurrentPage = val;
      this.getArticleList();
    },
    /**文章配置 */
    /**进驻商家配置 */
    showShopTypeFun() {
      this.showShopType = true;
      this.getShopTypeList();
    },
    selectShopType(data) {
      // this.shopTypeCheckedData.title = data.typeName;
      // this.shopTypeCheckedData.id = data.linkId;
      if (data.typeUse == "1") {
        this.$set(this.checkedData, "id", "/pagesA/merchants/merchantsList?title=" + data.typeName + "&typeLinkId=" + data.linkId)
      } else {
        this.$set(this.checkedData, "id", "/pagesA/serviceHall/serviceHallDetail?title=" + data.typeName + "&typeId=" + data.typeId + "&parkId=" + this.parkId)
      }
      this.$set(this.checkedData, "title", data.typeName)
      this.showShopType = false;
      this.$emit("changeFun", this.checkedData)
    },
    async getShopTypeList() {
      this.shopTypeLoading = true;
      let res = await getParkShopTypeLinkList({
        parkId: this.parkId,
        typeUse: this.typeUse,
        status: 0
      });
      try {
        this.shopTypeLinkList = res.data;
      } catch (err) {
        this.shopTypeLinkList = [];
      }
      this.shopTypeLoading = false;
    },
    /**活动配置 */
    /**@method 选中活动 */
    selectActivity(data) {
      // this.activityCheckedData.title = data.name;
      // this.activityCheckedData.id = data.aeId;
      this.$set(this.checkedData, "id", data.aeId)
      this.$set(this.checkedData, "title", data.name)
      this.showActivity = false;
      this.$emit("changeFun", this.checkedData)
    },
    /**@method 显示活动列表 */
    showActivityFun() {
      this.showActivity = true;
      this.getActivityList();
    },
    /**@method 活动获取列表 */
    async getActivityList() {
      let params = {
        pageSize: this.activityPageSize,
        pageNum: this.activityCurrentPage,
        parkId: this.parkId
      };
      try {
        this.activityLoading = true;
        let res = await activityEventList(params)
        this.activityLoading = false;

        const { data } = res;
        this.activityTableData = data.pageInfo.list;
        this.activityTotal = data.pageInfo.total;
      } catch (error) {
        this.activityLoading = false;
        this.activityTableData = [];
      }
    },
    /**@method 活动切换行 */
    activityHandleSizeChange(val) {
      this.activityPageSize = val;
      this.getActivityList();
    },
    /**@method 活动切换下一页 */
    activityHandleCurrentChange(val) {
      this.activityCurrentPage = val;
      this.getActivityList();
    },
    /**活动配置 */
    /**商品配置 */
    /**@method 选中商品 */
    selectProduct(data) {
      // this.productCheckedData.title = data.goodsName;
      // this.productCheckedData.id = data.goodsId;
      this.$set(this.checkedData, "id", data.goodsId)
      this.$set(this.checkedData, "title", data.goodsName)
      this.showProduct = false;
      this.$emit("changeFun", this.checkedData)
    },
    /**@method 显示商品列表 */
    showProductFun() {
      this.showProduct = true;
      this.getProductList();
    },
    /**@method 商品获取列表 */
    async getProductList() {
      let params = {
        pageSize: this.productPageSize,
        pageNum: this.productCurrentPage,
        accountType: 9,//园区商品
        parkId: this.parkId
      };
      try {
        this.productLoading = true;
        let res = await getGoodsListByLink(params)
        this.productLoading = false;

        const { data } = res;
        this.productTableData = data.pageInfo.list;
        this.productTotal = data.pageInfo.total;
      } catch (error) {
        this.productLoading = false;
        this.productTableData = [];
      }
    },
    /**@method 商品切换行 */
    productHandleSizeChange(val) {
      this.productPageSize = val;
      this.getProductList();
    },
    /**@method 商品切换下一页 */
    productHandleCurrentChange(val) {
      this.productCurrentPage = val;
      this.getProductList();
    },
  }
}
</script>

<style lang="scss" scoped>
.yqh-input {
  padding: 5px 15px;
  border-radius: 4px;
  height: 32px;
  border: 1px solid #DCDFE6;
  cursor: pointer;
}
</style>