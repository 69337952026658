<template>
  <div class="magiccube" :style="{
    'padding': datas.pageMargin + 'px',
  }">
    <div v-if="datas.addMethod == 'manual'">
      <!-- <img draggable="false" v-if="!showimageList1" src="../../../images/mor.png" alt="" style="width: 100%" /> -->

      <!-- 一行二个 -->
      <section class="buju buju0" v-if="datas.rubiksCubeType === 0">
        <div v-for="index in 2" :key="index" class="rubiksCubeType0 rubiksCubeType"
        :style="{ marginRight: index < 2 ? datas.imgMargin + 'px' : '0px' }">
          <img v-if="datas.imageList[index - 1]" :style="{ height: datas.imgHeight + 'px' }" draggable="false"
            :src="datas.imageList[index - 1].src" alt="" />
          <div class="nullBor flex-c-c" :style="{ height: datas.imgHeight + 'px' }" v-if="datas.imageList.length == 0"><i
              class="el-icon-picture-outline"></i></div>
        </div>
      </section>

      <!-- 一行三个 -->
      <section class="buju buju0" v-if="datas.rubiksCubeType === 1">
        <div v-for="index in 3" :key="index" class="rubiksCubeType1 rubiksCubeType" :style="{
          marginRight: index < 3 ? datas.imgMargin + 'px' : '0px',
          width: 33.33 + '%'
        }">
          <img v-if="datas.imageList[index - 1]" :style="{ height: datas.imgHeight + 'px' }" draggable="false"
            :src="datas.imageList[index - 1].src" alt="" />
          <div class="nullBor flex-c-c" :style="{ height: datas.imgHeight + 'px' }" v-if="datas.imageList.length == 0"><i
              class="
                          el-icon-picture-outline"></i></div>
        </div>
      </section>

      <!-- 一行四个 -->
      <section class="buju buju0" v-if="datas.rubiksCubeType === 2">
        <div v-for="index in 4" :key="index" class="rubiksCubeType2 rubiksCubeType" :style="{
          marginRight: index < 4 ? datas.imgMargin + 'px' : '0px',
          width: 25 - datas.imgMargin / 10 + '%'
        }">
          <img v-if="datas.imageList[index - 1]" :style="{ height: datas.imgHeight + 'px' }" draggable="false"
            :src="datas.imageList[index - 1].src" alt="" />
          <div class="nullBor flex-c-c" :style="{ height: datas.imgHeight + 'px' }" v-if="datas.imageList.length == 0"><i
              class="
                          el-icon-picture-outline"></i></div>
        </div>
      </section>

      <!-- 二左二右 -->
      <section class="buju buju0 flex-wrap-y" v-if="datas.rubiksCubeType === 3">
        <div v-for="index in 4" :key="index" class="rubiksCubeType3 rubiksCubeType"
          :style="{ height: datas.imgHeight + 'px' }">
          <img v-if="datas.imageList[index - 1]" draggable="false" :src="datas.imageList[index - 1].src" alt=""
            :style="{ padding: (datas.imgMargin / 2) + 'px', height: datas.imgHeight + 'px' }" />
          <div v-if="datas.imageList.length == 0" class="imgBor"
            :style="{ padding: (datas.imgMargin / 2) + 'px', width: '100%', height: datas.imgHeight + 'px' }">
            <div class="nullBor flex-c-c" :style="{ height: '100%', overflow: 'hidden' }"><i class="
                          el-icon-picture-outline"></i></div>
          </div>
        </div>
      </section>

      <!-- 一左二右 -->
      <section class="buju buju4" v-if="datas.rubiksCubeType === 4">
        <div class="rubiksCubeType hw">
          <img draggable="false" v-if="datas.imageList[0]" :src="datas.imageList[0].src" alt="" style="height:300px"
            :style="{ 'padding-right': (datas.imgMargin / 2) + 'px' }" />
          <div v-if="datas.imageList.length == 0" class="imgBor"
            :style="{ 'padding-right': (datas.imgMargin / 2) + 'px', width: '100%', height: '300px' }">
            <div class="nullBor flex-c-c" :style="{ height: '100%', overflow: 'hidden' }"><i class="
                          el-icon-picture-outline"></i></div>
          </div>
        </div>
        <div style="display: inline-flex; flex-direction: column; width: 100%">
          <div class=" hw imgone" v-for="index in 2" :key="index" style="height:150px">
            <img draggable="false" v-if="datas.imageList[index]" :src="datas.imageList[index].src" alt=""
              style="height:150px" :style="{ 'padding': (datas.imgMargin / 2) + 'px' }" />
            <div v-if="datas.imageList.length == 0" class="imgBor"
              :style="{ 'padding': (datas.imgMargin / 2) + 'px', width: '100%', height: '150px' }">
              <div class="nullBor flex-c-c" :style="{ height: '100%', overflow: 'hidden' }"><i class="
                          el-icon-picture-outline"></i></div>
            </div>
          </div>
        </div>
      </section>

      <!-- 一上二下 -->
      <section class="buju buju5" v-if="datas.rubiksCubeType === 5">
        <div class="rubiksCubeType hw" style="display: block;height:150px ">
          <img draggable="false" v-if="datas.imageList[0]" :src="datas.imageList[0].src" alt=""
            :style="{ 'padding-bottom': (datas.imgMargin / 2) + 'px' }" />
          <div v-if="datas.imageList.length == 0" class="imgBor"
            :style="{ 'padding-bottom': (datas.imgMargin / 2) + 'px', width: '100%', height: '150px' }">
            <div class="nullBor flex-c-c" :style="{ height: '100%', overflow: 'hidden' }"><i class="
                          el-icon-picture-outline"></i></div>
          </div>
        </div>
        <div style="display: flex; width: 100%">
          <div class="rubiksCubeType hw imgtow" v-for="index in 2" :key="index" style="height:150px">
            <img draggable="false" v-if="datas.imageList[index]" :src="datas.imageList[index].src" alt=""
              :style="{ 'padding': (datas.imgMargin / 2) + 'px' }" />
            <div v-if="datas.imageList.length == 0" class="imgBor"
              :style="{ 'padding': (datas.imgMargin / 2) + 'px', width: '100%', height: '150px' }">
              <div class="nullBor flex-c-c" :style="{ height: '100%', overflow: 'hidden' }"><i class="
                          el-icon-picture-outline"></i></div>
            </div>
          </div>
        </div>
      </section>

      <!-- 一左三右 -->
      <section class="buju buju4" v-if="datas.rubiksCubeType === 6">
        <!-- 第一张图片 -->
        <div class="rubiksCubeType hw" style="padding-top: 100%">
          <img draggable="false" v-if="datas.imageList[0]" :src="datas.imageList[0].src" alt="" style="height:300px"
            :style="{ 'padding-right': (datas.imgMargin / 2) + 'px' }" />
        </div>
        <div style="display: inline-flex; flex-direction: column; width: 100%">
          <!-- 第二张图片 -->
          <div class="rubiksCubeType hw" style="padding-top: 150px">
            <img draggable="false" v-if="datas.imageList[1]" :src="datas.imageList[1].src" alt="" :style="{
              'padding-bottom': (datas.imgMargin / 2) + 'px',
              'padding-left': (datas.imgMargin / 2) + 'px',
            }" />
          </div>
          <div class="rubiksCubeType">
            <div class="hw" style="
                                                display: inline-flex;
                                                justify-content: center;
                                                align-items: center;
                                                padding-top: 150px;
                                              " v-for="index in 2" :key="index">
              <img draggable="false" v-if="datas.imageList[index + 1]" :src="datas.imageList[index + 1].src" alt=""
                style="height:150px" :style="{
                  'padding-left': (datas.imgMargin / 2) + 'px',
                  'padding-top': (datas.imgMargin / 2) + 'px',
                }" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-if="datas.addMethod == 'auto'">
      <!-- <img draggable="false" v-if="!showimageList1" src="../../../images/mor.png" alt="" style="width: 100%" /> -->

      <!-- 一行二个 -->
      <section class="buju buju0" v-if="datas.rubiksCubeType === 0">
        <div v-for="index in 2" :key="index" class="rubiksCubeType0 rubiksCubeType"
          :style="{ marginRight: index < 2 ? datas.imgMargin + 'px' : '0px' }">
          <img v-if="datas.autoData[index - 1]" :style="{ height: datas.imgHeight + 'px' }" draggable="false"
            :src="datas.autoData[index - 1].src" alt="" />
          <div class="nullBor flex-c-c" :style="{ height: datas.imgHeight + 'px' }" v-if="datas.autoData.length == 0"><i
              class="el-icon-picture-outline"></i></div>
        </div>
      </section>

      <!-- 一行三个 -->
      <section class="buju buju0" v-if="datas.rubiksCubeType === 1">
        <div v-for="index in 3" :key="index" class="rubiksCubeType1 rubiksCubeType" :style="{
          marginRight: index < 3 ? datas.imgMargin + 'px' : '0px',
          width: 33 + '%'
        }">
          <img v-if="datas.autoData[index - 1]" :style="{ height: datas.imgHeight + 'px' }" draggable="false"
            :src="datas.autoData[index - 1].src" alt="" />
          <div class="nullBor flex-c-c" :style="{ height: datas.imgHeight + 'px' }" v-if="datas.autoData.length == 0"><i
              class="el-icon-picture-outline"></i></div>
        </div>
      </section>

      <!-- 一行四个 -->
      <section class="buju buju0" v-if="datas.rubiksCubeType === 2">
        <div v-for="index in 4" :key="index" class="rubiksCubeType2 rubiksCubeType" :style="{
          marginRight: index < 4 ? datas.imgMargin + 'px' : '0px',
          width: '25%'
        }">
          <img v-if="datas.autoData[index - 1]" :style="{ height: datas.imgHeight + 'px' }" draggable="false"
            :src="datas.autoData[index - 1].src" alt="" />
          <div class="nullBor flex-c-c" :style="{ height: datas.imgHeight + 'px' }" v-if="datas.autoData.length == 0"><i
              class="el-icon-picture-outline"></i></div>
        </div>
      </section>

      <!-- 二左二右 -->
      <section class="buju buju0 flex-wrap-y" v-if="datas.rubiksCubeType === 3">
        <div v-for="index in 4" :key="index" class="rubiksCubeType3 rubiksCubeType"
          :style="{ height: datas.imgHeight + 'px' }">
          <img v-if="datas.autoData[index - 1]" draggable="false" :src="datas.autoData[index - 1].src" alt=""
            :style="{ padding: (datas.imgMargin / 2) + 'px', height: datas.imgHeight + 'px' }" />
          <div v-if="datas.autoData.length == 0" class="imgBor"
            :style="{ padding: (datas.imgMargin / 2) + 'px', width: '100%', height: datas.imgHeight + 'px' }">
            <div class="nullBor flex-c-c" :style="{ height: '100%', overflow: 'hidden' }"><i class="
                          el-icon-picture-outline"></i></div>
          </div>
        </div>
      </section>

      <!-- 一左二右 -->
      <section class="buju buju4" v-if="datas.rubiksCubeType === 4">
        <div class="rubiksCubeType hw">
          <img draggable="false" v-if="datas.autoData && datas.autoData[0]" :src="datas.autoData[0].src" alt="" style="height:300px"
            :style="{ 'padding-right': (datas.imgMargin / 2) + 'px' }" />
          <div v-if="datas.autoData.length == 0" class="imgBor"
            :style="{ 'padding-right': (datas.imgMargin / 2) + 'px', width: '100%', height: '300px' }">
            <div class="nullBor flex-c-c" :style="{ height: '100%', overflow: 'hidden' }"><i class="
                          el-icon-picture-outline"></i></div>
          </div>
        </div>
        <div style="display: inline-flex; flex-direction: column; width: 100%">
          <div class=" hw imgone" v-for="index in 2" :key="index" style="height:150px">
            <img draggable="false" v-if="datas.autoData[index]" :src="datas.autoData[index].src" alt=""
              style="height:150px" :style="{ 'padding': (datas.imgMargin / 2) + 'px' }" />
            <div v-if="datas.autoData.length == 0" class="imgBor"
              :style="{ 'padding': (datas.imgMargin / 2) + 'px', width: '100%', height: '150px' }">
              <div class="nullBor flex-c-c" :style="{ height: '100%', overflow: 'hidden' }"><i class="
                          el-icon-picture-outline"></i></div>
            </div>
          </div>
        </div>
      </section>

      <!-- 一上二下 -->
      <section class="buju buju5" v-if="datas.rubiksCubeType === 5">
        <div class="rubiksCubeType hw" style="display: block;height:150px">
          <img draggable="false" v-if="datas.autoData && datas.autoData[0]" :src="datas.autoData[0].src" alt=""
            :style="{ 'padding-bottom': (datas.imgMargin / 2) + 'px' }" />
          <div v-if="datas.autoData.length == 0" class="imgBor"
            :style="{ 'padding-bottom': (datas.imgMargin / 2) + 'px', width: '100%', height: '150px' }">
            <div class="nullBor flex-c-c" :style="{ height: '100%', overflow: 'hidden' }"><i class="
                          el-icon-picture-outline"></i></div>
          </div>
        </div>
        <div style="display: flex; width: 100%">
          <div class="rubiksCubeType hw imgtow" v-for="index in 2" :key="index" style="height:150px">
            <img draggable="false" v-if="datas.autoData[index]" :src="datas.autoData[index].src" alt=""
              :style="{ 'padding': (datas.imgMargin / 2) + 'px' }" />
            <div v-if="datas.autoData.length == 0" class="imgBor"
              :style="{ 'padding': (datas.imgMargin / 2) + 'px', width: '100%', height: '150px' }">
              <div class="nullBor flex-c-c" :style="{ height: '100%', overflow: 'hidden' }"><i class="
                          el-icon-picture-outline"></i></div>
            </div>
          </div>
        </div>
      </section>

      <!-- 一左三右 -->
      <section class="buju buju4" v-if="datas.rubiksCubeType === 6">
        <!-- 第一张图片 -->
        <div class="rubiksCubeType hw" style="padding-top: 100%">
          <img draggable="false" v-if="datas.autoData && datas.autoData[0]" :src="datas.autoData[0].src" alt="" style="height:300px"
            :style="{ 'padding-right': datas.imgMargin + 'px' }" />
        </div>
        <div style="display: inline-flex; flex-direction: column; width: 100%">
          <!-- 第二张图片 -->
          <div class="rubiksCubeType hw" style="padding-top: 150px">
            <img draggable="false" v-if="datas.autoData[1]" :src="datas.autoData[1].src" alt="" :style="{
              'padding-bottom': datas.imgMargin + 'px',
              'padding-left': datas.imgMargin + 'px',
            }" />
          </div>
          <div class="rubiksCubeType">
            <div class="hw" style="
                                                display: inline-flex;
                                                justify-content: center;
                                                align-items: center;
                                                padding-top: 150px;
                                              " v-for="index in 2" :key="index">
              <img draggable="false" v-if="datas.autoData[index + 1]" :src="datas.autoData[index + 1].src" alt=""
                style="height:150px" :style="{
                  'padding-left': datas.imgMargin + 'px',
                  'padding-top': datas.imgMargin + 'px',
                }" />
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>

export default {
  name: 'magiccube',
  props: {
    datas: Object,
  },
  computed: {
    showimageList() {
      if (this.datas.imageList.length === 0) {
        return false;
      }
      if (
        this.datas.rubiksCubeType === 0 &&
        !this.datas.imageList[0].src &&
        !this.datas.imageList[1].src
      )
        return false

      if (
        (this.datas.rubiksCubeType === 1 ||
          this.datas.rubiksCubeType === 4 ||
          this.datas.rubiksCubeType === 5) &&
        !this.datas.imageList[0].src &&
        !this.datas.imageList[1].src &&
        !this.datas.imageList[2].src
      )
        return false

      if (
        (this.datas.rubiksCubeType === 2 ||
          this.datas.rubiksCubeType === 6 ||
          this.datas.rubiksCubeType === 3) &&
        !this.datas.imageList[0].src &&
        !this.datas.imageList[1].src &&
        !this.datas.imageList[2].src &&
        !this.datas.imageList[3].src
      )
        return false

      return true
    },
    showimageList2() {
      if (this.datas.autoData.length === 0) {
        return false;
      }
      if (
        this.datas.rubiksCubeType === 0 &&
        !this.datas.autoData[0].src &&
        !this.datas.autoData[1].src
      )
        return false

      if (
        (this.datas.rubiksCubeType === 1 ||
          this.datas.rubiksCubeType === 4 ||
          this.datas.rubiksCubeType === 5) &&
        !this.datas.autoData[0].src &&
        !this.datas.autoData[1].src &&
        !this.datas.autoData[2].src
      )
        return false

      if (
        (this.datas.rubiksCubeType === 2 ||
          this.datas.rubiksCubeType === 6 ||
          this.datas.rubiksCubeType === 3) &&
        !this.datas.autoData[0].src &&
        !this.datas.autoData[1].src &&
        !this.datas.autoData[2].src &&
        !this.datas.autoData[3].src
      )
        return false

      return true
    },
  },
}
</script>

<style scoped lang="scss">
.nullBor {
  background-color: #eceef0;
  width: 100%;
  color: #d3d3d3;
  font-size: 30px;
  border: 1px solid #d3d3d3;
}

.magiccube {
  position: relative;
  background: #fff;

  /* 布局 */
  .imgone {
    &:last-of-type {

      img,
      .imgBor {
        padding-bottom: 0 !important;
        padding-right: 0 !important;
      }
    }

    &:first-of-type {

      img,
      .imgBor {
        padding-top: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  .imgtow {
    &:first-of-type {

      img,
      .imgBor {
        padding-bottom: 0 !important;
        padding-left: 0 !important;
      }
    }

    &:last-of-type {

      img,
      .imgBor {
        padding-bottom: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  .hw {
    width: 100%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .buju {
    &.buju0 {
      display: flex;
      justify-content: space-around;
      // flex-wrap: wrap;
    }

    &.buju4 {
      display: flex;
      width: 100%;
      height: 300px;
      flex-direction: row;
      justify-content: space-around;
    }

    .active {
      background: #51cdcb26;
      border: 1px solid #51CDCB;
      color: #51CDCB;
      z-index: 2;
    }

    .rubiksCubeType {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.rubiksCubeType0 {
        width: 50%;

        // height: 200px;
        &:first-of-type {
          img {
            padding-left: 0 !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
          }
        }

        &:last-of-type {
          img {
            padding-right: 0 !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
          }
        }

        img {
          width: 100%;
          // height: 200px;
          display: block;
        }
      }

      &.rubiksCubeType1 {
        width: 33.333%;

        &:nth-of-type(1) {
          margin-left: 0 !important;
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }

        &:nth-of-type(2) {
          margin-top: 0 !important;
          margin-left: 0 !important;
          margin-bottom: 0 !important;
        }

        &:nth-of-type(3) {
          margin-top: 0 !important;
          margin-right: 0 !important;
          margin-bottom: 0 !important;
        }

        img {
          width: 100%;
          height: 130px;
          display: block;
        }
      }

      &.rubiksCubeType2 {
        width: 25%;

        &:nth-of-type(1) {
          margin-left: 0 !important;
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }

        &:nth-of-type(2) {
          margin-top: 0 !important;
          margin-left: 0 !important;
          margin-bottom: 0 !important;
        }

        &:nth-of-type(3) {
          margin-top: 0 !important;
          margin-left: 0 !important;
          margin-bottom: 0 !important;
        }

        &:nth-of-type(4) {
          margin-top: 0 !important;
          margin-right: 0 !important;
          margin-bottom: 0 !important;
        }

        img {
          width: 100%;
          height: 150px;
          display: block;
        }
      }

      &.rubiksCubeType3 {
        width: 50%;
        // padding-top: 50%;
        position: relative;

        &:nth-of-type(1) {

          img,
          .imgBor {
            padding-top: 0 !important;
            padding-left: 0 !important;
          }
        }

        &:nth-of-type(2) {

          img,
          .imgBor {
            padding-top: 0 !important;
            padding-right: 0 !important;
          }
        }

        &:nth-of-type(3) {

          img,
          .imgBor {
            padding-bottom: 0 !important;
            padding-left: 0 !important;
          }
        }

        &:nth-of-type(4) {

          img,
          .imgBor {
            padding-bottom: 0 !important;
            padding-right: 0 !important;
          }
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      &.rubiksCubeType4 {
        width: 187px;
        height: 187px;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
}
</style>
