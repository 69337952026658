<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>技术创新及科技成果转化奖励制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D006</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent" style="padding-top: 350px;">
                <div class="wordTtile">
                    <div class="flex-c-c textc">
                        <div class="zdhq fs24">技术创新及科技成果转化奖励制度</div>
                    </div>
                    <div class="textc pjTitle mt10 mb20 fs35">{{wordData.isAgain}}</div>
                </div>
            </div>
            <div class="wordFoot">
                <div class="textc fs30">
                    {{ checkedCompany.companyName }}
                </div>
                <div class="textc fs25">
                    <!-- <span class="fs30" v-if="!showSetTime">{{ formData.fileDay | textTime }}</span> -->
                    <el-date-picker v-model="formData.fileDay" class="dayInput w230" value-format="yyyy-MM-dd"
                        format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                    </el-date-picker>(自动转为中文)
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 1 页 共 3 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>技术创新及科技成果转化奖励制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D006</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordTtile">
                    <div class="textc pjTitle mt50 mb10 fs30">{{ checkedCompany.companyName }}</div>
                    <div class="flex-c-c textc lineBottom ">
                        <div class="zdhq fs40">技术创新及科技成果转化奖励制度</div>
                    </div>
                </div>
                <div class="lineheight45">
                    <div class="textIndent40">
                        为了促进企业技术开发及管理工作，促进新技术的应用，不断开发新产品。提高企业的核心竞争力，同时激励技术人员的工作积极性、做好人才培养和梯队建设，特制定本制度。
                    </div>
                    <div class="fw600">
                        一、技术人员的主要工作职责
                    </div>
                    <div class="textIndent40">
                        <div>
                            1、负责软件项目开发的文件和资料组织编制、贯彻实施，更改控制，建立、健全技术档案。
                        </div>
                        <div>
                            2、负责新项目、新软件的需求调研、设计与开发、实施等工作，组织“设计和开发控制程序”实施。
                        </div>
                        <div>
                            3、负责研发设计、方案编制与改进工作、组织工艺纪律的检查。
                        </div>
                        <div>4、负责“纠正和预防措施程序”的组织实施与控制。</div>
                        <div>5、负责企业产品标准化工作。</div>
                        <div>6、参与组织供方的质量控制，参与供方合格评定。</div>
                        <div>7、负责新产品使用、维护更新等说明的编制，参与公司内部培训的执行。</div>
                    </div>
                    <div class="fw600">
                        二、考核及奖励制度
                    </div>
                    <div class="textIndent40">
                        <div>
                            根据公司的《员工手册》对员工的奖惩制度，结合技术部门的特殊情况，对技术研发人员的奖励采取经济奖励和行政奖励并重的形式。经济奖励的形式为科技奖励、岗位技能工资和收益分享等，行政奖励形式为嘉奖、记功和“优秀员工”等。
                        </div>
                        <div>
                            1、技术人员应努力完成本职工作，达到或稍稍超出公司对员工的基本要求，被视为员工应尽的责任，不予以待遇之外的奖励；
                        </div>
                        <div>2、技术人员按年度进行具体考核。考核优秀的人员作为部门候选人参与公司“优秀员工”的评选，同时考核结果作为技术人员晋升和薪资（包括岗位技能工资）调整的重要依据。</div>
                        <div>3、通过开发立项报告审批，被列为公司年度重点研发项目的，由总经理事前设置项目科技奖励基金。项目按时完成的，根据项目组的成员贡献大小按比例予以奖励；</div>
                        <div>4、公司研发的新产品，因列入市级新产品、申请专利并授权或其他形式而被政府相关部门予以科技奖励时：</div>
                        <div>5、列入市级新产品等受政府有关部门予以科技奖励的资金，其中50%作为技术人员的奖励基金，但不超过10000元；</div>
                        <div>6、按项目组的成员贡献大小按比例予以奖励。</div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 2 页 共 3 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>技术创新及科技成果转化奖励制度 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D006</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt50">
                    <div>7、收益分享：</div>
                    <div class="textIndent40">
                        <div>
                            1）新产品的界定按公司的《新产品界定办法》执行；
                        </div>
                        <div>
                            2）研发的新产品以批量销售起的前二个年度技术部门和主要研发人员可以按产品的年度销售额按比例进行收益分享；
                        </div>
                        <div>
                            3）第一年度：新产品的收益分享比例为产品的实际销售额的1.5%计算，但不超过人均3000元/项年；
                        </div>
                        <div>
                            4）第二年度销售额超过30万时，技术人员的收益分享比例为总销售额的1%，不足30万不予以分享，但不超过人均2000元/项年；
                        </div>
                        <div>5）新产品的收益分享金额按部门20%、个人80%的比例进行分配。</div>
                        <div>6）研发中心设专人负责与财务部门配合统计，原则上一季度结算一次。</div>
                    </div>
                    <div>8、奖金申请及发放</div>
                    <div class="textIndent40">
                        <div>
                            1）由研发项目组提出申请，研发中心核实，人力资源部门及财务部门进行审核，报总经理进行审批。
                        </div>
                        <div>
                            2）审批通过后，由财务部门负责奖金发放。
                        </div>
                    </div>
                    <div>
                        三、附则
                    </div>
                    <div class="textIndent40">
                        <div>
                            1、技术人员与公司解除合同之日起不再享受以上相应政策。
                        </div>
                        <div>
                            2、本制度解释权归总经理办公会所有。
                        </div>
                    </div>
                    <div class="text-align-right" style="margin-top: 300px;">{{ checkedCompany.companyName }}</div>
                    <div class="text-align-right">
                        <!-- <span v-if="!showSetTime">{{ formData.fileDay | textTime }}</span> -->
                        <el-date-picker v-model="formData.fileDay" class="miniDay w190" value-format="yyyy-MM-dd"
                            format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                        </el-date-picker>(自动转为中文)
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 3 页 共 3 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution9310",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                fileDay: "",
            },
            checkedCompany:{}
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        },
        textTime(time) {
            if (time) {
                let dayArr = time.split("-");
                let fileDay = "";
                let dayObj = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
                let tObj = ["年", "月", "日"];
                for (let i in dayArr) {
                    let t = dayArr[i];
                    if (i == 0 || t < 10) {
                        for (let childKey of t) {
                            fileDay += dayObj[childKey];
                        }
                    } else {
                        if (t > 10) {
                            if(t>=20){
                                fileDay += dayObj[String(t).slice(0, 1)]; 
                            }
                            fileDay += "十";
                            if(String(t).slice(1)>0){
                                fileDay += dayObj[String(t).slice(1)];
                            }
                        } else {
                            fileDay += dayObj[t];
                        }
                    }
                    fileDay += tObj[i];
                }
                return fileDay;
            } else {
                return "";
            }
        },
        timeStr(time) {
            if (time) {
                let dayStr = time.replace("-", "年");
                dayStr = dayStr.replace("-", "月");
                return dayStr + "日";
            }
        },
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany=data.companyData;
                    if(data.rulesId){
                        this.formData.fileDay = data.fileDay;
                    }else{
                        this.formData.fileDay = this.checkedCompany.fileDay;
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
};
</script>

<style lang="scss" scoped>
.frame {
    padding: 0 10px;
    border: 1px solid #000;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemTitle {
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 20px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>