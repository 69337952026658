<template>
    <div class="view w100p">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="wordSetContent">
                <div class="flex-c-c textc mb20">
                    <!-- <el-input v-model="formData.name" class="fs30 pjName w100p" placeholder="项目名称"></el-input> -->
                    <div class=" fs35">{{ checkedCompany.companyName }}<el-date-picker v-model="formData.year"
                            class="bigDay w130" value-format="yyyy" format="yyyy" type="year" placeholder="年份">
                        </el-date-picker>年度培训计划表</div>
                </div>
                <table border="1" cellspacing="0" class="tableBorder institutionTable">
                    <thead>
                        <tr class="h40 fwbold">
                            <td class="textc itemTitle w50">序号</td>
                            <td class="textc itemTitle w100">培训类别</td>
                            <td class="textc itemTitle w110">课程名称</td>
                            <td class="textc itemTitle w110">课程目标</td>
                            <td class="textc itemTitle w110">课程对象</td>
                            <td class="textc itemTitle w70">培训时间（月份）</td>
                            <td class="textc itemTitle w70">参训人数</td>
                            <td class="textc itemTitle w70">培训课时（小时）</td>
                            <td class="textc itemTitle w80">培训方式</td>
                            <td class="textc itemTitle w80">讲师</td>
                            <td class="textc itemTitle w70">
                                <div>费用预算（元）</div>
                                <div class="bt000">讲师费用</div>
                            </td>
                            <td class="textc itemTitle w80">备注</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="h50" v-for="(row, index) in formData.content" :key="index">
                            <td class="textc itemContent">
                                <div class="h50" style="line-height: 50px" v-for="(child, key) in row.data"
                                    :key="key + 'd'" :class="{ 'bt000': key != 0 }">
                                    {{ child.num }}
                                </div>
                            </td>
                            <td class="itemContent">
                                <el-input v-model="row.type" class="wordInput" type="textarea" placeholder="培训类别"></el-input>
                            </td>
                            <td class="textc itemContent">
                                <el-popover placement="top" width="190" trigger="click" v-for="(child, key) in row.data"
                                    :key="key + 'a'">
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text"
                                            @click="delSpecsFun(index, key)">删除课程</el-button>
                                        <el-button type="primary" size="mini"
                                            @click="addSpecsFun(index)">添加课程</el-button>
                                    </div>
                                    <div slot="reference">
                                        <el-input v-model="child.className" type="textarea" rows="2"
                                            :class="{ 'bt000': key != 0 }" class="wordInput"
                                            placeholder="课程名称"></el-input>
                                    </div>
                                </el-popover>
                            </td>
                            <td class="itemContent">
                                <el-popover placement="top" width="190" trigger="click" v-for="(child, key) in row.data"
                                    :key="key + 'b'">
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text"
                                            @click="delSpecsFun(index, key)">删除课程</el-button>
                                        <el-button type="primary" size="mini"
                                            @click="addSpecsFun(index)">添加课程</el-button>
                                    </div>
                                    <div slot="reference">
                                        <div class="flex-a-c" :class="{ 'bt000': key != 0 }">
                                            <el-input v-model="child.classTarget" type="textarea" rows="2"
                                                class="wordInput" placeholder="课程目标"></el-input>
                                        </div>
                                    </div>
                                </el-popover>
                            </td>
                            <td class="itemContent">
                                <el-popover placement="top" width="190" trigger="click" v-for="(child, key) in row.data"
                                    :key="key + 'b'">
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text"
                                            @click="delSpecsFun(index, key)">删除课程</el-button>
                                        <el-button type="primary" size="mini"
                                            @click="addSpecsFun(index)">添加课程</el-button>
                                    </div>
                                    <div slot="reference">
                                        <div class="flex-a-c" :class="{ 'bt000': key != 0 }">
                                            <el-input v-model="child.classUser" type="textarea" rows="2"
                                                class="wordInput" placeholder="课程对象"></el-input>
                                        </div>
                                    </div>
                                </el-popover>
                            </td>
                            <td class="itemContent h50">
                                <el-popover placement="top" width="190" trigger="click" v-for="(child, key) in row.data"
                                    :key="key + 'b'">
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text"
                                            @click="delSpecsFun(index, key)">删除课程</el-button>
                                        <el-button type="primary" size="mini"
                                            @click="addSpecsFun(index)">添加课程</el-button>
                                    </div>
                                    <div slot="reference">
                                        <div class="h50" :class="{ 'bt000': key != 0 }">
                                            <el-input v-model="child.month" type="number" class="wordInput"
                                                placeholder="月份"></el-input>
                                        </div>
                                    </div>
                                </el-popover>
                            </td>
                            <td class="itemContent">
                                <el-popover placement="top" width="190" trigger="click" v-for="(child, key) in row.data"
                                    :key="key + 'b'">
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text"
                                            @click="delSpecsFun(index, key)">删除课程</el-button>
                                        <el-button type="primary" size="mini"
                                            @click="addSpecsFun(index)">添加课程</el-button>
                                    </div>
                                    <div slot="reference">
                                        <div class="h50" :class="{ 'bt000': key != 0 }">
                                            <el-input v-model="child.userNum" type="number" class="wordInput"
                                                placeholder="人数"></el-input>
                                        </div>
                                    </div>
                                </el-popover>
                            </td>
                            <td class="itemContent">
                                <el-popover placement="top" width="190" trigger="click" v-for="(child, key) in row.data"
                                    :key="key + 'b'">
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text"
                                            @click="delSpecsFun(index, key)">删除课程</el-button>
                                        <el-button type="primary" size="mini"
                                            @click="addSpecsFun(index)">添加课程</el-button>
                                    </div>
                                    <div slot="reference">
                                        <div class="h50" :class="{ 'bt000': key != 0 }">
                                            <el-input v-model="child.hours" type="number" class="wordInput"
                                                placeholder="培训课时"></el-input>
                                        </div>
                                    </div>
                                </el-popover>
                            </td>
                            <td class="itemContent">
                                <el-popover placement="top" width="190" trigger="click" v-for="(child, key) in row.data"
                                    :key="key + 'b'">
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text"
                                            @click="delSpecsFun(index, key)">删除课程</el-button>
                                        <el-button type="primary" size="mini"
                                            @click="addSpecsFun(index)">添加课程</el-button>
                                    </div>
                                    <div slot="reference">
                                        <div class="h50" :class="{ 'bt000': key != 0 }">
                                            <el-select v-model="child.way" placeholder="请选择">
                                                <el-option label="内训" :value="'内训'">
                                                </el-option>
                                                <el-option label="外训" :value="'外训'">
                                                </el-option>
                                                <el-option label="外派" :value="'外派'">
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                </el-popover>
                            </td>
                            <td class="itemContent">
                                <el-popover placement="top" width="190" trigger="click" v-for="(child, key) in row.data"
                                    :key="key + 'b'">
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text"
                                            @click="delSpecsFun(index, key)">删除课程</el-button>
                                        <el-button type="primary" size="mini"
                                            @click="addSpecsFun(index)">添加课程</el-button>
                                    </div>
                                    <div slot="reference">
                                        <div class="h50" :class="{ 'bt000': key != 0 }">
                                            <el-input v-model="child.teacher" type="text" class="wordInput"
                                                placeholder="讲师"></el-input>
                                        </div>
                                    </div>
                                </el-popover>
                            </td>
                            <td class="itemContent">
                                <el-popover placement="top" width="190" trigger="click" v-for="(child, key) in row.data"
                                    :key="key + 'b'">
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text"
                                            @click="delSpecsFun(index, key)">删除课程</el-button>
                                        <el-button type="primary" size="mini"
                                            @click="addSpecsFun(index)">添加课程</el-button>
                                    </div>
                                    <div slot="reference">
                                        <div class="h50" :class="{ 'bt000': key != 0 }">
                                            <el-input v-model="child.money" type="number" class="wordInput"
                                                placeholder="费用"></el-input>
                                        </div>
                                    </div>
                                </el-popover>
                            </td>
                            <td class="textc itemContent">
                                <el-input v-model="row.remark" type="textarea" :autosize="{ minRows: 2 }"
                                    class="wordInput" placeholder="备注"></el-input>
                                <div class="tool d-flex">
                                    <div>
                                        <i class="eicon el-icon-caret-top" @click="topDevice(index)" title="上移设备"></i>
                                        <i class="eicon el-icon-caret-bottom" @click="bottomDevice(index)"
                                            title="下移设备"></i>
                                    </div>
                                    <div>
                                        <i class="eicon el-icon-plus" @click="addDevice()" title="添加设备"></i>
                                        <i class="eicon el-icon-delete" @click="delDevice(index)" title="删除设备"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="h40">
                            <td class="textc itemTitle" colspan="6">合计</td>
                            <td class="textc itemTitle">{{ sumNumber('userNum') }}</td>
                            <td class="textc itemTitle">{{ sumNumber('hours') }}</td>
                            <td class="textc itemTitle">-</td>
                            <td class="textc itemTitle">-</td>
                            <td class="textc itemTitle">{{ sumNumber('money') }}</td>
                            <td class="textc itemTitle"></td>
                        </tr>
                        <tr class="h40">
                            <td class="textc itemTitle" colspan="7">年度培训费用总预算（元）</td>
                            <td class="textc itemTitle" colspan="5">{{ sumNumber('money') }}</td>
                        </tr>
                        <tr class="h40">
                            <td class="textc itemTitle" colspan="7">其中：外训</td>
                            <td class="textc itemTitle" colspan="5">{{ sumTypeNumber('外训') }}</td>
                        </tr>
                        <tr class="h40">
                            <td class="textc itemTitle" colspan="7">外派</td>
                            <td class="textc itemTitle" colspan="5">{{ sumTypeNumber('外派') }}</td>
                        </tr>
                        <tr class="h40">
                            <td class="textc itemTitle" colspan="7">内训</td>
                            <td class="textc itemTitle" colspan="5">{{ sumTypeNumber('内训') }}</td>
                        </tr>
                        <tr class="h40">
                            <td class="textc itemTitle" colspan="7">年度预计培训总场次（次）</td>
                            <td class="textc itemTitle" colspan="5">{{ classUserSum() }}</td>
                        </tr>
                        <tr class="h40">
                            <td class="textc itemTitle" colspan="7">年度预计参训总人次（人）</td>
                            <td class="textc itemTitle" colspan="5">{{ sumNumber('userNum') }}</td>
                        </tr>
                        <tr class="h40">
                            <td class="textc itemTitle" colspan="7">年度培训总课时数（小时）</td>
                            <td class="textc itemTitle" colspan="5">{{ sumNumber('hours') }}</td>
                        </tr>
                        <tr class="h40">
                            <td class="textc itemTitle" colspan="7">人均培训小时</td>
                            <td class="textc itemTitle" colspan="5">{{ averageTime() }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <gpt-model ref="gptModel" :GPTMessage="GPTMessage" :gptTitle="gptTitle" :commonUseMessage="commonUseMessage"
            @clickAnswer="clickAnswer" @caleChange="caleChange" @gptResult="gptResult" @gptMove="gptMove"></gpt-model>
    </div>
</template>

<script>
import gptModel from "@/components/common/gptModel";
export default {
    name: "institution9421",
    components: {
        gptModel
    },
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            //--gpt窗口配置
            // gpt窗口标题
            gptTitle: "",
            // gpt窗口提问预值
            GPTMessage: "",
            // gpt窗口快捷提问数据
            commonUseMessage: [
                '组织管理制度怎么写',
                '写组织管理制度该准备什么材料'
            ],
            //列表配置
            formData: {
                content: [],
                year: "",
            },
            showAddDevice: false,
            checkedCompany: {},
        };
    },
    computed: {
        sumNumber() {
            return function (key) {
                let sum = 0;
                if (this.formData.content) {
                    for (let row of this.formData.content) {
                        for (let child of row.data) {
                            sum += Number(child[key])
                        }
                    }
                }
                return sum
            }
        },
        sumTypeNumber() {
            return function (type) {
                let sum = 0;
                if (this.formData.content) {
                    for (let row of this.formData.content) {
                        for (let child of row.data) {
                            if (child.way == type) {
                                sum += Number(child.money)
                            }

                        }
                    }
                }
                return sum
            }
        },
        averageTime() {
            return function () {
                let userNums = 0;
                let hourss = 0;
                if (this.formData.content) {
                    for (let row of this.formData.content) {
                        for (let child of row.data) {
                            if (child.userNum) {
                                userNums += Number(child.userNum)
                                hourss += Number(child.hours)
                            }

                        }
                    }
                }
                return (hourss / userNums).toFixed(2)
            }
        },
        classUserSum() {
            return function () {
                let sum = 0;
                if (this.formData.content) {
                    for (let row of this.formData.content) {
                        for (let child of row.data) {
                            sum++;
                        }
                    }
                }
                return sum
            }
        },
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData.year = data.year ? String(data.year) : "";
                        this.formData.content = data.content ? data.content : [];
                    } else {
                        this.formData.year = this.yearValue;
                        this.formData.content = [
                            {
                                type: "新员工",
                                remark: "新员工入职必须参加",
                                data: [
                                    {
                                        num: 1,
                                        className: "企业文化",
                                        classTarget: "了解公司文化、公司发展历程",
                                        classUser: "新员工",
                                        month: 12,
                                        userNum: 5,
                                        hours: 2,
                                        way: "内训",
                                        teacher: this.checkedCompany.printName,
                                        money: 0,
                                    },
                                    {
                                        num: 2,
                                        className: "产品知识及岗前培训",
                                        classTarget: "了解公司产品",
                                        classUser: "新员工",
                                        month: 12,
                                        userNum: 5,
                                        hours: 2,
                                        way: "内训",
                                        teacher: this.checkedCompany.printName,
                                        money: 0,
                                    },
                                    {
                                        num: 3,
                                        className: "公司人事规章制度",
                                        classTarget: "了解公司人事管理制度",
                                        classUser: "新员工",
                                        month: 12,
                                        userNum: 5,
                                        hours: 2,
                                        way: "内训",
                                        teacher: this.checkedCompany.printName,
                                        money: 50,
                                    },
                                    {
                                        num: 4,
                                        className: "公司行政管理制度",
                                        classTarget: "了解公司行政制度",
                                        classUser: "新员工",
                                        month: 12,
                                        userNum: 5,
                                        hours: 2,
                                        way: "内训",
                                        teacher: this.checkedCompany.printName,
                                        money: 50,
                                    },
                                ]
                            },
                            {
                                type: "全体员工",
                                remark: "",
                                data: [
                                    {
                                        num: 5,
                                        className: "如何提高执行力",
                                        classTarget: "提高部门执行力",
                                        classUser: "部门全员",
                                        month: 6,
                                        userNum: 5,
                                        hours: 2,
                                        way: "内训",
                                        teacher: this.checkedCompany.legalName,
                                        money: 0,
                                    },
                                    {
                                        num: 6,
                                        className: "产品知识及岗前培训",
                                        classTarget: "了解公司产品",
                                        classUser: "新员工",
                                        month: 12,
                                        userNum: 5,
                                        hours: 2,
                                        way: "内训",
                                        teacher: this.checkedCompany.legalName,
                                        money: 0,
                                    },
                                ]
                            },
                            {
                                type: "中高层（全公司）",
                                remark: "",
                                data: [
                                    {
                                        num: 7,
                                        className: "沟通技巧与冲突处理",
                                        classTarget: "学会上下级之间以及跨部门之间沟通，有效处理部门内部和部门之间的冲突",
                                        classUser: "中高层管理人员",
                                        month: 6,
                                        userNum: 2,
                                        hours: 2,
                                        way: "内训",
                                        teacher: this.checkedCompany.legalName,
                                        money: 0,
                                    },
                                    {
                                        num: 8,
                                        className: "如何提高执行力",
                                        classTarget: "掌握有效提升团队执行力的技巧",
                                        classUser: "中高层管理人员",
                                        month: 6,
                                        userNum: 2,
                                        hours: 2,
                                        way: "内训",
                                        teacher: this.checkedCompany.legalName,
                                        money: 0,
                                    },
                                    {
                                        num: 9,
                                        className: "公司、部门绩效管理与考核",
                                        classTarget: "打造高绩效团队，配合公司绩效考核",
                                        classUser: "中高层管理人员",
                                        month: 6,
                                        userNum: 2,
                                        hours: 4,
                                        way: "内训",
                                        teacher: this.checkedCompany.legalName,
                                        money: 0,
                                    },
                                ]
                            },
                            {
                                type: "研发技术人员",
                                remark: "",
                                data: [
                                    {
                                        num: 10,
                                        className: "",
                                        classTarget: "",
                                        classUser: "全体技术人员",
                                        month: 4,
                                        userNum: 2,
                                        hours: 4,
                                        way: "内训",
                                        teacher: "",
                                        money: 200,
                                    },
                                    {
                                        num: 11,
                                        className: "",
                                        classTarget: "",
                                        classUser: "全体技术人员",
                                        month: 5,
                                        userNum: 2,
                                        hours: 4,
                                        way: "内训",
                                        teacher: "",
                                        money: 200,
                                    },
                                    {
                                        num: 12,
                                        className: "",
                                        classTarget: "",
                                        classUser: "全体技术人员",
                                        month: 6,
                                        userNum: 2,
                                        hours: 4,
                                        way: "内训",
                                        teacher: "",
                                        money: 200,
                                    }
                                ]
                            },
                        ];
                    }

                }
            },
            deep: true,
            immediate: true,
        },
    },
    created() {

    },
    methods: {
        /**@method GPT点击答案回调 */
        clickAnswer(str) {
            // if (!this.askKey) {
            //     this.copyFun(str);
            //     return;
            // } else {
            //     let msg = "";
            //     if (this.askKey == "innovate" && this.askKey == "core") {
            //         msg = str
            //     } else {
            //         msg = str.replace(/\n/ig, `\n    `);
            //     }
            //     this.assignment = { key: this.askKey, gptResult: msg }
            // }
        },
        /**@method 回答结果 */
        gptResult(res) {
            // let msg = "";
            // if (this.askKey == "innovate" && this.askKey == "core") {
            //     msg = res
            // } else {
            //     msg = res.replace(/\n/ig, `\n    `);
            // }
            // this.assignment = { key: this.askKey, gptResult: msg }
            // if (this.askKey === 'createData') {
            //     let answerData = localStorage.getItem("answerData") ? JSON.parse(localStorage.getItem("answerData")) : [];
            //     this.askContent.content = msg;
            //     answerData.unshift(this.askContent);
            //     localStorage.setItem("answerData", JSON.stringify(answerData.slice(0, 30)));
            //     this.askKey = "";
            //     this.askContent = { ask: "", content: "" };
            // }
        },
        /**@method 监听gpt窗口的移动 */
        gptMove(obj) {
            // this.modelClass = "showGptRight"
        },
        /**@method 监听缩放
         * 缩放类型 bigRight bigLeft centre small
         */
        caleChange(type) {
            // this.caleType = type;
        },
        /**@method 显示添加设备 */
        showAddDeviceFun() {
            this.showAddDevice = true;
        },
        /**@method 添加设备 */
        addDevice() {
            this.formData.content.push({
                type: "",
                remark: "",
                data: [
                    {
                        num: 0,
                        className: "",
                        classUser: "",
                        month: "",
                        userNum: 0,
                        hours: 0,
                        way: "",
                        teacher: "",
                        money: 0,
                        remarks: "",
                    }
                ]
            });
            this.getNumTable();
        },
        getNumTable() {
            let n = 0;
            for (let i in this.formData.content) {
                for (let k in this.formData.content[i].data) {
                    n++;
                    this.$set(this.formData.content[i].data[k], "num", Number(n));
                }
            }
        },
        delDevice(i) {
            if (this.formData.content.length > 1) {
                this.formData.content.splice(i, 1)
                this.getNumTable();
            }
        },
        /**@method 删除课程 */
        delSpecsFun(i, key) {
            if (this.formData.content[i].data.length > 1) {
                this.formData.content[i].data.splice(key, 1)
                this.getNumTable();
            }
        },
        bottomDevice(i) {
            if (this.formData.content.length != (i + 1)) {
                let bottomData = JSON.parse(JSON.stringify(this.formData.content[i + 1]));
                let currentData = JSON.parse(JSON.stringify(this.formData.content[i]));
                // this.formData.content[i] = bottomData;
                // this.formData.content[i + 1] = currentData;
                this.$set(this.formData.content, i, bottomData);
                this.$set(this.formData.content, i + 1, currentData);
                this.getNumTable();
            }
        },
        topDevice(i) {
            if (i > 0) {
                let topData = JSON.parse(JSON.stringify(this.formData.content[i - 1]));
                let currentData = JSON.parse(JSON.stringify(this.formData.content[i]));
                // this.formData.content[i] = topData;
                // this.formData.content[i - 1] = currentData;
                this.$set(this.formData.content, i, topData);
                this.$set(this.formData.content, i - 1, currentData);
                this.getNumTable();
            }
        },
        addSpecsFun(i) {
            this.formData.content[i].data.push({
                num: 0,
                className: "",
                classUser: "",
                month: "",
                userNum: 0,
                hours: 0,
                way: "",
                teacher: "",
                money: 0,
                remarks: "",
            });
            this.getNumTable();
        },
    },
};
</script>

<style lang="scss" scoped>
.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.wordSetContent {
    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.institutionTable {
    border-color: #000;



    .tool {
        display: block;
        position: absolute;
        top: 50%;
        right: -51px;
        width: 50px;
        height: 50px;
        margin-top: -25px;
        background: #f1f2f4;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

        .eicon {
            font-size: 18px;
            color: #666;
            cursor: pointer;
            height: 25px;
            width: 25px;
            text-align: center;
            line-height: 25px;
        }

        .el-icon-delete {
            color: #F56C6C;
        }
    }

    .itemTitle {
        height: 100%;
        font-size: 15px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 15px;
        position: relative;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    //letter-spacing: 3px;
}

.showModelContent {
    background: #fff;
    position: relative;
    margin: 0 10px;
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 20px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }
}
</style>