<template>
    <span>
        <el-autocomplete v-if="contentType === 'u'" popper-class="my-autocomplete" :value="value" @input="watchInput"
            class="printName w120" :fetch-suggestions="getUserData" :placeholder="placeholder" @select="selectUser">
            <template slot-scope="{ item }">
                <div class="flex-a-b-c">
                    <div class="addr text-overflow">{{ item.userName }}</div>
                    <!-- <div class="name">{{ item.work }}</div> -->
                </div>
            </template>
        </el-autocomplete>
        <el-autocomplete v-if="contentType === 'uwt'" popper-class="my-autocomplete" :value="value" @input="watchInput"
            class="printName w170" :fetch-suggestions="getUserData" :placeholder="placeholder" @select="selectUser">
            <template slot-scope="{ item }">
                <div class="flex-a-b-c">
                    <div class="addr w60 text-overflow">{{ item.userName }}</div>
                    <div class="name fs6 c999">{{ item.work }}</div>
                </div>
                <div v-if="item.inTime" class="fs6 c999">{{ item.inTime.slice(0, 10) }}</div>
            </template>
        </el-autocomplete>
    </span>
</template>

<script>
import { getProjectUserList } from "@/api/projectAdmin";
export default {
    name: "omSelectUser",
    props: {
        placeholder: {
            type: String,
            default: "请输入"
        },
        pcId: {
            default: ''
        },
        contentType: {
            type: String,
            default: "u"
        },
        value: String,
    },
    methods: {
        watchInput(val) {
            this.$emit("input", val)
        },
        /**@method 获取项目列表 */
        getUserData(str, cb) {
            let params = {
                pageSize: 100,
                pageNum: 1,
                sortCode: 1,
                pcId: this.pcId,
            };
            if (str) {
                params.userName = str;
            }
            let userTable = [];
            getProjectUserList(params).then(res => {
                if (res.data) {
                    for (let row of res.data.pageInfo.list) {
                        userTable.push(row);
                    }
                }
                cb(userTable);
            }).catch(err => {
                cb(userTable);
            })
        },
        /**@method 选中项目 */
        selectUser(data) {
            this.$emit("select", data);
        },
    }
}
</script>

<style>
.printName .el-input__inner {
    font-size: 20px !important;
}
</style>