<template>
  <div class="entertheshopstyle">
    <!-- 标题 -->
    <h2>{{ datas.text }}</h2>

    <!-- 表单 -->
    <el-form label-width="80px" :model="datas" :rules="rules" size="small">
      <el-form-item label="左侧标题" :hide-required-asterisk="true" prop="shopName">
        <el-input v-model="datas.shopName" placeholder="请输入左侧标题" maxlength="10" show-word-limit />
      </el-form-item>

      <!-- 文案 -->
      <el-form-item label="右侧内容" :hide-required-asterisk="true" prop="copywriting">
        <el-input v-model="datas.copywriting" placeholder="请输入右侧内容" maxlength="8" show-word-limit />
      </el-form-item>

      <el-form-item label="边框阴影">
        <div class="flex-a-b-c">
          {{ datas.isShadow ? '显示' : '隐藏' }}
          <el-checkbox v-model="datas.isShadow" />
        </div>
      </el-form-item>
      <!-- 跳转页面 -->
      <el-form-item label="跳转页面">
        <div class="imgText">
          <associationJumpLink :linkData="datas.http" @changeFun="jumpLinkChange($event)" />
        </div>
      </el-form-item>
      <el-form-item label="左侧图标">
      </el-form-item>
      <div>
        <img :src="datas.icon" v-if="datas.icon" style="height:50px;width:50px" />
        <!-- 添加导航按钮 -->
        <el-button @click="$refs.upload.showUpload()" class="uploadImg" type="primary" plain>
          <i class="el-icon-plus" />点击{{ datas.icon ? '更换' : '添加' }}图片
        </el-button>
      </div>
    </el-form>

    <!-- 上传图片 -->
    <uploadimg ref="upload" @uploadInformation="uploadInformation" />
  </div>
</template>

<script>
import uploadimg from '../../../uploadImg' //图片上传

export default {
  name: 'associationEntertheshopstyle',
  props: {
    datas: Object,
  },
  data() {
    return {
      rules: {
        //校验表单输入
        shopName: [
          //页面名称
          { required: true, message: '请输入左侧标题', trigger: 'blur' },
        ],
        copywriting: [
          //页面描述
        ],
      }
    }
  },
  methods: {
    /**@method 链接路径 */
    jumpLinkChange(data) {
      if (data.type) {
        let { urlType, type, id, title } = data;
        this.datas.http = {
          urlType,
          type,
          id,
          title
        };
      } else {
        this.datas.http = {};
      }
    },
    // 提交
    uploadInformation(res) {
      this.datas.icon = res
      //-console.log(res)
    },
  },
  components: {
    uploadimg,
  },
}
</script>

<style scoped lang="scss">
.entertheshopstyle {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 10px 20px;
  box-sizing: border-box;

  /* 标题 */
  h2 {
    padding: 24px 16px 24px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #f2f4f6;
    font-size: 18px;
    font-weight: 600;
    color: #323233;
  }

  /* 图片字 */
  .imgText {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;

    .fir-sele.el-select {
      width: 40%;
    }
  }

  /* 上传图片按钮 */
  .uploadImg {
    width: 345px;
    height: 40px;
    margin-top: 20px;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 56px;
    height: 56px;
  }
}
</style>
