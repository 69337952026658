var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity"},[_c('div',{staticClass:"more1"},[_c('h4',[_vm._v(_vm._s(_vm.datas.name))]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.viewMore1),expression:"datas.viewMore1"}]},[_vm._v("查看更多 "),_c('van-icon',{attrs:{"name":"arrow"}})],1)]),(_vm.datas.addMethod === 'auto')?_c('div',[_c('section',{directives:[{name:"show",rawName:"v-show",value:(!_vm.datas.autoData || !_vm.datas.autoData[0]),expression:"!datas.autoData || !datas.autoData[0]"}],staticClass:"defaultcommodity defaultcommodityList2"},_vm._l((_vm.datas.dataNumber),function(index){return _c('div',{key:index,staticClass:"defaultcommodityList defaultcommodityList2",style:({
                'border-radius': _vm.datas.borderRadius + 'px',
                border: '1px solid rgba(50,50,51,0.1)',
                width: 33 - _vm.datas.commodityMargin / 5 + '%',
                'margin-right': _vm.datas.pageMargin + 'px'
            })},[_c('div',{staticClass:"imgss",class:[_vm.datas.positions === 'top' ? 'containoptions' : ''],staticStyle:{"position":"relative","width":"100%","height":"120px"}},[_c('el-image',{staticStyle:{"width":"100%","height":"120px"},attrs:{"src":"../../../images/imgs.png","fit":"cover"}})],1),_c('div',{staticClass:"text",class:[_vm.datas.positions === 'top' ? 'positionsTop' : ''],style:({ background: _vm.datas.moditystyle !== 3 ? '#fff' : 'none' })},[_vm._m(0,true)])])}),0),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.autoData && _vm.datas.autoData[0]),expression:"datas.autoData && datas.autoData[0]"}],staticClass:"defaultcommodity defaultcommodityList2"},_vm._l((_vm.datas.autoData),function(item,index){return _c('div',{key:index,staticClass:"defaultcommodityList defaultcommodityList2",style:({
                    'border-radius': _vm.datas.borderRadius + 'px',
                    border: '1px solid rgba(50,50,51,0.1)',
                    width: 33 - _vm.datas.commodityMargin / 5 + '%',
                    'margin-right': _vm.datas.pageMargin + 'px'
                })},[_c('div',{staticClass:"imgss",class:[_vm.datas.positions === 'top' ? 'containoptions' : ''],staticStyle:{"position":"relative","width":"100%","height":"120px"}},[_c('el-image',{staticStyle:{"width":"100%","height":"120px"},attrs:{"src":item.img,"fit":"cover"}})],1),_c('div',{staticClass:"text",class:[_vm.datas.positions === 'top' ? 'positionsTop' : ''],style:({ background: _vm.datas.moditystyle !== 3 ? '#fff' : 'none' })},[_c('div',{staticClass:"dianz textc"},[_c('div',{staticClass:"uName text-overflow"},[_vm._v(_vm._s(item.name)+" "),_c('span',{staticClass:"companyName"},[_vm._v(_vm._s(item.levelName))])]),_c('div',{staticClass:"companyName text-overflow"},[_vm._v(_vm._s(item.companyName))])])])])}),0)]):_vm._e(),(_vm.datas.addMethod === 'manual')?_c('div',[_c('section',{directives:[{name:"show",rawName:"v-show",value:(!_vm.datas.manualData[0]),expression:"!datas.manualData[0]"}],staticClass:"defaultcommodity defaultcommodityList2"},_vm._l((3),function(index){return _c('div',{key:index,staticClass:"defaultcommodityList defaultcommodityList2",style:({
                'border-radius': _vm.datas.borderRadius + 'px',
                border: '1px solid rgba(50,50,51,0.1)',
                width: 33 - _vm.datas.commodityMargin / 5 + '%',
                'margin-right': _vm.datas.pageMargin + 'px'
            })},[_c('div',{staticClass:"imgss",class:[_vm.datas.positions === 'top' ? 'containoptions' : ''],staticStyle:{"position":"relative","width":"100%","height":"120px"}},[_c('el-image',{staticStyle:{"width":"100%","height":"120px"},attrs:{"src":"../../../images/imgs.png","fit":"cover"}})],1),_c('div',{staticClass:"text",class:[_vm.datas.positions === 'top' ? 'positionsTop' : ''],style:({ background: _vm.datas.moditystyle !== 3 ? '#fff' : 'none' })},[_vm._m(1,true)])])}),0),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.manualData[0]),expression:"datas.manualData[0]"}],staticClass:"defaultcommodity defaultcommodityList2"},_vm._l((_vm.datas.manualData),function(item,index){return _c('div',{key:index,staticClass:"defaultcommodityList defaultcommodityList2",style:({
                    'border-radius': _vm.datas.borderRadius + 'px',
                    border: '1px solid rgba(50,50,51,0.1)',
                    width: 33 - _vm.datas.commodityMargin / 5 + '%',
                    'margin-right': _vm.datas.pageMargin + 'px'
                })},[_c('div',{staticClass:"imgss",class:[_vm.datas.positions === 'top' ? 'containoptions' : ''],staticStyle:{"position":"relative","width":"100%","height":"120px"}},[_c('el-image',{staticStyle:{"width":"100%","height":"120px"},attrs:{"src":item.img,"fit":"cover"}})],1),_c('div',{staticClass:"text",class:[_vm.datas.positions === 'top' ? 'positionsTop' : ''],style:({ background: _vm.datas.moditystyle !== 3 ? '#fff' : 'none' })},[_c('div',{staticClass:"dianz textc"},[_c('div',{staticClass:"uName text-overflow"},[_vm._v(_vm._s(item.name)+" "),_c('span',{staticClass:"companyName"},[_vm._v(_vm._s(item.levelName))])]),_c('div',{staticClass:"companyName text-overflow"},[_vm._v(_vm._s(item.companyName))])])])])}),0)]):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.viewMore2),expression:"datas.viewMore2"}],staticClass:"more2"},[_vm._v(" 查看更多 "),_c('van-icon',{attrs:{"name":"arrow"}})],1),_vm._t("deles")],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dianz textc"},[_c('div',{staticClass:"uName text-overflow"},[_vm._v("名称 "),_c('span',{staticClass:"companyName"},[_vm._v("职务")])]),_c('div',{staticClass:"companyName text-overflow"},[_vm._v("公司名称")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dianz textc"},[_c('div',{staticClass:"uName text-overflow"},[_vm._v("名称 "),_c('span',{staticClass:"companyName"},[_vm._v("职务")])]),_c('div',{staticClass:"companyName text-overflow"},[_vm._v("公司名称")])])
}]

export { render, staticRenderFns }