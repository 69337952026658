var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"headBg",style:({ backgroundColor: _vm.pageSetup.titleBgColor, backgroundImage: 'url(' + _vm.pageSetup.titleBgImg + ')' })}),_c('div',{staticClass:"headerTop",style:({
      color: _vm.pageSetup.titleColor,
      height: _vm.pageSetup.titleHeight + 'px',
    })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pageSetup.isAddress),expression:"pageSetup.isAddress"}],staticClass:"lef"},[_c('i',{staticClass:"el-icon-location-outline"}),_vm._v(" "),_c('span',[_vm._v("中国")])]),_c('div',{staticClass:"header-title",style:({
        height: _vm.pageSetup.titleHeight + 'px',
        'line-height': _vm.pageSetup.titleHeight + 'px',
      })},[_vm._v(" "+_vm._s(_vm.associationName)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }