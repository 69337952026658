<template>
  <div class="decorate">
    <!-- 标题 -->
    <h2>页面设置</h2>

    <!-- 表单 -->
    <el-form label-position="top" label-width="80px" :model="datas" :rules="rules" size="small">
      <el-form-item label="页面标题" :hide-required-asterisk="true" prop="name">
        <el-input v-model="datas.name" placeholder="页面标题" maxlength="25" show-word-limit disabled />
      </el-form-item>

      <el-form-item label="页面描述" :hide-required-asterisk="true">
        <el-input rows="5" type="textarea"  v-model="datas.details" placeholder="页面描述" />
      </el-form-item>

      <!-- 地区选择 -->
      <el-form-item label="地区选择" class="lef">
        {{ datas.isAddress ? '显示' : '隐藏' }}
        <el-checkbox style="margin-left: 196px" v-model="datas.isAddress" />
      </el-form-item>

      <!-- 返回 -->
      <!-- <el-form-item label="返回按钮" class="lef">
        {{ datas.isBack ? '显示' : '隐藏' }}
        <el-checkbox style="margin-left: 196px" v-model="datas.isBack" />
      </el-form-item> -->

      <!-- 高度 -->
      <el-form-item label="高度" class="lef-height">
        <el-slider v-model="datas.titleHeight" :max="100" :min="45" input-size="mini" show-input>
        </el-slider>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <!-- 标题颜色 -->
          <el-form-item label="首页标题配置">
            <el-form-item label="标题颜色">
              <!-- 颜色选择器 -->
              <el-color-picker v-model="datas.titleColor" show-alpha class="picke" :predefine="predefineColors">
              </el-color-picker>
            </el-form-item>
            <el-form-item label="标题背景颜色">
              <!-- 颜色选择器 -->
              <el-color-picker v-model="datas.titleBgColor" show-alpha class="picke" :predefine="predefineColors">
              </el-color-picker>
            </el-form-item>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="其他页面标题配置">
            <el-form-item label="标题颜色">
              <el-switch v-model="datas.childTitleColor" active-text="黑" inactive-text="白">
              </el-switch>
            </el-form-item>
            <el-form-item label="标题背景颜色">
              <!-- 颜色选择器 -->
              <el-color-picker v-model="datas.childTitleBgColor" class="picke"
                :predefine="predefineColors">
              </el-color-picker>
            </el-form-item>
          </el-form-item>
        </el-col>
      </el-row>


      <!-- 标题背景图片 -->
      <el-form-item label="标题背景图片">
        <div class="shop-head-pic" style="text-align: center">
          <img class="title-home-bg" :src="datas.titleBgImg" alt="" v-if="datas.titleBgImg" />
          <div class="shop-head-pic-btn" style="text-align: center">
            <el-button @click="titleShowUpload('3')" class="uploadImg" type="primary" plain><i class="el-icon-plus" />更换图片
            </el-button>
            <el-button type="primary" @click="titleClear()">清空图片</el-button>
          </div>
        </div>
      </el-form-item>

      <!-- 背景颜色 -->
      <el-form-item label="背景颜色">
        <!-- 单选框 -->
        <el-radio-group v-model="colourAction">
          <el-radio label="默认颜色" />
          <el-radio label="自定义颜色" />
        </el-radio-group>

        <!-- 颜色选择器 -->
        <el-color-picker v-model="datas.bgColor" show-alpha class="picke" v-show="pickeShow" :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>

      <el-form-item label="背景图片">
        <div class="shop-head-pic" style="text-align: center">
          <img class="home-bg" :src="datas.bgImg" alt="" v-if="datas.bgImg" />
          <div class="shop-head-pic-btn" style="text-align: center">
            <el-button @click="showUpload('2')" class="uploadImg" type="primary" plain><i class="el-icon-plus" />更换图片
            </el-button>
            <el-button type="primary" @click="clear()">清空图片</el-button>
          </div>
        </div>
      </el-form-item>
      <div class="bor"></div>
      <h5 style="color: #000; font-size: 14px">导航设置</h5>
      <div v-if="datas.navigationData">
        <el-form-item class="lef" label="选中字体颜色">
          <!-- 颜色选择器 -->
          <el-color-picker v-model="datas.navigationData.activeTitleColor" show-alpha class="picke"
            :predefine="predefineColors">
          </el-color-picker>
        </el-form-item>

        <el-form-item class="lef" label="默认字体颜色">
          <!-- 颜色选择器 -->
          <el-color-picker v-model="datas.navigationData.titleColor" show-alpha class="picke"
            :predefine="predefineColors">
          </el-color-picker>
        </el-form-item>
        <p class="ml5">导航图标</p>
        <!-- <vuedraggable v-bind="{ animation: 200 }"> -->
          <transition-group>
            <section class="imgBanner flex-a-b-c" v-for="(item, index) in datas.navigationData.data" :key="item + index">
              <!-- <i class="el-icon-circle-close" @click="deleteimg(index)" /> -->
              <!-- 图片 -->
              <div class="d-flex">
                <div class="imagBox mr10" v-for="replaceIconIndex in 2" :key="replaceIconIndex">
                  <img class="imag" :src="replaceIconIndex == 1 ? item.activeIcon : item.icon" draggable="false" />
                  <div>
                    {{ replaceIconIndex == 1 ? '未选中时' : '选中时' }}
                  </div>
                </div>
              </div>
              <div>
                {{ item.title }}
              </div>
              <!-- 标题和链接 -->
              <div class="imgText">
                <div class="imgText-top">
                  <el-switch v-model="item.isShow" :disabled="showLength >= 5 && !item.isShow">
                  </el-switch>
                </div>
              </div>
            </section>
          </transition-group>
        <!-- </vuedraggable> -->
      </div>

    </el-form>

    <!-- 上传图片 -->
    <uploadimg ref="upload" @uploadInformation="uploadInformation" />
  </div>
</template>

<script>
import uploadimg from '../../../uploadImg' //图片上传

export default {
  name: 'parkHomeDecorate',
  props: {
    datas: Object,
  },
  data() {
    return {
      rules: {
        //校验表单输入
        name: [
          //页面名称
          { required: true, message: '请输入页面名称', trigger: 'blur' },
        ],
        details: [
          //页面描述
          { required: true, message: '请输入页面描述', trigger: 'blur' },
        ],
        classification: [
          //分类
          { required: true, message: '请选择页面分类', trigger: 'blur' },
        ],
      },
      colourAction: '默认颜色', // 颜色选择
      pickeShow: false, //颜色选择器是否显示
      predefineColors: [
        // 颜色选择器预设
        "#fff",
        "#000",
        "#3064ba",
        '#51CDCB',
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        '#409EFF',
        '#909399',
        '#C0C4CC',
        '#c7158577',
      ],
      uploadImgDataType: null, // 获取到的图片地址属于哪一类别   0 修改底部logo   1 修改店铺图标 2 页面背景图
    }
  },

  computed: {
    showLength() {
      let n = 0;
      for (let row of this.datas.navigationData.data) {
        if (row.isShow) {
          n++;
        }
      }
      return n;
    }
  },

  methods: {
    // 显示上传图片组件   type :  2 页面背景图 3 标题背景图
    showUpload(type) {
      this.uploadImgDataType = type
      this.$refs.upload.showUpload()
    },
    titleShowUpload(type) {
      this.uploadImgDataType = type
      this.$refs.upload.showUpload()
    },
    // 上传图片
    uploadInformation(res) {
      if (this.uploadImgDataType === '2') {
        this.datas.bgImg = res
      } else if (this.uploadImgDataType === '3') {
        this.datas.titleBgImg = res
      }
    },

    // 清空背景图片
    clear() {
      this.datas.bgImg = ''
    },
    titleClear() {
      this.datas.titleBgImg = ''
    },

  },
  watch: {
    colourAction(data) {
      if (data === '默认颜色') {
        this.datas.bgColor = 'rgba(249, 249, 249, 10)'
        this.pickeShow = false
        return
      } else return (this.pickeShow = true)
    },
  },
  components: { uploadimg },
}
</script>

<style scoped lang="scss">
/* 页面设置 */
.imgBanner {
  padding: 6px 12px;
  margin: 16px 7px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
  display: flex;
  position: relative;

  /* 删除图标 */
  .el-icon-circle-close {
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
    font-size: 19px;
  }

  /* 图片 */
  .imagBox {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;

    .imag {
      width: 60px;
      height: 60px;
    }

    div {
      position: absolute;
      top: 0;
      width: 60px;
      line-height: 60px;
      border-radius: 5px;
      text-align: center;
      font-size: 12px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  /* 图片字 */
  .imgText {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-around;

    /* 图片字 */
    .imgTextChild {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;

      .fir-sele.el-select {
        width: 40%;
      }
    }

    .imgText-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .imgText-top-r {
        flex: 1;
        text-align: center;

        span {
          margin-right: 10px;
        }
      }

      /deep/.el-input,
      .el-input--mini {
        flex: 1;
      }
    }
  }
}

.decorate {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 10px;
  box-sizing: border-box;

  h2 {
    padding: 24px 16px 24px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #f2f4f6;
    font-size: 18px;
    font-weight: 600;
    color: #323233;
  }

  /* 选择器添加和刷新 */
  .ification {
    color: #51CDCB;
    font-size: 14px;
    padding: 0 15px;
    cursor: pointer;
  }

  /* 颜色选择器 */
  .picke {
    margin-left: 15px;
    vertical-align: top;
  }

  .title-home-bg {
    width: 250px;
    height: 150px;
  }

  .home-bg {
    width: 200px;
    height: 300px;
  }

  .lef {
    display: flex;

    /deep/.el-form-item__label {
      text-align: left;
      margin-right: 20px;
    }
  }

  .lef-height {
    /deep/.el-form-item__label {
      text-align: left;
      width: 80px;
      float: left;
    }

    /deep/.el-form-item__content {
      margin-left: 80px;
    }
  }

  // 底部logo
  .bottomLogo {
    display: flex;
    flex-direction: column;

    img {
      display: block;
      width: 220px;
      margin: 10px auto;
    }
  }

  // 店铺信息修改
  .shop-info {
    .shop-name {
      display: flex;
      flex-direction: row;
      color: #ababab;

      .el-input {
        flex: 1;
      }
    }

    .shop-head-pic {
      color: #ababab;
      display: flex;
      flex-direction: column;

      img {
        width: 70px;
        height: 70px;
        margin: 10px auto;
      }

      .shop-head-pic-btn {
        display: flex;
        flex-direction: row;

        .el-button {
          flex: 1;
        }
      }
    }
  }
}
</style>
