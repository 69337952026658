<template>
    <div class="activity">
        <!-- 更多 -->
        <div class="more1">
            <h4>{{ datas.name }}</h4>
            <p v-show="datas.viewMore1">查看更多 <van-icon name="arrow" /></p>
        </div>

        <div v-if="datas.addMethod === 'auto'"><!-- 没有视频展示默认 -->
            <section v-show="!datas.autoData || !datas.autoData[0]" class="defaultcommodity defaultcommodityList2">
                <div v-for="index in datas.dataNumber" :key="index" class="defaultcommodityList defaultcommodityList2" :style="{
                    'border-radius': datas.borderRadius + 'px',
                    border: '1px solid rgba(50,50,51,0.1)',
                    width: 33 - datas.commodityMargin / 5 + '%',
                    'margin-right': datas.pageMargin + 'px'
                }">
                    <!-- 视频图片 -->
                    <div class="imgss" style="position: relative; width: 100%;height: 120px"
                        :class="[datas.positions === 'top' ? 'containoptions' : '']">
                        <el-image style="width: 100%; height: 120px" src="../../../images/imgs.png" fit="cover"></el-image>
                        <!-- 标签 -->
                        <!-- <p class="marks" v-if="datas.sponsor"><span>#</span>笔记标签</p> -->
                    </div>

                    <!-- 文字内容 -->
                    <div class="text" :class="[datas.positions === 'top' ? 'positionsTop' : '']"
                        :style="{ background: datas.moditystyle !== 3 ? '#fff' : 'none' }">
                        <!-- 名称 公司 职务 -->
                        <div class="dianz textc">
                            <div class="uName text-overflow">名称 <span class="companyName">职务</span></div>
                            <div class="companyName text-overflow">公司名称</div>
                        </div>
                    </div>
                </div>
            </section>

            <section v-show="datas.autoData && datas.autoData[0]" class="defaultcommodity defaultcommodityList2">
                <div v-for="(item, index) in datas.autoData" :key="index" class="defaultcommodityList defaultcommodityList2"
                    :style="{
                        'border-radius': datas.borderRadius + 'px',
                        border: '1px solid rgba(50,50,51,0.1)',
                        width: 33 - datas.commodityMargin / 5 + '%',
                        'margin-right': datas.pageMargin + 'px'
                    }">
                    <!-- 视频图片 -->
                    <div class="imgss" style="position: relative; width: 100%;height: 120px"
                        :class="[datas.positions === 'top' ? 'containoptions' : '']">
                        <el-image style="width: 100%; height: 120px" :src="item.img" fit="cover"></el-image>
                        <!-- 标签 -->
                        <!-- <p class="marks" v-if="datas.sponsor"><span>#</span>{{item.companyName}}</p> -->
                    </div>

                    <!-- 文字内容 -->
                    <div class="text" :class="[datas.positions === 'top' ? 'positionsTop' : '']"
                        :style="{ background: datas.moditystyle !== 3 ? '#fff' : 'none' }">
                        <!-- 名称 公司 职务 -->
                        <div class="dianz textc">
                            <div class="uName text-overflow">{{ item.name }} <span
                                    class="companyName">{{ item.levelName }}</span></div>
                            <div class="companyName text-overflow">{{ item.companyName }}</div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-if="datas.addMethod === 'manual'"><!-- 没有视频展示默认 -->
            <section v-show="!datas.manualData[0]" class="defaultcommodity defaultcommodityList2">
                <div v-for="index in 3" :key="index" class="defaultcommodityList defaultcommodityList2" :style="{
                    'border-radius': datas.borderRadius + 'px',
                    border: '1px solid rgba(50,50,51,0.1)',
                    width: 33 - datas.commodityMargin / 5 + '%',
                    'margin-right': datas.pageMargin + 'px'
                }">
                    <!-- 视频图片 -->
                    <div class="imgss" style="position: relative; width: 100%; height: 120px"
                        :class="[datas.positions === 'top' ? 'containoptions' : '']">
                        <el-image style="width: 100%; height: 120px" src="../../../images/imgs.png" fit="cover"></el-image>
                        <!-- 标签 -->
                        <!-- <p class="marks" v-if="datas.sponsor"><span>#</span>笔记标签</p> -->
                    </div>

                    <!-- 文字内容 -->
                    <div class="text" :class="[datas.positions === 'top' ? 'positionsTop' : '']"
                        :style="{ background: datas.moditystyle !== 3 ? '#fff' : 'none' }">
                        <!-- 名称 公司 职务 -->
                        <div class="dianz textc">
                            <div class="uName text-overflow">名称 <span class="companyName">职务</span></div>
                            <div class="companyName text-overflow">公司名称</div>
                        </div>
                    </div>
                </div>
            </section>

            <section v-show="datas.manualData[0]" class="defaultcommodity defaultcommodityList2">
                <div v-for="(item, index) in datas.manualData" :key="index"
                    class="defaultcommodityList defaultcommodityList2" :style="{
                        'border-radius': datas.borderRadius + 'px',
                        border: '1px solid rgba(50,50,51,0.1)',
                        width: 33 - datas.commodityMargin / 5 + '%',
                        'margin-right': datas.pageMargin + 'px'
                    }">
                    <!-- 视频图片 -->
                    <div class="imgss" style="position: relative; width: 100%;height: 120px"
                        :class="[datas.positions === 'top' ? 'containoptions' : '']">
                        <el-image style="width: 100%; height: 120px" :src="item.img" fit="cover"></el-image>
                        <!-- 标签 -->
                        <!-- <p class="marks" v-if="datas.sponsor"><span>#</span>{{item.companyName}}</p> -->
                    </div>

                    <!-- 文字内容 -->
                    <div class="text" :class="[datas.positions === 'top' ? 'positionsTop' : '']"
                        :style="{ background: datas.moditystyle !== 3 ? '#fff' : 'none' }">
                        <!-- 名称 公司 职务 -->
                        <div class="dianz textc">
                            <div class="uName text-overflow">{{ item.name }} <span
                                    class="companyName">{{ item.levelName }}</span></div>
                            <div class="companyName text-overflow">{{ item.companyName }}</div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <p class="more2" v-show="datas.viewMore2">
            查看更多 <van-icon name="arrow" />
        </p>

        <!-- 删除组件 -->
        <slot name="deles" />
    </div>
</template>
    
<script>
export default {
    name: 'memberStyle',
    props: {
        datas: Object,
    },
    data() {
        return {
            active: 0,
        }
    },

    created() { },

    methods: {},
}
</script>
    
<style scoped lang="scss">
.activity {
    position: relative;

    /* 更多1 */
    .more1 {
        margin: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            font-size: 16px;
            color: #323233;
            font-weight: 400;
            width: 270px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        p {
            color: #969799;
            font-size: 12px;
            display: flex;
            align-items: center;
        }
    }

    /* 更多2 */
    .more2 {
        text-align: center;
        color: #969799;
        font-size: 12px;
        margin-bottom: 15px;
    }

    /* 默认商品 */
    .defaultcommodity {
        box-sizing: border-box;
        margin: 1px;

        // padding: 0 15px;
        /* 横向滑动 */
        &.defaultcommodityList2 {
            overflow-x: scroll;
            display: flex;

            /* 滚动条 */
            &::-webkit-scrollbar {
                height: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #51CDCB;
            }

            /deep/.el-collapse-item__header,
            /deep/.el-collapse-item__wrap {
                border-bottom: 0 !important;
            }
        }

        /* 卡片列表 */
        .defaultcommodityList {
            position: relative;
            margin-bottom: 15px;
            overflow: hidden;
            display: inline-flex;
            flex-direction: column;

            /* 大图模式 */
            &.defaultcommodityList0 {
                width: 100%;

                /* 标签 */
                .containoptions {
                    .marks {
                        bottom: 33px;
                        z-index: 3;
                    }
                }

                /* 文字在图片中 */
                .positionsTop {
                    position: absolute;
                    display: flex;
                    flex-direction: column-reverse;
                    left: 0;
                    bottom: 0;
                    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6)) !important;

                    /* 标题 */
                    h5 {
                        color: #fff;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    span {
                        color: #fff;
                    }

                    /* 点赞和阅读量 */
                    .dianz {
                        padding-left: 190px;
                    }
                }
            }

            /* 横向滑动 */
            &.defaultcommodityList2 {
                width: 115px;
                flex: none;
            }


            /* 标签 */
            .marks {
                position: absolute;
                bottom: 10px;
                left: 13px;
                font-size: 10px;
                padding: 2px 5px;
                background: #fff;
                opacity: 0.85;
                border-radius: 3px;

                span {
                    color: #d40;
                }
            }

            /* 图片 */
            img {
                width: 100%;
                display: block;
                overflow: hidden;
            }

            /* 文字 */
            .text {
                padding-top: 10px;
                padding-bottom: 5px;
                width: 100%;
                box-sizing: border-box;

                /* 点赞和阅读量 */
                .dianz {
                    line-height: 25px;

                    .uName {
                        font-size: 16px;
                        color: #000;
                    }

                    .companyName {
                        color: rgb(123, 123, 123);
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
</style>
    