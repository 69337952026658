<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent">
                <div class="textc pjTitle mt80 mb20 fs40">技术研发中心会议记录</div>
                <table border="1" cellspacing="0" class="tableBorder institutionTable">
                    <tbody>
                        <tr class="h60">
                            <td class="textc itemContent w200">会议地点</td>
                            <td class="textc itemContent">会议室</td>
                            <td class="textc itemContent w200">会议时间</td>
                            <td class="textc itemContent">
                                <el-date-picker v-model="formData.meetingTime" class="dayInput w200"
                                    value-format="yyyy-MM-dd" format="yyyy.MM.dd" type="date" placeholder="选择日期">
                                </el-date-picker>
                            </td>
                        </tr>
                        <tr class="h60">
                            <td class="textc itemContent w200">主持人</td>
                            <td class="textc itemContent">
                                <el-input type="text" class="printName w200" placeholder="主持人"
                                    v-model="formData.hostName"></el-input>
                            </td>
                            <td class="textc itemContent w200">记录人</td>
                            <td class="textc itemContent">
                                <el-input type="text" class="printName w200" placeholder="记录人"
                                    v-model="formData.printName"></el-input>
                                <!-- {{ checkedCompany.printName }} -->
                            </td>
                        </tr>
                        <tr class="h60">
                            <td class="textc itemContent w200">参与人员</td>
                            <td class="itemContent" colspan="3">
                                <el-input type="text" class="printName" placeholder="参与人员"
                                    v-model="formData.joinName"></el-input>
                            </td>
                        </tr>
                        <tr class="h60">
                            <td class="textc itemContent w200">会议主题</td>
                            <td class="itemContent" colspan="3" v-if="isAgain">
                                优化调整技术研发中心的有关事项
                            </td>
                            <td class="itemContent" colspan="3" v-else>
                                成立技术研发中心的有关事项
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" class="lineheight60 pall10">
                                <div>会议内容：</div>
                                <div>1、解读技术研发中心的背景和意义；</div>
                                <div>2、确定技术研发中心的总负责人，设立各职能部门及负责人；</div>
                                <div v-if="isAgain">3、调整技术研发中心的相关管理制度；</div>
                                <div v-else>3、宣布技术研发中心的相关管理制度；</div>
                                <div>4、确定技术研究中心的工作计划；</div>
                                <div>5、展望技术研发中心的发展规划及近中期目标。</div>
                                <div class="mb80">
                                    <div class="text-align-right" style="margin-top: 300px;">{{
                checkedCompany.companyName }}</div>
                                    <div class="text-align-right">
                                        <!-- <span v-if="!showSetTime">{{ formData.fileDay | textTime }}</span> -->
                                        <el-date-picker v-model="formData.fileDay" class="dayInput w230"
                                            value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date"
                                            placeholder="选择日期">
                                        </el-date-picker>(自动转为中文)
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div></div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution9122",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        },
        isAgain: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formData: {
                meetingTime: "",
                hostName: "",
                printName:"",
                joinName: "",
                fileDay: ""
            },
            checkedCompany:{},
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        },
        textTime(time) {
            if (time) {
                let dayArr = time.split("-");
                let fileDay = "";
                let dayObj = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
                let tObj = ["年", "月", "日"];
                for (let i in dayArr) {
                    let t = dayArr[i];
                    if (i == 0 || t < 10) {
                        for (let childKey of t) {
                            fileDay += dayObj[childKey];
                        }
                    } else {
                        if (t > 10) {
                            if(t>=20){
                                fileDay += dayObj[String(t).slice(0, 1)]; 
                            }
                            fileDay += "十";
                            if(String(t).slice(1)>0){
                                fileDay += dayObj[String(t).slice(1)];
                            }
                        } else {
                            fileDay += dayObj[t];
                        }
                    }
                    fileDay += tObj[i];
                }
                return fileDay;
            } else {
                return "";
            }
        },
        timeStr(time) {
            if (time) {
                let dayStr = time.replace("-", "年");
                dayStr = dayStr.replace("-", "月");
                return dayStr + "日";
            }
        },
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData = {
                            meetingTime: data.fileDay,
                            hostName: data.hostName,
                            printName:data.printName,
                            joinName: data.joinName,
                            fileDay: data.fileDay
                        }
                    } else {
                        let checkedCompany = this.checkedCompany
                        this.formData = {
                            meetingTime: checkedCompany.fileDay,
                            hostName: checkedCompany.legalName,
                            printName:checkedCompany.printName,
                            joinName: "",
                            fileDay: checkedCompany.fileDay
                        }
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    created() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.frame {
    padding: 0 10px;
    border: 1px solid #000;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 25px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 25px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 25px !important;
        line-height: 20px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 25px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>