import httpUtil from "@/utils/httpUtil";

/**授权接口 */
export const componentloginpage = params => httpUtil.post("/api/crmPc/component/componentloginpage", params);


/**授权信息 */
export const getCompanyAccountList = async params => httpUtil.post("/api/crmPc/companyAccount/getCompanyAccountList", params);


/**重置授权信息 */
export const unbindCompanyAccount = async params => httpUtil.post("/api/crmPc/companyAccount/unbindCompanyAccount", params);


/**移交企业 */
export const companyTransfer = async params => httpUtil.post("/api/crmPc/companyGoods/companyTransfer", params);

/**创建小程序 */
export const createApp = async params => httpUtil.post("/api/crmPc/applet/createApp", params);

/**获得小程序预授权码 */
export const getAuthCodeLink = async params => httpUtil.post("/api/crmPc/applet/getAuthCodeLink", params);

/**获得小程序信息 */
export const getAuthorizer = async params => httpUtil.post("/api/crmPc/applet/getAuthorizer", params);

/**修改小程序名称 */
export const setNickname = async params => httpUtil.post("/api/crmPc/applet/setNickname", params);

/**修改小程序介绍 */
export const modifySignature = async params => httpUtil.post("/api/crmPc/applet/modifySignature", params);

/**提交代码 */
export const commitAndSave = async params => httpUtil.post("/api/crmPc/applet/commitAndSave", params);

/**设置小程序AppSecret */
export const setAppSecret = async params => httpUtil.post("/api/crmPc/applet/setAppSecret", params);

/**同步域名 */
export const reDomain = async params => httpUtil.post("/api/crmPc/applet/reDomain", params);

/**同步类目 */
export const setCategories = async params => httpUtil.post("/api/crmPc/applet/setCategories", params);

/**重新授权 */
export const appletComponentloginpage = async params => httpUtil.post("/api/crmPc/applet/componentloginpage", params);

/** 设置公众号信息 */
export const setOfficialAccount = params => httpUtil.post("/api/crmPc/companyAccount/setOfficialAccount", params);

/** 删除已发布公众号文章 */
export const deleteArticle = params => httpUtil.post("/api/crmPc/property/deleteArticle", params);

/** 删除草稿箱公众号文章 */
export const deleteDraft = params => httpUtil.post("/api/crmPc/property/deleteDraft", params);

/** 发布/预览公众号文章 */
export const publishNew = params => httpUtil.post("/api/crmPc/property/publishNew", params);

/** 新增公众号文章 */
export const uploadDynamicMessage = params => httpUtil.post("/api/crmPc/property/uploadDynamicMessage", params);

/** 群发文章 */
export const sendAll = params => httpUtil.post("/api/crmPc/property/sendAll", params);


/** 添加修改公众号文章设置 */
export const setOfficialAccountOption = params => httpUtil.post("/api/crmPc/companyAccount/setOfficialAccountOption", params);