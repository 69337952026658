<template>
    <el-autocomplete popper-class="my-autocomplete" :value="value" @input="watchInput" class="printName w200"
        :fetch-suggestions="getBookData" :placeholder="placeholder" @select="selectBook">
        <template slot-scope="{ item }">
            <div class="flex-a-b-c">
                <div class="addr">{{ item.title }}</div>
            </div>
        </template>
    </el-autocomplete>
</template>

<script>
export default {
    name: "omSelectBook",
    props: {
        placeholder: {
            type: String,
            default: "请输入"
        },
        value: String,
    },
    methods: {
        watchInput(val) {
            this.$emit("input", val)
        },
        getBookData(str, cb) {
            let arr = [
                { title: "发明专利" },
                { title: "实用新型专利" },
                { title: "外观专利" },
                { title: "软件著作权" },
                { title: "集成电路布图" },
            ];
            cb(arr);
        },
        selectBook(data) {
            this.$emit("select", data);
        },
    }
}
</script>

<style></style>