import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default new Router({
    mode: 'history',
    routes: [{
            path: "/",
            redirect: "/dashboard"
        },
        {
            path: "/",
            component: () =>
                import ("../components/Home.vue"),
            meta: { title: "home" },
            children: [{
                    path: "dashboard",
                    component: () =>
                        import ("../pages/dashboard"),
                    meta: { title: "工作台", keepAlive: false }
                },
                {
                    path: "managementAccounts",
                    component: () =>
                        import ("../pages/dashboard/managementAccounts"),
                    meta: { title: "账户管理", keepAlive: false }
                },
                /* {
					path: 'customer',
					component: () => import('../pages/customer'),
					meta: { title: '客户管理' }
				}, */
                {
                    path: "customerInput",
                    component: () =>
                        import ("../pages/customer/customerInput.vue"),
                    meta: { title: "客户录入", keepAlive: false }
                },
                {
                    path: "customerManagement",
                    component: () =>
                        import ("../pages/customer/customerManagement.vue"),
                    meta: { title: "客户管理", keepAlive: false }
                },
                {
                    path: "customerDetall",
                    component: () =>
                        import ("../pages/customer/customerDetall.vue"),
                    meta: { title: "客户详情", keepAlive: false }
                },
                {
                    path: "afterSalesDetall",
                    component: () =>
                        import ("../pages/customer/afterSalesDetall.vue"),
                    meta: { title: "售后详情", keepAlive: false }
                },
                {
                    path: "totalCustomer",
                    component: () =>
                        import ("../pages/customer/totalCustomer.vue"),
                    meta: { title: "总客户池", keepAlive: false }
                },
                {
                    path: "returnVisit",
                    component: () =>
                        import ("../pages/customer/returnVisit.vue"),
                    meta: { title: "待回访区", keepAlive: false }
                },
                // {
                //     path: "returnVisit",
                //     component: () =>
                //         import ("../pages/customer/returnVisit.vue"),
                //     meta: { title: "客户跟进分析", keepAlive: false }
                // },
                // {
                //     path: "returnVisit",
                //     component: () =>
                //         import ("../pages/customer/returnVisit.vue"),
                //     meta: { title: "客户跟进分析", keepAlive: false }
                // },
                {
                    path: "memberReturnVisit",
                    component: () =>
                        import ("../pages/customer/memberReturnVisit.vue"),
                    meta: { title: "成员客户跟进分析", keepAlive: false }
                },
                {
                    path: "followUpAnalyse",
                    component: () =>
                        import ("../pages/customer/followUpAnalyse.vue"),
                    meta: { title: "客户跟进分析", keepAlive: false }
                },
                {
                    path: "walletRecords",
                    component: () =>
                        import ("../pages/wallet/walletRecords.vue"),
                    meta: { title: "钱包流水", keepAlive: false }
                },
                {
                    path: "integralRecord",
                    component: () =>
                        import ("../pages/wallet/integralRecord.vue"),
                    meta: { title: "积分记录", keepAlive: false }
                },
                {
                    path: "pointsProgram",
                    component: () =>
                        import ("../pages/association/pointsProgram/pointsProgram.vue"),
                    meta: { title: "积分节日计划", keepAlive: false }
                },
                {
                    path: "partnership",
                    component: () =>
                        import ("../pages/interflow/partnership.vue"),
                    meta: { title: "合作伙伴", keepAlive: false }
                },
                {
                    path: "companyGoodsLink",
                    component: () =>
                        import ("../pages/interflow/companyGoodsLink.vue"),
                    meta: { title: "商品互通", keepAlive: false }
                },
                {
                    path: "companyOrderGoodsLink",
                    component: () =>
                        import ("../pages/interflow/companyOrderGoodsLink.vue"),
                    meta: { title: "预约商品", keepAlive: false }
                },
                {
                    path: "sharedLibrary",
                    component: () =>
                        import ("../pages/interflow/sharedLibrary.vue"),
                    meta: { title: "商品共享库", keepAlive: false }
                },
                /* 	{
			path: 'form',
			component: () => import('../pages/form'),
			meta: { title: '企业管理' }
		}, */
                {
                    path: 'corporateInformation',
                    component: () =>
                        import ('../pages/businessManagement/corporateInformation'),
                    meta: { title: '企业信息', keepAlive: false }
                },
                {
                    path: 'enterpriseMember',
                    component: () =>
                        import ('../pages/businessManagement/enterpriseMember'),
                    meta: { title: '企业成员', keepAlive: false }
                },
                {
                    path: 'memberDetall',
                    component: () =>
                        import ('../pages/businessManagement/memberDetall'),
                    meta: { title: '成员详情', keepAlive: false }
                },
                {
                    path: 'sectorSetup',
                    component: () =>
                        import ('../pages/businessManagement/sectorSetup/sectorSetup'),
                    meta: { title: '部门设置', keepAlive: false }
                },
                {
                    path: 'AdministratorList',
                    component: () =>
                        import ('../pages/businessManagement/AdministratorList/AdministratorList'),
                    meta: { title: '管理员列表', keepAlive: false }
                },
                {
                    path: 'AdministratorDetail',
                    component: () =>
                        import ('../pages/businessManagement/AdministratorList/AdministratorDetail'),
                    meta: { title: '管理员设置', keepAlive: false }
                },
                {
                    path: 'WeChatClient',
                    component: () =>
                        import ('../pages/businessManagement/WeChatClient'),
                    meta: { title: '客户企微数据', keepAlive: false }
                },
                {
                    path: 'WeChatClient',
                    component: () =>
                        import ('../pages/businessManagement/WeChatClient'),
                    meta: { title: '客户企微数据', keepAlive: false }
                },
                {
                    path: 'launchProducts',
                    component: () =>
                        import ('../pages/merchandiseManagement/appointment/management'),
                    meta: { title: '发布产品', keepAlive: false }
                },
                {
                    path: 'advancedForm',
                    component: () =>
                        import ('../pages/businessManagement/advancedForm'),
                    meta: { title: '高级表单', keepAlive: false }
                },
                {
                    path: 'performanceAssessment',
                    component: () =>
                        import ('../pages/businessManagement/performanceAssessment'),
                    meta: { title: '企业成员审核', keepAlive: false }
                },
                {
                    path: 'theInspectionRules',
                    component: () =>
                        import ('../pages/businessManagement/theInspectionRules'),
                    meta: { title: '考核规则', keepAlive: false }
                },
                {
                    path: 'theAssessmentDetails',
                    component: () =>
                        import ('../pages/businessManagement/theAssessmentDetails'),
                    meta: { title: '考核详情', keepAlive: false }
                },
                {
                    path: 'publishAdvancedForm',
                    name: 'publishAdvancedForm',
                    component: () =>
                        import ('../pages/businessManagement/publishAdvancedForm'),
                    meta: { title: '新增表单', keepAlive: false }
                },
                {
                    path: 'formDetall',
                    name: 'formDetall',
                    component: () =>
                        import ('../pages/businessManagement/formDetall'),
                    meta: { title: '表单详情', keepAlive: false }
                },
                // {
                // 	path: 'websiteSettings',
                // 	component: () => import('../pages/businessManagement/websiteSettings'),
                // 	meta: { title: '官网设置' }
                // },
                {
                    path: 'advertisingManagement',
                    component: () =>
                        import ('../pages/businessManagement/advertisingManagement'),
                    meta: { title: '广告图管理', keepAlive: false }
                },
                {
                    path: 'setBanner',
                    component: () =>
                        import ('../pages/businessManagement/setBanner'),
                    meta: { title: '广告图设置', keepAlive: false }
                },
                {
                    path: 'orderManagement',
                    component: () =>
                        import ('../pages/orderManagement'),
                    meta: { title: '订单管理', keepAlive: false }
                },
                {
                    path: 'financialStatistics',
                    component: () =>
                        import ('../pages/financialManagement/financialStatistics'),
                    meta: { title: '财务统计', keepAlive: false }
                },
                {
                    path: 'moneyRecord',
                    component: () =>
                        import ('../pages/financialManagement/moneyRecord'),
                    meta: { title: '资金记录', keepAlive: false }
                },
                {
                    path: 'WithdrawalRecord',
                    component: () =>
                        import ('../pages/financialManagement/WithdrawalRecord'),
                    meta: { title: '提现记录', keepAlive: false }
                },
                {
                    path: 'commissionManagement',
                    component: () =>
                        import ('../pages/financialManagement/commissionManagement'),
                    meta: { title: '佣金管理', keepAlive: false }
                },
                {
                    path: 'accountManagement',
                    component: () =>
                        import ('../pages/financialManagement/accountManagement'),
                    meta: { title: '账户管理', keepAlive: false }
                },
                {
                    path: 'moneyForDetails',
                    component: () =>
                        import ('../pages/financialManagement/moneyForDetails'),
                    meta: { title: '公司佣金详情', keepAlive: false }
                },
                {
                    path: 'partnerList',
                    component: () =>
                        import ('../pages/TurnToIntroduce/partnerList'),
                    meta: { title: '合作伙伴列表', keepAlive: false }
                },
                {
                    path: 'partnerListDetailList',
                    component: () =>
                        import ('../pages/TurnToIntroduce/partnerListDetailList'),
                    meta: { title: '列表详情', keepAlive: false }
                },
                {
                    path: 'partnerListDetail',
                    component: () =>
                        import ('../pages/TurnToIntroduce/partnerListDetail'),
                    meta: { title: '合作伙伴详情', keepAlive: false }
                },
                {
                    path: 'rulesSetting',
                    component: () =>
                        import ('../pages/TurnToIntroduce/rulesSetting'),
                    meta: { title: '规则设置', keepAlive: false }
                },
                {
                    path: 'withdrawalList',
                    component: () =>
                        import ('../pages/TurnToIntroduce/withdrawalList'),
                    meta: { title: '赏金提现列表', keepAlive: false }
                },
                {
                    path: 'rulesSetting',
                    component: () =>
                        import ('../pages/TurnToIntroduce/rulesSetting'),
                    meta: { title: '规则设置', keepAlive: false }
                },
                {
                    path: 'agentManage',
                    component: () =>
                        import ('../pages/agentManage/agentManage'),
                    meta: { title: '代理企业', keepAlive: false }
                },
                {
                    path: 'companyDetail',
                    component: () =>
                        import ('../pages/agentManage/companyDetail'),
                    meta: { title: '代理企业详情', keepAlive: false }
                },
                {
                    path: 'personalCommissionDetall',
                    component: () =>
                        import ('../pages/financialManagement/personalCommissionDetall'),
                    meta: { title: '个人佣金详情', keepAlive: false }
                },
                {
                    path: 'moneyManagement',
                    component: () =>
                        import ('../pages/financialManagement/moneyManagement'),
                    meta: { title: '资金管理', keepAlive: false }
                },
                {
                    path: 'management',
                    component: () =>
                        import ('../pages/merchandiseManagement/management'),
                    meta: { title: '商品管理', keepAlive: false }
                },
                {
                    path: 'appointmentManagement',
                    component: () =>
                        import ('../pages/merchandiseManagement/appointmentManagement'),
                    meta: { title: '预约管理', keepAlive: false }
                },
                {
                    path: 'freightManagement',
                    component: () =>
                        import ('../pages/merchandiseManagement/freightManagement'),
                    meta: { title: '运费管理', keepAlive: false }
                },
                {
                    path: 'distributionStatistics',
                    component: () =>
                        import ('../pages/marketingActivities/distribution/distributionStatistics'),
                    meta: { title: '分销统计', keepAlive: false }
                },
                {
                    path: 'personnelApplication',
                    component: () =>
                        import ('../pages/marketingActivities/distribution/personnelApplication'),
                    meta: { title: '人员申请', keepAlive: false }
                },
                {
                    path: 'distributionLevel',
                    component: () =>
                        import ('../pages/marketingActivities/distribution/distributionLevel'),
                    meta: { title: '分销等级', keepAlive: false }
                },
                {
                    path: 'distributionOrder',
                    component: () =>
                        import ('../pages/marketingActivities/distribution/distributionOrder'),
                    meta: { title: '分销订单', keepAlive: false }
                },
                {
                    path: 'accountTransfer',
                    component: () =>
                        import ('../pages/marketingActivities/distribution/accountTransfer'),
                    meta: { title: '到账流水', keepAlive: false }
                },
                {
                    path: 'productList',
                    component: () =>
                        import ('../pages/marketingActivities/joinGroup/productList'),
                    meta: { title: '拼团商品列表', keepAlive: false }
                },
                {
                    path: 'listParticipants',
                    component: () =>
                        import ('../pages/marketingActivities/joinGroup/listParticipants'),
                    meta: { title: '参团列表', keepAlive: false }
                },
                {
                    path: 'assembleOrdersInfo',
                    component: () =>
                        import ('../pages/marketingActivities/joinGroup/assembleOrdersInfo'),
                    meta: { title: '订单详情', keepAlive: false }
                },
                {
                    path: 'addAssemble',
                    component: () =>
                        import ('../pages/marketingActivities/joinGroup/components/addAssemble'),
                    meta: { title: '拼团配置', keepAlive: false }
                },
                {
                    path: 'spikeList',
                    component: () =>
                        import ('../pages/marketingActivities/spike'),
                    meta: { title: '秒杀商品列表', keepAlive: false }
                },
                {
                    path: 'addSpike',
                    component: () =>
                        import ('../pages/marketingActivities/spike/components/addSpike'),
                    meta: { title: '秒杀设置', keepAlive: false }
                },
                {
                    path: 'couponList',
                    component: () =>
                        import ('../pages/marketingActivities/couponManagement/couponList'),
                    meta: { title: '优惠券列表', keepAlive: false }
                },
                {
                    path: 'detailCoupon',
                    component: () =>
                        import ('../pages/marketingActivities/couponManagement/detailCoupon'),
                    meta: { title: '领取详情', keepAlive: false }
                },
                {
                    path: 'news',
                    component: () =>
                        import ('../pages/articleManagement/news'),
                    meta: { title: '新闻资讯', keepAlive: false }
                },
                {
                    path: 'customerArticles',
                    component: () =>
                        import ('../pages/articleManagement/customerArticles'),
                    meta: { title: '获客文章', keepAlive: false }
                },
                {
                    path: 'dynamicTable',
                    component: () =>
                        import ('../pages/articleManagement/dynamicTable'),
                    meta: { title: '动态管理', keepAlive: false }
                },
                {
                    path: 'isDrafts',
                    component: () =>
                        import ('../pages/articleManagement/isDrafts'),
                    meta: { title: '获客文章草稿箱', keepAlive: false }
                },
                {
                    path: 'employeeData',
                    component: () =>
                        import ('../pages/articleManagement/employeeData'),
                    meta: { title: '员工数据', keepAlive: false }
                },
                {
                    path: 'staffDataDetail',
                    component: () =>
                        import ('../pages/articleManagement/components/staffDataDetail'),
                    meta: { title: '员工数据', keepAlive: false }
                },

                {
                    path: 'visitorData',
                    component: () =>
                        import ('../pages/articleManagement/visitorData'),
                    meta: { title: '访客数据', keepAlive: false }
                },
                {
                    path: 'activityRegistration',
                    component: () =>
                        import ('../pages/articleManagement/activityRegistration'),
                    meta: { title: '活动报名管理', keepAlive: false }
                },
                {
                    path: 'addActivity',
                    component: () =>
                        import ('../pages/articleManagement/addActivity'),
                    meta: { title: '编辑活动', keepAlive: false }
                },
                {
                    path: 'activityDetails',
                    component: () =>
                        import ('../pages/articleManagement/activityDetails'),
                    meta: { title: '活动详情', keepAlive: false }
                },
                {
                    path: 'activityEventSupport',
                    component: () =>
                        import ('../pages/articleManagement/activityEventSupport'),
                    meta: { title: '活动赞助', keepAlive: false }
                },
                {
                    path: 'dynamicBasicSetting',
                    component: () =>
                        import ('../pages/articleManagement/dynamicBasicSetting'),
                    meta: { title: '基础设置', keepAlive: false }
                },
                {
                    path: 'videoManage',
                    component: () =>
                        import ('../pages/videoManage'),
                    meta: { title: '短视频管理', keepAlive: false }
                },
                {
                    path: 'myNeeds',
                    component: () =>
                        import ('../pages/addedServices/myNeeds'),
                    meta: { title: '我的需求', keepAlive: false }
                },
                {
                    path: 'demandCheck',
                    component: () =>
                        import ('../pages/addedServices/demandCheck'),
                    meta: { title: '需求审核', keepAlive: false }
                },
                {
                    path: 'demandContactsCheck',
                    component: () =>
                        import ('../pages/addedServices/demandContactsCheck'),
                    meta: { title: '人脉集市审核', keepAlive: false }
                },
                {
                    path: 'demandProtuctsCheck',
                    component: () =>
                        import ('../pages/addedServices/demandProtuctsCheck'),
                    meta: { title: '产品中心审核', keepAlive: false }
                },
                {
                    path: 'demandPublishSetting',
                    component: () =>
                        import ('../pages/addedServices/demandPublishSetting'),
                    meta: { title: '发布设置', keepAlive: false }
                },
                {
                    path: 'demandReport',
                    component: () =>
                        import ('../pages/addedServices/demandReport'),
                    meta: { title: '举报处理', keepAlive: false }
                },
                {
                    path: 'demandCustomize',
                    component: () =>
                        import ('../pages/addedServices/demandCustomize'),
                    meta: { title: '自定义设置', keepAlive: false }
                },
                {
                    path: 'demandPublishForm',
                    component: () =>
                        import ('../pages/addedServices/components/demandPublishForm'),
                    meta: { title: '发布需求', keepAlive: false }
                },
                {
                    path: 'posterAtlas',
                    component: () =>
                        import ('../pages/addedServices/sharingPromotion/posterAtlas'),
                    meta: { title: '海报图集', keepAlive: false }
                },
                {
                    path: 'posterAtlasDetall',
                    component: () =>
                        import ('../pages/addedServices/sharingPromotion/posterAtlasDetall'),
                    meta: { title: '海报图集详情', keepAlive: false }
                },
                {
                    path: 'clockData',
                    component: () =>
                        import ('../pages/addedServices/clockData'),
                    meta: { title: '打卡数据', keepAlive: false }
                },
                {
                    path: 'clockDataDetall',
                    component: () =>
                        import ('../pages/addedServices/clockData/clockDataDetall'),
                    meta: { title: '打卡详情', keepAlive: false }
                },
                {
                    path: 'appletConfig',
                    component: () =>
                        import ('../pages/miniProgram/appletConfig'),
                    meta: { title: '名片配置', keepAlive: false }
                },
                {
                    path: 'appletAd',
                    component: () =>
                        import ('../pages/miniProgram/appletAd'),
                    meta: { title: '广告设置', keepAlive: false }
                },
                {
                    path: 'appletPay',
                    component: () =>
                        import ('../pages/miniProgram/appletPay'),
                    meta: { title: '支付设置', keepAlive: false }
                },
                {
                    path: 'basicSetting',
                    component: () =>
                        import ('../pages/miniProgram/basicSetting'),
                    meta: {
                        title: '基础设置',
                        keepAlive: false
                    }
                },
                {
                    path: 'addCrmAppModel',
                    component: () =>
                        import ('../pages/miniProgram/addAppModel/addCrmAppModel'),
                    meta: {
                        title: '小程序装修',
                        keepAlive: false
                    }
                },
                {
                    path: 'mallSetting',
                    component: () =>
                        import ('../pages/miniProgram/mallSetting'),
                    meta: { title: '商城设置', keepAlive: true }
                },
                {
                    path: "registerWxApply",
                    component: () =>
                        import ("../pages/miniProgram/registerWxApply"),
                    hidden: true,
                    meta: { title: "小程序注册", keepAlive: true }
                },
                {
                    path: "wxApplyDetails",
                    component: () =>
                        import ("../pages/miniProgram/wxApplyDetails"),
                    hidden: true,
                    meta: { title: "小程序详情", keepAlive: true }
                },
                //项目管理
                {
                    path: 'createWord',
                    component: () =>
                        import ('../pages/projectAdmin/createWord'),
                    meta: { title: '生产模式' }
                },
                {
                    path: 'projectAdmin',
                    component: () =>
                        import ('../pages/projectAdmin/projectAdmin'),
                    meta: { title: '项目管理' }
                },
                {
                    path: 'documentAdmin',
                    component: () =>
                        import ('../pages/projectAdmin/documentAdmin'),
                    meta: { title: 'Word管理' }
                },
                {
                    path: 'pdfAdmin',
                    component: () =>
                        import ('../pages/projectAdmin/pdfAdmin'),
                    meta: { title: 'PDF管理' }
                },
                {
                    path: 'projectCompany',
                    component: () =>
                        import ('../pages/projectAdmin/projectCompany'),
                    meta: { title: '研发企业' }
                },
                {
                    path: 'modelAdmin',
                    component: () =>
                        import ('../pages/projectAdmin/modelAdmin'),
                    meta: { title: '模板管理' }
                },
                {
                    path: 'projectUser',
                    component: () =>
                        import ('../pages/projectAdmin/projectUser'),
                    meta: { title: '项目人员' }
                },
                {
                    path: 'allocationChart',
                    component: () =>
                        import ('../pages/projectAdmin/allocationChart'),
                    meta: { title: '人员分配图' }
                },
                {
                    path: 'projectDepartment',
                    component: () =>
                        import ('../pages/projectAdmin/projectDepartment'),
                    meta: { title: '研发部门' }
                },
                /**知识产权 */
                {
                    path: 'projectProperty',
                    component: () =>
                        import ('../pages/projectProperty/index'),
                    meta: { title: '知识产权' }
                },
                /**加计扣除 */
                {
                    path: 'report',
                    component: () =>
                        import ('../pages/cost/report'),
                    meta: { title: '辅助账报表生成' }
                },
                {
                    path: 'costAdjustment',
                    component: () =>
                        import ('../pages/cost/costAdjustment'),
                    meta: { title: '研发费用调整项录入' }
                },
                {
                    path: 'costEnterTable1',
                    component: () =>
                        import ('../pages/cost/components/costEnterTable1'),
                    meta: { title: '人员人工费用' }
                },
                {
                    path: 'costEnterTable2',
                    component: () =>
                        import ('../pages/cost/components/costEnterTable2'),
                    meta: { title: '直接投入费用' }
                },
                {
                    path: 'costEnterTable3',
                    component: () =>
                        import ('../pages/cost/components/costEnterTable3'),
                    meta: { title: '折旧费用' }
                },
                {
                    path: 'costEnterTable4',
                    component: () =>
                        import ('../pages/cost/components/costEnterTable4'),
                    meta: { title: '无形资产摊销' }
                },
                {
                    path: 'costEnterTable5',
                    component: () =>
                        import ('../pages/cost/components/costEnterTable5'),
                    meta: { title: '新产品设计费等' }
                },
                {
                    path: 'costEnterTable6',
                    component: () =>
                        import ('../pages/cost/components/costEnterTable6'),
                    meta: { title: '其他相关费用' }
                },
                {
                    path: 'costEnterTable7',
                    component: () =>
                        import ('../pages/cost/components/costEnterTable7'),
                    meta: { title: '委托外部机构或个人进行研发活动所发生的费用' }
                },
                {
                    path: 'costEnter',
                    component: () =>
                        import ('../pages/cost/costEnter'),
                    meta: { title: '研发费用逐项录入' }
                },
                {
                    path: 'assistrecord',
                    component: () =>
                        import ('../pages/cost/assistrecord'),
                    meta: { title: '研发支出辅助账' }
                },
                {
                    path: 'researchCosts',
                    component: () =>
                        import ('../pages/cost/researchCosts'),
                    meta: { title: '研发费归集表' }
                },
                {
                    path: 'personnelAllotment',
                    component: () =>
                        import ('../pages/personnelAllotment/index'),
                    meta: { title: '研发费分配说明' }
                },
                {
                    path: 'voucherInfo',
                    component: () =>
                        import ('../pages/cost/voucherInfo'),
                    meta: { title: '凭证信息录入' }
                },
                {
                    path: 'startMoney',
                    component: () =>
                        import ('../pages/cost/startMoney'),
                    meta: { title: '期初余额设置' }
                },
                //组织管理制度1
                {
                    path: 'organizationalManagement911',
                    component: () =>
                        import ('../pages/organizationalManagement/institution911'),
                    meta: { title: '管理制度9.1-初次认定' }
                },
                {
                    path: 'organizationalManagement912',
                    component: () =>
                        import ('../pages/organizationalManagement/institution912'),
                    meta: { title: '管理制度9.2-初次认定' }
                },
                {
                    path: 'organizationalManagement913',
                    component: () =>
                        import ('../pages/organizationalManagement/institution913'),
                    meta: { title: '管理制度9.3-初次认定' }
                },
                {
                    path: 'organizationalManagement914',
                    component: () =>
                        import ('../pages/organizationalManagement/institution914'),
                    meta: { title: '管理制度9.4-初次认定' }
                },
                //组织管理制度-初次认定2
                {
                    path: 'organizationalManagement921',
                    component: () =>
                        import ('../pages/organizationalManagement/institution921'),
                    meta: { title: '管理制度9.1-重新认定' }
                },
                {
                    path: 'organizationalManagement922',
                    component: () =>
                        import ('../pages/organizationalManagement/institution922'),
                    meta: { title: '管理制度9.2-重新认定' }
                },
                {
                    path: 'organizationalManagement923',
                    component: () =>
                        import ('../pages/organizationalManagement/institution923'),
                    meta: { title: '管理制度9.3-重新认定' }
                },
                {
                    path: 'organizationalManagement924',
                    component: () =>
                        import ('../pages/organizationalManagement/institution924'),
                    meta: { title: '管理制度9.4-重新认定' }
                },
                // {
                // 	path:'mallTemplate',
                // 	component: () => import('../pages/miniProgram/mallTemplate'),
                // 	meta: { title: '商城模板设置',keepAlive: false }
                // },
                {
                    path: 'templateA',
                    component: () =>
                        import ('../pages/miniProgram/templateA'),
                    meta: { title: '综合电商', keepAlive: true }
                },
                {
                    path: 'templateB',
                    component: () =>
                        import ('../pages/miniProgram/templateB'),
                    meta: { title: '节日优惠', keepAlive: true }
                },
                {
                    path: 'templateC',
                    component: () =>
                        import ('../pages/miniProgram/templateC'),
                    meta: { title: '日用百货', keepAlive: true }
                },
                {
                    path: 'templateD',
                    component: () =>
                        import ('../pages/miniProgram/templateD'),
                    meta: { title: '数码家电', keepAlive: true }
                },

                {
                    path: 'goodsDetails',
                    component: () =>
                        import ('../pages/merchandiseManagement/goodsDetails'),
                    meta: { title: '商品详情', keepAlive: false }
                },
                {
                    path: 'redactOrAdd',
                    component: () =>
                        import ('../pages/merchandiseManagement/redactOrAdd'),
                    meta: { title: '商品发布', keepAlive: false }
                },
                //分销会员
                {
                    path: "parkMember",
                    component: () =>
                        import ("../pages/propertyServer/parkMember/member"),
                    meta: { title: "会员管理", keepAlive: false }
                },
                {
                    path: "parkMemberLevel",
                    component: () =>
                        import ("../pages/propertyServer/parkMember/memberLevel"),
                    meta: { title: "会员等级", keepAlive: false }
                },
                {
                    path: "parkMemberDistributor",
                    component: () =>
                        import ("../pages/propertyServer/parkMember/memberDistributor"),
                    meta: { title: "分销设置", keepAlive: false }
                },
                {
                    path: "parkMemberDistributorRecord",
                    component: () =>
                        import ("../pages/propertyServer/parkMember/memberDistributorRecord"),
                    meta: { title: "分销记录", keepAlive: false }
                },
                {
                    path: "parkIntegralRecord",
                    component: () =>
                        import ("../pages/propertyServer/parkMember/integralRecord"),
                    meta: { title: "积分记录", keepAlive: false }
                },
                {
                    path: "parkMemberTask",
                    component: () =>
                        import ("../pages/propertyServer/parkMember/memberTask"),
                    meta: { title: "会员任务", keepAlive: false }
                },
                {
                    path: "parkMemberOrder",
                    component: () =>
                        import ("../pages/propertyServer/parkMember/memberOrder"),
                    meta: { title: "会员订单", keepAlive: false }
                },
                //互通管理
                {
                    path: "parkCompanyGoodsLink",
                    component: () =>
                        import ("../pages/propertyServer/interflow/companyGoodsLink.vue"),
                    meta: { title: "商品互通", keepAlive: false }
                },
                {
                    path: "parkCompanyOrderGoodsLink",
                    component: () =>
                        import ("../pages/propertyServer/interflow/companyOrderGoodsLink.vue"),
                    meta: { title: "预约商品", keepAlive: false }
                },
                //提现管理
                {
                    path: 'parkWithdrawal',
                    component: () =>
                        import ('../pages/propertyServer/withdrawal'),
                    meta: { title: '提现管理', keepAlive: false }
                },
                //园区管理
                {
                    path: 'parkManagement',
                    component: () =>
                        import ('../pages/propertyServer/parkManagement/parkManagementTable'),
                    meta: { title: '园区管理', keepAlive: false }
                },
                {
                    path: 'parkServices',
                    component: () =>
                        import ('../pages/propertyServer/parkServices/parkServicesTable'),
                    meta: { title: '园区动态', keepAlive: false }
                },
                {
                    path: 'parkDynamicType',
                    component: () =>
                        import ('../pages/propertyServer/parkDynamicType/parkDynamicTypeTable'),
                    meta: { title: '动态分类', keepAlive: false }
                },
                {
                    path: 'parkCompany',
                    component: () =>
                        import ('../pages/propertyServer/parkCompany/parkCompanyTable'),
                    meta: { title: '园区企业', keepAlive: false }
                },
                {
                    path: 'parkCard',
                    component: () =>
                        import ('../pages/propertyServer/parkCard/parkCardTable'),
                    meta: { title: '园区名片', keepAlive: false }
                },
                //园区活动
                {
                    path: 'parkActivity',
                    component: () =>
                        import ('../pages/propertyServer/activity/activityTable'),
                    meta: { title: '活动报名管理', keepAlive: false }
                },
                {
                    path: 'addParkActivity',
                    component: () =>
                        import ('../pages/propertyServer/activity/addActivity'),
                    meta: { title: '编辑活动', keepAlive: false }
                },
                {
                    path: 'parkActivityDetails',
                    component: () =>
                        import ('../pages/propertyServer/activity/activityDetails'),
                    meta: { title: '活动详情', keepAlive: false }
                },
                {
                    path: 'parkOrderList',
                    component: () =>
                        import ('../pages/propertyServer/orderList/index'),
                    meta: { title: '订单管理', keepAlive: false }
                },
                //进驻商家
                {
                    path: 'parkIntoCompany',
                    component: () =>
                        import ('../pages/propertyServer/parkIntoCompany/parkIntoCompanyTable'),
                    meta: { title: '合作商家', keepAlive: false }
                },
                {
                    path: 'applyParkIntoCompany',
                    component: () =>
                        import ('../pages/propertyServer/applyParkIntoCompany/applyParkIntoCompanyTable'),
                    meta: { title: '进驻审核', keepAlive: false }
                },
                //公司商品
                {
                    path: 'parkProduct',
                    component: () =>
                        import ('../pages/propertyServer/parkProduct/parkProductTable'),
                    meta: { title: '公司商品', keepAlive: false }
                },
                //物业服务
                {
                    path: 'houseSpace',
                    component: () =>
                        import ('../pages/propertyServer/houseSpace/houseSpaceTable'),
                    meta: { title: '房屋部面图', keepAlive: false }
                },
                {
                    path: 'propertyThing',
                    component: () =>
                        import ('../pages/propertyServer/propertyThing/propertyThingTable'),
                    meta: { title: '物业报修', keepAlive: false }
                },
                {
                    path: 'propertyStyle',
                    component: () =>
                        import ('../pages/propertyServer/propertyStyle/propertyStyleTable'),
                    meta: { title: '物业风采', keepAlive: false }
                },
                {
                    path: 'building',
                    component: () =>
                        import ('../pages/propertyServer/building/buildingTable'),
                    meta: { title: '楼宇管理', keepAlive: false }
                },
                // {
                //     path: 'property',
                //     component: () =>
                //         import ('../pages/propertyServer/property/propertyTable'),
                //     meta: { title: '物业管理', keepAlive: false }
                // },
                {
                    path: 'house',
                    component: () =>
                        import ('../pages/propertyServer/house/index'),
                    meta: { title: '房屋管理', keepAlive: false }
                },
                {
                    path: 'buildHouse',
                    hidden: true,
                    component: () =>
                        import ('../pages/propertyServer/house/checkTable'),
                    meta: { title: '查看房屋', keepAlive: false }
                },
                {
                    path: 'rentalManagement',
                    component: () =>
                        import ('../pages/propertyServer/rentalManagement/rentalManagementTable'),
                    meta: { title: '出租管理', keepAlive: false }
                },
                // {
                //     path: 'buildHouse',
                //     hidden: true,
                //     component: () =>
                //         import ('../pages/propertyServer/house/pages/houseTable'),
                //     meta: { title: '查看房屋', keepAlive: false }
                // },
                {
                    path: 'meeting',
                    component: () =>
                        import ('../pages/propertyServer/meeting/meetingTable'),
                    meta: { title: '会议室管理', keepAlive: false }
                },
                {
                    path: 'meetingUser',
                    component: () =>
                        import ('../pages/propertyServer/meeting/meetingUser'),
                    meta: { title: '会议室预约', keepAlive: false }
                },
                {
                    path: 'household',
                    component: () =>
                        import ('../pages/propertyServer/household/index'),
                    meta: { title: '住户管理', keepAlive: false }
                },
                {
                    path: 'qrCodeodeManagement',
                    component: () =>
                        import ('../pages/propertyServer/qrCodeodeManagement/qrCodeodeManagementTable'),
                    meta: { title: '二维码管理', keepAlive: false }
                },
                //园区报修
                {
                    path: 'repairType',
                    component: () =>
                        import ('../pages/propertyServer/repair/typeTable'),
                    meta: { title: '服务分类', keepAlive: false }
                },
                {
                    path: 'repairInfo',
                    component: () =>
                        import ('../pages/propertyServer/repair/infoTable'),
                    meta: { title: '信息管理', keepAlive: false }
                },
                {
                    path: 'repair',
                    component: () =>
                        import ('../pages/propertyServer/repair/repairTable'),
                    meta: { title: '手工报修', keepAlive: false }
                },
                {
                    path: 'repairCost',
                    component: () =>
                        import ('../pages/propertyServer/repair/costTable'),
                    meta: { title: '维修费用', keepAlive: false }
                },
                {
                    path: 'repairManage',
                    component: () =>
                        import ('../pages/propertyServer/repair/manageTable'),
                    meta: { title: '园区接收员', keepAlive: false }
                },
                {
                    path: 'repairPManage',
                    component: () =>
                        import ('../pages/propertyServer/repair/pManageTable'),
                    meta: { title: '园区接收员', keepAlive: false }
                },
                //智能门禁
                {
                    path: 'guardInfo',
                    component: () =>
                        import ('../pages/propertyServer/guard/infoTable'),
                    meta: { title: '基本设置', keepAlive: false }
                },
                {
                    path: 'guardGrouping',
                    component: () =>
                        import ('../pages/propertyServer/guard/groupingTable'),
                    meta: { title: '门禁分组', keepAlive: false }
                },
                {
                    path: 'guard',
                    component: () =>
                        import ('../pages/propertyServer/guard/guardTable'),
                    meta: { title: '门禁管理', keepAlive: false }
                },
                {
                    path: 'guardUser',
                    component: () =>
                        import ('../pages/propertyServer/guard/userTable'),
                    meta: { title: '用户管理', keepAlive: false }
                },
                {
                    path: 'guardFaceAuthorization',
                    component: () =>
                        import ('../pages/propertyServer/guard/faceAuthorization'),
                    meta: { title: '人脸授权', keepAlive: false }
                },
                {
                    path: 'guardFaceRecord',
                    component: () =>
                        import ('../pages/propertyServer/guard/faceRecord'),
                    meta: { title: '人脸记录', keepAlive: false }
                },
                {
                    path: 'guardSendCards',
                    component: () =>
                        import ('../pages/propertyServer/guard/sendCardsTable'),
                    meta: { title: '发卡管理', keepAlive: false }
                },
                {
                    path: 'guardCardSwipingRecord',
                    component: () =>
                        import ('../pages/propertyServer/guard/cardSwipingRecordTable'),
                    meta: { title: '刷卡记录', keepAlive: false }
                },
                {
                    path: 'guardDoorOpeningRecord',
                    component: () =>
                        import ('../pages/propertyServer/guard/doorOpeningRecordTable'),
                    meta: { title: '开门记录', keepAlive: false }
                },
                {
                    path: 'guardDoorOpeningStatistics',
                    component: () =>
                        import ('../pages/propertyServer/guard/doorOpeningStatisticsTable'),
                    meta: { title: '开门统计', keepAlive: false }
                },
                //智能禁车
                {
                    path: 'parksChargingStandard',
                    component: () =>
                        import ('../pages/propertyServer/parks/chargingStandard/index'),
                    meta: { title: '收费标准', keepAlive: false }
                },
                {
                    path: 'parksParkingLotManagement',
                    component: () =>
                        import ('../pages/propertyServer/parks/parkingLotManagementTable'),
                    meta: { title: '车场管理', keepAlive: false }
                },
                {
                    path: 'parksDeviceManagement',
                    component: () =>
                        import ('../pages/propertyServer/parks/deviceManagementTable'),
                    meta: { title: '设备管理', keepAlive: false }
                },
                {
                    path: 'parksLaneManagement',
                    component: () =>
                        import ('../pages/propertyServer/parks/laneManagementTable'),
                    meta: { title: '车道管理', keepAlive: false }
                },
                {
                    path: 'parksPreferentialManagement',
                    component: () =>
                        import ('../pages/propertyServer/parks/preferentialManagement/index'),
                    meta: { title: '优惠管理', keepAlive: false }
                },
                {
                    path: 'parksParkingSpaceManagement',
                    component: () =>
                        import ('../pages/propertyServer/parks/parkingSpaceManagementTable'),
                    meta: { title: '车位管理', keepAlive: false }
                },
                {
                    path: 'parksVehicleManagement',
                    component: () =>
                        import ('../pages/propertyServer/parks/vehicleManagement/index'),
                    meta: { title: '车辆管理', keepAlive: false }
                },
                {
                    path: 'parksDataRecord',
                    component: () =>
                        import ('../pages/propertyServer/parks/dataRecord/index'),
                    meta: { title: '数据记录', keepAlive: false }
                },
                {
                    path: 'parksFinancialCenter',
                    component: () =>
                        import ('../pages/propertyServer/parks/financialCenter/index'),
                    meta: { title: '财务中心', keepAlive: false }
                },
                //安防巡更
                {
                    path: 'securityPatrolPatrolDistribution',
                    component: () =>
                        import ('../pages/propertyServer/securityPatrol/patrolDistribution/index'),
                    meta: { title: '巡更分布', keepAlive: false }
                },
                {
                    path: 'securityPatrolPatrolRoute',
                    component: () =>
                        import ('../pages/propertyServer/securityPatrol/patrolRouteTable'),
                    meta: { title: '巡更路线', keepAlive: false }
                },
                {
                    path: 'securityPatrolDeviceManagement',
                    component: () =>
                        import ('../pages/propertyServer/securityPatrol/deviceManagementTable'),
                    meta: { title: '设备管理', keepAlive: false }
                },
                //小程序设置
                {
                    path: 'addParkAppModel',
                    component: () =>
                        import ('../pages/miniProgram/addAppModel/addParkAppModel'),
                    meta: {
                        title: '小程序装修',
                        keepAlive: false
                    }
                },
                //广告管理
                {
                    path: 'parkAdvertisement',
                    component: () =>
                        import ('../pages/propertyServer/advertisement/advertisementTable'),
                    meta: { title: '广告管理', keepAlive: false }
                },
                //社团端
                //分销会员
                {
                    path: "associationMember",
                    component: () =>
                        import ("../pages/association/associationMember/member"),
                    meta: { title: "会员管理", keepAlive: false }
                },
                {
                    path: "associationMemberLevel",
                    component: () =>
                        import ("../pages/association/associationMember/memberLevel"),
                    meta: { title: "会员等级", keepAlive: false }
                },
                {
                    path: "associationIntegralRecord",
                    component: () =>
                        import ("../pages/association/associationMember/integralRecord"),
                    meta: { title: "积分记录", keepAlive: false }
                },
                {
                    path: "associationMemberTask",
                    component: () =>
                        import ("../pages/association/associationMember/memberTask"),
                    meta: { title: "会员任务", keepAlive: false }
                },
                {
                    path: "associationMemberOrder",
                    component: () =>
                        import ("../pages/association/associationMember/memberOrder"),
                    meta: { title: "会员订单", keepAlive: false }
                },
                {
                    path: "associationMemberDistributor",
                    component: () =>
                        import ("../pages/association/associationMember/memberDistributor"),
                    meta: { title: "分销设置", keepAlive: false }
                },
                {
                    path: "associationMemberDistributorRecord",
                    component: () =>
                        import ("../pages/association/associationMember/memberDistributorRecord"),
                    meta: { title: "分销记录", keepAlive: false }
                },
                {
                    path: "associationRightsAndInterests",
                    component: () =>
                        import ("../pages/association/associationMember/rightsAndInterests"),
                    meta: { title: "会员权益", keepAlive: false }
                },
                //互通管理
                {
                    path: "associationCompanyGoodsLink",
                    component: () =>
                        import ("../pages/association/interflow/companyGoodsLink.vue"),
                    meta: { title: "商品互通", keepAlive: false }
                },
                {
                    path: "associationCompanyOrderGoodsLink",
                    component: () =>
                        import ("../pages/association/interflow/companyOrderGoodsLink.vue"),
                    meta: { title: "预约商品", keepAlive: false }
                },
                //提现管理
                {
                    path: 'associationWithdrawal',
                    component: () =>
                        import ('../pages/association/withdrawal'),
                    meta: { title: '提现管理', keepAlive: false }
                },
                //社团管理
                {
                    path: 'association',
                    component: () =>
                        import ('../pages/association/association/associationTable'),
                    meta: { title: '社团管理', keepAlive: false }
                },
                {
                    path: 'associationTermSetting',
                    component: () =>
                        import ('../pages/association/termSetting/termSetting'),
                    meta: { title: '条款设置', keepAlive: false }
                },
                {
                    path: "associationDynamic",
                    component: () =>
                        import ("../pages/association/dynamic/dynamicTable"),
                    meta: { title: "社团动态", keepAlive: false }
                },
                {
                    path: "associationDynamicType",
                    component: () =>
                        import ("../pages/association/dynamicType/dynamicTypeTable"),
                    meta: { title: "动态分类", keepAlive: false }
                },
                //订单管理
                {
                    path: 'associationOrderList',
                    component: () =>
                        import ('../pages/association/orderList/index'),
                    meta: { title: '订单管理', keepAlive: false }
                },
                //用户中心
                {
                    path: 'userList',
                    component: () =>
                        import ('../pages/association/user/userTable'),
                    meta: { title: '用户列表', keepAlive: false }
                },

                //社团活动
                //活动管理
                {
                    path: 'associationActivity',
                    component: () =>
                        import ('../pages/association/activity/activityTable'),
                    meta: { title: '活动报名管理', keepAlive: false }
                },
                {
                    path: 'addAssociationActivity',
                    component: () =>
                        import ('../pages/association/activity/addActivity'),
                    meta: { title: '编辑活动', keepAlive: false }
                },
                {
                    path: 'associationActivityDetails',
                    component: () =>
                        import ('../pages/association/activity/activityDetails'),
                    meta: { title: '活动详情', keepAlive: false }
                },
                //共享空间
                {
                    path: 'associationSpaces',
                    component: () =>
                        import ('../pages/association/spaces/spacesTable'),
                    meta: { title: '共享空间', keepAlive: false }
                },
                //商品管理
                {
                    path: 'memberProduct',
                    component: () =>
                        import ('../pages/association/memberProduct/memberProductTable'),
                    meta: { title: '会员套餐', keepAlive: false }
                },
                {
                    path: 'associationProduct',
                    component: () =>
                        import ('../pages/association/associationProduct/associationProductTable'),
                    meta: { title: '会员商品', keepAlive: false }
                },
                {
                    path: "associationCooperativeMerchants",
                    component: () =>
                        import ("../pages/association/cooperativeMerchants/cooperativeMerchantsTable"),
                    meta: { title: "合作商家", keepAlive: false }
                },
                {
                    path: "associationEnterpriseServiceMerchants",
                    component: () =>
                        import ("../pages/association/enterpriseServiceMerchants/enterpriseServiceMerchantsTable"),
                    meta: { title: "企服商家", keepAlive: false }
                },
                {
                    path: "applyAssociationIntoCompany",
                    component: () =>
                        import ("../pages/association/applyAssociationIntoCompany/applyAssociationIntoCompanyTable"),
                    meta: { title: "商家审核", keepAlive: false }
                },
                //会员中心
                {
                    path: 'memberList',
                    component: () =>
                        import ('../pages/association/member/memberTable'),
                    meta: { title: '会员列表', keepAlive: false }
                },
                {
                    path: 'membershipApplication',
                    component: () =>
                        import ('../pages/association/membershipApplication/membershipApplicationTable'),
                    meta: { title: '入团审核', keepAlive: false }
                },
                {
                    path: 'memberAttribute',
                    component: () =>
                        import ('../pages/association/memberAttribute/memberAttributeTable'),
                    meta: { title: '会员属性', keepAlive: false }
                },
                {
                    path: 'memberType',
                    component: () =>
                        import ('../pages/association/memberType/memberTypeTable'),
                    meta: { title: '会员类型', keepAlive: false }
                },
                //小程序设置
                {
                    path: 'addAssociationAppModel',
                    component: () =>
                        import ('../pages/miniProgram/addAppModel/addAssociationAppModel'),
                    meta: {
                        title: '小程序装修',
                        keepAlive: false
                    }
                },
                //广告管理
                {
                    path: 'associationAdvertisement',
                    component: () =>
                        import ('../pages/association/advertisement/advertisementTable'),
                    meta: { title: '广告管理', keepAlive: false }
                },
                // {
                //     path: 'memberType',
                //     component: () =>
                //         import ('../pages/association/memberType/memberTypeTable'),
                //     meta: { title: '会员类型', keepAlive: false }
                // },
                //系统设置
                {
                    path: 'associationWalletIntercommunication',
                    component: () =>
                        import ('../pages/association/walletIntercommunication/walletIntercommunicationTable'),
                    meta: { title: '钱包互通', keepAlive: false }
                },
                {
                    path: 'associationHighForm',
                    component: () =>
                        import ('../pages/association/highForm/highFormTable'),
                    meta: { title: '高级表单', keepAlive: false }
                },
                {
                    path: 'associationWxArticleRelease',
                    component: () =>
                        import ('../pages/association/wxArticleRelease/wxArticleReleaseTable'),
                    meta: { title: '公众号设置', keepAlive: false }
                },
                {
                    path: '/getUploadImg',
                    component: () =>
                        import ('../pages/getUploadImg'),
                    meta: { title: '图片上传' }
                },
                {
                    path: '/test',
                    component: () =>
                        import ('../pages/test'),
                    meta: { title: '测试页面' }
                },
            ]
        },
        {
            path: '/login',
            component: () =>
                import ('../pages/login'),
            meta: { title: '登录' }
        },
        {
            path: "*",
            redirect: "/dashboard"
        }
    ]
})