<template>
  <div class="custommodulestyle">
    <!-- 标题 -->
    <h2>{{ datas.text }}</h2>
    <el-form label-width="80px" >
      <el-form-item label="标题">
        <el-input v-model="datas.demo" placeholder="请输入标题" />
      </el-form-item>
      <el-form-item label="图片高度">
        <el-slider v-model="datas.imgHeight" :max="400" :min="50" input-size="mini" show-input>
        </el-slider>
      </el-form-item>
    </el-form>
    <el-upload class="imgFile" :action="$store.state.uploadingUrl" list-type="picture-card"
      :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="linkmanImgProgress" :on-success="linkmanImgSuccess"
      :on-remove="linkmanImgRemove" :file-list="imgFile">
      <i class="el-icon-plus"></i>
    </el-upload>

  </div>
</template>

<script>
export default {
  name: 'custommodulestyle',
  props: {
    datas: Object,
    'data-type': String,
  },
  data() {
    return {
      imgFile: []
    }
  },
  computed: {
    uploadDisabled() {
      return this.datas.img !== "";
    },
  },
  created() {
    this.imgFile = [{
      url: this.datas.img
    }]
  },
  methods: {
    /**@method 删除图片 */
    linkmanImgRemove(file, fileList) {
      this.datas.img = "";
      this.imgFile = [];
    },
    /**@method 图片上传 */
    linkmanImgProgress(event, file, fileList) {

    },
    /**@method 上传回调 */
    linkmanImgSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.$set(this.datas, "img", `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`);
    },
  },
}
</script>
<style>
.imgFile .el-upload {
  height: 150px;
  line-height: 150px;
  width: 100%;
}

.imgFile .el-upload-list__item {
  height: 150px;
  line-height: 150px;
  width: 100%;
}

.imgFile .el-icon-check {
  position: absolute;
  right: 15px;

}
</style>
<style scoped lang="scss">
.custommodulestyle {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 10px 20px;
  box-sizing: border-box;

  /* 标题 */
  h2 {
    padding: 24px 16px 24px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #f2f4f6;
    font-size: 18px;
    font-weight: 600;
    color: #323233;
  }
}
</style>
