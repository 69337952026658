<template>
  <div class="entertheshop">
    <!-- 内容 -->
    <!-- <van-cell :icon="datas.icon" :title="datas.shopName" is-link :value="datas.copywriting"
      :to="datas.type == 10 ? datas.http.id : ''" :url="datas.type == 11 ? datas.http.id : ''" /> -->
    <div class="cells flex-a-b-c" :class="{'eShadow':datas.isShadow}">
      <div class="flex-c-c"><el-image class="mr10" v-if="datas.icon" :src="datas.icon" :style="{height:'25px',width:'25px'}"></el-image> {{ datas.shopName }}</div>
      <div class="more">{{ datas.copywriting }}<van-icon name="arrow" /></div>
    </div>
    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
export default {
  name: 'entertheshop',
  props: {
    datas: Object,
  },
}
</script>

<style scoped lang="scss">
.entertheshop {
  position: relative;
}
.eShadow{
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
}
.cells {
  background: #fff;
  padding: 10px;
  height: 45px;
  line-height: 25px;
  .more{
    color:#969799;
  }
}
</style>
