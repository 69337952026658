<template>
    <div class="videostyle">
        <!-- 标题 -->
        <h2>{{ datas.text }}</h2>

        <el-form label-width="70px" :model="datas" size="small" class="lef">
            <el-form-item label="标题" class="lef">
                <el-input v-model="datas.title"></el-input>
            </el-form-item>

            <el-form-item label="背景颜色" class="lef">
                <el-color-picker v-model="datas.backgroundColor" show-alpha class="picke" :predefine="predefineColors">
                </el-color-picker>
            </el-form-item>
            <el-form-item label="边框倒角" class="lef borrediu">
                <el-slider v-model="datas.borderRadius" :max="30" input-size="mini" show-input>
                </el-slider>
            </el-form-item>
            <el-form-item class="lef" label="详情">
                {{ datas.showDetalis ? '显示' : '隐藏' }}
                <el-checkbox style="margin-left: 196px" v-model="datas.showDetalis" />
            </el-form-item>
            <!-- <el-form-item class="lef" label="详情链接">
                <parkJumpLink :linkData="datas.http" @changeFun="jumpLinkChange($event)" />
            </el-form-item> -->
        </el-form>
    </div>
</template>
  
<script>
export default {
    name: 'parkInfostyle',
    props: {
        datas: Object,
    },
    data() {
        return {
            imgFile: [],
            predefineColors: [
                // 颜色选择器预设
                "#fff",
                "#000",
                "#3064ba",
                '#51CDCB',
                '#ff4500',
                '#ff8c00',
                '#ffd700',
                '#90ee90',
                '#00ced1',
                '#1e90ff',
                '#c71585',
                '#409EFF',
                '#909399',
                '#C0C4CC',
                'rgba(255, 69, 0, 0.68)',
                'rgb(255, 120, 0)',
                'hsv(51, 100, 98)',
                'hsva(120, 40, 94, 0.5)',
                'hsl(181, 100%, 37%)',
                'hsla(209, 100%, 56%, 0.73)',
                '#c7158577',
            ],
        }
    },
    computed: {
        uploadDisabled() {
            return this.datas.coverUrl !== "";
        },
    },
    created() {
        if (this.datas.coverUrl) {
            this.imgFile = [{
                url: this.datas.coverUrl
            }]
        }
    },
    methods: {
        jumpLinkChange(data) {
            if (data.type) {
                let { urlType, type, id, title } = data;
                this.datas.http = {
                    urlType,
                    type,
                    id,
                    title
                };
            } else {
                this.datas.http = {};
            }
        },
        handleVideoSuccess(res) {
            this.$message.closeAll();
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.$set(this.datas, "src", `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${res.data}`);
        },
        /**@method 删除图片 */
        linkmanImgRemove(file, fileList) {
            this.datas.coverUrl = "";
            this.imgFile = [];
        },
        /**@method 图片上传 */
        linkmanImgProgress(event, file, fileList) {

        },
        /**@method 上传回调 */
        linkmanImgSuccess(response, file, fileList) {
            this.$message.closeAll();
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.$set(this.datas, "coverUrl", `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`);
        },
    },
}
</script>
<style>
.imgFile .el-upload {
    height: 150px;
    line-height: 150px;
    width: 100%;
}

.imgFile .el-upload-list__item {
    height: 150px;
    line-height: 150px;
    width: 100%;
}

.imgFile .el-icon-check {
    position: absolute;
    right: 15px;

}
</style>
<style scoped lang="scss">
.avatar-uploader {
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    background-color: #fbfdff;
    height: 150px;
    overflow: hidden;
}


.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 250px;
    height: 150px;
    line-height: 150px;
    text-align: center;
}

.select-goods-list {
    margin-bottom: 20px;
    border-radius: 40px;
    box-sizing: border-box;
    padding: 0 20px;
    background: #ffffff;
    transition: all 0.4s;
}

.select-goods-list:hover {
    background: #f5f5f6;
    transition: all 0.4s;
    cursor: pointer;
}

.videostyle {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 10px 20px;
    box-sizing: border-box;

    /* 标题 */
    h2 {
        padding: 24px 16px 24px 0;
        margin-bottom: 15px;
        border-bottom: 1px solid #f2f4f6;
        font-size: 18px;
        font-weight: 600;
        color: #323233;
    }

    .lef {
        /deep/.el-form-item__label {
            text-align: left;
        }
    }

    /* 刷新 */
    .link {
        display: inline-block;
        padding: 0 10px;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        cursor: pointer;
        color: #51CDCB;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}
</style>
  