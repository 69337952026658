<template>
    <div class="gptContainer">
        <div class="gptModel" v-if="gptmodelConfig.showType == 'big'">
            <div class="chatTitle flex-a-b-c" @mousedown.stop="startMove">
                <div class="text-overflow w340">{{ gptTitle ? gptTitle : "AI人工智能 ChatGPT" }}</div>
                <div class="close">
                    <el-image class="h20 w20 mr10" :src="dinzion" v-if="openPosition"
                        @click="openPosition = false;"></el-image>
                    <el-image class="h20 w20 mr10" :src="dingzi" v-else @click="openPosition = true;"></el-image>
                    <el-image class="h20 w20 mr10" :src="zuixiaohua" @click="showHideGptModel('small')"></el-image>
                    <el-image class="h20 w20" :src="zuizhonghua" v-if="gptmodelConfig.showType == 'big'"
                        @click="showHideGptModel('centre')"></el-image>
                    <el-image class="h20 w20" :src="zuidahua" v-else @click="showHideGptModel('big')"></el-image>
                </div>
            </div>
            <div class="chatContent scrollbar_none">
                <div v-for="(row, index) in messageData" :key="index">
                    <div class="chatRow left" v-if="row.type == 'left'">
                        <div class="h40 w40"></div>
                        <div class="GPTAvatar">
                            <el-image class="avatar" :class="{ 'hide': row.code != item.code }" :src="item.logo"
                                v-for="(item, index) in gptData" :key="index"></el-image>
                            <!-- <el-image class="avatar" :src="XFIcon" v-if="row.code == 301"></el-image>
                            <el-image class="avatar" :src="WXIcon" v-if="row.code == 302"></el-image> -->
                        </div>
                        <div class="chatInfo" v-if="row.status == 0">
                            <i class="el-icon-loading"></i>
                        </div>
                        <div class="chatInfo" title="点击填入" v-if="row.status == 1" @click="writeContent(row.message)"
                            v-html="row.html">

                        </div>
                    </div>
                    <div class="chatRow right" v-if="row.type == 'right'">
                        <div class="chatInfo" v-if="row.status == 0">
                            <i class="el-icon-loading"></i>
                        </div>
                        <div class="chatInfo" v-if="row.status == 1" v-html="row.message">
                        </div>
                        <el-image class="avatar" :src="userInfo.avatarUrl"></el-image>
                    </div>
                </div>
            </div>
            <div class="chatSend" @keyup="changeMessage" @keydown.shift="isSendMessgage = false"
                @mousedown.stop="startMove">
                <el-input class="chatInput" type="textarea" v-model="message"></el-input>
                <div>
                    <el-button class="twBtn" type="primary" @click="sendMessage()" :loading="messageBtnLoading">提
                        问</el-button>
                    <hr class="mb5 mt5">
                    <el-button class="twBtn" @click="withdrawFun()">清 空</el-button>
                </div>

            </div>
        </div>
        <div class="reduceGpt" @mousedown.stop="startMove" :class="gptmodelConfig.showType"
            v-if="gptmodelConfig.showType == 'centre' || gptmodelConfig.showType == 'small'">
            <div class="minGPT flex-c-c" @click.stop="showIcon = !showIcon">
                <el-image class="avatar" :class="{ 'hide': gptType != row.code }" :src="row.logo"
                    v-for="(row, index) in gptData" :key="index"></el-image>
                <!-- <el-image class="avatar" v-if="gptType == 301" :src="XFIcon"></el-image>
                <el-image class="avatar" v-if="gptType == 302" :src="WXIcon"></el-image> -->
            </div>
            <div class="gptLogo" :class="{ 'open': showIcon }" v-for="(row, index) in gptData" :key="index">
                <div class="flex-c-c minGPT2" @click.stop="switchGptType(row.code)">
                    <!-- <el-image class="avatar" :class="{ 'hide': gptType != row.code }" :src="row.logo"
                    v-for="(row, index) in gptData" :key="index"></el-image> -->
                    <el-image class="avatar" :src="row.logo"></el-image>
                </div>
            </div>
            <div class="smallGpt" v-if="gptmodelConfig.showType == 'small'">
                <i class="el-icon-s-operation" @click="showHideGptModel('big')"></i>
            </div>
            <!-- 缩放窗口功能 -->
            <div class="putAway" v-if="gptmodelConfig.showType == 'centre' || gptmodelConfig.showType == 'big'">
                <el-image class="h20 w20 mr10" :src="dinzion" v-if="openPosition"
                    @click="openPosition = false;"></el-image>
                <el-image class="h20 w20 mr10" :src="dingzi" v-else @click="openPosition = true;"></el-image>
                <el-image class="h20 w20 mr10" :src="zuixiaohua" v-if="gptmodelConfig.showType != 'small'"
                    @click="showHideGptModel('small')"></el-image>
                <el-image class="h20 w20" :src="zuizhonghua"
                    v-if="gptmodelConfig.showType == 'big' || gptmodelConfig.showType == 'small'"
                    @click="showHideGptModel('centre')"></el-image>
                <el-image class="h20 w20" :src="zuidahua" v-else @click="showHideGptModel('big')"></el-image>
            </div>
            <div class="gptMessage" v-if="gptmodelConfig.showType == 'centre'">
                <div class="gtpMsgInfo mt20">
                    <h4> Hi 我是智能AI</h4>
                    <div class="mt10">
                        有问题都可以来问我哦，我能快速为您提出合适方案。
                    </div>
                    <div class="mt10" v-for="(message, index) in commonUseMessage" :key="index">
                        <el-button class="br10" type="primary" plain @click.stop="sendShortcutMessage(message)">{{
            message
        }}</el-button>
                    </div>
                </div>
                <div class="h20"></div>
                <div @click="showHideGptModel('big')">
                    <el-input class="gptMsgInput" readonly type="textarea" rows="3" placeholder="请输入您需要的内容"></el-input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let base64 = require('js-base64').Base64

/**
 * askChange 提问回调
 * caleChange 缩放回调 type= bigRight bigLeft centre small
 * clickAnswer 点击回答回调
 */
export default {
    name: "gptModel",
    props: {
        gptTitle: {
            type: String,
            default: "ChatGPT研发"
        },
        commonUseMessage: {
            type: Array,
            default: () => {
                return []
            }
        },
        GPTMessage: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            userInfo: this.$store.state.loginRoot.userInfo,
            //ws配置
            ws: null,
            timeFun: null,
            token: localStorage.getItem("token"),
            messageData: [{ code: 301, status: 1, type: "left", message: "您好，我是智能AI,请问有什么可以帮到您。", html: "您好，我是智能AI,请问有什么可以帮到您。" }],
            message: "",
            askAQuestionIndex: 0,
            messageBtnLoading: false,
            formType: "",
            isSendMessgage: true,
            isMove: false,
            clickX: 0,
            clickY: 0,
            switchXY: {
                top: 0,
                left: 0,
            },
            // XFIcon: 'https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/spark-icon.png',//讯飞LOGO
            // WXIcon: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/favicon.png",//文心一言LOGO
            // QWIcon: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/QWIcon.png",//千问LOGO
            gptData: [
                { code: 301, logo: 'https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/spark-icon.png', messageCode: 3010 },//讯飞LOGO
                { code: 302, logo: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/favicon.png", messageCode: 3020 },//文心一言LOGO
                { code: 303, logo: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/QWIcon.png", messageCode: 3030 }//千问LOGO
            ],
            leftIcon: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/icon_left.png",
            zuizhonghua: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/zuizhonghua.png",
            dingzi: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/dingzi.png",
            dinzion: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/dinzion.png",
            zuidahua: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/zuidahua.png",
            zuixiaohua: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/zuixiaohua.png",
            gptType: 301,
            showIcon: false,
            openPosition: false,
            gptmodelConfig: {
                showType: "centre",//centre big small
                w: 300,
                h: 370,
                minh: 120,
                minw: 190,
                x: 0,
                y: 0,
            },
            startMoveTime: null,
            codeData:[],
        }
    },
    watch: {
        GPTMessage: {
            handler() {
                this.message = this.GPTMessage
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() {
        this.initGPT(true);
        this.createWs();
    },
    methods: {
        /**@method 初始化gpt设置 */
        initGPT(isInitPosition) {
            let GPTMessage = JSON.parse(localStorage.getItem("GPTMessage"));
            let checkedGPT = localStorage.getItem("checkedGPT")
            if (checkedGPT) {
                this.gptType = checkedGPT;
            } else {
                localStorage.setItem("checkedGPT", this.gptType)
            }
            if (GPTMessage && GPTMessage[this.gptType] && GPTMessage[this.gptType].length > 0) {
                this.messageData = GPTMessage[this.gptType];
            } else {
                this.messageData = [{ code: this.gptType, status: 1, type: "left", message: "您好，我是智能AI,请问有什么可以帮到您。", html: "您好，我是智能AI,请问有什么可以帮到您。" }];
            }
            $("#app").mouseup(() => {
                if (this.isMove) {
                    //console.log('mouseup');
                    this.endMove();
                }
            })
            $("#app").mousemove((e) => {
                if (!this.openPosition) {
                    if (this.isMove) {
                        //clientY 移动值 clickY初始位置
                        let y = e.clientY - this.clickY;
                        let x = e.clientX - this.clickX;
                        $(".gptContainer").css('top', y + 'px');
                        $(".gptContainer").css('left', x + 'px');
                        this.gptmodelConfig.x = x;
                        this.gptmodelConfig.y = y;
                        this.$emit("gptMove", { y, x });
                    }
                }

            })
            if (isInitPosition) {
                this.gptmodelConfig.x = $("#app").width() - 350;
                this.gptmodelConfig.y = $("#app").height() - 400;
            }
            $(".gptContainer").css('top', this.gptmodelConfig.y + 'px');
            $(".gptContainer").css('left', this.gptmodelConfig.x + 'px');
            this.$emit("gptMove", { y: this.gptmodelConfig.y, x: this.gptmodelConfig.x });
        },
        /**@method 建立连接、注册连接 */
        createWs() {
            this.wxclose = false;
            this.ws = new WebSocket(this.$store.state.wsUrl);
            // this.ws = new WebSocket("wss://api.yuanqi366.com/ws");
            this.ws.onopen = () => {
                this.isLink = true;
                this.sendWs({
                    "token": this.token,
                    "code": 101, // 操作编码
                    "version": "v1" // 版本号
                })
            }
            for (let key in this.gptData) {
                this.codeData.push(this.gptData[key].messageCode);
            }
            this.ws.onmessage = (request) => {
                let res = JSON.parse(request.data);
                // this.ws.close();
                if (this.codeData.indexOf(res.code) != -1) {
                    let message = this.messageData[this.askAQuestionIndex].message;
                    let newContent = res.content;
                    let content = (message + newContent).replace(/\n+/ig, "\n");
                    let html = (message + newContent).replace(/\n+/ig, "<br>")
                    this.$set(this.messageData[this.askAQuestionIndex], "message", content);
                    this.$set(this.messageData[this.askAQuestionIndex], "html", html);
                    this.$set(this.messageData[this.askAQuestionIndex], "status", 1);
                    this.$nextTick(function () {
                        if ($(".chatContent")[0]) {
                            $(".chatContent").scrollTop($(".chatContent")[0].scrollHeight);
                        }
                    })
                    //回答结束 status=2
                    if (res.status === 2) {
                        this.askAQuestionIndex = 0;
                        this.messageBtnLoading = false;
                        let obj = localStorage.getItem("GPTMessage") ? JSON.parse(localStorage.getItem("GPTMessage")) : {};
                        obj[this.gptType] = this.messageData.slice(-100);
                        localStorage.setItem("GPTMessage", JSON.stringify(obj))
                        this.$emit("gptResult", content)
                    }
                }
            }
            this.ws.onclose = (err) => {
                this.wxclose = true;
                //console.log("websocket已断开");
            }
            this.ws.onerror = (error) => {
                //console.log("websocket发生错误" + error);
            }
            this.timeFun = setInterval(() => {
                if (this.wxclose && this.timeFun) {
                    clearInterval(this.timeFun);
                    this.createWs();
                    return;
                }
                this.sendWs({
                    "token": this.token,
                    "code": 102, // 操作编码
                    "version": "v1" // 版本号
                })
            }, 4000)
        },
        /**@method 清空 */
        withdrawFun() {
            this.messageData = [{ code: this.gptType, status: 1, type: "left", message: "您好，我是智能AI,请问有什么可以帮到您。", html: "您好，我是智能AI,请问有什么可以帮到您。" }];
            localStorage.removeItem("GPTMessage");
        },
        /**@method gpt窗口移动，按下监听 */
        startMove(e) {
            //console.log('mousedown');
            if (e.target.className.indexOf("textarea") == -1) {
                this.clickX = e.clientX - this.gptmodelConfig.x;
                this.clickY = e.clientY - this.gptmodelConfig.y;
                this.isMove = true;
            }
        },
        /**@method gpt窗口移动，松开监听 */
        endMove() {
            this.clickX = 0;
            this.clickY = 0;
            this.isMove = false;
        },
        /**@method 选中填入的地方，没有则就复制 */
        writeContent(str) {
            this.$emit("clickAnswer", str)
        },
        /**@method 缩放提问弹窗 */
        showHideGptModel(showType) {
            if (showType == 'big') {//打开大窗口
                this.gptmodelConfig.w = 500;
                this.gptmodelConfig.h = $(".content-box").height();
                this.gptmodelConfig.minh = 370;
                this.gptmodelConfig.minw = 400;
                this.gptmodelConfig.y = 58;
                this.gptmodelConfig.x = $(document).width() - 540;
                this.showIcon = false;
                this.$nextTick(function () {
                    $(".chatContent").css('height', $(".content-box").height() - 150)
                    if ($(".chatContent")[0]) {
                        $(".chatContent").scrollTop($(".chatContent")[0].scrollHeight);
                    }
                })
                this.openPosition = true;
            } else if (showType == 'centre') {//打开小窗口
                this.gptmodelConfig.w = 300;
                this.gptmodelConfig.h = 370;
                this.gptmodelConfig.minh = 370;
                this.gptmodelConfig.minw = 300;
                if (this.gptmodelConfig.showType == 'big') {//旧状态为大窗口才回位
                    this.gptmodelConfig.x = $("#app").width() - 330;
                    this.gptmodelConfig.y = $("#app").height() - 370;
                } else {
                    this.gptmodelConfig.x = this.gptmodelConfig.x - 220;
                    this.gptmodelConfig.y = this.gptmodelConfig.y - 330;
                }
                // $(".minGPT2").removeAttr("style");
                // this.$nextTick(function () {
                //     $(".minGPT2").removeAttr("style");
                // })
                this.openPosition = false;
            } else if (showType == 'small') {//打开最小窗口
                this.gptmodelConfig.w = 190;
                this.gptmodelConfig.h = 120;
                this.gptmodelConfig.minh = 120;
                this.gptmodelConfig.minw = 190;
                if (this.gptmodelConfig.showType == 'big') {//旧状态为大窗口才回位
                    this.gptmodelConfig.x = $("#app").width() - 150;
                    this.gptmodelConfig.y = $("#app").height() - 50;
                } else {
                    this.gptmodelConfig.x = this.gptmodelConfig.x + 220;
                    this.gptmodelConfig.y = this.gptmodelConfig.y + 330;
                }
                // this.$nextTick(function () {
                //     $(".minGPT2").removeAttr("style");
                // })
                this.openPosition = false;
            }
            this.gptmodelConfig.showType = showType;
            $(".gptContainer").css('top', this.gptmodelConfig.y + 'px');
            $(".gptContainer").css('left', this.gptmodelConfig.x + 'px');
            this.$emit("caleChange", showType)
        },
        /**@method 切换gpt类型 */
        switchGptType(code) {
            // 301星火大模型 302文心一言
            this.gptType = code;
            this.showIcon = false;
            localStorage.setItem("checkedGPT", this.gptType)
            this.initGPT();
        },
        /**@method 回车发送问题 */
        changeMessage(e) {
            if (e.key === 'Shift') {
                this.isSendMessgage = true;
            }
            if (e.key === 'Enter') {
                if (this.message && this.isSendMessgage && !this.messageBtnLoading) {
                    this.sendMessage();
                }
            }
        },
        /**@method 发送快捷消息 */
        sendShortcutMessage(message) {
            this.showHideGptModel("big");
            this.sendMessage(message);
        },
        /**@method 发送题目 */
        sendMessage(message) {
            let sendCode = this.gptType;
            let msg = message ? message : this.message;
            if (!msg) {
                this.$message.info("请输入要提问的内容");
                return;
            }
            let history = [];//格式[{role:"user",content:""},{role:"assistant",content:""}]
            if (this.messageData.length >= 3) {
                let index = this.messageData.length - 1;
                let strLength = 0;
                for (let row of this.messageData) {
                    let item = this.messageData[index];
                    let initStr = '您好，我是智能AI,请问有什么可以帮到您。';
                    if (item.type === 'left' && item.message != initStr) {
                        strLength += (item.message.length + item.problem.length);
                        //console.log(strLength);
                        if (strLength > 2000) {
                            break;
                        } else {
                            history.unshift({ role: "assistant", content: item.message });
                            history.unshift({ role: "user", content: item.problem });
                        }
                    }
                    index--;
                }
            }
            let params = {
                token: this.token,
                version: "v1", // 版本号
                code: sendCode,
                message: msg
            }
            if (history.length > 0) {
                params.history = base64.encode(JSON.stringify(history));
            }
            //console.log(params);
            this.messageData.push({ code: sendCode, status: 1, type: "right", message: msg, html: msg });
            this.messageData.push({ code: sendCode, status: 0, type: "left", message: "", html: "", problem: msg });
            this.$nextTick(function () {
                if ($(".chatContent")[0]) {
                    $(".chatContent").scrollTop($(".chatContent")[0].scrollHeight);
                }
            })
            this.sendWs(params)
            this.$emit("askChange", msg)
            this.askAQuestionIndex = this.messageData.length - 1;
            this.messageBtnLoading = true;
            this.message = "";
        },
        /**@method 发送WebSocket */
        sendWs(data) {
            let params = {
                ...data
            }
            this.ws.send(JSON.stringify(params))

        },
    }
}
</script>

<style>
.chatInput textarea {
    height: 68px !important;
}

.gptModel .avatar img,
.reduceGpt .avatar img {
    pointer-events: none;
    -webkit-user-drag: none;
    user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.gptMsgInput textarea {
    cursor: pointer;

}
</style>
<style lang="scss" scoped>
.chatInput {
    width: calc(100% - 80px)
}

.putAway {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
}

.gptContainer {
    height: calc(100% - 60px);
    position: fixed;
    z-index: 99999;
    transform-origin: 100% 100%;
}

.reduceGpt {
    user-select: none;
    width: 330px;
    cursor: grab;
}

.gptLogo:nth-child(4) {
    transition: all .2s linear;
}

.gptLogo:nth-child(3) {
    transition: all .2s linear .1s;
}

.gptLogo:nth-child(2) {
    transition: all .2s linear .2s;
}

.gptLogo {
    opacity: 0;
    z-index: 1;
    position: absolute;
    transition: all .3s linear;
    transform-origin: center 25px;
    height: 50px;
    width: 50px;
    left: -25px;
    top: -25px;
}

.minGPT {
    // cursor: grab;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #51CBCD;
    z-index: 99999;
    width: 50px;
    height: 50px;
    position: absolute;
    left: -25px;
    top: -25px;

    .gptTips {
        display: none;
        position: absolute;
        right: 65px;
        top: 6px;
        color: #fff;
        background: #51CBCD;
        font-size: 15px;
        width: 180px;
        height: 40px;
        border-radius: 10px;
        letter-spacing: 3px;

        .pointed {
            border: 8px solid transparent;
            border-left: 8px solid #51CBCD;
            position: absolute;
            right: -16px;
            top: 10px;
        }
    }


    .avatar {
        border-radius: 50%;
        background: #fff;
        color: #fff;
        height: 40px;
        width: 40px;
    }
}

.minGPT2 {
    overflow: hidden;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #51CBCD;
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 0;

    .avatar {
        height: 40px;
        width: 40px;
    }
}

.gptLogo.open {
    display: flex;
    opacity: 1;

}

.smallGpt {
    margin-top: -25px;
    margin-left: -25px;
    background-image: linear-gradient(to right bottom, #74e2e4, #e0feff);
    height: 50px !important;
    border-radius: 50px;
    width: 120px;
    position: relative;
    border: 2px solid #51CBCD;

    i {
        position: absolute;
        cursor: pointer;
        top: 8px;
        right: 25px;
        font-size: 30px
    }
}

.gptLogo.open:nth-child(2) {
    transform: translate(-60px, 20px)
}

.gptLogo.open:nth-child(3) {
    transform: translate(-45px, -45px)
}

.gptLogo.open:nth-child(4) {
    transform: translate(20px, -60px)
}

.minGPT:hover .gptTips {
    display: flex;
}

.gptMessage {
    // cursor: pointer;
    background-image: linear-gradient(to right bottom, #74e2e4, #e0feff);
    border: 2px solid #51CBCD;
    border-radius: 10px;
    width: 100%;
    // height: 370px;
    padding: 15px;
    // position: absolute;
    // left: 25px;
    // top: 25px;

    .gtpMsgInfo {
        background: #fff;
        padding: 10px;
        border-radius: 10px;
    }

}

.reduceGpt.small {
    width: 190px;
    // height: 120px;

    .gptMessage {
        height: 120px;
    }
}

.gptMsgInput {
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}

.gptModel {
    border: 2px solid #51CBCD;
    border-radius: 10px;
    width: 500px;
    height: 100%;
    background-image: linear-gradient(45deg, #e0feff 30%, #74e2e4);

    .chatTitle {
        line-height: 30px;
        color: #000;
        padding: 10px 20px;
        cursor: grab;

        >div {
            font-size: 18px;
        }

        .close {
            font-size: 25px;
            cursor: pointer;
        }
    }

    .chatContent {
        padding: 10px;
        margin: 10px;
        margin-bottom: 100px;
        height: calc(100% - 150px);
        border-top: 1px solid #bebebe;
        overflow-y: scroll;
        margin-top: 0;

        .chatRow {
            display: flex;
            margin-bottom: 20px;
            position: relative;

            .GPTAvatar {
                background: #fff;
                width: 40px;
                border-radius: 20px;
                position: absolute;

                .avatar {
                    padding: 5px;
                }
            }

            .avatar {
                display: block;
                border-radius: 50%;
                font-size: 20px;
                height: 40px;
                width: 40px;
                background: #fff;
            }

            .chatInfo {
                box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
                border-radius: 10px;
                padding: 10px;
                line-height: 20px;
                max-width: calc(100% - 100px);
                word-wrap: break-word;
                cursor: pointer;
                text-align: justify;
                background: #fff;
            }
        }

        .chatRow.left {
            justify-content: flex-start;

            .chatInfo {
                margin-left: 10px;
            }
        }

        .chatRow.right {
            justify-content: flex-end;

            .chatInfo {
                margin-right: 10px;
            }
        }
    }

    .chatSend {
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #bebebe;
        cursor: grab;

        .twBtn {
            height: 32px;
        }
    }
}
</style>