<template>
    <div class="articlestyle">
        <!-- 标题 -->
        <h2>{{ datas.text }}</h2>

        <!-- 表单 -->
        <el-form label-width="80px" :model="datas" size="small" :rules="rules">
            <div class="moditystyle moditystyle2">
                <span class="addMethod" :class="datas.addMethod == 'auto' ? 'active' : ''"
                    @click="datas.addMethod = 'auto'">
                    自动获取文章
                </span>
                <span class="addMethod" :class="datas.addMethod == 'manual' ? 'active' : ''"
                    @click="datas.addMethod = 'manual'">
                    手动添加文章
                </span>
            </div>
            <br>
            <el-form-item label="文章标题" class="lef">
                <el-input v-model="datas.name"></el-input>
            </el-form-item>

            <div class="bor" />

            <!-- 标题内容 -->
            <el-form-item class="lef" label="选择模板">
                <p style="color: #000">{{ styleText }}</p>
            </el-form-item>

            <!-- 卡片样式选择 -->
            <div class="commodityType">
                <el-tooltip class="item" effect="dark" :content="item.content" placement="bottom"
                    v-for="(item, index) in commodityTypes" :key="index">
                    <span class="iconfont" style="font-size: 21px" :class="[
                        item.type === datas.commodityType ? 'active' : '',
                        item.icon,
                    ]" @click="setCommodityTypeFun(item.type)" />
                </el-tooltip>
            </div>

            <div class="bor" />
            <div v-if="datas.addMethod === 'auto'">
                <h5 style="color: #000; font-size: 14px">基础设置</h5>
                <div style="height: 10px" />
                <!-- <el-form-item class="lef" label="列表类型">
                    <el-radio v-model="datas.isPage" :label="true" @change="changeIsPage">拖动分页</el-radio>
                    <el-radio v-model="datas.isPage" :label="false" @change="changeIsPage">固定条数</el-radio>
                </el-form-item> -->
                <!-- <el-form-item label="文章分类" class="lef borrediu mt10" v-if="!datas.isPage">
                    <el-select class="mr20 w100p" v-model="datas.articleType" @change="changeType" clearable>
                        <el-option v-for="item in articleType" :key="item.typeId" :label="item.typeName"
                            :value="item.typeId">
                            {{ item.typeName }}
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="文章条数" v-if="!datas.isPage" class="lef borrediu">
                    <el-slider v-model="datas.dataNumber" @change="changeDataNumber($event)" :max="10" :min="1"
                        input-size="mini" show-input>
                    </el-slider>
                </el-form-item>
            </div>
            <div v-if="datas.addMethod === 'manual'">
                <h5 style="color: #000; font-size: 14px">添加文章</h5>
                <p style="color: #969799; font-size: 12px; margin-top: 10px">
                    鼠标拖拽调整卡片顺序
                </p>
                <!-- 上传图片 -->
                <el-button @click="showArticleModel()" class="uploadImg" type="primary" plain><i
                        class="el-icon-plus" />点击添加文章</el-button>
                <!-- 图片广告 -->
                <div v-if="datas.manualData[0]">
                    <vuedraggable v-model="datas.manualData" v-bind="dragOptions">
                        <transition-group>
                            <section class="imgBanner" v-for="(item, index) in datas.manualData" :key="item + index">
                                <i class="el-icon-circle-close" @click="deleteimg(index)" />
                                <!-- 图片 -->
                                <div class="imag">
                                    <img :src="item.img" alt="" draggable="false" />
                                </div>
                                <!-- 标题和链接 -->
                                <div class="imgText">
                                    <el-input v-model="item.text" placeholder="活动标题" size="mini" disabled />
                                    <!-- 标题和链接 -->
                                    <div class="imgTextChild">
                                        <!-- 选择类型 -->
                                        <el-input size="mini" placeholder="活动类型" v-model="item.type" disabled>
                                        </el-input>

                                        <!-- 输入外部链接 -->
                                        <el-input size="mini" placeholder="发布日期" v-model="item.releaseTime" disabled>
                                        </el-input>
                                    </div>
                                </div>
                            </section>
                        </transition-group>
                    </vuedraggable>
                </div>
            </div>


            <div style="height: 10px" />

            <!-- 卡片样式 -->
            <!-- <el-form-item label="文章样式" class="lef" /> -->
            <!-- 卡片样式选择 -->
            <!-- <div class="moditystyle">
                <span v-for="(item, index) in moditystyles" :key="index"
                    :class="item.type == datas.moditystyle ? 'active' : ''" @click="datas.moditystyle = index">
                    {{ item.text }}
                </span>
            </div> -->

            <div class="bor" />

            <!-- 文本粗细 -->
            <el-form-item class="lef" label="文本粗细" prop="textWeight" :hide-required-asterisk="true">
                <el-input type="number" v-model.number="datas.textWeight" placeholder="请输入文本粗细" />
            </el-form-item>

            <div style="height: 10px" v-if="datas.commodityType !== 3"/>

            <!-- 边框倒角 -->
            <el-form-item label="边框倒角" class="lef borrediu" v-if="datas.commodityType !== 3">
                <el-slider v-model="datas.borderRadius" :max="30" input-size="mini" show-input>
                </el-slider>
            </el-form-item>

            <div style="height: 10px" v-if="datas.commodityType === 0" />

            <!-- 显示位置 -->
            <el-form-item label="标题位置" class="lef" v-if="datas.commodityType === 0">
                <div class="weiz">
                    <el-tooltip class="item" effect="dark" content="上方" placement="bottom">
                        <i :class="datas.positions === 'top' ? 'active' : ''" class="iconfont icon-shang"
                            @click="datas.positions = 'top'" />
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="下方" placement="bottom">
                        <i :class="datas.positions === 'bottom' ? 'active' : ''" class="iconfont icon-jiantou"
                            @click="datas.positions = 'bottom'" />
                    </el-tooltip>
                </div>
            </el-form-item>

            <div class="bor" />

            <!--活动时间 -->
            <el-form-item class="lef" label="发布时间">
                {{ datas.releaseTime ? '显示' : '隐藏' }}
                <el-checkbox style="margin-left: 196px" v-model="datas.releaseTime" />
            </el-form-item>

            <!--查看更多 -->
            <el-form-item class="lef" label="查看更多">
                {{ datas.viewMore1 ? '显示' : '隐藏' }}
                <el-checkbox style="margin-left: 196px" v-model="datas.viewMore1" />
                <!-- <el-checkbox v-model="datas.viewMore1">头部{{ datas.viewMore1 ? '显示' : '隐藏' }}</el-checkbox>
                <el-checkbox v-model="datas.viewMore2">尾部{{ datas.viewMore2 ? '显示' : '隐藏' }}</el-checkbox>
                <div class="imgText1" v-show="datas.viewMore1 || datas.viewMore2">
                    <parkJumpLink :linkData="datas.http" @changeFun="jumpLinkChange($event)" />
                </div> -->
            </el-form-item>
        </el-form>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择文章资讯" :visible.sync="showArticle"
            :destroy-on-close="true" width="80%" center>
            <commonTable :tableData="articleTableData" :loading="articleLoading" @handleSizeChange="articleHandleSizeChange"
                @handleCurrentChange="articleHandleCurrentChange" :currentPage="articleCurrentPage" :total="articleTotal">
                <template v-slot:table>
                    <el-table-column width="55">
                        <template slot-scope="scope">
                            <el-checkbox v-model="articleIds[scope.row.messageId]" :disabled="!scope.row.photos"
                                @change="selectArticle($event, scope.row)" :label="scope.row.messageId">{{ ''
                                }}</el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column prop="photos" align="center" label="封面图" width="120px" required>
                        <template slot-scope="scope">
                            <el-image v-if="scope.row.photos" style="width: 100px; height: 100px;margin-bottom:-10px"
                                :src="scope.row.photos">
                            </el-image>
                            <p v-else
                                style="width: 100px; height: 100px;line-height: 100px;font-size:12px;margin:0 auto;background: #eee;">
                                暂无图片
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" align="center" label="标题" show-overflow-tooltip />
                    <el-table-column prop="originAuthor" align="center" label="来源" show-overflow-tooltip />
                    <el-table-column prop="typeName" align="center" label="动态类型" show-overflow-tooltip />
                </template>
            </commonTable>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消 上 传</el-button>
                <el-button type="primary" @click="submitTable" :disabled="disabl">点 击 上 传</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>
import vuedraggable from 'vuedraggable' //拖拽组件
import { getParkMessageList } from "@/api/appModel";
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec } from "@/utils";
export default {
    name: 'parkNewsarticlestyle',
    props: {
        datas: Object,
    },
    components: { vuedraggable, commonTable },
    data() {
        let kon = (rule, value, callback) => {
            if (value.length === 0) callback(new Error('请输入有效数字'))
        }
        return {
            moditystyles: [
                /* 卡片样式 */
                {
                    text: '无边白底',
                    type: 0,
                },
                {
                    text: '卡片投影',
                    type: 1,
                },
                {
                    text: '描边白底',
                    type: 2,
                },
                {
                    text: '无边透明底',
                    type: 3,
                },
            ],
            commodityTypes: [
                {
                    icon: 'icon-datumoshi',
                    type: 0,
                    content: '大图模式',
                },
                {
                    icon: 'icon-commodity-yihangliangge',
                    type: 1,
                    content: '一行两个',
                },
                {
                    icon: 'icon-xuanzemokuai-daohanghengxianghuadong',
                    type: 2,
                    content: '横向滑动',
                },
                {
                    icon: 'icon-sanlan',
                    type: 3,
                    content: '一大两小',
                },
                {
                    icon: 'icon-commodity-xiangxiliebiao',
                    type: 4,
                    content: '详细列表',
                },
            ],
            rules: {
                textWeight: [{ required: true, validator: kon, trigger: 'blur' }],
            },
            marker: ['新品', '热卖', 'NEW', 'HOT'],
            dragOptions: {
                animation: 200,
            },
            emptyText: '',
            //文章列表配置
            articleIds: [],
            articleTableData: [],
            showArticle: false,//模态框显示状态
            articleCurrentPage: 1, //当前页
            articlePageSize: 10, //显示条数
            articleLoading: false, //表格加载
            articleTotal: 0, //总条数
            checkedarticle: {},//选中的内容
            disabl: false,
            index: 0,
            parkId: 0,
            article: {},//文章数据
            articleType: [],//文章数据
        }
    },
    watch: {
        watchPark(row) {
            this.parkId = row.parkId;
        },
    },
    created() {
        this.parkId = JSON.parse(sessionStorage.getItem('checkedPark')).parkId;
        //获取文章数据
        this.article = this.$store.state.parkDrag.articleData;
        this.articleType = this.$store.state.parkDrag.articleType;
        if (this.datas.addMethod === 'auto') {
            //-console.log(this.article);
            this.getArticleData();
        }
    },
    methods: {
        setCommodityTypeFun(type) {
            this.datas.commodityType = type;
            if (this.datas.commodityType === 3) {
                this.datas.borderRadius = 0;
            }
        },
        /**@method 监听文章分类获取文章数据*/
        changeType() {
            if (!this.article[this.datas.articleType] && this.datas.articleType) {
                let params = {
                    pageSize: 50,
                    pageNum: 1,
                    parkId: this.parkId,
                    isPage: 1,
                    typeId: this.datas.articleType,
                    onlyPhotos: true
                };
                getParkMessageList(params).then(res => {
                    let articleData = [];
                    if (res.data) {
                        for (let row of res.data.list) {
                            articleData.push({
                                id: row.messageId,
                                type: row.typeName,
                                img: row.photos,
                                releaseTime: row.createTime.slice(0, 10),
                                text: row.title,
                                originAuthor:row.originAuthor
                            });
                        }
                    }
                    this.$store.commit("setParkDragObjData", {
                        parentKey:"articleData",
                        key: this.datas.articleType,
                        list: articleData
                    })
                    this.article = this.$store.state.parkDrag.articleData;
                    this.getArticleData();
                }).catch(err => {
                    this.$store.commit("setParkDragObjData", {
                        parentKey:"articleData",
                        key: this.datas.articleType,
                        list: []
                    })
                    this.article = this.$store.state.parkDrag.articleData;
                    this.getArticleData();
                })
            } else {
                this.getArticleData();
            }
            //-console.log(this.$store.state.parkDrag.articleData);
        },
        /**@method 链接路径 */
        jumpLinkChange(data) {
            if (data.type) {
                let { urlType, type, id, title } = data;
                this.datas.http = {
                    urlType,
                    type,
                    id,
                    title
                };
            } else {
                this.datas.http = {};
            }
        },
        /**文章配置 */
        /**@method 选中文章 */
        selectArticle(isOpen, data) {
            if (isOpen) {
                this.checkedarticle[data.messageId] = data;
            } else {
                delete this.checkedarticle[data.messageId];
            }
        },
        /**@method 显示园区文章列表 */
        showArticleModel() {
            this.showArticle = true;
            this.getArticleList();
        },
        /**@method 显示文章列表 */
        showArticleFun(index) {
            this.index = index;
            this.showArticle = true;
        },
        /**@method 文章获取列表 */
        async getArticleList() {
            let params = {
                pageSize: this.articlePageSize,
                pageNum: this.articleCurrentPage,
                parkId: this.parkId,
                isPage: 1,
                onlyPhotos: true
            };
            try {
                this.articleLoading = true;
                let res = await getParkMessageList(params)
                this.articleLoading = false;

                const { data } = res;
                this.articleTableData = data.pageInfo.list;
                this.articleTotal = data.pageInfo.total;
            } catch (error) {
                this.articleLoading = false;
                this.articleTableData = [];
            }
        },
        /**@method 文章切换行 */
        articleHandleSizeChange(val) {
            this.articlePageSize = val;
            this.getArticleList();
        },
        /**@method 文章切换下一页 */
        articleHandleCurrentChange(val) {
            this.articleCurrentPage = val;
            this.getArticleList();
        },
        /**文章配置 */
        // 关闭弹框
        handleClose() {
            this.showArticle = false
        },
        /**@method 获取商品数据 */
        getArticleData() {
            if (this.datas.articleType) {
                this.datas.autoData = this.article[this.datas.articleType].slice(0, this.datas.dataNumber)
            } else {
                this.datas.autoData = this.article.all.slice(0, this.datas.dataNumber);
            }
        },
        changeIsPage(val) {
            if (val) {
                this.datas.dataNumber = 3;
                this.datas.autoData = this.article.all.slice(0, this.datas.dataNumber)
            }
        },
        changeDataNumber(dataNumber) {
            if (this.datas.articleType) {
                this.datas.autoData = this.article[this.datas.articleType].slice(0, this.datas.dataNumber)
            } else {
                this.datas.autoData = this.article.all.slice(0, this.datas.dataNumber);
            }
        },
        // 提交
        submitTable() {
            this.disabl = true;
            for (let i in this.checkedarticle) {
                let row = this.checkedarticle[i];
                this.datas.manualData.push({
                    id: row.messageId,
                    type: row.typeName,
                    img: row.photos,
                    releaseTime: row.createTime.slice(0, 10),
                    text: row.title,
                    originAuthor:row.originAuthor
                })
            }
            this.showArticle = false;
            this.disabl = false;
        },

        /* 删除图片 */
        deleteimg(index) {
            this.datas.manualData.splice(index, 1)
        },
    },
    computed: {
        setDataTimeSec() {
            return (data) => getDataTimeSec(data);
        },
        // eslint-disable-next-line vue/return-in-computed-property
        styleText() {
            if (this.datas.commodityType === 0) return '大图模式'
            if (this.datas.commodityType === 1) return '一行两个'
            if (this.datas.commodityType === 2) return '横向滑动'
            if (this.datas.commodityType === 3) return '详细列表'
            if (this.datas.commodityType === 4) return '一大两小'
            if (this.datas.commodityType === 5) return '横向滑动'
        },
        watchPark() {
            return this.$store.state.checkedPark
        }
    },
}
</script>
  
<style scoped lang="scss">
.articlestyle {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 10px 20px;
    box-sizing: border-box;

    /* 标题 */
    h2 {
        padding: 24px 16px 24px 0;
        margin-bottom: 15px;
        border-bottom: 1px solid #f2f4f6;
        font-size: 18px;
        font-weight: 600;
        color: #323233;
    }

    .lef {
        /deep/.el-form-item__label {
            text-align: left;
        }
    }

    /* 图片字 */
    .imgText1 {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;

        .fir-sele.el-select {
            width: 40%;
        }
    }

    /* 列表样式 */
    .commodityType {
        display: flex;
        justify-content: space-around;
        align-items: center;

        span {
            display: inline-block;
            width: 58px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            background: #fff;
            border: 1px solid #ebedf0;
            color: #979797;
            margin: 0 1px;
            cursor: pointer;
            transition: all 0.5s;

            &:hover {
                border: 1px solid #51CDCB;
                color: #51CDCB;
            }

            &.active {
                border: 1px solid #51CDCB;
                background-color: #51cdcb26;
                color: #51CDCB;
            }
        }
    }

    /* 卡片样式 */
    .moditystyle {
        font-size: 12px;
        width: 100%;
        display: flex;

        span {
            width: 86px;
            height: 32px;
            display: inline-block;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            border: 1px solid #ebedf0;

            &.active {
                border: 1px solid #51CDCB;
                background-color: #51cdcb26;
                color: #51CDCB;
            }
        }

        .addMethod {
            width: 110px;
            height: 40px;
            line-height: 40px;
        }
    }

    .moditystyle2 {
        justify-content: center;
    }

    /* 位置 */
    .weiz {
        text-align: right;

        i {
            padding: 5px 14px;
            margin-left: 10px;
            border-radius: 0;
            border: 1px solid #ebedf0;
            font-size: 20px;
            font-weight: 500;
            cursor: pointer;

            &:last-child {
                font-size: 22px;
            }

            &.active {
                color: #51CDCB;
                border: 1px solid #51CDCB;
                background: #51cdcb26;
            }
        }
    }

    /* 单选框 */
    /deep/.radi1 {
        border-top: 1px solid #f7f8fa;
        border-bottom: 1px solid #f7f8fa;
        padding: 12px 0;

        .el-radio {
            margin: 10px 25px 7px 0;
        }
    }

    /* 卡片列表 */
    .imgBanner {
        padding: 6px 12px;
        margin: 16px 7px;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
        display: flex;
        position: relative;

        /* 删除图标 */
        .el-icon-circle-close {
            position: absolute;
            right: -10px;
            top: -10px;
            cursor: pointer;
            font-size: 19px;
        }

        /* 图片 */
        .imag {
            width: 60px;
            height: 60px;
            border-radius: 5px;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                display: inline-block;
            }

            span {
                background: rgba(0, 0, 0, 0.5);
                font-size: 12px;
                position: absolute;
                left: 0px;
                bottom: 0px;
                display: inline-block;
                width: 100%;
                text-align: center;
                color: #fff;
                height: 20px;
                line-height: 20px;
            }
        }

        /* 图片字 */
        .imgText {
            width: 80%;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            padding-left: 20px;
            justify-content: space-between;

            /* 图片字 */
            .imgTextChild {
                width: 100%;
                display: flex;
                box-sizing: border-box;
                justify-content: space-between;

                .fir-sele.el-select {
                    width: 40%;
                }
            }
        }
    }

    /* 上传图片按钮 */
    .uploadImg {
        width: 345px;
        height: 40px;
        margin-top: 20px;
    }

    // 上传弹框内容部分
    /deep/.uploadIMG .el-dialog__body {
        height: 280px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        justify-content: center;
    }

    .disable {
        /deep/.el-upload {
            display: none !important;
        }
    }

    .tit {
        /deep/.el-input__inner {
            text-align: center;
        }
    }
}
</style>
  