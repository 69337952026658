import axios from 'axios';
import router from '../router';
import { showLoading, hideLoading } from '@/utils/loading'
import { Message } from 'element-ui'
// 全局axios请求loading加载


const BASE_URL = process.env.VUE_APP_API_URL

let headers = {
        Accept: 'application/json;charset=utf-8',
        companyKey: 'one-stand',
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    // axios配置参数
axios.defaults.baseUrl = BASE_URL
console.log(BASE_URL)
    //axios.defaults.headers = headers
axios.defaults.timeout = 1000 * 300

const whiteList = ["getPcLoginImg", "pcLoginCallBack"];


// 请求拦截器
axios.interceptors.request.use(config => {
        config.withCredentials = true
        let token = localStorage.getItem("token")

        const whitle = whiteList.find(item => { return config.url.indexOf(item) != -1 })
            //这里处理不同header Content-Type
        if (whitle) {
            config.headers = {
                Accept: 'application/json;charset=utf-8',
                companyKey: 'one-stand',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        } else {
            config.headers = headers
        }

        if (token) {
            config.headers.token = token
        } else {
            router.push("/login")
        }
        if (config.method === 'get') {
            config.params = {
                t: Date.parse(new Date()) / 1000,
                ...config.params
            }
        }
        if (config.headers.showLoading === true) {
            showLoading()
        }
        return config
    },
    error => {
        hideLoading()
        return Promise.reject(error)
    }
)

//  响应拦截器
axios.interceptors.response.use(response => {
        hideLoading()
        let isShow401 = localStorage.getItem("isShow401")
        if ((response.data.code === 401 || response.data.code === 402) && isShow401 == 'true') {
            Message({
                message: "登录已过期，请重新登录！",
                type: 'error'
            });
            router.push('/login')
            localStorage.setItem("isShow401", false)
            return Promise.reject(response.data);
        }
        if (response.data.code === 201 || response.data.code === 200) {
            return response.data
        } else {
            return response.data
        }
    },

    error => {
        hideLoading()
        if (error.data.code === 401 || error.data.code === 402) {
            Message({
                message: "登录已过期，请重新登录！",
                type: 'error'
            });
            router.push('/login')
        }

    }
)

export default axios