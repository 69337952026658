<template>
    <div class="graphicnavigation"  :class="{ 'bShadow': datas.isShadow }"
        :style="{ backgroundImage: 'url(' + datas.bgImg + ')', background: datas.backgroundColor, 'border-radius': datas.borderRadius + 'px' }">
        <div class="navigationTitle flex-a-b-c" v-if="datas.showTitle || datas.showMore || datas.showEwm">
            <div class="top flex-a-b-c">
                <div v-if="datas.showTitle" class="title">{{ datas.title }}</div>
                <div v-if="datas.showEwm" :style="{ 'border-color': datas.btnColor, color: datas.btnColor }"
                    class="shareEwm flex-c-c"><van-icon name="qr" size="16" />分享码</div>
            </div>
            <div v-if="datas.showMore" class="iconYQH iconyou"></div>
        </div>
        <!-- 默认导航 -->
        <section class="defaultNavigation" :style="{ display: 'flex' }">
            <!-- 导航 -->
            <div class="navigationList">
                <!-- 图片 -->
                <p :style="{ 'font-size': datas.textSize + 'px', color: datas.textColor,'line-height':'34px' }">￥0</p>
                <!-- 文字 -->
                <p
                    :style="{ 'font-size': datas.textSize + 'px', color: datas.textColor, height: datas.textHeight + 'px' }">
                    销售额</p>
            </div>
            <div class="navigationList">
                <!-- 图片 -->
                <p :style="{ 'font-size': datas.textSize + 'px', color: datas.textColor,'line-height':'34px' }">￥0</p>
                <!-- 文字 -->
                <p
                    :style="{ 'font-size': datas.textSize + 'px', color: datas.textColor, height: datas.textHeight + 'px' }">
                    可提现</p>
            </div>
            <div class="navigationList">
                <!-- 图片 -->
                <p :style="{ 'font-size': datas.textSize + 'px', color: datas.textColor,'line-height':'34px' }">￥0</p>
                <!-- 文字 -->
                <p
                    :style="{ 'font-size': datas.textSize + 'px', color: datas.textColor, height: datas.textHeight + 'px' }">
                    待返现</p>
            </div>
            <div class="navigationList">
                <!-- 图片 -->
                <p :style="{ 'font-size': datas.textSize + 'px', color: datas.textColor,'line-height':'34px' }">￥0</p>
                <!-- 文字 -->
                <p
                    :style="{ 'font-size': datas.textSize + 'px', color: datas.textColor, height: datas.textHeight + 'px' }">
                    已返现</p>
            </div>
        </section>
        <!-- 删除组件 -->
        <slot name="deles" />
    </div>
</template>

<script>
export default {
    name: 'distributorRecord',
    props: {
        datas: Object,
    },
    created() {
       //-console.log(this.datas, '--------graphicnavigation')
    }
}
</script>

<style scoped lang="scss">
.bShadow {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
.graphicnavigation {
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .navigationTitle {
        padding: 10px;

        .top {
            height: 24px;
            font-size: 14px;
            width: 140px;
            .title {
                font-weight: bold;
            }

            .shareEwm {
                border: 1px solid #fff;
                border-radius: 5px;
                padding: 0 5px;
            }
        }
    }

    /* 默认导航 */
    .defaultNavigation {
        // overflow-x: scroll;
        // justify-content: space-evenly;

        &::-webkit-scrollbar {
            height: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #51CDCB;
        }

        /deep/.el-collapse-item__header,
        /deep/.el-collapse-item__wrap {
            border-bottom: 0 !important;
        }

        /* 导航 */
        .navigationList {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 25%;
            padding: 10px;
            img {
                margin-top: 5px;
                width: 45px;
                height: 45px;
            }

            p {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
                box-sizing: border-box;
            }
        }
    }
}
</style>
