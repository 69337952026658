var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"headerTop",style:({
    color: _vm.pageSetup.titleColor,
    height: _vm.pageSetup.titleHeight + 'px',
    background: _vm.pageSetup.titleBgColor,
    backgroundImage: 'url(' + _vm.pageSetup.titleBgImg + ')'
  })},[_c('div',{staticClass:"header-title",style:({
        height: _vm.pageSetup.titleHeight + 'px',
        'line-height': _vm.pageSetup.titleHeight + 'px',
      })},[_vm._v(" "+_vm._s(_vm.pageSetup.name)+" ")])]),_c('div',{staticClass:"newThreeBox flex-a-b-c pall10",style:({ background: _vm.pageSetup.titleBgColor, })},[(_vm.pageSetup.showButton)?_c('div',{staticClass:"typeBtn flex-c-c",style:({ backgroundColor: 'rgb(238, 238, 238)', color: '#999' })},[_c('div',{staticClass:"flex-c-c",style:({ background: '#51CDCB', color: '#fff' })},[_vm._v("商城 ")]),_c('div',{staticClass:"flex-c-c"},[_vm._v("服务 ")])]):_vm._e(),_c('div',{staticClass:"flex-a-b-c search",style:({ backgroundColor: 'rgb(238, 238, 238)', 'border-radius': '20px' })},[_c('div',{staticClass:"seeIcon flex-c-c pl10",style:({ color: '#999' })},[_c('span',{staticClass:"el-icon-search",staticStyle:{"font-size":"23px"}})]),(_vm.pageSetup.showButton)?_c('div',{staticClass:"searchPlaceholder",style:({ width: '180px', color: '#999' })},[_vm._v(" 请输入产品名称搜索")]):_c('div',{staticClass:"searchPlaceholder",style:({ width: '300px', color: '#999' })},[_vm._v(" 请输入产品名称搜索")])]),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-c-c pa5 coupon"},[_c('img',{attrs:{"src":"https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/one-stand/products/icon_discount.png","alt":""}})])
}]

export { render, staticRenderFns }