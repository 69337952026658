import httpUtil from '../utils/httpUtil'

/**
 * 小程序管理
 * @param params
 * @returns {Promise<R>}
 */
export const componentCallBack = params => httpUtil.post("/api/crmPc/component/componentCallBack", params);

export const getAuthorizer = params => httpUtil.post("/api/crmPc/applet/getAuthorizer", params);

export const submitPayInfo = params => httpUtil.post("/api/crmPc/companyGoods/payment", params);

/**
 * 企业登录强制授权
 * 
 */
export const switchCompanyModel = params => httpUtil.post("/api/crmPc/companyGoods/switchCompanyModel", params);

/**
 * 查询企业登录强制授权
 * 
 */
export const querySwitchCompanyModel = params => httpUtil.post("/api/crmPc/companyGoods/querySwitchCompanyModel", params);


/**
 * 按公司查询菜单基础配置
 */
export const queryTabMenu = params => httpUtil.post("/api/crmPc/tabMenu/queryTabMenu", params);


/**
 * 添加或者更新公司菜单
 */
export const saveTabMenu = params => httpUtil.post("/api/crmPc/tabMenu/saveTabMenu", params);

/**
 * 保存商城模板
 */
export const saveShopModelConfig = params => httpUtil.post("/api/crmPc/shopConfig/saveShopModelConfig", params);


/**
 * 更新商城模板
 */
export const updateShopModelConfig = params => httpUtil.post("/api/crmPc/shopConfig/updateShopModelConfig", params);


/**
 * 获取商城模板
 */
export const queryShopModelConfig = params => httpUtil.post("/api/crmPc/shopConfig/queryShopModelConfig", params);


/**
 * 获取所有模板信息
 */
export const queryShopModelConfigAll = async(params, type) => httpUtil.post("/api/crmPc/shopConfig/queryShopModelConfigAll", params, type);



/**
 * 保存商城基础配置
 */
export const saveShopConfig = params => httpUtil.post("/api/crmPc/shopConfig/saveShopConfig", params);


/**
 * 查询商城基础配置
 */
export const queryShopConfig = params => httpUtil.post("/api/crmPc/shopConfig/queryShopConfig", params);

/**
 * 设置公众号信息
 */
export const getOfficialAccountList = params => httpUtil.post("/api/crmPc/companyAccount/getOfficialAccountList", params);

/**
 * 获取公众号文章列表
 */
export const getBatchList = params => httpUtil.post("/api/crmPc/property/getBatchList", params);

/**
 * 公众号文章设置
 */
export const getOfficialAccountOptionList = params => httpUtil.post("/api/crmPc/companyAccount/getOfficialAccountOptionList", params);


/**获取名片信息 */
export const findBusinessCardDetail = params => httpUtil.post("/api/crmPc/businessCard/findBusinessCardDetail", params);

/**获取企业信息 */
export const getCompany = params => httpUtil.post("/api/crmPc/companyGoods/getCompany", params);

/**保存公司名片信息 */
export const updateCompanyUserDefined = params => httpUtil.post("/api/crmPc/companyGoods/updateCompanyUserDefined", params);

/**保存名片设置 */
export const updateUserDefined = params => httpUtil.post("/api/crmPc/businessCard/updateUserDefined", params);

/**获取名片模板列表 */
export const getCardStyleModelList = params => httpUtil.post("/api/crmPc/businessCard/getCardStyleModelList", params);
/**编辑名片信息*/
export const updateCardV2 = params => httpUtil.post("/api/crmPc/businessCard/updateCardV2", params);