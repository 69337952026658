var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"headBg",style:({ backgroundColor: _vm.pageSetup.titleBgColor, backgroundImage: 'url(' + _vm.pageSetup.titleBgImg + ')' })}),_c('div',{staticClass:"headerTop",style:({
      color: _vm.pageSetup.titleColor,
      height: _vm.pageSetup.titleHeight + 'px',
    })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pageSetup.isAddress),expression:"pageSetup.isAddress"}],staticClass:"lef"},[_c('i',{staticClass:"el-icon-location-outline"}),_vm._v(" "),_c('span',[_vm._v("中国")])]),_c('div',{staticClass:"header-title",style:({
        height: _vm.pageSetup.titleHeight + 'px',
        'line-height': _vm.pageSetup.titleHeight + 'px',
      })},[_vm._v(" "+_vm._s(_vm.associationName)+" ")])])]),_c('div',{staticClass:"commoditysearch"},[_c('div',{staticClass:"newThreeBox flex-a-b-c"},[_c('div',{staticClass:"iocnSort flex-c-c pa5",style:({ backgroundColor: 'rgba(55, 84, 140, 0.9)', color: _vm.pageSetup.titleColor, 'border-radius': '3px' })},[_c('span',{staticClass:"el-icon-sort",staticStyle:{"font-size":"23px"}})]),_c('div',{staticClass:"flex-a-b-c",style:({ backgroundColor: 'rgba(55, 84, 140, 0.9)', 'border-radius': '3px' })},[_c('div',{staticClass:"searchPlaceholder",style:({ borderRight: '1px solid '+_vm.pageSetup.titleColor, width: '180px', color: _vm.pageSetup.titleColor})},[_vm._v(" "+_vm._s(' 搜索动态/活动/企业/供求'))]),_c('div',{staticClass:"seeIcon flex-c-c pa5",style:({ color: _vm.pageSetup.titleColor })},[_c('span',{staticClass:"el-icon-search",staticStyle:{"font-size":"23px"}})])]),_c('div',{staticClass:"flex-c-c pa5",style:({ backgroundColor: 'rgba(55, 84, 140, 0.9)', color: '#fff', 'border-radius': '3px' })},[_c('van-icon',{attrs:{"name":"scan","size":"23","color":_vm.pageSetup.titleColor}})],1),_c('div',{staticClass:"flex-c-c pa5",style:({ backgroundColor: 'rgba(55, 84, 140, 0.9)', color: _vm.pageSetup.titleColor, 'border-radius': '3px' })},[_c('span',{staticClass:"el-icon-bell",staticStyle:{"font-size":"23px"}})])]),_vm._t("deles")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }