import httpUtil from "@/utils/httpUtil";

//拖拽功能显示数据
/**社团动态 */
export const getAssociationMessageList = (params) => httpUtil.post("/api/crmPc/association/getAssociationMessageList", params)

/**动态分类列表 */
export const getContentTypeList = (params) => httpUtil.post("/api/crmPc/sysDict/getContentTypeList", params)

/**字典 */
export const getByDictType = async params => httpUtil.post("/api/crmPc/sysDict/getByDictType", params);

/**获得关联社团的商品信息 */
export const getGoodsListByLink = params => { params.accountType = 9; return httpUtil.post("/api/crmPc/member/getGoodsListByLink", params) };

/**进驻分类 */
export const getParkShopTypeLinkList = async params => httpUtil.post("/api/crmPc/property/getParkShopTypeLinkList", params);

/** 活动列表 */
export function activityEventList(params, type) {
    return httpUtil.post("/api/crmPc/activityEvent/activityEventList", params, type)
}
/**crm新闻资讯 */
export function selectDynamicList(params, type) {
    return httpUtil.post("/api/crmPc/companyGoods/selectDynamicList", params, type)
}
/**获得商品会员关联列表 */
export const getGoodsMemberLinkList = params => httpUtil.post("/api/crmPc/member/getGoodsMemberLinkList", params);

/**获取社团成员 */
export const getAssociationEmployeeList = params => httpUtil.post("/api/crmPc/association/getAssociationEmployeeList", params);

/**商品分类列表 */
export const selectGoodsTypeInfoPC = async params => httpUtil.post("/api/crmPc/companyGoods/selectGoodsTypeInfoPC", params);

/**获取类型商品列表 */
export const getGoodsInfoPCShop = async params => httpUtil.post("/api/crmPc/companyGoods/getGoodsInfoPCShop", params);

/**选择商品 */
export const getGoodsInfoPC = async params => httpUtil.post("/api/crmPc/companyGoods/getGoodsInfoPC", params);

//园区数据
/** 园区服务 */
export const getParkMessageList = async params => httpUtil.post("/api/crmPc/property/getParkMessageList", params)

//拖拽装修配置数据
/** 首页装修数据 */
export function getDragPageAndModule(type, id, params) {
    if (type === 'association') {
        params.associationId = id;
    } else if (type === 'crm') {
        params.companyId = id;
    } else if (type === 'park') {
        params.parkId = id;
    }
    return httpUtil.post("/api/crmPc/drag/getDragPageAndModule", params)
}
export function delDragPageAndModule(pid) {
    return httpUtil.post("/api/crmPc/drag/delDragPageAndModule", { pid })
}
/** 提交装修数据 */
export function setDragPageAndModule(type, id, params) {
    if (type === 'association') {
        params.associationId = id;
    } else if (type === 'crm') {
        params.companyId = id;
    } else if (type === 'park') {
        params.parkId = id;
    }
    return httpUtil.post("/api/crmPc/drag/setDragPageAndModule", params)
}

/** 装修模板列表 */
export function getDragPageAndModuleList(params) {
    return httpUtil.post("/api/crmPc/drag/getDragPageAndModuleList", params)
}

// 获取社团拖拽自动数据
export const getAssociationTotalData = async params => httpUtil.post("/api/crmPc/association/getAssociationTotalData", params);
// 获取园区拖拽自动数据
export const getParkTotalData = async params => httpUtil.post("/api/crmPc/property/getParkTotalData", params);