<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发项目管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo mt30" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent" style="padding-top: 350px;">
                <div class="wordTtile">
                    <div class="flex-c-c textc">
                        <div class="zdhq fs24">研发项目管理办法</div>
                    </div>
                    <div class="textc pjTitle mt10 mb20 fs35">{{wordData.isAgain}}</div>
                </div>
            </div>
            <div class="wordFoot">
                <div class="textc fs30">
                    {{ checkedCompany.companyName }}
                </div>
                <div class="textc fs25">
                    <el-date-picker v-model="formData.fileDay" class="dayInput w230" value-format="yyyy-MM-dd"
                        format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                    </el-date-picker>(自动转为中文)
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 1 页 共 9 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发项目管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent mt50">
                <div class="wordContentTitle textc">目&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;录</div>
                <div class="wordDirectory">
                    <div v-for="(row, index) in directoryData" :key="index">
                        <a class="wordDirectoryItem" :href="'#' + row.id">
                            <div>
                                {{ row.title }}
                            </div>
                            <div class="line"></div>
                            <div>{{ row.page }}</div>
                        </a>
                        <div v-if="row.childData">
                            <div class="ml30" v-for="(item, key) in row.childData" :key="key">
                                <a class="wordDirectoryItem" :href="'#' + item.id">
                                    <div>
                                        {{ item.title }}
                                    </div>
                                    <div class="line"></div>
                                    <div>{{ row.page }}</div>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 2 页 共 9 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发项目管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordTtile">
                    <div class="textc pjTitle mt10 mb10 fs30">{{ checkedCompany.companyName }}</div>
                    <div class="flex-c-c textc lineBottom mb50">
                        <div class="zdhq fs40">研发项目管理办法</div>
                    </div>
                </div>
                <div class="lineheight45">
                    <div class="fw600" id="ZZ">第一章 总则</div>
                    <div class="textIndent40">
                        为使公司各项重大项目的管理规范化、科学化、程序化，提高运作与决策效率，同时为增强公司团队精神，促使公司整体战略目标的全面实现，特制定本办法。
                    </div>
                    <div class="fw600" id="SYFW">第二章 适用范围</div>
                    <div class="ml40">
                        <div class="fw600" id="SYXMTD">（一）适用项目特点</div>
                        <div>■ 项目是单一的，可辨认的，完整的基于公司整体发展的任务；</div>
                        <div>■ 任务复杂，具有一定的风险性；</div>
                        <div>■ 任务的完成有赖于多个职能部门之间的配合协作；</div>
                        <div>■ 可利用资源预先有明确的范围或预算；</div>
                        <div>■ 任务具有一个明确的寿命周期与终止日期；</div>
                        <div class="fw600" id="SYXMFW">（二）适用项目范围</div>
                        <div>■ 技术开发、产品开发、工程研发；</div>
                        <div>■ 重要合作洽谈、重大市场活动；</div>
                        <div>■ 其他为促进公司战略决策和发展所启动的项目；</div>
                    </div>
                    <div class="textIndent40">基于项目定义的不确切性，将由公司研发中心对所有提交的立项申请进行最终审定，确定此立项申请是否采用项目制运作及执行。</div>
                    <div class="fw600" id="XMGLJG">第三章 项目管理机构</div>
                    <div class="ml40">
                        <div>1、公司研发中心为项目常设管理机构，研发中心直接向总经理负责。</div>
                        <div>2、项目经理</div>
                        <div class="ml20">
                            <div> (1) 项目经理由研发中心主任提名，报总经理批准；</div>
                            <div>(2) 项目经理职责： </div>
                        </div>
                    </div>
                    <div class="textIndent40">
                        主要对公司所有项目进行督导和管理，组织项目论证、项目评审、项目进度督导、项目质量监督、项目成本控制、组织项目后评价等工作，包括对所有项目文档的统一编号、管理；对所有予以立项的所有项目进行记录、排期，项目进展记录等。
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 3 页 共 9 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发项目管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt80">
                    <div class="fw600" id="XMZZJG">第四章 项目组织结构</div>
                    <div class="textIndent40">
                        1、项目管理采用项目经理负责制，所有项目小组下设项目经理一名，立项申请人可在立项申请时对项目经理人选进行提议，但由公司研发中心最终确定项目经理的担任人。
                    </div>
                    <div class="textIndent40">2、项目经理根据所负责的项目性质确定项目小组的成员组成及成员分工，并报公司研发中心批准或直接在立项报告中予以明确。</div>
                    <div class="textIndent40">
                        3、项目经理的主要职责：项目计划制定、项目计划管理(包括工作计划基调把握、项目进展跟踪、项目调整控制等)、项目管理接口协调（小组之间与组外）、项目需求变更掌控、项目组成员考评、项目初期验收、项目总结等。
                    </div>
                    <div class="fw600" id="XMLX">第五章 项目立项</div>
                    <div class="fw600 textIndent40" id="SQLXLC">（一）申请立项流程(培养期)</div>
                    <div class="d-flex w100p">
                        <div class="frame">市场调查与分析</div>
                        <div class="process"></div>
                        <div class="frame">确定立项对象</div>
                        <div class="process"></div>
                        <div class="frame">提交立项申请</div>
                    </div>
                    <div class="ml40">
                        <div>说明：</div>
                        <div>1、所有项目立项前均须进行项目可行性论证；</div>
                        <div>2、项目的立项申请人为业务经办人或研发技术人员；</div>
                        <div>3、项目的立项申请直接向公司研发中心提出。</div>
                        <div class="fw600" id="LXFS">（二）立项方式</div>
                        <div>1、立项流程</div>
                    </div>
                    <div class="d-flex w100p">
                        <div class="frame textc w230">经办人提交立项申请</div>
                        <div class="process"></div>
                        <div class="frame textc w180">部门经理核实</div>
                        <div class="process"></div>
                        <div class="frame textc w180">研发中心审核</div>
                        <div class="process"></div>
                        <div class="frame textc w170">总经理审批</div>
                    </div>
                    <div class="textIndent40">2、公司研发中心根据各经办人员提交的立项申请，于三个工作日内做出是否立项，并对同意立项项目做出是否采用简易流程的批复意见，注明原因后报总经理批准。
                    </div>
                    <div class="textIndent40">3、研发中心对不予立项的批复意见应同时注明处理意见，并在必要时协调；</div>
                    <div class="fw600" id="LXWD">（三）立项文档</div>
                    <div class="textIndent40">
                        1、进行立项申请的主要文档包括：《科研项目立项申请书》，《项目可行性论证报告》、《需求说明书》；各立项申请人可根据项目类别和特性的不同对立项文档进行适当增删，但公司研发中心可要求立项申请人就此立项申请补充提供有关资料，否则不予受理。
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 4 页 共 9 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发项目管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt50">
                    <div class="fw600" id="XMZGZZD">第六章 项目组工作制度</div>
                    <div class="fw600 textIndent40" id="WDGLZD">（一）文档管理制度</div>
                    <div class="textIndent40">
                        1、项目组的基础文档由项目经理指定的项目组成员统一管理。项目组的基础文档含立项审批时提交的文档、项目实施过程中产生的文档、项目结束后的总结文档。
                    </div>
                    <div class="textIndent40">
                        2、项目组的重要文档(由项目经理根据项目特性确定，但研发中心也可根据需求要求项目经理提供)需交公司研发中心备案。
                    </div>
                    <div class="textIndent40">
                        3、申请立项的项目一经审批后实施，项目文档管理人员即根据公司研发中心提供的编号规则对该项目进行编号。项目编号规则由研发中心统一确定；
                    </div>
                    <div class="textIndent40">
                        4、项目结束后，项目经理应组织将所有的项目文档交公司研发中心存档保管。
                    </div>
                    <div class="fw600 textIndent40" id="HYZD">（二）会议制度</div>
                    <div class="textIndent40">
                        1、项目小组成立后，项目经理应根据项目特性拟定本项目小组的会议方式、日程、内容等，项目小组成员也可根据项目进展需求灵活安排会议时间与方式。
                    </div>
                    <div class="textIndent40">
                        2、项目小组的每一次会议应做好会议记录并呈报项目经理，项目小组的会议记录作为项目文档之一。
                    </div>
                    <div class="fw600 textIndent40" id="GZJBZD">（三）工作简报制度</div>

                    <div class="textIndent40">项目组应每周由项目经理进行一次工作简报，按统一格式填写相关内容交公司研发中心备案，通过邮件或纸质等形式向公司领导层汇报。</div>
                    <div class="fw600 textIndent40" id="LCBGLZD">（四）里程碑管理制度</div>
                    <div class="textIndent40">
                        1、项目一经批准立项，即由项目经理牵头，根据项目的目标和特点，在三个工作日内对项目中不同工作角色制定对该项目的工作计划，汇总完成项目实施计划。项目实施计划提交公司研发中心审议通过后，项目小组工作启动。
                    </div>
                    <div class="textIndent40">
                        2、项目实施计划内将项目的执行过程划分成不同阶段，并规定各阶段结束的标志和条件，作为项目里程碑。通过里程碑保证项目每个阶段的工作质量和进度，以保证下一阶段目标的实现。同时，项目小组需根据各个里程碑特点制定项目里程控制计划，项目里程控制计划是项目实施计划的重要部分。
                    </div>
                    <div class="textIndent40">
                        3、项目实施计划应包括：①项目人员、角色分工和职责；②项目任务和成果的定义；③（每种工作角色的）项目日程安排；④项目里程；⑤项目的目标完成时间；⑥项目的风险和假设；⑦项目的总成本及各阶段成本。
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 5 页 共 9 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发项目管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt80">
                    <div class="textIndent40">
                        其中项目里程控制计划应包括：①项目里程碑日期；②本阶段确认需要完成的项目任务和成果；③本阶段需要解决的主要问题；④本阶段的项目风险；⑤本阶段的过程文档；⑥本阶段的成本与收益预算；⑦项目改动记录和应变计划；⑧进入下一阶段项目工作的准备条件。
                    </div>
                    <div class="textIndent40">
                        4、在达到计划的里程碑日期的一个工作日内，项目经理向对公司研发中心提交项目工作报告，并要求对项目里程碑进行检查，由公司研发中心组织管理组人员依据项目计划和里程控制表对项目里程进行检查，里程检查的主要要素包括：①项目阶段完成时间与计划时间对比；目前项目完成情况和所处阶段与它应处的阶段比较；②计划中本阶段经过确认的任务和成果是否完成；③本阶段的主要问题是否得到及时解决，是否有新的问题，是否解决；④本阶段的项目风险是否成功解决；⑤本阶段的项目文档应该有哪些，是否完备；⑥本阶段的成本控制是否超出偏差许可；有偏差是应提供偏差原因和解决办法；⑦项目是否被要求改动，改动是否被项目经理同意；同意改动后应提供改动后的项目计划调整方案；⑧
                        是否已经做好进入下一阶段的项目工作的准备；
                    </div>
                    <div class="textIndent40">
                        5、检查结果如果超出了允许的偏差范围，或者项目被要求作重大改动，应暂停项目的执行，由公司研发中心对项目的偏差和改动进行审议并对解决办法进行审批。
                    </div>
                    <div class="fw600 textIndent40" id="NBHSZD">（五）内部核算制度</div>
                    <div class="textIndent40">
                        项目经理应于项目组成立后的三个工作日内提交项目预算计划，明确主要费用与收益，并交公司研发中心审核后报总经理批复同意。项目实施过程中，如产生重大支出或收益变动，需及时调整预算报告并报公司研发中心审核，并经总经理批复同意。项目实施完成后，由财务部门负责对项目预算执行情况，项目效益进行核算。验收合格后，项目评估报告中必须包括项目成本支出细则与收益情况一项。
                    </div>
                    <div class="fw600" id="XMZZSSLC">第七章 项目组织实施流程</div>
                    <div class="fw600 textIndent40" id="XMJDHF">（一）项目阶段划分</div>
                    <div class="ml40">
                        <div>培养期：市场调查、分析、项目可行论证</div>
                        <div>立项期：提交立项申请，决定是否予以立项、项目计划拟定</div>
                        <div>执行期：项目计划实施、调整</div>
                    </div>
                    <div class="textIndent40">
                        评估（作业）期：项目验收、总结、作业（指项目完成后维持项目产物正常运转所需的日常性、常规性或重复性的工作。
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 6 页 共 9 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发项目管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt80">
                    <div class="fw600 textIndent40" id="JDLCYZYWD">（二）阶段流程与主要文档</div>
                    <el-image class="w100p" :src="model9112Img"></el-image>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 7 页 共 9 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发项目管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt80">
                    <div class="textIndent40 fw600" id="XMZTZ">（三）项目组调整</div>
                    <div class="textIndent40">
                        1、项目经理调整：因项目重大变更、人员更替、项目经理重大失误等原因可导致项目经理的变更或调整，项目经理的变更或调整由公司研发中心决定并确定继任人选。
                    </div>
                    <div class="textIndent40">
                        2、项目组内人员调整：项目组成员(除项目经理外)在项目运作过程中可以申请退出项目，项目经理也可根据项目实施要求申请更换项目组其他成员，但需向公司研发中心阐述理由并获同意。
                    </div>
                    <div class="textIndent40">
                        3、项目工作计划调整：项目工作计划可根据其中运作情况进行调整，但每次项目组工作计划的重大变动，需报公司研发中心备案。
                    </div>
                    <div class="textIndent40">
                        4、项目组人员主动申请退出项目组次数超过5次者，个人需书面说明原因。
                    </div>
                    <div class="textIndent40">
                        5、项目组成员被项目组退回次数超过5次者，公司将对该成员退回原因分析后，对其重新进行岗位调整或任职资格评估。
                    </div>
                    <div class="fw600" id="XMYS">第八章 项目验收</div>
                    <div class="fw600 textIndent40" id="YSZZFS">（一）验收组织方式</div>
                    <div class="textIndent40">
                        1、项目完成后，由研发中心组织公司相关领导组成验收小组对项目进行验收。
                    </div>
                    <div class="textIndent40">
                        2、项目验收以项目合同书约定的内容和确定的考核目标为基本依据，对项目产生的科技成果水平、应用效果和对经济社会的影响、实施的技术路线、攻克关键技术的方案和效果、知识产权的形成和管理、项目实施的组织管理经验和教训、科技人才的培养和队伍的成长、经费使用的合理性以及所产生的效果等做出客观、实事求是的评价。
                    </div>
                    <div class="textIndent40">
                        3、对所有项目验收均采取现场验收和书面验收两种方式。
                    </div>
                    <div class="fw600 textIndent40" id="YSCX">（二）验收程序</div>
                    <div class="textIndent40">
                        项目验收按照下列程序进行:
                    </div>
                    <div class="textIndent40">
                        1、项目验收工作应在合同到期一个月内完成，一个月内如不能进行验收的，项目实施单位（组）须向研发中心提出延迟验收申请报告；
                    </div>
                    <div class="textIndent40">
                        2、项目组在完成技术研发总结的基础上，向研发中心提出验收申请并提交有关验收资料及数据；
                    </div>
                    <div class="textIndent40">
                        3、项目验收组查全部验收资料及有关证明；
                    </div>
                    <div class="fw600 textIndent40" id="YSZL">（三）验收材料</div>
                    <div class="textIndent40">
                        项目承担者申请验收时应提供以下验收文件、资料，以及一定形式的成果 (样机、样品等):
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 8 页 共 9 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml20">管理文件>研发项目管理办法 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-D001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="lineheight45 mt80">
                    <div class="textIndent40 fw600" id="XMZTZ">（三）项目组调整</div>
                    <div class="ml40">
                        <div>1、项目合同书、立项申请书或计划任务书（可选）；</div>
                        <div>2、研发中心对项目的审批件或有关批复文件；</div>
                        <div>3、项目验收申请报告； </div>
                        <div>4、项目实施工作总结报告（可选）; </div>
                        <div>5、项目实施技术报告（可选）；</div>
                        <div>6、有关产品测试报告或检测报告及用户使用报告；</div>
                        <div>7、购置的仪器、设备等固定资产清单；</div>
                        <div>8、涉及经济指标的有关证明材料；</div>
                        <div>9、项目经费的决算表；</div>
                        <div>10、其他有关项目完成情况的证明材料。</div>
                    </div>
                    <div class="fw600" id="KYCG">第九章 科研成果</div>
                    <div class="textIndent40">
                        项目产生科技成果后，项目组应按规定进行科技成果登记，成果计入项目绩效，并按公司《知识产权管理与激励制度》进行奖励。
                    </div>
                    <div class="fw600" id="XMZKP">第十章 项目组考评</div>
                    <div class="textIndent40">
                        各项目组的验收结果、考评纳入公司整体考评体系中，参见公司绩效考评的有关规定。
                    </div>
                    <div class="fw600" id="QT">第十一章 其 他</div>
                    <div class="textIndent40">
                        1、附件提供部分参考表格文档，项目组各成员根据实际运作需要可进行调整或撰写新的表格文档。
                    </div>
                    <div class="textIndent40">
                        2、本管理办法为公司科技项目管理办法试行，将在实践过程中不断补充完善。
                    </div>
                    <div class="textIndent40">
                        3、本管理办法由研发中心负责解释，自公司审批同意之日起生效。
                    </div>
                    <div class="text-align-right mt100">{{ checkedCompany.companyName }}</div>
                    <div class="text-align-right">
                        <el-date-picker v-model="formData.fileDay" class="miniDay w190" value-format="yyyy-MM-dd"
                            format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                        </el-date-picker>(自动转为中文)
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 9 页 共 9 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution9112",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            formData: {
                fileDay: "",
            },
            directoryData: [
                { title: "第一章 总则", id: "ZZ", page: 3 },
                {
                    title: "第二章 适用范围", id: "SYFW", page: 3,
                    childData: [
                        { title: "（一）适用项目特点", id: "SYXMTD", page: 3 },
                        { title: "（二）适用项目范围", id: "SYXMFW", page: 3 },
                    ]
                },
                { title: "第三章 项目管理机构", id: "XMGLJG", page: 3 },
                { title: "第四章 项目组织结构", id: "XMZZJG", page: 3 },
                {
                    title: "第五章 项目立项",
                    id: "XMLX",
                    page: 4,
                    childData: [
                        { title: "（一）申请立项流程(培养期)", id: "SQLXLC", page: 4 },
                        { title: "（二）立项方式", id: "LXFS", page: 4 },
                        { title: "（三）立项文档", id: "LXWD", page: 4 },
                    ]
                },


                {
                    title: "第六章 项目组工作制度",
                    id: "XMZGZZD",
                    page: 4,
                    childData: [
                        { title: "（一）文档管理制度", id: "WDGLZD", page: 4 },
                        { title: "（二）会议制度", id: "HYZD", page: 5 },
                        { title: "（三）工作简报制度", id: "GZJBZD", page: 5 },
                        { title: "（四）里程碑管理制度", id: "LCBGLZD", page: 5 },
                        { title: "（五）内部核算制度", id: "NBHSZD", page: 6 },
                    ]
                },
                {
                    title: "第七章 项目组织实施流程",
                    id: "XMZZSSLC",
                    page: 4,
                    childData: [
                        { title: "（一）项目阶段划分", id: "XMJDHF", page: 6 },
                        { title: "（二）阶段流程与主要文档", id: "JDLCYZYWD", page: 6 },
                        { title: "（三）项目组调整", id: "XMZTZ", page: 7 },
                    ]
                },
                {
                    title: "第八章 项目验收",
                    id: "XMYS",
                    page: 8,
                    childData: [
                        { title: "（一）验收组织方式", id: "YSZZFS", page: 8 },
                        { title: "（二）验收程序", id: "YSCX", page: 8 },
                        { title: "（三）验收材料", id: "YSZL", page: 8 },
                    ]
                },
                { title: "第九章 科研成果", id: "KYCG", page: 9 },
                { title: "第十章 项目组考评", id: "XMZKP", page: 9 },
                { title: "第十一章 其  他", id: "QT", page: 9 },
            ],
            model9112Img: require("@/static/institution/model9112.jpg"),
            checkedCompany:{}
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        },
        textTime(time) {
            if (time) {
                let dayArr = time.split("-");
                let fileDay = "";
                let dayObj = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
                let tObj = ["年", "月", "日"];
                for (let i in dayArr) {
                    let t = dayArr[i];
                    if (i == 0 || t < 10) {
                        for (let childKey of t) {
                            fileDay += dayObj[childKey];
                        }
                    } else {
                        if (t > 10) {
                            if(t>=20){
                                fileDay += dayObj[String(t).slice(0, 1)]; 
                            }
                            fileDay += "十";
                            if(String(t).slice(1)>0){
                                fileDay += dayObj[String(t).slice(1)];
                            }
                        } else {
                            fileDay += dayObj[t];
                        }
                    }
                    fileDay += tObj[i];
                }
                return fileDay;
            } else {
                return "";
            }
        },
        timeStr(time) {
            if (time) {
                let dayStr = time.replace("-", "年");
                dayStr = dayStr.replace("-", "月");
                return dayStr + "日";
            }
        },
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany=data.companyData;
                    if(data.rulesId){
                        this.formData.fileDay = data.fileDay;
                    }else{
                        this.formData.fileDay = this.checkedCompany.fileDay;
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
};
</script>

<style lang="scss" scoped>
.frame {
    padding: 0 10px;
    border: 1px solid #000;
    white-space: nowrap;
}

.process {
    width: 80px;
    background: #000;
    height: 2px;
    margin-top: 22px;
}

.wordFoot {
    position: absolute;
    bottom: 150px;
    width: 1000px;
}

.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 1px solid #000;
}

.wrodContent {
    line-height: 50px;
}

.institutionTable {
    border-color: #000;

    .itemTitle {
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 20px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
        margin-left: -10px;
    }
}

.fileDay {
    /deep/input {
        font-size: 20px !important;
        line-height: 20px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            padding-bottom: 20px;
            margin-bottom: 50px;
        }
    }

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .wordDirectory {
        .wordDirectoryItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }

        .line {
            flex: 1;
            height: 15px;
            border-bottom: 2px dotted #000;
            margin: 0 5px;
        }
    }
}
</style>