<template>
  <div class="magiccubestyle">
    <!-- 标题 -->
    <h2>{{ datas.text }}</h2>

    <p style="color: #d40000; font-size: 14px">魔方布局</p>
    <p style="color: #969799; font-size: 12px; margin: 5px 0">
      选定布局区域，在下方添加图片
    </p>

    <!-- 图片布局 -->
    <!-- 一行二个 -->
    <section class="buju buju0" v-show="datas.rubiksCubeType === 0">
      <div v-for="index in 2" :key="index" class="rubiksCubeType0 rubiksCubeType">
        <div style="font-size: 12px" v-if="!datas.imageList[index - 1]">
          宽度375像素
        </div>
        <img draggable="false" v-else :src="datas.imageList[index - 1].src" alt="" />
      </div>
    </section>

    <!-- 一行三个 -->
    <section class="buju buju0" v-show="datas.rubiksCubeType === 1">
      <div v-for="index in 3" :key="index" class="rubiksCubeType1 rubiksCubeType">
        <div style="font-size: 12px" v-if="!datas.imageList[index - 1]">
          宽度250像素
        </div>
        <img draggable="false" v-else :src="datas.imageList[index - 1].src" alt="" />
      </div>
    </section>

    <!-- 一行四个 -->
    <section class="buju buju0" v-show="datas.rubiksCubeType === 2">
      <div v-for="index in 4" :key="index" class="rubiksCubeType2 rubiksCubeType">
        <div style="font-size: 12px" v-if="!datas.imageList[index - 1]">
          宽度188像素
        </div>
        <img draggable="false" v-else :src="datas.imageList[index - 1].src" alt="" />
      </div>
    </section>

    <!-- 二左二右 -->
    <section class="buju buju0" v-show="datas.rubiksCubeType === 3">
      <div v-for="index in 4" :key="index" class="rubiksCubeType3 rubiksCubeType">
        <div style="font-size: 12px" v-if="!datas.imageList[index - 1]">
          375x375像素<br />或同等比例
        </div>
        <img draggable="false" v-else :src="datas.imageList[index - 1].src" alt="" />
      </div>
    </section>

    <!-- 一左二右 -->
    <section class="buju buju4" v-show="datas.rubiksCubeType === 4">
      <div class="rubiksCubeType4 rubiksCubeType" style="width: 163px; height: 326px">
        <div style="font-size: 12px" v-if="!datas.imageList[0]">
          375x750像素<br />或同等比例
        </div>
        <img draggable="false" v-else :src="datas.imageList[0].src" alt="" />
        <div></div>
      </div>
      <div style="display: inline-flex; flex-direction: column">
        <div class="rubiksCubeType4 rubiksCubeType" v-for="index in 2" :key="index">
          <div style="font-size: 12px" v-if="!datas.imageList[index]">
            375x375像素<br />或同等比例
          </div>
          <img draggable="false" v-else :src="datas.imageList[index].src" alt="" />
          <div></div>
        </div>
      </div>
    </section>

    <!-- 一上二下 -->
    <section class="buju buju5" v-show="datas.rubiksCubeType === 5">
      <div class="rubiksCubeType4 rubiksCubeType" style="width: 325px; height: 163px">
        <div style="font-size: 12px" v-if="!datas.imageList[0]">
          375x750像素<br />或同等比例
        </div>
        <img draggable="false" v-else :src="datas.imageList[0].src" alt="" />
        <div></div>
      </div>
      <div style="display: inline-flex">
        <div class="rubiksCubeType4 rubiksCubeType" v-for="index in 2" :key="index">
          <div style="font-size: 12px" v-if="!datas.imageList[index]">
            375x375像素<br />或同等比例
          </div>
          <img draggable="false" v-else :src="datas.imageList[index].src" alt="" style="width: 163px; height: 163px" />
          <div></div>
        </div>
      </div>
    </section>

    <!-- 一左三右 -->
    <section class="buju buju4" v-show="datas.rubiksCubeType === 6">
      <!-- 第一张图片 -->
      <div class="rubiksCubeType4 rubiksCubeType" style="width: 163px; height: 325px">
        <div style="font-size: 12px" v-if="!datas.imageList[0]">
          375x750像素<br />或同等比例
        </div>
        <img draggable="false" v-else :src="datas.imageList[0].src" alt="" />
      </div>
      <div style="display: inline-flex; flex-direction: column">
        <!-- 第二张图片 -->
        <div class="rubiksCubeType4 rubiksCubeType">
          <div style="font-size: 12px" v-if="!datas.imageList[1]">
            375x375像素<br />或同等比例
          </div>
          <img draggable="false" v-else :src="datas.imageList[1].src" alt="" />
          <div></div>
        </div>
        <div class="rubiksCubeType4 rubiksCubeType">
          <div style="
                  display: inline-flex;
                  width: 82px;
                  height: 163px;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #ebedf0;
                " v-for="index in 2" :key="index">
            <div style="font-size: 12px" v-if="!datas.imageList[index + 1]">
              188x375像素<br />或同等比例
            </div>
            <img draggable="false" v-else :src="datas.imageList[index + 1].src" alt="" />
          </div>
        </div>
      </div>
    </section>

    <p style="color: #d40000; font-size: 12px; margin-top: 10px">请添加图片</p>

    <!-- 表单 -->
    <el-form label-width="80px" :model="datas" size="small">
      <section class="magiccubestyleList" v-for="(item, index) in datas.imageList" :key="index">
        <i class="el-icon-circle-close" @click="deleteimg(index)" />
        <!-- 图片 -->
        <div class="imag" @click="uploadFun(index)">
          <img draggable="false" v-if="!item.src" src="../../../../images/add.png" style="border: 1px solid #e5e5e5"
            alt="" />
          <div v-else style="position: relative">
            <img draggable="false" :src="item.src" alt="" />
            <p>
              点击更换图
            </p>
          </div>
        </div>
        <!-- 标题和链接 -->
        <div class="imgText">
          <crmJumpLink :linkData="item.http" @changeFun="jumpLinkChange($event, index)" />
        </div>
      </section>

      <div style="height: 20px" />

      <!-- 标题内容 -->
      <el-form-item label="选择模板" class="lef">
        <p style="color: #000">{{ styleText }}</p>
      </el-form-item>

      <!-- 商品样式选择 -->
      <div class="rubiksCubeType">
        <el-tooltip class="item" effect="dark" :content="item.content" placement="bottom"
          v-for="(item, index) in rubiksCubeTypes" :key="index">
          <span class="iconfont" style="font-size: 21px" :class="[
            item.type === datas.rubiksCubeType ? 'active' : '',
            item.icon,
          ]" :style="item.type==7?'transform: rotate(180deg);':''" @click="datas.rubiksCubeType = item.type" />
        </el-tooltip>
      </div>
      <el-button @click="multipUploadFun()" class="uploadImg" type="primary" plain>
        <i class="el-icon-plus" />点击添加图片
      </el-button>
      <div style="height: 20px" />
      <!-- 图片高度 -->
      <el-form-item label="图片高度" v-if="datas.rubiksCubeType <= 3" class="lef">
        <el-slider v-model="datas.imgHeight" :max="200" input-size="mini" show-input>
        </el-slider>
      </el-form-item>
      <!-- 页面边距 -->
      <el-form-item label="页面边距" class="lef">
        <el-slider v-model="datas.pageMargin" :max="20" input-size="mini" show-input>
        </el-slider>
      </el-form-item>

      <!-- 图片间隙 -->
      <el-form-item label="图片间隙" class="lef">
        <el-slider v-model="datas.imgMargin" :max="20" input-size="mini" show-input>
        </el-slider>
      </el-form-item>
    </el-form>
    <!-- 上传图片 -->
    <uploadimg ref="upload" @uploadInformation="uploadInformation" />
    <uploadMultipleImg ref="multipUpload" @uploadInformation="multipUploadInformation" />
  </div>
</template>

<script>
import uploadimg from '../../../uploadImg' //图片上传
import uploadMultipleImg from '../../../uploadMultipleImg' //图片上传

export default {
  name: 'crmMagiccubeCommonstyle',
  props: {
    datas: Object,
  },
  components: { uploadimg, uploadMultipleImg },
  data() {
    return {
      imageNumber: 0,
      rubiksCubeTypes: [
        {
          icon: 'icon-yihangerge',
          type: 0,
          content: '一行二个',
          number: 2
        },
        {
          icon: 'icon-yihangsange',
          type: 1,
          content: '一行三个',
          number: 3
        },
        {
          icon: 'icon-yihangsige',
          type: 2,
          content: '一行四个',
          number: 4
        },
        {
          icon: 'icon-erzuoeryou',
          type: 3,
          content: '二左二右',
          number: 4
        },
        {
          icon: 'icon-yizuoeryou',
          type: 4,
          content: '一左二右',
          number: 3
        },
        {
          icon: 'icon-yishangerxia',
          type: 5,
          content: '一上二下',
          number: 3
        },
        // {
        //   icon: 'icon-yizuoeryou',
        //   type: 7,
        //   content: '二左一右',
        //   number: 3
        // },
        // {
        //   icon: 'icon-yizuosanyou',
        //   type: 6,
        //   content: '一左三右',
        // },
      ],
      imgActive: 0, //默认选中第一个图片
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    styleText() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.imgActive = 0
      if (this.datas.rubiksCubeType === 0) return '一行二个'
      if (this.datas.rubiksCubeType === 1) return '一行三个'
      if (this.datas.rubiksCubeType === 2) return '一行四个'
      if (this.datas.rubiksCubeType === 3) return '二左二右'
      if (this.datas.rubiksCubeType === 4) return '一左二右'
      if (this.datas.rubiksCubeType === 5) return '一上二下'
      if (this.datas.rubiksCubeType === 6) return '一左三右'
      if (this.datas.rubiksCubeType === 7) return '二左一右'
    },
  },
  created() { },
  methods: {
    multipUploadFun() {
      this.$refs.multipUpload.showUpload()
      this.imageNumber = this.rubiksCubeTypes[this.datas.rubiksCubeType].number;
    },
    uploadFun(index) {
      this.$refs.upload.showUpload();
      this.imgActive = index;
    },
    deleteimg(index) {
      this.datas.imageList.splice(index, 1)
    },
    /**@method 链接路径 */
    jumpLinkChange(data, index) {
      if (data.type) {
        let { urlType, type, id, title } = data;
        let obj = {
          urlType,
          type,
          id,
          title
        }
        this.datas.imageList[index].http = obj
      } else {
        this.datas.imageList[index].http = {};
      }
      this.$set(this.datas.imageList, index, this.datas.imageList[index])
    },
    /* 替换 */
    uploadInformation(res) {
      if (this.datas.imageList[this.imgActive]) {
        this.datas.imageList[this.imgActive].src = res
      } else {
        this.datas.imageList[this.imgActive] = {
          src: res
        };
      }
      this.$set(this.datas.imageList, this.imgActive, this.datas.imageList[this.imgActive])
    },
    multipUploadInformation(data) {
      if (data.length + this.datas.imageList.length > this.imageNumber) {
        this.$message({
          type: "info",
          message: "上传的数据和原有的数据不能超过 " + this.imageNumber + " 条"
        })
        this.$refs.multipUpload.showUpload()
        return;
      }
      for (let imageUrl of data) {
        this.datas.imageList.push({
          src: imageUrl,
          http: {},
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.uploadImg {
  width: 345px;
  height: 40px;
  margin-top: 20px;
}

// 上传弹框内容部分
/deep/.uploadIMG .el-dialog__body {
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
}

.magiccubestyle {
  width: 100%;
  position: absolute;
  left: 0;
  overflow: hidden;
  top: 0;
  padding: 0 10px 20px;
  box-sizing: border-box;

  /* 标题 */
  h2 {
    padding: 24px 16px 24px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #f2f4f6;
    font-size: 18px;
    font-weight: 600;
    color: #323233;
  }

  /* 布局 */
  .buju {
    &.buju0 {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    &.buju4 {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .active {
      background: #51cdcb26;
      border: 1px solid #51CDCB;
      color: #51CDCB;
      z-index: 2;
    }

    .rubiksCubeType {
      background-color: #fff;
      border: 1px solid #ebedf0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #7d7e80;
      cursor: pointer;

      &.active {
        background: #51cdcb26;
        border: 1px solid #51CDCB;
        color: #51CDCB;
        z-index: 2;
      }

      &.rubiksCubeType0 {
        width: 50%;
        margin: 10px 0;

        height: 200px;

        img {
          width: 100%;
          // height: 200px;
        }
      }

      &.rubiksCubeType1 {
        width: 33%;
        margin: 10px 0;
        height: 150px;

        img {
          width: 100%;
          height: 150px;
        }
      }

      &.rubiksCubeType2 {
        width: 25%;
        margin: 10px 0;
        height: 150px;

        img {
          width: 100%;
          height: 150px;
        }
      }

      &.rubiksCubeType3 {
        width: 50%;
        margin: 10px 0;
        height: 163px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &.rubiksCubeType4 {
        width: 163px;
        height: 163px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .lef {
    /deep/.el-form-item__label {
      text-align: left;
    }
  }

  /* 商品列表 */
  .magiccubestyleList {
    padding: 6px 12px;
    margin: 16px 0px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    display: flex;
    position: relative;

    /* 删除图标 */
    .el-icon-circle-close {
      position: absolute;
      right: -10px;
      top: -10px;
      cursor: pointer;
      font-size: 19px;
    }

    /* 图片 */
    .imag {
      width: 60px;
      height: 60px;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        height: 60px;
        display: inline-block;
      }

      span {
        background: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        display: inline-block;
        width: 100%;
        text-align: center;
        color: #fff;
        height: 20px;
        line-height: 20px;
      }

      p {
        width: 100%;
        background: #999;
        position: absolute;
        left: 0;
        bottom: 5px;
        color: #fff;
        font-size: 5px;
        text-align: center;
        height: 20px;
      }
    }

    /* 图片字 */
    .imgText {
      width: 80%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding-left: 20px;
      justify-content: space-between;
    }
  }

  /* 列表样式 */
  .rubiksCubeType {
    display: flex;
    justify-content: space-around;
    align-items: center;

    span {
      display: inline-block;
      width: 58px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      background: #fff;
      border: 1px solid #ebedf0;
      color: #979797;
      margin: 0 1px;
      cursor: pointer;
      transition: all 0.5s;

      &:hover {
        border: 1px solid #51CDCB;
        color: #51CDCB;
      }

      &.active {
        border: 1px solid #51CDCB;
        background-color: #51cdcb26;
        color: #51CDCB;
      }
    }
  }
}
</style>
