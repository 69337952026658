<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent mt10">
                <div class="wordTtile">
                    <div class="flex-c-c textc">
                        <div class="zdhq fs24">{{ checkedCompany.companyName }}</div>
                    </div>
                    <div class="textc pjTitle mt10 mb20 fs24">文&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;件</div>
                </div>
                <div class="flex-a-b-c">
                    <div class="flex-a-c">
                        <div class="fs30">
                            {{ checkedCompany.companyWord }}字
                        </div>
                        <span class="fs30">[{{ yearValue }}]</span>
                        <div class="fs30">02号</div>
                    </div>
                    <div class="flex-a-c fs30">
                        <div>签发人：</div>
                        <div>
                            <el-input type="text" class="printName w150" placeholder="签发人"
                                v-model="formData.signName"></el-input>
                        </div>
                    </div>
                </div>
                <div class="textc fs30 mt50">
                    <span class="fs30" v-if="isAgain">关于优化调整{{ checkedCompany.companyWord }}研发中心的决定</span>
                    <span class="fs30" v-else>关于成立{{ checkedCompany.companyWord }}研发中心的决定</span>
                </div>
                <div class="textc fs14 mb50 mt30">{{ wordData.isAgain }}</div>
                <div class="wrodContent">
                    <div>
                        各部门：
                    </div>
                    <div>
                        <div>
                            <span class="ml40">为提高公司的技术研发和产品创新能力，优化公司科技研发管理、实施水平，适应公司战略发展规则的需求，经</span>
                            <span>
                                <el-date-picker v-model="formData.fileDay" @change="changeDay" class="dayInput w230"
                                    value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                                </el-date-picker>
                            </span>
                            <span v-if="isAgain">{{ checkedCompany.companyName }}总经理办公会研究决定：优化调整公司技术研发中心。</span>
                            <span v-else>{{ checkedCompany.companyName }}总经理办公会研究决定：成立公司技术研发中心。</span>
                        </div>
                        <div class="textIndent40">
                            <div>一、研发中心的职责</div>
                            <div>1、根据技术发展现状和趋势，提出公司研发项目计划，包括立项目的、依据、关键技术、创新点、技术研发人员和经费需求、研发资金筹措等；</div>
                            <div>2、组织成立项目组，负责研发项目实施、成果验收和成果转化；</div>
                            <div>3、负责公司研发项目、技术研发人员、研发经费管理、项目经济核算；</div>
                            <div>4、负责公司技术培训，提高员工技术水平。</div>
                            <div>二、机构设置</div>
                            <div>中心主任：由公司主管技术的副总经理兼任，负责根据公司战略发展规划，制定研发项目计划，并组织实施。研发中心下设两个部门，其主要职责如下：</div>
                        </div>
                        <div v-for="(str, index) in content" :key="index">
                            {{ str }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 1 页 共 2 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent mt10">
                <div class="wrodContent">
                    <div class="textIndent40">
                        <div>公司研发项目采用项目经理负责制进行，即项目组设项目经理一名，同时由项目经理从两个部门中选拔相关研发技术人员作为项目组成员，以保证项目研发顺利进行，各部门需要全力配合与支持。
                        </div>
                        <div>三、项目经费</div>
                        <div>项目组负责制定项目经费使用需求和计划，按公司《研发准备金管理制度》、《研发辅助台账及核算管理制度》财务管理制度及政府各类专项财政资金管理办法执行。</div>
                    </div>
                </div>
                <div class="text-align-right mt100 h40" style="margin-bottom: 400px;">{{ formData.fileDay | textTime }}
                </div>
                <div class="d-flex pb20 lineBottom pt100">
                    <div>主题词：</div>
                    <div class="ml20">研发中心</div>
                    <div class="ml20">成立</div>
                    <div class="ml20">决定</div>
                </div>
                <div class="d-flex pb20 pt20 lineBottom">
                    <div>抄送：</div>
                    <div class="ml20">各部门</div>
                </div>
                <div class="flex-a-b-c pt20">
                    <div class="d-flex">
                        <div>打印：</div>
                        <div>
                            <el-input type="text" class="printName w150" placeholder="打印人"
                                v-model="formData.printName"></el-input>
                        </div>
                    </div>
                    <div>{{ checkedCompany.companyName }}</div>
                    <div>
                        <el-date-picker v-model="formData.fileDay" @change="changeDay" class="dayInput w230"
                            value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                        </el-date-picker>印发
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 2 页 共 2 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "institution921",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        },
        isAgain: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formData: {
                fileDay: "",
                hostName: "",
                printName: "",
            },
            content: [],
            checkedCompany: {}
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2);
        },
        textTime(time) {
            if (time) {
                let dayArr = time.split("-");
                let fileDay = "";
                let dayObj = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
                let tObj = ["年", "月", "日"];
                for (let i in dayArr) {
                    let t = dayArr[i];
                    if (i == 0 || t < 10) {
                        for (let childKey of t) {
                            fileDay += dayObj[childKey];
                        }
                    } else {
                        if (t > 10) {
                            if (t >= 20) {
                                fileDay += dayObj[String(t).slice(0, 1)];
                            }
                            fileDay += "十";
                            if (String(t).slice(1) > 0) {
                                fileDay += dayObj[String(t).slice(1)];
                            }
                        } else {
                            fileDay += dayObj[t];
                        }
                    }
                    fileDay += tObj[i];
                }
                return fileDay;
            } else {
                return "";
            }
        },
        timeStr(time) {
            if (time) {
                let dayStr = time.replace("-", "年");
                dayStr = dayStr.replace("-", "月");
                return dayStr + "日";
            }
        },
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData = {
                            fileDay: data.fileDay,
                            signName: data.signName,
                            printName: data.printName,
                        }
                        this.content = data.content ? data.content.split("\n") : [];
                    } else {
                        this.formData = {
                            fileDay: this.checkedCompany.fileDay,
                            signName: this.checkedCompany.legalName,
                            printName: this.checkedCompany.printName,
                        }
                        this.content = data.content ? data.content.split("\n") : [];
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    created() {

    },
    methods: {
        changeDay() {
            this.formData.year = this.formData.printDay.slice(0, 4)
            this.formData.fileDay = this.formData.printDay

        },
    },
};
</script>
<style lang="scss" scoped>
.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.lineBottom {
    border-bottom: 2px solid #000;
}

.wrodContent {
    line-height: 50px;

    pre {
        font-family: none;
    }
}

.institutionTable {
    border-color: #000;

    .itemTitle {
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 25px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.printName {
    /deep/input {
        font-size: 25px !important;
        line-height: 25px;
        margin-left: -10px;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;

        div {
            color: #999;
            font-size: 15px;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 30px !important;
                line-height: 30px;
                margin-left: -10px;
            }
        }

        .wordContentInput {
            /deep/input {
                font-size: 25px !important;
                line-height: 28px;
                margin-left: -10px;
            }
        }

        .wordTtile {
            div {
                color: red;
            }

            border-bottom: 4px solid red;
            padding-bottom: 20px;
            margin-bottom: 50px;
        }
    }
}
</style>